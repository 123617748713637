import { createContext, useState, useContext, useReducer } from "react";
import { isAzureFile } from "./HistoricAuditContext";
import { useSnackbar } from "notistack";
import useDeleteHistoricAction from "../hooks/mutations/useDeleteHistoricAction";

const defaultHistoricActionCreateUpdateState = {
    description: null,
    rating: null,
    azureFile: null,
    dueDate: null,
    notes: null,
    azureFileIdToDelete: null,
    newAzureFileId: null,
}

const historicActionCreateUpdateActionTypes = {
    SET_DESCRIPTION: 'SET_DESCRIPTION',
    SET_DUE_DATE: 'SET_DUE_DATE',
    SET_RATING: 'SET_RATING',
    SET_NOTES: 'SET_NOTES',
    SET_AZURE_FILE: 'SET_AZURE_FILE',
    ADD_AZURE_FILE: 'ADD_AZURE_FILE',
    DELETE_AZURE_FILE: 'DELETE_AZURE_FILE',
}

function historicActionReducer(state, action) {
    switch (action.type) {
        case historicActionCreateUpdateActionTypes.SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload
            }
        case historicActionCreateUpdateActionTypes.SET_DUE_DATE:
            return {
                ...state,
                dueDate: action.payload
            }
        case historicActionCreateUpdateActionTypes.SET_RATING:
            return {
                ...state,
                rating: action.payload
            }
        case historicActionCreateUpdateActionTypes.SET_NOTES:
            return {
                ...state,
                notes: action.payload
            }
        case historicActionCreateUpdateActionTypes.SET_AZURE_FILE:
            return {
                ...state,
                azureFile: action.payload
            }
        case historicActionCreateUpdateActionTypes.ADD_AZURE_FILE:
            return {
                ...state,
                azureFile: action.payload.file,
                newAzureFileId: action.payload.syncId
            }
        case historicActionCreateUpdateActionTypes.DELETE_AZURE_FILE: {
            if (isAzureFile(action.payload)) {
                return {
                    ...state,
                    azureFile: null,
                    azureFileIdToDelete: action.payload.azureFileId
                }
            } 
            
            return {
                ...state,
                azureFile: null,
                newAzureFileId: null
            }
        }
        default:
            return state
    }
}

const HistoricActionContext = createContext();

function HistoricActionProvider({ children }) {
    const [editHistoricActionDialogOpen, setEditHistoricActionDialogOpen] = useState(false)
    const [historicActionToEdit, setHistoricActionToEdit] = useState(null)
    const [isCreationMode, setIsCreationMode] = useState(false)
    const [requiresCreateValidation, setRequiresCreateValidation] = useState(false)
    const [createHistoricActionDialogOpen, setCreateHistoricActionDialogOpen] = useState(false)
    const [historicActionIdToDelete, setHistoricActionIdToDelete] = useState(null)
    const [deleteHistoricActionDialogOpen, setDeleteHistoricActionDialogOpen] = useState(false)

    const { enqueueSnackbar } = useSnackbar()

    const deleteHistoricAction = useDeleteHistoricAction()

    const [historicActionDetails, historicActionDispatch] = useReducer(historicActionReducer, defaultHistoricActionCreateUpdateState)

    function setDescription(description) {
        historicActionDispatch({ type: historicActionCreateUpdateActionTypes.SET_DESCRIPTION, payload: description })
    }

    function setDueDate(date) {
        historicActionDispatch({ type: historicActionCreateUpdateActionTypes.SET_DUE_DATE, payload: date })
    }

    function setRating(rating) {
        historicActionDispatch({ type: historicActionCreateUpdateActionTypes.SET_RATING, payload: rating })
    }

    function setNotes(notes) {
        historicActionDispatch({ type: historicActionCreateUpdateActionTypes.SET_NOTES, payload: notes })
    }

    function setAzureFile(azureFile) {
        historicActionDispatch({ type: historicActionCreateUpdateActionTypes.SET_AZURE_FILE, payload: azureFile })
    }

    function addAzureFile(azureFile) {
        historicActionDispatch({ type: historicActionCreateUpdateActionTypes.ADD_AZURE_FILE, payload: azureFile })
    }

    function deleteAzureFile(azureFile) {
        historicActionDispatch({ type: historicActionCreateUpdateActionTypes.DELETE_AZURE_FILE, payload: azureFile })
    }

    function reset() {
        setDescription(null)
        setDueDate(null)
        setRating(null)
        setNotes(null)
        setAzureFile(null)
        setHistoricActionToEdit(null)
        setEditHistoricActionDialogOpen(false)
        setIsCreationMode(false)
        setRequiresCreateValidation(false)
        setCreateHistoricActionDialogOpen(false)
        setDeleteHistoricActionDialogOpen(false)
        setHistoricActionIdToDelete(null)
    }

    function handleDeleteHistoricAction(actionToDelete) {
        if (!actionToDelete.historicActionId) {
            enqueueSnackbar('Action not found.', { variant: 'error' })
            return
        }

        if (!actionToDelete.historicAuditId) {
            enqueueSnackbar('Audit not found.', { variant: 'error' })
            return
        }

        const historicActionToDelete = {
            historicActionId: actionToDelete.historicActionId,
            historicAuditId: actionToDelete.historicAuditId,
        }

        deleteHistoricAction.mutate(historicActionToDelete)
        enqueueSnackbar('Action deleted successfully.', { variant: 'success' })
        setDeleteHistoricActionDialogOpen(false)
    }

    const value = {
        editHistoricActionDialogOpen,
        setEditHistoricActionDialogOpen,
        historicActionToEdit,
        setHistoricActionToEdit,
        historicActionDetails,
        setDescription,
        setDueDate,
        setRating,
        setNotes,
        setAzureFile,
        addAzureFile,
        deleteAzureFile,
        reset,
        isCreationMode,
        setIsCreationMode,
        requiresCreateValidation,
        setRequiresCreateValidation,
        createHistoricActionDialogOpen,
        setCreateHistoricActionDialogOpen,
        historicActionIdToDelete,
        setHistoricActionIdToDelete,
        deleteHistoricActionDialogOpen,
        setDeleteHistoricActionDialogOpen,
        handleDeleteHistoricAction,
    }

    return (
        <HistoricActionContext.Provider value={value}>
            {children}
        </HistoricActionContext.Provider>
    )
}

const useHistoricActionContext = () => useContext(HistoricActionContext);

export { HistoricActionProvider, useHistoricActionContext }