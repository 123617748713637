import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import incidentService from "../../services/incidentService";

const useIncidentSummaryStats = ({ externalIds, fromDate, toDate, weekCount }) =>
    useQuery(
        [queryKeys.incidentSummaryStats, externalIds?.join(","), fromDate, toDate, weekCount],
    () =>
      incidentService.fetchIncidentSummaryStats(
        externalIds,
        fromDate,
        toDate,
        weekCount
      ),
    {
      enabled: !!externalIds?.length && !!fromDate && !!toDate,
    }
  );

export default useIncidentSummaryStats;
