import React from "react";
import FileViewer from "react-file-viewer";
import { Box, Dialog, DialogTitle, Typography, IconButton } from "@material-ui/core";
import { CloudDownload } from '@material-ui/icons';
import caseNestDocumentService from "../services/caseNestDocumentService";
import "../css/fileViewer.css"
import { useTracking } from "react-tracking";
import trackingConstants from "../constants/trackingConstants";

const FileViewerModal = ({
    open,
    onClose,
    fileType,
    filePath,
    fileName,
    azureGuid,
    precedentId
}) => {

    const { trackEvent } = useTracking();

    const downloadDocument = async () => {

        if (precedentId)
            trackEvent({ event: `${trackingConstants.events.downloadPrecedent}: ${precedentId}` });

        const response = await caseNestDocumentService.fetchDocument(azureGuid, 'arraybuffer')
        let blob = new Blob([response], { type: fileType });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };

    return (
        <Dialog
            className="file-modal"
            onClose={onClose}
            open={open}
            fullWidth
            maxWidth={"lg"}
        >
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Viewing document - {fileName}</Typography>
                    <IconButton onClick={() => downloadDocument()}><CloudDownload /></IconButton>
                </Box>
            </DialogTitle>
            <FileViewer fileType={fileType.toLowerCase()} filePath={filePath} />
        </Dialog>
    );
};

export default FileViewerModal;
