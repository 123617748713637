import {
    Button, Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid, IconButton, makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead, TableRow,
    TextField
} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import {useState} from "react";
import {nanoid} from "nanoid";

const useStyles = makeStyles((theme) => ({
    bottomGap: {
        marginBottom: theme.spacing(2)
    },
    removed: {
        textDecoration: 'line-through'
    },
    dialog: {
        [theme.breakpoints.up("md")]: {
            height: "50vh",
        },
        [theme.breakpoints.down("sm")]: {
            height: "75vh",
        }
    },
}))

export default function CustomHazardDialog({open, onClose, selectedAccount, newCustomHazards, setNewCustomHazards }) {
    const classes = useStyles()

    const [newCustomHazard, setNewCustomHazard] = useState('')
    const [removeOnSave, setRemoveOnSave] = useState([])
    const [addedHazards, setAddedHazards] = useState([])

    function onCancel() {
        setRemoveOnSave([])
        setNewCustomHazard('')
        setAddedHazards([])
        onClose()
    }

    function onSave() {
        setNewCustomHazards(addedHazards.concat(newCustomHazards).filter(hazard => !removeOnSave.some(id => id === hazard?.id)))
        setNewCustomHazard('')
        setAddedHazards([])
        onClose()
    }

    function addHazard() {
        setAddedHazards([...addedHazards, {
            id: nanoid(5),
            name: newCustomHazard,
            siteExternalId: selectedAccount
        }])
        setNewCustomHazard('')
    }

    return (
        <Dialog maxWidth="sm" fullWidth open={open}>
            <DialogTitle>Custom Hazards</DialogTitle>
            <DialogContent>
                <Grid className={classes.bottomGap} spacing={2} alignItems='center' container>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            required
                            size='small'
                            variant="outlined"
                            label='Hazard Name'
                            value={newCustomHazard}
                            onChange={(e) => setNewCustomHazard(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant='text'
                            color='secondary'
                            disabled={!addedHazards.concat(newCustomHazard) || addedHazards.concat(newCustomHazards).some(x => x.name.toLowerCase() === newCustomHazard.toLowerCase())}
                            onClick={addHazard}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                {addedHazards.concat(newCustomHazards)?.length > 0 &&
                    <Table className={classes.bottomGap} size="small">
                        <TableHead>
                            <TableCell colSpan={2}>Hazard Name</TableCell>
                        </TableHead>
                        <TableBody>
                            {addedHazards.concat(newCustomHazards)?.map(hazard => {
                                const toBeRemoved = removeOnSave?.some(x => x === hazard?.id)

                                return (
                                    <TableRow key={hazard?.id}>
                                        <TableCell className={toBeRemoved && classes.removed}>{hazard?.name}</TableCell>
                                        <TableCell align="right">
                                            <IconButton disabled={toBeRemoved}
                                                        onClick={() => setRemoveOnSave([...removeOnSave, hazard?.id])}
                                                        size="small"><Delete/></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={addedHazards.concat(newCustomHazards)?.length === 0 && removeOnSave?.length === 0}
                    onClick={onSave}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}