import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import tutorialService from "../../services/tutorialService";

const useTutorial = (tutorialId) =>
  useQuery(
    [queryKeys.tutorial, tutorialId],
    () => tutorialService.fetchById(tutorialId),
    { enabled: !!tutorialId }
  );

export default useTutorial;
