import {
  alpha,
  Box,
  Button,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import CardBase from "../../components/cards/CardBase";
import { baseRoute } from "../routes";
import { formatShortDate } from "../../utils/dateTimeUtils";
import useTemplateFormHistory from "../hooks/queries/useTemplateFormHistory";
import { answerTypes } from "../constants/answerConstants";
import PageBase from "../../components/PageBase";
import FileUploads from "../components/FileUploads";
import useAddTemplateFormHistoryAttachment from "../hooks/mutations/useAddTemplateFormHistoryAttachment";
import useDeleteTemplateFormHistoryAttachment from "../hooks/mutations/useDeleteTemplateFormHistoryAttachment";

const useStyles = makeStyles((theme) => ({
  gap: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  padded: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  smallFont: {
    fontSize: "14px",
  },
  wide: {
    minWidth: "50vw",
  },
  uploadsListWrapper: {
    padding: theme.spacing(2),
    borderRadius: "8px",
    marginTop: theme.spacing(1),
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  },
  end: {
    marginLeft: "auto",
    },
    headerButtonsContainer: {
        display: "flex",
        gap: theme.spacing(2),
    },
}));

export default function ViewFormHistory() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const { params } = match;
  const { moduleArea } = params;
  const templateFormHistoryId = parseInt(params.templateFormHistoryId);
  const formHistory = useTemplateFormHistory(templateFormHistoryId);
  const addAttachment = useAddTemplateFormHistoryAttachment();
  const deleteAttachment = useDeleteTemplateFormHistoryAttachment();

  const [questionId, setQuestionId] = useState(null);


  function getAnswerForQuestion(question) {
    switch (question.answerType) {
      case answerTypes.TEXT:
      case answerTypes.DROPDOWN:
      case answerTypes.RADIO:
        return question.answerText;
      case answerTypes.NUMBER:
        return question.answerNumber;
      case answerTypes.DATE:
        return formatShortDate(question.answerDate);
      case answerTypes.MULTISELECT:
        return question.answerMultiSelect.join(", ");
    }
  }

    const onFilesChanged = async (files) => {
        
        const filesToAttach = files.filter(
            (file) => file.toBeAdded && !file.isUploading
        );

        const filesToDelete = files.filter((file) => file.toBeDeleted);

        filesToAttach.forEach((fileToAttach) => {

            const templateFormAttachmentToAdd = {
                templateFormHistoryId: templateFormHistoryId,
                azureFileId: fileToAttach.azureFileId,
                questionId: questionId,
            };

            addAttachment.mutate(templateFormAttachmentToAdd, {
                onSuccess: (data) => {
                    setQuestionId(null);
                    fileToAttach.toBeAdded = false;
                },
                onError: (error) => {
                    console.error(error);
                    enqueueSnackbar("Could not add file.", { variant: "error" });
                },
            });
        });

        filesToDelete.forEach((fileToDelete) => {
            const templateFormAttachmentToDelete = {
                templateFormHistoryId: templateFormHistoryId,
                azureFileId: fileToDelete.azureFileId,
                questionId: questionId,
            };

            deleteAttachment.mutate(templateFormAttachmentToDelete, {
                onSuccess: (data) => {
                    // Handle successful deletion
                },
                onError: (error) => {
                    console.error(error);
                    enqueueSnackbar("Could not delete file.", { variant: "error" });
                },
            });
        });
    };

  return (
    <PageBase>
      <div className={classes.gap}>
        <CardBase
          isLoading={formHistory.isLoading}
          title={
            formHistory.isSuccess && `Viewing ${formHistory.data?.templateName}`
          }
        >
          {formHistory.isSuccess && (
            <Typography>
              Completed on{" "}
              {formHistory.data?.completedDate &&
                formatShortDate(formHistory.data.completedDate)}
            </Typography>
          )}
        </CardBase>
        <div className={classes.gap}>
          {formHistory.data?.sections?.map((section) => (
            <CardBase key={section?.id} title={section?.name}>
              <div className={classes.gap}>
                {section?.questions?.map((question) => {
                  const answerValue = getAnswerForQuestion(question);
                  return (
                      <CardBase flat key={question?.id}>
                      <Typography>
                        <strong>{question?.text}</strong>
                      </Typography>
                      <Typography className={classes.smallFont}>
                        {answerValue && answerValue}
                      </Typography>
                      {question.additionalInfo && (
                        <Typography className={classes.smallFont}>
                          Additional Information: {question.additionalInfo}
                        </Typography>
                          )}
                          <FileUploads
                              files={question?.attachments}
                              showAddButton={question.allowPhotoUpload}
                              onFilesChanged={(e) => {
                                  setQuestionId(question.id);
                                  onFilesChanged(e);
                              }}
                          />
                      {!!question?.actions?.length && (
                        <>
                          <Divider className={classes.padded} />
                          <Typography>
                            <strong>Actions:</strong>
                          </Typography>
                          <div className={classes.uploadsListWrapper}>
                            {question?.actions?.map((action) => (
                              <Box
                                gridColumnGap={12}
                                alignItems="center"
                                display="flex"
                                key={action?.id}
                              >
                                <div>
                                  <Typography className={classes.smallFont}>
                                    Description: {action?.description}
                                  </Typography>
                                  <Typography className={classes.smallFont}>
                                    Due Date: {formatShortDate(action?.dueDate)}
                                  </Typography>
                                </div>
                                <Button
                                  component={Link}
                                  className={classes.end}
                                  to={`${baseRoute}/${moduleArea}/action/${action.id}`}
                                  variant="text"
                                  size="small"
                                >
                                  View
                                </Button>
                              </Box>
                            ))}
                          </div>
                        </>
                      )}
                    </CardBase>
                  );
                })}
              </div>
            </CardBase>
          ))}
          <div>
            <Button
              onClick={() => history.goBack()}
              color="primary"
              variant="outlined"
            >
              Back
            </Button>
          </div>
        </div>
      </div>
    </PageBase>
  );
}
