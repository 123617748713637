import { useMutation } from "react-query";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";
import riskAssessmentHistoryService from "../../services/riskAssessmentHistoryService";

const useSaveRiskAssessmentHistory = () =>
  useMutation(
    (riskAssessmentToSave) => 
      riskAssessmentHistoryService.saveRiskAssessmentHistory(riskAssessmentToSave),
    {
        onSuccess: (riskAssessmentToSave) => {
            reactQueryUtils.client.invalidateQueries([queryKeys.riskAssessment, riskAssessmentToSave.riskAssessmentId, true]);
            reactQueryUtils.client.invalidateQueries([queryKeys.riskAssessment, riskAssessmentToSave.riskAssessmentId]);
            reactQueryUtils.client.invalidateQueries([queryKeys.riskAssessmentHistory, riskAssessmentToSave.id]);
            reactQueryUtils.client.invalidateQueries([queryKeys.riskAssessmentVersionHistory, riskAssessmentToSave.id]);
      }
    }
  );

export default useSaveRiskAssessmentHistory;