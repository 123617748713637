import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import resourceNestService from "../../services/resourceNestService";

const usePrecedents = (licence) =>
    useQuery(
        [queryKeys.precedents, licence],
        () => resourceNestService.getPrecedents(licence),
        {
            staleTime: Infinity
        }
    )

export default usePrecedents;