import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import salesforceService from "../../services/salesforceService";

const useSalesforceUserDetails = (email) =>
  useQuery(
    [queryKeys.salesforceUserDetails, email],
    () => salesforceService.getUserDetails(email),
    {
      enabled: !!email,
      retry: false,
    }
  );

export default useSalesforceUserDetails;
