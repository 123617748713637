import { useMutation } from "react-query";
import incidentService from "../../services/incidentService";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";
import { differenceInDays } from "date-fns";

const useAddHistoricSiteIncident = () =>
  useMutation(incidentService.addHistoricIncident, {
    onMutate: async ({ siteExternalId, incidentDate }) => {
      const { client } = reactQueryUtils;
      const queryKey = [queryKeys.daysSinceIncident];
      await client.cancelQueries(queryKey);

      const incidentDaysBeforeChange = client.getQueryData(queryKey, {
        exact: false,
      });

      client.setQueriesData({ queryKey, exact: false }, (incidentDayData) =>
        incidentDayData.map((incident) => {
          if (incident.siteExternalId === siteExternalId)
            return {
              ...incident,
              incidentDate,
              daysSinceIncident: differenceInDays(
                new Date(),
                new Date(incidentDate)
              ),
            };

          return incident;
        })
      );

      return { incidentDaysBeforeChange };
    },
    onError: (error, variables, { incidentDaysBeforeChange }) => {
      const queryKey = [queryKeys.daysSinceIncident];
      reactQueryUtils.client.setQueriesData(queryKey, incidentDaysBeforeChange);
    },
    onSettled: () => {
      reactQueryUtils.client.invalidateQueries([queryKeys.daysSinceIncident]);
    },
  });

export default useAddHistoricSiteIncident;
