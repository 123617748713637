import { useMutation } from "react-query";
import salesforceService from "../../services/salesforceService";
import reactQueryUtils from "../../utils/reactQueryUtils";
import queryKeys from "../../constants/queryKeys";

const useUpdateUser = () =>
    useMutation(salesforceService.updateUser, {
        onMutate: async (userUpdates) => {
            const { client } = reactQueryUtils;
            const queryKey = [queryKeys.siteUsers, userUpdates.accountId];
            await client.cancelQueries(queryKey);

            const usersBeforeChange = client.getQueryData(queryKey);

            client.setQueryData(queryKey, (siteUsers) =>
                Object.keys(siteUsers).reduce(
                    (curr, key) => ({
                        ...curr,
                        [key]:
                            key === userUpdates.userExternalId
                                ? {
                                    ...siteUsers[key],
                                    // todo: optimistically update account apps with app id only?
                                    accountRoles: {
                                        ...siteUsers[key].accountRoles,
                                        [userUpdates.accountId]: {
                                            ...siteUsers[key].accountRoles[userUpdates.accountId],
                                            roles: userUpdates.roles,
                                            adviceTypes: userUpdates.adviceTypes,
                                            canAccessChildSites: userUpdates.canAccessChildSites,
                                        },
                                    },
                                }
                                : siteUsers[key],
                    }),
                    {}
                )
            );

            return { usersBeforeChange };
        },
        onError: (error, userUpdates, { usersBeforeChange }) => {
            const queryKey = [queryKeys.siteUsers, userUpdates.accountId];
            reactQueryUtils.client.setQueryData(queryKey, usersBeforeChange);
        },
        onSettled: (_data, _error, userUpdates) => {
            if (userUpdates)
                reactQueryUtils.client.invalidateQueries([
                    queryKeys.siteUsers,
                    userUpdates.accountId,
                ]);
        },
    });

export default useUpdateUser;
