import React, { useState } from "react";
import { TextField, makeStyles, Button, Typography, InputAdornment, Avatar } from "@material-ui/core";
import { validEmailRegex } from "../../src/utils/regexUtils";
import { useAuth } from "../contexts/authContext";
import { ArrowBack } from "@material-ui/icons"
import { useSnackbar } from "notistack";
import { MailOutlined } from "@material-ui/icons"
import layoutConstants from "../constants/layoutConstants";

const useStyles = makeStyles((theme) => ({
    backToSignIn: {
        textAlign: "left",
        cursor: "pointer",
        color: theme.palette.secondary.main,
        textDecoration: "none",
        display: 'flex', alignItems: 'center',
        "&:hover": {
            textDecoration: "underline",
        },
    },
    actionBtn: {
        marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
    },
    backText: {
        marginLeft: theme.spacing(1),
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: "column",
    },
    resendReset: {
        marginLeft: theme.spacing(1),
        color: theme.palette.secondary.main,
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    editEmailButton:
    {
        cursor: "pointer",
        color: theme.palette.secondary.main,
    },
    backButton:
    {
        marginTop: theme.spacing(2), 
        width: 'fit-content',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
    },
    resendButton:
    {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
    },
    emailIcon: {
        fontSize: "50px",
        color: theme.palette.secondary.main, 
    },
    purpleCircle: {
        backgroundColor: '#F2EDFF', 
        width: 100,
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        marginTop: theme.spacing(2)
    },
    logoWrapper: {
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: theme.spacing(2),
        maxWidth: layoutConstants.loginFormWidth,
      },
      greeting: {
        marginTop: theme.spacing(2),
            fontSize: "32px",
            fontWeight: "bold",
          textAlign: "center",
        },
        greetingSub: {
            textAlign: "center",
            fontSize: "16px",
            marginBottom: theme.spacing(5),
        },
}));

function ResetPasswordForm({ emailAddress, setEmailAddress, onBackClick }) {
    const classes = useStyles();
    const [invalidEmail, setInvalidEmail] = useState(false); 
    const [showConfirmation, setShowConfirmation] = useState(false); 
    const { resetPassword } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const handleRequestPasswordReset = () => {
        if (emailAddress.trim() === '' || !emailAddress.match(validEmailRegex)) {
            setInvalidEmail(true);
        } else {
            resetPassword({ email: emailAddress })
                .then(result => {
                    enqueueSnackbar(result, {variant: "success"});
                    setShowConfirmation(true)
                })
                .catch(err => {
                    enqueueSnackbar(err, { variant: "error" });
                });
        };
    }

    return (
        <>
            {showConfirmation ? (
                <>
                <div className={classes.logoWrapper}>
                        <Avatar className={classes.purpleCircle} >
                            <MailOutlined className={classes.emailIcon} />
                        </Avatar>
                    </div>
                <Typography className={classes.greeting}>Check your inbox</Typography>
                <Typography className={classes.greetingSub}>{`A link to reset your password has been emailed out to ${emailAddress}`}</Typography>
                <Button variant="contained" color="secondary" className={classes.actionBtn}
                    startIcon={<ArrowBack />}
                    onClick={onBackClick}>Back to sign in </Button>
                <div className={classes.container}>
                    <Typography>
                        Didnt receive the email?
                        <Button className={classes.resendButton} 
                            onClick={handleRequestPasswordReset}
                        >Resend
                        </Button>
                    </Typography>
                </div>
            </>
            ) : (
                <>
                <Typography className={classes.greeting}>Trouble signing in?</Typography>
                <Typography className={classes.greetingSub}>No worries, we'll email you instructions on how to reset your password</Typography>
                    <TextField
                        label="Email"
                        variant="outlined"
                        value={emailAddress}
                        inputProps={{ maxLength: 320 }}
                        helperText={invalidEmail && "Please enter a valid email address."}
                        error={invalidEmail}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        disabled
                        InputProps={{
                            endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            className={classes.editEmailButton}
                                            onClick={onBackClick}
                                        >
                                            Edit
                                        </Button>
                                    </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        className={classes.actionBtn}
                        variant="contained"
                        color="secondary"
                        onClick={handleRequestPasswordReset}
                    >Reset Password
                    </Button>
                    <Button startIcon={<ArrowBack/>} className={classes.backButton} onClick={onBackClick}
                >Back to sign in
                </Button>
                </>
            )}
        </>
    );
}

export default ResetPasswordForm;