import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import { historicActionService } from "../../services/historicActionService";

const useHistoricAuditActions = (historicAuditId) => 
    useQuery(
        [queryKeys.historicAuditActions, historicAuditId],
        () => historicActionService.getByHistoricAuditId(historicAuditId),
        {
            enabled: !!historicAuditId,
        }
    );

export default useHistoricAuditActions;