import React from "react";
import {
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import CustomRadioButton from "./CustomRadioButton";
import { userDetailOptions } from "../../constants/manageUserOptions";

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    margin: theme.spacing(2, 0),
  },
  lastDivider: {
    width: "100%",
    margin: theme.spacing(2, 0, 4, 0),
  },
  formTitle: {
    fontSize: 14,
    fontWeight: 600,
  },
}));

const EMAIL = "Email";

const UserDetails = ({ data, onChange, displayError, isExistingUser }) => {
  const classes = useStyles();

  const handleChange = (value, key) => onChange({ ...data, [key]: value });

  return (
    <Grid spacing={2} container>
      {userDetailOptions.map((option, index, { length }) => (
        <React.Fragment key={option.title}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.formTitle}>
              {option.title}
            </Typography>
          </Grid>
          {option.choices.map((choice) =>
            !option?.isRadioChoice ? (
              <Grid item xs={12} md={6} key={choice.label}>
                <TextField
                  required={choice.isRequired}
                  size="small"
                  label={choice.label}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  value={data[choice.key]}
                  onChange={(e) => handleChange(e.target.value, choice.key)}
                  error={
                    choice.isRequired &&
                    displayError &&
                    !data[choice.key].length
                  }
                  helperText={
                    choice.isRequired && displayError && !data.firstName.length
                      ? choice.errorMsg
                      : ""
                  }
                  disabled={choice.label === EMAIL && isExistingUser}
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={6} key={choice.label}>
                <CustomRadioButton
                  title={choice.label}
                  description={choice.description}
                  value={choice.value}
                  checked={data[choice.key] === choice.value}
                  onChange={(e) => handleChange(e, choice.key)}
                />
              </Grid>
            )
          )}
          <Grid item xs={12}>
            <Divider
              className={
                length - 1 !== index ? classes.divider : classes.lastDivider
              }
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default UserDetails;
