import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useTracking } from "react-tracking";
import CardBase from "../../../components/cards/CardBase";
import { Link, useRouteMatch } from "react-router-dom";
import RiskAssessmentAdminTemplatesTableCard from "../../components/RiskAssessmentAdminTemplatesTableCard";
import PageBase from "../../../components/PageBase";
import PageTitle from "../../components/PageTitle";
import { Create } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { useAuth } from "../../../contexts/authContext";
import RequiresRoleWrapper from "../../../components/RequiresRoleWrapper";

function RiskAssessmentsAdmin() {
  const { Track } = useTracking({
    page: `Admin: Risk Assessments`,
  });
  const match = useRouteMatch();
  const { selectedAccount } = useSelector((state) => state.account);
  const { isTemplateAdminForSites, isWorknestUser } = useAuth();
  const { path } = match;

  const isTemplateAdmin = isTemplateAdminForSites(
    selectedAccount?.childExternalIds
  );

  return (
    <RequiresRoleWrapper hasRole={isTemplateAdmin || isWorknestUser}>
      <PageBase>
        <Track>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardBase
                title={
                  <PageTitle icon={<Create />} title="Risk Assessments Setup" />
                }
                rightComponent={
                  <Button
                    component={Link}
                    to={`${path}/templatebuilder`}
                    variant="contained"
                    color="primary"
                  >
                    Create Template
                  </Button>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <RiskAssessmentAdminTemplatesTableCard />
            </Grid>
          </Grid>
        </Track>
      </PageBase>
    </RequiresRoleWrapper>
  );
}

export default RiskAssessmentsAdmin;
