import React from "react";
import clsx from "clsx";
import { Chip, makeStyles } from "@material-ui/core";
import { green, grey, red } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  root: {
    color: "white",
    borderRadius: "4px",
    "&.status-0": {
      backgroundColor: grey[400],
    },
    "&.status-1": {
      backgroundColor: red[400],
    },
    "&.status-2": {
      backgroundColor: green[400],
    },
  },
}));

function StatusChip({ status, statusName }) {
  const classes = useStyles();

  if (status === null || statusName === null) return null;

  return (
    <Chip
      size="small"
      className={clsx(classes.root, `status-${status}`)}
      label={statusName}
    />
  );
}

export default StatusChip;
