import React, { useMemo, useCallback } from "react";
import { Collapse, Divider, List, makeStyles } from "@material-ui/core";
import { Phone as PhoneIcon } from "@material-ui/icons";
import SidebarCategory from "./SidebarCategory";
import SidebarLink from "./SidebarLink";
import NavLinkWrapper from "./NavLinkWrapper";
import { useLocation } from "react-router-dom";
import {
    baseRoute as safetynestBaseRoute,
    menuItems as safetynestMenuItems,
} from "../../safetynest/routes";
import useExpandedCategories from "../../hooks/useExpandedCategories";
import { useAuth } from "../../contexts/authContext";
import useIsMobile from "../../hooks/useIsMobile";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    navigationContainer: {
        flexGrow: 1,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        overflowY: "auto",
    },
    divider: {
        background: "rgba(255, 255, 255, 0.12)",
    },
}));

const contactPhoneNumber = "0345 226 8393";

function DrawerMenuItems() {
    const classes = useStyles();
    const location = useLocation();
    const { expandedCategories, toggleCategory } = useExpandedCategories();
    const { isWorknestUser, hasAnySitesRoles, user } = useAuth();
    const isMobile = useIsMobile();
    const { selectedAccount } = useSelector((state) => state.account);

    const userAccessPermissionModules =
        user?.details?.userAccessPermission &&
        safetynestMenuItems
            .filter((module) =>
                !module.hiddenFromClientUser &&
                    !module?.userAccessPermission
                    ? true
                    : user?.details?.userAccessPermission[module.userAccessPermission] ===
                    true
            )
            .map((menuItem) =>
                !menuItem?.children
                    ? menuItem
                    : {
                        ...menuItem,
                        children: menuItem?.children?.filter(
                            (nestedModule) =>
                                user?.details?.userAccessPermission[
                                nestedModule.userAccessPermission
                                ] === true
                        ),
                    }
            );

    const filteredByRouteRoles = useCallback((route) =>
        !route.requiredSiteRoles || hasAnySitesRoles(selectedAccount?.childExternalIds, route.requiredSiteRoles
        ), [hasAnySitesRoles, selectedAccount?.childExternalIds]);

    const menuItemsForPath = useMemo(() => {
        if (location.pathname.startsWith(safetynestBaseRoute))
            return isWorknestUser
                ? safetynestMenuItems.filter((x) => !x.hiddenFromWorkNestUser)
                : userAccessPermissionModules?.filter(filteredByRouteRoles);

        return [];
    }, [filteredByRouteRoles, isWorknestUser, location.pathname, userAccessPermissionModules]);

    return (
        <div className={classes.navigationContainer}>
            <List disablePadding>
                <div>
                    {menuItemsForPath?.map((category, index) => (
                        <React.Fragment key={index}>
                            {category.children ? (
                                <React.Fragment key={index}>
                                    <SidebarCategory
                                        isOpen={expandedCategories.includes(index)}
                                        isCollapsable
                                        name={category.label}
                                        icon={category.icon}
                                        button
                                        disableRipple
                                        onClick={() => toggleCategory(index)}
                                    />
                                    <Collapse
                                        in={expandedCategories.includes(index)}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        {category.children.map((route, subIndex) => (
                                            <SidebarLink
                                                key={subIndex}
                                                name={route.label}
                                                to={category.path + route.path}
                                                icon={route.icon}
                                                badge={route.badge}
                                            />
                                        ))}
                                    </Collapse>
                                </React.Fragment>
                            ) : (
                                <SidebarCategory
                                    name={category.label}
                                    to={category.path}
                                    activeClassName="active"
                                    component={NavLinkWrapper}
                                    icon={category.icon}
                                    exact
                                    badge={category.badge}
                                />
                            )}
                        </React.Fragment>
                    ))}
                    {isMobile && (
                        <>
                            <Divider className={classes.divider} />
                            <SidebarCategory
                                name={`Need Help? Call: ${contactPhoneNumber}`}
                                activeClassName="active"
                                component="a"
                                icon={PhoneIcon}
                                exact
                                href={`tel:${contactPhoneNumber}`}
                            />
                        </>
                    )}
                </div>
            </List>
        </div>
    );
}

export default DrawerMenuItems;
