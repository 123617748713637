import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import useDeleteRiskAssessment from "../../../hooks/mutations/useDeleteRiskAssessment";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    margin: theme.spacing(3),
  },
  dialogText: {
    color: theme.palette.primary.main,
  },
  deleteBtn: {
    background: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark),
  },
}));

const RiskAssessmentDeleteDialog = ({
  isOpen,
  setIsOpen,
  riskAssessment,
  pageNum,
  pageSize,
  riskLevel,
}) => {
  const classes = useStyles();
  const deleteRiskAssessment = useDeleteRiskAssessment();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updateData = {
      riskAssessmentId: riskAssessment.id,
      siteExternalId: riskAssessment.siteExternalId, 
      pageNum,
      pageSize,
      riskLevel,
    };
    deleteRiskAssessment.mutate(updateData, {
      onSuccess: () => handleClose(),
      onError: (error) => {
        console.error(error);
        enqueueSnackbar(
          error?.message || "There was an error deleting the risk assessment",
          {
            variant: "error",
          }
        );
      },
    });
  };

  const getDialogContent = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item>
            <DialogContent>
              <DialogContentText
                className={classes.dialogText}
                id="alert-dialog-description"
              >
                This action cannot be undone
              </DialogContentText>
            </DialogContent>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle>Delete Risk Assessment?</DialogTitle>
      <form onSubmit={handleSubmit}>
        <div className={classes.dialogContainer}>{getDialogContent()}</div>
        <DialogActions>
          {deleteRiskAssessment.isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                className={classes.deleteBtn}
              >
                Delete
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RiskAssessmentDeleteDialog;
