import axios from "../../utils/axios";

const apiUrl = process.env.REACT_APP_MYWORKNEST_API_URL;

const searchAccountsByName = (accountName) => 
    axios.get(
      `${apiUrl}/account/search?accountName=${accountName}&safetyNestOnly=true`
    );

const accountService = {
    searchAccountsByName,
};

export default accountService;