const manageUserConstants = {
  ACTIVE: "active",
  FORMER: "former",
};

const manageUserTabs = {
  USER_DETAILS: {
    label: "User Details",
    value: "1",
  },
  ACCESS: {
    label: "Access",
    value: "2",
  },
  PERMISSIONS: {
    label: "Permissions",
    value: "3",
  },
  ALERTS: {
    label: "Alerts",
    value: "4",
  },
};

const tabMenuLabels = [
  manageUserTabs.USER_DETAILS.label,
  manageUserTabs.ACCESS.label,
  manageUserTabs.PERMISSIONS.label,
  manageUserTabs.ALERTS.label,
];

const permissionConstants = {
  CASENEST_ALL_CASES: "caseNestAllCases",
  MY_ACTIONS: "myActions",
  MY_CASES: "myCases",
  MYWORKNEST: "myWorkNest",
  CASENEST: "CaseNest",
  SAFETYNEST: "SafetyNest",
  SAFETYNESTINCIDENTLOGGING: "safetyNestIncidentLogging",
};

const alertConstants = {
  INCIDENT_WITH_INVESTIGATION_LOGGED: "incidentWithInvestigationLogged",
  ACTIONS: "Actions",
  AUDITS: "Audits",
  COMPLIANCE_FORMS: "Compliance Forms",
  INCIDENTS: "Incidents",
  MONITORING_FORMS: "Monitoring Forms",
  IS_DAILY: "isDaily",
  DAILY: "daily",
  WEEKLY: "weekly",
};

const permissionSalesforceNames = {
  myWorkNestAdministrator: "MyWorkNest Administrator",
  caseNestAdministrator: "Casenest Administrator",
  caseNestReceiveAdvice: "Casenest Receive Advice",
  caseNestAllCases: "Casenest All Cases",
  safetyNestAdministrator: "Safetynest Administrator",
  safetyNestAmendTemplates: "Safetynest Amend Templates",
  safetyNestCompleteIncidentInvestigations: "Safetynest Complete Incident Investigations",
  safetyNestEditCompletedActions: "Safetynest Edit Completed Actions",
  safetyNestAllActions: "Safetynest All Actions",
  safetyNestIncidentLogging: "Safetynest Incident Logging",
};

const caseNestAlertConstants = {
  IS_DAILY: "isDaily",
  CLOSED_CASES: "closedCases",
  HIGH_RISK_CASES: "highRiskCases",
  CASE_TYPES: "caseTypeIds",
  RECEIVE_NOTIFICATIONS: "receiveNotifications",
};

const alertFrequencyConstants = {
  NONE: 0,
  DAILY: 1,
  WEEKLY: 2,
};

const alertFrequencyType = {
  ADMIN: 0,
  PERSONAL: 1,
};

export {
  manageUserConstants,
  manageUserTabs,
  tabMenuLabels,
  permissionConstants,
  alertConstants,
  permissionSalesforceNames,
  caseNestAlertConstants,
  alertFrequencyConstants,
  alertFrequencyType,
};
