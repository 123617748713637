import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Dialog, DialogContent, DialogActions, Button, Typography, Box } from '@material-ui/core';
import logoImage from '../../../safetynestLogo.png';

export default function QRCodeDialog({ open, onClose, image, headerText, subText, fileName }) {
    const canvasRef = useRef(null);

    const base64String = useMemo(() => {
        if (image && image.length > 0) {
            return `data:image/png;base64,${image}`;
        }
    }, [image]);

    const drawCanvas = useCallback(async () => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const wrapText = (ctx, text, x, y, maxWidth, lineHeight) => {
            const words = text.split(' ');
            let line = '';
            let lines = [];

            for (let n = 0; n < words.length; n++) {
                const testLine = line + words[n] + ' ';
                const metrics = ctx.measureText(testLine);
                const testWidth = metrics.width;
                if (testWidth > maxWidth && n > 0) {
                    lines.push(line);
                    line = words[n] + ' ';
                } else {
                    line = testLine;
                }
            }
            lines.push(line);
            for (let i = 0; i < lines.length; i++) {
                ctx.fillText(lines[i], x, y + (i * lineHeight));
            }
            return lines.length * lineHeight; // Return total height of wrapped text
        };

        try {
            const qrImage = await new Promise((resolve, reject) => {
                const img = new Image();
                img.src = base64String;
                img.onload = () => resolve(img);
                img.onerror = (err) => reject(err);
            });

            // Temporarily draw the text to measure height
            ctx.font = 'bold 24px Arial';
            const maxWidth = canvas.width - 20;
            const headerLineHeight = 28;
            const headerHeight = wrapText(ctx, headerText, canvas.width / 2, 0, maxWidth, headerLineHeight);

            ctx.font = '16px Arial';
            const subLineHeight = 20;
            const subHeight = wrapText(ctx, subText, canvas.width / 2, 0, maxWidth, subLineHeight);

            // Dynamically resize the canvas based on text height
            const qrImageHeight = 400;
            const totalHeight = 600 + headerHeight + subHeight + 20;
            canvas.height = totalHeight; // Set canvas height based on text

            // Clear and redraw everything after canvas resizing
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Draw logo
            const logo = new Image();
            logo.src = logoImage;
            ctx.drawImage(logo, (canvas.width - 170) / 2, 20, 170, 65);

            // Draw QR code image
            ctx.drawImage(qrImage, 0, 100, canvas.width, qrImageHeight);

            // Draw header text
            ctx.font = 'bold 24px Arial';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            wrapText(ctx, headerText, canvas.width / 2, 520, maxWidth, headerLineHeight);

            // Draw subtext
            ctx.font = '16px Arial';
            wrapText(ctx, subText, canvas.width / 2, 520 + headerHeight + 10, maxWidth, subLineHeight);
        } catch (error) {
            console.error('Failed to load image.', error);
        }
    }, [base64String, headerText, subText]);

    useEffect(() => {
        if (open && base64String && canvasRef.current) {
            drawCanvas();
        }
    }, [open, drawCanvas, base64String]);

    const handleDownload = async () => {
        await drawCanvas();
        const canvas = canvasRef.current;
        if (!canvas) return;
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = fileName;
        link.click();
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <canvas ref={canvasRef} width={400} height={600} style={{ display: 'none' }} />
                    <img
                        src={logoImage}
                        alt="logo"
                        style={{ width: 170, height: 65, marginBottom: 10 }}
                    />
                    <img
                        src={base64String}
                        alt="QR Code"
                        style={{ width: 300, height: 300, marginBottom: 16 }}
                    />
                    <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '24px' }}>
                        {headerText}
                    </Typography>
                    <Typography style={{ textAlign: 'center' }}>
                        {subText}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDownload} color="primary" title="download">
                    <GetAppIcon />
                </Button>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}