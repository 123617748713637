import React from "react";
import {
    Box, 
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
	useMediaQuery,
    useTheme,

} from "@material-ui/core";
import { useSelector } from "react-redux";
import useArchiveTemplateForm from "../../../../safetynest/hooks/mutations/useArchiveTemplateForm";
import {useSnackbar} from "notistack";


const TemplateFormArchiveDialog = ({ isOpen, setIsOpen, form, page, rows, moduleArea, handleSnackbarAction }) => {

    const archiveTemplateForm = useArchiveTemplateForm();

    const { selectedSite, activeAppId, appSiteIds } = useSelector(
		(state) => state.account
    );

    const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
		setIsOpen(false);
	};

    const handleSubmit = async e => {
        e.preventDefault();
        const updateData = {
            templateFormId: form.id,
            externalIds: selectedSite
                ? [selectedSite.externalId]
                : appSiteIds[activeAppId],
            page: page + 1,
            rows: rows,
            moduleArea,
            isActive: form.isActive,

        };
        archiveTemplateForm.mutate(updateData, {
            onSuccess: () => {
                enqueueSnackbar("Form Archived",
                    {
                        variant: "success",
                        action: (
                            <Button color="inherit" size="small" onClick={() => handleSnackbarAction(form.isActive)}>
                                View
                            </Button>
                            ),
                    });
                handleClose();
            },
        
            onError: (error) => {
                console.error(error);
                enqueueSnackbar(error?.message || "There was an error archiving the template form",
                    {
                        variant: "error"
                    });
            },
        });
    }

    const getDialogContent = () => {
            return(
                <>
                    <Grid container spacing={2}>
                        <Grid item>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {form?.isActive ? "Form will no longer be in use and related notifications will be suspended." :
                                        "Form will become active again and related notifications will be re-enabled."}
                                </DialogContentText>
                            </DialogContent>

                        </Grid>
                    </Grid>
                </>
            );
    }

    return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			fullScreen={fullScreen}
		>
            <DialogTitle>{form?.isActive ? "Archive Form" : "Unarchive Form"}</DialogTitle>
			<form onSubmit={handleSubmit}>
				<Box m={3}>
					{getDialogContent()}
				</Box>
                <DialogActions>
                    {archiveTemplateForm.isLoading ?
						<CircularProgress /> :
						<>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
                            <Button variant="contained" type="submit" color="primary">  {form?.isActive ? "Archive" : "Unarchive"}</Button>
						</>}
				</DialogActions>
			</form>
        </Dialog>
    );
}

export default TemplateFormArchiveDialog;