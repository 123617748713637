export const injuryTypeList = [
  { value: 0, label: "Amputation" },
  { value: 1, label: "Loss of sight" },
  { value: 2, label: "Fracture" },
  { value: 3, label: "Dislocation without fracture" },
  { value: 4, label: "Concussion and/or internal injuries" },
  { value: 5, label: "Lacerations and open wounds" },
  { value: 6, label: "Contusions and bruising" },
  { value: 7, label: "Burns" },
  { value: 8, label: "Asphyxia or poisonings" },
  { value: 9, label: "Strains and sprains" },
  { value: 10, label: "Superficial injuries" },
  { value: 11, label: "Natural causes" },
  { value: 12, label: "Electric shock" },
  { value: 13, label: "Multiple injuries" },
  { value: 14, label: "Other not known" },
  { value: 15, label: "Other known injuries" },
];
