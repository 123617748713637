import React, { useMemo } from "react";
import {
  alpha,
  Checkbox,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import CustomRadioButton from "./CustomRadioButton";
import BreadboxCheckbox from "./BreadboxCheckbox";
import {
  alertConstants,
  caseNestAlertConstants,
} from "../../constants/manageUserConstants";
import { Autocomplete } from "@material-ui/lab";
import useCaseTypes from "../../hooks/queries/useCaseTypes";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    margin: theme.spacing(2, 0),
  },
  lastDivider: {
    width: "100%",
    margin: theme.spacing(2, 0, 4, 0),
  },
  componentTitle: {
    fontSize: 26,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: 600,
  },
  checkboxContainer: {
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
    },
  },
  description: {
    fontSize: 12,
    color: alpha(theme.palette.primary.main, 0.6),
  },
  dropdown: {
    marginBottom: theme.spacing(2),
  },
}));

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const CaseNestAlerts = ({ data, onChange }) => {
  const classes = useStyles();
  const caseTypes = useCaseTypes();

  const keyedCaseTypes = useMemo(() => {
    if (!caseTypes?.data) return {};
    return _.keyBy(caseTypes.data, (x) => x.caseTypeId);
  }, [caseTypes]);

  const handleChange = (value, key) => {
    if (key === caseNestAlertConstants.IS_DAILY) {
      return onChange({ ...data, [key]: value === alertConstants.DAILY });
    }
    return onChange({ ...data, [key]: value });
  };

  return (
    <Grid spacing={2} container>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.componentTitle}>
          CaseNest
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          color="primary"
          variant="body2"
          className={classes.sectionTitle}
        >
          CaseNest Alerts
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} className={classes.checkboxContainer}>
        <BreadboxCheckbox
          title="Alert Summary Email"
          description="Receive an email containing a summary of all of your CaseNest alerts."
          checked={data[caseNestAlertConstants.RECEIVE_NOTIFICATIONS] ?? false}
          onChange={(e) =>
            handleChange(e, caseNestAlertConstants.RECEIVE_NOTIFICATIONS)
          }
          hasSmallerLabel={true}
        />
      </Grid>
      {data[caseNestAlertConstants.RECEIVE_NOTIFICATIONS] && (
        <>
          <Grid item xs={12}>
            <Typography
              color="primary"
              variant="body2"
              className={classes.sectionTitle}
            >
              Frequency
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.description}>
              Choose how often you would like to receive the Alert Summary
              email.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomRadioButton
              title="Daily"
              checked={data[caseNestAlertConstants.IS_DAILY]}
              value={alertConstants.DAILY}
              onChange={(value) =>
                handleChange(value, caseNestAlertConstants.IS_DAILY)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomRadioButton
              title="Weekly"
              checked={!data[caseNestAlertConstants.IS_DAILY]}
              value={alertConstants.WEEKLY}
              onChange={(value) =>
                handleChange(value, caseNestAlertConstants.IS_DAILY)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              color="primary"
              variant="body2"
              className={classes.sectionTitle}
            >
              Case Status
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.checkboxContainer}>
            <BreadboxCheckbox
              title="Closed"
              description="Receive an alert when a case is closed."
              onChange={(value) =>
                handleChange(value, caseNestAlertConstants.CLOSED_CASES)
              }
              checked={data[caseNestAlertConstants.CLOSED_CASES]}
              hasSmallerLabel
            />
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              color="primary"
              variant="body2"
              className={classes.sectionTitle}
            >
              Risk Factors
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.checkboxContainer}>
            <BreadboxCheckbox
              title="Protected Characteristics"
              description="Receive an alert when a case is raised for an employee with protected characteristics."
              onChange={(value) =>
                handleChange(value, caseNestAlertConstants.HIGH_RISK_CASES)
              }
              checked={data[caseNestAlertConstants.HIGH_RISK_CASES]}
              hasSmallerLabel
            />
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              color="primary"
              variant="body2"
              className={classes.sectionTitle}
            >
              Case Type
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              className={classes.dropdown}
              multiple
              size="small"
              value={data.caseTypeIds}
              onChange={(e, values) =>
                handleChange(values, caseNestAlertConstants.CASE_TYPES)
              }
              options={caseTypes?.data?.map((x) => x.caseTypeId) || []}
              disableCloseOnSelect
              getOptionLabel={(id) => keyedCaseTypes[id]?.name}
              renderOption={(id, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={uncheckedIcon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {keyedCaseTypes[id]?.name}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  label="Case Types"
                  fullWidth
                />
              )}
            />
            <Typography variant="body2" className={classes.description}>
              Receive an alert when a case with a specific case type is raised.
            </Typography>
          </Grid>
        </>
      )}
      <Divider className={classes.lastDivider} />
    </Grid>
  );
};

export default CaseNestAlerts;
