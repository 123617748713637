import {
    Box,
    Button,
    CircularProgress,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Divider
} from "@material-ui/core";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import CardBase from "../../../components/cards/CardBase";
import PageBase from "../../../components/PageBase";
import { formatShortDate } from "../../../utils/dateTimeUtils";
import moduleAreaConstants, { moduleUrls } from "../../constants/moduleAreaConstants";
import { useRiskAssessmentDetails } from "../../contexts/RiskAssessmentContext";
import { baseRoute } from "../../routes";
import useContactsForAppAndSite from "../../../hooks/useContactsForAppAndSite";
import ArchiveRiskAssessmentHistoryDialog from "../../components/riskassessments/dialogs/ArchiveRiskAssessmentHistoryDialog";
import { MoreVert, DeleteOutline, GetApp, Send, Edit, History } from "@material-ui/icons";
import riskAssessmentHistoryService from "../../services/riskAssessmentHistoryService";
import { useSnackbar } from "notistack";
import { downloadFileFromBlob } from "../../../utils/fileUtils";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import { getComparator, stableSort } from "../../../utils/tableUtils";
import { tableSortingOrder } from "../../../constants/tableConstants";
import useRiskAssessmentHistoryAcknowledgementRecipients from "../../hooks/queries/useRiskAssessmentHistoryAcknowledgementRecipients";
import useShareRiskAssessmentHistory from "../../hooks/mutations/useShareRiskAssessmentHistory";
import ShareRiskAssessmentDialog from "../../components/dialogs/ShareRiskAssessmentDialog";
import RiskAssessmentVersionHistoryDialog from "../../components/riskassessments/dialogs/RiskAssessmentVersionHistoryDialog";
import RiskAssessmentHistoryAmendDialog from "../../components/riskassessments/dialogs/RiskAssessmentHistoryAmendDialog";

const useStyles = makeStyles((theme) => ({
    overviewCard: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
    },
    titleCard: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
        },
    },
    datesWrapper: {
        display: "flex",
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            marginTop: 0,
        },
    },
    backButton: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "flex-end",
    },
    bullets: {
        display: "grid",
        gridTemplateColumns: "20% 80%",
        [theme.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
        },
        menuItem: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            display: "flex",
            gap: theme.spacing(1),
            fontSize: "0.875rem",
        },
    },
    popMenuIcon: {
        marginRight: theme.spacing(1.5),
    },
    popMenuIconDel: {
        marginRight: theme.spacing(1.5),
        color: "red"
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    menuItemTextDelete: {
        color: "red !important",
    },
}));

const headCells = [
    { label: "Date", id: "completedDate", isSortable: true },
    { label: "User", id: "completedBy", isSortable: true },
    { label: "Actions", id: "actions", isSortable: false },
    { label: "Admin", id: "admin", isSortable: false },
];

export default function ViewRiskAssessment() {
    const classes = useStyles();
    const history = useHistory();

    const [emailInput, setEmailInput] = useState("");
    const [emailList, setEmailList] = useState([]);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [riskAssessmentHistoryId, setRiskAssessmentHistoryId] = useState(null);
    const [isVersionHistoryOpen, setIsVersionHistoryOpen] = useState(false);
    const [isAmendHistoryOpen, setIsAmendHistoryOpen] = useState(false);

    const match = useRouteMatch();
    const { params } = match;
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogAnchorEl, setDialogAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [dialogMenuOpen, setDialogMenuOpen] = useState(false);
    const [archiveRiskAssessmentId, setArchiveRiskAssessmentId] = useState(null);
    const [isArchiveOpen, setIsArchiveOpen] = useState(false);
    const [isPdfLoading, setIsPdfLoading] = useState(false);
    const [order, setOrder] = useState(tableSortingOrder.asc);
    const [orderBy, setOrderBy] = useState(headCells[0].id);
    const { enqueueSnackbar } = useSnackbar();

    const openArchiveFormPopup = (e, form) => {
        e.stopPropagation();
        setArchiveRiskAssessmentId(form.id);
        setIsArchiveOpen(true);
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const riskAssessmentId = useMemo(() => {
        return parseInt(params.riskAssessmentId);
    }, [params]);

    const { returnToDashboard } = params;
    const { sites } = useSelector((state) => state.account);

    const contacts = useContactsForAppAndSite({ selectedSiteId: null });

    const { data: riskAssessmentHistoryAcknowledgementRecipients, remove } = useRiskAssessmentHistoryAcknowledgementRecipients(riskAssessmentHistoryId);
    const shareRiskAssessmentHistory = useShareRiskAssessmentHistory();
    const { riskAssessmentHistories, reset, setSelectedRiskAssessmentHistoryId, setSelectedRiskAssessmentVersionHistoryId, riskAssessmentVersionHistory, setIsEditMode } = useRiskAssessmentDetails();

    function shareRiskAssessmentHistoryInvite(emailAddresses, isReSend) {
        shareRiskAssessmentHistory.mutate(
            { riskAssessmentHistoryId, emailAddresses, isReSend },
            {
                onSuccess: () => {
                    setEmailInput("");
                    setEmailList([]);
                    setShareDialogOpen(false);
                    remove();
                    enqueueSnackbar("Risk assessment shared", { variant: "default" });
                },
                onError: () => {
                    enqueueSnackbar("There was an error sharing the risk assessment", { variant: "error" });
                },
            }
        );
    }

    function handleEmailListChange(e) {
        setEmailInput(e.target.value);
    }

    function openShareDialog(e, riskAssessmentHistory) {
        e.stopPropagation();
        setRiskAssessmentHistoryId(riskAssessmentHistory.id);
        setShareDialogOpen(true);
        setAnchorEl(null);
        setMenuOpen(false);
    }

    function closeShareDialog() {
        setShareDialogOpen(false);
        setEmailInput("");
        setEmailList([]);
        setRiskAssessmentHistoryId(null);
    }

    if (riskAssessmentHistories?.isLoading)
        return (
            <Box display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        );

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
        setMenuOpen(null);
    };

    const handleDialogMenuClose = (e) => {
        e.stopPropagation();
        setDialogAnchorEl(null);
        setDialogMenuOpen(null);
    };

    const handleClick = (e, id) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setMenuOpen(id);
    };

    const handleDialogMenuClick = (e, id) => {
        e.stopPropagation();
        setDialogAnchorEl(e.currentTarget);
        setDialogMenuOpen(id);
    };

    const handleDownloadRiskAssessmentPdf = async (e, riskAssessmentHistory) => {
        e.stopPropagation();
        setIsPdfLoading(true);

        try {
            const response = await riskAssessmentHistoryService.downloadRiskAssessmentPdf(riskAssessmentId, riskAssessmentHistory.id);
            const filename = `RiskAssessment - ${riskAssessmentHistory.id.toString()}`;
            const fileType = "application/pdf";

            downloadFileFromBlob(response, filename, fileType);
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Could not download risk assessment pdf", { variant: "error" });
        } finally {
            setIsPdfLoading(false);
            setMenuOpen(null);
            setAnchorEl(null);
        }
    };

    function openVersionHistoryDialog(e, riskAssessmentHistory) {
        e.stopPropagation();
        setSelectedRiskAssessmentVersionHistoryId(riskAssessmentHistory.id);
        setIsVersionHistoryOpen(true);
        setAnchorEl(null);
        setMenuOpen(false);
    }

    const handleEditAndNavigate = (e, x) => {
        history.push(`${baseRoute}/${moduleUrls[moduleAreaConstants.RISKASSESSMENTS]}/review/${e.id}`); // Navigate to the desired URL
        setIsEditMode(true);
    };

    const handleEditExisting = async (e, riskAssessmentHistory) => {
        e.stopPropagation();
        setIsAmendHistoryOpen(true);
        setSelectedRiskAssessmentHistoryId(riskAssessmentHistory.id);
        setAnchorEl(null);
        setMenuOpen(null);
    }

    const handleSortRequest = (_, property) => {
        const isAsc = orderBy === property && order === tableSortingOrder.asc;
        setOrder(isAsc ? tableSortingOrder.desc : tableSortingOrder.asc);
        setOrderBy(property);
    };

    const lastCompletedRiskAssessment = [...riskAssessmentHistories?.data?.riskAssessmentHistories]?.pop();

    return (
        <PageBase>
            <ShareRiskAssessmentDialog
                closeShareDialog={closeShareDialog}
                dialogAnchorEl={dialogAnchorEl}
                dialogMenuOpen={dialogMenuOpen}
                emailInput={emailInput}
                emailList={emailList}
                handleDialogMenuClick={handleDialogMenuClick}
                handleDialogMenuClose={handleDialogMenuClose}
                handleEmailListChange={handleEmailListChange}
                riskAssessmentHistoryAcknowledgementRecipients={riskAssessmentHistoryAcknowledgementRecipients}
                setEmailList={setEmailList}
                shareDialogOpen={shareDialogOpen}
                shareRiskAssessmentHistoryInvite={shareRiskAssessmentHistoryInvite}
            />
            <Paper className={classes.titleCard}>
                <div>
                    <Typography variant="h5">{riskAssessmentHistories?.data?.riskAssessment?.templateName}</Typography>
                </div>
                <div className={classes.datesWrapper}>
                    <div>
                        <Typography>
                            <strong>Due Date:</strong>{" "}
                            {riskAssessmentHistories?.data?.riskAssessment?.dueDate
                                ? formatShortDate(riskAssessmentHistories.data.riskAssessment.dueDate)
                                : "N/A"}
                        </Typography>
                    </div>
                    <div>
                        <Typography>
                            <strong>Last Completed:</strong>{" "}
                            {lastCompletedRiskAssessment?.completedDate ? formatShortDate(lastCompletedRiskAssessment.completedDate) : "N/A"}
                        </Typography>
                    </div>
                </div>
            </Paper>
            <Paper className={classes.overviewCard}>
                <Typography className={classes.bullets}>
                    <strong>Sector:</strong> {riskAssessmentHistories?.data?.riskAssessment?.sector}
                </Typography>
                <Typography className={classes.bullets}>
                    <strong>Site:</strong> {sites[riskAssessmentHistories?.data?.riskAssessment?.siteExternalId]?.name}
                </Typography>
                <Typography className={classes.bullets}>
                    <strong>Responsible:</strong>{" "}
                    {contacts?.data[riskAssessmentHistories?.data?.riskAssessment?.assignedEmployeeExternalId]
                        ? `${contacts?.data[riskAssessmentHistories.data.riskAssessment.assignedEmployeeExternalId]?.firstName} ${
                              contacts?.data[riskAssessmentHistories.data.riskAssessment.assignedEmployeeExternalId]?.lastName
                          }`
                        : ""}
                </Typography>
                <Typography className={classes.bullets}>
                    <strong>Start Date:</strong>{" "}
                    {riskAssessmentHistories?.data?.riskAssessment?.startDate ? formatShortDate(riskAssessmentHistories?.data?.riskAssessment?.startDate) : "N/A"}
                </Typography>
                <Typography className={classes.bullets}>
                    <strong>Next Review Date:</strong>{" "}
                    {riskAssessmentHistories?.data?.riskAssessment?.dueDate ? formatShortDate(riskAssessmentHistories?.data?.riskAssessment?.dueDate) : "N/A"}
                </Typography>
                <Typography className={classes.bullets}>
                    <strong>Frequency:</strong> {riskAssessmentHistories?.data?.riskAssessment?.frequencyDescription}
                </Typography>
                <Typography className={classes.bullets}>
                    <strong>Description:</strong> {riskAssessmentHistories?.data?.riskAssessment?.description}
                </Typography>
            </Paper>
            <CardBase
                title="History"
                isLoading={riskAssessmentHistories?.isLoading}
                error={riskAssessmentHistories?.error}
                rightComponent={
                    <Button
                        onClick={(e) => setIsEditMode(false)}
                        component={Link}
                        to={`${baseRoute}/${moduleUrls[moduleAreaConstants.RISKASSESSMENTS]}/review/${riskAssessmentId}`}
                        variant="contained"
                        color="primary"
                    >
                        Create New Version
                    </Button>
                }
            >
                <TableContainer>
                    <Table>
                        <EnhancedTableHead
                            headCells={headCells}
                            sortDirection={order}
                            sortColumn={orderBy}
                            onRequestSort={handleSortRequest}
                        />
                        <TableBody>
                            {riskAssessmentHistories?.data?.riskAssessmentHistories?.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={3}>This risk assessment has not been previously completed.</TableCell>
                                </TableRow>
                            )}
                            {stableSort(riskAssessmentHistories?.data?.riskAssessmentHistories, getComparator(order, orderBy)).map((x) => (
                                <TableRow key={x.id}>
                                    <TableCell>{x?.completedDate ? formatShortDate(x.completedDate) : "N/A"}</TableCell>
                                    <TableCell>
                                        {contacts?.data[x?.completedByEmployeeExternalId]
                                            ? `${contacts?.data[x.completedByEmployeeExternalId]?.firstName} ${
                                                  contacts?.data[x.completedByEmployeeExternalId]?.lastName
                                              }`
                                            : ""}
                                    </TableCell>
                                    <TableCell>{`${x.completeActionsCount}/${x.totalActionsCount}`}</TableCell>
                                    <TableCell>
                                        {isPdfLoading && menuOpen === x.id ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <>
                                                <IconButton onClick={(e) => handleClick(e, x.id)}>
                                                    <MoreVert />
                                                </IconButton>
                                                <Menu keepMounted open={menuOpen === x.id} onClose={handleClose} anchorEl={anchorEl}>
                                                    <MenuItem onClick={(e) => handleDownloadRiskAssessmentPdf(e, x)}>
                                                        <GetApp className={classes.popMenuIcon} /> Download
                                                    </MenuItem>
                                                    <MenuItem onClick={(e) => openShareDialog(e, x)}>
                                                        <Send className={classes.popMenuIcon} /> Share
                                                    </MenuItem>
                                                        {x.id === lastCompletedRiskAssessment?.id && (
                                                            <MenuItem
                                                                onClick={(e) => handleEditExisting(e, x)}
                                                            >
                                                                <Edit className={classes.popMenuIcon} /> Edit
                                                            </MenuItem>)}
                                                    <MenuItem onClick={(e) => openVersionHistoryDialog(e, x)}>
                                                            <History className={classes.popMenuIcon} /> Version History
                                                        </MenuItem>
                                                        <Divider className={classes.divider} />
                                                        <MenuItem
                                                            onClick={(e) => openArchiveFormPopup(e, x)}>
                                                            <DeleteOutline className={classes.popMenuIconDel} />
                                                            <Typography className={classes.menuItemTextDelete}>
                                                                Delete
                                                            </Typography>
                                                    </MenuItem>
                                                </Menu>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.backButton}>
                    <Button
                        onClick={() => {
                            reset();
                            returnToDashboard ? history.push(`${baseRoute}`) : history.push(`${baseRoute}/${moduleUrls[moduleAreaConstants.RISKASSESSMENTS]}`);
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Back
                    </Button>
                </div>
                <ArchiveRiskAssessmentHistoryDialog
                    isOpen={isArchiveOpen}
                    setIsOpen={setIsArchiveOpen}
                    riskAssessmentId={riskAssessmentId}
                    archiveRiskAssessmentId={archiveRiskAssessmentId}
                />
                <RiskAssessmentVersionHistoryDialog
                    isOpen={isVersionHistoryOpen}
                    setIsOpen={setIsVersionHistoryOpen}
                    versionHistory={riskAssessmentVersionHistory} 
                />
                <RiskAssessmentHistoryAmendDialog
                    isOpen={isAmendHistoryOpen}
                    setIsOpen={setIsAmendHistoryOpen}
                    riskAssessment={lastCompletedRiskAssessment} 
                    handleEditAndNavigate={handleEditAndNavigate}
                />
            </CardBase>
        </PageBase>
    );
}
