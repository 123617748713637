import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import incidentService from "../../services/incidentService";

const useDaysSinceIncidentForSites = ({ externalIds }) =>
  useQuery(
    [queryKeys.daysSinceIncident, externalIds?.join(",")],
    () => incidentService.fetchDaysSinceIncidentForSites({ externalIds }),
    { enabled: externalIds?.length > 0 }
  );

export default useDaysSinceIncidentForSites;
