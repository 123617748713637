import { useMutation } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import historicAuditService from "../../services/historicAuditService";
import reactQueryUtils from "../../../utils/reactQueryUtils";

const { client } = reactQueryUtils

const useCreateHistoricAudit = () => 
    useMutation(
        (historicAudit) => historicAuditService.create(historicAudit, historicAudit.siteExternalId),
        {
            onMutate: async ({ siteExternalId, ...historicAudit }) => {
                const queryKey = [queryKeys.siteHistoricAudits, siteExternalId];

                await client.cancelQueries(queryKey);

                const previousHistoricAudit = client.getQueryData(queryKey);

                client.setQueryData(queryKey, (old) =>
                    old ? [...old, historicAudit] : [historicAudit]
                );
                
                return { previousHistoricAudit, queryKey };
            },
            onError: (_err, _newDocument, context) => {
                if (!context) return;

                client.setQueryData(context.queryKey, context.previousHistoricAudit);
            },
            onSettled: () => {
                client.invalidateQueries(queryKeys.siteHistoricAudits);
            }
        }
    )
export default useCreateHistoricAudit;