import { Container, makeStyles } from "@material-ui/core";
import PageBase from "../components/PageBase";
import { useRouteMatch } from "react-router-dom";
import useAcknowledgementDetails from "../hooks/queries/useAcknowledgementDetails";
import { useEffect, useState } from "react";
import useVerifiedAcknowledgement from "../hooks/queries/useVerifiedAcknowledgement";
import { formatShortMonthDateAndTime } from "../utils/dateTimeUtils";
import useAcknowledgeDocument from "../hooks/mutations/useAcknowledgeDocument";
import { useSnackbar } from "notistack";
import myWorkNestLogo from "../images/myWorkNest_Purple_Positive_RGB.svg";
import safetyNestLogo from "../images/SafetyNest_Purple_Positive_RGB.svg";
import AcknowledgementToolbar from "../components/acknowledgements/AcknowledgementToolbar";
import DismissableToolbar from "../components/DismissableToolbar";
import AcknowledgementDialog from "../components/acknowledgements/AcknowledgeDialog";
import VerifyEmailDialog from "../components/acknowledgements/VerifyEmailDialog";
import DocumentViewer from "../components/acknowledgements/DocumentViewer";
import FixedButton from "../components/FixedButton";
import ConfirmDetails from "../components/acknowledgements/ConfirmDetails";
import AccessDenied from "../components/acknowledgements/AccessDenied";
import { validEmailRegex } from "../utils/regexUtils";
import { Check } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    marginBottom: {
        marginBottom: theme.spacing(8),
        marginTop: theme.spacing(8),
    },
    check: {
        marginRight: theme.spacing(1),
    },
}));

export default function Acknowledge() {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const match = useRouteMatch();
    const { params } = match;
    const { reference } = params;

    const [email, setEmail] = useState("");
    const [requiresVerification, setRequiresVerification] = useState(true);
    const [isUnauthorized, setIsUnauthorized] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [readyToVerify, setReadyToVerify] = useState(false);
    const [dismissedReview, setDismissedReview] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [dismissedToolbar, setDismissedToolbar] = useState(false);

    const acknowledge = useAcknowledgeDocument();
    const { data: acknowledgementDetails } = useAcknowledgementDetails({ reference });
    const { data: verifiedAcknowledgement, refetch } = useVerifiedAcknowledgement({
        emailAddress: email,
        acknowledgementId: acknowledgementDetails?.id,
        readyToVerify,
    });

    useEffect(() => {
        if (verifiedAcknowledgement === false) {
            setIsUnauthorized(true);
        }
    }, [verifiedAcknowledgement]);

    let logoUrl = myWorkNestLogo;

    switch (acknowledgementDetails?.application) {
        case 0:
            logoUrl = safetyNestLogo;
            break;
        default:
            logoUrl = myWorkNestLogo;
            break;
    }

    function verifyEmail() {
        if (!email.match(validEmailRegex)) {
            setInvalidEmail(true);
        } else {
            setInvalidEmail(false);
            setRequiresVerification(false);
            setReadyToVerify(true);
            refetch();
        }
    }

    function confirmReview() {
        acknowledge.mutate(
            { acknowledgementRecipientId: verifiedAcknowledgement?.acknowledgementRecipient?.id },
            {
                onSuccess: () => {
                    refetch();
                    setConfirmDialogOpen(false);
                    enqueueSnackbar("Review confirmed", { variant: "default" });
                },
                onError: () => {
                    enqueueSnackbar("There was an error confirming your review", { variant: "error" });
                },
            }
        );
    }

    function openConfirmDialog() {
        setConfirmDialogOpen(true);
        setDismissedReview(true);
    }

    function reVerifyEmail() {
        setRequiresVerification(true);
        setIsUnauthorized(false);
        setReadyToVerify(false);
        setEmail("");
    }

    return (
        <>
            <AcknowledgementToolbar logoUrl={logoUrl} />
            {!dismissedToolbar && !isUnauthorized && !requiresVerification && (
                <>
                    {verifiedAcknowledgement?.acknowledgementRecipient?.status === 2 && (
                        <DismissableToolbar
                            onDismiss={() => setDismissedToolbar(true)}
                            title="Review Confirmed"
                            subtitle={
                                verifiedAcknowledgement?.acknowledgementRecipient?.acknowledgedDate &&
                                formatShortMonthDateAndTime(verifiedAcknowledgement?.acknowledgementRecipient?.acknowledgedDate)
                            }
                        />
                    )}
                    {acknowledgementDetails?.isExpired && verifiedAcknowledgement?.acknowledgementRecipient?.status !== 2 && (
                        <DismissableToolbar
                            onDismiss={() => setDismissedToolbar(true)}
                            title="Document Expired"
                            subtitle="A newer version of this document now exists."
                        />
                    )}
                </>
            )}
            <PageBase>
                <AcknowledgementDialog open={confirmDialogOpen} onConfirm={confirmReview} onCancel={() => setConfirmDialogOpen(false)} />
                <VerifyEmailDialog
                    open={requiresVerification}
                    onVerify={verifyEmail}
                    onCancel={() => {
                        setRequiresVerification(false);
                        setIsUnauthorized(true);
                    }}
                    email={email}
                    setEmail={setEmail}
                    invalidEmail={invalidEmail}
                />
                <Container maxWidth="md" className={classes.marginBottom}>
                    {!isUnauthorized && !requiresVerification && (
                        <>
                            <DocumentViewer fileType={verifiedAcknowledgement?.file?.contentType} fileContents={verifiedAcknowledgement?.file?.fileContents} />
                            {verifiedAcknowledgement?.acknowledgementRecipient?.status !== 2 && dismissedReview && (
                                <FixedButton onClick={openConfirmDialog} text="Confirm Review" icon={<Check className={classes.check} />} />
                            )}
                            {verifiedAcknowledgement?.acknowledgementRecipient?.status !== 2 && !dismissedReview && !acknowledgementDetails?.isExpired && (
                                <ConfirmDetails onConfirm={openConfirmDialog} onDismiss={() => setDismissedReview(true)} />
                            )}
                        </>
                    )}
                    {isUnauthorized && !requiresVerification && <AccessDenied verify={reVerifyEmail} />}
                </Container>
            </PageBase>
        </>
    );
}
