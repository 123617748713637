import React from "react";
import { Button, makeStyles, Paper, Typography } from "@material-ui/core";
import classNames from "classnames";
import layoutConstants from "../../constants/layoutConstants";
import { grey } from "@material-ui/core/colors";

const totalLayoutSpacing =
  layoutConstants.contentPaddingMultiplier * 8 +
  layoutConstants.homePageSectionsPaddingMultiplier * 8 +
  (layoutConstants.tilesGridSpacingMultiplier / 2) * 8 +
  layoutConstants.featureTileWidth;

const useStyles = makeStyles((theme) => ({
  card: {
    color: ({ bgImageUrl }) => (bgImageUrl ? "#fff" : "inherit"),
    position: "relative",
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    minHeight: `${layoutConstants.featureTileWidth}px`,
    transition: "0.2s all ease-out",
    "&:hover": {
      backgroundColor: grey[200],
      transform: "scale(1.03)",
    },
    gridColumnEnd: "span 1",
    "& .MuiButton-label": {
      flexDirection: "column",
      alignItems: "unset",
      justifyContent: "unset",
      height: "100%",
    },
  },
  spanTwo: {
    [`@media (min-width:${totalLayoutSpacing * 2}px)`]: {
      gridColumnEnd: "span 2",
    },
  },
  spanThree: {
    [`@media (min-width:${totalLayoutSpacing * 3}px)`]: {
      gridColumnEnd: "span 3",
    },
  },
  bgImage: {
    backgroundImage: ({ bgImageUrl }) => `url(${bgImageUrl})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  bgImageOverlay: {
    backgroundColor: "rgba(83,62,145,0.7)",
    position: "absolute",
    borderRadius: "4px",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  title: {
    fontWeight: 600,
    textTransform: "none",
    textAlign: "left",
    marginBottom: theme.spacing(2),
    color: ({ bgImageUrl }) =>
      bgImageUrl ? theme.palette.primary.main : "inherit",
  },
  description: {
    textTransform: "none",
    textAlign: "left",
    color: ({ bgImageUrl }) =>
      bgImageUrl ? theme.palette.primary.main : "inherit",
  },
  textSecondary: {
    color: theme.palette.secondary.main,
    display: "block",
  },
  bodyWrapper: {
    zIndex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    gap: "16px",
  },
}));

const SpannableTile = ({
  title = null,
  titleHighlight = null,
  description = null,
  descriptionHighlight = null,
  icon: Icon = null,
  bgImageUrl = null,
  spanCols = 1,
  onClick = () => {},
}) => {
  const classes = useStyles({ bgImageUrl });
  const cardClasses = classNames(classes.card, {
    [classes.spanTwo]: spanCols >= 2,
    [classes.spanThree]: spanCols >= 3,
    [classes.bgImage]: !!bgImageUrl,
  });

  const cardBody = (
    <div className={classes.bodyWrapper}>
      <div className={classes.contentWrapper}>
        {title && (
          <Typography className={classes.title} variant="h5">
            {title}
            {titleHighlight && (
              <div className={classes.textSecondary}>{titleHighlight}</div>
            )}
          </Typography>
        )}
        {description && (
          <Typography className={classes.description}>
            {description}
            {descriptionHighlight && (
              <span className={classes.textSecondary}>
                {descriptionHighlight}
              </span>
            )}
          </Typography>
        )}
      </div>
      {Icon && <Icon style={{ fontSize: 48 }} />}
    </div>
  );

  return (
    <Paper component={Button} className={cardClasses} onClick={onClick}>
      {cardBody}
    </Paper>
  );
};

export default SpannableTile;
