import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import incidentService from "../../services/incidentService";

const useIncidentsForSites = ({ externalIds, pageNum, numPerPage, sortOrder, orderByColumn, fromDate, toDate, assignees, filteredText, filteredPersonText, riddorReportable, incidentCategoryId, }) =>
  useQuery(
    [
      queryKeys.incidents,
          externalIds?.join(","),
          pageNum, numPerPage, sortOrder, orderByColumn, fromDate, toDate, riddorReportable, incidentCategoryId,
          filteredText && filteredText.length > 3 ? filteredText : undefined, assignees, filteredPersonText && filteredPersonText.length > 3 ? filteredPersonText : undefined,
    ],
    () =>
      incidentService.fetchIncidentsForSites({
        externalIds,
        pageNum,
        numPerPage,
        sortOrder,
        orderByColumn,
        fromDate,
        toDate,
        filteredText: filteredText && filteredText.length > 3 ? filteredText : undefined,
        assignees,
        filteredPersonText: filteredPersonText && filteredPersonText.length > 3 ? filteredPersonText : undefined,
        riddorReportable,
        incidentCategoryId,
      }),
    {
      enabled: !!externalIds?.length > 0,
    }
  );

export default useIncidentsForSites;
