import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Menu, MenuItem } from "@material-ui/core"
import { useHistoricAuditContext } from "../../contexts/HistoricAuditContext"
import useHistoricAuditActions from "../../hooks/queries/useHistoricAuditActions"
import { formatShortDate } from "../../../utils/dateTimeUtils"
import { MoreVert } from "@material-ui/icons"
import { useState } from "react"
import { useHistoricActionContext } from "../../contexts/HistoricActionContext"

const tableColumns = [
    'Action ID',
    'Description',
    'Due Date',
    'Rating',
    'Notes',
    ''
]

export default function HistoricAuditActionsTable() {
    const { 
        selectedAudit, 
        isCreationMode,
    } = useHistoricAuditContext()

    const {
        setHistoricActionToEdit,
        setEditHistoricActionDialogOpen,
        setDescription,
        setDueDate,
        setRating,
        setNotes,
        setAzureFile,
        setHistoricActionIdToDelete,
        setDeleteHistoricActionDialogOpen,
    } = useHistoricActionContext()

    const historicAuditActions = useHistoricAuditActions(selectedAudit?.id)
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpenOnId, setMenuOpenOnId] = useState(null);

    function handleClick(e, id) {
        setAnchorEl(e.currentTarget)
        setMenuOpenOnId(id)
    }

    function handleClose() {
        setAnchorEl(null)
        setMenuOpenOnId(null)
    }

    function handleEditClick(action) {
        setHistoricActionToEdit(action)
        setDescription(action.description)
        setDueDate(action.dueDate)
        setRating(action.rating)
        setNotes(action.notes)
        setAzureFile(action.azureFiles && action.azureFiles.length > 0 ? action.azureFiles[action.azureFiles.length - 1] : null)
        setEditHistoricActionDialogOpen(true)
        setAnchorEl(null)
        setMenuOpenOnId(null)
    }

    function handleDeleteClick(action) {
        setHistoricActionIdToDelete(action.id)
        setDeleteHistoricActionDialogOpen(true)
        setAnchorEl(null)
        setMenuOpenOnId(null)
    }

    function tableContent() {
        if (isCreationMode) {
            return (
                <TableRow>
                    <TableCell colSpan={tableColumns.length}>
                        Please save the audit before adding actions.
                    </TableCell>
                </TableRow>
            )
        }

        if (historicAuditActions?.isLoading) {
            return (
                <TableRow>
                    <TableCell colSpan={tableColumns.length}>
                        Loading...
                    </TableCell>
                </TableRow>
            )
        }

        if (historicAuditActions?.data?.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={tableColumns.length}>
                        No actions found
                    </TableCell>
                </TableRow>
            )
        }

        return historicAuditActions?.data?.map(action => (
            <TableRow key={action.id}>
                <TableCell>
                    {action.id}
                </TableCell>
                <TableCell>
                    {action.description}
                </TableCell>
                <TableCell>
                    {formatShortDate(action.dueDate)}
                </TableCell>
                <TableCell>
                    {action.ratingDescription}
                </TableCell>
                <TableCell>
                    {action.notes}
                </TableCell>
                <TableCell align="right">
                    <IconButton
                        onClick={(e) => handleClick(e, action.id)} 
                    >
                        <MoreVert />                        
                    </IconButton>
                    <Menu
                        keepMounted
                        open={menuOpenOnId === action.id}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                    >
                        <MenuItem
                            onClick={() => handleEditClick(action)}
                        >
                            Edit
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleDeleteClick(action)}
                        >
                            Delete
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
        ))
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableColumns.map(column => (
                            <TableCell key={column}>
                                {column}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableContent()}
                </TableBody>
            </Table>
        </TableContainer>
    )
}