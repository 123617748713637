import { SET_IS_TUTORIAL_OPEN, SET_TUTORIAL } from "../types";

const initialState = {
  isTutorialOpen: false,
  tutorial: null,
};

function tutorialReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_TUTORIAL_OPEN:
      return {
        ...state,
        isTutorialOpen: action.payload.isOpen,
      };
    case SET_TUTORIAL:
      return {
        ...state,
        tutorial: action.payload.tutorial,
      };

    default:
      return state;
  }
}

export default tutorialReducer;
