import { Button, Divider, makeStyles } from "@material-ui/core"
import CardBase from "../../../components/cards/CardBase"
import { useHistoricAuditContext } from "../../contexts/HistoricAuditContext"
import { useHistory } from "react-router-dom"
import { useSnackbar } from "notistack"
import Details from "../../components/historicaudits/Details"
import AuditReport from "../../components/historicaudits/AuditReport"
import HistoricAuditActionsTable from "../../components/historicaudits/HistoricAuditActionsTable"
import useCreateHistoricAudit from "../../hooks/mutations/useCreateHistoricAudit"
import { useSiteSearchContext } from "../../contexts/SiteSearchContext"

const useStyles = makeStyles(theme => ({
    auditId: {
        marginBottom: theme.spacing(2)
    },
    form: {
        marginTop: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr'
        }
    },
    columnSpan: {
        gridColumn: '1 / 3',
        [theme.breakpoints.down('sm')]: {
            gridColumn: '1 / 1'
        }
    },
    rightAlignedButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        gridColumn: '1 / 3',
        [theme.breakpoints.down('sm')]: {
            gridColumn: '1 / 1'
        }
    },
}))

export default function CreateHistoricAudit() {
    const classes = useStyles()

    const history = useHistory()

    const { 
        historicAuditDetails, 
        reset,
        setIsPDFError,
    } = useHistoricAuditContext()

    const { selectedSite } = useSiteSearchContext()

    const createHistoricAudit = useCreateHistoricAudit()
    
    const { enqueueSnackbar } = useSnackbar()

    function handleCreateHistoricAudit(e) {
        e.preventDefault()
        
        if (!historicAuditDetails.document) {
            setIsPDFError(true)
            return
        } 

        createHistoricAudit.mutate({
            ...historicAuditDetails,
            siteExternalId: selectedSite?.externalId
        })
        
        returnToAudits()
        enqueueSnackbar('Historic Audit created successfully', { variant: 'success' })
    }

    function handleCancel() {
        returnToAudits()
    }

    function returnToAudits() {
        reset()
        history.goBack()
    }

    function divider() {
        return (
            <div className={classes.columnSpan}>
                <Divider />
            </div>
        )
    }
    
    return (
        <CardBase>
            <form 
                className={classes.form}
                onSubmit={(e) => handleCreateHistoricAudit(e)}
                onReset={handleCancel}
            >
                <Details />
                {divider()}
                <AuditReport />
                {divider()}
                <HistoricAuditActionsTable />
                <div className={classes.rightAlignedButtons}>
                    <Button
                        variant="outlined"
                        color="primary"
                        type="reset"
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                </div>
            </form>
        </CardBase>
    )
}