import { userAccessPermissionConstant } from "../../constants/userAccessPermissionConstants";
import {
  Beenhere,
  Create,
  Favorite,
  People,
  Visibility,
} from "@material-ui/icons";

const moduleAreas = {
  COMPLIANCE: 0,
  INCIDENTS: 1,
  MONITORING: 2,
  RISKASSESSMENTS: 3,
  AUDIT: 4,
};

export const moduleUrls = {
  [moduleAreas.COMPLIANCE]: "compliance",
  [moduleAreas.INCIDENTS]: "incidents",
  [moduleAreas.MONITORING]: "monitoring",
  [moduleAreas.RISKASSESSMENTS]: "riskassessments",
  [moduleAreas.AUDIT]: "audit",
};

export const moduleIcons = {
  AUDIT: <People />,
  COMPLIANCE: <Beenhere />,
  INCIDENTS: <Favorite />,
  MONITORING: <Visibility />,
  RISKASSESSMENTS: <Create />,
};

export const moduleIconForEnum = {
  0: moduleIcons.COMPLIANCE,
  1: moduleIcons.INCIDENTS,
  2: moduleIcons.MONITORING,
  3: moduleIcons.RISKASSESSMENTS,
  4: moduleIcons.AUDIT,
};

export const moduleAreaDropdownValues = [
  {
    value: moduleAreas.COMPLIANCE,
    label: "Compliance",
    userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_COMPLIANCE,
  },
  {
    value: moduleAreas.MONITORING,
    label: "Monitoring",
    userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_MONITORING,
  },
  {
    value: moduleAreas.INCIDENTS,
    label: "Incidents",
    userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_INCIDENTS,
  },
  {
    value: moduleAreas.RISKASSESSMENTS,
    label: "Risk Assessments",
    userAccessPermission:
      userAccessPermissionConstant.CAN_ACCESS_RISKASSESSMENTS,
  },
  {
    value: moduleAreas.AUDIT,
    label: "Audits",
    userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_AUDITS,
  },
];

export const moduleAreaNames = {
  COMPLIANCE: "Compliance",
  INCIDENTS: "Incidents",
  MONITORING: "Monitoring",
  RISKASSESSMENTS: "Risk Assessments",
  AUDIT: "Audit",
};

export const moduleFileLabels = {
  [moduleAreas.COMPLIANCE]: "Compliance",
  [moduleAreas.INCIDENTS]: "Incidents",
  [moduleAreas.MONITORING]: "Monitoring",
  [moduleAreas.RISKASSESSMENTS]: "RiskAssessments",
  [moduleAreas.AUDIT]: "Audit",
};

export const formsModuleAreaDropdownValues = [
    {
        value: moduleAreas.COMPLIANCE,
        label: "Compliance",
        userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_COMPLIANCE,
    },
    {
        value: moduleAreas.MONITORING,
        label: "Monitoring",
        userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_MONITORING,
    },
    {
        value: moduleAreas.RISKASSESSMENTS,
        label: "Risk Assessments",
        userAccessPermission:
            userAccessPermissionConstant.CAN_ACCESS_RISKASSESSMENTS,
    },
];

export default moduleAreas;
