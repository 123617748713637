import React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router";
import { Box, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import DocumentViewer from "../components/documents/DocumentViewer";
import CardBase from "../components/cards/CardBase";
import { documentTypes } from "../constants/documentConstants";
import useDocuments from "../hooks/queries/useDocuments";
import PageBase from "../components/PageBase";

const Documents = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { params } = match;
  const { documentTypeValue } = params;
  const documentType = Object.values(documentTypes).filter(
    (t) => t.value === parseInt(documentTypeValue)
  )[0];

  const { selectedAccount } = useSelector(
    (state) => state.account
  );
  
  const { data, isLoading, isError, error } = useDocuments({
    documentType: documentType.value,
    accountExternalIds: [ ...(selectedAccount?.childExternalIds || []), selectedAccount?.account.externalId],
  });
  

  return (
    <PageBase>
      <CardBase
        title="Document Manager"
        isLoading={isLoading}
        isError={isError}
        error={error}
      >
        <Box>
          <Box mb={2}>
            <IconButton
              data-testid="documents-back"
              onClick={() => history.push("/documents")}
            >
              <ArrowBack />
            </IconButton>
          </Box>
          <DocumentViewer
            documentType={documentType}
            data={data}
          />
        </Box>
      </CardBase>
    </PageBase>
  );
};

export default Documents;
