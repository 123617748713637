import { Dialog, Button, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { useHistoricActionContext } from '../../../contexts/HistoricActionContext';
import { useSnackbar } from 'notistack';
import useUpdateHistoricAction from '../../../hooks/mutations/useUpdateHistoricAction';
import { useHistoricAuditContext } from '../../../contexts/HistoricAuditContext';
import ActionForm from '../ActionForm';

export default function EditHistoricActionDialog({ open, onCancel }) {
    const { enqueueSnackbar } = useSnackbar()

    const { 
        historicActionDetails, 
        historicActionToEdit,
        reset,
    } = useHistoricActionContext()

    const { selectedAudit } = useHistoricAuditContext()

    function handleCancel() {
        reset()
        onCancel()
    }

    const updateHistoricAction = useUpdateHistoricAction()

    function handleUpdateHistoricAction(e) {
        e.preventDefault()

        updateHistoricAction.mutate({
            ...historicActionDetails,
            historicActionId: historicActionToEdit?.id,
            historicAuditId: selectedAudit?.id,
        })
        
        reset()
        onCancel()

        enqueueSnackbar('Historic Action updated successfully', { variant: 'success' })
    }

    return (
        <Dialog
            open={open}
            onClose={onCancel}
        >  
            <form
                onSubmit={(e) => handleUpdateHistoricAction(e)}
                onReset={handleCancel}
            >
                <DialogTitle>{historicActionToEdit?.id}</DialogTitle>
                <DialogContent>
                    <ActionForm />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onCancel}
                        color="secondary"
                        variant="text"
                    >
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        color="primary"
                        variant="text"
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}