import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  templateTypeChip: {
    color: "white",
    borderRadius: "4px",
    "&.Custom": {
      backgroundColor: orange[400],
    },
    "&.WorkNest": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  wrapper: {
    display: "flex",
    gap: theme.spacing(1),
    flexWrap: "wrap",
    alignItems: "center",
  },
}));

function TemplateNameCell({ isWorkNest, name }) {
  const classes = useStyles();
  const label = isWorkNest ? "WorkNest" : "Custom";
  return (
    <div className={classes.wrapper}>
      {name}
      <Chip
        size="small"
        className={clsx(classes.templateTypeChip, label)}
        label={label}
      />
    </div>
  );
}

export default TemplateNameCell;
