const acknowledgementStatus = {
    Sent: 0,
    Read: 1,
    Acknowledged: 2,
}

const AcknowledgementStatusDescription = {
    [acknowledgementStatus.Sent]: "Sent",
    [acknowledgementStatus.Read]: "Read",
    [acknowledgementStatus.Acknowledged]: "Acknowledged",
}

export const getAcknowledgementStatusDescription = (acknowledgementStatus) => AcknowledgementStatusDescription[acknowledgementStatus];