import React from "react";
import {
  alpha,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { alertFrequencyOptions } from "../../constants/manageUserOptions";

const useStyles = makeStyles((theme) => ({
  checkboxDescription: {
    fontSize: 12,
    color: alpha(theme.palette.primary.main, 0.6),
    marginTop: theme.spacing(1),
  },
  componentSubtitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  subComponent: {
    marginBottom: theme.spacing(2),
  },
  radioLabel: {
    fontSize: 13,
    fontWeight: 600,
  },
}));

const AlertFrequency = ({
  title,
  description,
  subDescription,
  value,
  handleChange,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.subComponent}>
        <Typography
          color="primary"
          variant="subtitle2"
          className={classes.componentSubtitle}
        >
          {title}
        </Typography>
        <Typography variant="body2" className={classes.checkboxDescription}>
          {description}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          color="primary"
          variant="body2"
          className={classes.sectionTitle}
        >
          Frequency
        </Typography>
        <Typography variant="body2" className={classes.checkboxDescription}>
          {subDescription}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl>
          <RadioGroup
            value={value}
            onChange={(e) => handleChange(parseInt(e.target.value))}
          >
            {alertFrequencyOptions.map((option) => (
              <FormControlLabel
                disableTypography
                key={option.value}
                control={<Radio />}
                label={
                  <Typography
                    color="primary"
                    variant="body2"
                    className={classes.radioLabel}
                  >
                    {option.label}
                  </Typography>
                }
                value={option.value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
};

export default AlertFrequency;
