export const reorder = (array, startIndex, endIndex) => {
  if (
    startIndex < 0 ||
    endIndex < 0 ||
    startIndex + 1 > array.length ||
    endIndex + 1 > array.length
  )
    return array;

  const modifiedArray = [...array];
  const [removed] = modifiedArray.splice(startIndex, 1);
  modifiedArray.splice(endIndex, 0, removed);
  return modifiedArray;
};
