import { Toolbar, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    appTitle: {
        display: "flex",
        alignItems: "center",
    },
    toolbar: {
        boxShadow: theme.shadows[1],
        height: "80px",
        padding: "0 24px",
    },
    logo: {
        height: "40px",
    },
}));

export default function AcknowledgementToolbar({ logoUrl }) {
    const classes = useStyles();

    return (
        <Toolbar className={classes.toolbar}>
            <div className={classes.appTitle}>
                <img className={classes.logo} alt="logo" src={logoUrl} />
            </div>
        </Toolbar>
    );
}
