import { filter } from '@prismicio/client'
import prismicUtils from "../utils/prismicUtils";
import stringUtils from "../utils/stringUtils";
import { RichText } from "prismic-reactjs";
import prismicContentTypes from "../constants/prismicContentTypes";

const itemsPerPage = 10;

const fetchCategories = async (sectionName) => {

    const prismicApi = await prismicUtils.getApiForSection(sectionName);

    const response = await prismicApi.get(
        {
            filters: [
                filter.missing("my.category.parent"),
                filter.not("document.tags", ["precedents"])
            ],
            pageSize: 100
        }
    );

    const { results } = response;
    const mappedResults = results
        .map((item) => ({
            id: item.id,
            title: item.data.title[0].text,
        }))
        .sort((a, b) => a.title.localeCompare(b.title));

    console.log({ method: 'fetchCategories', sectionName, mappedResults });

    return mappedResults;
};

const fetchCategoriesByParentId = async (sectionName, parentId) => {
    const prismicApi = await prismicUtils.getApiForSection(sectionName);
    const response = await prismicApi.get(
        {
            filters: [
                filter.at("my.category.parent", parentId),
                filter.not("document.tags", ["precedents"]),
            ],
            pageSize: 100
        }
    );

    const { results } = response;
    const mappedResults = results
        .map((item) => ({
            id: item.id,
            title: item.data.title[0].text,
        }))
        .sort((a, b) => a.title.localeCompare(b.title));

    console.log({ method: 'fetchCategoriesByParentId', sectionName, parentId, mappedResults });

    return mappedResults;
};

const fetchCategory = async (sectionName, categoryId) => {
    const prismicApi = await prismicUtils.getApiForSection(sectionName);
    const response = await prismicApi.getByID(categoryId);

    const mappedResponse = {
        id: response.id,
        title: response.data.title[0].text,
    };

    console.log({ method: 'fetchCategory', sectionName, categoryId, mappedResponse });
    return mappedResponse;
};

const fetchCategoryArticles = async (sectionName, categoryId, pageNumber, searchTerm) => {
    const ordering = "my.post.post_title";
    const predicate = "my.post.category";

    const prismicApi = await prismicUtils.getApiForSection(sectionName);

    const response = await prismicApi.get(
        {
            filters: searchTerm
                ? filter.fulltext(ordering, searchTerm)
                : filter.at(predicate, categoryId),
            pageSize: itemsPerPage,
            page: pageNumber,
            orderings: [{ field: ordering }],
        }
    );

    const pagedResponse = createPagedResponse(response);

    console.log({ method: 'fetchCategoryArticles', sectionName, categoryId, pageNumber, pagedResponse, searchTerm });
    return pagedResponse;
};

const fetchCategoryWebinars = async (sectionName, categoryId, pageNumber, searchTerm) => {
    const ordering = "my.webinar.title";
    const predicate = "my.webinar.category";

    const prismicApi = await prismicUtils.getApiForSection(sectionName);

    const response = await prismicApi.get(
        {
            filters: searchTerm
                ? filter.fulltext(ordering, searchTerm)
                : filter.at(predicate, categoryId),
            pageSize: itemsPerPage,
            page: pageNumber,
            orderings: [{ field: ordering }],
        }
    );

    const pagedResponse = createPagedResponse(response);

    console.log({ method: 'fetchCategoryWebinars', sectionName, categoryId, pageNumber, pagedResponse, searchTerm });

    return pagedResponse;
};

const fetchCategoryPrecedents = async (sectionName, categoryId, pageNumber, searchTerm) => {
    const ordering = "my.precedent.title";
    const predicate = "my.precedent.category";

    const prismicApi = await prismicUtils.getApiForSection(sectionName);

    const response = await prismicApi.get(
        {
            filters: searchTerm
                ? filter.fulltext(ordering, searchTerm)
                : filter.at(predicate, categoryId),
            pageSize: itemsPerPage,
            page: pageNumber,
            orderings: [{ field: ordering }],
        }
    );

    const pagedResponse = createPagedResponse(response);

    console.log({ method: 'fetchCategoryPrecedents', sectionName, categoryId, pageNumber, pagedResponse, searchTerm });
    return pagedResponse;
};

const fetchCategoryDownloads = async (sectionName, categoryId, pageNumber, searchTerm) => {
    const ordering = "my.download.title";
    const predicate = "my.download.category";

    const prismicApi = await prismicUtils.getApiForSection(sectionName);

    const response = await prismicApi.get(
        {
            filters: searchTerm
                ? filter.fulltext(ordering, searchTerm)
                : filter.at(predicate, categoryId),
            pageSize: itemsPerPage,
            page: pageNumber,
            orderings: [{ field: ordering }],
        }       
    );

    const pagedResponse = createPagedResponse(response);

    console.log({ method: 'fetchCategoryDownloads', sectionName, categoryId, pageNumber, pagedResponse, searchTerm });
    return pagedResponse;
};

const fetchContentItemById = async (sectionName, contentItemId) => {
    const prismicApi = await prismicUtils.getApiForSection(sectionName);

    const response = await prismicApi.getByID(contentItemId);

    const mappedResponse = extractItemData(response);

    console.log({ method: 'fetchContentItemById', sectionName, contentItemId, mappedResponse });
    return mappedResponse;
};

const createPagedResponse = (response) => {
    const mappedResults = response.results.map(extractItemData);

    const mappedResponse = {
        results: mappedResults,
        totalPages: response.total_pages,
        totalResults: response.total_results_size,
    };

    return mappedResponse;
};

const extractItemData = (item) => {
    switch (item.type) {
        case "post": {
            const fullExcerpt = RichText.asText(
                item.data?.body[0]?.primary.content_editor
            );
            return {
                id: item.id,
                title: RichText.asText(item.data.post_title),
                excerpt: stringUtils.trimTextToLength(fullExcerpt),
                content: item.data?.body[0]?.primary.content_editor,
                type: prismicContentTypes.article,
            };
        }
        case "webinar": {
            const fullExcerpt = RichText.asText(item.data?.intro_text);
            return {
                id: item.id,
                title: RichText.asText(item.data.title),
                excerpt: stringUtils.trimTextToLength(fullExcerpt),
                content: item.data.body,
                embed: item.data.video,
                type: prismicContentTypes.webinar,
                introText: item.data.intro_text,
                downloadUrl: item.data?.attachment?.url,
            };
        }
        case "precedent": {
            const fullExcerpt = RichText.asText(
                item.data?.body[0]?.primary?.content_editor
            );

            return {
                id: item.id,
                title: RichText.asText(item.data.title),
                excerpt: stringUtils.trimTextToLength(fullExcerpt),
                content: item.data?.body[0]?.primary?.content_editor,
                type: prismicContentTypes.precedent,
                downloadUrl: item.data?.attachment?.url,
            };
        }
        case "download": {
            return {
                id: item.id,
                title: RichText.asText(item.data.title),
                previewImageUrl: item.data.file_preview.url,
                downloadUrl: item.data.upload_media.url,
                type: prismicContentTypes.download,
            };
        }
        case "page": {
            return {
                id: item.id,
                title: item.data.page.title.value,
                bodyContent: item.data?.page.body.value[0]["non-repeat"].content.value,
            };
        }
        default:
            throw new Error(`Unrecognised prismic content type: ${item.type}`);
    }
};

const prismicService = {
    fetchCategory,
    fetchCategories,
    fetchCategoriesByParentId,
    fetchCategoryArticles,
    fetchCategoryWebinars,
    fetchCategoryPrecedents,
    fetchCategoryDownloads,
    fetchContentItemById,
};

export default prismicService;
