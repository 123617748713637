import { Typography, makeStyles, alpha } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  errorMessageContainer: {
    width: "100%",
    background: alpha(theme.palette.error.light, 0.08),
    borderRadius: 4,
    padding: theme.spacing(1),
  },
}));

export const CherryError = ({ message }) => {
  const classes = useStyles();
  return (
    <div className={classes.errorMessageContainer}>
      <Typography variant="body2" color="error" align="center">
        {message}
      </Typography>
    </div>
  );
};

export default CherryError;
