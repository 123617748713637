import { Divider, TextField, Typography, makeStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ResponsiveSelect from "../../../components/ui/ResponsiveSelect";
import ActionAttachment from "./ActionAttachment";
import { useHistoricActionContext } from "../../contexts/HistoricActionContext";
import stringUtils from "../../../utils/stringUtils";
import { ratingList } from "../../constants/actionRatingConstants";

const useStyles = makeStyles((theme) => ({
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}))

const formDateFormat = 'dd/MM/yyyy';
const MAX_DESCRIPTION = 250;

export default function ActionForm() {
    const classes = useStyles();

    const {
        historicActionDetails,
        setDescription,
        setDueDate,
        setRating,
        setNotes,
        setRequiresCreateValidation,
        requiresCreateValidation,
        isCreationMode,
    } = useHistoricActionContext()

    const showDescriptionError = () => {
        return (!isCreationMode && (!historicActionDetails?.description || stringUtils.isEmpty(historicActionDetails?.description))) || (isCreationMode && requiresCreateValidation && (!historicActionDetails?.description  || stringUtils.isEmpty(historicActionDetails?.description)))
    }

    const showDatePickerError = () => {
        return (!isCreationMode && !historicActionDetails?.dueDate) || (isCreationMode && requiresCreateValidation && !historicActionDetails?.dueDate)
    }

    const showRatingError = () => {
        return (!isCreationMode && (historicActionDetails?.rating === null || historicActionDetails?.rating === undefined)) || (isCreationMode && requiresCreateValidation && (historicActionDetails?.rating === null || historicActionDetails?.rating === undefined))
    }

    return (
        <div className={classes.form}>
            <TextField 
                required                
                fullWidth
                inputProps={{ maxLength: MAX_DESCRIPTION }}
                onInvalid={() => setRequiresCreateValidation(true)}
                helperText='Please enter a description (250 characters max)'
                error={showDescriptionError()}
                FormHelperTextProps={{ hidden: !showDescriptionError() }}
                multiline
                minRows={3}
                value={historicActionDetails?.description}
                variant="outlined"
                label="Description"
                onChange={(e) => setDescription(e.target.value)}
            />
            <div className={classes.flex}>
                <KeyboardDatePicker 
                    required
                    fullWidth
                    onInvalid={() => setRequiresCreateValidation(true)}
                    error={showDatePickerError()}
                    helperText='Please enter a valid due date'
                    FormHelperTextProps={{ hidden: !showDatePickerError() }}
                    value={historicActionDetails?.dueDate}
                    inputVariant="outlined"
                    format={formDateFormat}
                    label="Due Date"
                    onChange={(date) => setDueDate(date)}
                />
                <ResponsiveSelect
                    required
                    fullWidth
                    onInvalid={() => setRequiresCreateValidation(true)}
                    error={showRatingError()}
                    helperText='Please select a rating'
                    FormHelperTextProps={{ hidden: !showRatingError() }}
                    value={historicActionDetails?.rating}
                    variant="outlined"
                    label="Rating"
                    options={ratingList}
                    optionValueKey="value"
                    optionLabelKey="label"
                    onChange={(e) => setRating(e.target.value)}
                />
            </div>
            <TextField 
                fullWidth
                multiline
                minRows={3}
                value={historicActionDetails?.notes}
                variant="outlined"
                label="Notes"
                onChange={(e) => setNotes(e.target.value)}
            />
            <Divider />
            <Typography variant="h6">
                Attachment
            </Typography>
            <ActionAttachment />
        </div>
    )
}