import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    subTitle: {
        padding: theme.spacing(3),
    },
    title: {
        padding: theme.spacing(0),
        margin: theme.spacing(0),
    },
    actions: {
        display: "flex",
        flexDirection: "row-reverse",
        gap: theme.spacing(1),
        margin: theme.spacing(3, 0, 2, 0),
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
}));

export default function VerifyEmailDialog({ open, onVerify, onCancel, email, setEmail, invalidEmail }) {
    const classes = useStyles();

    return (
        <Dialog open={open}>
            <div className={classes.subTitle}>
                <DialogTitle className={classes.title}>Verify Email Address</DialogTitle>
                <DialogContentText className={classes.title}>To access this document, please verify your email address</DialogContentText>
            </div>
            <DialogContent>
                <TextField
                    helperText={invalidEmail && "Please enter a valid email address."}
                    error={invalidEmail}
                    fullWidth
                    color="secondary"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    label="Email"
                />
                <div className={classes.actions}>
                    <Button fullWidth color="secondary" variant="contained" onClick={onVerify}>
                        Verify
                    </Button>
                    <Button onClick={onCancel} fullWidth variant="outlined" color="secondary">
                        Cancel
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}
