import { makeStyles } from "@material-ui/core";
import CardBase from "../../../components/cards/CardBase";
import OverallScore from "./OverallScore";
import { moduleIconForEnum } from "../../constants/moduleAreaConstants";
import clsx from "clsx";
import {
  bandColours,
} from "../../constants/safetynestScoreConstants";

const useStyles = makeStyles((theme) => ({
  scoreContainer: {
    marginBottom: theme.spacing(2),
  },
  scoreModuleArea: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  band: {
    backgroundColor: "#d9e4adcc",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    flexShrink: 0,
    "&.band-0": {
      backgroundColor: bandColours.AT_RISK,
      color: theme.palette.getContrastText(bandColours.AT_RISK),
    },
    "&.band-1": {
      backgroundColor: bandColours.REACTIVE,
      color: theme.palette.getContrastText(bandColours.REACTIVE),
    },
    "&.band-2": {
      backgroundColor: bandColours.DEVELOPING,
      color: theme.palette.getContrastText(bandColours.DEVELOPING),
    },
    "&.band-3": {
      backgroundColor: bandColours.PROACTIVE,
      color: theme.palette.getContrastText(bandColours.PROACTIVE),
    },
    "&.band-4": {
      backgroundColor: bandColours.ESTABLISHED,
      color: theme.palette.getContrastText(bandColours.ESTABLISHED),
    },
  },
  scoreText: {
    textAlign: "right",
    fontWeight: 700,
    flex: "0 0 30px",
  },
  scoreRow: {
    display: "flex",
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      gap: 0,
    },
  },
  scoreDetails: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "right",
    gap: "8px",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "left",
    },
  },
  scores: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

const ScoreRow = ({ moduleScore }) => {
  const classes = useStyles();
  const icon = moduleIconForEnum[moduleScore.applicationArea] ?? "N/A";
  const band = moduleScore.band;
  const hasBand = band !== undefined;

  return (
    <div className={classes.scoreRow}>
      <span className={classes.scoreModuleArea}>
        <span>{icon}</span>
        <span>{moduleScore.applicationAreaText}</span>
      </span>
      <div className={classes.scoreDetails}>
        {hasBand && (
          <div className={clsx(classes.band, `band-${band}`)} />
        )}
        <span className={classes.scoreText}>{moduleScore.scoreText}</span>
      </div>
    </div>
  );
};

export default function OverallScoreCompact({ overallScore }) {
  const classes = useStyles();

  return (
    <div className={classes.scoreContainer}>
      <CardBase title="SafetyNest Score">
        {overallScore?.data && (
          <div className={classes.scoreContainer}>
            <OverallScore scoreDetails={overallScore.data} compact />
          </div>
        )}
        {overallScore?.data && (
          <div className={classes.scores}>
            {overallScore?.data?.moduleScores?.map((moduleScore) => (
              <ScoreRow
                key={moduleScore.applicationArea}
                moduleScore={moduleScore}
              />
            ))}
          </div>
        )}
      </CardBase>
    </div>
  );
}
