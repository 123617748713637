import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import salesforceService from "../../services/salesforceService";

const useSiteUsers = ({ siteExternalIds }) =>
  useQuery(
    [queryKeys.siteUsers, siteExternalIds?.join(",")],
    () => salesforceService.getContactsForAccountIds(siteExternalIds),
    {
      enabled: !!siteExternalIds?.length && !!siteExternalIds[0],
      staleTime: Infinity,
    }
  );

export default useSiteUsers;
