import {
  SET_DATE_RESPONSE,
  SET_TEXT_RESPONSE,
  SET_NUMBER_RESPONSE,
  SET_OPTION_RESPONSE,
  SET_MULTISELECT_RESPONSE,
  CLEAR_MULTISELECT_RESPONSE,
  CLEAR_FORM,
  INIT_QUESTION_ACTIONS,
  SET_ACTION_DUE_DATE,
  SET_ACTION_ASSIGNED_TO_EMPLOYEE,
  SET_QUESTION_FILES,
  SET_EXISTING_RESPONSES,
  SET_QUESTION_ADDITIONAL_INFO,
  SET_ACTION_RATING,
} from "../types";
import { nanoid } from "nanoid";

const initialState = {
  questionResponses: {},
  uploads: {},
  actions: {},
};

function formCompleterReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EXISTING_RESPONSES: {
      const questionResponses = {};

      for (const response of action.payload.responses) {
        questionResponses[response.templateQuestionId] = {
          templateFormResponseId: response.templateFormResponseId,
          dateValue: response.dateValue,
          textValue: response.textValue,
          numberValue: parseFloat(response.numberValue),
          templateQuestionId: response.templateQuestionId,
          optionIds: response.optionIds,
          additionalInfo: response.additionalInfo,
          optionId: response.optionId,
          azureFiles: response.azureFiles,
        };
      }

      return {
        ...state,
        questionResponses,
      };
    }
    case SET_DATE_RESPONSE:
      return {
        ...state,
        questionResponses: {
          ...state.questionResponses,
          [action.payload.templateQuestionId]: {
            ...state.questionResponses[action.payload.templateQuestionId],
            dateValue: action.payload.value,
            templateQuestionId: action.payload.templateQuestionId,
          },
        },
      };
    case SET_TEXT_RESPONSE:
      return {
        ...state,
        questionResponses: {
          ...state.questionResponses,
          [action.payload.templateQuestionId]: {
            ...state.questionResponses[action.payload.templateQuestionId],
            textValue: action.payload.value,
            templateQuestionId: action.payload.templateQuestionId,
          },
        },
      };
    case SET_NUMBER_RESPONSE:
      return {
        ...state,
        questionResponses: {
          ...state.questionResponses,
          [action.payload.templateQuestionId]: {
            ...state.questionResponses[action.payload.templateQuestionId],
            numberValue: parseFloat(action.payload.value),
            templateQuestionId: action.payload.templateQuestionId,
          },
        },
      };
    case SET_OPTION_RESPONSE:
      return {
        ...state,
        questionResponses: {
          ...state.questionResponses,
          [action.payload.templateQuestionId]: {
            ...state.questionResponses[action.payload.templateQuestionId],
            optionId: action.payload.value,
            templateQuestionId: action.payload.templateQuestionId,
          },
        },
      };
    case SET_MULTISELECT_RESPONSE:
      return {
        ...state,
        questionResponses: {
          ...state.questionResponses,
          [action.payload.templateQuestionId]: {
            ...state.questionResponses[action.payload.templateQuestionId],
            optionIds: action.payload.values,
            templateQuestionId: action.payload.templateQuestionId,
          },
        },
          };
    case CLEAR_MULTISELECT_RESPONSE:
          const { [action.payload.templateQuestionId]: removedItem, ...rest } = state.questionResponses;
          return {
              ...state,
              questionResponses: rest,
          };
    case SET_QUESTION_ADDITIONAL_INFO: {
      return {
        ...state,
        questionResponses: {
          ...state.questionResponses,
          [action.payload.templateQuestionId]: {
            ...state.questionResponses[action.payload.templateQuestionId],
            templateQuestionId: action.payload.templateQuestionId,
            additionalInfo: action.payload.additionalInfo,
          },
        },
      };
    }
    case SET_QUESTION_FILES:
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [action.payload.templateQuestionId]: action.payload.files,
        },
      };
    case INIT_QUESTION_ACTIONS:
      return {
        ...state,
        actions: action.payload.reduce((curr, val) => {
          const id = nanoid(5);
          return {
            ...curr,
            [id]: { ...val, id },
          };
        }, {}),
      };
    case SET_ACTION_DUE_DATE:
      return {
        ...state,
        actions: {
          ...state.actions,
          [action.payload.actionId]: {
            ...state.actions[action.payload.actionId],
            dueDate: action.payload.dueDate,
          },
        },
      };
    case SET_ACTION_RATING:
      return {
        ...state,
        actions: {
          ...state.actions,
          [action.payload.actionId]: {
            ...state.actions[action.payload.actionId],
            rating: action.payload.rating,
          },
        },
      };
      case SET_ACTION_ASSIGNED_TO_EMPLOYEE:
          return {
              ...state,
              actions: {
                  ...state.actions,
                  [action.payload.actionId]: {
                      ...state.actions[action.payload.actionId],
                      employeeExternalId: action.payload.employeeExternalId,
                  },
              },
          };
    case CLEAR_FORM:
      return initialState;
    default:
      return state;
  }
}

export default formCompleterReducer;
