const sortOrder = {
    ASC: 0,
    DESC: 1,
};

export const sortOrderName = {
    ASC: 'asc',
    DESC: 'desc',
}

export default sortOrder