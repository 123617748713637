import { answerTypes } from "../constants/answerConstants"

const riskAssessmentMultiSelectOptions = [
    {
      option: 1,
      displayOrder: 0,
      requiresAdditionalInfo: false
    },
    {
      option: 2,
      displayOrder: 1,
      requiresAdditionalInfo: false
    },
    {
      option: 3,
      displayOrder: 2,
      requiresAdditionalInfo: false
    },
    {
      option: 4,
      displayOrder: 3,
      requiresAdditionalInfo: false
    },
    {
      option: 5,
      displayOrder: 4,
      requiresAdditionalInfo: false
    }
  ]
  
  export const riskAssessmentQuestions = [
    {
      text: 'Choose the hazard severity',
      displayOrder: 0,
      answerType: answerTypes.MULTISELECT,
      options: riskAssessmentMultiSelectOptions
    },
    {
      text: 'Choose the likelihood',
      displayOrder: 1,
      answerType: answerTypes.MULTISELECT,
      options: riskAssessmentMultiSelectOptions
    },
    {
      text: 'Choose the hazard severity',
      displayOrder: 2,
      answerType: answerTypes.MULTISELECT,
      options: riskAssessmentMultiSelectOptions
    },
    {
      text: 'Choose the likelihood',
      displayOrder: 3,
      answerType: answerTypes.MULTISELECT,
      options: riskAssessmentMultiSelectOptions
    }
  ]