import React from "react";
import {
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  setChoiceOutcomeOptionId,
  setOutcomeLogicOperator,
} from "../../../redux/actions/templateBuilderActions";
import { choiceOutcomeLogicOptions, multiselectOutcomeLogicOptions } from "../../../constants/outcomeLogicConstants";
import { answerTypes } from "../../constants/answerConstants";
import { useDispatch, useSelector } from "react-redux";
import { ratingList } from "../../constants/actionRatingConstants";

const useStyles = makeStyles(() => ({
  outcomeText: {
    fontWeight: "500",
  },
  outcomeDropdown: {
    backgroundColor: "white",
  },
}));

function ChoiceOutcomeItem({
  outcomeId,
  questionId,
  actionPresets,
  isLoadingActionPresets,
  isActionPresetsError,
  onActionPresetChange,
  onActionRatingChange,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { questions, options, choiceOutcomes } = useSelector(
    (state) => state.templateBuilder
  );

  const question = questions[questionId];
  const outcome = choiceOutcomes[outcomeId];

  const onLogicOperatorChange = (e) => {
    dispatch(
      setOutcomeLogicOperator({ outcomeId, logicOperator: e.target.value })
    );
    };

  const onOutcomeOptionIdChange = (e) => {
    dispatch(setChoiceOutcomeOptionId({ outcomeId, optionId: e.target.value }));
  };

  const handleActionPresetChange = (e) => {
    onActionPresetChange(e.target.value);
    };

    const handleActionRatingChange = (e) => {
        onActionRatingChange(e.target.value);
    };

  return (
    <>
      <Typography className={classes.outcomeText}>If response</Typography>
      <FormControl
        className={classes.outcomeDropdown}
        variant="outlined"
        size="small"
      >
        <Select
          SelectDisplayProps={{
            "data-testid": `choice-logic-operator-${outcomeId}`,
          }}
          value={outcome.logicType}
          onChange={onLogicOperatorChange}>
            {question.answerType === answerTypes.MULTISELECT?
                multiselectOutcomeLogicOptions.map((choice) => (
                    <MenuItem key={choice.value} value={choice.value}>
                        {choice.label}
                    </MenuItem>
                )) : choiceOutcomeLogicOptions.map((choice) => (
                    <MenuItem key={choice.value} value={choice.value}>
                        {choice.label}
                    </MenuItem>
                ))
            }
        </Select>
      </FormControl>
      <FormControl
        className={classes.outcomeDropdown}
        variant="outlined"
        size="small"
      >
        <Select
          SelectDisplayProps={{ "data-testid": `choice-value-${outcomeId}` }}
          value={outcome.templateQuestionOptionId}
          onChange={onOutcomeOptionIdChange}
        >
          {question.optionIds.map((optionId, optionIndex) => (
            <MenuItem key={optionIndex} value={options[optionId].id}>
              {options[optionId].option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography className={classes.outcomeText}>then</Typography>
      <FormControl
        className={classes.outcomeDropdown}
        variant="outlined"
        size="small"
        disabled={isLoadingActionPresets}
        error={isActionPresetsError}
      >
        <Select
          value={outcome.actionPresetId}
          onChange={handleActionPresetChange}
          SelectDisplayProps={{
            "data-testid": `choice-action-preset-select-${outcomeId}`,
          }}
        >
          {actionPresets &&
            actionPresets.map((preset) => (
              <MenuItem key={preset.id} value={preset.id}>
                {preset.description}
              </MenuItem>
            ))}
        </Select>
          </FormControl>
          <Typography className={classes.outcomeText}>with priority of</Typography>
          <FormControl
              required
              className={classes.outcomeDropdown}
              variant="outlined"
              size="small"
              disabled={isLoadingActionPresets}
              error={isActionPresetsError}
          >
              <Select
                  value={outcome.rating}
                  onChange={handleActionRatingChange}
                  SelectDisplayProps={{
                        "data-testid": `choice-rating-select-${outcomeId}`,
                  }}
              >
                  {ratingList.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                          {item.label}
                      </MenuItem>
                  ))}
              </Select>
          </FormControl>          
    </>
  );
}

export default ChoiceOutcomeItem;
