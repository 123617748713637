import {
  SET_TABLE_PAGE,
  SET_ACTIONS_TABLE_PAGE,
  SET_COMPLIANCE_MONITORING_ACTIONS_TABLE_PAGE,
  SET_RISK_ASSESSMENTS_TABLE_PAGE,
  SET_CASES_TABLE_PAGE,
  SET_CONTACT_CASES_TABLE_PAGE,
  SET_FORM_INSIGHTS_TABLE_PAGE,
  SET_FORMS_TABLE_PAGE,
  SET_INCIDENTS_TABLE_PAGE,
} from "../types";

export function setTablePage({ key, page, rowsPerPage, sortBy, orderBy, fromDate, toDate, filteredText, assignees, forms, formIds, showHistoric }) {
  return {
    type: SET_TABLE_PAGE,
    payload: {
      key,
      page,
      rowsPerPage,
      sortBy,
      orderBy,
      fromDate,
      toDate,
      filteredText,
      assignees,
      forms,
      formIds,
      showHistoric,
    },
  };
}

export function setCasesTablePage({
  key,
  page,
  rowsPerPage,
  displayAll,
  displayClosed,
  caseNumber,
  adviser,
  employee,
  description,
  primaryContact,
  caseType,
}) {
  return {
    type: SET_CASES_TABLE_PAGE,
    payload: {
      key,
      page,
      rowsPerPage,
      displayAll,
      displayClosed,
      caseNumber,
      adviser,
      employee,
      description,
      primaryContact,
      caseType,
    },
  };
}

export function setContactCasesTablePage({
  key,
  page,
  rowsPerPage,
  displayAll,
}) {
  return {
    type: SET_CONTACT_CASES_TABLE_PAGE,
    payload: {
      key,
      page,
      rowsPerPage,
      displayAll,
    },
  };
}

export function setActionsTablePage({
  key,
  page,
  rowsPerPage,
  module,
  forUser,
  showCompleted,
  fromDate,
  toDate,
  rating,
  sortOrder,
  orderBy,
  assignees,
  filteredText,
}) {
  return {
    type: SET_ACTIONS_TABLE_PAGE,
    payload: {
      key,
      page,
      rowsPerPage,
      module,
      forUser,
      showCompleted,
      fromDate,
      toDate,
      rating,
      sortOrder,
      orderBy,
      assignees,
      filteredText,
    },
  };
}

export function setComplianceMonitoringActionsTablePage({
  key,
  page,
  rowsPerPage,
  forUser,
  showCompleted,
  fromDate,
  toDate,
}) {
  return {
    type: SET_COMPLIANCE_MONITORING_ACTIONS_TABLE_PAGE,
    payload: {
      key,
      page,
      rowsPerPage,
      forUser,
      showCompleted,
      fromDate,
      toDate,
    },
  };
}

export function setRiskAssessmentsTablePage({
  key,
  page,
  rowsPerPage,
  riskLevel,
  sortBy,
  orderBy,
  fromDate,
  toDate,
  filteredText,
  assignees
}) {
  return {
    type: SET_RISK_ASSESSMENTS_TABLE_PAGE,
    payload: {
      key,
      page,
      rowsPerPage,
      riskLevel,
      sortBy,
      orderBy,
      fromDate,
      toDate,
      filteredText,
      assignees
    },
  };
}

export function setFormsTablePage({
    key,
    page,
    rowsPerPage,
    riskLevel,
    sortBy,
    orderBy,
    module,
    assignees,
    filteredText,
    fromDate,
    toDate,
}) {
    return {
        type: SET_FORMS_TABLE_PAGE,
        payload: {
            key,
            page,
            rowsPerPage,
            riskLevel,
            sortBy,
            orderBy,
            module,
            assignees,
            filteredText,
            fromDate,
            toDate,
        },
    };
}

export function setIncidentsTablePage({ key, page, rowsPerPage, sortBy, orderBy, fromDate, toDate, filteredText, assignees, filteredPersonText, riddorReportable, incidentCategoryId, }) {
    return {
        type: SET_INCIDENTS_TABLE_PAGE,
        payload: {
            key,
            page,
            rowsPerPage,
            sortBy,
            orderBy,
            fromDate,
            toDate,
            filteredText,
            assignees,
            filteredPersonText,
            riddorReportable,
            incidentCategoryId,
        },
    };
}

export function setFormInsightsTablePage({
    key,
    fromDate,
    toDate,
    forms,
    formIds
}) {
    return {
        type: SET_FORM_INSIGHTS_TABLE_PAGE,
        payload: {
            key,
            fromDate,
            toDate,
            forms,
            formIds
        },
    };
}

