import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useTracking } from "react-tracking";
import { Link, useRouteMatch } from "react-router-dom";
import CardBase from "../../../components/cards/CardBase";
import TemplatesTableCard from "../../components/ComplianceAdminTemplatesTableCard";
import PageBase from "../../../components/PageBase";
import PageTitle from "../../components/PageTitle";
import { Beenhere } from "@material-ui/icons";
import RequiresRoleWrapper from "../../../components/RequiresRoleWrapper";
import { useAuth } from "../../../contexts/authContext";
import { useSelector } from "react-redux";

function ComplianceAdmin() {
  const match = useRouteMatch();
  const { selectedAccount } = useSelector((state) => state.account);
  const { isTemplateAdminForSites, isWorknestUser } = useAuth();
  const { path } = match;
  const { Track } = useTracking({
    page: `Admin: Compliance`,
  });

  const isTemplateAdmin = isTemplateAdminForSites(
    selectedAccount?.childExternalIds
  );

  return (
    <RequiresRoleWrapper hasRole={isTemplateAdmin || isWorknestUser}>
      <PageBase>
        <Track>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardBase
                title={
                  <PageTitle icon={<Beenhere />} title="Compliance Setup" />
                }
                rightComponent={
                  <Button
                    component={Link}
                    to={`${path}/templatebuilder`}
                    variant="contained"
                    color="primary"
                  >
                    Create Template
                  </Button>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TemplatesTableCard />
            </Grid>
          </Grid>
        </Track>
      </PageBase>
    </RequiresRoleWrapper>
  );
}

export default ComplianceAdmin;
