import React, { useMemo, useState } from "react";
import { CircularProgress, Grid, makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import { useSelector } from "react-redux";
import useSiteAuditsLatest from "../hooks/queries/useSiteAuditsLatest";
import AuditsTableCard from "../components/AuditsTableCard";
import { auditTypes } from "../../constants/auditConstants";
import { useTracking } from "react-tracking";
import PageBase from "../../components/PageBase";
import useIsMobile from "../../hooks/useIsMobile";
import PageTitle from "../components/PageTitle";
import SiteSelect from "../../components/SiteSelect";
import ActionsTableCard from "../components/ActionsTableCard";
import moduleAreas, { moduleIcons } from "../constants/moduleAreaConstants";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    tabsContainer: {
        flexGrow: 1,
    },
    tab: {
        minWidth: "unset",
        textTransform: "none",
    },
    grid: {
        marginBottom: theme.spacing(3),
    },
    titleGrid: {
        marginBottom: theme.spacing(2),
    },
}));

function Audit() {
    const { Track } = useTracking({
        page: `Audits`,
    });
    const classes = useStyles();
    const isMobile = useIsMobile();
    const { selectedSite, activeAppId, appSiteIds } = useSelector(
        (state) => state.account
    );
    const [selectedTabValue, setSelectedTabValue] = useState(null);

    const handleTabChange = (event, newValue) => {
        setSelectedTabValue(newValue);
    };

    const latestAuditType = useSiteAuditsLatest({
        externalIds: selectedSite
            ? [selectedSite.externalId]
            : appSiteIds[activeAppId]
    });

    const auditTypeForTab = useMemo(() => {
        switch (selectedTabValue) {
            case 0:
                return auditTypes.GRA;
            case 1:
                return auditTypes.FRA;
            default:
                return null;
        }
    }, [selectedTabValue]);

    useMemo(() => {
        switch (latestAuditType.data) {
            case auditTypes.GRA:
                setSelectedTabValue(0);
                break;
            case auditTypes.FRA:
                setSelectedTabValue(1);
                break;
            default:
                return null;
        }
    }, [latestAuditType.data]);

    const pageContent = () => {
        if (latestAuditType.isLoading)
            return (
                <div className={classes.spinnerContainer}>
                    <CircularProgress color="primary" />
                </div>
            );
        else if (latestAuditType.isError)
            return <Alert severity="error">Error loading Audit Data</Alert>;
        else if (latestAuditType.isSuccess)
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ActionsTableCard moduleArea={moduleAreas.AUDIT} auditType={auditTypeForTab} />
                    </Grid>
                    <Grid item xs={12}>
                        <AuditsTableCard auditType={auditTypeForTab} />
                    </Grid>
                </Grid>
            );
    };

    return (
        <PageBase>
            <Track>
                <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    className={classes.titleGrid}
                >
                    <Grid item xs={12} md={9}>
                        <PageTitle
                            icon={moduleIcons.AUDIT}
                            title="Audit"
                            description="Once published by your consultant, view your General Risk Assessment, Workplace Inspection or Fire Risk Assessment. Actions associated with your Audit will be available to view."
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SiteSelect size="small" />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12}>
                        <Paper className={classes.tabsContainer}>
                            <Tabs
                                value={selectedTabValue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                orientation={isMobile ? "vertical" : "horizontal"}
                                textColor="primary"
                                aria-label="overview tabs"
                                centered
                                variant="fullWidth"
                            >
                                <Tab
                                    className={classes.tab}
                                    icon={<PeopleIcon />}
                                    label="General Risk Assessments & Workplace Inspections"
                                    disableRipple
                                />
                                <Tab
                                    className={classes.tab}
                                    icon={<WhatshotIcon />}
                                    label="Fire Risk Assessments"
                                    disableRipple
                                />
                            </Tabs>
                        </Paper>
                    </Grid>
                </Grid>
                {pageContent()}
            </Track>
        </PageBase>
    );
}

export default Audit;
