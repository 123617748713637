import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    deleteButton: {
        color: theme.palette.error.main,
    },
}));

export default function DeleteHistoricActionDialog({ open, onCancel, onDelete, actionId }) {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onCancel}
        >
            <DialogTitle>Delete Historic Action</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Action {actionId} will be permanently deleted. This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    color="secondary"
                    variant="text"
                >
                    Cancel
                </Button>
                <Button
                    onClick={onDelete}
                    className={classes.deleteButton}
                    variant='text'
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}