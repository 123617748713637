import axios from "../../utils/axios";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const fetchAllHazards = () => axios.get(`${apiUrl}/Hazard/GetAll`);

const hazardService = {
    fetchAllHazards,
};

export default hazardService;
