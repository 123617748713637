const downloadFileByUrl = (downloadUrl) => {
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = downloadUrl;
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(downloadUrl);
    link.remove();
};

const getDropBoxColor = (props) => {
    if (props.isDragAccept) {
        return "#00e676";
    }
    if (props.isDragReject) {
        return "#ff1744";
    }
    if (props.isDragActive) {
        return "#2196f3";
    }
    return "#eeeeee";
};

export const downloadFileFromBlob = (bytes, fileName, type = "application/octet-stream") => {
    let blob = new Blob([bytes], { type });
    let link = window.document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
};


export const downloadFileFromTypedBlob = (blob, fileName) => {
    let link = window.document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
};

const getObjectFromFileNames = (fileNames, externalId) => {
    return new Promise((resolve) => {
        const obj = fileNames.reduce((result, item) => {
            if (!item.name.startsWith(externalId))
                return result;

            let x = result;
            const path = item.name.substring(externalId.length + 1);
            const blob = item.name.substring(0, externalId.length);
            if (!path.length)
                return result;
            let elements = path.split("/");
            elements.forEach((el, i) => {
                if (!x[el]) {
                    x[el] = {
                        blob,
                        path: elements.slice(0, i + 1).join("/"),
                    };
                    if (i !== elements.length - 1)
                        x[el] = {
                            ...x[el],
                            children: {},
                        };
                }
                if (x[el].children) x = x[el].children;
            });

            return result;
        }, {});

        resolve(obj);
    });
};

export const bytesToSize = (bytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

const fileUtils = {
    downloadFileByUrl,
    getDropBoxColor,
    downloadFileFromBlob,
    getObjectFromFileNames,
    bytesToSize,
};

export default fileUtils;
