import React from "react";
import { TrendingDown, TrendingUp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { scoreTrend } from "../../constants/safetynestScoreConstants";

const green = "#2E7D32";
const red = "#B00020";

const useStyles = makeStyles(() => ({
  trend: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
  },
  trendIcon: {
    display: "flex",
    "&.trend-1": {
      color: green,
    },
    "&.trend-2": {
      color: red,
    },
  },
  differenceText: {
    fontWeight: 400,
    "&.small": {
      fontSize: "0.75rem",
    },
    "&.trend-1": {
      color: green,
    },
    "&.trend-2": {
      color: red,
    },
  },
}));

const trendIcons = {
  [scoreTrend.RISING]: <TrendingUp fontSize="small" />,
  [scoreTrend.FALLING]: <TrendingDown fontSize="small" />,
};

function TrendIndicator({ trend, difference, size = "regular" }) {
  const classes = useStyles();
  return (
    <div className={classes.trend}>
      <span className={clsx(classes.trendIcon, `trend-${trend}`)}>
        {trendIcons[trend]}
      </span>
      <span
        className={clsx(classes.differenceText, `trend-${trend}`, `${size}`)}
      >
        {difference}
      </span>
    </div>
  );
}

export default TrendIndicator;
