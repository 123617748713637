import { riskAssessmentQuestions } from "./RiskAssessmentQuestionsAndOptions";
import TemplateQuestionBuilder from "./TemplateQuestionBuilder";

class TemplateBuilderHelper {
    constructor(name, moduleArea, description, frequency, incidentType, frequencyInterval, frequencyDays) {
    this.name = name;
    this.moduleArea = moduleArea;
    this.description = description;
    this.frequency = frequency;
    this.incidentType = incidentType;
    this.frequencyInterval = frequencyInterval;
    this.frequencyDays = frequencyDays;
  }

  withIncidentType(incidentType) {
    this.incidentType = incidentType;
    return this;
    }

  withSectorId(sectorId) {
    this.sectorId = sectorId;
    return this;
  }

  withSubjectId(subjectId) {
    this.subjectId = subjectId;
    return this;
  }

  withHazardIds(hazardIds) {
    this.hazardIds = hazardIds;
    return this;
  }

  withPeopleIds(peopleIds) {
    this.peopleIds = peopleIds;
    return this;
  }

  withTemplateVersionId(templateVersionId) {
    this.templateVersionId = templateVersionId
    return this
  }

  withChangeNotes(changeNotes) {
    this.changeNotes = changeNotes
    return this
  }


  withRiskAssessmentSections(hazardIds, hazards) {
    this.sections = hazardIds?.map((hazardId, index) => ({
      displayOrder: index,
      name: hazards?.find((x) => x.id === hazardId)?.name,
      isInvestigation: false,
      questions: riskAssessmentQuestions,
    }));

    return this;
  }
  
  withCustomHazards(customHazards) {
    this.customHazards = customHazards?.map(hazard => ({
      name: hazard.name,
      siteExternalId: hazard.siteExternalId
    }))
    
    return this
  }

  withSections(sectionIds, sections, questions, choiceOutcomes, options) {
    this.sections = sectionIds.map(
      ({ sectionId, isInvestigation }, sectionIndex) => ({
        name: sections[sectionId].name,
        displayOrder: sectionIndex,
        questions: sections[sectionId].questionIds.map(
          (questionId, questionIndex) =>
            new TemplateQuestionBuilder(
              questions[questionId],
              questionIndex,
              choiceOutcomes,
              options,
              this.moduleArea
            )
              .withOutcomes()
              .withRangeRestrictions()
              .withMultipleChoiceOptionsAndOutcomes()
              .withIncidentFields()
              .build()
        ),
        isInvestigation,
      })
    );

    return this;
  }

  withSiteExternalId(siteExternalId) {
    this.siteExternalId = siteExternalId;
    return this;
  }

    build() {
    return {
      name: this.name,
      description: this.description,
      applicationArea: this.moduleArea,
      incidentType: this.incidentType,
      sectorId: this.sectorId,
      subjectId: this.subjectId,
      frequency: this.frequency,
      hazardIds: this.hazardIds,
      peopleIds: this.peopleIds,
      sections: this.sections,
      siteExternalId: this.siteExternalId,
      templateVersionId: this.templateVersionId,
      changeNotes: this.changeNotes,
      frequencyInterval: this.frequencyInterval,
      frequencyDays: this.frequencyDays,
      customHazards: this.customHazards,
    };
  }
}

export default TemplateBuilderHelper;
