import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import useAccountLinkableUserSearch from "../../hooks/queries/useAccountLinkableUserSearch";
import CustomButton from "../CustomButton";
import { grey } from "@material-ui/core/colors";
import { Person } from "@material-ui/icons";
import useAddUserToSite from "../../hooks/mutations/useAddUserToSite";
import { Alert } from "@material-ui/lab";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    marginBottom: theme.spacing(2),
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  personIcon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
  },
  searchResultContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    borderRadius: "4px",
    border: `1px solid ${grey[300]}`,
    padding: theme.spacing(2),
  },
  userDetailsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

function AddUserToSiteDialog({
  isOpen,
  onClose,
  linkingToSiteId,
  onUserAdded,
}) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const userSearch = useAccountLinkableUserSearch({
    accountId: linkingToSiteId,
    email,
  });
  const addUser = useAddUserToSite();

  const { enqueueSnackbar } = useSnackbar();

  const onSearchSubmit = (e) => {
    e.preventDefault();
    setEmail(e.target.email.value);
  };

  const handleClose = () => {
    if (addUser.isLoading) return;
    setEmail("");
    userSearch.remove();
    onClose();
  };

  const onLinkUser = () => {
    addUser.mutate(
      { newUser: userSearch.data, accountId: linkingToSiteId },
      {
        onSuccess: ({ accountId, appRoles }) => {
          const newUser = { ...userSearch.data };
          newUser.accountRoles[accountId] = appRoles;
          newUser.accountApps[accountId] = [];
          newUser.accountIds = [...newUser.accountIds, accountId];
          onUserAdded(userSearch.data);
        },
        onError: (error) => {
          console.error(error);
          enqueueSnackbar(error?.message || "There was an error adding the user to the site",
              {
                variant: "error"
              });
        }
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Link Existing User</DialogTitle>
      <DialogContent>
        <form onSubmit={onSearchSubmit}>
          <TextField
            className={classes.searchInput}
            fullWidth
            variant="outlined"
            id="email"
            name="email"
            label="Email"
            type="email"
            autoComplete="off"
            placeholder="Email address"
            InputLabelProps={{
              shrink: true,
            }}
          />
          {userSearch.isError && (
            <Alert className={classes.error} severity="info">
              {userSearch.error}
            </Alert>
          )}
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            isLoading={userSearch.isLoading}
          >
            Search
          </CustomButton>
        </form>
        {userSearch.data && (
          <div className={classes.searchResultContainer}>
            <div className={classes.userDetailsContainer}>
              <Person fontSize="large" className={classes.personIcon} />
              <Typography>
                {userSearch.data.firstName} {userSearch.data.lastName}
              </Typography>
            </div>
            <CustomButton
              variant="outlined"
              color="primary"
              onClick={onLinkUser}
              isLoading={addUser.isLoading}
            >
              Link to account
            </CustomButton>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default AddUserToSiteDialog;
