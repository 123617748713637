import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import riskAssessmentHistoryService from "../../services/riskAssessmentHistoryService";

const useRiskAssessmentHistoryAcknowledgementRecipients = (riskAssessmentHistoryId) =>
  useQuery(
    [
      queryKeys.riskAssessmentHistoryAcknowledgementRecipients,
      riskAssessmentHistoryId,
    ],
    () => riskAssessmentHistoryService.getRiskAssessmentHistoryAcknowledgementRecipients(riskAssessmentHistoryId),
    {
      enabled: !!riskAssessmentHistoryId,
    }
  );

export default useRiskAssessmentHistoryAcknowledgementRecipients;