import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import useArchiveRiskAssessmentHistory from "../../../hooks/mutations/useArchiveRiskAssessmentHistory";
import { useSnackbar } from "notistack";

const ArchiveRiskAssessmentHistoryDialog = ({
  isOpen,
    setIsOpen,
    riskAssessmentId,
    archiveRiskAssessmentId,

}) => {
  const archiveRiskAssessmentHistory = useArchiveRiskAssessmentHistory();
  const { enqueueSnackbar } = useSnackbar();

    const handleConfirm = async e => {
        e.preventDefault();
        const updateData = {
            id: riskAssessmentId,
            archiveRiskAssessmentId: archiveRiskAssessmentId,
            };
            archiveRiskAssessmentHistory.mutate(updateData, {
                onSuccess: () => handleClose(),
                onError: (error) => {
                    console.error(error);
                    enqueueSnackbar(error?.message || "There was an error deleting the Risk Assessment History",
                        {
                            variant: "error"
                        });
                },
            });
        }

    const handleClose = () => {
        setIsOpen(false);
    };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Archive Risk Assessment History</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to archive this risk assessment history? The data will no
          longer be visible within SafetyNest.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveRiskAssessmentHistoryDialog;
