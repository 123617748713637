import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useActiveTutorials from "../hooks/queries/useActiveTutorials";
import { tutorialAppearanceModes } from "../constants/tutorialAppearanceModes";
import useTutorialViews from "../hooks/queries/useTutorialViews";
import useAddTutorialView from "../hooks/mutations/useAddTutorialView";
import {
  setIsTutorialOpen,
  setTutorial,
} from "../redux/actions/tutorialActions";
import TutorialDialog from "./dialogs/TutorialDialog";

function TutorialTracker() {
  const dispatch = useDispatch();

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const tutorials = useActiveTutorials();
  const tutorialViews = useTutorialViews();
  const addTutorialView = useAddTutorialView();
  const { path, url } = useSelector((state) => state.page);
  const { tutorial, isTutorialOpen } = useSelector((state) => state.tutorial);
  const { isOpen: cookieJarOpen } = useSelector((state) => state.cookie);

  useEffect(() => {
    if (tutorials.data && tutorialViews.data) {
      const tutorialForPath = tutorials.data[path];

      dispatch(setTutorial(tutorialForPath));

      if (tutorialForPath && !tutorialViews.data.includes(path) && !cookieJarOpen) {
        dispatch(setIsTutorialOpen(true));
      }
    }
  }, [path, url, tutorials.data, tutorialViews.data, dispatch, cookieJarOpen]);

  const handleClose = () => {
    dispatch(setIsTutorialOpen(false));
    if (tutorial?.appearanceMode === tutorialAppearanceModes.FIRST_LOGIN) {
      addTutorialViewForUser(tutorial?.id);
    }
    setActiveStepIndex(0);
  };

  const addTutorialViewForUser = (tutorialId) => {
    addTutorialView.mutate(tutorialId);
  };

  const handleDontShowAgain = () => {
    dispatch(setIsTutorialOpen(false));
    addTutorialViewForUser(tutorial?.id);
  };

  const handleNextStep = () => {
    setActiveStepIndex((curr) => curr + 1);
  };

  const handlePreviousStep = () => {
    setActiveStepIndex((curr) => curr - 1);
  };

  return (
    <TutorialDialog
      onClose={handleClose}
      isOpen={isTutorialOpen}
      activeStepIndex={activeStepIndex}
      onDontShowAgain={handleDontShowAgain}
      onNextStep={handleNextStep}
      onPreviousStep={handlePreviousStep}
      tutorial={tutorial}
    />
  );
}

export default TutorialTracker;
