import {
  SELECT_ACCOUNT,
  SELECT_SITE,
  SET_ACTIVE_APPID,
  SET_SITES,
  SET_ROOT_ACCOUNTS,
  HIDE_CASENEST_TERMS_BANNER,
} from "../types";

const initialState = {
  rootAccounts: [],
  selectedSite: null,
  selectedAccount: null,
  activeAppId: null,
  hideCaseNestTermsBanner: false,
  sites: {},
  appSiteIds: {},
};

function accountReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ROOT_ACCOUNTS:
      return { ...state, rootAccounts: action.payload };
    case SELECT_SITE:
      return { ...state, selectedSite: action.payload };
    case SELECT_ACCOUNT:
      return { ...state, selectedAccount: action.payload, selectedSite: null };
    case SET_ACTIVE_APPID:
      return { ...state, activeAppId: action.payload };
    case HIDE_CASENEST_TERMS_BANNER:
      return { ...state, hideCaseNestTermsBanner: true };
    case SET_SITES: {
      const sites = {};
      const appSiteIds = [];

      for (const site of action.payload.sites) {
        sites[site.externalId] = { ...site };
        appSiteIds.push(site.externalId);
      }

      return {
        ...state,
        sites: { ...state.sites, ...sites },
        appSiteIds: { ...state.appSiteIds, [action.payload.appId]: appSiteIds },
      };
    }
    default:
      return state;
  }
}

export default accountReducer;
