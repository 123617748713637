import React, { useState } from "react";
import { useTracking } from "react-tracking";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import RequiresRoleWrapper from "../components/RequiresRoleWrapper";
import { useAuth } from "../contexts/authContext";
import CardBase from "../components/cards/CardBase";
import useSalesforceUserDetails from "../hooks/queries/useSalesforceUserDetails";
import { red } from "@material-ui/core/colors";
import BusinessIcon from "@material-ui/icons/Business";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  detailsCard: {
    marginTop: theme.spacing(2),
  },
  errorText: {
    color: red[500],
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  buttonWrapper: {
    position: "relative",
  },
  buttonSpinner: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  searchButton: {
    minHeight: "36px",
  },
  impersonateSpinner: {
    color: theme.palette.error.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  impersonateButton: {
    minHeight: "36px",
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  lineItemLabel: {
    fontWeight: 500,
  },
  lineItemValue: {
    wordWrap: "break-word",
  },
  resultDetails: {
    marginTop: theme.spacing(2),
  },
  accountsText: {
    marginTop: theme.spacing(2),
  },
}));

function UserImpersonation() {
  const classes = useStyles();
  const history = useHistory();
  const {
    isCxAdmin,
    impersonateUser,
    isImpersonationLoading,
    isImpersonationError,
    impersonationError,
  } = useAuth();
  const [emailSearchValue, setEmailSearchValue] = useState("");
  const [email, setEmail] = useState("");
  const { data, isLoading, isError, isFetching } =
    useSalesforceUserDetails(emailSearchValue);
  useTracking({
    page: "User Impersonation",
  });

  const searchForUser = () => {
    setEmailSearchValue(email);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") searchForUser();
  };

  const handleImpersonateUser = async () => {
    try {
      await impersonateUser({
        userId: data.externalId,
        name: `${data.firstName} ${data.lastName}`,
        email: data.email,
      });

      history.push("/");
    } catch (e) {
      console.error(e);
    }
  };

  const ResultLineItem = ({ label, value }) => {
    return (
      <>
        <Grid xs={12} sm={6} item>
          <Typography className={classes.lineItemLabel}>{label}</Typography>
        </Grid>
        <Grid xs={12} sm={6} item>
          <Typography>{value}</Typography>
        </Grid>
      </>
    );
  };

  return (
    <RequiresRoleWrapper hasRole={isCxAdmin}>
      <Container maxWidth="md">
        <CardBase
          title="User Impersonation"
          description="Please search for a user to impersonate."
        >
          <TextField
            id="email-address"
            label="Email Address"
            fullWidth
            margin="normal"
            placeholder="Email address"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.searchButton}
              disabled={!email}
              fullWidth
              color={"primary"}
              variant={"contained"}
              onClick={searchForUser}
            >
              {isLoading ? "" : "Search"}
            </Button>
            {isLoading && (
              <CircularProgress
                data-testid="search-spinner"
                size={24}
                className={classes.buttonSpinner}
              />
            )}
          </div>
          {isError && (
            <Typography className={classes.errorText} variant="body2">
              Could not find user.
            </Typography>
          )}
        </CardBase>
        {data && (
          <Box className={classes.detailsCard}>
            <CardBase title="User Details" isFetching={isFetching}>
              <Grid container spacing={1} className={classes.resultDetails}>
                <ResultLineItem
                  label="Name"
                  value={`${data.firstName} ${data.lastName}`}
                />
                <ResultLineItem label="Email" value={data.email} />
              </Grid>
              {data.accounts.length > 0 ? (
                <>
                  <Typography
                    data-testid="user-accounts-success-copy"
                    className={classes.accountsText}
                  >
                    <span
                      className={classes.lineItemLabel}
                    >{`${data.firstName} ${data.lastName}`}</span>{" "}
                    has access to the following accounts:
                  </Typography>
                  <List>
                    {data.accounts.map((account) => (
                      <ListItem key={account.id}>
                        <ListItemAvatar>
                          <Avatar>
                            <BusinessIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={account.name} />
                      </ListItem>
                    ))}
                  </List>

                  <div className={classes.buttonWrapper}>
                    <Button
                      className={classes.impersonateButton}
                      fullWidth
                      variant={"outlined"}
                      onClick={handleImpersonateUser}
                    >
                      {isImpersonationLoading
                        ? ""
                        : `Impersonate ${data.firstName} ${data.lastName}`}
                    </Button>
                    {isImpersonationLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.impersonateSpinner}
                      />
                    )}
                  </div>
                  {isImpersonationError && (
                    <Typography className={classes.errorText} variant="body2">
                      {impersonationError}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography
                  data-testid="user-accounts-failure-copy"
                  className={classes.accountsText}
                >
                  <span
                    className={classes.lineItemLabel}
                  >{`${data.firstName} ${data.lastName}`}</span>{" "}
                  does not have access to any accounts yet. Please assign this
                  user to an account in Salesforce.
                </Typography>
              )}
            </CardBase>
          </Box>
        )}
      </Container>
    </RequiresRoleWrapper>
  );
}

export default UserImpersonation;
