import { Button, DialogContent, DialogContentText, DialogTitle, Paper, makeStyles } from "@material-ui/core";
import { Check } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    acknowledgementActions: {
        position: "fixed",
        bottom: "0",
        right: "0",
        margin: theme.spacing(2),
        width: "400px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            margin: theme.spacing(0),
        },
    },
    check: {
        marginRight: theme.spacing(1),
    },
    subTitleTextPadding: {
        padding: theme.spacing(3, 3, 0, 3),
    },
    title: {
        padding: theme.spacing(0),
        margin: theme.spacing(0),
    },
    actions: {
        display: "flex",
        flexDirection: "row-reverse",
        gap: theme.spacing(1),
        margin: theme.spacing(3, 0, 2, 0),
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
}));

export default function ConfirmDetails({ onConfirm, onDismiss }) {
    const classes = useStyles();

    return (
        <div className={classes.acknowledgementActions}>
            <Paper>
                <div className={classes.subTitleTextPadding}>
                    <DialogTitle className={classes.title}>Review Required</DialogTitle>
                    <DialogContentText variant="body2" className={classes.title}>
                        Take your time to review the document. Don't forget to acknowledge when you are done!
                    </DialogContentText>
                </div>
                <DialogContent>
                    <div className={classes.actions}>
                        <Button color="secondary" variant="contained" onClick={onConfirm}>
                            <Check className={classes.check} /> Confirm Review
                        </Button>
                        <Button variant="text" color="secondary" onClick={onDismiss}>
                            Dismiss
                        </Button>
                    </div>
                </DialogContent>
            </Paper>
        </div>
    );
}
