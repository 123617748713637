import React, { useState } from "react";
import {
  alpha,
  Button,
  CircularProgress,
  Container,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import moduleAreas, { moduleIconForEnum, moduleUrls } from "../../constants/moduleAreaConstants";
import clsx from "clsx";
import { Check, Speed } from "@material-ui/icons";
import { bandColours } from "../../constants/safetynestScoreConstants";
import { useSelector } from "react-redux";
import useSafetyNestScoreApplicability from "../../hooks/queries/useSafetyNestScoreApplicability";
import { Alert } from "@material-ui/lab";
import ScoreCalculationDialog from "./dialogs/ScoreCalculationDialog";
import { useHistory } from "react-router-dom";
import { baseRoute } from "../../routes";

const useStyles = makeStyles((theme) => ({
  scoreApplicability: {
    padding: theme.spacing(2),
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(1),
  },
  headerIcon: {
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.mainOpaque,
    height: "48px",
    width: "48px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: "24px",
    },
  },
  title: {
    fontWeight: 600,
  },
  progress: {
    backgroundColor: theme.palette.secondary.main,
  },
  progressRoot: { width: "100%" },
  progressColorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    listStyle: "none",
    padding: 0,
  },
  applicabilityRow: {
    display: "flex",
    borderWidth: "1px",
    borderColor: theme.palette.grey[400],
    borderStyle: "dashed",
    padding: theme.spacing(2),
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    "&.active": {
      borderStyle: "solid",
      borderColor: bandColours.ESTABLISHED,
      backgroundColor: alpha(bandColours.ESTABLISHED, 0.1),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  moduleDetails: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    width: "fit-content",
    borderRadius: "100%",
    border: `2px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[500],
    "&.active": {
      border: `2px solid ${bandColours.ESTABLISHED}`,
      backgroundColor: alpha(bandColours.ESTABLISHED, 0.1),
      color: bandColours.ESTABLISHED,
    },
  },
  scoreDetails: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  ctaButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  promptText: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
  checkIconWrapper: {
    color: "white",
    backgroundColor: bandColours.ESTABLISHED,
    width: "24px",
    height: "24px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function ScoreApplicability() {
  const history = useHistory();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { selectedSite, activeAppId, appSiteIds } = useSelector(
    (state) => state.account
  );

  const scoreApplicability = useSafetyNestScoreApplicability({
    externalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
  });

  function handleCreateClick(applicationArea) {
    if (applicationArea === moduleAreas.COMPLIANCE || applicationArea === moduleAreas.MONITORING)
      history.push(`${baseRoute}/${moduleUrls[applicationArea]}/addtemplateform`) 
    else if (applicationArea !== moduleAreas.AUDIT)
      history.push(`${baseRoute}/${moduleUrls[applicationArea]}/add`)
  }

  const content = () => {
    if (scoreApplicability.isLoading)
      return (
        <div className={classes.flexCenter}>
          <CircularProgress />
        </div>
      );

    if (scoreApplicability.isError)
      return (
        <div className={classes.flexCenter}>
          <Alert severity="error">
            Error loading SafetyNest Score applicability.
          </Alert>
        </div>
      );

    const remainingModulesRequired =
      (scoreApplicability.data?.requiredActiveModuleCount ?? 0) -
      (scoreApplicability.data?.currentActiveModuleCount ?? 0);

    return (
      <Paper className={classes.scoreApplicability}>
        <div className={classes.headerContainer}>
          <div className={classes.headerIcon}>
            <Speed />
          </div>
          <Typography className={classes.title}>
            Unlock SafetyNest Score
          </Typography>
          <Typography variant={"body2"}>
            Activate{" "}
            <span className={classes.promptText}>
              {remainingModulesRequired} more
            </span>{" "}
            {remainingModulesRequired === 1 ? "module" : "modules"} to unlock
            your SafetyNest Score.
          </Typography>
          <LinearProgress
            variant="determinate"
            value={scoreApplicability.data?.progressPercentage}
            classes={{
              root: classes.progressRoot,
              barColorPrimary: classes.progress,
              colorPrimary: classes.progressColorPrimary,
            }}
          />
        </div>
        <ul className={classes.listContainer}>
          {scoreApplicability.data?.moduleScoreApplicability.map((a) => (
            <li
              key={a.applicationArea}
              className={clsx(classes.applicabilityRow, {
                active: a.isRequirementMet,
              })}
            >
              <div className={classes.moduleDetails}>
                <div
                  className={clsx(classes.icon, { active: a.isRequirementMet })}
                >
                  {moduleIconForEnum[a.applicationArea]}
                </div>
                <div className={classes.scoreDetails}>
                  <Typography>{a.applicationAreaText}</Typography>
                  {!a.isRequirementMet && (
                    <Typography variant={"body2"}>
                      <span className={classes.promptText}>
                        {a.requiredCount - a.currentCount} more
                      </span>{" "}
                      required.
                    </Typography>
                  )}
                </div>
              </div>
              {a.applicationArea !== moduleAreas.AUDIT && (
              !a.isRequirementMet ? (
                <Button
                  disabled={a.applicationArea === moduleAreas.AUDIT}
                  className={classes.ctaButton}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => handleCreateClick(a.applicationArea)}
                >
                  Create
                </Button>
              ) : (
                <div className={classes.checkIconWrapper}>
                  <Check fontSize="small" />
                </div>
              ))}
            </li>
          ))}
        </ul>
        <Button
          variant="text"
          color="secondary"
          onClick={() => setDialogOpen(true)}
        >
          How is your score calculated?
        </Button>
      </Paper>
    );
  };

  return (
    <Container maxWidth="sm" disableGutters>
      {content()}
      <ScoreCalculationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Container>
  );
}

export default ScoreApplicability;
