import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React from "react";

export default function SkeletonTableLoader({ columns, numberOfRows }) {

    const rows = Array.apply(null, Array(numberOfRows));
    
    return (
        <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns?.map(col => {
                                return (
                                    <TableCell key={col}>{col}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row, index) => !row && 
                            <TableRow key={index}>
                                {columns?.map(col => {
                                    return (
                                        <TableCell key={col}><Skeleton variant="text" /></TableCell>
                                    )
                                })}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
    )
}