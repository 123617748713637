import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import tutorialService from "../../services/tutorialService";

const useActiveTutorialsList = () =>
  useQuery([queryKeys.tutorialsList], () =>
    tutorialService.fetchAllActiveList()
  );

export default useActiveTutorialsList;
