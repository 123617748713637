import axios from "../../utils/axios";
import { buildQueryStringList } from "../../utils/stringUtils";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const fetchOverallSafetyNestScore = ({ externalIds }) =>
  axios.get(
    `${apiUrl}/score/overall${buildQueryStringList("externalIds", externalIds)}`
  );

const fetchSiteBreakdownSafetyNestScore = ({
  externalIds,
  page,
  rows,
  sortBy,
  orderBy,
}) => {
  let queryString = buildQueryStringList("externalIds", externalIds);
  if (sortBy !== null) queryString += `&sortBy=${sortBy}`;

  return axios.get(
    `${apiUrl}/score/breakdown/${queryString}&page=${page}&rows=${rows}&orderBy=${orderBy}`
  );
};

const fetchSafetyNestScoreApplicability = ({ externalIds }) =>
  axios.get(
    `${apiUrl}/score/applicability${buildQueryStringList(
      "externalIds",
      externalIds
    )}`
    );

const downloadSafetyNestScoreForSite = async ({
    externalIds
}) => axios.get(
    `${apiUrl}/score/download${buildQueryStringList("externalIds", externalIds)}`);

const safetyNestScoreService = {
  fetchOverallSafetyNestScore,
  fetchSiteBreakdownSafetyNestScore,
  fetchSafetyNestScoreApplicability,
  downloadSafetyNestScoreForSite,
};

export default safetyNestScoreService;
