import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import acknowledgementService from "../../services/acknowledgementService";

const useAcknowledgementDetails = ({ reference }) =>
    useQuery(
        [queryKeys.acknowledgementDetails, reference],
        () => acknowledgementService.getDetailsByReference({ reference }),
        { enabled: !!reference }
    );

export default useAcknowledgementDetails;