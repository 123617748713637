import { useQuery } from "react-query";
import caseNestUserAlertSettingService from "../../services/caseNestUserAlertSettingService";
import queryKeys from "../../constants/queryKeys";

const useCaseNestUserAlertSetting = ({ userExternalId, isCaseNestAdmin, hasCaseNestAccess }) =>
  useQuery(
    [queryKeys.caseNestUserAlertSetting, userExternalId],
    () => caseNestUserAlertSettingService.getByUserExternalId(userExternalId),
    {
      enabled: !!userExternalId && isCaseNestAdmin && hasCaseNestAccess,
    }
  );

export default useCaseNestUserAlertSetting;
