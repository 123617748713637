import { useMutation } from "react-query";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";
import templateVersionViewService from "../../services/templateVersionViewService";

const useAddTemplateVersionView = () => 
    useMutation(templateVersionViewService.addTemplateVersionViews, {  
        onMutate: async (data) => {
            const { client } = reactQueryUtils;
            const queryKey = [
                queryKeys.templateVersionViews,
                data.templateId,
            ];
            const previousTemplateVersionViews = client.getQueryData(queryKey);

            client.setQueryData(queryKey, (previous) =>
                previous ? [...previousTemplateVersionViews, data] : [data]
            )
            
            return { previousTemplateVersionViews }
        },
        onError: (error, data, context) => {
            reactQueryUtils.client.setQueryData(
              [
                queryKeys.templateVersionViews,
                data.templateId,
              ],
              context.previousTemplateVersionViews
            );
        },
        onSettled: (_data, _error, variables) => {
            if (variables)
                reactQueryUtils.client.invalidateQueries([
                    queryKeys.templateVersionViews,
                    variables.templateId,
                ]);
        },
    });

export default useAddTemplateVersionView;