import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useSnackbar } from "notistack";
import useIsMobile from "../../../../hooks/useIsMobile";
import { frequencyDropdownValues, frequency } from "../../../constants/frequencyConstants";
import ResponsiveSelect from "../../../../components/ui/ResponsiveSelect";
import useUpdateInfoRiskAssessment from "../../../hooks/mutations/useAmendRiskAssessment";
import useContactsForAppAndSite from "../../../../hooks/useContactsForAppAndSite";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    display: "flex",
    alignItems: "flex-end",
  },
  dialogContainer: {
    margin: theme.spacing(3),
  },
}));

const RiskAssessmentAmendDialog = ({
  isOpen,
  setIsOpen,
  riskAssessment,
  pageNum,
  pageSize,
  riskLevel,
}) => {
  const classes = useStyles();

  const amendRiskAssessment = useUpdateInfoRiskAssessment();

  const [assignedEmployee, setAssignedEmployee] = useState("");
  const [selectedFrequencyId, setSelectedFrequencyId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [siteExternalId, setSiteExternalId] = useState("");
  const isMobile = useIsMobile();

  const { enqueueSnackbar } = useSnackbar();

  const contacts = useContactsForAppAndSite({ selectedSiteId: siteExternalId });

  const hasNoUsers =
    Object.values(contacts.data).length === 0 &&
    riskAssessment &&
    riskAssessment.siteExternalId;

  const handleClose = () => {
    setIsOpen(false);
  };
    const handleSubmit = async (e) => {
    e.preventDefault();
    if (!assignedEmployee || hasNoUsers) return;
    const updateData = {
      riskAssessmentId: riskAssessment.id,
      startDate,
      frequency: selectedFrequencyId,
      assignedEmployeeExternalId: assignedEmployee,
      siteExternalId: riskAssessment.siteExternalId,
      pageNum,
      pageSize,
      riskLevel,
    };
    amendRiskAssessment.mutate(updateData, {
      onSuccess: () => handleClose(),
      onError: (error) => {
        console.error(error);
        enqueueSnackbar(
          error?.message || "There was an error updating the risk assessment",
          {
            variant: "error",
          }
        );
      },
    });
  };

  useEffect(() => {
    if (riskAssessment) {
      setAssignedEmployee(riskAssessment.assignedEmployeeExternalId);
      setSelectedFrequencyId(riskAssessment.frequency);
      setSiteExternalId(riskAssessment.siteExternalId);
      setStartDate(riskAssessment.startDate);
    }
  }, [riskAssessment]);

  const getDialogContent = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl error={hasNoUsers} variant="outlined" fullWidth>
              <InputLabel id="employee-select-label">
                Employee Responsible
              </InputLabel>
              <Select
                required
                labelId="employee-select-label"
                label="Employee Responsible"
                value={assignedEmployee}
                onChange={(e) => setAssignedEmployee(e.target.value)}
              >
                {hasNoUsers ? (
                  <MenuItem>No employees for the selected site</MenuItem>
                ) : (
                  Object.values(contacts.data).sort((a, b) => (a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0)).map((user) => (
                    <MenuItem
                      key={user.externalId}
                      value={user.externalId}
                    >{`${user.firstName} ${user.lastName}`}</MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} className={classes.datePicker}>
            <FormControl fullWidth>
              <KeyboardDatePicker
                required
                format="dd/MM/yyyy"
                variant="inline"
                inputVariant="outlined"
                label="Date Created"
                value={startDate}
                onChange={(date) => setStartDate(new Date(date.setHours(12)))}
                minDate={riskAssessment?.startDate}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <ResponsiveSelect
              required
              fullWidth
              label="Select Frequency"
              options={frequencyDropdownValues.filter((s) => s.value !== frequency.NONE)}
              optionValueKey="value"
              optionLabelKey="label"
              value={selectedFrequencyId}
              onChange={(e) => setSelectedFrequencyId(e.target.value)}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={isMobile}>
      <DialogTitle>Edit Risk Assessment?</DialogTitle>
      <form onSubmit={handleSubmit}>
        <div className={classes.dialogContainer}>{getDialogContent()}</div>
        <DialogActions>
          {amendRiskAssessment.isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" color="primary">
                Save
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RiskAssessmentAmendDialog;
