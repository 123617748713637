import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import tileService from "../../services/tileService";

const useAccountsTiles = (accountExternalIds) =>
  useQuery(
    [queryKeys.accountsTiles, accountExternalIds?.join(",")],
    () => tileService.fetchTilesForAccounts(accountExternalIds),
    {
      staleTime: Infinity,
    }
  );

export default useAccountsTiles;
