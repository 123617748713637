import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    margin: theme.spacing(1),
  },
  dialogText: {
    color: theme.palette.primary.main,
  },
}));

const RiskAssessmentHistoryAmendDialog = ({
  isOpen,
  setIsOpen,
  riskAssessment,
  handleEditAndNavigate,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      handleEditAndNavigate(riskAssessment);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle>Amend Hisoric Risk Assessment</DialogTitle>
      <form onSubmit={handleSubmit}>
              <div className={classes.dialogContainer}>
                  <Grid container spacing={2}>
                      <Grid item>
                          <DialogContent>
                              <DialogContentText
                                  className={classes.dialogText}
                                  id="alert-dialog-description"
                              >
                                  Update the contents of the existing risk assessment. Adjust hazard scores and modify controls and responses as necessary to ensure they correctly reflect the assessment carried out.
                              </DialogContentText>
                          </DialogContent>
                      </Grid>
                  </Grid>
              </div>
        <DialogActions>
            <>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                          type="submit"
                          color="primary"
              >
                Amend Risk Assessment
              </Button>
            </>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RiskAssessmentHistoryAmendDialog;
