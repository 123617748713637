const documentTypes = {
  WORKNEST: {
    value: 0,
    description: "WorkNest Documents",
  },
  ACCOUNT: {
    value: 1,
    description: "Company Documents",
  },
};

const fileExtensions = {
  image: [".jpg", ".png", ".jpeg"],
  video: [".mp4", ".mpeg", ".mov", ".mpg"],
};

export { documentTypes, fileExtensions };
