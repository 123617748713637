import React, { useEffect } from "react";
import { Box, CircularProgress, Container, Grid, makeStyles } from "@material-ui/core";
import FeatureTile from "../components/tiles/FeatureTile";
import ProductTile from "../components/tiles/ProductTile";
import * as muiIcons from "@material-ui/icons";
import layoutConstants from "../constants/layoutConstants";
import SpannableTile from "../components/tiles/SpannableTile";
import referralBgImage from "../images/mWN_referral_banner.svg";
import LatestBlogPosts from "../components/carousel/LatestBlogPosts";
import { useTracking } from "react-tracking";
import { useDispatch, useSelector } from "react-redux";
import useAccountFeatures from "../hooks/queries/useAccountFeatures";
import { setActiveAppId } from "../redux/actions/accountActions";
import ErrorBox from "../components/ErrorBox";
import PageBase from "../components/PageBase";

const useStyles = makeStyles((theme) => ({
    tilesGrid: {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${layoutConstants.featureTileWidth}px, 1fr))`,
        gap: theme.spacing(layoutConstants.tilesGridSpacingMultiplier),
    },
    loadingContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
    },
}));

const appEnvironment = process.env.REACT_APP_ENV;
const isProduction = appEnvironment === "production";
const isDevelopment = appEnvironment === "development";
const tileRouteTokenReplacement = isProduction ? "" : isDevelopment ? "test" : appEnvironment;

const Home = () => {
    const dispatch = useDispatch();
    const { Track } = useTracking({
        page: "Home",
    });
    const { selectedAccount } = useSelector((state) => state.account);
    const classes = useStyles();
    const { data, isLoading, isError, error } = useAccountFeatures(selectedAccount?.childExternalIds);

    useEffect(() => {
        dispatch(setActiveAppId(null));
    }, [dispatch]);

    const goToReferralPage = () => {
        window.open("https://worknest.com/referral/?v=myworknest", "_blank");
    };

    const featureTiles = () => {
        if (isLoading)
            return (
                <Box className={classes.loadingContainer}>
                    <CircularProgress color="primary" />
                </Box>
            );

        if (isError) return <ErrorBox message={error.message} />;

        return (
            <Track>
                <ProductTile />
                <div className={classes.tilesGrid}>
                    
                    {data &&
                        data.map((tile, index) => (
                            <FeatureTile
                                key={index}
                                title={tile.displayName}
                                icon={muiIcons[tile.icon]}
                                image={tile.imageUrl}
                                imageText={tile.imageText}
                                internalRoute={tile.internalRoute}
                                externalRoute={tile.clickUrl?.replace("{{ENV}}", tileRouteTokenReplacement)}
                            />
                        ))}
                    <SpannableTile
                        title="Know someone who could benefit from "
                        titleHighlight="WorkNest's support?"
                        description="If you're happy with the service you receive from WorkNest, we'd love it if you'd spread the word. "
                        descriptionHighlight="You could earn &pound;300!"
                        spanCols={2}
                        bgImageUrl={referralBgImage}
                        onClick={goToReferralPage}
                    />
                </div>
            </Track>
        );
    };

    return (
        <PageBase>
            <Container maxWidth="lg">
                <Grid container spacing={layoutConstants.homePageSectionsPaddingMultiplier}>
                    <Grid item xs={12}>
                        {featureTiles()}
                    </Grid>
                    <Grid item xs={12}>
                        <LatestBlogPosts />
                    </Grid>
                </Grid>
            </Container>
        </PageBase>
    );
};

export default Home;
