import { useEffect, useState } from "react";
import fileUtils from "../../utils/fileUtils";

const useDocumentsForAllSites = ({ data }) => {
  const [sites, setSites] = useState([]);
  useEffect(() => {
    const getTrees = async () => {
      let trees = [];
      for (let i = 0; i < data.length; i++) {
        trees.push({
          externalId: data[i].accountExternalId,
          name: data[i].accountName,
          tree: await fileUtils.getObjectFromFileNames(
            data[i].documents,
            data[i].accountExternalId
          ),
        });
      }
      if (!data?.length) return;
      setSites(trees);
    };
    getTrees();
  }, [data]);

  return sites;
};
export default useDocumentsForAllSites;
