import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import wordpressService from "../../services/wordpressService";

const useLatestBlogPosts = () =>
  useQuery(
    queryKeys.latestBlogPosts,
    wordpressService.fetchLatestWorknestBlogPosts,
    {
      staleTime: Infinity,
    }
  );

export default useLatestBlogPosts;
