import React, { useState } from "react";
import {
    Box, 
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
	useMediaQuery,
    useTheme,
    FormControlLabel,
    Checkbox,
    makeStyles,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import useDeleteTemplateForm from "../../../../safetynest/hooks/mutations/useDeleteTemplateForm";
import {useSnackbar} from "notistack";
import { capitaliseFirstLetter } from "../../../../utils/stringUtils";

const useStyles = makeStyles((theme) => ({
    delButton: {
        backgroundColor: "red",
        color: "white" 
    },
}));

const TemplateFormDeleteDialog = ({ isOpen, setIsOpen, form, page, rows, moduleArea }) => {
    const classes = useStyles();
    const deleteTemplateForm = useDeleteTemplateForm();

    const { selectedSite, activeAppId, appSiteIds } = useSelector(
		(state) => state.account
    );
    const [removeAssociatedActions, setRemoveAssociatedActions] = useState(true);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
		setIsOpen(false);
	};

    const handleSubmit = async e => {
        e.preventDefault();

        const updateData = {
            templateFormId: form.id,
            externalIds: selectedSite
                ? [selectedSite.externalId]
                : appSiteIds[activeAppId],
            page: page + 1,
            rows: rows,
            moduleArea,
            removeAssociatedActions,

        };
        deleteTemplateForm.mutate(updateData, {
            onSuccess: () => handleClose(),
            onError: (error) => {
                console.error(error);
                enqueueSnackbar(error?.message || "There was an error deleting the template form",
                    {
                        variant: "error"
                    });
            },
        });
    }

    const getDialogContent = () => {
            return(
                <>
                    <Grid container spacing={2}>
                        <Grid item>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Form and its history will be permanently deleted.
                                </DialogContentText>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={removeAssociatedActions}
                                            onChange={(e) => setRemoveAssociatedActions(e.target.checked)}
                                        />
                                    }
                                    label="Delete any linked actions"
                                />
                            </DialogContent>

                        </Grid>
                    </Grid>
                </>
            );
    }

    return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			fullScreen={fullScreen}
		>
			<DialogTitle>{`Delete ${capitaliseFirstLetter(moduleArea)} Form`}</DialogTitle>
			<form onSubmit={handleSubmit}>
				<Box m={3}>
					{getDialogContent()}
				</Box>
                <DialogActions>
                    {deleteTemplateForm.isLoading ?
						<CircularProgress /> :
						<>
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                            <Button type="submit" className={classes.delButton}>Delete</Button>
						</>}
				</DialogActions>
			</form>
        </Dialog>
    );
}

export default TemplateFormDeleteDialog;