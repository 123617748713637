import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useDeleteFolder from "../../hooks/mutations/useDeleteFolder";
import useRenameFolder from "../../hooks/mutations/useRenameFolder";
import { useSnackbar } from "notistack";
import {
    List,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography,
    TextField,
    makeStyles,
} from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { setSelectedFolder } from "../../redux/actions/documentsActions";
import { documentTypes } from "../../constants/documentConstants";
import RecursiveTree from "./RecursiveTree";
import useSiblingFolderNames from "../../hooks/documents/useSiblingFolderNames";

const useStyles = makeStyles(() => ({
    textField: {
        minWidth: "300px",
    },
    deleteButton: {
        color: "red",
    },
}));

const FolderList = ({ sites, documentType }) => {
    const classes = useStyles();
    const { selectedFolder } = useSelector((state) => state.documents);

    const [menuAnchor, setMenuAnchor] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [openRename, setOpenRename] = useState(false);
    const [selected, setSelected] = useState("");

    const [menuFolder, setMenuFolder] = useState(null);

    const [newFolderName, setNewFolderName] = useState("");

    const deleteFolder = useDeleteFolder();
    const renameFolder = useRenameFolder();
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const siblingFolderNames = useSiblingFolderNames({
        folder: menuFolder,
        sites,
    });

    const handleRenameClick = () => {
        setNewFolderName(menuFolder.split("/").slice(-1)[0]);
        setMenuAnchor(null);
        setOpenRename(true);
    };

    const handleDeleteClick = () => {
        setMenuAnchor(null);
        setOpenDelete(true);
    };

    const validNewName = useMemo(() => {
        let name = newFolderName.trim();

        if (name === "")
            return false;

        if (name === menuFolder?.split("/").slice(-1)[0])
            return false;

        return !siblingFolderNames.includes(name);
    }, [menuFolder, newFolderName, siblingFolderNames]);

    const confirmDelete = () => {
        setOpenDelete(false);

        selectParent();

        deleteFolder.mutate(
            {
                documentType: documentType.value,
                folder: menuFolder,
            },
            {
                onError: (e) =>
                    enqueueSnackbar(
                        e?.message || "There was an error deleting the folder",
                        { variant: "error" }
                    ),
                onSuccess: () => enqueueSnackbar("Folder deleted"),
            }
        );
    };

    const selectParent = () => {
        let elements = menuFolder.split("/");
        elements.pop();
        selectFolder(elements.join("/"));
    };

    const confirmRename = () => {
        setOpenRename(false);

        let elements = menuFolder.split("/");
        const oldFolderName = elements.pop();
        let newPath = elements.join("/") + "/" + newFolderName.trim();

        renameFolder.mutate(
            {
                documentType: documentType.value,
                folder: menuFolder,
                newFolder: newPath,
            },
            {
                onError: (e) =>
                    enqueueSnackbar(
                        e?.message || "There was an error renaming the folder",
                        { variant: "error" }
                    ),
                onSuccess: () =>
                    enqueueSnackbar(`'${oldFolderName}' renamed to '${newFolderName}'`),
            }
        );
        selectFolder(newPath);
        setNewFolderName("");
    };

    const clearMenu = () => {
        setMenuAnchor(null);
        setMenuFolder(null);
    };

    const handleClickMenu = (target, path) => {
        setMenuAnchor(target);
        setMenuFolder(path);
    };

    const selectFolder = (folder) => {
        setSelected(folder);
        dispatch(setSelectedFolder(folder));
    };

    return (
        <React.Fragment>
            <List disablePadding>
                <RecursiveTree
                    sites={sites}
                    handleClickMenu={documentType === documentTypes.ACCOUNT ? handleClickMenu : null}
                    selected={selected}
                    setSelected={setSelected}
                />
            </List>
            {documentType === documentTypes.ACCOUNT && (
                <React.Fragment>
                    <Menu
                        id="long-menu"
                        anchorEl={menuAnchor}
                        keepMounted
                        open={!!menuAnchor}
                        onClose={clearMenu}
                    >
                        <MenuItem onClick={handleRenameClick}>
                            <EditIcon />
                            &nbsp;Rename
                        </MenuItem>
                        <MenuItem onClick={handleDeleteClick}>
                            <DeleteIcon />
                            &nbsp;Delete
                        </MenuItem>
                    </Menu>
                    <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
                        <DialogTitle>
                            <Typography>Delete Folder?</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                '{menuFolder?.split("/").pop()}' and all its contents will be
                                permanently deleted
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDelete(false)} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={confirmDelete} className={classes.deleteButton}>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openRename} onClose={() => setOpenRename(false)}>
                        <DialogTitle>
                            <Typography>Rename {selectedFolder.description}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                variant="outlined"
                                label="Folder Name"
                                value={newFolderName}
                                onChange={(e) => setNewFolderName(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenRename(false)} color="secondary">
                                Cancel
                            </Button>
                            <Button
                                onClick={confirmRename}
                                color="secondary"
                                disabled={!validNewName}
                            >
                                Rename
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default FolderList;
