import axios from "../utils/axios";

const tutorialEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/tutorial`;

const fetchAllActive = () => axios.get(`${tutorialEndpointUrl}`);
const fetchAllActiveList = () => axios.get(`${tutorialEndpointUrl}/list`);
const add = (tutorial) => axios.post(`${tutorialEndpointUrl}`, tutorial);
const fetchById = (tutorialId) =>
  axios.get(`${tutorialEndpointUrl}/${tutorialId}`);
const deleteById = (tutorialId) =>
  axios.delete(`${tutorialEndpointUrl}/${tutorialId}`);
const update = (tutorial) => axios.patch(`${tutorialEndpointUrl}`, tutorial);
const fetchViewsForUser = () => axios.get(`${tutorialEndpointUrl}/views`);
const addViewForUser = (tutorialId) =>
  axios.post(`${tutorialEndpointUrl}/views/${tutorialId}`);

const tutorialService = {
  fetchAllActive,
  fetchAllActiveList,
  add,
  fetchById,
  deleteById,
  update,
  fetchViewsForUser,
  addViewForUser,
};

export default tutorialService;
