import {IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Save } from '@material-ui/icons'
import { KeyboardDatePicker } from '@material-ui/pickers'
import React, { useState } from 'react'
import ResponsiveSelect from '../../../components/ui/ResponsiveSelect'
import { useRiskAssessmentDetails } from '../../contexts/RiskAssessmentContext'
import useContactsForAppAndSite from "../../../hooks/useContactsForAppAndSite";
import { ratingList } from "../../constants/actionRatingConstants";
import { format, isValid } from "date-fns";

const useStyles = makeStyles((theme) => ({
    hazardContainer: {
        border: `solid 1px ${grey[400]}`,
        borderRadius: '4px',
        padding: theme.spacing(2),
    },
    hazardIcons: {
        height: '40px',
        marginRight: theme.spacing(2),
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
        gap: theme.spacing(2),
    },
    controlTable: {
        width: '100%',
    },
    selectors: {
        width: '20%'
    },
    hazardName: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    assignMultiple: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
    },
    saveAction: {
        display: 'flex',
        alignItems: 'center',
    },
}))

const hazardImagesUrl = `${process.env.PUBLIC_URL}/images/hazardIcons/`

export default function HazardActions() {
    const classes = useStyles()
    const { 
        riskAssessmentDetails,
        addActionDueDate,
        updateActionDueDate,
        addActionAssignedEmployee,
        updateActionAssignedEmployee,
        addCustomAction,
        selectedSiteId,
        addActionRating,
        updateActionRating,
    } = useRiskAssessmentDetails()
    
    const incompleteActions = riskAssessmentDetails?.incompleteActions

    const allHazards = {...riskAssessmentDetails?.hazards ?? {}, ...riskAssessmentDetails?.customHazards ?? {}}
    const hazardsWithActions = Object.keys(allHazards).filter(hazardId => allHazards[hazardId]?.hazardControls?.filter(hazardControl => !hazardControl.hasOwnProperty('inPlace') || !hazardControl.inPlace)?.length > 0)
    const customHazardsWithActions = Object.keys(allHazards).filter(hazardId => allHazards[hazardId]?.customHazardControls?.filter(customHazardControl => !customHazardControl.hasOwnProperty('inPlace') || !customHazardControl.inPlace)?.length > 0)
    const uniqueHazardsWithActions = Array.from(new Set([...hazardsWithActions, ...customHazardsWithActions]));

    const contacts = useContactsForAppAndSite({ selectedSiteId });

    const [actionDescription, setActionDescription] = useState({})

    function saveCustomAction(hazardId, controlId, isCustomHazard, isCustomControl) {
        addCustomAction({hazardId, controlId, actionDescription: actionDescription[controlId], isCustomHazard, isCustomControl })
    }

    const onDueDateChange = ({dueDate, actionId, employeeId, rating}) => {
        if (!dueDate || isValid(new Date(dueDate))) {
            var formattedDate = format(new Date(dueDate), "yyyy-MM-dd'T'hh:mm:ss");
            updateActionDueDate({ dueDate: formattedDate, actionId, employeeId, rating})
        }
    };

    return (
        <div className={classes.actionsContainer}>
            {incompleteActions?.length > 0 && 
                <div className={classes.hazardContainer}>
                    <Typography variant='subtitle2'>
                        Incomplete Actions
                    </Typography>
                    <TableContainer>
                        <Table size='small' className={classes.controlTable}>
                            <TableHead>
                                <TableCell>Action</TableCell>
                                <TableCell>Priority</TableCell>
                                <TableCell>Due Date</TableCell>
                                <TableCell>Assigned Employee</TableCell>
                            </TableHead>
                            <TableBody>
                                {incompleteActions.map(action => (
                                    <TableRow key={action.id}>
                                        <TableCell>{action.description}</TableCell>
                                        <TableCell>
                                            <ResponsiveSelect
                                                size='small'
                                                fullWidth
                                                required
                                                options={ratingList}
                                                optionValueKey="value"
                                                optionLabelFunction={(x) => x.label}
                                                value={action.rating}
                                                onChange={(e) => updateActionRating({ employeeId: action.employeeId, rating: e.target.value, actionId: action.id, dueDate: action.dueDate })}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <KeyboardDatePicker
                                                size='small'
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                value={action.dueDate}
                                                onChange={(date) => onDueDateChange({ dueDate: date, actionId: action.id, employeeId: action.employeeId, rating: action.rating})}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <ResponsiveSelect 
                                                size='small'
                                                fullWidth
                                                required
                                                options={Object.values(contacts?.data ?? [])}
                                                optionValueKey="externalId"
                                                optionLabelFunction={(x) => `${x.firstName} ${x.lastName}`}
                                                value={action.employeeId}
                                                onChange={(e) => updateActionAssignedEmployee({ employeeId: e.target.value, actionId: action.id, rating: action.rating, dueDate: action.dueDate})}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
            {uniqueHazardsWithActions?.length > 0 ? uniqueHazardsWithActions?.map(id => {
                const hazard = allHazards[id]
                const img = new Image()
                img.src = `${hazardImagesUrl}${hazard?.name}.svg`
                const isCustomHazard = Object.keys(riskAssessmentDetails?.customHazards)?.includes(id)

                return (
                    <div className={classes.hazardContainer} key={id}>
                        <div className={classes.hazardName}>
                            <img className={classes.hazardIcons} alt='warning' src={img.complete ? img.src : `${hazardImagesUrl}General warning.svg`} />
                            <Typography variant='subtitle2'>{hazard?.name}</Typography>
                        </div>
                        <TableContainer>
                            <Table className={classes.controlTable} size='small'>
                                <TableHead>
                                    <TableCell>Control</TableCell>
                                    <TableCell>Action</TableCell>
                                    <TableCell className={classes.selectors}>Assign Priority</TableCell>
                                    <TableCell className={classes.selectors}>Assign Due Date</TableCell>
                                    <TableCell className={classes.selectors}>Assign Employee</TableCell>
                                </TableHead>
                                <TableBody>
                                    {hazard?.hazardControls?.map(control => {
                                        if ((!control?.hasOwnProperty('inPlace') || !control?.inPlace)) {
                                            control.dueDate = control?.dueDate ?? new Date()
                                            const controlAction = control?.control?.controlAction && control.control.controlAction[0]
                                            return (
                                                <TableRow key={control?.id}>
                                                    <TableCell>
                                                        {control?.control?.name ?? 'Control name not found.'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            controlAction?.actionPresetDescription?.length ?
                                                            control?.control?.controlAction[0]?.actionPresetDescription :
                                                            control?.riskAssessmentControl?.riskAssessmentControlAction?.actionPresetDescription?.length ?
                                                            control?.riskAssessmentControl?.riskAssessmentControlAction?.actionPresetDescription : 
                                                            <div className={classes.saveAction}>
                                                                <TextField 
                                                                    placeholder='Enter action description.' 
                                                                    required 
                                                                    variant='outlined'
                                                                    size='small'
                                                                    fullWidth
                                                                    value={actionDescription[control?.id]}
                                                                    onChange={(e) => setActionDescription({...actionDescription, [control?.id]: e.target.value})}
                                                                />
                                                                <IconButton onClick={() => saveCustomAction(id, control?.id, isCustomHazard, control?.isCustomControl)}>
                                                                    <Save />
                                                                </IconButton>
                                                            </div>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <ResponsiveSelect
                                                            size='small'
                                                            fullWidth
                                                            required
                                                            options={ratingList}
                                                            optionValueKey={"value"}
                                                            optionLabelFunction={(x) => x.label}
                                                            label="Priority"
                                                            value={control?.riskAssessmentControl?.riskAssessmentControlAction?.rating}
                                                            onChange={(e) => addActionRating({ controlId: control?.id, hazardId: id, rating: e.target.value, isCustomHazard })}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <KeyboardDatePicker
                                                            size='small'
                                                            format="dd/MM/yyyy"
                                                            inputVariant="outlined"
                                                            value={control?.riskAssessmentControl?.riskAssessmentControlAction?.dueDate}
                                                            onChange={(date) => addActionDueDate({ controlId: control?.id, hazardId: id, dueDate: date, isCustomHazard })}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <ResponsiveSelect 
                                                            size='small'
                                                            fullWidth
                                                            required
                                                            options={Object.values(contacts?.data ?? [])}
                                                            optionValueKey="externalId"
                                                            optionLabelFunction={(x) => `${x.firstName} ${x.lastName}`}
                                                            value={control?.riskAssessmentControl?.riskAssessmentControlAction?.employeeId}
                                                            onChange={(e) => addActionAssignedEmployee({ controlId: control?.id, hazardId: id, employeeId: e.target.value, isCustomHazard })}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    })}
                                     {hazard?.customHazardControls?.map(control => {
                                        if ((!control?.hasOwnProperty('inPlace') || !control?.inPlace)) {
                                            control.dueDate = control?.dueDate ?? new Date()
                                            const controlAction = control?.control?.controlAction && control.control.controlAction[0]
                                            return (
                                                <TableRow key={control?.id}>
                                                    <TableCell>
                                                        {control?.control?.name ?? 'Control name not found.'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            controlAction?.actionPresetDescription?.length ?
                                                                control?.control?.controlAction[0]?.actionPresetDescription :
                                                                control?.riskAssessmentControl?.riskAssessmentControlAction?.actionPresetDescription?.length ?
                                                                    control?.riskAssessmentControl?.riskAssessmentControlAction?.actionPresetDescription :
                                                                    <div className={classes.saveAction}>
                                                                        <TextField
                                                                            placeholder='Enter action description.'
                                                                            required
                                                                            variant='outlined'
                                                                            size='small'
                                                                            fullWidth
                                                                            value={actionDescription[control?.id]}
                                                                            onChange={(e) => setActionDescription({ ...actionDescription, [control?.id]: e.target.value })}
                                                                        />
                                                                        <IconButton onClick={() => saveCustomAction(id, control?.id, isCustomHazard, control?.isCustomControl)}>
                                                                            <Save />
                                                                        </IconButton>
                                                                    </div>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <ResponsiveSelect
                                                            size='small'
                                                            fullWidth
                                                            required
                                                            options={ratingList}
                                                            optionValueKey={"value"}
                                                            optionLabelFunction={(x) => x.label}
                                                            label="Priority"
                                                            value={control?.riskAssessmentControl?.riskAssessmentControlAction?.rating}
                                                            onChange={(e) => addActionRating({ controlId: control?.id, hazardId: id, rating: e.target.value, isCustomHazard, isCustomControl: control?.isCustomControl })}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <KeyboardDatePicker
                                                            size='small'
                                                            format="dd/MM/yyyy"
                                                            inputVariant="outlined"
                                                            value={control?.riskAssessmentControl?.riskAssessmentControlAction?.dueDate}
                                                            onChange={(date) => addActionDueDate({ controlId: control?.id, hazardId: id, dueDate: date, isCustomHazard, isCustomControl: control?.isCustomControl })}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <ResponsiveSelect
                                                            size='small'
                                                            fullWidth
                                                            required
                                                            options={Object.values(contacts?.data ?? [])}
                                                            optionValueKey="externalId"
                                                            optionLabelFunction={(x) => `${x.firstName} ${x.lastName}`}
                                                            value={control?.riskAssessmentControl?.riskAssessmentControlAction?.employeeId}
                                                            onChange={(e) => addActionAssignedEmployee({ controlId: control?.id, hazardId: id, employeeId: e.target.value, isCustomHazard, isCustomControl : control?.isCustomControl })}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )
            })
            :
                <div> 
                    No Actions created
                </div>
            }
        </div>
    )
}