import {
    ADD_QUESTION,
    ADD_QUESTION_OPTION,
    ADD_SECTION,
    ADD_EDITABLE_SECTION,
    DELETE_QUESTION,
    DELETE_QUESTION_OPTION,
    DELETE_SECTION,
    INSERT_QUESTION,
    INSERT_SECTION,
    SET_QUESTION_OPTIONS,
    REORDER_QUESTION,
    REORDER_SECTION,
    SET_QUESTION_ALLOW_PHOTO_UPLOAD,
    SET_QUESTION_ATTACHMENT_MANDATORY,
    SET_QUESTION_ANSWER_TYPE,
    SET_QUESTION_MAX_VALUE,
    SET_QUESTION_MIN_VALUE,
    SET_QUESTION_OPTION_TEXT,
    SET_QUESTION_TEXT,
    SET_SECTION_TEXT,
    CLEAR_TEMPLATE,
    ADD_OUTCOME,
    DELETE_OUTCOME,
    SET_CHOICE_OUTCOME_OPTION_ID,
    SET_OUTCOME_LOGIC_OPERATOR,
    SET_NUMBER_OUTCOME_LOGIC_OPERATOR,
    SET_OUTCOME_ACTION_PRESET_ID,
    SET_NUMBER_OUTCOME_VALUE1,
    SET_NUMBER_OUTCOME_VALUE2,
    CLEAR_TEMPLATE_OUTCOMES,
    SET_TEMPLATE_APPLICATION_AREA,
    SET_QUESTION_MANDATORY,
    SET_QUESTION_HELP_INFO_REQUIRED,
    SET_QUESTION_HELP_INFO_TEXT,
    SET_QUESTION_OPTION_REQUIRES_ADDITIONAL_INFO,
    SET_OUTCOME_RATING,
    SET_TEMPLATE_NAME,
    SET_TEMPLATE_FREQUENCY,
    SET_TEMPLATE_SECTOR,
    SET_TEMPLATE_SUBJECT,
    SET_IS_GLOBAL_TEMPLATE,
    SET_INCIDENT_TYPE,
    SET_TEMPLATE_FREQUENCY_INTERVAL,
    SET_TEMPLATE_FREQUENCY_DAYS,
} from "../types";
import { reorder } from "../../utils/arrayUtils";
import { nanoid } from "nanoid";
import { answerTypes } from "../../safetynest/constants/answerConstants";
import {
    choiceOutcomeLogic,
    dateOutcomeLogic,
    numberOutcomeLogic,
} from "../../constants/outcomeLogicConstants";

const newQuestion = {
    text: "",
    allowPhotoUpload: false,
    attachmentMandatory: false,
    isMandatory: false,
    isHelpInfoRequired: false,
    helpInfoText: "",
    answerType: answerTypes.TEXT,
    rangeRestriction: {
        minValue: "",
        maxValue: "",
    },
    optionIds: [],
    outcomeIds: [],
};

const newNumberOutcome = {
    actionPresetId: 0,
    numberOutcome: {
        logicType: numberOutcomeLogic.lessThanOrEqualTo,
        value1: 0,
        value2: 0,
    }
};

const newDateOutcome = {
    actionPresetId: 0,
    logicType: dateOutcomeLogic.after,
    value1: new Date(),
    value2: new Date(),
};

const newChoiceOutcome = {
    actionPresetId: 0,
    logicType: choiceOutcomeLogic.is,
    templateQuestionOptionId: "",
};

const newSectionWithQuestionId = (questionId) => ({
    name: "",
    questionIds: [questionId],
});

const initialState = {
    applicationArea: null,
    sectionIds: [],
    investigationSectionIds: [],
    sections: {},
    questions: {},
    options: {},
    choiceOutcomes: {},
};

const newId = () => nanoid(5);

function templateBuilderReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SECTION_TEXT: {
            return {
                ...state,
                hasUpdated: true,
                sections: {
                    ...state.sections,
                    [action.payload.sectionId]: {
                        ...state.sections[action.payload.sectionId],
                        name: action.payload.text,
                    },
                },
            };
        }
        case REORDER_SECTION: {
            return {
                ...state,
                hasUpdated: true,
                sectionIds: !action.payload.isInvestigation
                    ? reorder(
                        state.sectionIds,
                        action.payload.startIndex,
                        action.payload.endIndex
                    )
                    : state.sectionIds,
                investigationSectionIds: action.payload.isInvestigation
                    ? reorder(
                        state.investigationSectionIds,
                        action.payload.startIndex,
                        action.payload.endIndex
                    )
                    : state.investigationSectionIds,
            };
        }
        case DELETE_SECTION: {
            const newSections = { ...state.sections };
            const newQuestions = { ...state.questions };
            const sectionToDelete = newSections[action.payload.sectionId];

            for (const questionId of sectionToDelete.questionIds) {
                delete newQuestions[questionId];
            }

            delete newSections[action.payload.sectionId];

            return {
                ...state,
                hasUpdated: true,
                sectionIds: !action.payload.isInvestigation
                    ? state.sectionIds.filter((x) => x !== action.payload.sectionId)
                    : state.sectionIds,
                investigationSectionIds: action.payload.isInvestigation
                    ? state.investigationSectionIds.filter(
                        (x) => x !== action.payload.sectionId
                    )
                    : state.investigationSectionIds,
                sections: newSections,
                questions: newQuestions,
            };
        }
        case ADD_EDITABLE_SECTION: {
            const questions = action.payload.section.questions.reduce((curr, val) => {
                const choiceOutcomes = [].concat.apply([], val.options?.map(x => x.choiceOutcomes?.map(x => x.id)))
                const outcomes = val.outcomes?.map(x => x.id)
                const allOutcomes = [...choiceOutcomes, ...outcomes]

                return {
                    ...curr,
                    [val.id]: {
                        ...val,
                        outcomeIds: allOutcomes,
                        optionIds: val.options?.map(x => x.id)
                    },
                };
            }, {})

            const choiceOutcomes = action.payload.section.questions.reduce((curr, val) => {
                return {
                    ...curr,
                    ...val.outcomes?.reduce((a, b) => {
                        return {
                            ...a,
                            [b.id]: {
                                ...b
                            }
                        }
                    }, {}),
                    ...val.options?.reduce((a, b) => {
                        return {
                            ...a,
                            ...b.choiceOutcomes?.reduce((c, d) => {
                                return {
                                    ...c,
                                    [d.id]: {
                                        ...d
                                    }
                                }
                            }, {})
                        }
                    }, {})
                }
            }, {})

            const options = action.payload.section.questions.reduce((curr, val) => {
                return {
                    ...curr,
                    ...val.options?.reduce((a, b) => {
                        return {
                            ...a,
                            [b.id]: {
                                ...b
                            }
                        }
                    }, {})
                }
            }, {})

            return {
                ...state,
                sectionIds: !action.payload.section.isInvestigation
                    ? [...state.sectionIds, action.payload.section.id]
                    : state.sectionIds,
                investigationSectionIds: action.payload.section.isInvestigation
                    ? [...state.investigationSectionIds, action.payload.section.id]
                    : state.investigationSectionIds,
                sections: {
                    ...state.sections,
                    [action.payload.section.id]: {
                        ...action.payload.section,
                        questionIds: action.payload.section.questions.map(x => x.id)
                    },
                },
                questions: {
                    ...state.questions,
                    ...questions
                },
                options: {
                    ...state.options,
                    ...options
                },
                choiceOutcomes: {
                    ...state.choiceOutcomes,
                    ...choiceOutcomes
                }
            }
        }
        case ADD_SECTION: {
            const newSectionId = newId();
            const newQuestionId = newId();
            return {
                ...state,
                sectionIds: !action.payload.isInvestigation
                    ? [...state.sectionIds, newSectionId]
                    : state.sectionIds,
                investigationSectionIds: action.payload.isInvestigation
                    ? [...state.investigationSectionIds, newSectionId]
                    : state.investigationSectionIds,
                sections: {
                    ...state.sections,
                    [newSectionId]: newSectionWithQuestionId(newQuestionId),
                },
                questions: { ...state.questions, [newQuestionId]: newQuestion },
            };
        }
        case INSERT_SECTION: {
            const newSectionId = newId();
            const newQuestionId = newId();
            const newSectionIds = [...state.sectionIds];
            const newInvestigationSectionIds = [...state.investigationSectionIds];

            if (action.payload.isInvestigation)
                newInvestigationSectionIds.splice(
                    action.payload.atIndex,
                    0,
                    newSectionId
                );
            else newSectionIds.splice(action.payload.atIndex, 0, newSectionId);

            return {
                ...state,
                sectionIds: newSectionIds,
                investigationSectionIds: newInvestigationSectionIds,
                sections: {
                    ...state.sections,
                    [newSectionId]: newSectionWithQuestionId(newQuestionId),
                    isInvestigation: action.payload.isInvestigation,
                },
                questions: { ...state.questions, [newQuestionId]: newQuestion },
            };
        }
        case ADD_QUESTION: {
            const newQuestionId = newId();
            return {
                ...state,
                hasUpdated: true,
                sections: {
                    ...state.sections,
                    [action.payload]: {
                        ...state.sections[action.payload],
                        questionIds: [
                            ...state.sections[action.payload].questionIds,
                            newQuestionId,
                        ],
                    },
                },
                questions: { ...state.questions, [newQuestionId]: newQuestion },
            };
        }
        case INSERT_QUESTION: {
            const newQuestionId = newId();
            const newQuestionIds = [
                ...state.sections[action.payload.sectionId].questionIds,
            ];
            newQuestionIds.splice(action.payload.atIndex, 0, newQuestionId);

            return {
                ...state,
                hasUpdated: true,
                sections: {
                    ...state.sections,
                    [action.payload.sectionId]: {
                        ...state.sections[action.payload.sectionId],
                        questionIds: newQuestionIds,
                    },
                },
                questions: { ...state.questions, [newQuestionId]: newQuestion },
            };
        }
        case DELETE_QUESTION: {
            const newQuestions = { ...state.questions };
            delete newQuestions[action.payload.questionId];
            return {
                ...state,
                hasUpdated: true,
                sections: {
                    ...state.sections,
                    [action.payload.sectionId]: {
                        ...state.sections[action.payload.sectionId],
                        questionIds: state.sections[
                            action.payload.sectionId
                        ].questionIds.filter((x) => x !== action.payload.questionId),
                    },
                },
                questions: newQuestions,
            };
        }
        case REORDER_QUESTION: {
            return {
                ...state,
                hasUpdated: true,
                sections: {
                    ...state.sections,
                    [action.payload.sectionId]: {
                        ...state.sections[action.payload.sectionId],
                        questionIds: reorder(
                            state.sections[action.payload.sectionId].questionIds,
                            action.payload.startIndex,
                            action.payload.endIndex
                        ),
                    },
                },
            };
        }
        case SET_QUESTION_TEXT: {
            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        text: action.payload.text,
                    },
                },
            };
        }
        case SET_QUESTION_MIN_VALUE: {
            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        rangeRestriction: {
                            ...state.questions[action.payload.questionId].rangeRestriction,
                            minValue: action.payload.minValue,
                        }
                    },
                },
            };
        }
        case SET_QUESTION_MAX_VALUE: {
            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        rangeRestriction: {
                            ...state.questions[action.payload.questionId].rangeRestriction,
                            maxValue: action.payload.maxValue,
                        }
                    },
                },
            };
        }
        case SET_QUESTION_ANSWER_TYPE: {
            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        answerType: action.payload.answerType,
                    },
                },
            };
        }
        case SET_QUESTION_ALLOW_PHOTO_UPLOAD: {
            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        allowPhotoUpload: action.payload.allowPhotoUpload,
                        attachmentMandatory: false,
                    },
                },
            };
        }
        case SET_QUESTION_ATTACHMENT_MANDATORY: {
            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        attachmentMandatory: action.payload.attachmentMandatory,
                    },
                },
            };
        }
        case ADD_QUESTION_OPTION: {
            const newOptionId = newId();
            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload]: {
                        ...state.questions[action.payload],
                        optionIds: [
                            ...state.questions[action.payload].optionIds ?? [],
                            newOptionId,
                        ],
                    },
                },
                options: {
                    ...state.options,
                    [newOptionId]: { id: newOptionId, option: "" },
                },
            };
        }
        case SET_QUESTION_OPTIONS: {
            const newOptions = action.payload.options.map((optionText) => ({
                id: newId(),
                option: optionText,
                requiresAdditionalInfo: false,
            }));

            const newOptionsObject = {};

            for (const newOption of newOptions) {
                newOptionsObject[newOption.id] = {
                    id: newOption.id,
                    option: newOption.text,
                };
            }

            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        optionIds: newOptions.map((x) => x.id),
                    },
                },
                options: { ...state.options, ...newOptionsObject },
            };
        }
        case DELETE_QUESTION_OPTION: {
            const newOptions = { ...state.options };
            delete newOptions[action.payload.optionId];
            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        optionIds: state.questions[
                            action.payload.questionId
                        ].optionIds.filter((x) => x !== action.payload.optionId),
                    },
                },
                options: newOptions,
            };
        }
        case SET_QUESTION_OPTION_TEXT: {
            return {
                ...state,
                hasUpdated: true,
                options: {
                    ...state.options,
                    [action.payload.optionId]: {
                        ...state.options[action.payload.optionId],
                        option: action.payload.text,
                    },
                },
            };
        }
        case SET_QUESTION_OPTION_REQUIRES_ADDITIONAL_INFO: {
            return {
                ...state,
                hasUpdated: true,
                options: {
                    ...state.options,
                    [action.payload.optionId]: {
                        ...state.options[action.payload.optionId],
                        requiresAdditionalInfo: action.payload.requiresAdditionalInfo,
                    },
                },
            };
        }
        case SET_QUESTION_MANDATORY: {
            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        isMandatory: action.payload.isMandatory,
                    },
                },
            };
        }
        case SET_QUESTION_HELP_INFO_REQUIRED: {
            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        isHelpInfoRequired: action.payload.isHelpInfoRequired,
                    },
                },
            };
        }
        case SET_QUESTION_HELP_INFO_TEXT: {
            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        helpInfoText: action.payload.helpInfoText,
                    },
                },
            };
        }
        case CLEAR_TEMPLATE: {
            return { ...initialState };
        }
        case ADD_OUTCOME: {
            const newOutcomeId = newId();
            const isChoiceAnswerType =
                action.payload.answerType === answerTypes.DROPDOWN ||
                action.payload.answerType === answerTypes.RADIO;
            const isNumberAnswerType =
                action.payload.answerType === answerTypes.NUMBER;

            const newOutcome = isChoiceAnswerType
                ? newChoiceOutcome
                : isNumberAnswerType
                    ? newNumberOutcome
                    : newDateOutcome;

            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        outcomeIds: [
                            ...state.questions[action.payload.questionId].outcomeIds,
                            newOutcomeId,
                        ],
                    },
                },
                choiceOutcomes: { ...state.choiceOutcomes, [newOutcomeId]: newOutcome },
            };
        }
        case DELETE_OUTCOME: {
            const newOutcomes = { ...state.choiceOutcomes };
            delete newOutcomes[action.payload.outcomeId];
            return {
                ...state,
                hasUpdated: true,
                questions: {
                    ...state.questions,
                    [action.payload.questionId]: {
                        ...state.questions[action.payload.questionId],
                        outcomeIds: state.questions[
                            action.payload.questionId
                        ].outcomeIds.filter((x) => x !== action.payload.outcomeId),
                    },
                },
                choiceOutcomes: newOutcomes,
            };
        }
        case SET_CHOICE_OUTCOME_OPTION_ID: {
            return {
                ...state,
                choiceOutcomes: {
                    ...state.choiceOutcomes,
                    [action.payload.outcomeId]: {
                        ...state.choiceOutcomes[action.payload.outcomeId],
                        templateQuestionOptionId: action.payload.optionId,
                    },
                },
            };
        }
        case SET_NUMBER_OUTCOME_LOGIC_OPERATOR: {
            return {
                ...state,
                choiceOutcomes: {
                    ...state.choiceOutcomes,
                    [action.payload.outcomeId]: {
                        ...state.choiceOutcomes[action.payload.outcomeId],
                        numberOutcome: {
                            ...state.choiceOutcomes[action.payload.outcomeId].numberOutcome,
                            logicType: action.payload.logicOperator,
                        }
                    },
                },
            };
        }
        case SET_OUTCOME_LOGIC_OPERATOR: {
            return {
                ...state,
                choiceOutcomes: {
                    ...state.choiceOutcomes,
                    [action.payload.outcomeId]: {
                        ...state.choiceOutcomes[action.payload.outcomeId],
                        logicType: action.payload.logicOperator,
                    },
                },
            };
        }
        case SET_OUTCOME_ACTION_PRESET_ID: {
            return {
                ...state,
                hasUpdated: true,
                choiceOutcomes: {
                    ...state.choiceOutcomes,
                    [action.payload.outcomeId]: {
                        ...state.choiceOutcomes[action.payload.outcomeId],
                        actionPresetId: action.payload.actionPresetId,
                    },
                },
            };
        }
        case SET_OUTCOME_RATING: {
            return {
                ...state,
                hasUpdated: true,
                choiceOutcomes: {
                    ...state.choiceOutcomes,
                    [action.payload.outcomeId]: {
                        ...state.choiceOutcomes[action.payload.outcomeId],
                        rating: action.payload.rating,
                    },
                },
            };
        }
        case SET_NUMBER_OUTCOME_VALUE1: {
            return {
                ...state,
                choiceOutcomes: {
                    ...state.choiceOutcomes,
                    [action.payload.outcomeId]: {
                        ...state.choiceOutcomes[action.payload.outcomeId],
                        numberOutcome: {
                            ...state.choiceOutcomes[action.payload.outcomeId].numberOutcome,
                            value1: action.payload.value1,
                        }
                    },
                },
            };
        }
        case SET_NUMBER_OUTCOME_VALUE2: {
            return {
                ...state,
                choiceOutcomes: {
                    ...state.choiceOutcomes,
                    [action.payload.outcomeId]: {
                        ...state.choiceOutcomes[action.payload.outcomeId],
                        numberOutcome: {
                            ...state.choiceOutcomes[action.payload.outcomeId].numberOutcome,
                            value2: action.payload.value2,
                        }
                    },
                },
            };
        }
        case CLEAR_TEMPLATE_OUTCOMES: {
            const allQuestionIds = Object.keys(state.questions);
            const newQuestionsObject = {};

            for (const questionId of allQuestionIds) {
                newQuestionsObject[questionId] = {
                    ...state.questions[questionId],
                    outcomeIds: [],
                };
            }

            return {
                ...state,
                questions: newQuestionsObject,
                choiceOutcomes: {},
            };
        }
        case SET_TEMPLATE_APPLICATION_AREA: {
            return { ...state, applicationArea: action.payload };
        }
        case SET_TEMPLATE_NAME: {
            return {
                ...state,
                templateName: action.payload.text,
                hasUpdated: action.payload.hasUpdated
            };
        }
        case SET_TEMPLATE_FREQUENCY: {
            return {
                ...state,
                templateFrequency: action.payload.freq,
                hasUpdated: action.payload.hasUpdated
            };
        }
        case SET_TEMPLATE_SECTOR: {
            return {
                ...state,
                templateSectorId: action.payload.sectorId,
                hasUpdated: action.payload.hasUpdated
            };
        }
        case SET_TEMPLATE_SUBJECT: {
            return {
                ...state,
                templateSubjectId: action.payload.subjectId,
                hasUpdated: action.payload.hasUpdated
            };
        }
        case SET_INCIDENT_TYPE: {
            return {
                ...state,
                incidentType: action.payload.incidentType,
                hasUpdated: action.payload.hasUpdated
            };
        }
        case SET_IS_GLOBAL_TEMPLATE: {
            return {
                ...state,
                isGlobalTemplate: action.payload.isGlobalTemplate
            };
        }
        case SET_TEMPLATE_FREQUENCY_INTERVAL: {
            return {
                ...state,
                templateFrequencyInterval: action.payload.frequencyInterval,
                hasUpdated: action.payload.hasUpdated
            };
        }
        case SET_TEMPLATE_FREQUENCY_DAYS: {
            return {
                ...state,
                templateFrequencyDays: action.payload.frequencyDays,
                hasUpdated: action.payload.hasUpdated
            };
        }
        default:
            return state;
    }
}

export default templateBuilderReducer;
