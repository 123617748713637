import React from "react";
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import CustomRadioButton from "./CustomRadioButton";
import BreadboxCheckbox from "./BreadboxCheckbox";
import { permissionOptions } from "../../constants/manageUserOptions";
import { permissionConstants } from "../../constants/manageUserConstants";

const useStyles = makeStyles((theme) => ({
    divider: {
        width: "100%",
        margin: theme.spacing(2, 0),
    },
    lastDivider: {
        width: "100%",
        margin: theme.spacing(2, 0, 4, 0),
    },
    formTitle: {
        fontSize: 17,
        fontWeight: 600,
    },
    radioLabel: {
        fontSize: 14,
        fontWeight: 600,
        marginBottom: theme.spacing(1),
    },
    option: {
        marginLeft: theme.spacing(2),
    },
}));

const Permissions = ({
    data,
    onChange,
    isCaseNestAdmin,
    isSafetyNestAdmin,
    isMyWorkNestAdmin,
    adviceTypes,
    hasIncidentsAccess, 
}) => {
    const classes = useStyles();

    const handleChange = (value, key) => onChange({ ...data, [key]: value });

    const handleRadioChange = (value, key) => {
        if (key === permissionConstants.CASENEST_ALL_CASES) {
            return onChange({
                ...data,
                [key]: value !== permissionConstants.MY_CASES,
            });
        }
        return onChange({
            ...data,
            [key]: value !== permissionConstants.MY_ACTIONS,
        });
    };

    const hasAccess = (label) =>
        (label === permissionConstants.MYWORKNEST && isMyWorkNestAdmin) ||
        (label === permissionConstants.CASENEST && isCaseNestAdmin) ||
        (label === permissionConstants.SAFETYNEST && isSafetyNestAdmin);

    return (
        <Grid spacing={2} container>
            {permissionOptions.map(
                (option, index, { length }) =>
                    hasAccess(option.label) && (
                        <React.Fragment key={option.label}>
                            <Grid item xs={12} key={option.label}>
                                <Typography variant="subtitle1" className={classes.formTitle}>
                                    {option.label}
                                </Typography>
                            </Grid>
                            {option.choices.map((choice, index) =>
                                !choice.isRadioChoice ? (
                                    (!choice.isAdviceType && ((choice.key !== permissionConstants.SAFETYNESTINCIDENTLOGGING) || (choice.key === permissionConstants.SAFETYNESTINCIDENTLOGGING && !hasIncidentsAccess))
                                        || adviceTypes.includes(choice.key)) &&
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.option}
                                        key={choice.key}
                                    >
                                            <BreadboxCheckbox
                                            title={choice.title} 
                                            description={choice.description}
                                            checked={!!data[choice.key]}
                                            onChange={(e) => handleChange(e, choice.key)}
                                        />
                                    </Grid>
                                ) : (
                                    <React.Fragment key={`${choice.label}-${index}`}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body2"
                                                className={classes.radioLabel}
                                            >
                                                {choice.label}
                                            </Typography>
                                        </Grid>
                                        {choice.radioChoices.map((radioChoice) => (
                                            <Grid item xs={12} md={6} key={radioChoice.value}>
                                                <CustomRadioButton
                                                    title={radioChoice.title}
                                                    description={radioChoice.description}
                                                    value={radioChoice.value}
                                                    checked={
                                                        radioChoice.value === permissionConstants.MY_CASES || radioChoice.value === permissionConstants.MY_ACTIONS
                                                            ? !data[radioChoice.key]
                                                            : data[radioChoice.key]
                                                    }
                                                    warningText={radioChoice?.warningText}
                                                    onChange={e => handleRadioChange(e, radioChoice.key)}
                                                />
                                            </Grid>
                                        ))}
                                    </React.Fragment>
                                )
                            )}
                            <Grid item xs={12}>
                                <Divider
                                    className={length - 1 !== index ? classes.divider : classes.lastDivider}
                                />
                            </Grid>
                        </React.Fragment>
                    )
            )}
        </Grid>
    );
};

export default Permissions;
