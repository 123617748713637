import { useMutation } from "react-query";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";
import templateFormService from "../../services/templateFormService";

const useAddTemplateForm = () => 
    useMutation(templateFormService.addNewTemplateForm, {  
        onMutate: async (data) => {
            const { client } = reactQueryUtils;
            const queryKey = [
                queryKeys.templateForms,
                data.siteExternalId,
            ];
            const previousTemplateForms = client.getQueryData(queryKey);

            client.setQueryData(queryKey, (previous) =>
                previous ? [...previousTemplateForms, data] : [data]
            )
            
            return { previousTemplateForms }
        },
        onError: (error, data, context) => {
            reactQueryUtils.client.setQueryData(
              [
                queryKeys.templateForms,
                data.siteExternalId,
              ],
              context.previousTemplateForms
            );
        },
        onSettled: (_data, _error, variables) => {
            if (variables)
                reactQueryUtils.client.invalidateQueries([
                    queryKeys.templateForms,
                    variables.siteExternalId
                ]);
        },
    });

export default useAddTemplateForm;