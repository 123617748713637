export const incidentTypes = {
  ACCIDENT: 0,
  NEAR_MISS: 1,
  DANGEROUS_OCCURENCE: 2,
  OCCUPATION_DISEASE: 3,
};
export const incidentTypeList = [
  {
    value: incidentTypes.ACCIDENT,
    label: "Accident",
  },
  {
    value: incidentTypes.NEAR_MISS,
    label: "Near Miss",
  },
  {
    value: incidentTypes.DANGEROUS_OCCURENCE,
    label: "Dangerous Occurence",
  },
  {
    value: incidentTypes.OCCUPATION_DISEASE,
    label: "Occupation Disease",
  },
];
