import React, { useState, useMemo, useRef, useCallback } from "react";
import {
    Button,
    Grid,
    makeStyles,
    Typography,
    Box,
    Menu,
    MenuItem
} from "@material-ui/core";
import { useTracking } from "react-tracking";
import CardBase from "../../../components/cards/CardBase";
import PageBase from "../../../components/PageBase";
import PageTitle from "../../components/PageTitle";
import PeopleIcon from "@material-ui/icons/People";
import RequiresRoleWrapper from "../../../components/RequiresRoleWrapper";
import { useAuth } from "../../../contexts/authContext";
import { useSelector } from "react-redux";
import GetAppIcon from "@material-ui/icons/GetApp";
import AuditInsightsTableCard from "../../components/insights/AuditInsightsTableCard";
import { auditTypes } from "../../../constants/auditConstants";
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { styled } from "@material-ui/core/styles";
import { ResponsivePie } from "@nivo/pie";
import useAccountAppSites from "../../../hooks/queries/useAccountAppSites";
import useAuditInsights from "../../hooks/queries/useAuditInsights";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import html2canvas from 'html2canvas';
import { generatePdfFromHtml } from "../../services/pdfGeneratorService"

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: theme.palette.secondary.main
    }
}));

const useStyles = makeStyles(() => ({
    root: {
        height: 200,
        position: "relative",
        width: 450
    },
    overlay: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        color: "#000",
        textAlign: "center",
        pointerEvents: "none",
        "& p": {
            fontSize: 36
        }
    },

}));

const csvHeaders = [
    { label: 'Site', key: 'site' },
    { label: 'Last Audit', key: 'lastAudit' },
    { label: 'Low Complete', key: 'LowComplete' },
    { label: 'Low Total', key: 'Low' },
    { label: 'Medium Complete', key: 'MediumComplete' },
    { label: 'Medium Total', key: 'Medium' },
    { label: 'High Complete', key: 'HighComplete' },
    { label: 'High Total', key: 'High' },
    { label: 'Critical Complete', key: 'CriticalComplete' },
    { label: 'Critical Total', key: 'Critical' },
    { label: 'Overall Complete', key: 'completedCount' },
    { label: 'Overall Total', key: 'overallCount' },
    { label: 'Completion Percent', key: 'completionPercent' }
]

const ratings = ["Critical", "High", "Medium", "Low"];

const AuditInsights = () => {

    const classes = useStyles();
    const csvExportLinkRef = useRef();
    const chart1Ref = useRef();
    const chart2Ref = useRef();
    const [menuAnchor, setMenuAnchor] = useState(null);

    const { selectedAccount, activeAppId } = useSelector((state) => state.account);
    const { isTemplateAdminForSites, isWorknestUser } = useAuth();
    const [auditType, setAuditType] = useState(auditTypes.GRA);
    const [exporting, setExporting] = useState(false);

    const AUDIT_INSIGHTS = "Audit Insights";
    const AUDIT_INSIGHTS_DESCRIPTION = "A real-time snapshot of all ongoing and completed safety audits.";
    const GRA = "General Risk Assessments";
    const FRA = "Fire Risk Assessments";

    const { Track } = useTracking({
        page: `Insights: Audit`,
    });

    const isTemplateAdmin = isTemplateAdminForSites(
        selectedAccount?.childExternalIds
    );

    const { data: sites } = useAccountAppSites({
        childExternalIds: [
            ...(selectedAccount?.childExternalIds || []),
            ...(selectedAccount?.childExternalIds || []),
            selectedAccount?.account.externalId,
        ],
        activeAppId,
    });

    const externalIds = useMemo(() => sites?.map(x => x.externalId), [sites]);

    const insights = useAuditInsights(externalIds, auditType);

    const auditTypeSelected = (e, newAuditType) => {

        if (newAuditType != null)
            setAuditType(newAuditType);
    }

    const incompleteActionsData = useMemo(() => {

        if (!insights?.data)
            return [];

        let colors = ["#5C45C7", "#7d6ad2", "#9d8fdd", "#beb5e9"];

        let data = [];

        for (var index = 0; index < ratings.length; index++) {
            let rating = ratings[index];

            let value = insights.data.reduce((accumulator, i) => {
                return accumulator + (i.actionCounts[rating] || 0) - (i.actionCompleteCounts[rating] || 0);
            }, 0);

            if (value) {
                data.push({
                    id: rating,
                    label: rating,
                    color: colors[index],
                    value
                });
            }
        }     

        return data
    }, [insights.data]);


    const actionCount = useMemo(() => {
        if (!insights.data)
            return null;

        let count = insights.data.reduce((accumulator, i) => {
            return accumulator + i.overallCount
        }, 0);

        return count;

    }, [insights]);

    const completeActionCount = useMemo(() => {
        if (!insights.data)
            return null;

        let count = insights.data.reduce((accumulator, i) => {
            return accumulator + i.completedCount
        }, 0);

        return count;

    }, [insights]);

    const incompleteActionCount = useMemo(() => {
        if (!insights.data)
            return null;

        return actionCount - completeActionCount;

    }, [actionCount, completeActionCount, insights.data]);

    const completionPercent = useMemo(() => (completeActionCount * 100 / actionCount).toFixed(1), [actionCount, completeActionCount]);


    const completionData = useMemo(() => {

        if (!insights?.data)
            return [];

        let data = [
            {
                id: 'Complete',
                label: 'Complete',
                value: actionCount - incompleteActionCount,
                color: "#5C45C7"
            },
            {
                id: 'Incomplete',
                label: 'Incomplete',
                value: incompleteActionCount,
                color: "#EBE8F8"
            }
        ]

        return data
    }, [actionCount, incompleteActionCount, insights?.data]);

    const siteName = useCallback(externalId => sites.find(x => x.externalId === externalId)?.name, [sites])

    const exportPDF = async () => {
        setMenuAnchor(null);
        setExporting(true);        

        const canvas1 = await html2canvas(chart1Ref.current, { scale: 2 });
        const dataUrl1 = canvas1.toDataURL('image/jpg');

        const canvas2 = await html2canvas(chart2Ref.current, { scale:2});
        const dataUrl2 = canvas2.toDataURL('image/jpg');       

        let html = '';

        html += '<figure style="width:100%;display:table;"><img src="https://ewdatastore.blob.core.windows.net/images/worknesths.png"/></figure>'

        html += `<h2>${AUDIT_INSIGHTS}</h2>`;
        html += `<p>${AUDIT_INSIGHTS_DESCRIPTION}</p>`;

        html += `<h6>${auditType === auditTypes.GRA ? GRA : FRA}</h6>`;

        html += '<table style="width:"100%">';
        
        html += `<tr><td style="width:50%">Incomplete Actions</td><td style="width:50%">Completion</td></tr>`;
        html += `<tr><td style="width:50%"><img style="width:100%" src="${dataUrl1}" /></td><td style="width:50%"><img style="width:100%" src="${dataUrl2}" /></td></tr>`;
        html += '</table >';

        html += '<table style="width:"100%">';
        html += '<thead>';
        html += '<tr>';
        csvHeaders.filter(h => !h.label.includes('Total')).forEach(h => html += `<td>${h.label}</td>`);        
        html += '</tr>';
        html += '</thead>';
        
        exportData.forEach(r => {
            html += '<tr>';
            html += `<td>${r.site}</td>`
            html += `<td>${r.lastAudit}</td>`
            html += `<td>${r.LowComplete} / ${r.Low}</td>`
            html += `<td>${r.MediumComplete} / ${r.Medium}</td>`
            html += `<td>${r.HighComplete} / ${r.High}</td>`
            html += `<td>${r.CriticalComplete} / ${r.Critical}</td>`
            html += `<td>${r.completedCount} / ${r.overallCount}</td>`
            html += `<td>${r.completionPercent}%</td>`
            html += '</tr>';
        })

        html += '</table>';


        let href = await generatePdfFromHtml(html);

        var link = window.document.createElement("a");
        link.href = href;
        link.setAttribute("target", "_blank");
        link.download = "AuditInsights.pdf";
        link.click();       
       
        setExporting(false);
    }

    const exportCSV = () => {
        setMenuAnchor(null);
        setExporting(true);               

        setTimeout(() => {
            csvExportLinkRef.current.link.click();
            setExporting(false);
        });
    }

    const exportData = useMemo(() => {
        if (!insights.data)
            return [];

        let data = insights.data.map(i => {
            let row = {
                site: siteName(i.siteExternalId),
                lastAudit: format(new Date(i.lastAudit), "dd/MM/yyyy"),
                overallCount: i.overallCount,
                completedCount: i.completedCount,
                completionPercent: i.completionPercent
            }

            ratings.forEach(r => {
                row = {
                    ...row,
                    ...{
                        [r]: i.actionCounts[r] || 0,
                        [`${r}Complete`]: i.actionCompleteCounts[r]

                    }
                }
            })

            return row;
        });      

        return data;
    }, [insights.data, siteName]);

    return (
        <RequiresRoleWrapper hasRole={isTemplateAdmin || isWorknestUser}>
            <PageBase>
                <Track>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CardBase
                                title={
                                    <PageTitle
                                        icon={<PeopleIcon />}
                                        title={AUDIT_INSIGHTS}
                                        description={AUDIT_INSIGHTS_DESCRIPTION} />
                                }
                                rightComponent={
                                    <div>
                                        <Button
                                            disabled={exporting}
                                            variant="outlined"
                                            color="primary"
                                            onClick={e => setMenuAnchor(e.currentTarget)}
                                        >
                                            <GetAppIcon /> Download
                                        </Button>
                                        <Menu
                                            anchorEl={menuAnchor}
                                            open={!!menuAnchor}
                                            getContentAnchorEl={null}
                                            onClose={() => setMenuAnchor(null)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <MenuItem onClick={exportCSV}>CSV</MenuItem>
                                            <MenuItem onClick={exportPDF}>PDF</MenuItem>
                                        </Menu>
                                    </div>                                    
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ToggleButtonGroup
                                size="small"
                                value={auditType}
                                exclusive
                                onChange={auditTypeSelected}
                                aria-label="text alignment"
                            >
                                <ToggleButton value={auditTypes.GRA} aria-label="left aligned">{GRA}</ToggleButton>
                                <ToggleButton value={auditTypes.FRA} aria-label="centered">{FRA}</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <CardBase title="Incomplete Actions">
                                <div style={{ maxWidth: 450, margin: '0 auto' }}>
                                    <div className={classes.root} ref={chart1Ref}>
                                        {
                                            incompleteActionCount > 0 &&
                                            <ResponsivePie
                                                data={incompleteActionsData}
                                                enableArcLinkLabels={false}
                                                enableArcLabels={false}
                                                innerRadius={0.5}
                                                colors={datum => datum.data.color}
                                                legends={[
                                                    {
                                                        anchor: 'right',
                                                        direction: 'column',
                                                        itemWidth: 100,
                                                        itemHeight: 20,
                                                        symbolShape: 'circle',
                                                        symbolSize: 8
                                                    }]}
                                            />
                                        }

                                        <Box className={classes.overlay}>
                                            {incompleteActionCount === 0 && <Typography>You're all done</Typography>}
                                            {incompleteActionCount > 0 && <Typography>{incompleteActionCount}</Typography>}
                                        </Box>
                                    </div>
                                </div>
                                
                            </CardBase>
                        </Grid>
                        <Grid item xs={6}>
                            <CardBase title="Completion">
                                <div style={{ maxWidth: 500, margin: '0 auto' }}>
                                    <div className={classes.root} ref={chart2Ref}>
                                        {
                                            incompleteActionCount > 0 &&
                                            <ResponsivePie
                                                data={completionData}
                                                enableArcLinkLabels={false}
                                                enableArcLabels={false}
                                                innerRadius={0.5}
                                                colors={datum => datum.data.color}
                                                legends={[]}
                                            />
                                        }
                                        {
                                            !isNaN(completionPercent) &&
                                            <Box className={classes.overlay}>
                                                <Box><Typography style={{ fontSize: '1.4em' }}>{completionPercent} %</Typography></Box>
                                                <Box><Typography style={{ fontSize: '1em' }}>{actionCount - incompleteActionCount} of {actionCount}</Typography></Box>
                                            </Box>
                                        }
                                    </div>
                                </div>                                
                            </CardBase>
                        </Grid>
                        <Grid item xs={12}>
                            <AuditInsightsTableCard insights={insights} siteName={siteName} />
                        </Grid>
                        <CSVLink
                            ref={csvExportLinkRef}
                            headers={csvHeaders}
                            data={exportData}
                            filename="AuditInsights.csv"
                        />
                    </Grid>
                </Track>
            </PageBase>
        </RequiresRoleWrapper>
    );
}

export default AuditInsights;
