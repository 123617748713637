import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import useSectors from "../../hooks/queries/useSectors";
import { dayBit, frequency, frequencyDays, frequencyDropdownValues, frequencyOptionValues } from "../../constants/frequencyConstants";
import useSubjects from "../../hooks/queries/useSubjects";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAddTemplate from "../../hooks/mutations/useAddTemplate";
import useRemainingIncidentTypes from "../../hooks/queries/useRemainingIncidentTypes";
import CustomButton from "../../../components/CustomButton";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
    addSection,
    addEditableSection,
    clearTemplate,
    clearTemplateOutcomes,
    setTemplateApplicationArea,
    setIsGlobalTemplate,
    setTemplateName,
    setTemplateFrequency,
    setTemplateSubjectId,
    setTemplateSectorId,
    setIncidentType,
    setTemplateFrequencyInterval,
    setTemplateFrequencyDays,
} from "../../../redux/actions/templateBuilderActions";
import { selectSite } from "../../../redux/actions/accountActions";
import moduleAreaConstants, {
    moduleUrls,
} from "../../constants/moduleAreaConstants";
import { useSnackbar } from "notistack";
import Template from "../../components/templatebuilder/Template";
import PepperStepper from "../../components/common/PepperStepper";
import { AddCircleOutline, Search, InfoOutlined } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import useHazards from "../../hooks/queries/useHazards";
import usePeople from "../../hooks/queries/usePeople";
import moduleAreas from "../../constants/moduleAreaConstants";
import PageBase from "../../../components/PageBase";
import { useAuth } from "../../../contexts/authContext";
import TemplateBuilderHelper from "../../utils/TemplateBuilderHelper";
import useTemplateVersion from "../../hooks/queries/useTemplateVersion";
import { incidentTypeList } from "../../constants/incidentType";
import useUpdateTemplate from "../../hooks/mutations/useUpdateTemplate";
import SiteSelect from "../../../components/SiteSelect";
import TemplateVersionHistoryDialog from "../../components/riskassessments/dialogs/TemplateVersionHistoryDialog";
import CustomRadioButton from "../../../../src/components/administration/CustomRadioButton";
import CustomHazardDialog from "../../components/templatebuilder/CustomHazardDialog";
import useAllEnabledModuleTemplates from "../../hooks/queries/useAllEnabledModuleTemplates";
import useAccountAppSites from "../../../hooks/queries/useAccountAppSites";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
    },
    hazardStep: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
    },
    header: {
        display: "flex",
        gap: theme.spacing(2),
        alignItems: "center",
        marginBottom: theme.spacing(1),
    },
    version: {
        color: "#7a5cf0",
        fontSize: "0.775rem",
    },
    templateNameField: {
        width: "33%",
    },
    hazardIconText: {
        "&> span": {
            display: "flex",
            alignItems: "center",
        },
    },
    hazardIcons: {
        height: "40px",
        marginRight: theme.spacing(2),
    },
    templateNameContainer: {
        display: "flex",
        justifyContent: "center",
    },
    stepperContainer: {
        marginBottom: theme.spacing(2),
    },
    hazardContainer: {
        backgroundColor: "white",
        borderRadius: "4px",
        border: `1px solid ${grey[400]}`,
        padding: theme.spacing(2),
    },
    listWrapper: {
        height: "400px",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    description: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    fullWidthField: {
        width: "100%",
    },
    halfWidth: {
        width: "50%",
    },
    buttonsContainer: {
        marginTop: theme.spacing(2),
        display: "flex",
        gap: theme.spacing(2),
        flexWrap: "wrap",
        justifyContent: "flex-end",
    },
    formButton: {
        [theme.breakpoints.down("sm")]: { width: "100%" },
    },
    headings: {
        fontSize: 16,
        fontWeight: "bold",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',

    },
    infoIcon: {
        verticalAlign: "top",
    },
    freqField: {
        width: "100%",
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    insertSectionButton: {
        backgroundColor: "white",
        border: `1px solid ${grey[300]}`,
        borderRadius: "4px",
        fontSize: "12px",
        padding: "4px 8px",
        color: theme.palette.secondary.main,
        fontWeight: "normal",
        zIndex: 1,
        "&:hover": {
            backgroundColor: grey[100],
        },
    },
    dialogBold: {
        marginBottom: theme.spacing(1),
        fontWeight: "bold",
    },
    dialogExample: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        backgroundColor: grey[100],
        color: theme.palette.secondary.main,
    },
    dialogExampleItalic: {
        fontStyle: "italic",
    },
    dialogTitle: {
        padding: theme.spacing(2),
        margin: theme.spacing(1),
        fontSize: 20,
        fontWeight: "bold",
    },
    dialogClose: {
        margin: theme.spacing(1),
    },
    title: {
        fontSize: 16,
        fontWeight: "bold",
    },
    builderTitle: {
        fontSize: 20,
        fontWeight: "bold",
    },
    togGroup: {
        width: "100%",
        margin: theme.spacing(2),
        padding: theme.spacing(0, 1),
        "& .MuiToggleButton-root": {
            borderRadius: "10%",
        },
        "& .MuiToggleButton-root:not(:first-child)": {
            borderLeft: "none",
        },
    },
    weekdayPick: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginTop: `-${theme.spacing(3)}px`,
        fontSize: 10,
    },
    textContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
        fontSize: 12,
        color: "#736793"
    },
    togButton: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1),
        marginTop: `-${theme.spacing(1)}px`,
        border: `1px solid #E0DBF7 !important`,
        color: "#692B7C",
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "black",
            backgroundColor: "#F2EDFF",
            border: `1px solid ${theme.palette.primary.dark} !important`,
        },
        "&$selected": {
            color: "white",
            backgroundColor: "#7A5EED",
        },
        "&:hover": {
            borderColor: "#BA9BC3",
            background: "#BA9BC3",
        },
        "&:hover$selected": {
            borderColor: "#BA9BC3",
            background: "#BA9BC3",
        },
        minWidth: 48,
        maxWidth: 48,
        height: 32,
        textTransform: "unset",
        fontSize: "0.75rem",
    },
    togWarning: {
        marginLeft: theme.spacing(5),
        marginTop: `-${theme.spacing(3)}px`,
    },
    buttonGroupContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

function TemplateBuilder() {
    const { isWorknestUser } = useAuth(); 
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { selectedSite, selectedAccount, sites, activeAppId, appSiteIds } = useSelector(
        (state) => state.account
    );
    const {
        sectionIds,
        sections,
        questions,
        options,
        choiceOutcomes,
        investigationSectionIds,
        hasUpdated,
        isGlobalTemplate,
        templateName,
        templateFrequency,
        templateSectorId,
        templateSubjectId,
        incidentType,
        templateFrequencyInterval,
        templateFrequencyDays,
    } = useSelector((state) => state.templateBuilder);
    const [isSubjectChangeDialogOpen, setIsSubjectChangeDialogOpen] = useState(false);
    const [templateDescription, setTemplateDescription] = useState("");
    const [templateHazardIds, setTemplateHazardIds] = useState([]);
    const [templatePeopleIds, setTemplatePeopleIds] = useState([]);
    const [templateSubjectIdToChangeTo, setTemplateSubjectIdToChangeTo] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [hazardSearch, setHazardSearch] = useState("");
    const [changeNotes, setChangeNotes] = useState("");
    const [showChangeNotesDialog, setShowChangeNotesDialog] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState();
    const [versionDialogOpen, setVersionDialogOpen] = useState(false);
    const [isTemplateChangeDialogOpen, setIsTemplateChangeDialogOpen] = useState(false);
    const [showFrequencyInformationDialog, setShowFrequencyInformationDialog] = useState(false);
    const [templateDays, setTemplateDays] = useState();
  
    const sectors = useSectors();
    const subjects = useSubjects();
    const hazards = useHazards();
    const people = usePeople();
    const addNewTemplate = useAddTemplate();
    const updateTemplate = useUpdateTemplate();

    const [newCustomHazards, setNewCustomHazards] = useState([])

    const match = useRouteMatch();
    const { params } = match;
    const { moduleArea: moduleAreaUrl, templateVersionId, isCopy } = params;
    const moduleArea = parseInt(
        Object.keys(moduleUrls).find((key) => moduleUrls[key] === moduleAreaUrl)
    );
    
    let siteExternalIds = selectedSite ? [selectedSite.externalId] : appSiteIds[activeAppId];

    const { data: accountSitesData } = useAccountAppSites({
        childExternalIds: [
            ...(selectedAccount?.childExternalIds || []),
            selectedAccount?.account.externalId,
        ],
        activeAppId,
    });

    let backupSites;

    if (accountSitesData !== undefined) {
        backupSites = accountSitesData.map(item => item.externalId);
    }

    // Get all enabled Templates to check name of new template is unique
    const { data: templatesData } = useAllEnabledModuleTemplates({
        moduleArea: moduleAreas.INCIDENTS,
        accountId: selectedAccount.account.externalId,
        siteExternalIds: siteExternalIds ?? backupSites,
        includeDisabled: "false",
    });
    let filteredTemplates;
    if (templatesData !== undefined) {
        filteredTemplates = templatesData.filter(f => f.incidentType === incidentType);
    }


    const templateVersion = useTemplateVersion(templateVersionId, {
        onSuccess: onTemplateVersionSuccess,
    });

    function decodeFrequencyDays(encodedValue) {
        const frequencyDays = [];

        Object.keys(dayBit).forEach(day => {
            if ((encodedValue & dayBit[day]) !== 0) {
                frequencyDays.push(dayBit[day]);
            }
        });
        return frequencyDays;
    }

    function onTemplateVersionSuccess(data) {
        if (data?.id && !templateName) {
            dispatch(setIsGlobalTemplate({ isGlobalTemplate: !data.siteExternalId }));
            dispatch(setTemplateName({ text: isCopy ? data.name + " - Copy" : data.name, hasUpdated: false }));
            dispatch(setTemplateSectorId({ sectorId: data.sectorId, hasUpdated: false }));
            dispatch(setTemplateSubjectId({ subjectId: data.subjectId, hasUpdated: false }));
            dispatch(setTemplateFrequency({ freq: data.frequency, hasUpdated: false }));
            dispatch(setTemplateFrequencyInterval({ frequencyInterval: data.frequencyInterval == null ? 0 : data.frequencyInterval, hasUpdated: false }));
            dispatch(setTemplateFrequencyDays({ frequencyDays: data.frequencyDays == null ? decodeFrequencyDays(127) : decodeFrequencyDays(data.frequencyDays), hasUpdated: false }));
            dispatch(setIncidentType({ incidentType: data.incidentType, hasUpdated: true }));
            setTemplateDescription(data.description);
            setTemplateHazardIds(data.templateHazards.map((x) => x.hazardId));
            setTemplatePeopleIds(data.templatePeoples.map((x) => x.peopleId));
            data.sections.map((x) => dispatch(addEditableSection({ section: x })));
            data.investigationSections.map((x) =>
                dispatch(addEditableSection({ section: x }))
            );
            setSelectedTemplateId(data.templateId);
            dispatch(selectSite(sites[data.siteExternalId] ?? null));
            setNewCustomHazards(data.customHazards)
        }
    }

    const [hazardDialogOpen, setHazardDialogOpen] = useState(false);
    
    function handleCloseDialog() {
        setVersionDialogOpen(false);
    }

    function handleOpenVersionDialog() {
        setVersionDialogOpen(true);
    }

    const steps =
        moduleArea === moduleAreaConstants.INCIDENTS
            ? [
                {
                    label: "Incident form",
                    isOptional: false,
                },
                {
                    label: "Investigation",
                    isOptional: true,
                },
            ]
            : moduleArea === moduleAreaConstants.RISKASSESSMENTS
                ? [
                    {
                        label: "Set Up",
                        isOptional: false,
                    },
                    {
                        label: "Hazards",
                        isOptional: false,
                    },
                    {
                        label: "People",
                        isOptional: false,
                    },
                ]
                : [];

    const { enqueueSnackbar } = useSnackbar();
    const { data: remainingIncidentTypes } = useRemainingIncidentTypes(selectedAccount?.account?.externalId);

    const hazardImagesUrl = `${process.env.PUBLIC_URL}/images/hazardIcons/`;

    useEffect(() => {
        dispatch(setTemplateApplicationArea(moduleArea));
        if (
            sectionIds.length === 0 &&
            !templateVersion.isLoading &&
            !templateVersion.data?.id
        ) {
            dispatch(addSection({ isInvestigation: false }));
        }
    }, [dispatch, sectionIds, moduleArea]);

    useEffect(() => {
        return () => {
            dispatch(clearTemplate());
        };
    }, [dispatch]);

    const goBack = (e) => {
        e?.preventDefault();
        dispatch(clearTemplate());
        history.goBack();
    };

    const manageCustomHazards = () => {
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={() => setHazardDialogOpen(true)}
            >
                Manage Custom Hazards
            </Button>
        );
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        // Check new name is unique within incident type
        if (filteredTemplates !== undefined) {
            // If template has the same name and its a duplicate, dont allow, or if creating new, and the name is already taken
            if (isCopy || templateVersion.data === undefined) {
                if (filteredTemplates.some(t => t.name === templateName.trim())) {
                    enqueueSnackbar("You must select a unique name.", {
                        variant: "warning",
                    });
                    return;
                }
            }           
            // If editing, allow to save as same name as self, but not same name as different ID template
            else if (filteredTemplates.some(t => t.name === templateName.trim() && t.templateVersionId !== templateVersion.data.id)) {                                
                enqueueSnackbar("You must select a unique name.", {
                    variant: "warning",
                });
                return;
            }            
        }
        if (
            activeStep === 0 &&
            (moduleArea === moduleAreaConstants.INCIDENTS ||
                moduleArea === moduleAreaConstants.RISKASSESSMENTS)
        ) {
            setActiveStep(1);
            return;
        }

        if (
            activeStep === 1 &&
            moduleArea === moduleAreaConstants.RISKASSESSMENTS
        ) {
            if (templateHazardIds.length === 0) {
                enqueueSnackbar("You must select at least one hazard.", {
                    variant: "warning",
                });
                return;
            }

            setActiveStep(2);
            return;
        }

        if (templateFrequency === frequency.DAILY) {
            if (templateFrequencyDays.length === 0) {
                enqueueSnackbar("You must select at least one day form can be filled.", {
                    variant: "warning",
                });
                return;
            }
            else {
                const dayValues = templateFrequencyDays.map(day => {
                    const dayKey = Object.keys(dayBit).find(key => dayBit[key] === day);
                    return { key: dayKey, value: dayBit[dayKey] };
                });
                setTemplateDays(dayValues.reduce((acc, day) => acc | day.value, 0));
            }
        }

        if (
            moduleArea === moduleAreaConstants.RISKASSESSMENTS &&
            templatePeopleIds.length === 0
        ) {
            enqueueSnackbar("You must select at least one type of person.", {
                variant: "warning",
            });
            return;
        }

        if (templateVersionId && !changeNotes) {
            setShowChangeNotesDialog(true);
            return;
        }

        const isIncidentsTemplate = moduleArea === moduleAreaConstants.INCIDENTS;
        const isRiskAssessmentTemplate =
            moduleArea === moduleAreaConstants.RISKASSESSMENTS;

        const sectionIdsToMap = [
            ...sectionIds.map((sectionId) => ({ sectionId, isInvestigation: false })),
            ...investigationSectionIds.map((sectionId) => ({
                sectionId,
                isInvestigation: true,
            })),
        ];

        try {

            const newTemplate = new TemplateBuilderHelper(
                templateName,
                moduleArea,
                templateDescription,
                templateFrequency,
                incidentType,
                templateFrequencyInterval,
                templateDays,
            );

            if (templateVersionId) {
                newTemplate.withTemplateVersionId(templateVersionId);
                newTemplate.withChangeNotes(changeNotes);
            }

            if (isIncidentsTemplate) newTemplate.withIncidentType(incidentType);
            else {
                newTemplate.withSectorId(templateSectorId);
            }

            if (!isIncidentsTemplate && !isRiskAssessmentTemplate)
                newTemplate.withSubjectId(templateSubjectId);

            if (isRiskAssessmentTemplate) {
                newTemplate.withHazardIds(templateHazardIds);
                newTemplate.withPeopleIds(templatePeopleIds);
                newTemplate.withRiskAssessmentSections(
                    templateHazardIds,
                    hazards?.data
                );
                newTemplate.withCustomHazards(newCustomHazards)
            } else {
                newTemplate.withSections(
                    sectionIdsToMap,
                    sections,
                    questions,
                    choiceOutcomes,
                    options
                );
            }

            if (!isWorknestUser)
                newTemplate.withSiteExternalId(
                    selectedSite?.externalId ?? selectedAccount.account.externalId
                );

            if (!templateVersionId || (!isWorknestUser && isGlobalTemplate) || isCopy) {
                addNewTemplate.mutate(newTemplate.build(), {
                    onSuccess: () => goBack(),
                    onError: (error) => {
                        console.error(error);
                        enqueueSnackbar(
                            error?.message || "There was an error adding the template",
                            {
                                variant: "error",
                            }
                        );
                    },
                });
            } else {
                updateTemplate.mutate(newTemplate.build(), {
                    onSuccess: () => goBack(),
                    onError: (error) => {
                        console.error(error);
                        enqueueSnackbar(
                            error?.message || "There was an error updating the template",
                            {
                                variant: "error",
                            }
                        );
                    },
                });
            }
        } catch (e) {
            console.error(e.message);
        }
    };

    const handleSubjectChange = (e) => {
        const subjectId = e.target.value;
        if (Object.keys(choiceOutcomes).length > 0 && templateSubjectId) {
            setTemplateSubjectIdToChangeTo(subjectId);
            setIsSubjectChangeDialogOpen(true);
            return;
        }
        dispatch(setTemplateSubjectId({ subjectId: subjectId, hasUpdated: true }));
    };

    const handleSectorChange = (e) => {
        dispatch(setTemplateSectorId({ sectorId: e.target.value, hasUpdated: true }));
    };

    const handleTemplateNameChange = (e) => {
        dispatch(setTemplateName({ text: e.target.value, hasUpdated: true }));
    };

    const handleFrequecyChange = (e) => {
        dispatch(setTemplateFrequency({ freq: e.target.value, hasUpdated: true }));
    };

    const handleIncidentTypeChange = (e) => {
        dispatch(setIncidentType({ incidentType: e.target.value, hasUpdated: true }));
    };

    const handleFrequecyIntervalChange = (e) => {
        dispatch(setTemplateFrequencyInterval({ frequencyInterval: e, hasUpdated: true }));
    };

    const handleFrequecyDaysChange = (selectedDays) => {
        dispatch(setTemplateFrequencyDays({ frequencyDays: selectedDays, hasUpdated: true }));
    };

    const onSubjectChangeDialogClose = () => {
        setIsSubjectChangeDialogOpen(false);
    };

    const onConfirmSubjectChange = () => {
        dispatch(clearTemplateOutcomes());
        setTemplateSubjectId(templateSubjectIdToChangeTo);
        setTemplateSubjectIdToChangeTo(0);
        setIsSubjectChangeDialogOpen(false);
    };

    const onTemplateChangeDialogClose = () => {
        setIsTemplateChangeDialogOpen(false);
    };

    const onDiscardTemplateChanges = () => {
        setIsTemplateChangeDialogOpen(false);
        goBack();
    };

    const handleClickBack = () => {
        if (activeStep === 0) {
            if (hasUpdated) {
                setIsTemplateChangeDialogOpen(true);
                return;
            }
            else {
                goBack();
                return;
            }
        }

        if (activeStep === 1) {
            setActiveStep(0);
            return;
        }

        setActiveStep(1);
    };


    function toggleHazard(id) {
        const newHazardIds = templateHazardIds.includes(id)
            ? templateHazardIds.filter((x) => x !== id)
            : [...templateHazardIds, id];

        setTemplateHazardIds(newHazardIds);
    }

    function togglePerson(id) {
        const newPeopleIds = templatePeopleIds.includes(id)
            ? templatePeopleIds.filter((x) => x !== id)
            : [...templatePeopleIds, id];

        setTemplatePeopleIds(newPeopleIds);
    }

    function closeChangeNotesDialog() {
        setShowChangeNotesDialog(false);
        setChangeNotes("");
    }

    return (
        <>
            <TemplateVersionHistoryDialog
                onClose={handleCloseDialog}
                open={versionDialogOpen}
                templateId={selectedTemplateId}
            />
            <CustomHazardDialog
                open={hazardDialogOpen}
                onClose={() => setHazardDialogOpen(false)}
                selectedAccount={selectedAccount.account.externalId}
                newCustomHazards={newCustomHazards}
                setNewCustomHazards={setNewCustomHazards}
            />
            <PageBase>
                <Paper className={classes.paper}>
                    <div className={classes.header}>
                        <Typography className={classes.builderTitle}>Template Builder</Typography>
                        {templateVersionId && (
                            <Button
                                variant="text"
                                className={classes.version}
                                onClick={handleOpenVersionDialog}
                            >
                                Version History
                            </Button>
                        )}
                    </div>
                    {(moduleArea === moduleAreaConstants.INCIDENTS ||
                        moduleArea === moduleAreaConstants.RISKASSESSMENTS) && (
                            <div className={classes.stepperContainer}>
                                <PepperStepper activeStep={activeStep} steps={steps} />
                            </div>
                        )}
                    <form onSubmit={onSubmit}>
                        {activeStep === 0 && (
                            <>
                                <Typography className={classes.headings}>Details</Typography>
                                <Grid spacing={2} container>
                                    <Grid
                                        className={classes.templateNameContainer}
                                        item
                                        xs={12}
                                        sm={!isWorknestUser ? 6 : 12}
                                    >
                                        <TextField
                                            required
                                            size="small"
                                            className={!isWorknestUser ? "" : classes.templateNameField}
                                            label="Template Name"
                                            variant="outlined"
                                            fullWidth
                                            value={templateName ?? ""}
                                            onChange={handleTemplateNameChange}
                                        />
                                    </Grid>
                                    {!isWorknestUser && (
                                        <Grid item xs={12} sm={6}>
                                            <SiteSelect size="small" />
                                        </Grid>
                                    )}
                                    {moduleArea === moduleAreaConstants.INCIDENTS ? (
                                        <>
                                            <Grid item xs={12} md={4} />
                                            <Grid item xs={12} md={4}>
                                                <FormControl
                                                    required
                                                    className={classes.fullWidthField}
                                                    variant="outlined"
                                                    size="small"
                                                >
                                                    <InputLabel id="frequency-select-label">
                                                        Incident Type
                                                    </InputLabel>
                                                    <Select
                                                        labelId="inctype-select-label"
                                                        label="Incident Type"
                                                        value={incidentType ?? ""}
                                                        onChange={handleIncidentTypeChange}
                                                    >
                                                        {incidentTypeList.map((incidentType) => (
                                                            <MenuItem
                                                                key={incidentType.value}
                                                                value={incidentType.value}
                                                            >
                                                                {incidentType.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4} />
                                        </>
                                    ) : (
                                        <>
                                            {moduleArea === moduleAreaConstants.RISKASSESSMENTS && (
                                                <Grid item xs={12} md={4} />
                                            )}
                                            <Grid item xs={12} sm={4}>
                                                {(!templateVersionId ||
                                                    (templateVersion.data && templateSectorId) ||
                                                    ((moduleArea === moduleAreaConstants.MONITORING || moduleArea === moduleAreaConstants.COMPLIANCE) && templateVersion.data)) && (
                                                        <FormControl
                                                            required
                                                            className={classes.fullWidthField}
                                                            variant="outlined"
                                                            size="small"
                                                            disabled={sectors.isLoading}
                                                            error={sectors.isError}
                                                        >
                                                            <InputLabel id="sector-select-label">
                                                                Sector
                                                            </InputLabel>
                                                            <Select
                                                                labelId="sector-select-label"
                                                                label="Sector"
                                                                value={templateSectorId ?? ""}
                                                                onChange={handleSectorChange}
                                                            >
                                                                {sectors.data &&
                                                                    sectors.data.map((sector) => (
                                                                        <MenuItem key={sector.id} value={sector.id}>
                                                                            {sector.name}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                            {sectors.isError && (
                                                                <FormHelperText>
                                                                    {sectors.error?.message ||
                                                                        "Could not load sectors"}
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    )}
                                            </Grid>
                                            {moduleArea !== moduleAreaConstants.RISKASSESSMENTS ? (
                                                <>
                                                    <Grid item xs={12} sm={4}>
                                                        {(!templateVersionId ||
                                                            (templateVersion.data && templateSubjectId) ||
                                                            ((moduleArea === moduleAreaConstants.MONITORING || moduleArea === moduleAreaConstants.COMPLIANCE) && templateVersion.data)) && (
                                                                <FormControl
                                                                    required
                                                                    className={classes.fullWidthField}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    disabled={subjects.isLoading}
                                                                    error={subjects.isError}
                                                                >
                                                                    <InputLabel id="subject-select-label">
                                                                        Subject
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId="subject-select-label"
                                                                        label="Sector"
                                                                        value={templateSubjectId ?? ""}
                                                                        onChange={handleSubjectChange}
                                                                    >
                                                                        {subjects.data &&
                                                                            subjects.data.map((subject) => (
                                                                                <MenuItem
                                                                                    key={subject.id}
                                                                                    value={subject.id}
                                                                                >
                                                                                    {subject.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                    {subjects.isError && (
                                                                        <FormHelperText>
                                                                            {subjects.error?.message ||
                                                                                "Could not load subjects"}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            )}
                                                    </Grid>
                                                </>
                                            ) : (
                                                <Grid container className={classes.description}>
                                                    <Grid item xs={2} />
                                                    <Grid item xs={12} md={8}>
                                                        <TextField
                                                            required
                                                            label="Description"
                                                            rows={4}
                                                            multiline
                                                            variant="outlined"
                                                            className={classes.fullWidthField}
                                                            value={templateDescription}
                                                            onChange={(e) =>
                                                                setTemplateDescription(e.target.value)
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                </Grid>
                                {moduleArea !== moduleAreaConstants.RISKASSESSMENTS && moduleArea !== moduleAreaConstants.INCIDENTS && (
                                    <>
                                        <div className={classes.headings} >
                                            <Typography className={classes.title}>Frequency</Typography>
                                            <IconButton
                                                size="small"
                                                onClick={() => setShowFrequencyInformationDialog(true)}
                                            >
                                                <InfoOutlined />
                                            </IconButton>
                                        </div>
                                        <Grid spacing={2} container>
                                            <Grid item xs={12} sm={4}>
                                                <FormControl
                                                    required
                                                    className={classes.freqField}
                                                    variant="outlined"
                                                    size="small"
                                                >
                                                    <InputLabel id="frequency-select-label">
                                                        Frequency
                                                    </InputLabel>
                                                    <Select
                                                        labelId="frequency-select-label"
                                                        label="Frequency"
                                                        value={templateFrequency ?? ""}
                                                        onChange={handleFrequecyChange}
                                                    >
                                                        {frequencyDropdownValues.map((frequency) => (
                                                            <MenuItem
                                                                key={frequency.value}
                                                                value={frequency.value}
                                                            >
                                                                {frequency.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            {templateFrequency == frequency.DAILY &&
                                                <Grid item xs={12} sm={4}>
                                                    <div className={classes.weekdayPick}>
                                                        <div className={classes.textContainer}>
                                                            <Typography className={classes.textContainer}>Days forms can be filled in :</Typography>
                                                        </div>
                                                        <div className={classes.buttonGroupContainer}>
                                                            <ToggleButtonGroup
                                                                size="small"
                                                                aria-label="Days of the week"
                                                                value={templateFrequencyDays}
                                                                onChange={(event, value) => handleFrequecyDaysChange(value)}
                                                                className={classes.togGroup}
                                                            >
                                                                {frequencyDays.map((day, index) => (
                                                                    <ToggleButton className={classes.togButton} key={day.key} value={dayBit[day.label]}>
                                                                        {day.label}
                                                                    </ToggleButton>
                                                                ))}
                                                            </ToggleButtonGroup>
                                                        </div>
                                                        {templateFrequency === frequency.DAILY && !templateFrequencyDays?.length && (
                                                            <FormHelperText className={classes.togWarning} error>At least one day must be This field is required.</FormHelperText>
                                                        )}
                                                    </div>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Typography className={classes.headings} >Form due date occurs at regular intervals based on :</Typography>
                                        <Grid spacing={2} container>
                                            {frequencyOptionValues.map((freqInterval) => (
                                                <Grid item xs={12} md={4} key={freqInterval.value}>
                                                    <CustomRadioButton
                                                        title={freqInterval.label}
                                                        description={freqInterval.description}
                                                        value={freqInterval.value}
                                                        checked={templateFrequencyInterval == freqInterval.value}
                                                        onChange={(e) => handleFrequecyIntervalChange(e, freqInterval)}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}

                        {activeStep === 1 &&
                            moduleArea === moduleAreaConstants.RISKASSESSMENTS &&
                            hazards?.data?.length > 0 && (
                                <Grid container className={classes.hazardContainer}>
                                    <Grid item xs={12}>
                                        <div className={classes.hazardStep}>
                                            <TextField
                                                label="Search for hazards"
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                className={classes.halfWidth}
                                                value={hazardSearch}
                                                onChange={(e) => setHazardSearch(e.target.value)}
                                            />
                                            {manageCustomHazards()}
                                        </div>
                                        <List className={classes.listWrapper}>
                                            <Grid container>
                                                {hazards?.data
                                                    ?.filter((x) =>
                                                        x.name
                                                            .toLowerCase()
                                                            .includes(hazardSearch.toLowerCase())
                                                    )
                                                    ?.map((hazard) => (
                                                        <Grid item key={hazard?.id} xs={12} md={4}>
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        onChange={() => toggleHazard(hazard?.id)}
                                                                        checked={templateHazardIds.includes(
                                                                            hazard?.id
                                                                        )}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    className={classes.hazardIconText}
                                                                >
                                                                    <img
                                                                        className={classes.hazardIcons}
                                                                        alt="warning"
                                                                        src={`${hazardImagesUrl}${hazard?.name}.svg`}
                                                                    />
                                                                    {hazard?.name}
                                                                </ListItemText>
                                                            </ListItem>
                                                        </Grid>
                                                    ))}
                                            </Grid>
                                        </List>
                                    </Grid>
                                </Grid>
                            )}

                        {activeStep === 1 &&
                            investigationSectionIds.length === 0 &&
                            moduleArea === moduleAreaConstants.INCIDENTS && (
                                <Box display="flex" justifyContent="center" width="100%">
                                    <Button
                                        className={classes.insertSectionButton}
                                        startIcon={<AddCircleOutline />}
                                        onClick={() =>
                                            dispatch(addSection({ isInvestigation: true }))
                                        }
                                    >
                                        Add Section
                                    </Button>
                                </Box>
                            )}

                        {activeStep === 0 &&
                            moduleArea !== moduleAreaConstants.RISKASSESSMENTS && (
                                <Template
                                    templateSubjectId={templateSubjectId}
                                />
                            )}

                        {activeStep === 1 &&
                            moduleArea === moduleAreaConstants.INCIDENTS && (
                                <Template
                                    templateSubjectId={templateSubjectId}
                                    isInvestigation
                                />
                            )}

                        {activeStep === 2 &&
                            moduleArea === moduleAreaConstants.RISKASSESSMENTS && (
                                <Grid container className={classes.hazardContainer}>
                                    <Grid item xs={12}>
                                        <List className={classes.listWrapper}>
                                            <Grid container>
                                                {people?.data?.map((person) => (
                                                    <Grid item key={person?.id} xs={12} md={4}>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => togglePerson(person?.id)}
                                                                    checked={templatePeopleIds.includes(
                                                                        person?.id
                                                                    )}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText>{person?.name}</ListItemText>
                                                        </ListItem>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </List>
                                    </Grid>
                                </Grid>
                            )}

                        <div className={classes.buttonsContainer}>
                            <Button
                                className={classes.formButton}
                                color="primary"
                                variant="outlined"
                                onClick={handleClickBack}
                            >
                                {activeStep === 0 ? "Cancel" : "Back"}
                            </Button>
                            <CustomButton
                                className={classes.formButton}
                                color="primary"
                                variant="contained"
                                type="submit"
                                isLoading={addNewTemplate.isLoading || updateTemplate.isLoading}
                            >
                                {(activeStep === 0 &&
                                    (moduleArea === moduleAreaConstants.INCIDENTS ||
                                        moduleArea === moduleAreaConstants.RISKASSESSMENTS)) ||
                                    (activeStep === 1 &&
                                        moduleArea === moduleAreaConstants.RISKASSESSMENTS)
                                    ? "Next"
                                    : templateVersionId && isWorknestUser
                                        ? "Update"
                                        : "Save"}
                            </CustomButton>
                        </div>
                        <Dialog open={showChangeNotesDialog} maxWidth="md" fullWidth>
                            <DialogTitle>Add version change notes</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Add change notes to the updated template version for users to
                                    view changes.
                                </DialogContentText>
                                <TextField
                                    size="small"
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    label="Change Notes"
                                    variant="outlined"
                                    value={changeNotes}
                                    onChange={(e) => setChangeNotes(e.target.value)}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={closeChangeNotesDialog}
                                    color="primary"
                                    variant="outlined"
                                >
                                    Cancel
                                </Button>
                                <Button color="primary" variant="contained" onClick={onSubmit}>
                                    Save
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={showFrequencyInformationDialog} maxWidth="md" fullWidth>
                            <Typography className={classes.dialogTitle}>Frequency</Typography>
                            <DialogContent>
                                <Typography className={classes.dialogBold}>
                                    Last completed date
                                </Typography>
                                <DialogContentText>
                                    Due date frequency will repeat at regular intervals based on when the form was last completed.
                                </DialogContentText>
                                <DialogContentText className={classes.dialogExample}>
                                    Example
                                    <DialogContentText className={classes.dialogExampleItalic}>
                                        If a form is created with a due date on 10th January with a frequency of every 3 months, but the form was not completed until 20th January, the next occurence of the form will be April 20th.
                                    </DialogContentText>
                                </DialogContentText>
                                <Typography className={classes.dialogBold}>
                                    Original due date
                                </Typography>
                                <DialogContentText>
                                    Due date frequency will repeat at regular intervals based on the original due date.
                                </DialogContentText>
                                <DialogContentText className={classes.dialogExample}>
                                    Example
                                    <DialogContentText className={classes.dialogExampleItalic}>
                                        If a form is created with a due date on 10th January with a frequency of every 3 months, it will recur on 10th April, 10 July, 10 October etc.regardless of when the form was last completed.
                                    </DialogContentText>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    className={classes.dialogClose}
                                    onClick={() => setShowFrequencyInformationDialog(false)}
                                    color="primary"
                                    variant="text"
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </form>
                </Paper>

                <Dialog
                    open={isTemplateChangeDialogOpen}
                    onClose={onTemplateChangeDialogClose}
                >
                    <DialogTitle>Discard changes?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to discard the changes you have made to this template?
                            Any unsaved changes will be lost.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={onTemplateChangeDialogClose}
                            color="primary"
                            variant="outlined"
                        >Cancel</Button>
                        <Button
                            onClick={onDiscardTemplateChanges}
                            color="primary"
                            variant="contained"
                        >Discard</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={isSubjectChangeDialogOpen}
                    onClose={onSubjectChangeDialogClose}
                >
                    <DialogTitle>Reset all outcomes?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Changing the template subject will reset all outcomes in this
                            template. Are you sure you want to change the template subject?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={onSubjectChangeDialogClose}
                            color="primary"
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onConfirmSubjectChange}
                            color="primary"
                            variant="contained"
                        >
                            Change subject
                        </Button>
                    </DialogActions>
                </Dialog>
            </PageBase>
        </>
    );
}

export default TemplateBuilder;
