import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import safetyNestScoreService from "../../services/safetyNestScoreService";

const useSafetyNestScoreApplicability = ({ externalIds }) =>
  useQuery(
    [queryKeys.safetyNestScoreApplicability, externalIds?.join(",")],
    () =>
      safetyNestScoreService.fetchSafetyNestScoreApplicability({ externalIds }),
    { enabled: !!externalIds?.length }
  );

export default useSafetyNestScoreApplicability;
