import { createContext, useState, useContext, useReducer } from "react";

const defaultActionCreateState = {
    description: null,
    dueDate: null,
    rating: null,
    siteExternalId: null,
    module: null,
    assignedEmployeeId: null,
    azureFile: null,
    applicationArea:null,
    newAzureFileId: null,
}

const actionCreateUpdateAction = {
    SET_DESCRIPTION: 'SET_DESCRIPTION',
    SET_DUE_DATE: 'SET_DUE_DATE',
    SET_RATING: 'SET_RATING',
    SET_EXTERNALID: 'SET_SITE_EXTERNALID',
    SET_MODULE: 'SET_MODULE',
    SET_ASSIGNED_EMPLOYEEID: 'SET_ASSIGNED_EMPLOYEEID',    
    SET_AZURE_FILE: 'SET_AZURE_FILE',
    ADD_AZURE_FILE: 'ADD_AZURE_FILE',
}

function createActionReducer(state, action) {
    switch (action.type) {
        case actionCreateUpdateAction.SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload
            }
        case actionCreateUpdateAction.SET_DUE_DATE:
            return {
                ...state,
                dueDate: action.payload
            }
        case actionCreateUpdateAction.SET_RATING:
            return {
                ...state,
                rating: action.payload
            }
        case actionCreateUpdateAction.SET_SITE_EXTERNALID:
            return {
                ...state,
                siteExternalId: action.payload
            }
        case actionCreateUpdateAction.SET_MODULE:
            return {
                ...state,
                applicationArea: action.payload,
            }  
        case actionCreateUpdateAction.SET_ASSIGNED_EMPLOYEEID:
            return {
                ...state,
                assignedEmployeeId: action.payload
            }
        case actionCreateUpdateAction.SET_AZURE_FILE:
            return {
                ...state,
                azureFile: action.payload
            }
        case actionCreateUpdateAction.ADD_AZURE_FILE:
            return {
                ...state,
                azureFile: action.payload.file,
                newAzureFileId: action.payload.syncId
            }
        default:
            return state
    }
}

const CreateActionContext = createContext();

function CreateActionProvider({ children }) {
    const [createActionDialogOpen, setCreateActionDialogOpen] = useState(false)
    const [requiresCreateValidation, setRequiresCreateValidation] = useState(false)

    const [actionDetails, createActionDispatch] = useReducer(createActionReducer, defaultActionCreateState)

    function setDescription(description) {
        createActionDispatch({ type: actionCreateUpdateAction.SET_DESCRIPTION, payload: description })
    }

    function setDueDate(date) {
        createActionDispatch({ type: actionCreateUpdateAction.SET_DUE_DATE, payload: date })
    }

    function setRating(rating) {
        createActionDispatch({ type: actionCreateUpdateAction.SET_RATING, payload: rating })
    }

    function setSiteExternalId(siteExternalId) {
        createActionDispatch({ type: actionCreateUpdateAction.SET_SITE_EXTERNALID, payload: siteExternalId })
    }

    function setModule(module) {
        createActionDispatch({ type: actionCreateUpdateAction.SET_MODULE, payload: module })
    }

    function setAssignedEmployeeId(assignedEmployeeId) {
        createActionDispatch({ type: actionCreateUpdateAction.SET_ASSIGNED_EMPLOYEEID, payload: assignedEmployeeId })
    }

    function setAzureFile(azureFile) {
        createActionDispatch({ type: actionCreateUpdateAction.SET_AZURE_FILE, payload: azureFile })
    }

    function addAzureFile(azureFile) {
        createActionDispatch({ type: actionCreateUpdateAction.ADD_AZURE_FILE, payload: azureFile })
    }

    function reset() {
        setDescription(null)
        setDueDate(null)
        setRating(null)
        setSiteExternalId(null)
        setModule(null)
        setAssignedEmployeeId(null)
        setAzureFile(null)
        setRequiresCreateValidation(false)
        setCreateActionDialogOpen(false)
    }
    
    const value = {
        actionDetails,
        setDescription,
        setDueDate,
        setRating,
        setSiteExternalId,
        setModule,
        setAssignedEmployeeId,
        setAzureFile,
        addAzureFile,
        reset,
        requiresCreateValidation,
        setRequiresCreateValidation,
        createActionDialogOpen,
        setCreateActionDialogOpen,
    }

    return (
        <CreateActionContext.Provider value={value}>
            {children}
        </CreateActionContext.Provider>
    )
}

const useCreateActionContext = () => useContext(CreateActionContext);

export { CreateActionProvider, useCreateActionContext }