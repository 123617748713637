/* eslint-disable react-hooks/rules-of-hooks */

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import useTemplateVersions from '../../../hooks/queries/useTemplateVersions'
import { useAuth } from '../../../../contexts/authContext';
import { formatShortDate } from '../../../../utils/dateTimeUtils'
import useContactsForAppAndSite from "../../../../hooks/useContactsForAppAndSite";

export default function TemplateVersionHistoryDialog({ open, onClose, templateId, versions }) {
    if (!templateId && !versions) return null

    let templateVersions = versions
    if (templateId) {
        templateVersions = useTemplateVersions({ templateId })
    }
    const { isWorknestUser } = useAuth()
    
    const versionsToMap = templateId ? templateVersions?.data : templateVersions

    const contacts = useContactsForAppAndSite({ selectedSiteId: null });

    return (
        <Dialog open={open} maxWidth="md" fullWidth>
            <DialogTitle>Version History</DialogTitle>
            <DialogContent>
                {!templateId && <DialogContentText>There have been changes to the template since you last visited.</DialogContentText>}
                <Table>
                    <TableHead>
                        <TableCell>Version Number</TableCell>
                        <TableCell>Date</TableCell>
                        {!isWorknestUser && <TableCell>User</TableCell>}
                        <TableCell>Notes</TableCell>
                    </TableHead>
                    <TableBody>
                        {versionsToMap?.sort((a, b) => a.versionNumber - b.versionNumber).map(version => (
                            <TableRow key={version.id}>
                                <TableCell>{version.versionNumber}</TableCell>
                                <TableCell>{formatShortDate(version.createdOn)}</TableCell>
                                {!isWorknestUser && <TableCell>{(contacts?.data && `${contacts?.data[version.createdByExternalId]?.firstName ?? 'Unknown'} ${contacts?.data[version.createdByExternalId]?.lastName ?? 'User'}`)}</TableCell>}
                                <TableCell>{version.changeNotes ?? '-'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button 
                    variant='text' 
                    onClick={onClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}