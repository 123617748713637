import { useMutation } from "react-query";
import incidentService from "../../services/incidentService";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";

const useDeleteIncident = () =>
    useMutation(incidentService.deleteIncident, {
        onMutate: async (incidentUpdate) => {
            const { client } = reactQueryUtils;
            const queryKey = [
                queryKeys.incidents,
                {
                    numPerPage: incidentUpdate.rows,
                    page: incidentUpdate.page,
                }, incidentUpdate.externalIds?.join(","),
            ];
            await client.cancelQueries(queryKey);
            const previousIncidents = client.getQueryData(queryKey);

            client.setQueryData(queryKey, incidents => {
                var validIncidents = incidents?.results?.filter(d => d.id !== incidentUpdate.id && d.isDriIncident == incidentUpdate.isDri);
                return {
                    totalCount: validIncidents?.count,
                    results: validIncidents
                };
            }
            );

            return { previousIncidents }
        },
        onError: (error, data, context) => {
            reactQueryUtils.client.setQueryData(
                [
                    queryKeys.incidents,
                    data.externalIds?.join(","),
                    data.page,
                    data.rows
                ],
                context.previousIncidents
            );
        },
        onSettled: (_data, _error, variables) => {
            if (variables)
                reactQueryUtils.client.invalidateQueries([
                    queryKeys.incidents,
                    variables.externalIds?.join(","),
                    variables.page,
                    variables.rows
                ]);
            reactQueryUtils.client.invalidateQueries(queryKeys.accountCurrentActions, { exact: false });
        },
  });

export default useDeleteIncident;
