import { Button, IconButton, makeStyles } from "@material-ui/core"
import { DeleteOutline } from "@material-ui/icons"
import { nanoid } from "nanoid"
import { useHistoricActionContext } from "../../contexts/HistoricActionContext"

const useStyles = makeStyles((theme) => ({
    flex: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1)
    },
}))

export default function ActionAttachment() {
    const classes = useStyles()

    const {
        addAzureFile,
        historicActionDetails,
        deleteAzureFile,
    } = useHistoricActionContext()

    const handleChangeFile = async (e) => {
        const files = e.target.files
    
        if (files && files.length) {
          const newFile = {
            syncId: nanoid(5),
            file: files[0],
          };
    
          addAzureFile(newFile)
        }
    };

    return (
        <div>
            {historicActionDetails.azureFile ? (
                <div className={classes.flex}>
                    <Button 
                        variant="text" 
                        color="secondary"
                    >
                        {historicActionDetails.azureFile.name}
                    </Button>
                    <IconButton
                        onClick={() => deleteAzureFile(historicActionDetails.azureFile)}
                    >
                        <DeleteOutline />
                    </IconButton>
                </div>
            ) : (
                <Button
                    variant="text"
                    color="secondary"
                    component="label"
                >
                    Select file to upload
                    <input
                        type="file"
                        hidden
                        onChange={handleChangeFile}
                    />
                </Button>
            )}
        </div>
    )
}