import axios from "../utils/axios";

const acknowledgementEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/Acknowledgement`;

const getDetailsByReference = ({ reference }) => axios.get(`${acknowledgementEndpointUrl}/Details/${reference}`)

const verify = ({ emailAddress, acknowledgementId }) => {
    let queryString = `?emailAddress=${emailAddress}&id=${acknowledgementId}`

    return axios.get(`${acknowledgementEndpointUrl}/Verify${queryString}`);
}

const acknowledge = ({ acknowledgementRecipientId }) => axios.post(`${acknowledgementEndpointUrl}/Acknowledge/${ acknowledgementRecipientId }`);
  
const acknowledgementService = {
    getDetailsByReference,
    verify,
    acknowledge,
};
  
export default acknowledgementService;