import { makeStyles, TextField, InputAdornment, Button } from "@material-ui/core"
import SearchIcon from '@material-ui/icons/Search'
import CardBase from "../../components/cards/CardBase";
import StringUtils from "../utils/StringUtils";
import { useSiteSearchContext } from "../contexts/SiteSearchContext";

const useStyles = makeStyles(theme => ({
    searchBarContainer: {
        padding: theme.spacing(1, 0),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
}))

export default function SearchBar({ isHistoric }) {
    const classes = useStyles()

    const { siteSearch, setSiteSearch, handleSiteSearch } = useSiteSearchContext()

    function handleSearchChange(e) {
        setSiteSearch(e.target.value)
    }

    return (
        <CardBase
            title={isHistoric ? 'Historic Audits' : 'Audits'}
            description={'Search for a site.'}
        >
            <div className={classes.searchBarContainer}>
                <TextField 
                    fullWidth
                    label='Search' 
                    value={siteSearch}
                    onChange={handleSearchChange}
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <Button
                    color="primary"
                    variant="contained"
                    disabled={StringUtils.isNullOrWhiteSpace(siteSearch)}
                    fullWidth
                    onClick={handleSiteSearch}
                >
                    Search
                </Button>
            </div>
        </CardBase>
    )
}