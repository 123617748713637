import { answerTypes } from "../constants/answerConstants";
import moduleAreaConstants from "../constants/moduleAreaConstants";

class TemplateQuestionBuilder {
    constructor(questionDetails, questionIndex, choiceOutcomes, options, moduleArea) {
        this.isMultipleChoiceQuestion =
            questionDetails.answerType === answerTypes.DROPDOWN ||
            questionDetails.answerType === answerTypes.RADIO ||
            questionDetails.answerType === answerTypes.MULTISELECT;
        this.isNumberQuestion = questionDetails.answerType === answerTypes.NUMBER;
        this.questionDetails = questionDetails;
        this.choiceOutcomes = choiceOutcomes;
        this.options = options;
        this.moduleArea = moduleArea;

        this.question = {
            text: questionDetails.text,
            allowPhotoUpload: questionDetails.allowPhotoUpload,
            attachmentMandatory: questionDetails.attachmentMandatory,
            displayOrder: questionIndex,
            answerType: questionDetails.answerType,
        };
    }

    withOutcomes() {
        const isValidAnswerType = this.isNumberQuestion;

        if (!isValidAnswerType) return this;

        this.question.outcomes = this.questionDetails.outcomeIds.map(
            (outcomeId) => {
                const outcomeDetails = this.choiceOutcomes[outcomeId];

                const outcome = {
                    actionPresetId: outcomeDetails.actionPresetId,
                };

                if (this.isNumberQuestion) {
                    outcome.numberOutcome = {
                        logicType: outcomeDetails.numberOutcome?.logicType,
                        value1: outcomeDetails.numberOutcome?.value1,
                        value2: outcomeDetails.numberOutcome?.value2,
                    };
                }

                return outcome;
            }
        );

        return this;
    }

    withRangeRestrictions() {
        if (!this.isNumberQuestion) return this;

        this.question.rangeRestriction = {};
        if (!!this.questionDetails.rangeRestriction?.minValue)
            this.question.rangeRestriction.minValue = this.questionDetails.rangeRestriction?.minValue;
        if (!!this.questionDetails.rangeRestriction?.maxValue)
            this.question.rangeRestriction.maxValue = this.questionDetails.rangeRestriction?.maxValue;

        return this;
    }

    withIncidentFields() {
        if (this.moduleArea !== moduleAreaConstants.INCIDENTS) return this;

        this.question.isMandatory = this.questionDetails.isMandatory;
        this.question.isHelpInfoRequired = this.questionDetails.isHelpInfoRequired;
        this.question.helpInfoText = this.questionDetails.helpInfoText;

        return this;
    }

    withMultipleChoiceOptionsAndOutcomes() {
        if (!this.isMultipleChoiceQuestion) return this;

        this.question.options = this.questionDetails.optionIds.map(
            (optionId, optionIndex) => {
                const optionText = this.options[optionId].option;
                if (optionText === "") throw new Error("Options must have text");

                return {
                    option: optionText,
                    displayOrder: optionIndex,
                    requiresAdditionalInfo: this.options[optionId].requiresAdditionalInfo,
                    choiceOutcomes: this.questionDetails.outcomeIds
                        .filter(outcomeId => this.choiceOutcomes[outcomeId].templateQuestionOptionId === optionId)
                        .map((outcomeId) => {
                            const outcomeDetails = this.choiceOutcomes[outcomeId];
                            return {
                                actionPresetId: outcomeDetails.actionPresetId,
                                logicType: outcomeDetails.logicType,
                                rating: outcomeDetails.rating,
                            };
                        })
                };
            }
        );

        return this;
    }

    build() {
        return this.question;
    }
}

export default TemplateQuestionBuilder;
