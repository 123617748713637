import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  errorContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  error: {
    backgroundColor: "#fff",
  },
}));

const ErrorBox = ({ message }) => {
  const classes = useStyles();

  return (
    <Box className={classes.errorContainer}>
      <Alert className={classes.error} variant="outlined" severity="error">
        {message}
      </Alert>
    </Box>
  );
};

export default ErrorBox;