import { useSnackbar } from 'notistack';
import { createContext, useContext, useEffect, useReducer, useState } from 'react'
import useDeleteHistoricAudit from '../hooks/mutations/useDeleteHistoricAudit';

const defaultHistoricAuditCreateUpdateState = {
    createdDate: null,
    lastModifiedDate: null,
    startDate: null,
    endDate: null,
    type: null,
    description: null,
    document: null,
    documentIdToDelete: null,
    newDocumentId: null,
}

const historicAuditCreateUpdateActionTypes = {
    SET_CREATED_DATE: 'SET_CREATED_DATE',
    SET_LAST_MODIFIED_DATE: 'SET_LAST_MODIFIED_DATE',
    SET_START_DATE: 'SET_START_DATE',
    SET_END_DATE: 'SET_END_DATE',
    SET_TYPE: 'SET_TYPE',
    SET_DESCRIPTION: 'SET_DESCRIPTION',
    SET_DOCUMENT: 'SET_DOCUMENT',
    ADD_DOCUMENT: 'ADD_DOCUMENT',
    DELETE_DOCUMENT: 'DELETE_DOCUMENT',
}

export function isAzureFile(doc) {
    return doc?.azureFileId !== undefined
}

function historicAuditReducer(state, action) {
    switch (action.type) {
        case historicAuditCreateUpdateActionTypes.SET_CREATED_DATE:
            return {
                ...state,
                createdDate: action.payload,
            }
        case historicAuditCreateUpdateActionTypes.SET_LAST_MODIFIED_DATE:
            return {
                ...state,
                lastModifiedDate: action.payload,
            }
        case historicAuditCreateUpdateActionTypes.SET_START_DATE:
            return {
                ...state,
                startDate: action.payload,
            }
        case historicAuditCreateUpdateActionTypes.SET_END_DATE:
            return {
                ...state,
                endDate: action.payload,
            }
        case historicAuditCreateUpdateActionTypes.SET_TYPE:
            return {
                ...state,
                type: action.payload,
            }
        case historicAuditCreateUpdateActionTypes.SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            }
        case historicAuditCreateUpdateActionTypes.ADD_DOCUMENT:
            return {
                ...state,
                document: action.payload.file,
                newDocumentId: action.payload.syncId,
            }
        case historicAuditCreateUpdateActionTypes.DELETE_DOCUMENT: {
            if (isAzureFile(action.payload)) {
                return {
                    ...state,
                    documentIdToDelete: action.payload.azureFileId,
                    document: null,
                }
            }
            
            return {
                ...state,
                document: null,
                newDocumentId: null,
            }
        }
        case historicAuditCreateUpdateActionTypes.SET_DOCUMENT:             
            return {
                ...state,
                document: action.payload
            }
        default:
            return state
    }
}

const HistoricAuditContext = createContext();

function HistoricAuditProvider({ children }) {
    const [siteSearch, setSiteSearch] = useState('')
    const [siteSearchValue, setSiteSearchValue] = useState('')
    const [showSiteResults, setShowSiteResults] = useState(false)
    const [selectedSite, setSelectedSite] = useState(null)
    const [selectedAudit, setSelectedAudit] = useState(undefined)
    const [isPDFError, setIsPDFError] = useState(false)
    const [isCreationMode, setIsCreationMode] = useState(false)
    const [requiresCreateValidation, setRequiresCreateValidation] = useState(false)
    const [deleteHistoricAuditDialogOpen, setDeleteHistoricAuditDialogOpen] = useState(false)
    const [historicAuditIdToDelete, setHistoricAuditIdToDelete] = useState(null)

    const [historicAuditDetails, historicAuditDespatch] = useReducer(historicAuditReducer, defaultHistoricAuditCreateUpdateState)

    const deleteHistoricAudit = useDeleteHistoricAudit()
    const { enqueueSnackbar } = useSnackbar()

    function setCreatedDate(date) {
        historicAuditDespatch({ type: historicAuditCreateUpdateActionTypes.SET_CREATED_DATE, payload: date })
    }

    function setLastModifiedDate(date) {
        historicAuditDespatch({ type: historicAuditCreateUpdateActionTypes.SET_LAST_MODIFIED_DATE, payload: date })
    }

    function setStartDate(date) {
        historicAuditDespatch({ type: historicAuditCreateUpdateActionTypes.SET_START_DATE, payload: date })
    }

    function setEndDate(date) {
        historicAuditDespatch({ type: historicAuditCreateUpdateActionTypes.SET_END_DATE, payload: date })
    }

    function setType(type) {
        historicAuditDespatch({ type: historicAuditCreateUpdateActionTypes.SET_TYPE, payload: type })
    }

    function setDescription(description) {
        historicAuditDespatch({ type: historicAuditCreateUpdateActionTypes.SET_DESCRIPTION, payload: description })
    }

    function addDocument(document) {
        historicAuditDespatch({ type: historicAuditCreateUpdateActionTypes.ADD_DOCUMENT, payload: document })
    }

    function deleteDocument(document) {
        historicAuditDespatch({ type: historicAuditCreateUpdateActionTypes.DELETE_DOCUMENT, payload: document })
    }

    function setDocument(document) {
        historicAuditDespatch({ type: historicAuditCreateUpdateActionTypes.SET_DOCUMENT, payload: document })
    }

    function reset() {
        setCreatedDate(null)
        setLastModifiedDate(null)
        setStartDate(null)
        setEndDate(null)
        setType(null)
        setDescription(null)
        setDocument(null)
        setIsPDFError(false)
        setIsCreationMode(false)
        setRequiresCreateValidation(false)
        setDeleteHistoricAuditDialogOpen(false)
        setHistoricAuditIdToDelete(null)
    }

    useEffect(() => {
        if (selectedAudit) {
            setCreatedDate(selectedAudit.createdDate)
            setLastModifiedDate(selectedAudit.lastModifiedDate)
            setStartDate(selectedAudit.startDate)
            setEndDate(selectedAudit.endDate)
            setType(selectedAudit.type)
            setDescription(selectedAudit.description)
            setDocument(selectedAudit.documents && selectedAudit.documents.length > 0 ? selectedAudit.documents[selectedAudit.documents.length - 1] : null)
        }
    }, [selectedAudit])

    function handleSiteSearch() {
        setShowSiteResults(true)
        setSiteSearchValue(siteSearch)
    }

    function handleDeleteHistoricAudit(auditId) {
        if (!auditId) {
            enqueueSnackbar('Audit not found.', { variant: 'error' })
            return
        }

        const historicAuditToDelete = {
            historicAuditId: auditId,
            siteExternalId: selectedSite?.externalId ?? '',
        }

        deleteHistoricAudit.mutate(historicAuditToDelete)
        enqueueSnackbar('Audit deleted successfully.', { variant: 'success' })
        setDeleteHistoricAuditDialogOpen(false)
    }

    const value = {
        siteSearch,
        setSiteSearch,
        handleSiteSearch,
        showSiteResults,
        siteSearchValue,
        selectedSite,
        setSelectedSite,
        selectedAudit,
        setSelectedAudit,
        historicAuditDetails,
        setCreatedDate,
        setLastModifiedDate,
        setStartDate,
        setEndDate,
        setType,
        setDescription,
        addDocument,
        deleteDocument,
        reset,
        isPDFError,
        setIsPDFError,
        isCreationMode,
        setIsCreationMode,
        requiresCreateValidation,
        setRequiresCreateValidation,
        deleteHistoricAuditDialogOpen,
        setDeleteHistoricAuditDialogOpen,
        historicAuditIdToDelete,
        setHistoricAuditIdToDelete,
        handleDeleteHistoricAudit,
    }

    return (
        <HistoricAuditContext.Provider value={value}>
            {children}
        </HistoricAuditContext.Provider>
    )
}

const useHistoricAuditContext = () => useContext(HistoricAuditContext)

export { HistoricAuditProvider, useHistoricAuditContext }