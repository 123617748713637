import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import FormQuestion from "./FormQuestion";
import CardBase from "../../../components/cards/CardBase";

const useStyles = makeStyles((theme) => ({
  questions: {
    marginTop: theme.spacing(2),
  },
}));

const FormSection = ({ section, flat = false }) => {
  const classes = useStyles();

  return (
    <CardBase title={section.name} flat={flat}>
      <div className={classes.questions}>
        <Grid container spacing={4}>
          {section.questions.map((question) => (
            <Grid
              item
              key={question.id}
              xs={12}
              md={section.questions.length > 5 ? 6 : 12}
            >
              <FormQuestion question={question} />
            </Grid>
          ))}
        </Grid>
      </div>
    </CardBase>
  );
};

export default FormSection;
