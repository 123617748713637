import React, { useState } from "react";
import {
    Box,
    CircularProgress,
    Container,
    makeStyles,
    Typography,
    TextField,
    Button
} from "@material-ui/core";
import usePrismicCategories from "../../hooks/queries/usePrismicCategories";
import { useTracking } from "react-tracking";
import { sectionNameMap } from "../../constants/knowledgeHubNameMaps";
import KnowedgeHubBreadcrumbs from "../../components/knowledgeHub/KnowledgeHubBreadcrumbs";
import CategoriesGrid from "../../components/knowledgeHub/CategoriesGrid";
import ErrorBox from "../../components/ErrorBox";
import { useRouteMatch } from "react-router-dom";
import PageBase from "../../components/PageBase";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(2),
    },
    loadingContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const HubCategories = () => {
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();
    const { params } = match;
    const { sectionName } = params;
    const { Track } = useTracking({
        page: `KnowledgeHub:${sectionName}`,
    });
    const { data, isLoading, isError, error } = usePrismicCategories(sectionName);
    const [searchText, setSearchText] = useState('');

    const search = () => {
        history.push(`/knowledgehub/${sectionName}/search/${searchText}`)
    }

    const content = () => {
        if (isLoading)
            return (
                <Box className={classes.loadingContainer}>
                    <CircularProgress color="primary" />
                </Box>
            );

        if (isError) return <ErrorBox message={error.message} />;

        return <CategoriesGrid categories={data} />;
    };   

    return (
        <PageBase>
            <Track>
                <Container maxWidth="lg">
                    <KnowedgeHubBreadcrumbs />
                    <Typography className={classes.title} variant="h4">{sectionNameMap[sectionName]}</Typography>
                    <Box display="flex" my={2}>
                        <TextField
                            className={classes.description}
                            placeholder="search"
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            variant="outlined"
                            size="small"
                        />
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            disabled={searchText.length < 4}
                            onClick={search}>
                            Search
                        </Button>
                    </Box>
                    
                    {content()}                   
                </Container>
            </Track>
        </PageBase>
    );
};

export default HubCategories;
