import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import subjectService from "../../services/subjectService";

const useSubjects = () =>
  useQuery([queryKeys.subjects], () => subjectService.fetchAllSubjects(), {
    staleTime: Infinity,
  });

export default useSubjects;
