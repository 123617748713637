import axios from "../utils/axios";

const apiUrl = process.env.REACT_APP_LEGAL_URL;

const getProductRecommendation = async (accountId) => {
    return axios.get(`${apiUrl}/client/getproductrecommendation/${accountId}`);
};

const acceptRecommendation = async (recommendation) => {
    try {
        const response = await axios.post(`${apiUrl}/client/acceptrecommendation`, recommendation);
        return response.data
    } catch (e) {
        return Promise.reject(e?.message || "Could not save product recommendation.");
    }
}


const productService = {
    getProductRecommendation,
    acceptRecommendation,
};

export default productService;
