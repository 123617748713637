import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    Menu,
} from "@material-ui/core";
import { Apps } from "@material-ui/icons";
import useQuickNavigation from "../../hooks/queries/useQuickNavigation";
import { useSelector } from "react-redux";
import QuickNavigationMenuItem from "./QuickNavigationMenuItem";
import useIsMobile from "../../hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
    menu: {
        maxWidth: 450,
    },
}));

function QuickNavigationMenu() {
    const classes = useStyles();
    const isMobile = useIsMobile();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const { selectedAccount } = useSelector((state) => state.account);
    const quickNavigation = useQuickNavigation(selectedAccount?.childExternalIds);

    const handleMenuToggle = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    if (!quickNavigation.data)
        return null;

    const menuItems = quickNavigation.data.map((app) => (
        <div key={app.id}><QuickNavigationMenuItem app={app} /></div>
    ));

    return (
        <>
            <IconButton
                aria-label="Quick navigation menu"
                aria-controls="quick-navigation-menu"
                aria-haspopup="true"
                onClick={(e) => handleMenuToggle(e)}
                color="inherit"
            >
                <Apps />
            </IconButton>
            {isMobile ? (
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={!!menuAnchorEl}
                    onClose={handleCloseMenu}
                >
                    <DialogTitle>Quick Navigation</DialogTitle>
                    <DialogContent>{menuItems}</DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseMenu}>Close</Button>
                    </DialogActions>
                </Dialog>
            ) : (
                <Menu
                    id="quick-navigation-menu"
                    anchorEl={menuAnchorEl}
                    getContentAnchorEl={null}
                    keepMounted
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    classes={{ paper: classes.menu }}
                    open={!!menuAnchorEl}
                    onClose={handleCloseMenu}
                    onClick={handleCloseMenu}
                >
                    {menuItems}
                </Menu>
            )}
        </>
    );
}

export default QuickNavigationMenu;
