import { useMemo } from "react";

const useDocumentNames = ({ selectedFolder, data }) =>
  useMemo(() => {
    const siteExternalId = selectedFolder.split("/")[0];
    const site = data.filter(
      (x) => x.accountExternalId === siteExternalId
    )?.[0];
    const documents = site?.documents.filter((x) => {
      if (!x.name.includes(selectedFolder)) return false;
      const name = x.name.replace(selectedFolder + "/", "");
      return !name.includes("/") && !name.includes("$$$.$$$");
    });
    return (
      documents?.map((x) => x.name.replace(selectedFolder + "/", "")) || []
    );
  }, [selectedFolder, data]);

export default useDocumentNames;
