import axios from "../../utils/axios";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const saveRiskAssessmentHistory = (riskAssessmentToSave) => axios.post(`${apiUrl}/RiskAssessmentHistory`, riskAssessmentToSave);

const archiveRiskAssessmentHistory = (riskAssessmentHistoryUpdate) =>
    axios.post(`${apiUrl}/riskassessmenthistory/archive/${riskAssessmentHistoryUpdate.archiveRiskAssessmentId}/${riskAssessmentHistoryUpdate.id}`);

const downloadRiskAssessmentPdf = (riskAssessmentId, riskAssessmentHistoryId) =>
    axios.get(`${apiUrl}/riskassessmenthistory/downloadpdf/${riskAssessmentId}/${riskAssessmentHistoryId}`, {
        responseType: "arraybuffer",
    });

const getRiskAssessmentHistoryAcknowledgementRecipients = (riskAssessmentHistoryId) =>
    axios.get(`${apiUrl}/riskassessmenthistory/acknowledgementrecipients/${riskAssessmentHistoryId}`);

const shareRiskAssessmentHistory = ({ riskAssessmentHistoryId, emailAddresses, isReSend }) => 
    axios.post(`${apiUrl}/riskassessmenthistory/share/${riskAssessmentHistoryId}/${isReSend}`, emailAddresses);

const fetchRiskAssessmentVersionHistory = (riskAssessmentHistoryId) => {
    return axios.get(`${apiUrl}/RiskAssessmentVersionHistory/${riskAssessmentHistoryId}`);
}

const fetchRiskAssessmentHistory = (riskAssessmentHistoryId) => {
    return axios.get(`${apiUrl}/RiskAssessmentHistory/${riskAssessmentHistoryId}`);
}

const riskAssessmentHistoryService = {
    saveRiskAssessmentHistory,
    archiveRiskAssessmentHistory,
    downloadRiskAssessmentPdf,
    getRiskAssessmentHistoryAcknowledgementRecipients,
    shareRiskAssessmentHistory,
    fetchRiskAssessmentVersionHistory,
    fetchRiskAssessmentHistory,
};

export default riskAssessmentHistoryService;
