import axios from "../../utils/axios";
import { buildQueryStringList } from "../../utils/stringUtils";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const fetchForSubject = (subjectId, accountId, siteExternalIds) => {
    let sitesList = buildQueryStringList("siteExternalIds", siteExternalIds);
    
    return axios.get(`${apiUrl}/ActionPreset/GetBySubjectId/${subjectId}${sitesList ? sitesList : "?"
        }&accountId=${accountId}`);
}


const fetchForIncidents = (accountId, siteExternalIds) => {
    let sitesList = buildQueryStringList("siteExternalIds", siteExternalIds);
    return axios.get(`${apiUrl}/ActionPreset/GetForIncidents/${sitesList ? sitesList : "?"
        }&accountId=${accountId}`);
}

const addNew = (actionPreset) =>
  axios.post(`${apiUrl}/ActionPreset/Add`, actionPreset);

const actionPresetService = {
  fetchForSubject,
  addNew,
  fetchForIncidents,
};

export default actionPresetService;
