import TrustCentreArticle from "../TrustCentreArticle";

const contentNavItems = [
    {
        id: "terms-of-use",
        title: "Terms of Use",
    },
    {
        id: "changes-to-these-terms",
        title: "Changes to these Terms",
    },
    {
        id: "changes-to-our-site",
        title: "Changes to our Site",
    },
    {
        id: "reliance-on-information",
        title: "Reliance on Information",
    },
    {
        id: "limitation-of-our-liability",
        title: "Limitation of our Liability",
    },
    {
        id: "uploading-content-to-our-site",
        title: "Uploading Content to our Site",
    },
    {
        id: "viruses",
        title: "Viruses",
    },
    {
        id: "contact-us",
        title: "Contact Us",
    },
];

function TermsOfUsePolicy() {
    return (
        <TrustCentreArticle title="Terms of Use Policy" lastUpdatedDate="2023-08-01" contentsNavItems={contentNavItems}>
            <section id="terms-of-use">
                <h3>Terms of Use</h3>
                <p>Please read the following notice carefully. Your use of our website will be regarded as acceptance of these conditions.</p>
                <p>
                    WorkNest accepts no liability for loss of any kind because of any inaccuracies or of your reliance on the information contained on the site.
                </p>
                <p>Any legal or health and safety related information is not a substitute for specific advice relevant to your circumstances.</p>
                <p>
                    We cannot guarantee that our website and any matter downloaded from this site will be free from viruses or other harmful programs or
                    computer code.
                </p>
                <p>We accept no liability for any loss that may be suffered by any person arising from any such harmful programs or computer code.</p>
            </section>
            <section id="changes-to-these-terms">
                <h3>Changes to these Terms</h3>
                <p>We may revise these terms of use at any time by amending this page.</p>
                <p>Please check this page from time to time to take notice of any changes we made, as they are binding on you.</p>
            </section>
            <section id="changes-to-our-site">
                <h3>Changes to our Site</h3>
                <p>
                    We may update our site from time to time, and may change the content at any time. However, please note that any of the content on our site
                    may be out of date at any given time, and we are under no obligation to update it.
                </p>
                <p>We do not guarantee that our site, or any content on it, will be free from errors or omissions.</p>
            </section>
            <section id="reliance-on-information">
                <h3>Reliance on Information</h3>
                <p>
                    The content of the general areas of our site is provided for general information only. It is not intended to amount to advice on which you
                    should rely.
                </p>
                <p>
                    Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether
                    express or implied, that the content on the general areas of our site are complete or up-to-date.
                </p>
                <p>
                    When you access data, documents and advice we provide as part of the services to which you have subscribed, such access is governed by the
                    terms of the customer services agreement which you have entered into with us as well as the letters of engagement of our external legal
                    advisors which you have agreed to.
                </p>
            </section>
            <section id="limitation-of-our-liability">
                <h3>Limitation of our Liability</h3>
                <p>
                    Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or
                    fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.
                </p>
                <p>
                    We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or
                    otherwise, even if foreseeable, arising under or in connection with the use of, or inability to use, our site.
                </p>
                <p>We will not be liable to you for:</p>
                <ul>
                    <li>loss of profits, sales, business, or revenue</li>
                    <li>business interruption</li>
                    <li>loss of anticipated savings</li>
                    <li>loss of business opportunity, goodwill or reputation, or</li>
                    <li>any indirect or consequential loss or damage</li>
                </ul>
                <p>If you are a consumer user, please note that we only provide services to businesses and you agree not to use our site as a consumer.</p>
                <p>
                    We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful
                    material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our site or to
                    your downloading of any content on it, or on any website linked to it.
                </p>
                <p>
                    We assume no responsibility for the content of websites linked on our site. Such links should not be interpreted as endorsement by us of
                    those linked websites. We will not be liable for any loss or damage that may arise from your use of them.
                </p>
            </section>
            <section id="uploading-content-to-our-site">
                <h3>Uploading Content to our Site</h3>
                <p>
                    Whenever you make use of a feature that allows you to upload content to our site, or to make contact with other users of our site, you must
                    comply with the content standards set out in our Acceptable Use Policy.
                </p>
                <p>
                    You warrant that any such contribution complies with those standards, and you will be liable to us and indemnify us for any breach of that
                    warranty.
                </p>
                <p>
                    Any content you upload to our site will be treated as confidential and in compliance with the Data Protection Act 1998. You retain all of
                    your ownership rights in your content, but you are required to grant us and other users of our site a non-exclusive, perpetual,
                    royalty-free, world-wide licence to use, store and copy that content and to distribute and make it available to those third parties who may
                    provide services to you.
                </p>
                <p>
                    We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our site
                    constitutes a violation of their intellectual property rights, or of their right to privacy.
                </p>
                <p>
                    We will not be responsible, or liable to any third party, for the content or accuracy of any content posted by you or any other user of our
                    site.
                </p>
                <p>
                    We have the right to remove any posting you make on our site if, in our opinion, your post does not comply with the content standards set
                    out in our Acceptable Use Policy.
                </p>
                <p>The views expressed by other users on our site do not represent our views or values.</p>
            </section>
            <section id="viruses">
                <h3>Viruses</h3>
                <p>We do not guarantee that our site will be secure or free from bugs or viruses.</p>
                <p>
                    You are responsible for configuring your information technology, computer programmes and platform in order to access our site. You should
                    use your own virus protection software.
                </p>
                <p>
                    You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or
                    technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server,
                    computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service
                    attack. By breaching this provision, you may commit criminal offences under the Computer Misuse Act 1990 and the Data Protection Act 1998.
                    We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your
                    identity to them. In the event of such a breach, your right to use our site will cease immediately.
                </p>
            </section>
            <section id="contact-us">
                <h3>Contact Us</h3>
                <p>
                    To contact us, please email <a href="mailto:enquiries@worknest.com">enquiries@worknest.com</a>
                </p>
                <p>Thank you for visiting our site.</p>
            </section>
        </TrustCentreArticle>
    );
}

export default TermsOfUsePolicy;
