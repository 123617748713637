import React from "react";
import { alpha, makeStyles, Radio, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  description: {
    fontSize: 13,
    color: alpha(theme.palette.primary.main, 0.6),
  },
  warningText: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.error.main,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    minHeight: "100%",
    padding: theme.spacing(2),
    border: "1px solid lightgray",
    borderRadius: 4,
    cursor: "pointer",
  },
  radioContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  radio: {
    padding: 0,
  },
  radioChecked: {
    color: theme.palette.secondary.main,
  },
  borderSelected: {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}));

export const CustomRadioButton = ({
  title = "",
  description = "",
  value = "",
  checked = false,
  onChange,
  warningText,
}) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.container} ${checked && classes.borderSelected}`}
      onClick={() => onChange(value)}
    >
      <div className={classes.radioContainer}>
        <Typography variant="body1" className={classes.title}>
          {title}
        </Typography>
        <Radio
          checked={checked}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          color="secondary"
          classes={{
            root: classes.radio,
            checked: classes.radioChecked,
          }}
        />
      </div>
      {description && (
        <Typography variant="body2" className={classes.description}>
          {description}
        </Typography>
      )}
      {warningText && (
        <Typography className={classes.warningText}>{warningText}</Typography>
      )}
    </div>
  );
};

export default CustomRadioButton;
