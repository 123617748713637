import React, {createContext, useContext, useEffect, useReducer, useState} from "react";
import { nanoid } from "nanoid";
import useIncidentCategories from "../hooks/queries/useIncidentCategories";

const IncidentContext = createContext();

const initialSteps = [
    { label: "Initial Details" },
    { label: "People" },
    { label: "Incident Form" },
];

const stepsWithInvestigation = [
    ...initialSteps,
    { label: "Incident Investigation Form" },
];

const stepsWithActions = [...stepsWithInvestigation, { label: "Actions" }];

const descriptions = [
    "Please enter initial details about the incident.",
    "Please enter details of people affected by the incident.",
    "Please select a site then fill out the incident form.",
    "Please fill out the following incident investigation form.",
    "The following actions will be created based on the answers provided.",
];

const peopleActionTypes = {
    ADD_PERSON: "add-person",
    DELETE_PERSON: "delete-person",
    SET_PERSON_NAME: "set-person-name",
    SET_PERSON_ADDRESS: "set-person-address",
    SET_PERSON_ROLE: "set-person-role",
    SET_PERSON_IS_EMPLOYEE: "set-person-is-employee",
    SET_PERSON_INJURY_TYPE: "set-person-injury-type",
    SET_PERSON_INJURED_BODY_PARTS: "set-person-injured-body-parts",
    SET_PERSON_FIRST_AID_PROVIDED: "set-person-first-aid-provided",
    SET_PERSON_GENDER_TYPE: "set-person-gender-type",
    SET_PERSON_DATE_OF_BIRTH: "set-person-date-of-birth",
    ADD_RESPONSE_PEOPLE: "add-response-people",
    RESET_PEOPLE: "reset-people",
};

const newId = () => nanoid(5);

const newPerson = {
    name: "",
    address: "",
    role: "",
    isEmployee: false,
    injuryType: null,
    injuredBodyParts: [],
    firstAidProvided: false,
    toBeDeleted: false,
    genderType: null,
    dateOfBirth: null,
};

export const riddorReportableStatus = {
  DONT_KNOW: {
      value: 0,
      label: "Do not know at this time",
  },
  YES: {
      value: 1,
      label: "Yes",
  },
  NO: {
      value: 2,
      label: "No",
  },
};

function peopleReducer(state, action) {
    switch (action.type) {
        case peopleActionTypes.ADD_RESPONSE_PEOPLE: {
            const details = {};
            const ids = [];
            for (const person of action.payload.people) {
                const id = newId();
                ids.push(id);
                details[id] = {
                    id: person.id,
                    name: person.name,
                    address: person.address,
                    firstAidProvided: person.incidentAccident?.hadFirstAid,
                    injuryType: person.incidentAccident?.incidentInjury,
                    isEmployee: person.isEmployee,
                    role: person.position,
                    genderType: person.genderType,
                    dateOfBirth: person.dateOfBirth,
                    injuredBodyParts:
                        person.incidentAccident?.injuries?.map((x) => {
                            return {
                                value: x.incidentBodyPart,
                                label: x.incidentBodyPartDescription,
                            };
                        }) ?? [],
                    incidentAccidentId: person.incidentAccident?.id,
                };
            }

            return {
                ...state,
                details,
                ids,
            };
        }
        case peopleActionTypes.ADD_PERSON: {
            const personId = newId();
            return {
                ...state,
                details: { ...state.details, [personId]: { ...newPerson } },
                ids: [...state.ids, personId],
            };
        }
        case peopleActionTypes.DELETE_PERSON: {
            const newDetails = { ...state.details };
            const person = newDetails[action.payload.personId];
            const isExisting = !!person.id;

            if (isExisting) {
                newDetails[action.payload.personId] = { ...person, toBeDeleted: true };
            } else {
                delete newDetails[action.payload.personId];
            }

            return {
                ...state,
                details: newDetails,
                ids: isExisting
                    ? state.ids
                    : state.ids.filter((id) => id !== action.payload.personId),
            };
        }
        case peopleActionTypes.SET_PERSON_NAME:
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.personId]: {
                        ...state.details[action.payload.personId],
                        name: action.payload.name,
                    },
                },
            };
        case peopleActionTypes.SET_PERSON_ADDRESS:
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.personId]: {
                        ...state.details[action.payload.personId],
                        address: action.payload.address,
                    },
                },
            };
        case peopleActionTypes.SET_PERSON_ROLE:
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.personId]: {
                        ...state.details[action.payload.personId],
                        role: action.payload.role,
                    },
                },
            };
        case peopleActionTypes.SET_PERSON_IS_EMPLOYEE:
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.personId]: {
                        ...state.details[action.payload.personId],
                        isEmployee: action.payload.isEmployee,
                    },
                },
            };
        case peopleActionTypes.SET_PERSON_INJURY_TYPE:
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.personId]: {
                        ...state.details[action.payload.personId],
                        injuryType: action.payload.injuryType,
                    },
                },
            };
        case peopleActionTypes.SET_PERSON_INJURED_BODY_PARTS:
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.personId]: {
                        ...state.details[action.payload.personId],
                        injuredBodyParts: action.payload.injuredBodyParts,
                    },
                },
            };
        case peopleActionTypes.SET_PERSON_FIRST_AID_PROVIDED:
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.personId]: {
                        ...state.details[action.payload.personId],
                        firstAidProvided: action.payload.firstAidProvided,
                    },
                },
            };
        case peopleActionTypes.SET_PERSON_GENDER_TYPE:
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.personId]: {
                        ...state.details[action.payload.personId],
                        genderType: action.payload.genderType,
                    },
                },
            };
        case peopleActionTypes.SET_PERSON_DATE_OF_BIRTH:
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.personId]: {
                        ...state.details[action.payload.personId],
                        dateOfBirth: action.payload.dateOfBirth,
                    },
                },
            };
        case peopleActionTypes.RESET_PEOPLE:
            return peopleStateInit();
        default:
            return;
    }
}

const initialPeopleState = {
    details: {},
    ids: [],
    idsToDelete: [],
};

const peopleStateInit = () => {
    const id = newId();
    return {
        details: {
            [id]: newPerson,
        },
        ids: [id],
        idsToDelete: [],
    };
};

function IncidentProvider({ children }) {
  const [people, peopleDispatch] = useReducer(
    peopleReducer,
    initialPeopleState,
    peopleStateInit
  );
  const [incidentType, setIncidentType] = useState(0);
  const [incidentDate, setIncidentDate] = useState(null);
    const [incidentDescription, setIncidentDescription] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [templateVersionId, setTemplateVersionId] = useState(0);
  const [steps, setSteps] = useState(initialSteps);
  const [investigationStatus, setInvestigationStatus] = useState(null)
  const [reasonInvestigationNotRequired, setReasonInvestigationNotRequired] = useState('')
  const [riddorReportable, setRiddorReportable] = useState(riddorReportableStatus.DONT_KNOW.value);
  const [reportingDate, setReportingDate] = useState(null);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [incidentCategoryId, setIncidentCategoryId] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  
  const { data: incidentCategories } = useIncidentCategories();

  useEffect(() => {
    if (incidentCategories)
      setIncidentCategoryId(incidentCategories.filter(x => x.isDefault)[0].id);
  }, [incidentCategories]);

    const addResponsePeople = (people) => {
        peopleDispatch({
            type: peopleActionTypes.ADD_RESPONSE_PEOPLE,
            payload: { people },
        });
    };

    const goToPreviousStep = () => {
        setActiveStep((currentStep) => currentStep - 1);
    };

    const goToNextStep = () => {
        setActiveStep((currentStep) => currentStep + 1);
    };

    const addPerson = () => {
        peopleDispatch({ type: peopleActionTypes.ADD_PERSON });
    };

    const deletePerson = (personId) => {
        peopleDispatch({
            type: peopleActionTypes.DELETE_PERSON,
            payload: { personId },
        });
    };

    const setPersonName = ({ personId, name }) => {
        peopleDispatch({
            type: peopleActionTypes.SET_PERSON_NAME,
            payload: { personId, name },
        });
    };

    const setPersonAddress = ({ personId, address }) => {
        peopleDispatch({
            type: peopleActionTypes.SET_PERSON_ADDRESS,
            payload: { personId, address },
        });
    };

    const setPersonRole = ({ personId, role }) => {
        peopleDispatch({
            type: peopleActionTypes.SET_PERSON_ROLE,
            payload: { personId, role },
        });
    };

    const setPersonIsEmployee = ({ personId, isEmployee }) => {
        peopleDispatch({
            type: peopleActionTypes.SET_PERSON_IS_EMPLOYEE,
            payload: { personId, isEmployee },
        });
    };

    const setPersonInjuryType = ({ personId, injuryType }) => {
        peopleDispatch({
            type: peopleActionTypes.SET_PERSON_INJURY_TYPE,
            payload: { personId, injuryType },
        });
    };

    const setPersonInjuredBodyParts = ({ personId, injuredBodyParts }) => {
        peopleDispatch({
            type: peopleActionTypes.SET_PERSON_INJURED_BODY_PARTS,
            payload: { personId, injuredBodyParts },
        });
    };

    const setPersonFirstAidProvided = ({ personId, firstAidProvided }) => {
        peopleDispatch({
            type: peopleActionTypes.SET_PERSON_FIRST_AID_PROVIDED,
            payload: { personId, firstAidProvided },
        });
    };

    const setPersonGenderType = ({ personId, genderType }) => {
        peopleDispatch({
            type: peopleActionTypes.SET_PERSON_GENDER_TYPE,
            payload: { personId, genderType },
        });
    };

    const setPersonDateOfBirth = ({ personId, dateOfBirth }) => {
        peopleDispatch({
            type: peopleActionTypes.SET_PERSON_DATE_OF_BIRTH,
            payload: { personId, dateOfBirth },
        });
    };

    const resetPeople = () => {
        peopleDispatch({
            type: peopleActionTypes.RESET_PEOPLE,
        });
    };

    const currentCardTitle = steps[activeStep]?.label;
    const currentCardDescription = descriptions[activeStep];

    const isFirstStep = activeStep === 0;
    const isLastStep = activeStep === steps.length - 1;
    const isIncidentFormStep = activeStep === 2;
    const isInvestigationStep = activeStep === 3;
    const isActionsStep = activeStep === 4;

  const resetIncident = () => {
    resetPeople();
    setReasonInvestigationNotRequired('')
    setInvestigationStatus(null)
    setIncidentDate(null);
    setIncidentType(0);
    setIncidentDescription(null);
    setActiveStep(0);
    setRiddorReportable(riddorReportableStatus.DONT_KNOW.value);
    setReportingDate(null);
    setReferenceNumber("");
    setIncidentCategoryId(incidentCategories?.filter(x => x.isDefault)[0]?.id ?? null);
  };

  const value = {
    activeStep,
    isFirstStep,
    isLastStep,
    isIncidentFormStep,
    isActionsStep,
    goToPreviousStep,
    goToNextStep,
    steps,
    currentCardTitle,
    currentCardDescription,
    incidentType,
    setIncidentType,
    incidentDate,
    setIncidentDate,
    incidentDescription,
    setIncidentDescription,
    people,
    addPerson,
    deletePerson,
    setPersonName,
    setPersonAddress,
    setPersonRole,
    setPersonIsEmployee,
    setPersonInjuryType,
    setPersonInjuredBodyParts,
    setPersonFirstAidProvided,
    setPersonGenderType,
    setPersonDateOfBirth,
    templateVersionId,
    setTemplateVersionId,
    setActiveStep,
    addResponsePeople,
    setSteps,
    stepsWithInvestigation,
    stepsWithActions,
    initialSteps,
    isInvestigationStep,
    resetIncident,
    investigationStatus,
    setInvestigationStatus,
    reasonInvestigationNotRequired, 
    setReasonInvestigationNotRequired,
    riddorReportable,
    setRiddorReportable,
    reportingDate,
    setReportingDate,
    referenceNumber,
    setReferenceNumber,
    incidentCategoryId,
    setIncidentCategoryId,
    templateId,
      setTemplateId,
  };

    return (
        <IncidentContext.Provider value={value}>
            {children}
        </IncidentContext.Provider>
    );
}

const useIncidentDetails = () => useContext(IncidentContext);

export { IncidentProvider, useIncidentDetails };
