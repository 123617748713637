import React from "react";
import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import layoutConstants from "../../constants/layoutConstants";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    height: `${layoutConstants.featureTileWidth}px`,
    backgroundColor: "#fff",
    transition: "0.2s all ease-out",
    "&:hover": {
      backgroundColor: grey[200],
      transform: "scale(1.03)",
    },
    "& .MuiButton-label": {
      flexDirection: "column",
    },
    cursor: "pointer",
  },
  title: {
    textAlign: "center",
    fontWeight: 500,
    textTransform: "none",
  },
  image: {
    maxHeight: "60%",
    maxWidth: "60%",
  },
  icon: {
    fontSize: 64
  }
}));

const DocumentTile = ({
  title,
  icon: Icon = null,
  handleClick = "",
  image = "",
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.card} onClick={handleClick}>
      {(Icon || image) && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          marginBottom={2}
          height="100%"
          flexDirection="column"
        >
          {Icon && <Icon color="primary" className={classes.icon} />}
          {image && <img className={classes.image} src={image} />}
          <Typography className={classes.title}>{title}</Typography>
        </Box>
      )}
    </Paper>
  );
};

export default DocumentTile;
