import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    TextField,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    FormHelperText,
    makeStyles,
    alpha,
    Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { answerTypes } from "../../constants/answerConstants";
import {
    setDateResponse,
    setNumberResponse,
    setOptionResponse,
    setQuestionFiles,
    setTextResponse,
    setMultiselectResponse,
    clearMultiselectResponse,
    setQuestionAdditionalInfo,
} from "../../../redux/actions/formCompleterActions";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormQuestionText from "../../../components/FormQuestionText";
import FileUploads from "../FileUploads";
import useIsMobile from "../../../hooks/useIsMobile";

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
        justifyContent: "center",
    },
    shortDisplay: {
        [theme.breakpoints.up("sm")]: {
            width: "50%",
        },
    },
    dateDisplay: {
        [theme.breakpoints.up("sm")]: {
            width: "35%",
        },
    },
    input: {
        display: "none",
    },
    uploadsTitle: {
        fontWeight: 500,
    },
    uploadsContainer: {
        padding: theme.spacing(2),
        borderRadius: "8px",
        marginTop: theme.spacing(2),
        backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    },
    uploadsWrapper: {
        marginTop: theme.spacing(1),
    },
    textField: {
        margin: "8px 0",
    },
}));

const TEXT_MAX_LENGTH = 4095;

const FormQuestion = ({ question }) => {
    const classes = useStyles();
    const { questionResponses } = useSelector((state) => state.formCompleter);
    const dispatch = useDispatch();
    const isMobile = useIsMobile();


    const onFilesChanged = (files) => {
        dispatch(setQuestionFiles({ templateQuestionId: question.id, files }));
    };

    const onAdditionalInfoChanged = (e) => {
        dispatch(
            setQuestionAdditionalInfo({
                templateQuestionId: question.id,
                additionalInfo: e.target.value,
            })
        );
    };

    const handleChangeValue = (value, option) => {
        let response = { templateQuestionId: question.id, value };

        switch (question.answerType) {
            case answerTypes.DATE:
                dispatch(setDateResponse(response));
                return;

            case answerTypes.TEXT:
                dispatch(setTextResponse(response));
                return;

            case answerTypes.NUMBER:
                dispatch(setNumberResponse(response));
                return;

            case answerTypes.RADIO:
            case answerTypes.DROPDOWN:
                dispatch(setOptionResponse(response));
                return;

            case answerTypes.MULTISELECT:
                let selectedOptionIds = option.map((element) => element.id);

                let multiselectResponse = {
                    templateQuestionId: question.id,
                    values: selectedOptionIds,
                };

                if (selectedOptionIds.length === 0) {
                    dispatch(clearMultiselectResponse(multiselectResponse.templateQuestionId));
                } else {
                    dispatch(setMultiselectResponse(multiselectResponse));
                }
                return;

            default:
                return;
        }
    };

    return (
        <>
            <FormQuestionText
                text={question.text}
                required={!!question.isMandatory}
            />
            <FormControl
                required={!!question.isMandatory}
                className={classes.formControl}
            >
                {question.answerType === answerTypes.TEXT && (
                    <TextField
                        inputProps={{ maxLength: TEXT_MAX_LENGTH }}
                        required={!!question.isMandatory}
                        placeholder="Please type answer here"
                        value={questionResponses[question.id]?.textValue || ""}
                        onChange={(e) => handleChangeValue(e.target.value)}
                        variant="outlined"
                        multiline
                    />
                )}

                {question.answerType === answerTypes.RADIO && (
                    <RadioGroup
                        className={classes.shortDisplay}
                        value={questionResponses[question.id]?.optionId || null}
                        onChange={(e) => handleChangeValue(parseInt(e.target.value))}
                    >
                        {question.options
                            .sort((a, b) => a.displayOrder - b.displayOrder)
                            .map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    value={option.id}
                                    label={option.option}
                                    control={<Radio required />}
                                />
                            ))}
                    </RadioGroup>
                )}
                {question.answerType === answerTypes.DROPDOWN && (
                    <Select
                        className={classes.shortDisplay}
                        value={questionResponses[question.id]?.optionId || ""}
                        onChange={(e) => handleChangeValue(e.target.value)}
                        native={isMobile}
                        displayEmpty
                        variant="outlined"
                    >
                        {isMobile
                            ? [
                                <option disabled value="" key="empty">
                                    Please select an option
                                </option>,
                                ...question.options
                                    .sort((a, b) => a.displayOrder - b.displayOrder)
                                    .map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.option}
                                        </option>
                                    )),
                            ]
                            : [
                                <MenuItem disabled value="" key="empty">
                                    Please select an option
                                </MenuItem>,
                                ...question.options
                                    .sort((a, b) => a.displayOrder - b.displayOrder)
                                    .map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.option}
                                        </MenuItem>
                                    )),
                            ]}
                    </Select>
                )}

                {question.answerType === answerTypes.DATE && (
                    <KeyboardDatePicker
                        className={classes.dateDisplay}
                        required={!!question.isMandatory}
                        value={questionResponses[question.id]?.dateValue || null}
                        onChange={(date) => handleChangeValue(date?.toString())}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        placeholder="Please enter date here"
                    />
                )}

                {question.answerType === answerTypes.NUMBER && (
                    <TextField
                        className={classes.shortDisplay}
                        value={questionResponses[question.id]?.numberValue || ""}
                        onChange={(e) => handleChangeValue(e.target.value)}
                        variant="outlined"
                        required={!!question.isMandatory}
                        type="number"
                        inputProps={{
                            min: question.rangeRestriction?.minValue,
                            max: question.rangeRestriction?.maxValue,
                            step: "any",
                        }}
                        placeholder="Please enter number here"
                    />
                )}
                {question.answerType === answerTypes.MULTISELECT && (
                    <Autocomplete
                        className={classes.shortDisplay}
                        multiple
                        onChange={(e, option) => handleChangeValue(e.target.value, option)}
                        options={question.options}
                        value={question.options.filter(option => questionResponses[question.id]?.optionIds?.includes(option.id))}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.option}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={uncheckedIcon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.option}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className={classes.textField}
                                variant="outlined"
                                size="small"
                                label="Please select an option"
                                fullWidth
                            />
                        )}
                    />
                )}
                {!!question.helpInfoText && (
                    <FormHelperText>{question.helpInfoText}</FormHelperText>
                )}
            </FormControl>
            {question.options?.some((option) => option.id === questionResponses[question.id]?.optionId && option.requiresAdditionalInfo) && (
                <TextField
                    inputProps={{ maxLength: TEXT_MAX_LENGTH }}
                    fullWidth
                    id="outlined-multiline-static"
                    label="Additional Info"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={questionResponses[question.id]?.additionalInfo}
                    onChange={onAdditionalInfoChanged}
                />
            )}            
            <div className={classes.uploadsWrapper}>               
                <FileUploads
                    required={question.attachmentMandatory}
                    files={questionResponses[question.id]?.azureFiles}
                    showAddButton={question.allowPhotoUpload}
                    onFilesChanged={onFilesChanged}
                />
            </div>
        </>
    );
};

export default FormQuestion;
