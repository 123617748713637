import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { useActionDetails } from "../../contexts/ActionContext";

const useStyles = makeStyles((theme) => ({
    tabs: {
        display: 'flex',
        alignItems: 'end',
    },
    tab: {
        '&.MuiTab-root': {
            maxWidth: 'max-content',
            padding: theme.spacing(0.75, 1),
            maxHeight: 'max-content',
        },
        '&.Mui-selected': {
            color: '#5c45c7',
        },
    },
    tabContent: {
        display: 'flex',
        columnGap: theme.spacing(1),
        alignItems: 'center',
    },
    badge: {
        backgroundColor: '#f1ecff',
        color: '#5c45c7',
        padding: theme.spacing(0.25, 0.75),
        borderRadius: theme.spacing(0.5),
        fontSize: '0.75rem',
    },
}));

export default function ActionTabs() {
    const classes = useStyles();

    const { selectedTab, setSelectedTab, actionTabDetails } = useActionDetails()

    const TabContent = ({tab, count}) => (
        <div className={classes.tabContent}>
            <div>{tab}</div>
            {count > 0 &&
                <div className={classes.badge}>{count}</div>
            }
        </div>
    )

    const tabs = () => {
        return Object.keys(actionTabDetails).map((tab, index) => (
            <Tab
                key={index}
                className={classes.tab}
                label={<TabContent tab={actionTabDetails[tab].label} count={actionTabDetails[tab].count} />}
            />
        ))
    }

    return (
        <Tabs 
            className={classes.tabs} 
            value={selectedTab}
            onChange={(e, value) => setSelectedTab(value)}
        >
            {tabs()}
        </Tabs>
    )
}