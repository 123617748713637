import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    CircularProgress,
    Container,
    makeStyles,
    Paper,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import { useTracking } from "react-tracking";
import CategorySection from "../../components/knowledgeHub/CategorySection";
import trackingConstants from "../../constants/trackingConstants";
import usePrismicCategory from "../../hooks/queries/usePrismicCategory";
import usePrismicCategoryArticles from "../../hooks/queries/usePrismicCategoryArticles";
import usePrismicCategoryDownloads from "../../hooks/queries/usePrismicCategoryDownloads";
import usePrismicCategoryPrecedents from "../../hooks/queries/usePrismicCategoryPrecedents";
import usePrismicCategoryWebinars from "../../hooks/queries/usePrismicCategoryWebinars";
import KnowledgeHubBreadcrumbs from "../../components/knowledgeHub/KnowledgeHubBreadcrumbs";
import usePrismicCategoriesByParentId from "../../hooks/queries/usePrismicCategoriesByParentId";
import CategoriesGrid from "../../components/knowledgeHub/CategoriesGrid";
import ErrorBox from "../../components/ErrorBox";
import PageBase from "../../components/PageBase";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(2),
    },
    loadingContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tabsBar: {
        marginBottom: theme.spacing(2),
    },
    categoriesWrapper: {
        marginBottom: theme.spacing(2),
    },
    tabsFlexContainer: {
        flexWrap: "wrap",
    },
}));

const tabsLabelsMap = {
    articles: "Articles",
    webinars: "Webinars",
    precedents: "Precedents",
    downloads: "Downloads",
};

const HubCategory = () => {
    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(null);
    const [activeTabs, setActiveTabs] = useState([]);
    const match = useRouteMatch();
    const { params } = match;
    const { sectionName, categoryId, searchTerm } = params;

    const { Track, trackEvent } = useTracking();

    const [isLoading, setIsLoading] = useState(true);
    const [articlesPage, setArticlesPage] = useState(1);
    const [webinarsPage, setWebinarsPage] = useState(1);
    const [precedentsPage, setPrecedentsPage] = useState(1);
    const [downloadsPage, setDownloadsPage] = useState(1);

    const categoryQuery = usePrismicCategory(sectionName, categoryId);
    const categoriesQuery = usePrismicCategoriesByParentId(sectionName, categoryId);
    const articlesQuery = usePrismicCategoryArticles(sectionName, categoryId, articlesPage, searchTerm);
    const webinarsQuery = usePrismicCategoryWebinars(sectionName, categoryId, webinarsPage, searchTerm);
    const precedentsQuery = usePrismicCategoryPrecedents(sectionName, categoryId, precedentsPage, searchTerm);
    const downloadsQuery = usePrismicCategoryDownloads(sectionName, categoryId, downloadsPage, searchTerm);

    const initialiseTabs = useCallback(() => {
        if (tabIndex) return;

        const tabs = [];
        if (articlesQuery.data?.results.length > 0)
            tabs.push(tabsLabelsMap.articles);
        if (webinarsQuery.data?.results.length > 0)
            tabs.push(tabsLabelsMap.webinars);
        if (precedentsQuery.data?.results.length > 0)
            tabs.push(tabsLabelsMap.precedents);
        if (downloadsQuery.data?.results.length > 0)
            tabs.push(tabsLabelsMap.downloads);

        setTabIndex(tabs[0]);
        setActiveTabs(tabs);
        setIsLoading(false);
    }, [
        articlesQuery.data,
        downloadsQuery.data,
        precedentsQuery.data,
        webinarsQuery.data,
        tabIndex,
    ]);

    useEffect(() => {
        if (
            articlesQuery.isLoading ||
            webinarsQuery.isLoading ||
            precedentsQuery.isLoading ||
            downloadsQuery.isLoading ||
            categoryQuery.isLoading ||
            categoriesQuery.isLoading
        )
            return;

        setIsLoading(false);
    }, [
        articlesQuery.isLoading,
        categoriesQuery.isLoading,
        categoryQuery.isLoading,
        downloadsQuery.isLoading,
        precedentsQuery.isLoading,
        webinarsQuery.isLoading,
    ]);

    useEffect(() => {
        if (!isLoading) initialiseTabs();
    }, [isLoading, initialiseTabs]);

    useEffect(() => {
        if (categoryQuery.data)
            trackEvent({
                page: `KnowledgeHub:${sectionName}:${categoryQuery.data.title}`,
                event: trackingConstants.events.pageView,
            });
    }, [categoryQuery.data, sectionName, trackEvent]);

    const handleTabChange = (_e, indexValue) => {
        setTabIndex(indexValue);
    };

    const content = () => {
        if (isLoading)
            return (
                <Box className={classes.loadingContainer}>
                    <CircularProgress color="primary" />
                </Box>
            );

        if (categoryQuery.isError)
            return (
                <ErrorBox
                    message={
                        categoryQuery.error?.message || "Could not load category data."
                    }
                />
            );

        const hasNestedCategories = categoriesQuery.data?.length > 0;
        const hasTabbedContent = activeTabs.length > 0;

        return (
            <>
                {hasNestedCategories && (
                    <Box className={classes.categoriesWrapper}>
                        <CategoriesGrid isNested categories={categoriesQuery.data} />
                    </Box>
                )}
                {hasTabbedContent && (
                    <>
                        <Paper className={classes.tabsBar}>
                            <Tabs
                                classes={{
                                    flexContainer: classes.tabsFlexContainer,
                                }}
                                value={tabIndex}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                {activeTabs.map((x, index) => (
                                    <Tab key={index} label={x} value={x} />
                                ))}
                            </Tabs>
                        </Paper>
                        {tabIndex === tabsLabelsMap.articles && (
                            <CategorySection
                                query={articlesQuery}
                                page={articlesPage}
                                onPageChange={(newPage) => setArticlesPage(newPage)}
                            />
                        )}
                        {tabIndex === tabsLabelsMap.webinars && (
                            <CategorySection
                                query={webinarsQuery}
                                page={webinarsPage}
                                onPageChange={(newPage) => setWebinarsPage(newPage)}
                            />
                        )}
                        {tabIndex === tabsLabelsMap.precedents && (
                            <CategorySection
                                query={precedentsQuery}
                                page={precedentsPage}
                                onPageChange={(newPage) => setPrecedentsPage(newPage)}
                            />
                        )}
                        {tabIndex === tabsLabelsMap.downloads && (
                            <CategorySection
                                query={downloadsQuery}
                                page={downloadsPage}
                                onPageChange={(newPage) => setDownloadsPage(newPage)}
                            />
                        )}
                    </>
                )}
            </>
        );
    };

    return (
        <PageBase>
            <Track>
                <Container maxWidth="lg">
                    <KnowledgeHubBreadcrumbs />
                    {
                        (searchTerm || categoryQuery.data?.title) &&
                        <Typography className={classes.title} variant="h4">
                            {searchTerm ? `[${searchTerm}]` : categoryQuery.data.title}
                        </Typography>
                    }
                    {content()}
                </Container>
            </Track>
        </PageBase>
    );
};

export default HubCategory;