import axios from "../../utils/axios";
import { buildQueryStringList, addQueryStringList } from "../../utils/stringUtils";
import { isAzureFile } from "../contexts/HistoricAuditContext";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const fetchMostImpactfulActionsForSite = ({ siteExternalIds }) => {
  const queryString = buildQueryStringList("siteExternalIds", siteExternalIds);
  return axios.get(`${apiUrl}/Action/MostImpactful${queryString}`);
};

const fetchActionsForSite = ({
  externalIds,
  forUser,
  moduleArea = null,
  fromDate,
  toDate,
  pageSize,
  pageNum,
  showCompleted,
  auditType,
  rating,
  sortOrder,
  orderByColumn,
  assignees,
  filteredText,
}) => {
    let queryString = buildQueryStringList("externalIds", externalIds);
    let queryStringAssignees = addQueryStringList("assignees", assignees);
    
    if (queryStringAssignees !== null) queryString += queryStringAssignees;
    if (moduleArea !== null) queryString += `&applicationArea=${moduleArea}`;
    if (auditType !== null) queryString += `&auditType=${auditType}`;
    if (rating !== null) queryString += `&rating=${rating}`;
    if (fromDate !== null) queryString += `&fromDate=${fromDate}`;
    if (toDate !== null) queryString += `&toDate=${toDate}`;
    if (sortOrder !== null) queryString += `&sortOrder=${sortOrder}`;
    if (orderByColumn !== null) queryString += `&orderByColumn=${orderByColumn}`;
    if (filteredText) queryString += `&filteredText=${filteredText}`;

  queryString += `&forUser=${forUser}`;

  return axios.get(
    `${apiUrl}/action/sites/${queryString}&pageNum=${pageNum}&pageSize=${pageSize}&showCompleted=${showCompleted}`
  );
};

const fetchActionsForSiteSummary = ({
  externalIds,
  fromDate,
  toDate,
  forUser,
  moduleArea = null,
}) => {
  let queryString = buildQueryStringList("externalIds", externalIds);
  if (moduleArea !== null) queryString += `&applicationArea=${moduleArea}`;
  queryString += `&forUser=${forUser}`;

  return axios.get(
    `${apiUrl}/action/summary/${fromDate}/${toDate}${queryString}`
  );
};

const calculateActions = (completedForm) =>
    axios.post(`${apiUrl}/Action/Calculate`, completedForm);

const getById = ({ actionId, isDri }) =>
  axios.get(`${apiUrl}/Action/${actionId}/${!!isDri}`);

const update = (action) => axios.post(`${apiUrl}/Action/Update`, action);

const setViewedByUser = (id) =>
  axios.patch(`${apiUrl}/Action/ViewedByUser/${id}`);

const addActionComment = (commentToAdd) =>
  axios.post(`${apiUrl}/Action/Comment/Add`, commentToAdd);

const downloadActionsForSite = async ({
   externalIds,
   forUser,
   moduleArea = null,
   fromDate,
   toDate,
   showCompleted,
   auditType,
   rating,
 }) => {
  let queryString = buildQueryStringList("externalIds", externalIds);
    if (moduleArea !== null) queryString += `&applicationArea=${moduleArea}`;
    if (auditType !== null) queryString += `&auditType=${auditType}`;
    if (rating !== null) queryString += `&rating=${rating}`;
    if (fromDate !== null) queryString += `&fromDate=${fromDate}`;
    if (toDate !== null) queryString += `&toDate=${toDate}`;
    
  queryString += `&forUser=${forUser}`;

  return axios.get(
    `${apiUrl}/Action/Sites/Download/${queryString}&showCompleted=${showCompleted}`
  );
};

const uploadAttachments = ({ actionId, files, isDri = false, onUploadProgress }) => {
  const formData = new FormData();
  for (const [index, dto] of files.entries()) {
    formData.append(`[${index}].syncId`, dto.syncId);
    formData.append(`[${index}].file`, dto.file);
  }
  return axios.post(
    `${apiUrl}/Action/${actionId}/${isDri}/Attachments`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    }
  );
};

const downloadAttachment = (reference, isDri = false) =>
  axios.get(`${apiUrl}/Action/Attachment/${reference}/${isDri}`, {
    responseType: "arraybuffer",
  });

const create = (action) => {
    const formData = new FormData();

    if (action.azureFile !== null && !isAzureFile(action.azureFile)) {
        formData.append("azureFile", action.azureFile);
    }
    formData.append("actionDTO", JSON.stringify(action));

    return axios.post(`${apiUrl}/action/create`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

const actionService = {
  fetchActionsForSite,
  addActionComment,
  fetchActionsForSiteSummary,
  calculateActions,
  getById,
  update,
  setViewedByUser,
  downloadActionsForSite,
  fetchMostImpactfulActionsForSite,
  uploadAttachments,
  downloadAttachment,
  create,
};

export default actionService;
