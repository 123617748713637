import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import accountService from "../../services/accountService";

const useAccountAdminSites = ({ childExternalIds }) =>
  useQuery(
    [
      queryKeys.accountSites,
      childExternalIds?.join(","),
      "admin",
    ],
    () =>
      accountService.fetchAccountsForAdmin({
        accountExternalIds: childExternalIds,
      }),
    {
      enabled: !!childExternalIds?.length,
      staleTime: Infinity,
    }
  );

export default useAccountAdminSites;
