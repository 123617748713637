import React, { useState } from "react";
import Avatar from "../Avatar";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";

import {
  Email as EmailIcon,
  PhoneAndroid as MobileIcon,
  Phone as PhoneIcon,
} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  textCenter: {
    textAlign: "center",
  },
  dialogPopup: {
    maxWidth: "lg",
  },
  htmlText: {
    marginLeft: "6px",
  },
  advDetails: {
    borderTop: "1px solid lightgray",
  },
}));

const LawyerCard = ({ accountAdvisers }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const ViewAdviserDetails = (adviser) => (
    <Grid
      className={classes.advDetails}
      key={adviser.name}
      container
      spacing={12}
    >
      <Grid item xs={12} md={3}>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={5}
            mb={2}
            ml={2}
            mr={2}
            className={classes.textCenter}
          >
            <Box>
              <Avatar alt={adviser.name} size="md" src={adviser.photo} />
            </Box>
            <Typography className={classes.textCenter} variant="h5" mb={6}>
              {adviser.name}
            </Typography>
            <Link href={"mailto:" + adviser.email} underline="hover">
              <EmailIcon fontSize="small" />
              {adviser.email}
            </Link>
            {adviser.phone && (
              <Link href={"tel:" + adviser.phone} underline="hover">
                <PhoneIcon fontSize="small" />
                {adviser.phone}
              </Link>
            )}
            {adviser.mobile && (
              <Link href={"tel:" + adviser.mobile} underline="hover">
                <MobileIcon fontSize="small" />
                {adviser.mobile}
              </Link>
            )}
          </Box>
        </DialogContent>
      </Grid>
      <Grid item xs={12} md={9}>
        <DialogContent>
          <Typography
            dangerouslySetInnerHTML={{
              __html: `<div>${adviser.aboutMe}</div>`,
            }}
          />
        </DialogContent>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      <CardContent>
        <Box display="flex">
          <Box flexGrow={1} alignItems="center">
            <Typography className={classes.textCenter} variant="h5" mb={6}>
              Your Employment Law Team
            </Typography>
            <Box
              display="flex"
              justifyContent="space-around"
              flexWrap="wrap"
              m={-2}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={5}
                mb={2}
                ml={2}
                mr={2}
                className={classes.textCenter}
              >
                <AvatarGroup max={3}>
                  {accountAdvisers
                    .sort((a, b) =>
                      a.photo > b.photo ? 1 : b.photo > a.photo ? -1 : 0
                    )
                    .map((adviser) => (
                      <Avatar
                        key={adviser.name}
                        size="md"
                        alt={adviser.name}
                        src={adviser.photo}
                      />
                    ))}
                </AvatarGroup>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-around"
              flexWrap="wrap"
              mt={2}
            >
              <Button
                variant="outlined"
                className={classes.textCenter}
                onClick={() => setOpen(true)}
                color="primary"
              >
                More Information
              </Button>
            </Box>
          </Box>
        </Box>
      </CardContent>

      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <Grid>
          <DialogTitle id="alert-dialog-title">
            {accountAdvisers.length === 1
              ? "Your " + accountAdvisers[0].role
              : "Your " + accountAdvisers[0].role + " Team"}
          </DialogTitle>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={5}
            mb={2}
            ml={5}
            mr={5}
          >
            <Box>
              <Typography gutterBottom>
                Your dedicated Employment Law team will deal with all your
                employment law issues. They will draw on their combined
                experience and knowledge to provide you with solutions-focused
                advice to help you comply with law and best practice whilst
                still achieving commercial objectives.
              </Typography>
              <Typography gutterBottom>
                They are available to assist with drafting letters and
                documents, producing Contracts of Employment and creating
                Employee Handbooks that detail best-practice HR policies and
                procedures. Please don&lsquo;t hesitate to contact the team for
                all of your employment law issues.
              </Typography>
            </Box>
          </Box>
          {accountAdvisers.map((a) => ViewAdviserDetails(a))}
          <Box
            display="flex"
            justifyContent="space-around"
            flexWrap="wrap"
            m={2}
          >
            <Button
              variant="outlined"
              className={classes.textCenter}
              onClick={handleCloseDialog}
              color="primary"
            >
              Close
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};

export default LawyerCard;
