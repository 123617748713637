import { isBefore } from "date-fns";

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const descendingComparator = (a, b, orderBy) => {
  const isDateValue = orderBy.toLowerCase().includes("date");
  const isNestedValue = orderBy.includes(".");

  let compareValueA = a[orderBy];
  let compareValueB = b[orderBy];

  if (isNestedValue) {
    const splitKeys = orderBy.split(".");
    compareValueA = a[splitKeys[0]]?.[splitKeys[1]];
    compareValueB = b[splitKeys[0]]?.[splitKeys[1]];
  }

  if (isDateValue) return compareDates(compareValueA, compareValueB);

  if (compareValueB < compareValueA) return -1;
  if (compareValueB > compareValueA) return 1;
  return 0;
};

const compareDates = (dateStringA, dateStringB) => {
  const dateA = new Date(dateStringA);
  const dateB = new Date(dateStringB);

  if (isBefore(dateA, dateB)) return -1;
  if (isBefore(dateB, dateA)) return 1;
  return 0;
};
