import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import incidentService from "../../services/incidentService";

const useDownloadIncidentAttachment = ({ reference }, options = {}) =>
  useQuery(
    [queryKeys.downloadIncidentAttachment, reference],
    () => incidentService.downloadAttachment(reference),
    { enabled: !!reference, ...options }
  );

export default useDownloadIncidentAttachment;
