import { Button, IconButton, makeStyles, Typography } from "@material-ui/core"
import { DeleteOutline, ErrorOutline } from "@material-ui/icons"
import { nanoid } from "nanoid"
import { useSnackbar } from "notistack"
import { useHistoricAuditContext } from "../../contexts/HistoricAuditContext"

const useStyles = makeStyles((theme) => ({
    columnSpan: {
        gridColumn: '1 / 3',
        [theme.breakpoints.down('sm')]: {
            gridColumn: '1 / 1'
        }
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1)
    },
    input: {
        display: 'none'
    },
    pdfError: {
        color: theme.palette.error.main,
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1)
    },
}))

export default function AuditReport() {
    const classes = useStyles()

    const { enqueueSnackbar } = useSnackbar()

    const {
        historicAuditDetails,
        deleteDocument,
        addDocument,
        isPDFError,
        setIsPDFError
    } = useHistoricAuditContext()

    const handleChangeFile = async (e) => {
        const files = e.target.files

        if (files && files[0].type !== 'application/pdf') {
            enqueueSnackbar('Please select a PDF file only', { variant: 'error' })
            return
        }
    
        if (files && files.length) {
          const newFile = {
            syncId: nanoid(5),
            file: files[0],
          };
    
          addDocument(newFile)
          setIsPDFError(false)
        }
    };

    return (
        <>
            <div className={classes.columnSpan}>
                <Typography variant="h6">
                    Audit Report
                </Typography>
                {historicAuditDetails.document ? (
                    <div className={classes.flex}>
                        <Button 
                            variant="text" 
                            color="secondary"
                        >
                            {historicAuditDetails.document.name}
                        </Button>
                        <IconButton
                            onClick={() => deleteDocument(historicAuditDetails.document)}
                        >
                            <DeleteOutline />
                        </IconButton>
                    </div>
                ) : (
                    <>
                        <Button
                            variant="text"
                            color="secondary"
                            component="label"
                        >
                            Select PDF file to upload
                            <input
                                type="file"
                                className={classes.input}
                                accept="application/pdf"
                                onChange={handleChangeFile}
                            />
                        </Button>
                        {isPDFError &&
                            <Typography variant="body2" className={classes.pdfError}>
                                <ErrorOutline />
                                Please select a PDF file to upload
                            </Typography>
                        }
                    </>
                )}
            </div>
        </>
    )
}