import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import prismicService from "../../services/prismicService";

const usePrismicCategoryPrecedents = (sectionName, categoryId, pageNumber, searchTerm) =>
    useQuery(
        [queryKeys.prismicCategoryPrecedents, categoryId, pageNumber, searchTerm],
        () => prismicService.fetchCategoryPrecedents(sectionName, categoryId, pageNumber, searchTerm)
    );

export default usePrismicCategoryPrecedents;