import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import riskAssessmentService from "../../services/riskAssessmentService";

const useRiskAssessment = (riskAssessmentId) =>
  useQuery(
    [
      queryKeys.riskAssessment,
      riskAssessmentId,
    ],
    () => riskAssessmentService.fetchRiskAssessment(riskAssessmentId),
    {
      enabled: !!riskAssessmentId,
    }
  );

export default useRiskAssessment;