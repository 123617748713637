import React, { useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import useDaysSinceIncidentForSites from "../hooks/queries/useDaysSinceIncidentForSites";
import { Edit } from "@material-ui/icons";
import { formatShortDate } from "../../utils/dateTimeUtils";
import { formatNumber } from "../../utils/numberUtils";
import { KeyboardDatePicker } from "@material-ui/pickers";
import useAddHistoricSiteIncident from "../hooks/mutations/useAddHistoricSiteIncident";
import { isAfter } from "date-fns";
import { getComparator, stableSort } from "../../utils/tableUtils";
import { tableSortingOrder } from "../../constants/tableConstants";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    display: "flex",
    height: "100%",
  },
  textContainer: {
    padding: theme.spacing(2),
    flexGrow: 1,
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  daysSinceText: {
    fontWeight: 500,
    fontSize: "4rem",
    overflowWrap: "anywhere",
    lineHeight: 1.2,
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  daysSinceLabel: {
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  editButton: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    alignSelf: "flex-start",
  },
  loadingSpinnerContainer: {
    minHeight: 132,
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  loadingSpinner: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  editDateButton: {
    padding: 0,
    marginLeft: "auto",
  },
  editTableCell: {
    display: "flex",
  },
  datePicker: {
    margin: 0,
  },
  saveDateButton: {
    marginLeft: theme.spacing(1),
    alignSelf: "center",
  },
}));

const maximumDate = new Date().setHours(23, 59, 59);

function DaysSinceIncidentCard() {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingSiteId, setEditingSiteId] = useState(null);
  const [editingSiteIncidentDate, setEditingSiteIncidentDate] = useState(null);
  const { selectedSite, activeAppId, appSiteIds, sites } = useSelector(
    (state) => state.account
  );
  const addHistoricIncident = useAddHistoricSiteIncident();
  const latestIncidents = useDaysSinceIncidentForSites({
    externalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
  });

  const { enqueueSnackbar } = useSnackbar();

  const onDialogClose = () => {
    setIsDialogOpen(false);
    handleCloseEditingSiteDate();
  };

  const hasEditableIncidents = useMemo(() => {
    if (!latestIncidents.data) return false;
    return latestIncidents.data.some((x) => !x.incidentDate);
  }, [latestIncidents.data]);

  const daysSinceLastIncident = useMemo(() => {
    if (!latestIncidents.data) return null;
    const sorted = stableSort(
      latestIncidents.data,
      getComparator(tableSortingOrder.asc, "incidentDate")
    );

    const latestIncident = sorted[0];

    return Number.isInteger(latestIncident?.daysSinceIncident)
      ? formatNumber(latestIncident?.daysSinceIncident)
      : null;
  }, [latestIncidents.data]);

  const handleCloseEditingSiteDate = () => {
    setEditingSiteId(null);
    setEditingSiteIncidentDate(null);
  };

  const handleSaveEditingSiteDate = () => {
    addHistoricIncident.mutate(
      {
        siteExternalId: editingSiteId,
        incidentDate: editingSiteIncidentDate,
      },
      {
        onError: (error) => {
          console.error(error);
          enqueueSnackbar(
            error?.message || "There was an error adding the incident",
            {
              variant: "error",
            }
          );
        },
      }
    );
    handleCloseEditingSiteDate();
  };

  return (
    <>
      <Paper className={classes.card}>
        {latestIncidents.isLoading ? (
          <div className={classes.loadingSpinnerContainer}>
            <CircularProgress size={40} className={classes.loadingSpinner} />
          </div>
        ) : (
          <>
            <div className={classes.textContainer}>
              <Typography className={classes.daysSinceText}>
                {daysSinceLastIncident === null ? "-" : daysSinceLastIncident}
              </Typography>
              <Typography className={classes.daysSinceLabel}>days since last incident</Typography>
            </div>
            {hasEditableIncidents && (
              <IconButton
                onClick={() => setIsDialogOpen(true)}
                className={classes.editButton}
              >
                <Edit />
              </IconButton>
            )}
          </>
        )}
      </Paper>
      <Dialog
        open={isDialogOpen}
        onClose={onDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Last Incident Dates</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Manually set historic incidents dates where applicable below.
          </DialogContentText>
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell>Site</TableCell>
                <TableCell>Date of Last Incident</TableCell>
              </TableHead>
              <TableBody>
                {latestIncidents.data?.map((incident, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {sites[incident.siteExternalId]?.name ?? "Unknown"}
                    </TableCell>
                    <TableCell className={classes.editTableCell}>
                      {editingSiteId === incident.siteExternalId ? (
                        <>
                          <KeyboardDatePicker
                            className={classes.datePicker}
                            format="dd/MM/yyyy"
                            margin="dense"
                            label="Incident date"
                            inputVariant="outlined"
                            value={editingSiteIncidentDate}
                            onChange={(date) =>
                              setEditingSiteIncidentDate(date)
                            }
                            maxDate={maximumDate}
                            maxDateMessage={
                              "Incident date must be in the past."
                            }
                          />
                          <Button
                            className={classes.saveDateButton}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleSaveEditingSiteDate}
                            disabled={
                              editingSiteIncidentDate
                                ? isAfter(
                                    new Date(editingSiteIncidentDate),
                                    maximumDate
                                  )
                                : false
                            }
                          >
                            Save
                          </Button>
                          <Button
                            className={classes.saveDateButton}
                            color="primary"
                            size="small"
                            onClick={handleCloseEditingSiteDate}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : incident.incidentDate ? (
                        formatShortDate(incident.incidentDate)
                      ) : (
                        <>
                          {"Not set"}
                          <Button
                            color="primary"
                            variant="outlined"
                            className={classes.editDateButton}
                            onClick={() =>
                              setEditingSiteId(incident.siteExternalId)
                            }
                          >
                            Set
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DaysSinceIncidentCard;
