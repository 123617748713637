import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateFormService from "../../services/templateFormService";

const useSitesTemplateForms = ({ externalIds, pageSize, pageNum, moduleArea, sortOrder, orderByColumn, fromDate, toDate, filteredText, assignees, showHistoric  }) =>
  useQuery(
      [queryKeys.templateForms, externalIds?.join(","), pageNum, pageSize, moduleArea, sortOrder, orderByColumn, fromDate, toDate, showHistoric, filteredText && filteredText.length > 3 ? filteredText : undefined, assignees ].filter(Boolean), 
      () => templateFormService.fetchTemplateFormsForSites({ siteExternalIds: externalIds, pageSize, pageNum, moduleArea, sortOrder, orderByColumn, fromDate, toDate, showHistoric, filteredText: filteredText && filteredText.length > 3 ? filteredText : undefined, assignees }),
    {
      enabled: !!externalIds?.length && !!moduleArea,
    }
  );

export default useSitesTemplateForms;
