import React from "react";
import {Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import BreadboxCheckbox from "./BreadboxCheckbox";
import { accessOptions } from "../../constants/manageUserOptions";

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    margin: theme.spacing(2, 0),
  },
  lastDivider: {
    width: "100%",
    margin: theme.spacing(2, 0, 4, 0),
  },
  nestedCheckboxesContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
  },
  title: {
    fontSize: "24px",
    fontWeight: "500",
    marginBottom: theme.spacing(3)
  }
}));

const Access = ({
  appIds,
  onChange,
  safetyNestModules,
  onSafetyNestModuleChange,
  isCaseNestAdmin,
  isSafetyNestAdmin,
  isMyWorkNestAdmin,
  hasSafetyNestAccess,
  adminUserApps,
  canAccessChildSites,
  setCanAccessChildSites
}) => {
  const classes = useStyles();

  const handleChange = (value, key) =>
    onSafetyNestModuleChange({ ...safetyNestModules, [key]: value });

  const isIndeterminate =
    hasSafetyNestAccess && Object.values(safetyNestModules).includes(false);

  return (
    <Grid spacing={2} container>
      <Typography className={classes.title}>Site Access</Typography>
      <Grid item xs={12}>
        <BreadboxCheckbox
          title={accessOptions.siteAccessAdmin.title}
          description={accessOptions.siteAccessAdmin.description}
          checked={canAccessChildSites}
          onChange={checked => setCanAccessChildSites(checked)}
        />
      </Grid>
      
      {(isCaseNestAdmin || isSafetyNestAdmin || (isMyWorkNestAdmin && adminUserApps)) && (
        <>
          <Divider className={classes.divider} />
          <Typography className={classes.title}>Application Access</Typography>

          {isCaseNestAdmin && (
            <Grid item xs={12}>
              <BreadboxCheckbox
                title={accessOptions.caseNestAdmin.title}
                description={accessOptions.caseNestAdmin.description}
                checked={appIds.includes(accessOptions.caseNestAdmin.appId)}
                onChange={() => onChange(accessOptions.caseNestAdmin.appId)}
              />
            </Grid>
          )}

          {isSafetyNestAdmin && (
            <>
              <Divider className={classes.divider} />
              <Grid item xs={12}>
                <BreadboxCheckbox
                  title={accessOptions.safetyNestAdmin.title}
                  description={accessOptions.safetyNestAdmin.description}
                  checked={appIds.includes(accessOptions.safetyNestAdmin.appId)}
                  onChange={() => onChange(accessOptions.safetyNestAdmin.appId)}
                  indeterminate={isIndeterminate}
                />
                {accessOptions.safetyNestAdmin.modules.map((module) => (
                  <div
                    className={classes.nestedCheckboxesContainer}
                    key={module.key}
                  >
                    <BreadboxCheckbox
                      title={module.title}
                      description={module.description}
                      checked={safetyNestModules[module.key]}
                      onChange={(e) => handleChange(e, module.key)}
                      disabled={!hasSafetyNestAccess}
                    />
                  </div>
                ))}
              </Grid>
            </>
          )}

          {isMyWorkNestAdmin && adminUserApps && (
            <>
              {accessOptions.myWorkNestAdmin.map(
                (app) =>
                  adminUserApps.includes(app.appId) && (
                    <React.Fragment key={app.appId}>
                      <Divider className={classes.divider} />
                      <Grid item xs={12}>
                        <BreadboxCheckbox
                          title={app.title}
                          oldName={app.oldName}
                          description={app.description}
                          checked={appIds.includes(app.appId)}
                          onChange={() => onChange(app.appId)}
                        />
                      </Grid>
                    </React.Fragment>
                  )
              )}
            </>
          )}
        </>
      )}
      
      <Divider className={classes.lastDivider} />
    </Grid>
  );
};

export default Access;
