import React from "react";
import { Box, Button, makeStyles, Paper, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useHistory } from "react-router";
import { useTracking } from "react-tracking";
import layoutConstants from "../../constants/layoutConstants";
import trackingConstants from "../../constants/trackingConstants";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    height: `${layoutConstants.featureTileWidth}px`,
    transition: "0.2s all ease-out",
    "&::before": {
      content: '""',
      paddingBottom: "100%",
      display: "block",
    },
    "&:hover": {
      backgroundColor: grey[200],
      transform: "scale(1.03)",
    },
    "& .MuiButton-label": {
      flexDirection: "column",
    },
  },
  logoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  logo: {
    width: "100%",
    maxWidth: "100%",
    maxHeight: "60px",
  },
  icon: {
    fontSize: 54,
  },
  title: {
    textAlign: "center",
    fontWeight: 500,
    textTransform: "none",
  },
}));

const FeatureTile = ({
  title,
  icon: Icon = null,
  image,
  imageText,
  internalRoute = "",
  externalRoute = "",
}) => {
  const { trackEvent } = useTracking();
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    if (internalRoute) return history.push(internalRoute);

    if (externalRoute) {
      trackEvent({
        event: `${trackingConstants.events.featureTileClick}: ${title}`,
      });
      return (window.location.href = externalRoute);
    }

    throw new Error("No internal or external route provided for service tile.");
  };

  const titleComponent = (title) => (
    <Typography className={classes.title}>{imageText ?? title}</Typography>
  );

  const imageComponent = () => (
    <img src={image} className={classes.logo} alt={`${title} logo`} />
  );

  const iconComponent = () => (
    <>
      <Icon color="secondary" className={classes.icon} />
      <Typography className={classes.title}>{imageText ?? title}</Typography>
    </>
  );

  return (
    <Paper component={Button} className={classes.card} onClick={handleClick}>
      <Box className={classes.logoWrapper}>
        {!Icon && !image && titleComponent(title)}
        {Icon && !image && iconComponent()}
        {image && imageComponent()}
        {image && imageText && titleComponent(imageText)}
      </Box>
    </Paper>
  );
};

export default FeatureTile;
