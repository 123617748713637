import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import acknowledgementService from "../../services/acknowledgementService";

const useVerifiedAcknowledgement = ({ emailAddress, acknowledgementId, readyToVerify }) => 
    useQuery(
        [queryKeys.verifiedAcknowledgement, emailAddress, acknowledgementId],
        () => acknowledgementService.verify({ emailAddress, acknowledgementId }),
        { enabled: !!emailAddress && !!acknowledgementId && !!readyToVerify }
    );

export default useVerifiedAcknowledgement;