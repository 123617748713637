import { nanoid } from "nanoid";
import { useSnackbar } from "notistack";
import { createContext, useContext, useMemo, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import { actionsStatus } from "../constants/actionStatusConstants";
import useSaveAction from "../hooks/mutations/useSaveAction";
import useAction from "../hooks/queries/useAction";
import useUploadActionAttachments from "../hooks/mutations/useUploadActionAttachments";
import useAddActionComment from "../hooks/mutations/useAddActionComment";
import { formatShortMonthDate } from "../../utils/dateTimeUtils";
import { useSelector } from "react-redux";
import useContactsForAppAndSite from "../../hooks/useContactsForAppAndSite";

const ActionContext = createContext()

const actionTabEnum = {
    COMMENTS: 0,
    ATTACHMENTS: 1,
}

const actionComments = [];
const actionAttachments = [];

const actionCommentActions = {
    ADD_COMMENTS: "ADD_COMMENTS",
    RESET: "RESET",
}

const actionAttachmentActions = {
    ADD_ATTACHMENTS: "ADD_ATTACHMENTS",
    RESET: "RESET",
    UPLOAD_REQUEST: "upload-request",
    UPLOAD_ERROR: "upload-error",
    UPLOAD_SUCCESS: "upload-success",
    DELETE: "delete",
}

function actionCommentReducer(_, action) {
    switch (action.type) {
        case actionCommentActions.ADD_COMMENTS:
            return action.payload
        case actionCommentActions.RESET:
            return [];
        default:
            return;
    }
}

function actionAttachmentReducer(state, action) {
    switch (action.type) {
        case actionAttachmentActions.ADD_ATTACHMENTS:
            return action.payload
        case actionAttachmentActions.RESET:
            return [];
        case actionAttachmentActions.UPLOAD_REQUEST: {
            const newFiles = action.files.map((newUpload) => ({
                syncId: newUpload.syncId,
                name: newUpload.file.name,
                type: newUpload.file.fileType,
                isUploading: true,
            }));

            return [...state, ...newFiles];
        }
        case actionAttachmentActions.UPLOAD_ERROR:
            return state.filter((x) => action.syncIds.includes(x.syncId));
        case actionAttachmentActions.UPLOAD_SUCCESS: {
            return state.map((x) => {
                const updatedUpload = action.files.find((f) => f.syncId === x.syncId);
                return updatedUpload
                    ? {
                        ...x,
                        ...updatedUpload,
                        isUploading: false,
                        toBeAdded: true,
                    }
                    : x;
            });
        }
        case actionAttachmentActions.DELETE:
            return state.map((x) =>
                x?.azureFileId === action.azureFileId
                    ? { ...x, toBeAdded: false, toBeDeleted: true }
                    : x
            );
        default:
            return;
    }
}

const actionTabs = {
    COMMENTS: {
        label: "Comments",
        count: 0,
    },
    ATTACHMENTS: {
        label: "Attachments",
        count: 0,
    },
}

const actionTabActions = {
    SET_COMMENTS_COUNT: "SET_COMMENTS_COUNT",
    SET_ATTACHMENTS_COUNT: "SET_ATTACHMENTS_COUNT",
}

function actionTabReducer(state, action) {
    switch (action.type) {
        case actionTabActions.SET_COMMENTS_COUNT:
            return {
                ...state,
                COMMENTS: {
                    ...state.COMMENTS,
                    count: action.payload,
                },
            }
        case actionTabActions.SET_ATTACHMENTS_COUNT:
            return {
                ...state,
                ATTACHMENTS: {
                    ...state.ATTACHMENTS,
                    count: action.payload,
                },
            }
        default:
            return;
    }
}

function ActionProvider({ children }) {
    
    const [selectedActionId, setSelectedActionId] = useState(null)
    const [isDri, setIsDri] = useState(false)
    const [isComplete, setIsComplete] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [filesUploading, setFilesUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const saveAction = useSaveAction();
    const addComment = useAddActionComment();

    const { activeAppId, appSiteIds, selectedSite } = useSelector(
        (state) => state.account
    );

    const siteUsers = useContactsForAppAndSite({
        siteExternalIds: selectedSite
            ? [selectedSite.externalId]
            : appSiteIds[activeAppId],
    });

    const action = useAction({ actionId: selectedActionId, isDri }, { onSuccess: initActionData })
    const uploadActionAttachments = useUploadActionAttachments();

    const [assignedEmployeeExternalId, setAssignedEmployeeExternalId] = useState(action.data?.assignedEmployeeExternalId ?? "");
    const [dueDate, setDueDate] = useState(action.data?.dueDate);

    const isEditAllowed = useMemo(() =>
        action.data?.status !== actionsStatus.COMPLETED || (action.data?.status === actionsStatus.COMPLETED && action.data?.isEditCompletedAllowed),
        [action]);

    const [actionTabDetails, actionTabDispatch] = useReducer(actionTabReducer, actionTabs);
    const [actionCommentDetails, actionCommentDispatch] = useReducer(actionCommentReducer, actionComments);
    const [actionAttachmentDetails, actionAttachmentDispatch] = useReducer(actionAttachmentReducer, actionAttachments);

    const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
    };

    const resetState = () => {
        resetAttachments();
        resetComments();
        setSelectedActionId(null);
        setIsDri(false);
        setIsComplete(false);
        setSelectedTab(0);
        setFilesUploading(false);
        setUploadProgress(0);
    };

    const goBack = () => {
        history.goBack();
        resetState();
    };

    const save = () => {
        if (!assignedEmployeeExternalId) {
            enqueueSnackbar("Please select the employee responsible.", { variant: "error" });
            return;
        }

        const dto = {
            ...action.data,
            assignedEmployeeExternalId,
            dueDate,
            isComplete,
            isDri,
            azureFileIdsToDelete: actionAttachmentDetails.filter(x => x.toBeDeleted).map(x => x.azureFileId),
        }

        saveAction.mutate(dto, {
            onSuccess: () => {
                if (dueDate !== action.data.dueDate) {
                    const commentToAdd = {
                        comment: `Due Date was changed from ${formatShortMonthDate(action.data.dueDate)} to ${formatShortMonthDate(dueDate)}`,
                        actionId: selectedActionId,
                        isDri,
                    }

                    addComment.mutate(commentToAdd, {
                        onSuccess: (data) => {
                            addComments(data)
                        },
                        onError: (error) => {
                            console.error(error);
                            enqueueSnackbar("Could not add comment to action.", { variant: "error" });
                        },
                    })
                }

                if (assignedEmployeeExternalId !== action.data.assignedEmployeeExternalId) {

                    let oldUser = siteUsers.data[action.data.assignedEmployeeExternalId];
                    let oldUserName = oldUser ? `${oldUser.firstName} ${oldUser.lastName}` : "Not Set";
                    let newUser = siteUsers.data[assignedEmployeeExternalId];

                    const commentToAdd = {
                        comment: `Responsible User was changed from ${oldUserName} to ${newUser.firstName} ${newUser.lastName}`,
                        actionId: selectedActionId,
                        isDri,
                    }

                    addComment.mutate(commentToAdd, {
                        onSuccess: (data) => {
                            addComments(data)
                        },
                        onError: (error) => {
                            console.error(error);
                            enqueueSnackbar("Could not add comment to action.", { variant: "error" });
                        },
                    })
                }

                goBack()
                resetState()
            },
            onError: (error) => {
                console.error(error);
                enqueueSnackbar("Could not save action.", { variant: "error" });
            },
        });
    };

    const uploadAttachments = async (files) => {
        setFilesUploading(true);
        const mappedFiles = [...files].map((file) => ({
            syncId: nanoid(5),
            file,
        }));

        actionAttachmentDispatch({
            type: actionAttachmentActions.UPLOAD_REQUEST,
            files: mappedFiles,
        });

        try {
            const uploadResponse = await uploadActionAttachments.mutateAsync(
                {
                    actionId: action?.data?.id,
                    files: mappedFiles,
                    isDri: action?.data?.isDri,
                    onUploadProgress,
                },
                {
                    onError: (error) => {
                        console.error(error);
                        enqueueSnackbar(
                            error?.message || "There was an error uploading attachments",
                            {
                                variant: "error",
                            }
                        );
                    },
                }
            );

            actionAttachmentDispatch({
                type: actionAttachmentActions.UPLOAD_SUCCESS,
                files: uploadResponse,
            });
            setAttachmentsCount(actionTabDetails.ATTACHMENTS.count + uploadResponse.length)
        } catch (e) {
            console.error(e);
            actionAttachmentDispatch({
                type: actionAttachmentActions.UPLOAD_ERROR,
                syncIds: mappedFiles.map((x) => x.syncId),
            });
            enqueueSnackbar("Could not upload file", { variant: "error" });
            setFilesUploading(false);
        } finally {
            setFilesUploading(false);
        }
    };

    const handleDeleteFile = (azureFileId) => {
        actionAttachmentDispatch({ type: actionAttachmentActions.DELETE, azureFileId });
    };

    const setCommentsCount = (count) => {
        actionTabDispatch({
            type: actionTabActions.SET_COMMENTS_COUNT,
            payload: count,
        })
    }

    const setAttachmentsCount = (count) => {
        actionTabDispatch({
            type: actionTabActions.SET_ATTACHMENTS_COUNT,
            payload: count,
        })
    }

    const addComments = (comments) => {
        actionCommentDispatch({
            type: actionCommentActions.ADD_COMMENTS,
            payload: comments,
        })
        setCommentsCount(comments.length)
    }

    const resetComments = () => {
        actionCommentDispatch({
            type: actionCommentActions.RESET,
        })
    }

    const addAttachments = (attachments) => {
        actionAttachmentDispatch({
            type: actionAttachmentActions.ADD_ATTACHMENTS,
            payload: attachments,
        })
        setAttachmentsCount(attachments.length)
    }

    const resetAttachments = () => {
        actionAttachmentDispatch({
            type: actionAttachmentActions.RESET,
        })
    }

    function initActionData(data) {
        setAssignedEmployeeExternalId(data.assignedEmployeeExternalId);
        setDueDate(data.dueDate);
        setCommentsCount(data.comments?.length ?? 0);
        setAttachmentsCount(data.azureFiles?.length ?? 0);
        addComments(data.comments ?? []);
        addAttachments(data.azureFiles ?? []);
        setIsComplete(data.status === actionsStatus.COMPLETED);
    }

    const value = {
        setSelectedActionId,
        setIsDri,
        action,
        assignedEmployeeExternalId,
        setAssignedEmployeeExternalId,
        dueDate,
        setDueDate,
        selectedTab,
        setSelectedTab,
        actionTabDetails,
        actionTabEnum,
        addComments,
        addAttachments,
        actionCommentDetails,
        actionAttachmentDetails,
        resetComments,
        resetAttachments,
        selectedActionId,
        isDri,
        isComplete,
        setIsComplete,
        isEditAllowed,
        filesUploading,
        uploadAttachments,
        uploadProgress,
        handleDeleteFile,
        save,
        goBack,
        resetState,
    }

    return (
        <ActionContext.Provider value={value}>
            {children}
        </ActionContext.Provider>
    )
}

const useActionDetails = () => useContext(ActionContext)

export { ActionProvider, useActionDetails, ActionContext }