export const incidentBodyPartsList = [
  {
    value: 0,
    label: "Head",
  },
  {
    value: 1,
    label: "Neck",
  },
  {
    value: 2,
    label: "Wrist",
  },
  {
    value: 3,
    label: "Upper Limb",
  },
  {
    value: 4,
    label: "Lower Limb",
  },
  {
    value: 5,
    label: "Ankle",
  },
  {
    value: 6,
    label: "Eye",
  },
  {
    value: 7,
    label: "Trunk",
  },
  {
    value: 8,
    label: "Back",
  },
  {
    value: 9,
    label: "Arm",
  },
  {
    value: 10,
    label: "Hand",
  },
  {
    value: 11,
    label: "Finger",
  },
  {
    value: 12,
    label: "Leg",
  },
  {
    value: 13,
    label: "Foot",
  },
  {
    value: 14,
    label: "Thumb",
  },
  {
    value: 15,
    label: "Toe",
  },
  {
    value: 16,
    label: "Internal",
  },
  {
    value: 17,
    label: "Hearing",
  },
  {
    value: 18,
    label: "Other",
  },
];
