import React, { useState } from "react";
import { Box, Button, makeStyles, Paper, Typography } from "@material-ui/core";
import learningNestImage from "../../images/LearningNest Green.svg";
import { darken } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import useProductRecommendation from "../../hooks/queries/useProductRecommendation";
import productService from "../../services/productService";


const size = 280;
const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(3),
        position: "relative",
        width: '100%',
        backgroundColor: "#defaf7",
        color: '#084a54',
        marginBottom: '10px',
        height: `${size}px`,
        overflow: 'hidden',
        paddingRight: `${size + 48}px`,
        '& p, & ul': {
            margin: 0,
        },
        '& li': {
            color: '#084a54 !important',
        }
    },
    circle: {
        position: "absolute",
        backgroundColor: "#12a3ab",
        width: `${size * 1.9}px`,
        height: `${size * 1.9}px`,
        borderRadius: "50%",
        top: -size * 0.2 - 24,
        right: -size * 0.5 - 24,
        zIndex: 1,
    },
    image: {
        position: "absolute",
        zIndex: 2,
        height: `${size - 24}px`,
        right: 24,
    },
    button: {
        width: 300,
        color: 'white',
        backgroundColor: '#0a7a87',
        marginTop: '10px',
        '&:hover': {
            backgroundColor: darken('#0a7a87', 0.2)
        }
    },
    small: {
        '& p': {
            fontSize: '12px',
        }        
    },
}));

const ProductTile = () => {
    const classes = useStyles();
    const { selectedAccount } = useSelector((state) => state.account);
    const [requesting, setRequesting] = useState(false);
    const [complete, setComplete] = useState(false);

    const { data: recommendation } = useProductRecommendation(selectedAccount.account.externalId);
    
    const contactMe = async () => {
        setRequesting(true);

        try {
            await productService.acceptRecommendation({ id: recommendation.id, accountId: selectedAccount.account.externalId });
            setComplete(true);
        }
        finally {
            setRequesting(false);
        }       
    }

    if (!recommendation)
        return null;
    
    return (
        <Paper className={classes.card}>
            <div className={classes.circle} />
            <img className={classes.image} src={learningNestImage} alt=""/>
            <Box display="flex" flexDirection="column" style={{ maxHeight: '100%', height: '100%' }}>
                <Typography style={{fontSize:'12px'}}>Suggested for you</Typography>
                <Typography variant="h6">{recommendation.productName}</Typography>
                <div style={{ flex: 1, height: 0, overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: recommendation.productInfo }} />
                {!complete &&
                    <Button
                        disabled={requesting}
                        className={classes.button}
                        variant="contained"
                        onClick={contactMe}>
                        {requesting ? "Sending request" : "Contact me with more information"}
                    </Button>
                }
                {complete &&
                    <Box display="flex" flexDirection="column" className={classes.small}>
                        <Typography><strong>Request received! We'll be in touch soon!</strong></Typography>
                        <Typography variant="body2">In the meantime, check your inbox for initial product information.</Typography>
                    </Box>
                }
            </Box>
        </Paper>
    );
};

export default ProductTile;
