import {
  SET_DATE_RESPONSE,
  SET_NUMBER_RESPONSE,
  SET_TEXT_RESPONSE,
  SET_OPTION_RESPONSE,
  SET_MULTISELECT_RESPONSE,
  CLEAR_MULTISELECT_RESPONSE,
  CLEAR_FORM,
  INIT_QUESTION_ACTIONS,
  SET_ACTION_DUE_DATE,
  SET_ACTION_ASSIGNED_TO_EMPLOYEE,
  SET_QUESTION_FILES,
  SET_EXISTING_RESPONSES,
  SET_QUESTION_ADDITIONAL_INFO,
  SET_ACTION_RATING,
} from "../types";

export function setDateResponse({ value, templateQuestionId }) {
  return {
    type: SET_DATE_RESPONSE,
    payload: {
      value,
      templateQuestionId,
    },
  };
}

export function setNumberResponse({ value, templateQuestionId }) {
  return {
    type: SET_NUMBER_RESPONSE,
    payload: {
      value,
      templateQuestionId,
    },
  };
}

export function setExistingResponses(responses) {
  return {
    type: SET_EXISTING_RESPONSES,
    payload: {
      responses,
    },
  };
}

export function setTextResponse({ value, templateQuestionId }) {
  return {
    type: SET_TEXT_RESPONSE,
    payload: {
      value,
      templateQuestionId,
    },
  };
}

export function setOptionResponse({ value, templateQuestionId }) {
  return {
    type: SET_OPTION_RESPONSE,
    payload: {
      value,
      templateQuestionId,
    },
  };
}

export function setMultiselectResponse({ values, templateQuestionId }) {
  return {
    type: SET_MULTISELECT_RESPONSE,
    payload: {
      values,
      templateQuestionId,
    },
  };
}

export function clearMultiselectResponse(templateQuestionId) {
    return {
        type: CLEAR_MULTISELECT_RESPONSE,
        payload: {
            templateQuestionId,
        },
    };
}

export function clearForm() {
  return {
    type: CLEAR_FORM,
  };
}

export function setQuestionFiles({ templateQuestionId, files }) {
  return {
    type: SET_QUESTION_FILES,
    payload: {
      templateQuestionId,
      files,
    },
  };
}

export function setQuestionAdditionalInfo({
  templateQuestionId,
  additionalInfo,
}) {
  return {
    type: SET_QUESTION_ADDITIONAL_INFO,
    payload: {
      templateQuestionId,
      additionalInfo,
    },
  };
}

export function initialiseQuestionActions(actions) {
  return {
    type: INIT_QUESTION_ACTIONS,
    payload: actions,
  };
}

export function setActionDueDate({ actionId, dueDate }) {
  return {
    type: SET_ACTION_DUE_DATE,
    payload: {
      actionId,
      dueDate,
    },
  };
}

export function setActionAssignedToEmployeeId({
  actionId,
  employeeExternalId,
}) {
  return {
    type: SET_ACTION_ASSIGNED_TO_EMPLOYEE,
    payload: {
      actionId,
      employeeExternalId,
    },
  };
}

export function setActionRating({ actionId, rating }) {
    return {
        type: SET_ACTION_RATING,
        payload: {
            actionId,
            rating,
        },
    };
}
