import { Container } from "@material-ui/core"
import RequiresRoleWrapper from "../../../components/RequiresRoleWrapper";
import { useAuth } from "../../../contexts/authContext";
import SearchContainer from "../../components/SearchContainer";

export default function SiteSearch() {
    const { isWorknestUser } = useAuth()

    return (
        <RequiresRoleWrapper hasRole={isWorknestUser}>
            <Container maxWidth='md'>
                <SearchContainer />
            </Container>
        </RequiresRoleWrapper>
    )
}