import React from "react";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import CardBase from "../../../../components/cards/CardBase";
import CabbageCounter from "../../../../components/ui/CabbageCounter";
import useIsMobile from "../../../../hooks/useIsMobile";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  mobile: {
    display: "flex",
    justifyContent: "end",
    marginBottom: theme.spacing(1),
  },
  bottomGap: {
    marginBottom: theme.spacing(2),
  },
  marginTopAndBottom: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paddingTopAndBottom: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  description: {
    color: grey[500],
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    gridColumnGap: 12,
    marginTop: theme.spacing(1),
  },
}));

export function SelectedTemplatesList({
  addedTemplates,
  handleDelete,
  addTemplate,
  handleDecrement,
  setActiveStep,
}) {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useIsMobile();

  const distinctTemplates = [
    ...new Map(
      addedTemplates.map((template) => [template.template["id"], template])
    ).values(),
  ];

  function onBack() {
    history.goBack();
    return;
  }

  return (
    <div className={classes.bottomGap}>
      <CardBase title="Selected Templates">
        <Grid
          container
          className={addedTemplates.length && classes.marginTopAndBottom}
        >
          <Grid item xs={12} md={12}>
            <List
              className={addedTemplates.length && classes.paddingTopAndBottom}
              disablePadding={!addedTemplates.length}
            >
              {distinctTemplates?.map((form) => (
                <Fragment key={form.template.id}>
                  <ListItem>
                    <ListItemText
                      primary={form.template.name}
                      secondary={`Sector: ${form.template.sector}, Subject: ${form.template.subject}`}
                    />
                    {!isMobile && (
                      <CabbageCounter
                        count={
                          addedTemplates?.length
                            ? addedTemplates.filter(
                                (template) =>
                                  template.template.id === form.template.id
                              ).length
                            : ""
                        }
                        handleDecrement={() => handleDecrement(form.tempFormId)}
                        handleIncrement={() => addTemplate(form.template)}
                        handleDelete={() => handleDelete(form.template.id)}
                      />
                    )}
                  </ListItem>
                  {isMobile && (
                    <div className={classes.mobile}>
                      <CabbageCounter
                        count={
                          addedTemplates?.length
                            ? addedTemplates.filter(
                                (template) =>
                                  template.template.id === form.template.id
                              ).length
                            : ""
                        }
                        handleDecrement={() => handleDecrement(form.tempFormId)}
                        handleIncrement={() => addTemplate(form.template)}
                        handleDelete={() => handleDelete(form.template.id)}
                      />
                    </div>
                  )}
                </Fragment>
              ))}
            </List>
          </Grid>
        </Grid>
        {!addedTemplates.length && (
        <Typography
            variant="subtitle2"
            component="h6"
            className={classes.description}
        >
            No template selected
        </Typography>
        )}
        <div className={classes.buttonContainer}>
          <Button variant="outlined" color="primary" onClick={onBack}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!addedTemplates.length}
            onClick={() => setActiveStep(1)}
          >
            Next
          </Button>
        </div>
      </CardBase>
    </div>
  );
}
