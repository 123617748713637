import { Dialog, Button, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { useHistoricActionContext } from '../../../contexts/HistoricActionContext';
import { useSnackbar } from 'notistack';
import { useHistoricAuditContext } from '../../../contexts/HistoricAuditContext';
import ActionForm from '../ActionForm';
import useCreateHistoricAction from '../../../hooks/mutations/useCreateHistoricAction';

export default function CreateHistoricActionDialog({ open, onCancel }) {
    const { enqueueSnackbar } = useSnackbar()

    const { 
        historicActionDetails, 
        reset,
    } = useHistoricActionContext()

    const { selectedAudit } = useHistoricAuditContext()

    function handleCancel() {
        reset()
        onCancel()
    }

    const createHistoricAction = useCreateHistoricAction()

    function handleCreateHistoricAction(e) {
        e.preventDefault()

        if (!selectedAudit?.id) 
            return

        createHistoricAction.mutate({
            ...historicActionDetails,
            historicAuditId: selectedAudit.id,
            siteExternalId: selectedAudit.siteExternalId,
        })
        
        reset()
        onCancel()

        enqueueSnackbar('Historic Action created successfully', { variant: 'success' })
    }

    return (
        <Dialog
            open={open}
            onClose={onCancel}
        >  
            <form
                onSubmit={(e) => handleCreateHistoricAction(e)}
                onReset={handleCancel}
            >
                <DialogTitle>Create New Action</DialogTitle>
                <DialogContent>
                    <ActionForm />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onCancel}
                        color="secondary"
                        variant="text"
                    >
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        color="primary"
                        variant="text"
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}