import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, makeStyles, Tabs, Tab, } from '@material-ui/core'
import React, { useState } from 'react'
import { useAuth } from '../../../../contexts/authContext';
import { format, parseISO } from "date-fns";
import useContactsForAppAndSite from "../../../../hooks/useContactsForAppAndSite";
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    centerCell: {
        textAlign: "center"
    },
    preLine: {
    whiteSpace: "pre-line"
    },
    noContentDialog: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(1),
    },
    infoIcon: {
        fontSize: '32px',
        marginRight: theme.spacing(2),
    }
 }));

export default function RiskAssessmentVersionHistoryDialog({ isOpen, setIsOpen, versionHistory }) {
    const classes = useStyles();
    const { isWorknestUser } = useAuth()
    const contacts = useContactsForAppAndSite({ selectedSiteId: null });
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Dialog open={isOpen} maxWidth="md" fullWidth>
            <DialogTitle>Version History</DialogTitle>
            <DialogContent>
                {(versionHistory?.data?.controlChanges?.length === 0 && versionHistory?.data?.hazardChanges?.length === 0) ?
                    <DialogContent className={classes.noContentDialog}>
                        <InfoIcon className={classes.infoIcon} color="primary" />

                        <span>No amendments have been made to this version of the Risk Assessment.</span>
                    </DialogContent>:<>
                        <Tabs value={selectedTab} onChange={handleTabChange}>
                            {versionHistory?.data?.hazardChanges?.length > 0 && <Tab label={`Hazard Score Amendments ${versionHistory?.data?.hazardChanges?.length > 0 ? ` (${versionHistory.data.hazardChanges.length})` : ''}`} />}
                            {versionHistory?.data?.controlChanges?.length > 0 && <Tab label={`Hazard Control Amendments ${versionHistory?.data?.controlChanges?.length > 0 ? ` (${versionHistory.data.controlChanges.length})` : ''}`} />}
                        </Tabs>
                        {selectedTab === 0 && versionHistory?.data?.hazardChanges?.length > 0 ? (
                            <Table>
                                <TableHead>
                                    <TableCell className={classes.centerCell}>Updated On</TableCell>
                                    <TableCell>Updated By</TableCell>
                                    <TableCell>Update Notes</TableCell>
                                </TableHead>
                                <TableBody>
                                    {versionHistory?.data?.hazardChanges?.map(version => (
                                        <TableRow key={version.id}>
                                            <TableCell className={classes.centerCell}>{format(parseISO(version.changeDate), 'dd/MM/yyyy HH:mm')}</TableCell>
                                            {!isWorknestUser && <TableCell>{(contacts?.data && `${contacts?.data[version.updatedByExternalId]?.firstName ?? 'Unknown'} ${contacts?.data[version.updatedByExternalId]?.lastName ?? 'User'}`)}</TableCell>}
                                            <TableCell className={classes.preLine}>{version.updateSummary}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : versionHistory?.data?.controlChanges?.length > 0 && (
                            <Table>
                                <TableHead>
                                    <TableCell className={classes.centerCell}>Updated On</TableCell>
                                    <TableCell>Updated By</TableCell>
                                    <TableCell>Hazard Section</TableCell>
                                    <TableCell>Update Notes</TableCell>
                                </TableHead>
                                <TableBody>
                                    {versionHistory?.data?.controlChanges?.map(version => (
                                        <TableRow key={version.id}>
                                            <TableCell className={classes.centerCell}>{format(parseISO(version.changeDate), 'dd/MM/yyyy HH:mm')}</TableCell>
                                            {!isWorknestUser && <TableCell>{(contacts?.data && `${contacts?.data[version.updatedByExternalId]?.firstName ?? 'Unknown'} ${contacts?.data[version.updatedByExternalId]?.lastName ?? 'User'}`)}</TableCell>}
                                            <TableCell>{version.hazardName}</TableCell>
                                            <TableCell>
                                                {version.archivedDate ? (
                                                    <span>
                                                        <strong>Control Removed : </strong> {version.originalCustomControlText}
                                                    </span>
                                                ) : (
                                                    <React.Fragment>
                                                        {version.originalCustomControlText && (version.originalCustomControlText !== version.updatedCustomControlText) && (
                                                            <span>
                                                                <strong>Original Control Text:</strong> {version.originalCustomControlText}<br />
                                                            </span>
                                                        )}
                                                        <span>
                                                            <strong>Control Text:</strong> {version.updatedCustomControlText}
                                                        </span>
                                                        {version.originalInPlace !== undefined && version.originalInPlace !== version.updatedInPlace && (
                                                            <span>
                                                                <br /><strong>Control Answer changed : </strong> {version.originalInPlace ? "Yes" : "No"} to {version.updatedInPlace ? "Yes" : "No"}<br />
                                                            </span>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </>
                }
                    </DialogContent>
            <DialogActions>
                <Button
                    variant='text'
                    onClick={() => setIsOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}