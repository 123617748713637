import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";
import { useIncidentDetails } from "../../contexts/IncidentContext";
import { CheckBox, CheckBoxOutlineBlank, Delete } from "@material-ui/icons";
import ResponsiveSelect from "../../../components/ui/ResponsiveSelect";
import { Autocomplete } from "@material-ui/lab";
import { incidentBodyPartsList } from "../../constants/incidentBodyParts";
import { injuryTypeList } from "../../constants/injuryType";
import { incidentTypes } from "../../constants/incidentType";
import { genderTypeList } from "../../constants/genderListConstants";
import { KeyboardDatePicker } from "@material-ui/pickers";

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: `1px solid ${grey[200]}`,
    borderRadius: "4px",
  },
  deleteButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  deleteButton: {
    color: red[500],
    borderColor: red[500],
  },
  textField: {
    margin: 0,
  },
}));

function PersonForm({ personId, isOnly }) {
  const classes = useStyles();
  const {
    people,
    deletePerson,
    incidentType,
    setPersonName,
    setPersonAddress,
    setPersonRole,
    setPersonIsEmployee,
    setPersonInjuryType,
    setPersonInjuredBodyParts,
    setPersonFirstAidProvided,
    setPersonGenderType,
    setPersonDateOfBirth,
  } = useIncidentDetails();

  const person = people.details[personId];

  const onNameChange = (e) => {
    setPersonName({ personId, name: e.target.value });
  };

  const onAddressChange = (e) => {
    setPersonAddress({ personId, address: e.target.value });
  };

  const onRoleChange = (e) => {
    setPersonRole({ personId, role: e.target.value });
  };

  const onIsEmployeeChange = (e) => {
    setPersonIsEmployee({ personId, isEmployee: e.target.checked });
  };

  const onInjuryTypeChange = (e) => {
    setPersonInjuryType({ personId, injuryType: e.target.value });
  };

  const onInjuryBodyPartsChange = (e, injuredBodyParts) => {
    setPersonInjuredBodyParts({ personId, injuredBodyParts });
  };

  const onFirstAidProvidedChange = (e) => {
    setPersonFirstAidProvided({ personId, firstAidProvided: e.target.checked });
  };

  const onGenderTypeChange = (e) => {
    setPersonGenderType({ personId, genderType: e.target.value });
  };

  const onDateOfBirthChange = (date) => {       
    setPersonDateOfBirth({ personId, dateOfBirth: date });
  };

  return (
    <div className={classes.root}>
      <div className={classes.deleteButtonWrapper}>
        <Button
          disabled={isOnly}
          className={classes.deleteButton}
          variant="outlined"
          size="small"
          startIcon={<Delete />}
          onClick={() => deletePerson(personId)}
        >
          Delete
        </Button>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            required
            className={classes.textField}
            variant="outlined"
            size="small"
            label="Name"
            fullWidth
            value={person.name}
            onChange={onNameChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <KeyboardDatePicker
            size="small"
            fullWidth
            inputVariant="outlined"
            format="dd/MM/yyyy"
            label="Date of birth"
            value={person.dateOfBirth || null}
            onChange={onDateOfBirthChange}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ResponsiveSelect
            required
            fullWidth
            label="Gender"
            size="small"
            options={genderTypeList}
            optionValueKey="value"
            optionLabelKey="label"
            value={person.genderType}
            onChange={onGenderTypeChange}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            className={classes.textField}
            variant="outlined"
            size="small"
            label="Address"
            fullWidth
            value={person.address}
            onChange={onAddressChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.textField}
            variant="outlined"
            size="small"
            label="Role"
            fullWidth
            value={person.role}
            onChange={onRoleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={person.isEmployee}
                name="isEmployee"
                color="primary"
                onChange={onIsEmployeeChange}
              />
            }
            label="Is this person an employee?"
          />
        </Grid>
        {incidentType === incidentTypes.ACCIDENT && (
          <>
            <Grid item xs={12} md={4}>
              <ResponsiveSelect
                fullWidth
                required
                label="Injury type"
                size="small"
                options={injuryTypeList}
                optionValueKey="value"
                optionLabelKey="label"
                value={person.injuryType}
                onChange={onInjuryTypeChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                size="small"
                value={person.injuredBodyParts}
                onChange={onInjuryBodyPartsChange}
                options={incidentBodyPartsList}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={uncheckedIcon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    variant="outlined"
                    size="small"
                    label="Injured Body Parts"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={person.firstAidProvided}
                    name="firstAidProvided"
                    color="primary"
                    onChange={onFirstAidProvidedChange}
                  />
                }
                label="Was first aid provided?"
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

export default PersonForm;
