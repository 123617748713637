import appIdConstants from "./appIdConstants";
import { alertFrequencyConstants, permissionConstants } from "./manageUserConstants";

const userDetailOptions = [
    {
        title: "Name",
        choices: [
            {
                label: "First Name",
                key: "firstName",
                isRequired: true,
                errorMsg: "Enter a first name",
            },
            {
                label: "Last Name",
                key: "lastName",
                isRequired: true,
                errorMsg: "Enter a last name",
            },
            {
                label: "Job Title",
                key: "title",
            },
        ],
    },
    {
        title: "Contact",
        choices: [
            {
                label: "Email",
                key: "email",
                isRequired: true,
                errorMsg: "Enter a valid email address",
            },
            { label: "Mobile", key: "mobile" },
            { label: "Phone", key: "phone" },
        ],
    },
    {
        title: "Employment Status",
        isRadioChoice: true,
        choices: [
            {
                label: "Employed",
                description: "An employee who can have access to myWorkNest",
                value: "active",
                key: "employmentStatus",
            },
            {
                label: "Leaver",
                description: "No longer an employee, and no longer has access to myWorkNest",
                value: "former",
                key: "employmentStatus",
            },
        ],
    },
];

const accessOptions = {
    caseNestAdmin: {
        title: "CaseNest",
        description: "Your legal application allows you to view your legal cases.",
        appId: appIdConstants.casenest,
    },
    safetyNestAdmin: {
        title: "SafetyNest",
        description:
            "Your Health and Safety application, allows you to view and manage any Health and Safety actions.",
        appId: appIdConstants.safetynest,
        modules: [
            {
                title: "Audit",
                description: "View your General Risk Assessment, Workplace Inspection or Fire Risk Assessment. The action associated with your Audit will be available to view.",
                key: "canAccessAudits",
            },
            {
                title: "Compliance",
                description: "Store and monitor key certifications in an online filing cabinet that you can access instantly. ",
                key: "canAccessCompliance",
            },
            {
                title: "Monitoring",
                description: "Checklists that enable you to log and view your periodic checks for health and safety.",
                key: "canAccessMonitoring",
            },
            {
                title: "Incidents",
                description: "An electronic accident book, allows you to investigate and then understand trends.",
                key: "canAccessIncidents",
            },
            {
                title: "Risk Assessments",
                description: "Generate and manage your Risk Assessments. ",
                key: "canAccessRiskAssessments",
            },
        ],
    },
    myWorkNestAdmin: [
        {
            title: "SafetyNest",
            oldName: "DRI",
            description: "Your Health and Safety application, allows you to view and manage any health and Safety actions.",
            appId: appIdConstants.safetyNestDri,
        },
        {
            title: "LearningNest",
            oldName: "SafetyMedia",
            description: "Your e-learning application.",
            appId: appIdConstants.learningNestSafetyMedia,
        },
        {
            title: "LearningNest",
            oldName: "Delta Net",
            description: "Your e-learning application.",
            appId: appIdConstants.learningNestDeltaNet,
        },
        {
            title: "StorageNest",
            oldName: null,
            description: "View documents that you have stored on myWorkNest or documents that have been stored for you by WorkNest on your behalf.",
            appId: appIdConstants.storageNest,
        },
        {
            title: "ResourceNest H&S",
            oldName: null,
            description: "A resource of H&S documents and materials maintained by WorkNest.",
            appId: appIdConstants.resourceNestHS,
        },
        {
            title: "ResourceNest LAW",
            oldName: null,
            description: "A resource of legal documents and materials maintained by WorkNest.",
            appId: appIdConstants.resourceNestLAW,
        },
        {
            title: "LEI",
            oldName: null,
            description: "Legal Expenses Insurance Terms and Annual Declaration form.",
            appId: appIdConstants.lei,
        },
        {
            title: "PeopleNest",
            oldName: null,
            description: "Your people management application.",
            appId: appIdConstants.peopleNest,
        },
        {
            title: "Insights, News and Resources",
            oldName: null,
            description: "Topical webinars, helpful guides and template to help you manage your workforce and safety responsibilities.",
            appId: appIdConstants.insightsNewsAndResources,
        },
        {
            title: "myWorkNest",
            description: "Welcome information and team allocation.",
            appId: appIdConstants.myWorkNest,
        },
    ],
    siteAccessAdmin: {
        title: "Can Access Child Sites",
        description: "User will have access to all sites which have a child relationship with their direct site.",
    },
};

const permissionOptions = [
    {
        label: permissionConstants.MYWORKNEST,
        choices: [
            {
                title: "Administrator",
                description:
                    "Allows you to edit the permissions for other users to this application.",
                key: "myWorkNestAdministrator",
            },
        ],
    },
    {
        label: permissionConstants.CASENEST,
        choices: [
            {
                title: "Administrator",
                description: "Allows you to edit the permissions for other users to this application.",
                key: "caseNestAdministrator",
            },
            {
                title: "Receive Advice",
                description: "Allows you to receive legal advice from your legal advisers.",
                key: "Non-SRA",
                isAdviceType: true,
            },
            {
                title: "Receive Advice (SRA)",
                description: "Allows you to receive SRA regulated legal advice from your legal advisers.",
                key: "SRA",
                isAdviceType: true,
            },
            {
                title: "Receive Advice (HRC)",
                description: "Allows you to receive HR Consultancy advice from your HR advisers",
                key: "HRC",
                isAdviceType: true,
            },
            {
                isRadioChoice: true,
                label: "Cases",
                radioChoices: [
                    {
                        title: "My Cases Only",
                        description: "User only has access to cases where they have been added as a contact.",
                        key: "caseNestAllCases",
                        value: "myCases",
                    },
                    {
                        title: "All Cases",
                        description: "User has access to all cases.",
                        key: "caseNestAllCases",
                        value: "allCases",
                        warningText: "To be granted to most senior personnel only.",
                    },
                ],
            },
        ],
    },
    {
        label: permissionConstants.SAFETYNEST,
        choices: [
            {
                title: "Administrator",
                description: "Allows you to edit the permissions for other users to this application.",
                key: "safetyNestAdministrator",
            },
            {
                title: "Amend Templates",
                description: "Allows you to add, remove or customise templates.",
                key: "safetyNestAmendTemplates",
            },
            {
                title: "Complete Incident Investigations",
                description: "Allows you to complete an investigation when an incident has occurred.",
                key: "safetyNestCompleteIncidentInvestigations",
            },
            {
                title: "Edit Completed Actions",
                description: "Allows you to change a completed Action’s status, assignment, notes and attachments.",
                key: "safetyNestEditCompletedActions",
            },
            {
                title: "Incident Logging",
                description: "Allows you to log incidents despite not having access to the Incident module.",
                key: "safetyNestIncidentLogging",
            },
            {
                isRadioChoice: true,
                label: "Actions",
                radioChoices: [
                    {
                        title: "My Actions Only",
                        description: "User has access to actions that have been assigned to them only.",
                        key: "safetyNestAllActions",
                        value: "myActions",
                    },
                    {
                        title: "All Actions",
                        description: "User has access to all actions.",
                        key: "safetyNestAllActions",
                        value: "allActions",
                    },
                ],
            },
        ],
    },
];

const alertFrequencyOptions = [
    {
        label: "Daily",
        value: alertFrequencyConstants.DAILY,
        defaultChecked: true,
    },
    {
        label: "Weekly",
        value: alertFrequencyConstants.WEEKLY,
        defaultChecked: false,
    },
    {
        label: "Never",
        value: alertFrequencyConstants.NONE,
        defaultChecked: false,
    },
];

const alertOptions = [
    {
        label: "Actions",
        options: [
            {
                label: "Assigned",
                key: "actionAssigned",
                description: "Receive an alert when assigned an Action.",
                defaultChecked: false,
            },
            {
                label: "Overdue",
                key: "actionOverdue",
                description: "Receive an alert when an Action becomes overdue.",
                defaultChecked: true,
            },
        ],
    },
    {
        label: "Audits",
        options: [
            {
                label: "Published",
                key: "auditPublished",
                description: "Receive an alert when an Audit is published.",
                defaultChecked: true,
            },
        ],
    },
    {
        label: "Compliance Forms",
        options: [
            {
                label: "Due",
                key: "complianceFormDue",
                description: "Receive an alert when the completion of a Compliance Form is due.",
                defaultChecked: false,
            },
            {
                label: "Overdue",
                key: "complianceFormOverdue",
                description: "Receive an alert when the completion of a Compliance Form becomes overdue.",
                defaultChecked: true,
            },
        ],
    },
    {
        label: "Incidents",
        description: "Receive an alert when an incident has been logged.",
        key: "incidentLogged",
        defaultChecked: true,
        options: [
            {
                label: "All Incidents",
                description: "",
                key: "allIncidents",
                defaultChecked: false,
            },
            {
                label: "Incidents with investigation only",
                description: "",
                key: "incidentWithInvestigationLogged",
                defaultChecked: false,
            },
        ],
    },
    {
        label: "Monitoring Forms",
        options: [
            {
                label: "Due",
                key: "monitoringFormDue",
                description: "Receive an alert when the completion of a Monitoring Form is due.",
                defaultChecked: false,
            },
            {
                label: "Overdue",
                key: "monitoringFormOverdue",
                description: "Receive an alert when the completion of a Monitoring Form becomes overdue.",
                defaultChecked: true,
            },
        ],
    },
];

const instantIncidentAlertOptions =
{
    label: "Incidents",
    title: "Configure your instant emails based on your individual preferences and needs.",
    description: "Receive an instant alert when an incident has been logged on any site that you have access to.",
    key: "instantIncidentSetting",
    defaultChecked: true,
    options: [
        {
            label: "Do not notify me",
            value: 0,
            defaultChecked: false,
        },
        {
            label: "All incidents",
            value: 1,
            defaultChecked: false,
        },
        {
            label: "Incidents with investigation only",
            value: 2,
            defaultChecked: false,
        },
    ],
};

const instantAuditAlertOptions =
{
    label: "Audits",
    description: "Receive an instant alert when an audit has been published at any site that you have access to.",
    key: "instantAuditSetting",
    defaultChecked: true,
    options: [
        {
            label: "Do not notify me",
            value: 0,
            defaultChecked: false,
        },
        {
            label: "All audits",
            value: 1,
            defaultChecked: false,
        },
    ],
};

export {
    userDetailOptions,
    accessOptions,
    permissionOptions,
    alertFrequencyOptions,
    alertOptions,
    instantIncidentAlertOptions,
    instantAuditAlertOptions,
};
