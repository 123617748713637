import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateFormService from "../../services/templateFormService";

const useTemplateForm = (templateFormId) =>
  useQuery(
    [
      queryKeys.templateForm,
      templateFormId
    ],
    () => templateFormService.getTemplateForm(templateFormId),
    {
      enabled: !!templateFormId,
    }
  );

export default useTemplateForm;