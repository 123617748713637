import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateFormHistoryService from "../../services/templateFormHistoryService";

const useDownloadFormAttachment = (
  { reference, templateFormHistoryId },
  options = {}
) =>
  useQuery(
    [queryKeys.downloadFormAttachment, reference],
    () =>
      templateFormHistoryService.downloadAttachment(reference, templateFormHistoryId),
    { enabled: !!reference && !!templateFormHistoryId, ...options }
  );

export default useDownloadFormAttachment;
