import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import { useRiskAssessmentDetails } from "../../../contexts/RiskAssessmentContext";

const useStyles = makeStyles((theme) => ({
    bottomGap: {
        marginBottom: theme.spacing(2)
    },
    removed: {
        textDecoration: 'line-through'
    },
    dialog: {
        [theme.breakpoints.up("md")]: {
            height: "50vh",
        },
        [theme.breakpoints.down("sm")]: {
            height: "75vh",
        }
    },
}))

export default function CustomPeopleDialog() {
    const classes = useStyles()
    const { 
        setPeopleDialogOpen,
        peopleDialogOpen,
        addCustomPerson,
        riskAssessmentDetails,
        removeCustomPerson,
    } = useRiskAssessmentDetails()

    const [newCustomPerson, setNewCustomPerson] = useState('')
    const [removeOnSave, setRemoveOnSave] = useState([])
    const [newCustomPeople, setNewCustomPeople] = useState([])

    function onCancel() {
        setRemoveOnSave([])
        setNewCustomPeople([])
        setPeopleDialogOpen(false)
    }

    function onSave() {
        newCustomPeople?.map(person => addCustomPerson({
            id: person?.id,
            name: person?.name,
        }))
        removeOnSave?.map(id => removeCustomPerson(id))
        setNewCustomPeople([])
        setRemoveOnSave([])
        setPeopleDialogOpen(false)
    }

    function addPerson() {
        setNewCustomPeople([...newCustomPeople, {
            id: nanoid(5),
            name: newCustomPerson,
        }])
        setNewCustomPerson('')
    }

    return (
        <Dialog classes={Object.keys(riskAssessmentDetails?.customPeople)?.length > 0 && { paper: classes.dialog }} maxWidth="sm" fullWidth open={peopleDialogOpen}>
            <DialogTitle>Custom People</DialogTitle>
            <DialogContent>
                <Grid className={classes.bottomGap} spacing={2} alignItems='center' container>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            required
                            size='small'
                            variant="outlined"
                            label='Person Name'
                            value={newCustomPerson}
                            onChange={(e) => setNewCustomPerson(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant='text'
                            color='secondary'
                            disabled={!newCustomPerson}
                            onClick={addPerson}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                {Object.keys((riskAssessmentDetails?.customPeople)?.length > 0 || newCustomPeople?.length > 0) &&
                    <Table className={classes.bottomGap} size="small">
                        <TableHead>
                            <TableCell colSpan={2}>Person Name</TableCell>
                        </TableHead>
                        <TableBody>
                            {Object.keys(riskAssessmentDetails?.customPeople)?.map(person => {
                                const toBeRemoved = removeOnSave?.some(x => x === person)

                                return (    
                                    <TableRow key={person}>
                                        <TableCell className={toBeRemoved && classes.removed}>{riskAssessmentDetails?.customPeople[person]?.name}</TableCell>
                                        <TableCell align="right">
                                            <IconButton disabled={toBeRemoved} onClick={() => setRemoveOnSave([...removeOnSave, person])} size="small"><Delete /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            {newCustomPeople?.map(person => {
                                const toBeRemoved = removeOnSave?.some(x => x === person?.id)

                                return (
                                    <TableRow key={person?.id}>
                                        <TableCell className={toBeRemoved && classes.removed}>{person?.name}</TableCell>
                                        <TableCell align="right">
                                            <IconButton disabled={toBeRemoved} onClick={() => setRemoveOnSave([...removeOnSave, person?.id])} size="small"><Delete /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={newCustomPeople?.length === 0 && removeOnSave?.length === 0}
                    onClick={onSave}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}