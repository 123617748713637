import React, { useState } from "react";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
    addQuestionOption,
    deleteQuestion,
    deleteQuestionOption,
    setQuestionOptions,
    reorderQuestion,
    setQuestionAllowPhotoUpload,
    setQuestionAttachmentMandatory,
    setQuestionAnswerType,
    setQuestionMaxValue,
    setQuestionMinValue,
    setQuestionOptionText,
    setQuestionText,
    addOutcome,
    deleteOutcome,
    setOutcomeActionPresetId,
    setOutcomeRating,
    setQuestionIsMandatory,
    setQuestionIsHelpInfoRequired,
    setQuestionHelpInfoText,
    setQuestionOptionRequiresAdditionalInfo,
} from "../../../redux/actions/templateBuilderActions";
import { grey, red } from "@material-ui/core/colors";
import { Clear } from "@material-ui/icons";
import {
    answerTypeDropdownValues,
    answerTypes,
} from "../../constants/answerConstants";
import SectionOptions from "./SectionOptions";
import useActionPresets from "../../hooks/queries/useActionPresets";
import OutcomeItemWrapper from "./OutcomeItemWrapper";
import ChoiceOutcomeItem from "./ChoiceOutcomeItem";
import TabPanel from "../../../components/TabPanel";
import NumberOutcomeItem from "./NumberOutcomeItem";
import moduleAreaConstants from "../../constants/moduleAreaConstants";

const useStyles = makeStyles((theme) => ({
    questionWrapper: {
        backgroundColor: "white",
        borderRadius: "4px",
        border: `1px solid ${grey[400]}`,
    },
    container: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    headerWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    questionTextField: {
        width: "100%",
    },
    answerTypeSelect: {
        maxWidth: "300px",
        width: "100%",
    },
    allowPhotoUploadCheckbox: {
        marginRight: 0,
    },
    gridContainer: {
        marginTop: theme.spacing(2),
    },
    numberValidationContainer: {
        display: "flex",
        gap: theme.spacing(2),
    },
    questionDetailsRow: {
        display: "flex",
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
        flexWrap: "wrap",
    },
    helpInfoText: {
        flex: 1,
    },
    questionOption: {
        display: "flex",
        gap: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    questionOptionsContainer: {
        marginTop: theme.spacing(2),
    },
    optionsList: {
        listStyle: "none",
        padding: 0,
        margin: 0,
    },
    deleteOptionButton: {
        color: red[500],
    },
    tabIntroText: {
        marginBottom: theme.spacing(2),
    },
    outcomesList: {
        listStyle: "none",
        padding: 0,
        margin: 0,
    },
}));

const questionTabs = {
    validation: {
        value: 1,
        label: "Validation",
    },
    outcomes: {
        value: 2,
        label: "Outcomes",
    },
};

const getTabsForAnswerType = (answerType, isIncidentForm, isInvestigation) => {
    switch (answerType) {
        case answerTypes.TEXT:
        case answerTypes.DATE:
            return [];
        case answerTypes.RADIO:
        case answerTypes.DROPDOWN:
        case answerTypes.MULTISELECT: {
            return isIncidentForm && !isInvestigation ? [] : [questionTabs.outcomes];
        }
        case answerTypes.NUMBER:
            return isIncidentForm && !isInvestigation
                ? [questionTabs.validation]
                : [questionTabs.validation, questionTabs.outcomes];
        default:
            return [];
    }
};

const dropdownChoiceOptions = ["Option 1", "Option 2"];
const radioChoiceOptions = ["Yes", "No", "N/A"];
const multiselectChoiceOptions = ["Option 1", "Option 2"];

function SectionQuestion({
    questionId,
    sectionId,
    isFirst,
    isLast,
    index,
    subjectId,
    isInvestigation,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);

    const { selectedSite, activeAppId, appSiteIds, selectedAccount } = useSelector((state) => state.account);

    const currentAccountId = selectedAccount?.account?.externalId;

    const siteExternalIds = selectedSite
        ? [selectedSite.externalId]
        : appSiteIds[activeAppId];

    const { questions, options, applicationArea } = useSelector((state) => state.templateBuilder);
    const {
        data: actionPresetsData,
        isLoading: isLoadingActionPresets,
        isError: isActionPresetsError,
    } = useActionPresets({ applicationArea, subjectId, accountId: currentAccountId, siteExternalIds, });

    const question = questions[questionId];

    const isIncidentForm = applicationArea === moduleAreaConstants.INCIDENTS;

    const answerTypeHasOptions =
        question.answerType === answerTypes.DROPDOWN ||
        question.answerType === answerTypes.RADIO ||
        question.answerType === answerTypes.MULTISELECT;

    const handleAnswerTypeChange = (e) => {
        const answerType = e.target.value;

        dispatch(setQuestionAnswerType({ questionId, answerType: answerType }));

        const hasNoOptions = question.optionIds?.length === 0;

        if (hasNoOptions && answerType === answerTypes.RADIO)
            dispatch(setQuestionOptions({ questionId, options: radioChoiceOptions }));
        else if (hasNoOptions && answerType === answerTypes.DROPDOWN)
            dispatch(setQuestionOptions({ questionId, options: dropdownChoiceOptions }));
        else if (hasNoOptions && answerType === answerTypes.MULTISELECT)
            dispatch(setQuestionOptions({ questionId, options: multiselectChoiceOptions }));
    };

    const handleOutcomeActionPresetChange = ({ outcomeId, actionPresetId }) => {
        dispatch(setOutcomeActionPresetId({ outcomeId, actionPresetId }));
    };

    const handleOutcomeRatingChange = ({ outcomeId, rating }) => {
        dispatch(setOutcomeRating({ outcomeId, rating }));
    };

    return (
        <div className={classes.questionWrapper}>
            <div className={classes.headerWrapper}>
                <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
                    <Tab label="Details" value={0} />
                    {getTabsForAnswerType(question.answerType, isIncidentForm, isInvestigation)
                        .map((x) =><Tab key={x.value} label={x.label} value={x.value} />)}
                </Tabs>
                <SectionOptions
                    isFirst={isFirst}
                    isLast={isLast}
                    onMoveUp={() => dispatch(reorderQuestion({ sectionId, startIndex: index, endIndex: index - 1 }))}
                    onMoveDown={() => dispatch(reorderQuestion({ sectionId, startIndex: index, endIndex: index + 1 }))}
                    onDelete={() => dispatch(deleteQuestion({ questionId, sectionId }))}
                />
            </div>
            <div className={classes.container}>
                <TabPanel value={0} index={activeTab}>
                    <TextField
                        className={classes.questionTextField}
                        required
                        name={`question-name-${questionId}`}
                        id={`question-name-${questionId}`}
                        label="Question Text"
                        variant="outlined"
                        size="small"
                        value={question.text}
                        onChange={(e) => dispatch(setQuestionText({ questionId, text: e.target.value }))}
                    />
                    <div className={classes.questionDetailsRow}>
                        {isIncidentForm && (
                            <>
                                <FormControlLabel
                                    className={classes.allowPhotoUploadCheckbox}
                                    control={
                                        <Checkbox
                                            checked={question.isMandatory}
                                            onChange={(e) => dispatch(setQuestionIsMandatory({ questionId, isMandatory: e.target.checked }))}
                                        />
                                    }
                                    label="Question is mandatory?"
                                />
                                <FormControlLabel
                                    className={classes.allowPhotoUploadCheckbox}
                                    control={
                                        <Checkbox
                                            checked={question.isHelpInfoRequired}
                                            onChange={(e) => dispatch(setQuestionIsHelpInfoRequired({ questionId, isHelpInfoRequired: e.target.checked }))}
                                        />
                                    }
                                    label="Is help info required?"
                                />
                                {question.isHelpInfoRequired && (
                                    <TextField
                                        className={classes.helpInfoText}
                                        size="small"
                                        variant="outlined"
                                        value={question.helpInfoText}
                                        label="Help info text"
                                        inputProps={{ "data-testid": `help-info-text-input-${questionId}` }}
                                        onChange={(e) => dispatch(setQuestionHelpInfoText({ questionId, helpInfoText: e.target.value }))}
                                    />
                                )}
                            </>
                        )}
                        <FormControlLabel
                            className={classes.allowPhotoUploadCheckbox}
                            control={
                                <Checkbox
                                    checked={question.allowPhotoUpload}
                                    onChange={(e) => dispatch(setQuestionAllowPhotoUpload({ questionId, allowPhotoUpload: e.target.checked }))}
                                />
                            }
                            label="Can add an attachment?"
                        />
                        {question.allowPhotoUpload && 
                            <FormControlLabel
                                className={classes.allowPhotoUploadCheckbox}
                                control={
                                    <Checkbox
                                        checked={question.attachmentMandatory}
                                        onChange={(e) => dispatch(setQuestionAttachmentMandatory({ questionId, attachmentMandatory: e.target.checked }))}
                                    />
                                }
                                label="Attachment is mandatory?"
                            />
                        }
                    </div>
                    <div className={classes.questionDetailsRow}>
                        <FormControl
                            className={classes.answerTypeSelect}
                            required
                            variant="outlined"
                            size="small"
                        >
                            <InputLabel id={`answer-type-select-label-${questionId}`}>
                                Answer Type
                            </InputLabel>
                            <Select
                                labelId={`answer-type-select-label-${questionId}`}
                                label="Answer Type"
                                value={question.answerType}
                                onChange={handleAnswerTypeChange}
                                inputProps={{
                                    "data-testid": `answer-type-select-${questionId}`,
                                }}
                            >
                                {answerTypeDropdownValues.map((answerType) => 
                                    <MenuItem key={answerType.value} value={answerType.value}>
                                        {answerType.label}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    {answerTypeHasOptions && (
                        <div className={classes.questionOptionsContainer}>
                            <ul className={classes.optionsList}>
                                {question.optionIds?.map((optionId, optionIndex) => (
                                    <li key={optionIndex} className={classes.questionOption}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            value={options[optionId].option}
                                            required
                                            onChange={(e) => dispatch(setQuestionOptionText({ optionId, text: e.target.value }))}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={options[optionId].requiresAdditionalInfo}
                                                    onChange={(e) => dispatch(setQuestionOptionRequiresAdditionalInfo({ optionId, requiresAdditionalInfo: e.target.checked}))}
                                                />
                                            }
                                            label="Requires additional info?"
                                        />
                                        <IconButton
                                            disabled={question.optionIds.length === 2}
                                            className={classes.deleteOptionButton}
                                            size="small"
                                            onClick={() => dispatch(deleteQuestionOption({ questionId, optionId }))}
                                        >
                                            <Clear />
                                        </IconButton>
                                    </li>
                                ))}
                            </ul>
                            <Button
                                variant="outlined"
                                size="small"
                                color="secondary"
                                onClick={() => dispatch(addQuestionOption(questionId))}
                            >
                                Add option
                            </Button>
                        </div>
                    )}
                </TabPanel>
                <TabPanel value={questionTabs.validation.value} index={activeTab}>
                    {question.answerType === answerTypes.NUMBER && (
                        <div className={classes.numberValidationContainer}>
                            <TextField
                                variant="outlined"
                                size="small"
                                type="number"
                                label="Minimum value"
                                value={question.rangeRestriction?.minValue}
                                onChange={(e) => dispatch(setQuestionMinValue({ questionId, minValue: parseFloat(e.target.value) }))}
                            />
                            <TextField
                                variant="outlined"
                                size="small"
                                type="number"
                                label="Maximum value"
                                value={question.rangeRestriction?.maxValue}
                                onChange={(e) => dispatch(setQuestionMaxValue({questionId, maxValue: parseFloat(e.target.value)}))}
                            />
                        </div>
                    )}
                </TabPanel>
                <TabPanel value={questionTabs.outcomes.value} index={activeTab}>
                    <Typography className={classes.tabIntroText}>
                        Add outcomes below to perform actions based on the response to the
                        question.
                    </Typography>

                    <ul className={classes.outcomesList}>
                        {question.outcomeIds.map((outcomeId) => (
                            <OutcomeItemWrapper
                                key={outcomeId}
                                subjectId={subjectId}
                                onActionPresetAdded={(newActionPresetId) => handleOutcomeActionPresetChange({outcomeId, actionPresetId: newActionPresetId })}
                                onDelete={() => dispatch(deleteOutcome({ questionId, outcomeId }))}
                            >
                                {question.answerType === answerTypes.RADIO ||
                                    question.answerType === answerTypes.DROPDOWN ||
                                    question.answerType === answerTypes.MULTISELECT ? (
                                    <ChoiceOutcomeItem
                                        outcomeId={outcomeId}
                                        questionId={questionId}
                                        actionPresets={actionPresetsData}
                                        isActionPresetsError={isActionPresetsError}
                                        isLoadingActionPresets={isLoadingActionPresets}
                                        onActionRatingChange={(rating) => handleOutcomeRatingChange({ outcomeId, rating: rating })}
                                        onActionPresetChange={(actionPresetId) => handleOutcomeActionPresetChange({ outcomeId, actionPresetId: actionPresetId })}
                                    />
                                ) : question.answerType === answerTypes.NUMBER ? (
                                    <NumberOutcomeItem
                                        outcomeId={outcomeId}
                                        actionPresets={actionPresetsData}
                                        isActionPresetsError={isActionPresetsError}
                                        isLoadingActionPresets={isLoadingActionPresets}
                                        onActionPresetChange={(actionPresetId) =>
                                            handleOutcomeActionPresetChange({
                                                outcomeId,
                                                actionPresetId: actionPresetId,
                                            })
                                        }
                                    />
                                ) : null}
                            </OutcomeItemWrapper>
                        ))}
                    </ul>
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        disabled={applicationArea !== moduleAreaConstants.INCIDENTS && !subjectId}
                        onClick={() => dispatch(addOutcome({ questionId, answerType: question.answerType }))}
                    >
                        Add outcome
                    </Button>
                </TabPanel>
            </div>
        </div>
    );
}

export default SectionQuestion;
