import { useMutation } from "react-query";
import reactQueryUtils from "../../utils/reactQueryUtils";
import queryKeys from "../../constants/queryKeys";
import userAlertSettingService from "../../services/userAlertSettingService";

const useUpdateUserAlertSetting = () =>
  useMutation(userAlertSettingService.update, {
    onMutate: async (userAlertSetting) => {
      const { client } = reactQueryUtils;
      const queryKey = [queryKeys.userAlertSetting, userAlertSetting.userExternalId];
      await client.cancelQueries(queryKey);

      const userAlertSettingBeforeChange = client.getQueryData(queryKey);

      client.setQueryData(queryKey, userAlertSetting);

      return { userAlertSettingBeforeChange };
    },
    onError: (error, userAlertSetting, { userAlertSettingBeforeChange }) => {
      const queryKey = [queryKeys.userAlertSetting, userAlertSetting.userExternalId];
      reactQueryUtils.client.setQueryData(queryKey, userAlertSettingBeforeChange);
    },
    onSettled: (_data, _error, userAlertSetting) => {
      if (userAlertSetting)
        reactQueryUtils.client.invalidateQueries([
          queryKeys.userAlertSetting, 
          userAlertSetting.userExternalId,
        ]);
    },
  });

export default useUpdateUserAlertSetting;
