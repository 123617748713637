import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import accountService from "../../services/accountService";

const useConsultantAccounts = () =>
  useQuery([queryKeys.consultantAccounts], () =>
    accountService.fetchAccountsForConsultant()
  );

export default useConsultantAccounts;
