import { useMutation } from "react-query";
import salesforceService from "../../services/salesforceService";
import reactQueryUtils from "../../utils/reactQueryUtils";
import queryKeys from "../../constants/queryKeys";

const useAddUserToSite = () =>
  useMutation(salesforceService.addAccountContactRelation, {
    onMutate: async ({ newUser, accountId }) => {
      const { client } = reactQueryUtils;
      const queryKey = [queryKeys.siteUsers, accountId];
      await client.cancelQueries(queryKey);

      const usersBeforeChange = client.getQueryData(queryKey);

      client.setQueryData(queryKey, (siteUsers) => ({
        ...siteUsers,
        [newUser.externalId]: newUser,
      }));

      return { usersBeforeChange };
    },
    onError: (error, { accountId }, { usersBeforeChange }) => {
      const queryKey = [queryKeys.siteUsers, accountId];
      reactQueryUtils.client.setQueryData(queryKey, usersBeforeChange);
    },
    onSettled: (data, error, { accountId }) => {
      if (accountId)
        reactQueryUtils.client.invalidateQueries([
          queryKeys.siteUsers,
          accountId,
        ]);
    },
  });

export default useAddUserToSite;
