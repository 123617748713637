import React from "react";
import { ListItem, ListItemIcon, ListItemText, List, makeStyles } from "@material-ui/core";
import { Description, Image, Videocam } from "@material-ui/icons";
import { fileExtensions } from "../../constants/documentConstants";
import { useSelector } from "react-redux";
import { useDrag } from 'react-dnd';
import { documentTypes } from "../../constants/documentConstants";

const useStyles = makeStyles(() => ({
  text: {
    overflowWrap: "break-word"
  }
}))

const DocumentItem = ({ documentName, setSelectedFile, selectedFile, documentType }) => {

    const classes = useStyles();
    const { selectedFolder } = useSelector((state) => state.documents);

    const [, drag] = useDrag(
        () => ({
            type: 'file',
            item: () => {
                console.log({ documentName, from: selectedFolder, drag: true });
                return { documentName, from: selectedFolder };
            },
            canDrag: documentType === documentTypes.ACCOUNT,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
    )

    return (
        <ListItem
            ref={drag}
            button
            selected={selectedFile.path === selectedFolder + "/" + documentName}
            onClick={() =>
                setSelectedFile({
                    name: documentName,
                    path: selectedFolder + "/" + documentName,
                })
            }
        >
            <ListItemIcon>
                {fileExtensions.image.reduce(
                    (prev, curr) => prev || documentName.endsWith(curr),
                    false
                ) ? (
                    <Image />
                ) : fileExtensions.video.reduce(
                    (prev, curr) => prev || documentName.endsWith(curr),
                    false
                ) ? (
                    <Videocam />
                ) : (
                    <Description />
                )}
            </ListItemIcon>
            <ListItemText primary={documentName} className={classes.text} />
        </ListItem>
    );
}

const DocumentList = ({ documentNames, setSelectedFile, selectedFile, documentType }) => {  

  return (
    <List disablePadding>
          {
              documentNames.map((documentName) => (
                  <DocumentItem
                      setSelectedFile={setSelectedFile}
                      selectedFile={selectedFile}
                      documentName={documentName}
                      key={documentName}
                      documentType={documentType}
                  />
              ))
          }
    </List>
  );
};

export default DocumentList;
