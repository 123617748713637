import React, { useEffect, useReducer, useState, useMemo } from "react";
import { AttachFile, CloudDownload, Delete } from "@material-ui/icons";
import {
    alpha,
    Button,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { nanoid } from "nanoid";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import useDownloadFormAttachment from "../hooks/queries/useDownloadFormAttachment";
import useDownloadIncidentAttachment from "../hooks/queries/useDownloadIncidentAttachment";
import incidentService from "../services/incidentService";
import templateFormHistoryService from "../services/templateFormHistoryService";
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    uploadsHeadingContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    uploadsHeading: {
        display: "flex",
        alignItems: "center",
    },
    uploadsIcon: {
        marginRight: theme.spacing(1),
    },
    uploadsListWrapper: {
        padding: theme.spacing(2),
        borderRadius: "8px",
        marginTop: theme.spacing(1),
        backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    },
    input: {
        display: "none",
    },
    uploadButton: {
        marginLeft: "auto",
    },
    uploadActionsContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
    },
}));

const actionTypes = {
    INIT: "init",
    UPLOAD_REQUEST: "upload-request",
    UPLOAD_ERROR: "upload-error",
    UPLOAD_SUCCESS: "upload-success",
    DELETE: "delete",
};

function filesReducer(state, action) {
    switch (action.type) {
        case actionTypes.INIT:
            return [...action.files];
        case actionTypes.UPLOAD_REQUEST: {
            const newFiles = action.files.map((newUpload) => ({
                syncId: newUpload.syncId,
                name: newUpload.file.name,
                type: newUpload.file.fileType,
                isUploading: true,
            }));
            return [...state, ...newFiles];
        }
        case actionTypes.UPLOAD_ERROR:
            return state.filter((x) => action.syncIds.includes(x.syncId));
        case actionTypes.UPLOAD_SUCCESS: {
            return state.map((x) => {
                const updatedUpload = action.files.find((f) => f.syncId === x.syncId);
                return updatedUpload
                    ? {
                        ...x,
                        ...updatedUpload,
                        isUploading: false,
                        toBeAdded: true,
                    }
                    : x;
            });
        }
        case actionTypes.DELETE:
            return state.map((x) =>
                x?.azureFileId === action.azureFileId
                    ? { ...x, toBeAdded: false, toBeDeleted: true }
                    : x
            );
        default:
            throw new Error("Unrecognised action!");
    }
}

function FileUploads({ files, onFilesChanged, showAddButton = true, required }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { templateFormId, templateFormHistoryId } = useParams();

    const [fileToDownload, setFileToDownload] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const downloadFile = useDownloadFormAttachment(
        { reference: fileToDownload?.upload?.reference, templateFormHistoryId },
        { onSuccess: onDownloadFileSuccess, onError: onDownloadFileError }
    );

    const downloadIncidentFile = useDownloadIncidentAttachment(
        {
            reference: fileToDownload?.isIncident
                ? fileToDownload?.upload?.reference
                : null,
        },
        { onSuccess: onDownloadFileSuccess, onError: onDownloadFileError }
    );

    const [_files, filesDispatch] = useReducer(filesReducer, files ?? []);    

    const hasAttachments = _files.filter((x) => !x?.toBeDeleted).length > 0;

    useEffect(() => {
        if (files?.length)
            filesDispatch({
                type: actionTypes.INIT,
                files,
            });
    }, [files]);

    useEffect(() => {
        const newFiles = _files.filter((x) => !!x?.azureFileId);
        onFilesChanged(newFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_files]);

    function onDownloadFileSuccess(data) {
        if (!fileToDownload) {
            console.error("Could not download file. File in state is missing!");
            return;
        }

        let blob = new Blob([data]);
        let link = window.document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileToDownload.upload.name;
        link.click();
        setFileToDownload(null);
    }

    function onDownloadFileError(e) {
        console.error(e);
        enqueueSnackbar("Could not download file.", { variant: "error" });
    }

    const handleDeleteFile = (azureFileId) => {
        filesDispatch({ type: actionTypes.DELETE, azureFileId });
    };

    const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
    };

    const handleChangeFile = async (e) => {
        const files = e.target.files;

        if (files.length) {
            const mappedFiles = [...files].map((file) => ({
                syncId: nanoid(5),
                file,
            }));

            filesDispatch({
                type: actionTypes.UPLOAD_REQUEST,
                files: mappedFiles,
            });

            try {
                let uploadResponse;
                if (templateFormId) {
                    uploadResponse = await templateFormHistoryService.uploadAttachments({
                        templateFormId,
                        files: mappedFiles,
                        onUploadProgress,
                    });
                } else {
                    uploadResponse = await incidentService.uploadAttachments({
                        files: mappedFiles,
                        onUploadProgress,
                    });
                }

                filesDispatch({
                    type: actionTypes.UPLOAD_SUCCESS,
                    files: uploadResponse,
                });
            } catch (e) {
                console.error(e);
                filesDispatch({
                    type: actionTypes.UPLOAD_ERROR,
                    syncIds: mappedFiles.map((x) => x.syncId),
                });
                enqueueSnackbar("Could not upload file", { variant: "error" });
            } finally {
                setUploadProgress(0);
            }
        }
    };

    const filesToKeep = useMemo(() => _files.filter((x) => !x?.toBeDeleted), [_files]);

    const uploadAction = (upload) => {
        if (upload.isUploading) {
            return uploadProgress < 100 ? (
                <CircularProgress
                    size={20}
                    variant="determinate"
                    value={uploadProgress}
                />
            ) : (
                <CircularProgress size={20} />
            );
        }

        if (
            fileToDownload?.upload?.reference === upload.reference &&
            (downloadFile.isLoading || downloadIncidentFile.isLoading)
        )
            return <CircularProgress size={20} />;

        return (
            <>
                <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() => setFileToDownload({ upload, isIncident: !templateFormId && !templateFormHistoryId })}
                >
                    <CloudDownload />
                </IconButton>
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteFile(upload.azureFileId)}
                >
                    <Delete />
                </IconButton>
            </>
        );
    };

    return (
        <>
            <div className={classes.uploadsHeadingContainer}>
                {hasAttachments && (
                    <div className={classes.uploadsHeading}>
                        <AttachFile fontSize="small" className={classes.uploadsIcon} />
                        <Typography variant="body1">Attached Files</Typography>
                    </div>
                )}
                {showAddButton && (
                    <Button
                        className={classes.uploadButton}
                        variant="text"
                        color="secondary"
                        component="label"
                    >
                        Add attachment
                        <input
                            type="file"
                            multiple
                            className={classes.input}
                            onChange={handleChangeFile}
                        />
                    </Button>
                )}
            </div>
            {required && filesToKeep.length === 0 && <Alert severity="error">Adding an attachment is required</Alert>}
            {hasAttachments && (
                <div className={classes.uploadsListWrapper}>
                    <List dense>
                        {filesToKeep
                            .map((upload, index) => (
                                <ListItem key={`${upload.name}-${index}`}>
                                    <ListItemIcon>
                                        <AttachFile />
                                    </ListItemIcon>
                                    <ListItemText primary={upload.name} />
                                    <ListItemSecondaryAction className={classes.uploadActionsContainer}>
                                        {uploadAction(upload)}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                    </List>
                </div>
            )}
        </>
    );
}

export default FileUploads;
