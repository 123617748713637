import {
  Box,
  CircularProgress,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React from "react";
import useLatestBlogPosts from "../../hooks/queries/useLatestBlogPosts";
import BlogPostCard from "./BlogPostCard";
import { Facebook, Twitter, LinkedIn } from "@material-ui/icons";
import Carousel, {
  autoplayPlugin,
  slidesToShowPlugin,
  arrowsPlugin,
} from "@brainhubeu/react-carousel";
import PreviousButton from "./PreviousButton";
import NextButton from "./NextButton";
import ErrorBox from "../ErrorBox";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    backgroundColor: "#fff",
    borderRadius: "4px",
  },
  carousel: {
    "& .BrainhubCarouselItem": {
      alignItems: "unset !important",
    },
  },
  headingContainer: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    padding: theme.spacing(2),
    color: "#fff",
  },
  heading:{
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  footerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: grey[100],
    padding: theme.spacing(2),
    borderTop: `1px solid ${grey[300]}`,
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  footerCopy: {
    fontWeight: 400,
    fontSize: "1.125rem",
  },
  loadingContainer: {
    width: "100%",
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  socialIcon: {
    fontSize: 40,
    transition: "all 0.2s ease-out",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const LatestBlogPosts = () => {
  const classes = useStyles();
  const { data, isLoading, isError, error } = useLatestBlogPosts();

  if (isLoading)
    return (
      <Box className={classes.loadingContainer}>
        <CircularProgress color="primary" />
      </Box>
    );

  if (isError)
    return (
      <ErrorBox message={error.message} />
    );

  return (
    <Paper>
      <div className={classes.headingContainer}>
        <Typography variant="h6" className={classes.heading}>Latest updates from WorkNest</Typography>
      </div>
      <Carousel
        className={classes.carousel}
        plugins={[
          "infinite",
          { resolve: autoplayPlugin, options: { interval: 6000 } },
          { resolve: slidesToShowPlugin, options: { numberOfSlides: 3 } },
          {
            resolve: arrowsPlugin,
            options: {
              arrowLeft: <PreviousButton color="primary" />,
              arrowLeftDisabled: <PreviousButton disabled />,
              arrowRight: <NextButton color="primary" />,
              arrowRightDisabled: <NextButton disabled />,
              addArrowClickHandler: true,
            },
          },
        ]}
        animationSpeed={1000}
        breakpoints={{
          960: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1,
                },
              },
            ],
          },
          1280: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 2,
                },
              },
            ],
          },
        }}
      >
        {data.map((blogPost, index) => (
          <BlogPostCard key={index} blogPost={blogPost} />
        ))}
      </Carousel>
      <div className={classes.footerContainer}>
        <Typography className={classes.footerCopy} variant="h6">
          Follow us for our latest updates
        </Typography>
        <Box display="flex" gridGap={16} flexWrap="wrap">
          <Link href="https://www.facebook.com/WorkNestGroup/">
            <Facebook className={classes.socialIcon} color="secondary" />
          </Link>
          <Link href="https://twitter.com/WorkNestGroup">
            <Twitter className={classes.socialIcon} color="secondary" />
          </Link>
          <Link href="https://www.linkedin.com/company/worknestgroup">
            <LinkedIn className={classes.socialIcon} color="secondary" />
          </Link>
        </Box>
      </div>
    </Paper>
  );
};

export default LatestBlogPosts;
