import React from "react";
import {
  Box,
  Button,
  Divider,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { grey } from "@material-ui/core/colors";
import DescriptionIcon from "@material-ui/icons/Description";
import VideocamRoundedIcon from "@material-ui/icons/VideocamRounded";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    padding: theme.spacing(2),
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  cardFooter: {
    padding: theme.spacing(2),
    backgroundColor: grey[50],
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    gap: theme.spacing(2),
  },
  link: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const iconsMap = {
  article: DescriptionIcon,
  webinar: VideocamRoundedIcon,
  precedent: AttachFileIcon,
};

const ContentCard = ({ title, content, route, type }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleViewClick = () => {
    history.push(route);
  };

  const Icon = iconsMap[type];

  return (
    <Paper className={classes.card}>
      <Box className={classes.cardHeader}>
        <Link className={classes.link} component={RouterLink} to={route}>
          {Icon && <Icon className={classes.icon} />}
          <Typography>{title}</Typography>
        </Link>
      </Box>
      {content && (
        <>
          <Divider />
          <Box className={classes.cardContent}>
            <Typography>{content}</Typography>
          </Box>
        </>
      )}
      <Divider />
      <Box className={classes.cardFooter}>
        <Button onClick={handleViewClick} color="primary" variant="outlined">
          View
        </Button>
      </Box>
    </Paper>
  );
};

export default ContentCard;
