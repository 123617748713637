import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateService from "../../services/templateService";

const useRemainingIncidentTypes = (accountExternalId) =>
    useQuery(queryKeys.templates, () =>
        templateService.fetchRemainingIncidentTypes(accountExternalId),
        {
            enabled: !!accountExternalId,
        }
    );

export default useRemainingIncidentTypes;
