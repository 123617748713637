import queryKeys from "../../../constants/queryKeys";
import { useQuery } from "react-query";
import incidentService from "../../services/incidentService";

const useIncidentBodyPartStatsForSites = ({ siteExternalIds, fromDate }) =>
  useQuery(
    [queryKeys.incidentBodyParts, siteExternalIds?.join(","), fromDate],
    () =>
      incidentService.fetchBodyPartStatsForSites({
        externalIds: siteExternalIds,
        fromDate,
      }),
    {
      enabled: siteExternalIds?.length > 0,
    }
  );

export default useIncidentBodyPartStatsForSites;
