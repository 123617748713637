import React, { useState } from "react";
import {
    Chip,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { format } from "date-fns";
import { blue, green, orange, red } from "@material-ui/core/colors";
import useSiteAudits from "../hooks/queries/useSiteAudits";
import CardBase from "../../components/cards/CardBase";
import { useSelector } from "react-redux";
import { tableSortingOrder } from "../../constants/tableConstants";
import EnhancedTableHead from "../../components/table/EnhancedTableHead";
import { getComparator, stableSort } from "../../utils/tableUtils";
import { auditTypes } from "../../constants/auditConstants";
import { useSnackbar } from "notistack";
import { downloadFileFromBlob } from "../../utils/fileUtils";
import CustomButton from "../../components/CustomButton";
import useDownloadAuditPdf from "../hooks/queries/useDownloadAuditPdf";

const useStyles = makeStyles(() => ({
    statusChip: {
        color: "white",
        borderRadius: "4px",
        "&.status-0": {
            backgroundColor: orange[400],
        },
        "&.status-1": {
            backgroundColor: red[400],
        },
        "&.status-2": {
            backgroundColor: green[400],
        },
        "&.status-3": {
            backgroundColor: blue[400],
        },
    },
}));

const headCells = [
    {
        id: 'id',
        label: 'Audit Id',
        isSortable: true,
    },
    {
        id: "type",
        label: "Audit Type",
        isSortable: true,
    },
    {
        id: "siteExternalId",
        label: "Site",
        isSortable: true,
    },
    {
        id: "name",
        label: "Visit Name",
        isSortable: true,
    },
    {
        id: "startDate",
        label: "Audit Date",
        isSortable: true,
    },
    {
        id: "lastModifiedDate",
        label: "Last Modified Date",
        isSortable: true,
    },
    {
        id: "status",
        label: "Status",
        isSortable: true,
    },
    {
        id: "completedActionsSummary",
        label: "Completed Actions / Total Actions",
        isSortable: true,
    },
    {
        id: "consultant.fullName",
        label: "Auditor",
        isSortable: true,
    },
    {
        id: "Action",
    },
];

function AuditsTableCard({ auditType }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [order, setOrder] = useState(tableSortingOrder.asc);
    const [orderBy, setOrderBy] = useState(headCells[3].id);
    const [downloadInfo, setDownloadInfo] = useState(null);
    const downloadFile = useDownloadAuditPdf(
        { auditId: downloadInfo?.auditId, isDriAudit: downloadInfo?.isDriAudit },
        { onSuccess: onDownloadSuccess, onError: onDownloadError }
    );
    const { selectedSite, activeAppId, appSiteIds, sites } = useSelector(
        (state) => state.account
    );
    const { data, isLoading, error, isFetching } = useSiteAudits({
        externalIds: selectedSite
            ? [selectedSite.externalId]
            : appSiteIds[activeAppId],
        auditType,
    });

    function onDownloadSuccess(data) {
        if (!downloadInfo?.filename) {
            console.error(
                "Could not download audit document. File in state is missing!"
            );
            return;
        }

        downloadFileFromBlob(data, downloadInfo.filename);
        setDownloadInfo(null);
    }

    function onDownloadError(error) {
        console.error(error);
        enqueueSnackbar("Could not download audit document.", { variant: "error" });
        setDownloadInfo(null);
    }

    const downloadFileForAudit = (audit) => {
        setDownloadInfo({
            auditId: audit.id,
            filename: audit.documents[0].name,
            isDriAudit: audit.isDriAudit,
        });
    };

    const handleSortRequest = (event, property) => {
        const isAsc = orderBy === property && order === tableSortingOrder.asc;
        setOrder(isAsc ? tableSortingOrder.desc : tableSortingOrder.asc);
        setOrderBy(property);
    };

    const tableContent = () => {
        if (data && data.length > 0) 
          return stableSort(data, getComparator(order, orderBy)).map((audit) => {
                const statusChipClass = clsx(
                    classes.statusChip,
                    `status-${audit.status}`
                );

                return (
                    <TableRow key={audit.id}>
                        <TableCell>
                            {audit.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {audit.typeDescription}
                        </TableCell>
                        <TableCell>{sites[audit.siteExternalId]?.name}</TableCell>
                        <TableCell>{audit.name || "-"}</TableCell>
                        <TableCell>
                            {format(new Date(audit.startDate), "dd/MM/yyyy")}
                        </TableCell>
                        <TableCell>
                            {format(new Date(audit.lastModifiedDate), "dd/MM/yyyy")}
                        </TableCell>
                        <TableCell>
                            <Chip
                                size="small"
                                className={statusChipClass}
                                label={audit.statusDescription}
                            />
                        </TableCell>
                        <TableCell>
                            {audit.completedActionsSummary}
                        </TableCell>
                        <TableCell>{audit.consultant?.fullName ?? "N/A"}</TableCell>
                        <TableCell align="right">
                            {audit.documents.length > 0 && (
                                <CustomButton
                                    isLoading={
                                        downloadFile.isLoading &&
                                        downloadInfo?.auditId === audit.id &&
                                        downloadInfo?.isDriAudit === audit.isDriAudit
                                    }
                                    onClick={() => downloadFileForAudit(audit)}
                                    variant="text"
                                    size="small"
                                    color="primary"
                                >
                                    View
                                </CustomButton>
                            )}
                        </TableCell>
                    </TableRow>
                );
            });

        return (
            <TableRow>
                <TableCell colSpan={headCells.length}>
                    <Typography>No audit data.</Typography>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <CardBase
            title="Audits"
            description={`A list of audits for ${auditType === auditTypes.GRA
                    ? "General Risk Assessments and Workplace Inspections"
                    : "Fire Risk Assessments"
                }.`}
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
        >
            <TableContainer>
                <Table aria-label="audit-table">
                    <EnhancedTableHead
                        headCells={headCells}
                        sortDirection={order}
                        sortColumn={orderBy}
                        onRequestSort={handleSortRequest}
                    />
                    <TableBody>{tableContent()}</TableBody>
                </Table>
            </TableContainer>
        </CardBase>
    );
}

export default AuditsTableCard;
