import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import prismicService from "../../services/prismicService";

const usePrismicCategoryWebinars = (sectionName, categoryId, pageNumber, searchTerm) =>
    useQuery(
        [queryKeys.prismicCategoryWebinars, categoryId, pageNumber, searchTerm],
        () => prismicService.fetchCategoryWebinars(sectionName, categoryId, pageNumber, searchTerm)
    );

export default usePrismicCategoryWebinars;