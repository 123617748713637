import React, { useState } from "react";
import useModuleTemplates from "../hooks/queries/useModuleTemplates";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  Tooltip,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import EnhancedTableHead from "../../components/table/EnhancedTableHead";
import moduleAreas from "../constants/moduleAreaConstants";
import CardBase from "../../components/cards/CardBase";
import ArchiveTemplateDialog from "./dialogs/ArchiveTemplateDialog";
import { Archive, Edit, History, MoreVert } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import TemplateNameCell from "./TemplateNameCell";
import { Link, useRouteMatch } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import TemplateVersionHistoryDialog from "./riskassessments/dialogs/TemplateVersionHistoryDialog";
import { setTablePage } from "../../redux/actions/tablePageActions";
import { tablePage } from "../constants/tablePageConstants";
import { sortOrderName } from "../constants/sortOrder";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    gap: theme.spacing(1),
    fontSize: "0.875rem",
  },
}));

const headCells = [
  {
    id: "name",
        label: "Name",
        isSortable: true,
  },
  {
    id: "sector",
      label: "Sector",
      isSortable: true,
  },
  {
    id: "description",
      label: "Description",
      isSortable: true,
    },
    {
        id: "site",
        label: "Site",
        isSortable: false,
    },    
  {
    id: "actions",
      label: "Actions",
  },
];

const tableKey = tablePage.RISK_ASSESSMENTS_ADMIN_TEMPLATES_TABLE;

const hideOptionForClientUserIfTemplateIsGlobal = (
  siteExternalId,
  isWorkNestUser
) => (!siteExternalId && isWorkNestUser) || siteExternalId;

function RiskAssessmentAdminTemplatesTableCard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [archiveTemplateId, setArchiveTemplateId] = useState(null);

  const { selectedSite, activeAppId, appSiteIds, selectedAccount, sites } =
    useSelector((state) => state.account);
  const { riskAssessmentsAdminTemplatesTable } = useSelector(
    (state) => state.tablePage
  );
  const currentAccountId = selectedAccount?.account?.externalId;
  const siteExternalIds = selectedSite
    ? [selectedSite.externalId]
    : appSiteIds[activeAppId];

  const match = useRouteMatch();
  const { path } = match;
  const { isWorknestUser } = useAuth();

  const templates = useModuleTemplates({
    moduleArea: moduleAreas.RISKASSESSMENTS,
    pageNum: riskAssessmentsAdminTemplatesTable.page,
    rowsPerPage: riskAssessmentsAdminTemplatesTable.rowsPerPage,
    accountId: currentAccountId,
    siteExternalIds,
    orderByColumn: riskAssessmentsAdminTemplatesTable.sortBy || headCells[0].id,
    sortOrder: riskAssessmentsAdminTemplatesTable.orderBy || sortOrderName.ASC,
    frequency: null,
    sectorId: null,
    subjectId: null,
    templateType: null,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [versionDialogOpen, setVersionDialogOpen] = useState(false);

  function handleCloseDialog() {
    setVersionDialogOpen(false);
  }

  function handleOpenVersionDialog(id) {
    setSelectedTemplateId(id);
    setVersionDialogOpen(true);
    setOpen(false);
  }

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleRowsPerPageChange = (e) => {
    dispatch(
      setTablePage({
        key: tableKey,
        page: 0,
        rowsPerPage: parseInt(e.target.value),
      })
    );
  };

  const handlePageChange = (e, newPage) => {
    dispatch(
      setTablePage({
        key: tableKey,
        page: newPage,
        rowsPerPage: riskAssessmentsAdminTemplatesTable.rowsPerPage,
      })
    );
  };

    const handleSortClick = (event, sortColumn) => {
        const isAsc = riskAssessmentsAdminTemplatesTable.sortBy === sortColumn && riskAssessmentsAdminTemplatesTable.orderBy === sortOrderName.ASC;
        dispatch(
            setTablePage({
                ...riskAssessmentsAdminTemplatesTable,
                key: tableKey,
                page: 0,
                sortBy: sortColumn,
                orderBy: isAsc ? sortOrderName.DESC : sortOrderName.ASC,
            })
        );
    };

  const tableContent = () => {
    if (templates.data?.results?.length > 0) {
      return templates.data.results.map((t) => (
        <TableRow key={t.id}>
          <TableCell>
            <TemplateNameCell name={t.name} isWorkNest={!t.siteExternalId} />
          </TableCell>
          <TableCell>{t.sector}</TableCell>
          <TableCell>{t.description}</TableCell>
          <TableCell>{sites[t?.siteExternalId]?.name ?? "All"}</TableCell>
          <TableCell>
            <Tooltip title="More">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => handleClick(e, t.id)}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open === t.id}
              onClose={handleClose}
            >
              <MenuItem
                className={classes.menuItem}
                component={Link}
                to={`${path}/templatebuilder/edit/${t.templateVersionId}`}
              >
                <Edit />
                {isWorknestUser || t.siteExternalId ? "Edit Template" : "Duplicate and Edit Template"}
              </MenuItem>
              {hideOptionForClientUserIfTemplateIsGlobal(
                t.siteExternalId,
                isWorknestUser
              ) && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => setArchiveTemplateId(t.id)}
                >
                  <Archive />
                  Archive Template
                </MenuItem>
              )}
              <MenuItem
                className={classes.menuItem}
                onClick={() => handleOpenVersionDialog(t.id)}
              >
                <History />
                Version History
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
      ));
    }

    return (
      <TableRow>
        <TableCell colSpan={headCells.length}>
          <Typography>No templates have been added.</Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <TemplateVersionHistoryDialog
        onClose={handleCloseDialog}
        open={versionDialogOpen}
        templateId={selectedTemplateId}
      />
      <CardBase
        title="Risk Assessment Templates"
        description="A list of all available risk assessment templates."
        isLoading={templates.isLoading}
        isFetching={templates.isFetching}
        error={templates.error}
      >
        <TableContainer>
          <Table aria-label="Risk Assessment">
                      <EnhancedTableHead
                          headCells={headCells}
                          sortColumn={riskAssessmentsAdminTemplatesTable.sortBy}
                          sortDirection={riskAssessmentsAdminTemplatesTable.orderBy || sortOrderName.DESC}
                          onRequestSort={handleSortClick}
                      />
            <TableBody>{tableContent()}</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={templates.data ? templates.data.totalCount : 0}
          rowsPerPage={riskAssessmentsAdminTemplatesTable.rowsPerPage}
          page={riskAssessmentsAdminTemplatesTable.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
        <ArchiveTemplateDialog
          open={!!archiveTemplateId}
          onCancel={() => setArchiveTemplateId(null)}
          archiveTemplateId={archiveTemplateId}
          applicationArea={moduleAreas.RISKASSESSMENTS}
          page={riskAssessmentsAdminTemplatesTable.page}
          rows={riskAssessmentsAdminTemplatesTable.rowsPerPage}
          accountId={currentAccountId}
          siteExternalIds={siteExternalIds}
        />
      </CardBase>
    </>
  );
}

export default RiskAssessmentAdminTemplatesTableCard;
