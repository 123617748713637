import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { RichText } from "prismic-reactjs";
import React, { useEffect } from "react";
import { useRouteMatch } from "react-router";
import { useTracking } from "react-tracking";
import KnowledgeHubBreadcrumbs from "../../components/knowledgeHub/KnowledgeHubBreadcrumbs";
import trackingConstants from "../../constants/trackingConstants";
import usePrismicContentItem from "../../hooks/queries/usePrismicContentItem";
import fileUtils from "../../utils/fileUtils";
import ErrorBox from "../../components/ErrorBox";
import PageBase from "../../components/PageBase";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    backgroundColor: grey[50],
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  cardContent: {
    padding: theme.spacing(2),
    "& pre": {
      whiteSpace: "pre-wrap",
    },
  },
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
}));

const HubItemDetails = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { params } = match;
  const { sectionName, itemId, cachePage, itemType, categoryId } = params;
  const { trackEvent } = useTracking();
  const { data, isLoading, isError, error } = usePrismicContentItem(
    sectionName,
    itemId,
    itemType,
    categoryId,
    cachePage
  );

  useEffect(() => {
    if (data)
      trackEvent({
        page: `KnowledgeHub:${sectionName}:${data.type}:${data.title}`,
        event: trackingConstants.events.pageView,
      });
  }, [data, sectionName, trackEvent]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDownload = () => {
    trackEvent({
      event: `${trackingConstants.events.downloadFile}: ${data.title}`,
    });
    fileUtils.downloadFileByUrl(data.downloadUrl);
  };

  if (isLoading)
    return (
      <Box className={classes.wrapper}>
        <CircularProgress color="primary" />
      </Box>
    );

  if (isError)
    return (
      <ErrorBox message={error?.message || "Could not load item details."} />
    );

  return (
    <PageBase>
      <Container maxWidth="lg">
        <KnowledgeHubBreadcrumbs />
        <Paper>
          <Box className={classes.cardHeader}>
            <Typography>{data.title}</Typography>
            {data?.downloadUrl && (
              <Button
                onClick={handleDownload}
                color="primary"
                variant="outlined"
              >
                Download
              </Button>
            )}
          </Box>
          <Divider />
          <Box className={classes.cardContent}>
            {data?.introText && RichText.render(data.introText)}
            {data?.embed && (
              <div
                dangerouslySetInnerHTML={{
                  __html: data.embed.html,
                }}
              />
            )}
            {data?.content && RichText.render(data.content)}
          </Box>
        </Paper>
      </Container>
    </PageBase>
  );
};

export default HubItemDetails;
