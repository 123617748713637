export const numberOutcomeLogic = {
  lessThanOrEqualTo: 0,
  greaterThanOrEqualTo: 1,
  lessThan: 2,
  greaterThan: 3,
  equalTo: 4,
  between: 5,
};

export const numberOutcomeLogicOptions = [
  {
    value: numberOutcomeLogic.lessThan,
    label: "Less than",
  },
  {
    value: numberOutcomeLogic.lessThanOrEqualTo,
    label: "Less than or equal to",
  },
  {
    value: numberOutcomeLogic.equalTo,
    label: "Equal to",
  },
  {
    value: numberOutcomeLogic.greaterThanOrEqualTo,
    label: "Greater than or equal to",
  },
  {
    value: numberOutcomeLogic.greaterThan,
    label: "Greater than",
  },
  {
    value: numberOutcomeLogic.between,
    label: "Between",
  },
];

export const choiceOutcomeLogic = {
  is: 0,
  isNot: 1,
};

export const choiceOutcomeLogicOptions = [
  {
    value: choiceOutcomeLogic.is,
    label: "Is",
  },
  {
    value: choiceOutcomeLogic.isNot,
    label: "Is not",
  },
];

export const dateOutcomeLogic = {
  before: 0,
  after: 1,
  equalTo: 2,
};

export const multiselectOutcomeLogicOptions = [
  {
    value: choiceOutcomeLogic.is,
    label: "Contains",
  },
  {
    value: choiceOutcomeLogic.isNot,
    label: "Does not contain",
  },
];