import regex from "../constants/regex";

const removeFileExtension = (fileName) =>
  fileName.replace(regex.fileExtension, "");

const getFileExtension = (fileName) =>
  fileName.match(regex.fileExtension)?.[0] || "";

const fileNameUtils = {
  removeFileExtension,
  getFileExtension,
};

export default fileNameUtils;
