import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
    Typography,
    makeStyles,
    Box,
    CircularProgress,
    Paper,
    Button,
    Divider,
} from "@material-ui/core";
import { isAfter, isValid } from "date-fns";
import { grey } from "@material-ui/core/colors";
import { useSnackbar } from "notistack";
import useTemplateForm from "../hooks/queries/useTemplateForm";
import useSaveTemplateForm from "../hooks/mutations/useSaveTemplateForm";
import useCalculateFormActions from "../hooks/mutations/useCalculateFormActions";
import {
    clearForm,
    initialiseQuestionActions,
} from "../../redux/actions/formCompleterActions";
import { baseRoute } from "../routes";
import CompletedFormBuilder from "../utils/CompletedFormBuilder";
import ErrorBox from "../../components/ErrorBox";
import { formatShortDate } from "../../utils/dateTimeUtils";
import FormSection from "../components/formcompleter/FormSection";
import TemplateFormHistoryTable from "../components/compliance/templateForms/TemplateFormHistoryTable";
import DueDateWarningDialog from "../components/dialogs/DueDateWarningDialog";
import CardBase from "../../components/cards/CardBase";
import CreatedActions from "../components/formcompleter/CreatedActions";
import CustomButton from "../../components/CustomButton";
import PageBase from "../../components/PageBase";
import TemplateVersionHistoryDialog from "../components/riskassessments/dialogs/TemplateVersionHistoryDialog";
import useAddTemplateVersionView from "../hooks/mutations/useAddTemplateVersionView";
import FormComments from "../components/compliance/templateForms/FormComments";
import { frequencyInterval } from "../constants/frequencyConstants";
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
    dateContainer: {
        display: "flex",
        justifyContent: "center",
        border: `1px solid ${grey[400]}`,
        borderRadius: "4px",
    },
    historyTable: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    sections: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
    titleCard: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
        },
    },
    datesWrapper: {
        display: "flex",
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            marginTop: 0,
        },
    },
    buttonsContainer: {
        marginTop: theme.spacing(2),
        display: "flex",
        gap: theme.spacing(2),
        flexWrap: "wrap",
        justifyContent: "flex-end",
    },
    btnFormat: {
        marginLeft: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderTop: '1px dashed #ccc'
    },
    notDueWarning: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        display: 'flex',
    },
    warningWrapper: {
        marginTop: `-${theme.spacing(3)}px`,
        marginLeft: `-${theme.spacing(3)}px`,
        marginRight: `-${theme.spacing(3)}px`,
        marginBottom: theme.spacing(2),
        backgroundColor: '#FDF1C9',
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
        },
    },
    warningIcon: {
        marginRight: theme.spacing(1),
        color: '#B44C1B'
    },
    warningText: {
        fontSize: '18px',
        fontWeight: 400,
    },
    warningIconWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        flexDirection: "row",
    },
}));

const steps = {
    FORM: 0,
    ACTIONS: 1,
};

const saveErrorMessage = "Could not save form.";

const CompleteTemplateForm = () => {
    const classes = useStyles();
    const [step, setStep] = useState(steps.FORM);
    const [versionDialogOpen, setVersionDialogOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const dispatch = useDispatch();
    const { questionResponses, uploads, actions } = useSelector(
        (state) => state.formCompleter
    );

    const history = useHistory();
    const match = useRouteMatch();
    const { params } = match;
    const { moduleArea } = params;
    const templateFormId = parseInt(params.templateFormId);
    const { returnToDashboard } = params;

    const { enqueueSnackbar } = useSnackbar();
    const addTemplateVersionViews = useAddTemplateVersionView();

    const { data, isLoading, isError, error } = useTemplateForm(templateFormId);   

    const saveForm = useSaveTemplateForm();
    const calculateActions = useCalculateFormActions();

    const onSuccessfulSave = () => {
        dispatch(clearForm());
        returnToDashboard ? history.push(`${baseRoute}`) : history.push(`${baseRoute}/${moduleArea}`);
    };

    function onCancel() {
        dispatch(clearForm());
        history.goBack();
    }

    const userHasUnseenVersions =
        data?.template?.templateVersions &&
        data?.template?.templateVersions?.length > 0;

    useEffect(() => {
        setVersionDialogOpen(userHasUnseenVersions ?? false);
    }, [userHasUnseenVersions]);

    const submitForm = async (e) => {
        e.preventDefault();        

        const responses = Object.values(questionResponses);

        let attachmentRequiredQuestions = data.templateVersion.sections.map(s => s.questions).flat().filter(x => x.attachmentMandatory);

        for (const question of attachmentRequiredQuestions) {
            if (!uploads[question.id]?.filter(x => !x.toBeDeleted).length) {
                enqueueSnackbar("Question requires attachment", { variant: "error", });
                return;
            }
        }
        
        for (let i = 0; i < responses.length; i++) {
            if (responses[i].dateValue && !isValid(new Date(responses[i].dateValue))) {
                enqueueSnackbar("Invalid date supplied", { variant: "error", });
                return;
            }
        }       

        const completedForm = new CompletedFormBuilder(uploads, questionResponses, actions)
            .withTemplateFormId(templateFormId)
            .withTemplateVersionId(data?.templateVersion?.id)
            .buildResponses();

        const completedFormForActions = new CompletedFormBuilder(uploads, questionResponses, actions)
            .withTemplateFormId(templateFormId)
            .withTemplateVersionId(data?.templateVersion?.id)
            .buildResponses();

        const actionCalculationRequired = completedForm.result.templateFormResponses.filter((x) => x.numberValue || x.optionId || x.optionIds).length > 0;

        if (actionCalculationRequired) {
            const formForActionsCalculate = completedFormForActions
                .forActionCalculation()
                .build();

            calculateActions.mutate(formForActionsCalculate, {
                onSuccess: (calculatedActions) => {
                    if (calculatedActions.length > 0) {
                        dispatch(initialiseQuestionActions(calculatedActions));
                        setStep(steps.ACTIONS);
                        return;
                    }

                    saveForm.mutate(completedForm.build(), {
                        onSuccess: () => onSuccessfulSave(),
                        onError: (e) => {
                            console.error(e);
                            enqueueSnackbar(saveErrorMessage, { variant: "error" });
                        },
                    });
                },
                onError: (error) => {
                    console.error(error);
                    enqueueSnackbar(saveErrorMessage, { variant: "error" });
                },
            });
        } else {
            saveForm.mutate(completedForm.build(), {
                onSuccess: () => onSuccessfulSave(),
                onError: (e) => {
                    console.error(e);
                    enqueueSnackbar(saveErrorMessage, { variant: "error" });
                },
            });
        }
    };

    const handleSubmit = async (e) => {
        console.log('submit');

        e.preventDefault();
      
        if (isAfter(new Date(data.dueDate), new Date())) {
            setDialogOpen(true);
            return;
        }

        submitForm(e);
    };

    const onActionsSubmit = (e) => {
        e.preventDefault();
        const completedForm = new CompletedFormBuilder(
            uploads,
            questionResponses,
            actions
        )
            .withTemplateFormId(templateFormId)
            .buildResponses(true)
            .build();

        saveForm.mutate(completedForm, {
            onSuccess: () => onSuccessfulSave(),
            onError: (e) => {
                console.error(e);
                enqueueSnackbar(saveErrorMessage, {
                    variant: "error",
                });
            },
        });
    };

    if (isLoading)
        return (
            <Box display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        );

    if (isError)
        return (
            <ErrorBox
                message={error.message || "There was a problem loading the form"}
            />
        );

    function handleCloseDialog() {
        addTemplateVersionViews.mutate(
            {
                templateId: data.template.id,
                templateVersionIds: data.template.templateVersions.map((x) => x.id),
            },
            {
                onSuccess: () => setVersionDialogOpen(false),
                onError: (error) => {
                    console.error(error);
                    enqueueSnackbar("Could not save template version views.", {
                        variant: "error",
                    });
                },
            }
        );
    }

    const dueDate = new Date(data?.dueDate);
    return (
        (!data?.template?.isEnabled || !data?.isActive) 
            ? <>
                <Paper className={classes.titleCard}>
                    <Typography variant="h5">Historic : {data?.templateVersion?.name}</Typography>
                </Paper>
                {templateFormId ? (
                        <Box mt={2}>
                            <TemplateFormHistoryTable
                                moduleArea={moduleArea}
                                templateFormId={templateFormId}
                            />
                        </Box>
                ) : null}
            </>
            : <>
                <TemplateVersionHistoryDialog
                    open={versionDialogOpen}
                    onClose={handleCloseDialog}
                    versions={data?.template?.templateVersions}
                />
                {(data?.template.frequencyInterval === frequencyInterval.ORIGINALDUEDATE && dueDate > new Date()) &&
                    <Paper className={classes.warningWrapper}>
                        <div className={classes.warningIconWrapper}>
                            <WarningIcon className={classes.warningIcon} />
                            <Typography className={classes.warningText}> Cannot complete this form until due date.</Typography>
                        </div>
                        <div className={classes.datesWrapper}>
                            <Typography className={classes.warningText}>
                                    Due Date:{" "}{data?.dueDate ? formatShortDate(data.dueDate) : "N/A"}
                            </Typography>
                        </div>
                    </Paper>
                }
                <PageBase>
                    <Paper className={classes.titleCard}>
                        <div>
                            <Typography variant="h5">{data?.templateVersion?.name}</Typography>
                        </div>
                        <div className={classes.datesWrapper}>
                            <div>
                                <Typography>
                                    <strong>Due Date:</strong>{" "}
                                    {data?.dueDate ? formatShortDate(data.dueDate) : "N/A"}
                                </Typography>
                            </div>
                            <div>
                                <Typography>
                                    <strong>Last Completed:</strong>{" "}
                                    {data?.lastCompletedDate
                                        ? formatShortDate(data.lastCompletedDate)
                                        : "N/A"}
                                </Typography>
                            </div>
                        </div>
                    </Paper>
                    <form onSubmit={step === steps.FORM ? handleSubmit : onActionsSubmit}>
                        {step === steps.FORM && (
                            <>
                                <div className={classes.sections}>
                                    {data?.templateVersion?.sections.map((section) => (
                                        <FormSection key={section.id} section={section} />
                                    ))}
                                </div>

                                <div className={classes.buttonsContainer}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={onCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                            type="submit"
                                            color="primary"
                                            disabled={data?.template.frequencyInterval === frequencyInterval.ORIGINALDUEDATE && dueDate > new Date()}
                                            variant="contained"
                                            isLoading={calculateActions.isLoading || saveForm.isLoading}
                                        >
                                            {step === steps.FORM ? "Submit Form" : "Save"}
                                    </Button>
                                </div>
                                <Divider className={classes.divider} />
                                <Box mt={2}>
                                    <TemplateFormHistoryTable
                                        moduleArea={moduleArea}
                                        templateFormId={templateFormId}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <CardBase title="Comments">
                                        <FormComments templateFormId={templateFormId} comments={data?.comments} />
                                    </CardBase>                                    
                                </Box>
                                <DueDateWarningDialog
                                    open={dialogOpen}
                                    dueDate={data?.dueDate}
                                    onBackClick={() => setDialogOpen(false)}
                                    onContinueClick={submitForm}
                                />
                            </>
                        )}
                        {step === steps.ACTIONS && (
                            <CardBase
                                title="Actions"
                                description="The following actions will be created based on the answers provided."
                            >
                                <CreatedActions
                                    moduleArea={moduleArea}
                                    siteExternalId={data?.siteExternalId}
                                    formStartDate={data?.startDate}
                                />
                            </CardBase>
                        )}
                        <Box display="flex" justifyContent="end" mt={2} >
                            {step === steps.ACTIONS && (
                                <>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => setStep(steps.FORM)}
                                >
                                    Back
                                </Button>
                                <CustomButton className={classes.btnFormat}
                                type="submit"
                                color="primary"
                                variant="contained"
                                isLoading={calculateActions.isLoading || saveForm.isLoading}
                            >
                                {step === steps.FORM ? "Next" : "Save"}
                                </CustomButton>
                                </>
                            )}
                        </Box>
                    </form>
                </PageBase>
            </>
    );
};

export default CompleteTemplateForm;
