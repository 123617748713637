import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CardBase from "../../../components/cards/CardBase";
import { moduleFileLabels, moduleIconForEnum, moduleUrls } from "../../constants/moduleAreaConstants";
import { bandColours } from "../../constants/safetynestScoreConstants";
import useMostImpactfulActionsForSites from "../../hooks/queries/useMostImpactfulActionsForSites";
import { baseRoute } from "../../routes";

const noActionsSvgUrl = `${process.env.PUBLIC_URL}/images/checklist.svg`;

const useStyles = makeStyles((theme) => ({
    actionContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      fontSize: theme.typography.caption.fontSize,
    },
    actionButton: {
      marginLeft: 'auto',
    },
    actionRatingPill: {
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.spacing(0.75),
      fontSize: theme.typography.caption.fontSize,
      width: "fit-content",
      "&.rating-3": {
        backgroundColor: bandColours.AT_RISK,
        color: theme.palette.getContrastText(bandColours.AT_RISK),
      },
      "&.rating-2": {
        backgroundColor: bandColours.REACTIVE,
        color: theme.palette.getContrastText(bandColours.REACTIVE),
      },
      "&.rating-1": {
        backgroundColor: bandColours.DEVELOPING,
        color: theme.palette.getContrastText(bandColours.DEVELOPING),
      },
      "&.rating-0": {
        backgroundColor: bandColours.ESTABLISHED,
        color: theme.palette.getContrastText(bandColours.ESTABLISHED),
      },
    },
    noActions: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
    noActionsImage: {
      maxHeight: 200,
      marginTop: theme.spacing(2),
    },
    actionText: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      minWidth: 0,
      fontSize: '0.875rem',
      flex: 1,
    },
    flexCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modulePill: {
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.spacing(0.75),
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
      '& svg': {
        fontSize: '1rem',
      },
      '& div': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    pills: { 
      display: "flex",
      gap: theme.spacing(1),
      flexWrap: "wrap",
      width: "100%",
      paddingBottom: theme.spacing(1),
    },
    actionDetails: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      minWidth: 0,
    },
    row: {
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      padding: theme.spacing(1),
      '&:hover': {
        backgroundColor: '#fbfbfb',
        cursor: 'pointer',
      },
    },
    chevron: {
      marginLeft: 'auto',
    }
  }));

export default function MostImpactfulActions() {
    const history = useHistory();
    const classes = useStyles();

    const { selectedSite, activeAppId, appSiteIds } = useSelector(
        (state) => state.account
      );

    const mostImpactfulActions = useMostImpactfulActionsForSites({
        siteExternalIds: selectedSite
          ? [selectedSite.externalId]
          : appSiteIds[activeAppId],
      })

    function handleCompleteActionClick(applicationArea, id) {
      history.push(`${baseRoute}/${moduleUrls[applicationArea]}/action/${id}`) 
    }

    const content = () => {
      if (mostImpactfulActions.isLoading)
        return (
          <div className={classes.flexCenter}>
            <CircularProgress />
          </div>
        );

      if (mostImpactfulActions.isError)
        return (
          <div className={classes.flexCenter}>
            <Alert severity="error">
              Error loading most impactful actions.
            </Alert>
          </div>
        );

      return (
        <Fragment>
          {mostImpactfulActions.data ? (
            <div className={classes.actionContainer}>
                {mostImpactfulActions.data.map((mostImpactfulAction) => {
                const icon = moduleIconForEnum[mostImpactfulAction.applicationArea] ?? 'N/A';
                  
                return (
                    <div 
                      className={classes.row} 
                      key={mostImpactfulAction.id} 
                      onClick={() => handleCompleteActionClick(mostImpactfulAction.applicationArea, mostImpactfulAction.id)}
                    >
                      <div className={classes.actionDetails}>
                        <div className={classes.actionText}>
                            {mostImpactfulAction.actionText}
                        </div>
                        <div className={classes.pills}>
                          <div className={classes.modulePill}>
                            <div className={classes.pillIcon}>
                              {icon}
                            </div>
                            <div>
                              {moduleFileLabels[mostImpactfulAction.applicationArea]}
                            </div>
                          </div>
                          <div className={clsx(classes.actionRatingPill, `rating-${mostImpactfulAction.actionRating}`)}>
                              {mostImpactfulAction.actionRatingText}
                          </div>
                        </div>
                      </div>
                      <ChevronRight className={classes.chevron} />  
                    </div>
                )
                })}
            </div>
            ) : (
            <div>
                <Typography align="center" variant='body2'>No impactful actions to complete - great job!</Typography>
                <div className={classes.noActions}>
                    <img className={classes.noActionsImage} src={noActionsSvgUrl} />
                </div>
            </div>
            )}
        </Fragment>
      )
    }
    
    return (
        <CardBase title='Most Impactful Actions' description='Completing these actions will have the biggest impact on your SafetyNest Score'>
            {content()}
        </CardBase>
    )
}