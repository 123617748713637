import { SET_SITE_ID, SET_USER_TO_MANAGE, SET_RESET_USER } from "../types";

export function setSiteId({ siteId }) {
  return {
    type: SET_SITE_ID,
    payload: {
      siteId,
    },
  };
}

export function setUserToManage({ userToManage }) {
  return {
    type: SET_USER_TO_MANAGE,
    payload: {
      userToManage,
    },
  };
}

export function setResetUser() {
  return {
    type: SET_RESET_USER,
  };
}
