import React from "react";
import { TreeView } from "@material-ui/lab";
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedFolder } from "../../redux/actions/documentsActions";
import StyledTreeItem from "./StyledTreeItem";

const RecursiveTree = ({ sites, handleClickMenu, selected, setSelected }) => {
  const [expanded, setExpanded] = useState([]);
  const [hovered, setHovered] = useState("");
  const dispatch = useDispatch();

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeId) => {
    setSelected(nodeId);
    dispatch(setSelectedFolder(nodeId));
  };

  const createTree = (branch, siteExternalId) => {
    if (!branch) return null;
    return (
      <>
        {Object.keys(branch)?.map((label) => {
          if (label === "$$$.$$$" || !branch[label].hasOwnProperty("children"))
            return null;

          const path = siteExternalId + "/" + branch[label].path;
          const hasChildren = Object.values(branch[label].children).some((x) =>
            x.hasOwnProperty("children")
          );

          return (
            <StyledTreeItem
              nodeId={path}
              key={path}
              label={label}
              selected={selected}
              hovered={hovered}
              setHovered={setHovered}
              handleClickMenu={handleClickMenu}
              children={
                hasChildren
                  ? createTree(branch[label].children, siteExternalId)
                  : undefined
              }
            />
          );
        })}
      </>
    );
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
      onMouseLeave={() => setHovered("")}
    >
      {sites.map((site) => {
        const keys = Object.keys(site.tree);
        const hasChildren = !(keys.length === 1 && keys[0] === "$$$.$$$");
        return (
          <StyledTreeItem
            nodeId={site.externalId}
            key={site.externalId}
            label={site.name}
            selected={selected}
            hovered={hovered}
            setHovered={setHovered}
            handleClickMenu={handleClickMenu}
            children={
              hasChildren ? createTree(site.tree, site.externalId) : undefined
            }
          />
        );
      })}
    </TreeView>
  );
};

export default RecursiveTree;
