import React, { useRef, useState } from "react";
import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
  IconButton,
  Box,
  TextField,
  makeStyles,
  CircularProgress,
  Chip,
  alpha,
  Button,
} from "@material-ui/core";
import { Edit, Save, Replay, GetApp } from "@material-ui/icons";
import CardBase from "../../../../components/cards/CardBase";
import useTemplateFormHistories from "../../../hooks/queries/useTemplateFormHistories";
import useUpdateTemplateFormHistory from "../../../hooks/mutations/useUpdateTemplateFormHistory";
import { useSnackbar } from "notistack";
import { green, red } from "@material-ui/core/colors";
import clsx from "clsx";
import { baseRoute } from "../../../routes";
import { useHistory } from "react-router-dom";
import { formatShortDate } from "../../../../utils/dateTimeUtils";
import useContactsForAppAndSite from "../../../../hooks/useContactsForAppAndSite";
import templateFormHistoryService from "../../../services/templateFormHistoryService";
import { CSVLink } from "react-csv";
import TemplateFormHistoryDownloadDialog from "./TemplateFormHistoryDownloadDialog";
import { sortOrderName } from "../../../constants/sortOrder";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import TemplateFormHistoryAmendDialog from "./TemplateFormHistoryAmendDialog";

const useStyles = makeStyles((theme) => ({
  moduleChip: {
    color: "white",
    borderRadius: "4px",
    "&.status-1": {
      backgroundColor: green[400],
    },
    "&.status-2": {
      backgroundColor: red[400],
    },
  },
  textField: {
    flexGrow: 1,
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    },
  },
  csvLink: {
    visibility: "hidden",
  },
}));

const headCells = [
    { label: "Date", id: "completedDate", key: "completedDate", isSortable: true, },
    { label: "Status", id: "statusDescription", key: "statusDescription", isSortable: false, },
    { label: "Employee", id: "assignedEmployee", key: "assignedEmployee", isSortable: true, },
    { label: "Completed Actions", id: "completedActions", key: "completedActions", isSortable: false, },
    { label: "Notes", id: "notes", key: "notes", isSortable: false, },
];

const TemplateFormHistoryTable = ({ templateFormId, moduleArea }) => {
  const classes = useStyles();
  const history = useHistory();

  const contacts = useContactsForAppAndSite({ selectedSiteId: null });

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [notes, setNotes] = useState(null);
  const [sortBy, setSortBy] = useState(headCells[0].key);
  const [orderBy, setOrderBy] = useState(sortOrderName.DESC);

  const csvExportLinkRef = useRef();
  const [exportData, setExportData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [csvFilename, setCsvFilename] = useState("");
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [formToAmend, setFormToAmend] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, error, isFetching } = useTemplateFormHistories({
    templateFormId,
    pageSize: rowsPerPage,
    pageNum: page + 1,
    orderByColumn: sortBy,
    sortOrder: orderBy,
  });

  const { mutate, isLoading: isSaving } = useUpdateTemplateFormHistory();

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    };

    const handleSortClick = (event, sortColumn) => {
        const isAsc = sortBy === sortColumn && orderBy === sortOrderName.ASC;
        setSortBy(sortColumn);
        setOrderBy(isAsc ? sortOrderName.DESC : sortOrderName.ASC);
    };


    const handleChangeNotes = (e) => {
    e.preventDefault();
    setNotes({
      ...notes,
      notes: e.target.value,
    });
  };

    const handleClickSave = (e) => {
    e.stopPropagation();
    const notesToSave = notes;
    setNotes(null);
    mutate(
      {
        templateFormHistory: notesToSave,
        pageSize: rowsPerPage,
        pageNum: page + 1,
        templateFormId,
        sortBy,
        orderBy,
      },
      {
        onError: (error) => {
          console.error(error);
          enqueueSnackbar(
            error?.message || "There was an error updating the form history",
            {
              variant: "error",
            }
          );
        },
      }
    );
  };

  const goToTemplateFormHistory = (formHistoryId) => {
    history.push(`${baseRoute}/${moduleArea}/form/history/${formHistoryId}`);
  };

  const handleDownloadDialogClose = () => setIsDownloadDialogOpen(false);

  const getNameFromUserExternalId = (userExternalId) => {
    if (
      !contacts?.data[userExternalId]?.firstName ||
      !contacts?.data[userExternalId]?.lastName
    )
      return "Unknown";

    return `${contacts?.data[userExternalId]?.firstName} ${contacts?.data[userExternalId]?.lastName}`;
  };

  const handleDownloadClick = async () => {
    try {
      let data = await templateFormHistoryService.downloadHistory(
        templateFormId
      );

      const versions = Object.values(data);
      if (versions.length > 0) {
        for (const version of versions) {
          const form = version[0];

          setCsvFilename(
            `${form.templateName}_${
              form.templateVersionNumber
            }_${formatShortDate(new Date())}.csv`
          );

          const questions = form.templateFormResponses.map((t) => ({
            label: t.question,
            key: t.questionId.toString(),
          }));

          const headers = [...headCells, ...questions];
          const exportDataObj = version.map((v) => {
            let formResponses = {};

            if (v.templateFormResponses?.length > 0) {
              v.templateFormResponses.forEach((tfr) => {
                if (!tfr[tfr.questionId]) {
                  return (formResponses = {
                    ...formResponses,
                    [tfr.questionId]: tfr.response,
                  });
                }
              });
            }

            return {
              ...v,
              ...formResponses,
              assignedEmployee: getNameFromUserExternalId(
                v.assignedEmployeeExternalId
              ),
              completedActions: `${v.completedActionsCount}/${v.totalActionsCount}`,
            };
          });

          setCsvHeaders(headers);
          setExportData(exportDataObj);

          await handleDownloadPromise();

          setExportData([]);
          setCsvHeaders([]);
          setCsvFilename("");
        }
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Could not download template form history", {
        variant: "error",
      });
    } finally {
      setIsDownloadDialogOpen(false);
    }
  };

  const handleDownloadPromise = () =>
    new Promise((res) =>
      setTimeout(() => {
        csvExportLinkRef.current.link.click();
        res();
      })
        );

    const openEditFormPopup = (e, form) => {
        e.stopPropagation();
        setFormToAmend(form);
        setIsEditOpen(true);
    };

  return (
    <CardBase
      title="Form History"
      isLoading={isLoading}
      error={error}
      isFetching={isFetching}
      rightComponent={
        data?.results?.length > 0 ? (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setIsDownloadDialogOpen(true)}
          >
            <GetApp /> Download
          </Button>
        ) : null
      }
    >
      <TableContainer>
        <Table>
            <EnhancedTableHead
                headCells={headCells}
                sortColumn={sortBy}
                sortDirection={orderBy}
                onRequestSort={handleSortClick}
            />
          <TableBody>
            {data?.results.map((x) => {
              return (
                <TableRow
                  key={x.templateFormHistoryId}
                  onClick={() =>
                    goToTemplateFormHistory(x.templateFormHistoryId)
                  }
                  className={classes.tableRow}
                >
                  <TableCell>{formatShortDate(x.completedDate)}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={x.statusDescription}
                      className={clsx(classes.moduleChip, `status-${x.status}`)}
                    />
                  </TableCell>
                  <TableCell>
                    {contacts?.data[x.assignedEmployeeExternalId]
                      ? `${
                          contacts?.data[x.assignedEmployeeExternalId]
                            ?.firstName
                        } ${
                          contacts?.data[x.assignedEmployeeExternalId]?.lastName
                        }`
                      : ""}
                  </TableCell>
                  <TableCell>
                    {!!x.totalActionsCount
                      ? `${x.completedActionsCount}/${x.totalActionsCount}`
                      : "No Actions"}
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {notes?.templateFormHistoryId ===
                      x.templateFormHistoryId ? (
                        <>
                          <TextField
                            className={classes.textField}
                            value={notes?.notes || ""}
                            onChange={handleChangeNotes}
                          />
                          {isSaving ? (
                            <CircularProgress />
                          ) : (
                            <Box display="flex">
                              <IconButton onClick={() => setNotes(null)}>
                                <Replay />
                              </IconButton>
                              <IconButton onClick={handleClickSave}>
                                <Save />
                              </IconButton>
                            </Box>
                          )}
                        </>
                      ) : (
                        <>
                          <div>{x.notes}</div>
                            <IconButton
                                onClick={(e) => openEditFormPopup(e, x)}
                            >
                                <Edit />
                            </IconButton>
                        </>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <CSVLink
        className={classes.csvLink}
        ref={csvExportLinkRef}
        headers={csvHeaders}
        data={exportData}
        filename={csvFilename}
        target="_blank"
      />
      <TemplateFormHistoryDownloadDialog
        isOpen={isDownloadDialogOpen}
        onClose={handleDownloadDialogClose}
        onConfirm={handleDownloadClick}
          />
        <TemplateFormHistoryAmendDialog
            isOpen={isEditOpen}
            setIsOpen={setIsEditOpen}
            form={formToAmend}
            page={page}
            rows={rowsPerPage}
            moduleArea={moduleArea}
            sortBy={sortBy}
            orderBy={orderBy}
        />
    </CardBase>
  );
};

export default TemplateFormHistoryTable;
