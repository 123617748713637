import { Box, Grid, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import ScoreSelector from "./ScoreSelector"
import React from 'react'

const useStyles = makeStyles((theme) => ({
    hazardIcons: {
        height: '40px',
        marginRight: theme.spacing(2)
    },
    text2: {
        fontSize: '12px',
    },
    riskCircle: {
        background: '#eee',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#888',
        '&.red': {
            background: '#f37142',
            color: '#fff'
        },
        '&.amber': {
            background: '#ffb943',
            color: '#fff'
        },
        '&.green': {
            background: '#76dd78', 
            color: '#fff'
        },
    },
    riskScore: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
}))

export default function QuestionMapper({ id, postControl, scoreSelectors, allHazards, riskAssessmentDetails }) {
    const classes = useStyles()
    const overallScore = postControl ?
        allHazards[id]?.severityScorePostControl * allHazards[id]?.likelihoodScorePostControl :
        allHazards[id]?.severityScorePreControl * allHazards[id]?.likelihoodScorePreControl
        
    return (
        <Box width='100%' marginTop={2} display='flex'>
            <Grid container>
                <Grid item xs={12} md={5}>
                    <Box marginBottom={2} display='flex' flexDirection='column' alignItems='center'>
                        <Typography 
                            className={classes.text2} 
                            variant='subtitle2'
                        >
                            Choose the hazard severity
                        </Typography>
                        <Box display='flex' gridGap={5}>
                            {scoreSelectors.map(selector => (
                                <ScoreSelector
                                    key={selector.value} 
                                    id={id}
                                    numericValue={selector.value}
                                    textValue={selector.text}
                                    color={selector.color}
                                    onSelection={postControl ? selector.onPostControlSeverityScoreClick : selector.onPreControlSeverityScoreClick}
                                    score={postControl ? 'severityScorePostControl' : 'severityScorePreControl'}
                                    isCustomHazard={Object.keys(riskAssessmentDetails?.customHazards)?.includes(id)}
                                    allHazards={allHazards}
                                />
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box marginBottom={2} display='flex' flexDirection='column' alignItems='center'>
                        <Typography 
                            className={classes.text2} 
                            variant='subtitle2'
                        >
                            Choose the hazard likelihood
                        </Typography>
                        <Box display='flex' gridGap={5}>
                            {scoreSelectors.map(selector => (
                                <ScoreSelector
                                    key={selector.value} 
                                    id={id}
                                    numericValue={selector.value}
                                    textValue={selector.text}
                                    color={selector.color}
                                    onSelection={postControl ? selector.onPostControlLikelihoodScoreClick : selector.onPreControlLikelihoodScoreClick}
                                    score={postControl ? 'likelihoodScorePostControl' : 'likelihoodScorePreControl'}
                                    isCustomHazard={Object.keys(riskAssessmentDetails?.customHazards)?.includes(id)}
                                    allHazards={allHazards}
                                />
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                    <div className={classes.riskScore}>
                        <div 
                            className={
                                clsx(classes.riskCircle, 
                                overallScore > 14 ? 'red' : 
                                overallScore > 7 ? 'amber' : 
                                overallScore > 0 ? 'green' : 
                                null)
                            }
                        >
                            {overallScore}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}