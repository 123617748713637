import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment.js";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold.js";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic.js";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline.js";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough.js";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials.js";
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor.js";
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily.js";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize.js";
import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js";
import Image from "@ckeditor/ckeditor5-image/src/image.js";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
import Indent from "@ckeditor/ckeditor5-indent/src/indent.js";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock.js";
import Link from "@ckeditor/ckeditor5-link/src/link.js";
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage";
import List from "@ckeditor/ckeditor5-list/src/list.js";
import ListProperties from "@ckeditor/ckeditor5-list/src/listproperties";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph.js";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat.js";
import Table from "@ckeditor/ckeditor5-table/src/table.js";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation.js";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote.js";
import CloudServices from "@ckeditor/ckeditor5-cloud-services/src/cloudservices.js";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
import AutoImage from "@ckeditor/ckeditor5-image/src/autoimage.js";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert.js";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed.js";

const plugins = [
  Autoformat,
  AutoImage,
  Alignment,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  CloudServices,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageInsert,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  RemoveFormat,
  Strikethrough,
  Table,
  TableCellProperties,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Underline,
];

const toolbar = {
  items: [
    "heading",
    "|",
    "fontSize",
    "fontFamily",
    "|",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "|",
    "alignment",
    "|",
    "numberedList",
    "bulletedList",
    "|",
    "outdent",
    "indent",
    "|",
    "link",
    "blockQuote",
    "imageInsert",
    "insertTable",
    "|",
    "undo",
    "redo",
    "horizontalLine",
    "removeFormat",
  ],
};

const colors = [
  { color: "#000000", label: "Black" },
  { color: "#4D4D4D", label: "Dim grey" },
  { color: "#999999", label: "Grey" },
  { color: "#E6E6E6", label: "Light grey" },
  { color: "#FFFFFF", label: "White" },
  { color: "#E64C4C", label: "Red" },
  { color: "#E6994C", label: "Orange" },
  { color: "#E6E64C", label: "Yellow" },
  { color: "#99E64C", label: "Light Green" },
  { color: "#4CE64C", label: "Green" },
  { color: "#4CE699", label: "Aquamarine" },
  { color: "#4CE6E6", label: "Turquoise" },
  { color: "#4C99E6", label: "Light blue" },
  { color: "#4C4CE6", label: "Blue" },
  { color: "#994CE6", label: "Purple" },
];

export const editorConfig = {
  plugins,
  toolbar,
  image: {
    toolbar: [
      "imageTextAlternative",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
      "linkImage",
    ],
  },
  table: {
    contentToolbar: [
      "tableProperties",
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
    ],
  },
  fontColor: { colors },
  fontBackgroundColor: { colors },
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true,
    },
  },
};
