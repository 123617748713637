import {useQuery} from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseTypeService from "../../services/caseTypeService";

const useCaseTypes = () =>
  useQuery(
    [queryKeys.caseTypes],
    caseTypeService.getCaseTypes,
    {
      staleTime: Infinity
    }
  )

export default useCaseTypes;