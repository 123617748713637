import React, { useState } from "react";
import {
  Divider,
  IconButton,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AccountCircle, Settings } from "@material-ui/icons";
import CachedIcon from "@material-ui/icons/Cached";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AssistantIcon from "@material-ui/icons/Assistant";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth } from "../../contexts/authContext";

const useStyles = makeStyles((theme) => ({
  userMenuText: {
    marginLeft: theme.spacing(1),
  },
}));

function UserMenu() {
  const classes = useStyles();
  const history = useHistory();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(menuAnchorEl);
  const { selectedAccount, rootAccounts } = useSelector(
    (state) => state.account
  );
  const {
    logout,
    isCxAdmin,
    isConsultant,
    isTutorialAdmin,
    isAdministratorForSites,
  } = useAuth();

  const goToAccountSelect = () => {
    history.push("/account-select");
  };

  const handleMenuToggle = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const goToImpersonate = () => {
    history.push("/impersonate");
  };

  const goToConsultantAccountSelect = () => {
    history.push("/consultant-account-select");
  };

  const goToAdministration = () => {
    history.push("/administration");
  };

  const goToTutorialBuilder = () => {
    history.push("/tutorial-builder");
  };

  return (
    <>
      <IconButton
        aria-label="User menu"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleMenuToggle}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={menuAnchorEl}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
      >
        {rootAccounts.length > 1 && (
          <MenuItem onClick={goToAccountSelect}>
            <CachedIcon fontSize="small" />
            <ListItemText
              className={classes.userMenuText}
              primary="Change Account"
            />
          </MenuItem>
        )}
        {isCxAdmin && (
          <MenuItem onClick={goToImpersonate}>
            <SupervisorAccountIcon fontSize="small" />
            <ListItemText
              className={classes.userMenuText}
              primary="User Impersonation"
            />
          </MenuItem>
        )}
        {isConsultant && (
          <MenuItem onClick={goToConsultantAccountSelect}>
            <AccountBoxIcon fontSize="small" />
            <ListItemText
              className={classes.userMenuText}
              primary="Change Consultant Account"
            />
          </MenuItem>
        )}
        {isTutorialAdmin && (
          <MenuItem onClick={goToTutorialBuilder}>
            <AssistantIcon fontSize="small" />
            <ListItemText
              className={classes.userMenuText}
              primary="Tutorial Builder"
            />
          </MenuItem>
        )}
        {isAdministratorForSites(selectedAccount?.childExternalIds) && (
          <MenuItem onClick={goToAdministration}>
            <Settings fontSize="small" />
            <ListItemText
              className={classes.userMenuText}
              primary="Administration"
            />
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={logout}>
          <ExitToAppIcon fontSize="small" />
          <ListItemText className={classes.userMenuText} primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;
