import axios from "../utils/axios";

const apiUrl = process.env.REACT_APP_LEGAL_URL;

const fetchDocument = (fileReference) =>
  axios.get(apiUrl + "/client/downloaddocument/" + fileReference, {
    responseType:  "arraybuffer",
  });

const retrieveDocumentPreviewPath = async ({ reference, contentType }) => {
    const isVideoContent = contentType.startsWith("video");
    const endpointUrl = isVideoContent
        ? "client/downloadvideo"
        : "client/downloaddocument";

    try {
        const response = await axios.get(apiUrl + `/${endpointUrl}/${reference}`, {
            responseType: isVideoContent ? "stream" : "arraybuffer",
        });

        if (isVideoContent)
            return response;

        const blob = new Blob([response], { type: contentType });
        const previewPath = window.URL.createObjectURL(blob);
        return previewPath;
    } catch (e) {
        throw new Error(e?.message || "Could not fetch document.");
    }
};

const caseNestDocumentService = {
    fetchDocument,
    retrieveDocumentPreviewPath
};

export default caseNestDocumentService;
