import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateFormHistoryService from "../../services/templateFormHistoryService";

const useTemplateFormHistories = ({ templateFormId, pageSize, pageNum, sortOrder, orderByColumn, }) =>
  useQuery(
      [queryKeys.templateFormHistories, templateFormId, pageSize, pageNum, sortOrder, orderByColumn,],
    () =>
      templateFormHistoryService.getFormHistories({
        templateFormId,
        pageSize,
        pageNum,
        sortOrder,
        orderByColumn,
      }),
    {
      enabled: !!templateFormId,
    }
  );

export default useTemplateFormHistories;
