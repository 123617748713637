import { useMutation } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import historicAuditService from "../../services/historicAuditService";
import reactQueryUtils from "../../../utils/reactQueryUtils";

const { client } = reactQueryUtils

const useDeleteHistoricAudit = () => 
    useMutation(
        (historicAudit) => historicAuditService.archive(historicAudit.historicAuditId),
        {
            onMutate: async ({ siteExternalId, historicAuditId }) => {
                const queryKey = [queryKeys.siteHistoricAudits, siteExternalId];

                await client.cancelQueries(queryKey);

                const previousHistoricAudits = client.getQueryData(queryKey);

                if (previousHistoricAudits) {
                    client.setQueryData(queryKey, (historicAudits) => {
                        const validHistoricAudits = historicAudits?.results?.filter((historicAudit) => historicAudit.id !== historicAuditId) ?? [];

                        return {
                            totalCount: validHistoricAudits?.length,
                            results: validHistoricAudits,
                        }
                    });
                }
                return { previousHistoricAudits, queryKey };
            },
            onError: (err, variables, context) => {
                if (!context) return;

                client.setQueryData(context.queryKey, context.previousHistoricAudits);
            },
            onSettled: (data, error, variables, context) => {
                client.invalidateQueries(queryKeys.siteHistoricAudits);
            },
        }
    )
export default useDeleteHistoricAudit;