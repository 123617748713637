import React, { createContext, useContext, useEffect, useReducer, useState } from "react"
import { ratingList } from "../constants/actionRatingConstants"
import { riskAssessmentSteps } from "../constants/riskAssessmentStepConstants"
import useRiskAssessment from "../hooks/queries/useRiskAssessment"
import useRiskAssessmentHistory from "../hooks/queries/useRiskAssessmentHistory"
import useRiskAssessmentWithHistory from "../hooks/queries/useRiskAssessmentWithHistory"
import useTemplate from '../hooks/queries/useTemplate'
import useRiskAssessmentVersionHistory from "../hooks/queries/useRiskAssessmentVersionHistory"

const RiskAssessmentContext = createContext()

const steps = [
    { label: 'Hazards', title: 'Hazards', subtitle: 'Select applicable hazards or create a custom hazard' },
    { label: 'People', title: 'People', subtitle: 'Select applicable people or create a custom person' },
    { label: 'Evaluate', title: 'Hazard Evaluation', subtitle: 'Calculate score for each hazard' },
    { label: 'Record', title: 'Actions', subtitle: `Assign actions to employee's and set due dates` },
]

const riskAssessmentActionTypes = {
    ADD_HAZARD: 'add-hazard',
    ADD_CONTROL: 'add-control',
    ADD_PERSON: 'add-person',
    ADD_CUSTOM_HAZARD: 'add-custom-hazard',
    ADD_CUSTOM_PERSON: 'add-custom-person',
    REMOVE_HAZARD: 'remove-hazard',
    REMOVE_CONTROL: 'remove-control',
    REMOVE_PERSON: 'remove-person',
    REMOVE_CUSTOM_HAZARD: 'remove-custom-hazard',
    REMOVE_CUSTOM_PERSON: 'remove-custom-person',
    ADD_RESPONSE_HAZARDS: 'add-response-hazards',
    ADD_INCOMPLETE_ACTIONS: 'add-incomplete-actions',
    ADD_RESPONSE_PEOPLE: 'add-response-people',
    ADD_PRE_CONTROL_SEVERITY_SCORE: 'add-pre-control-severity-score',
    ADD_PRE_CONTROL_LIKELIHOOD_SCORE: 'add-pre-control-likelihood-score',
    ADD_POST_CONTROL_SEVERITY_SCORE: 'add-post-control-severity-score',
    ADD_POST_CONTROL_LIKELIHOOD_SCORE: 'add-post-control-likelihood-score',
    TOGGLE_CONTROL: 'toggle-control',
    ADD_RESPONSE_SITE: 'add-response-site',
    ADD_ACTION_DUE_DATE: 'add-action-due-date',
    UPDATE_ACTION_DUE_DATE: 'update-action-due-date',
    ADD_ACTION_ASSIGNED_EMPLOYEE: 'add-action-assigned-employee',
    UPDATE_ACTION_ASSIGNED_EMPLOYEE: 'update-action-assigned-employee',
    ADD_CUSTOM_ACTION: 'add-custom-action',
    ADD_RESPONSE_ID: 'add-response-id',
    RESET_RISK_ASSESSMENT: 'reset-risk-assessment',
    ADD_ACTION_RATING: 'add-action-rating',
    UPDATE_ACTION_RATING: 'update-action-rating',
    UPDATE_CONTROL_TEXT: 'update-control-text',
}

const initialRiskAssessmentState = {
    hazards: {
        hazardControls: {
            riskAssessmentControl: {
                riskAssessmentControlAction: {
                    dueDate: new Date(),
                    employeeId: '',
                    rating: ratingList[0].value,
                },
            },
            inPlace: undefined,
        },
    },
    customHazards: {},
    hazardIds: [],
    people: {},
    customPeople: {},
    peopleIds: [],
    siteExternalId: '',
    id: 0,
    incompleteActions: [],
}

function riskAssessmentReducer(state, action) {

    switch (action.type) {
        case riskAssessmentActionTypes.RESET_RISK_ASSESSMENT: {
            return initialRiskAssessmentState
        }
        case riskAssessmentActionTypes.ADD_RESPONSE_ID: {
            return {
                ...state,
                id: action.payload.id,
            }
        }
        case riskAssessmentActionTypes.ADD_RESPONSE_SITE: {
            return {
                ...state,
                siteExternalId: action.payload.siteExternalId,
            }
        }
        case riskAssessmentActionTypes.ADD_INCOMPLETE_ACTIONS: {
            const incompleteActions = []

            if (action?.payload?.incompleteActions)
                for (const incompleteAction of action.payload.incompleteActions) {
                    incompleteActions.push({
                        id: incompleteAction.id,
                        actionPresetId: incompleteAction.actionPresetId,
                        employeeId: incompleteAction.assignedEmployeeExternalId,
                        dueDate: incompleteAction.dueDate,
                        rating: incompleteAction.rating,
                        description: incompleteAction.description,
                        hasUpdated: false,
                    })
                }

            return {
                ...state,
                incompleteActions
            }
        }
        case riskAssessmentActionTypes.ADD_RESPONSE_HAZARDS: {
            const hazards = {}
            const hazardIds = []
            const customHazards = {}
            
            if (action?.payload?.riskAssessment?.templateCustomHazards)
                for (const hazard of action.payload.riskAssessment.templateCustomHazards) {
                    if (!action?.payload?.riskAssessment?.riskAssessmentHistory)
                    {
                        hazardIds.push(hazard.id);
                        customHazards[hazard.id] = {
                            id: hazard.id,
                            name: hazard.name,
                            hazardControls: [],
                            severityScorePreControl: 0,
                            severityScorePostControl: 0,
                            likelihoodScorePreControl: 0,
                            likelihoodScorePostControl: 0,
                        }
                    }
                    
                    if (new Date(action?.payload?.createdDate) > new Date(action?.payload?.lastCompletedDate))
                    {
                        hazardIds.push(hazard.id);
                        customHazards[hazard.id] = {
                            id: hazard.id,
                            name: hazard.name,
                            hazardControls: [],
                            severityScorePreControl: 0,
                            severityScorePostControl: 0,
                            likelihoodScorePreControl: 0,
                            likelihoodScorePostControl: 0,
                        }
                    }
                }

            if (action?.payload?.riskAssessment?.riskAssessmentHistory)
                for (const hazard of action.payload.riskAssessment.riskAssessmentHistory.riskAssessmentHazards) {

                    const hazControls = hazard.hazardControls || [];
                    const customHazControls = hazard.customHazardControls || [];
                    const hazControlsValues = hazard.hazardControlsValues || [];

                    if (hazard.hazardId) {
                        if (hazard.hazardId)
                            hazardIds.push(hazard.hazardId);
                        hazards[hazard.hazardId] = {
                            id: hazard.hazardId,
                            name: hazard.name,
                            hazardControls: hazControls.map(control => (
                                {
                                    ...control,
                                    inPlace: hazControlsValues?.filter(newControl => newControl.controlId === control.control?.id)[0]?.inPlace, // Using the inPlace from hazControlsNew
                                    id: hazControlsValues?.filter(newControl => newControl.controlId === control.control?.id)[0]?.id,
                                    isCustom: control.isCustom,
                                    riskAssessmentControl: {
                                        riskAssessmentControlAction: {
                                            actionPresetId: control.control?.controlAction[0]?.actionPresetId,
                                            dueDate: new Date(),
                                            employeeId: '',
                                            rating: ratingList[0].value,
                                        },
                                    },
                                }
                            )),
                            customHazardControls: customHazControls.map(control => (
                                {
                                    ...control,
                                    isCustom: true,
                                    isCustomControl: true,
                                    riskAssessmentControl: {
                                        riskAssessmentControlAction: {
                                            actionPresetId: control.control?.controlAction[0]?.actionPresetId,
                                            dueDate: new Date(),
                                            employeeId: '',
                                            rating: ratingList[0].value,
                                        },
                                    },
                                }
                            )),
                            severityScorePreControl: hazard.severityScorePreControl,
                            severityScorePostControl: hazard.severityScorePostControl,
                            likelihoodScorePreControl: hazard.likelihoodScorePreControl,
                            likelihoodScorePostControl: hazard.likelihoodScorePostControl,
                        }
                    }
                    if (hazard.customHazardId) {
                        hazardIds.push(hazard.customHazardId);
                        customHazards[hazard.customHazardId] = {
                            id: hazard.customHazardId,
                            name: hazard.customHazardName,
                            hazardControls: customHazControls.map(control => (
                                {
                                    ...control,
                                    inPlace: control.inPlace,
                                    isCustom: control.isCustom,
                                    riskAssessmentControl: {
                                        riskAssessmentControlAction: {
                                            actionPresetId: control.control?.controlAction[0]?.actionPresetId,
                                            dueDate: new Date(),
                                            employeeId: '',
                                            rating: ratingList[0].value,
                                        },
                                    },
                                }
                            )),
                            severityScorePreControl: hazard.severityScorePreControl,
                            severityScorePostControl: hazard.severityScorePostControl,
                            likelihoodScorePreControl: hazard.likelihoodScorePreControl,
                            likelihoodScorePostControl: hazard.likelihoodScorePostControl,
                        }
                    }
                }
            else if (action?.payload?.riskAssessment?.hazards)
                for (const hazard of action.payload.riskAssessment.hazards) {
                    hazardIds.push(hazard.hazardId)
                    hazards[hazard.hazardId] = {
                        id: hazard.hazardId,
                        name: hazard.name,
                        hazardControls: hazard.hazardControls.map(control => (
                            {
                                ...control,
                                inPlace: undefined,
                                riskAssessmentControl: {
                                    riskAssessmentControlAction: {
                                        actionPresetId: control.control?.controlAction[0]?.actionPresetId,
                                        dueDate: new Date(),
                                        employeeId: '',
                                        rating: ratingList[0].value,
                                    },
                                },
                            }
                        )),
                        severityScorePreControl: 0,
                        severityScorePostControl: 0,
                        likelihoodScorePreControl: 0,
                        likelihoodScorePostControl: 0,
                    }
                }

            return {
                ...state,
                hazards,
                hazardIds,
                customHazards
            }
        }
        case riskAssessmentActionTypes.ADD_RESPONSE_PEOPLE: {
            const people = {}
            const peopleIds = []
            const customPeople = {}
            if (action?.payload?.riskAssessment?.riskAssessmentHistory)
                for (const person of action.payload.riskAssessment.riskAssessmentHistory.riskAssessmentPeoples) {
                    if (person.peopleId) {
                        peopleIds.push(person.peopleId);
                        people[person.peopleId] = {
                            id: person.peopleId,
                            name: person.name,
                        }
                    }
                    if (person.customPeopleId) {
                        customPeople[person.customPeopleId] = {
                            id: person.customPeopleId,
                            name: person.customPeopleName,
                        }
                    }
                }

            else if (action?.payload?.riskAssessment?.people)
                for (const person of action.payload.riskAssessment.people) {
                    peopleIds.push(person.people?.id)
                    people[person.people?.id] = {
                        id: person.people?.id,
                        name: person.people?.name,
                    }
                }

            return {
                ...state,
                people,
                peopleIds,
                customPeople
            }
        }
        case riskAssessmentActionTypes.ADD_HAZARD: {
            return {
                ...state,
                hazards: {
                    ...state.hazards, [action.payload.hazard.id]: {
                        id: action.payload.hazard.id,
                        name: action.payload.hazard.name,
                        hazardControls: action.payload.hazard.hazardControls?.map(hazardControl => ({
                            ...hazardControl,
                            inPlace: undefined,
                            riskAssessmentControl: {
                                riskAssessmentControlAction: {
                                    actionPresetId: hazardControl.control?.controlAction[0]?.actionPresetId,
                                    dueDate: new Date(),
                                    employeeId: '',
                                    rating: ratingList[0].value,
                                },
                            },
                        })),
                        severityScorePreControl: 0,
                        severityScorePostControl: 0,
                        likelihoodScorePreControl: 0,
                        likelihoodScorePostControl: 0,
                    }
                },
                hazardIds: [...state.hazardIds, action.payload.hazard.id],
            }
        }
        case riskAssessmentActionTypes.ADD_CONTROL: {
            const newHazard = !action.payload.hazardControl.isCustomHazard ?
                { ...state.hazards[action.payload.hazardControl.hazardId] } :
                { ...state.customHazards[action.payload.hazardControl.hazardId] }

            return {
                ...state,
                ...(!action.payload.hazardControl.isCustomHazard && {
                    hazards: {
                        ...state.hazards, [action.payload.hazardControl.hazardId]: {
                            ...state.hazards[action.payload.hazardControl.hazardId],
                            hazardControls: [...newHazard?.hazardControls, {
                                id: action.payload.hazardControl.id,
                                isCustom: action.payload.hazardControl.isCustomHazard,
                                control: {
                                    id: action.payload.hazardControl.id,
                                    name: action.payload.hazardControl.name
                                },
                                inPlace: undefined,
                                riskAssessmentControl: {
                                    riskAssessmentControlAction: {
                                        actionPresetDescription: '',
                                        dueDate: new Date(),
                                        employeeId: '',
                                        rating: ratingList[0].value,
                                    },
                                },
                            }]
                        }
                    }
                }),
                ...(action.payload.hazardControl.isCustomHazard && {
                    customHazards: {
                        ...state.customHazards, [action.payload.hazardControl.hazardId]: {
                            ...state.customHazards[action.payload.hazardControl.hazardId],
                            hazardControls: [...newHazard?.hazardControls, {
                                id: action.payload.hazardControl.id,
                                isCustom: action.payload.hazardControl.isCustomHazard,
                                control: {
                                    id: action.payload.hazardControl.id,
                                    name: action.payload.hazardControl.name
                                },
                                inPlace: undefined,
                                riskAssessmentControl: {
                                    riskAssessmentControlAction: {
                                        actionPresetDescription: '',
                                        dueDate: new Date(),
                                        employeeId: '',
                                        rating: ratingList[0].value,
                                    },
                                },
                            }]
                        }
                    }
                })
            }
        }
        case riskAssessmentActionTypes.ADD_PERSON: {
            return {
                ...state,
                people: {
                    ...state.people,
                    [action.payload.person.id]: {
                        id: action.payload.person.id,
                        name: action.payload.person.name,
                    }
                },
                peopleIds: [...state.peopleIds, action.payload.person.id],
            }
        }
        case riskAssessmentActionTypes.ADD_CUSTOM_HAZARD: {
            return {
                ...state,
                customHazards: {
                    ...state.customHazards,
                    [action.payload.customHazard.id]: {
                        id: action.payload.customHazard.id,
                        name: action.payload.customHazard.name,
                        hazardControls: [],
                        severityScorePreControl: 0,
                        severityScorePostControl: 0,
                        likelihoodScorePreControl: 0,
                        likelihoodScorePostControl: 0,
                    }
                },
                hazardIds: [...state.hazardIds, action.payload.customHazard.id],
            }
        }
        case riskAssessmentActionTypes.ADD_CUSTOM_PERSON: {
            return {
                ...state,
                customPeople: {
                    ...state.customPeople,
                    [action.payload.customPerson.id]: {
                        id: action.payload.customPerson.id,
                        name: action.payload.customPerson.name,
                    }
                },
                peopleIds: [...state.peopleIds, action.payload.customPerson.id],
            }
        }
        case riskAssessmentActionTypes.REMOVE_HAZARD: {
            const newHazards = { ...state.hazards }
            delete newHazards[action.payload.hazardId]

            return {
                ...state,
                hazards: newHazards,
                hazardIds: state.hazardIds.filter((id) => id !== action.payload.hazardId),
            }
        }
        case riskAssessmentActionTypes.REMOVE_CONTROL: {
            const newHazard = !action.payload.hazardControl.isCustomHazard
                ? { ...state.hazards[action.payload.hazardControl.hazardId] }
                : { ...state.customHazards[action.payload.hazardControl.hazardId] }

            return {
                ...state,
                ...(!action.payload.hazardControl.isCustomHazard && !action.payload.hazardControl.isCustomControl && {
                    hazards: {
                        ...state.hazards, [action.payload.hazardControl.hazardId]: {
                            ...state.hazards[action.payload.hazardControl.hazardId],
                            hazardControls: newHazard?.hazardControls?.filter(x => x.id !== action.payload.hazardControl.controlId)
                        }
                    }
                }),
                ...(!action.payload.hazardControl.isCustomHazard &&
                    action.payload.hazardControl.isCustomControl && {
                    hazards: {
                        ...state.hazards,
                        [action.payload.hazardControl.hazardId]: {
                            ...state.hazards[action.payload.hazardControl.hazardId],
                            customHazardControls: newHazard?.customHazardControls?.filter(x => x.id !== action.payload.hazardControl.controlId)
                        }
                    }
                }),
                ...(action.payload.hazardControl.isCustomHazard && {
                    customHazards: {
                        ...state.customHazards,
                        [action.payload.hazardControl.hazardId]:
                        {
                            ...state.customHazards[action.payload.hazardControl.hazardId],
                            hazardControls: newHazard?.hazardControls?.filter(x => x.id !== action.payload.hazardControl.controlId)
                        }
                    }
                })
            }
        }
        case riskAssessmentActionTypes.ADD_ACTION_DUE_DATE: {
            const newHazard = !action.payload.controlAction.isCustomHazard
                ? { ...state.hazards[action.payload.controlAction.hazardId] }
                : { ...state.customHazards[action.payload.controlAction.hazardId] }

            if (action.payload.controlAction.isCustomControl) {
                const newHazardControlIndex = newHazard?.customHazardControls?.findIndex(x => x.id === action.payload.controlAction.controlId);
                newHazard.customHazardControls[newHazardControlIndex].riskAssessmentControl.riskAssessmentControlAction.dueDate = action.payload.controlAction.dueDate;
            }
            else {
                const newHazardControlIndex = newHazard?.hazardControls?.findIndex(x => x.id === action.payload.controlAction.controlId)
                newHazard.hazardControls[newHazardControlIndex].riskAssessmentControl.riskAssessmentControlAction.dueDate = action.payload.controlAction.dueDate;
            }
            return {
                ...state,
                ...(!action.payload.controlAction.isCustomHazard && {
                    hazards: {
                        ...state.hazards,
                        [action.payload.controlAction.hazardId]: {
                            ...state.hazards[action.payload.controlAction.hazardId],
                            hazardControls: newHazard?.hazardControls
                        }
                    }
                }),
                ...(action.payload.controlAction.isCustomControl && {
                    hazards: {
                        ...state.hazards,
                        [action.payload.controlAction.hazardId]: {
                            ...state.hazards[action.payload.controlAction.hazardId],
                            customHazardControls: newHazard?.customHazardControls
                        }
                    }
                }),
                ...(action.payload.controlAction.isCustomHazard && {
                    customHazards: {
                        ...state.customHazards,
                        [action.payload.controlAction.hazardId]: {
                            ...state.customHazards[action.payload.controlAction.hazardId],
                            hazardControls: newHazard?.hazardControls
                        }
                    }
                })
            }
        }
        case riskAssessmentActionTypes.UPDATE_ACTION_DUE_DATE: {
            const prevActions = [...state.incompleteActions]

            const actionToUpdate = prevActions.find(x => x.id === action.payload.action.actionId)
            actionToUpdate.dueDate = action.payload.action.dueDate
            actionToUpdate.hasUpdated = true

            const incompleteActions = prevActions.map(x => x.id === action.payload.action.actionId ? { ...prevActions, ...actionToUpdate } : x)

            return {
                ...state,
                incompleteActions
            }
        }
        case riskAssessmentActionTypes.ADD_ACTION_ASSIGNED_EMPLOYEE: {
            const newHazard = !action.payload.controlAction.isCustomHazard ?
                { ...state.hazards[action.payload.controlAction.hazardId] } :
                { ...state.customHazards[action.payload.controlAction.hazardId] }

            if (action.payload.controlAction.isCustomControl) {
                const newHazardControlIndex = newHazard?.customHazardControls?.findIndex(x => x.id === action.payload.controlAction.controlId);
                newHazard.customHazardControls[newHazardControlIndex].riskAssessmentControl.riskAssessmentControlAction.employeeId = action.payload.controlAction.employeeId;
            }
            else {
                const newHazardControlIndex = newHazard?.hazardControls?.findIndex(x => x.id === action.payload.controlAction.controlId)
                newHazard.hazardControls[newHazardControlIndex].riskAssessmentControl.riskAssessmentControlAction.employeeId = action.payload.controlAction.employeeId
            }
            return {
                ...state,
                ...(!action.payload.controlAction.isCustomHazard && {
                    hazards: {
                        ...state.hazards, [action.payload.controlAction.hazardId]: {
                            ...state.hazards[action.payload.controlAction.hazardId],
                            hazardControls: newHazard?.hazardControls
                        }
                    }
                }),
                ...(action.payload.controlAction.isCustomControl && {
                    hazards: {
                        ...state.hazards, [action.payload.controlAction.hazardId]: {
                            ...state.hazards[action.payload.controlAction.hazardId],
                            customHazardControls: newHazard?.customHazardControls
                        }
                    }
                }),
                ...(action.payload.controlAction.isCustomHazard && {
                    customHazards: {
                        ...state.customHazards, [action.payload.controlAction.hazardId]: {
                            ...state.customHazards[action.payload.controlAction.hazardId],
                            hazardControls: newHazard?.hazardControls
                        }
                    }
                })
            }
        }

        case riskAssessmentActionTypes.UPDATE_ACTION_ASSIGNED_EMPLOYEE: {
            const prevActions = [...state.incompleteActions]

            const actionToUpdate = prevActions.find(x => x.id === action.payload.action.actionId)
            actionToUpdate.employeeId = action.payload.action.employeeId
            actionToUpdate.hasUpdated = true

            const incompleteActions = prevActions.map(x => x.id === action.payload.action.actionId ? { ...prevActions, ...actionToUpdate } : x)

            return {
                ...state,
                incompleteActions
            }
        }

        case riskAssessmentActionTypes.ADD_ACTION_RATING: {
            const newHazard = !action.payload.controlAction.isCustomHazard
                ? { ...state.hazards[action.payload.controlAction.hazardId] }
                : { ...state.customHazards[action.payload.controlAction.hazardId] }

            if (action.payload.controlAction.isCustomControl) {
                const newHazardControlIndex = newHazard?.customHazardControls?.findIndex(x => x.id === action.payload.controlAction.controlId);
                newHazard.customHazardControls[newHazardControlIndex].riskAssessmentControl.riskAssessmentControlAction.rating = action.payload.controlAction.rating;
            }
            else {
                const newHazardControlIndex = newHazard?.hazardControls?.findIndex(x => x.id === action.payload.controlAction.controlId)
                newHazard.hazardControls[newHazardControlIndex].riskAssessmentControl.riskAssessmentControlAction.rating = action.payload.controlAction.rating
            }
            return {
                ...state,
                ...(!action.payload.controlAction.isCustomHazard && {
                    hazards: {
                        ...state.hazards,
                        [action.payload.controlAction.hazardId]: {
                            ...state.hazards[action.payload.controlAction.hazardId],
                            hazardControls: newHazard?.hazardControls
                        }
                    }
                }),
                ...(action.payload.controlAction.isCustomControl && {
                    hazards: {
                        ...state.hazards,
                        [action.payload.controlAction.hazardId]: {
                            ...state.hazards[action.payload.controlAction.hazardId],
                            customHazardControls: newHazard?.customHazardControls
                        }
                    }
                }),
                ...(action.payload.controlAction.isCustomHazard && {
                    customHazards: {
                        ...state.customHazards,
                        [action.payload.controlAction.hazardId]: {
                            ...state.customHazards[action.payload.controlAction.hazardId],
                            hazardControls: newHazard?.hazardControls
                        }
                    }
                })
            }
        }
        case riskAssessmentActionTypes.UPDATE_ACTION_RATING: {
            const prevActions = [...state.incompleteActions]

            const actionToUpdate = prevActions.find(x => x.id === action.payload.action.actionId)
            actionToUpdate.rating = action.payload.action.rating
            actionToUpdate.hasUpdated = true

            const incompleteActions = prevActions.map(x => x.id === action.payload.action.actionId ? { ...prevActions, ...actionToUpdate } : x)

            return {
                ...state,
                incompleteActions
            }
        }
        case riskAssessmentActionTypes.UPDATE_CONTROL_TEXT: {
            const newHazard = (!action.payload.hazardControl.isCustomHazard || action.payload.hazardControl.isCustomControl)
                ? { ...state.hazards[action.payload.hazardControl.hazardIdToAmend] }
                : { ...state.customHazards[action.payload.hazardControl.hazardIdToAmend] }

            if (action.payload.hazardControl.isCustomControl) {
                const controlToUpdate = newHazard?.customHazardControls?.find(item => item.control.id === action.payload.hazardControl.controlId);
                const updatedControl = {
                    ...controlToUpdate,
                    control: {
                        ...controlToUpdate.control,
                        name: action.payload.hazardControl.hazardControlText,
                        hasControlTextChanged: true,
                    }
                };
                const indexToUpdate = newHazard?.customHazardControls?.findIndex(x => x.control.id === action.payload.hazardControl.controlId)
                const updatedData = [...newHazard.customHazardControls];
                updatedData[indexToUpdate] = updatedControl;

                return {
                    ...state,
                    ...(!action.payload.hazardControl.isCustomHazard && !action.payload.hazardControl.isCustomControl && {
                        hazards: {
                            ...state.hazards,
                            [action.payload.hazardControl.hazardIdToAmend]: {
                                ...state.hazards[action.payload.hazardControl.hazardIdToAmend],
                                hazardControls: updatedData
                            }
                        }
                    }),
                    ...(!action.payload.hazardControl.isCustomHazard && action.payload.hazardControl.isCustomControl && {
                        hazards: {
                            ...state.hazards,
                            [action.payload.hazardControl.hazardIdToAmend]: {
                                ...state.hazards[action.payload.hazardControl.hazardIdToAmend],
                                customHazardControls: updatedData
                            }
                        }
                    }),
                }
            }
            else {
                const controlToUpdate = newHazard?.hazardControls?.find(item => item.control.id === action.payload.hazardControl.controlId);
                const updatedControl = {
                    ...controlToUpdate,
                    control: {
                        ...controlToUpdate.control,
                        name: action.payload.hazardControl.hazardControlText,
                        hasControlTextChanged: true,
                    }
                };
                const indexToUpdate = newHazard?.hazardControls?.findIndex(item => item.control.id === action.payload.hazardControl.controlId);
                const updatedData = [...newHazard.hazardControls];
                updatedData[indexToUpdate] = updatedControl;

                return {
                    ...state,
                    ...(!action.payload.hazardControl.isCustomHazard && !action.payload.hazardControl.isCustomControl && {
                        hazards: {
                            ...state.hazards,
                            [action.payload.hazardControl.hazardIdToAmend]: {
                                ...state.hazards[action.payload.hazardControl.hazardIdToAmend],
                                hazardControls: updatedData
                            }
                        }
                    }),
                    ...(!action.payload.hazardControl.isCustomHazard && action.payload.hazardControl.isCustomControl && {
                        hazards: {
                            ...state.hazards,
                            [action.payload.hazardControl.hazardIdToAmend]: {
                                ...state.hazards[action.payload.hazardControl.hazardIdToAmend],
                                customHazardControls: updatedData
                            }
                        }
                    }),
                    ...(action.payload.hazardControl.isCustomControl && {
                        hazards: updatedData
                    }),
                    ...(action.payload.hazardControl.isCustomHazard && {
                        customHazards: {
                            ...state.customHazards, [action.payload.hazardControl.hazardIdToAmend]: {
                                ...state.customHazards[action.payload.hazardControl.hazardIdToAmend],
                                hazardControls: updatedData
                            }
                        }
                    })
                }
            }
        }
        case riskAssessmentActionTypes.TOGGLE_CONTROL: {
            const newHazard = (!action.payload.hazardControl.isCustomHazard || action.payload.hazardControl.isCustomControl)
                ? { ...state.hazards[action.payload.hazardControl.hazardId] }
                : { ...state.customHazards[action.payload.hazardControl.hazardId] }

            if (action.payload.hazardControl.isCustomControl) {
                const newHazardControlIndex = newHazard?.customHazardControls?.findIndex(x => x.id === action.payload.hazardControl.controlId)
                newHazard.customHazardControls[newHazardControlIndex].inPlace = action.payload.hazardControl.inPlace
            }
            else {
                const newHazardControlIndex = newHazard?.hazardControls?.findIndex(x => x.id === action.payload.hazardControl.controlId)
                newHazard.hazardControls[newHazardControlIndex].inPlace = action.payload.hazardControl.inPlace
            }

            return {
                ...state,
                ...(!action.payload.hazardControl.isCustomHazard && {
                    hazards: {
                        ...state.hazards,
                        [action.payload.hazardControl.hazardId]: {
                            ...state.hazards[action.payload.hazardControl.hazardId],
                            hazardControls: newHazard?.hazardControls
                        }
                    }
                }),
                ...(action.payload.hazardControl.isCustomControl && {
                    hazards: {
                        ...state.hazards,
                        [action.payload.hazardControl.hazardId]: {
                            ...state.hazards[action.payload.hazardControl.hazardId],
                            hazardControls: newHazard?.hazardControls
                        }
                    }
                }),
                ...(action.payload.hazardControl.isCustomHazard && {
                    customHazards: {
                        ...state.customHazards, [action.payload.hazardControl.hazardId]: {
                            ...state.customHazards[action.payload.hazardControl.hazardId],
                            hazardControls: newHazard?.hazardControls
                        }
                    }
                })
            }
        }
        case riskAssessmentActionTypes.ADD_CUSTOM_ACTION: {
            const newHazard = !action.payload.controlAction.isCustomHazard
                ? { ...state.hazards[action.payload.controlAction.hazardId] }
                : { ...state.customHazards[action.payload.controlAction.hazardId] }

            if (action.payload.controlAction.isCustomControl) {
                const newHazardControlIndex = newHazard?.customHazardControls?.findIndex(x => x.id === action.payload.controlAction.controlId);
                newHazard.customHazardControls[newHazardControlIndex].riskAssessmentControl.riskAssessmentControlAction.actionPresetDescription = action.payload.controlAction.actionDescription;
            }
            else {
                const newHazardControlIndex = newHazard?.hazardControls?.findIndex(x => x.id === action.payload.controlAction.controlId)
                newHazard.hazardControls[newHazardControlIndex].riskAssessmentControl.riskAssessmentControlAction.actionPresetDescription = action.payload.controlAction.actionDescription
            }
            return {
                ...state,
                ...(!action.payload.controlAction.isCustomHazard && {
                    hazards: {
                        ...state.hazards,
                        [action.payload.controlAction.hazardId]: {
                            ...state.hazards[action.payload.controlAction.hazardId],
                            hazardControls: newHazard?.hazardControls
                        }
                    }
                }),
                ...(action.payload.controlAction.isCustomControl && {
                    hazards: {
                        ...state.hazards,
                        [action.payload.controlAction.hazardId]: {
                            ...state.hazards[action.payload.controlAction.hazardId],
                            customHazardControls: newHazard?.customHazardControls
                        }
                    }
                }),
                ...(action.payload.controlAction.isCustomHazard && {
                    customHazards: {
                        ...state.customHazards,
                        [action.payload.controlAction.hazardId]: {
                            ...state.customHazards[action.payload.controlAction.hazardId],
                            hazardControls: newHazard?.hazardControls
                        }
                    }
                })
            }
        }
        case riskAssessmentActionTypes.REMOVE_PERSON: {
            const newPeople = { ...state.people }
            delete newPeople[action.payload.personId]

            return {
                ...state,
                people: newPeople,
                peopleIds: state.peopleIds.filter((id) => id !== action.payload.personId),
            }
        }
        case riskAssessmentActionTypes.REMOVE_CUSTOM_HAZARD: {
            const newCustomHazards = { ...state.customHazards }
            delete newCustomHazards[action.payload.customHazardId]

            return {
                ...state,
                customHazards: newCustomHazards,
                hazardIds: state.hazardIds.filter((id) => id !== action.payload.customHazardId),
            }
        }
        case riskAssessmentActionTypes.REMOVE_CUSTOM_PERSON: {
            const newCustomPeople = { ...state.customPeople }
            delete newCustomPeople[action.payload.customPersonId]

            return {
                ...state,
                customPeople: newCustomPeople,
                peopleIds: state.peopleIds.filter((id) => id !== action.payload.customPersonId),
            }
        }
        case riskAssessmentActionTypes.ADD_PRE_CONTROL_SEVERITY_SCORE: {
            return {
                ...state,
                ...(action.payload.score.hazardId && {
                    hazards: {
                        ...state.hazards,
                        [action.payload.score.hazardId]: {
                            ...state.hazards[action.payload.score.hazardId],
                            severityScorePreControl: action.payload.score.value,
                        }
                    }
                }),
                ...(action.payload.score.customHazardId && {
                    customHazards: {
                        ...state.customHazards,
                        [action.payload.score.customHazardId]: {
                            ...state.customHazards[action.payload.score.customHazardId],
                            severityScorePreControl: action.payload.score.value,
                        }
                    }
                })
            }
        }
        case riskAssessmentActionTypes.ADD_PRE_CONTROL_LIKELIHOOD_SCORE: {
            return {
                ...state,
                ...(action.payload.score.hazardId && {
                    hazards: {
                        ...state.hazards,
                        [action.payload.score.hazardId]: {
                            ...state.hazards[action.payload.score.hazardId],
                            likelihoodScorePreControl: action.payload.score.value,
                        }
                    }
                }),
                ...(action.payload.score.customHazardId && {
                    customHazards: {
                        ...state.customHazards, [action.payload.score.customHazardId]: {
                            ...state.customHazards[action.payload.score.customHazardId],
                            likelihoodScorePreControl: action.payload.score.value,
                        }
                    }
                })
            }
        }
        case riskAssessmentActionTypes.ADD_POST_CONTROL_SEVERITY_SCORE: {
            return {
                ...state,
                ...(action.payload.score.hazardId && {
                    hazards: {
                        ...state.hazards, [action.payload.score.hazardId]: {
                            ...state.hazards[action.payload.score.hazardId],
                            severityScorePostControl: action.payload.score.value,
                        }
                    }
                }),
                ...(action.payload.score.customHazardId && {
                    customHazards: {
                        ...state.customHazards, [action.payload.score.customHazardId]: {
                            ...state.customHazards[action.payload.score.customHazardId],
                            severityScorePostControl: action.payload.score.value,
                        }
                    }
                })
            }
        }
        case riskAssessmentActionTypes.ADD_POST_CONTROL_LIKELIHOOD_SCORE: {
            return {
                ...state,
                ...(action.payload.score.hazardId && {
                    hazards: {
                        ...state.hazards, [action.payload.score.hazardId]: {
                            ...state.hazards[action.payload.score.hazardId],
                            likelihoodScorePostControl: action.payload.score.value,
                        }
                    }
                }),
                ...(action.payload.score.customHazardId && {
                    customHazards: {
                        ...state.customHazards, [action.payload.score.customHazardId]: {
                            ...state.customHazards[action.payload.score.customHazardId],
                            likelihoodScorePostControl: action.payload.score.value,
                        }
                    }
                })
            }
        }
        default:
            return state;
    }
}

function RiskAssessmentProvider({ children }) {
    const [activeStep, setActiveStep] = useState(0)
    const [selectedRiskAssessmentTemplateId, setSelectedRiskAssessmentTemplateId] = useState(null)
    const [selectedRiskAssessmentId, setSelectedRiskAssessmentId] = useState(null)
    const [selectedSiteId, setSelectedSiteId] = useState(null)
    const [selectedFrequencyId, setSelectedFrequencyId] = useState(null)
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [templateHazardIds, setTemplateHazardIds] = useState([])
    const [hazardDialogOpen, setHazardDialogOpen] = useState(false)
    const [templatePeopleIds, setTemplatePeopleIds] = useState([])
    const [peopleDialogOpen, setPeopleDialogOpen] = useState(false)
    const [selectedRiskAssessmentVersionHistoryId, setSelectedRiskAssessmentVersionHistoryId] = useState(null)
    const [isEditMode, setIsEditMode] = useState(null)
    const riskAssessmentVersionHistory = useRiskAssessmentVersionHistory(selectedRiskAssessmentVersionHistoryId)
    const [selectedRiskAssessmentHistoryId, setSelectedRiskAssessmentHistoryId] = useState(null)
    const [lastCompletedDate, setLastCompletedDate] = useState(null)
    const [templateVersionCreatedDate, setTemplateVersionCreatedDate] = useState(null)

    const [riskAssessmentDetails, riskAssessmentDispatch] = useReducer(riskAssessmentReducer, initialRiskAssessmentState)

    const addResponseId = (id) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_RESPONSE_ID,
            payload: { id },
        })
    }

    const addResponseSite = (siteExternalId) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_RESPONSE_SITE,
            payload: { siteExternalId },
        })
    }

    const addActionDueDate = (controlAction) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_ACTION_DUE_DATE,
            payload: { controlAction }
        })
    }

    const updateActionDueDate = (action) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.UPDATE_ACTION_DUE_DATE,
            payload: { action }
        })
    }

    const addActionAssignedEmployee = (controlAction) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_ACTION_ASSIGNED_EMPLOYEE,
            payload: { controlAction }
        })
    }

    const updateActionAssignedEmployee = (action) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.UPDATE_ACTION_ASSIGNED_EMPLOYEE,
            payload: { action }
        })
    }

    const addActionRating = (controlAction) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_ACTION_RATING,
            payload: { controlAction }
        })
    }

    const updateActionRating = (action) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.UPDATE_ACTION_RATING,
            payload: { action }
        })
    }

    const addCustomAction = (controlAction) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_CUSTOM_ACTION,
            payload: { controlAction }
        })
    }

    const addResponseHazards = (riskAssessment, lastCompletedDate, createdDate) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_RESPONSE_HAZARDS,
            payload: { riskAssessment, lastCompletedDate, createdDate },
        })
    }

    const addIncompleteActions = (incompleteActions) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_INCOMPLETE_ACTIONS,
            payload: { incompleteActions },
        })
    }

    const addControl = (hazardControl) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_CONTROL,
            payload: { hazardControl },
        })
    }

    const addResponsePeople = (riskAssessment) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_RESPONSE_PEOPLE,
            payload: { riskAssessment },
        })
    }

    const removeHazard = (hazardId) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.REMOVE_HAZARD,
            payload: { hazardId },
        })
    }

    const removeControl = (hazardControl) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.REMOVE_CONTROL,
            payload: { hazardControl },
        })
    }

    const toggleControl = (hazardControl) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.TOGGLE_CONTROL,
            payload: { hazardControl },
        })
    }

    const updateControlText = (hazardControl) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.UPDATE_CONTROL_TEXT,
            payload: { hazardControl },
        })
    }

    const removePerson = (personId) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.REMOVE_PERSON,
            payload: { personId },
        })
    }

    const addHazard = (hazard) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_HAZARD,
            payload: { hazard },
        })
    }

    const addPerson = (person) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_PERSON,
            payload: { person },
        })
    }

    const addCustomHazard = (customHazard) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_CUSTOM_HAZARD,
            payload: { customHazard },
        })
    }

    const addCustomPerson = (customPerson) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_CUSTOM_PERSON,
            payload: { customPerson },
        })
    }

    const removeCustomHazard = (customHazardId) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.REMOVE_CUSTOM_HAZARD,
            payload: { customHazardId },
        })
    }

    const removeCustomPerson = (customPersonId) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.REMOVE_CUSTOM_PERSON,
            payload: { customPersonId },
        })
    }

    const addPreControlSeverityScore = (score) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_PRE_CONTROL_SEVERITY_SCORE,
            payload: { score },
        })
    }

    const addPreControlLikelihoodScore = (score) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_PRE_CONTROL_LIKELIHOOD_SCORE,
            payload: { score },
        })
    }

    const addPostControlSeverityScore = (score) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_POST_CONTROL_SEVERITY_SCORE,
            payload: { score },
        })
    }

    const addPostControlLikelihoodScore = (score) => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.ADD_POST_CONTROL_LIKELIHOOD_SCORE,
            payload: { score },
        })
    }

    const resetRiskAssessment = () => {
        riskAssessmentDispatch({
            type: riskAssessmentActionTypes.RESET_RISK_ASSESSMENT,
        })
    }

    const isFirstStep = activeStep === riskAssessmentSteps.HAZARDS
    const isLastStep = activeStep === riskAssessmentSteps.RECORD

    const riskAssessmentTemplate = useTemplate(selectedRiskAssessmentTemplateId)
    const riskAssessment = useRiskAssessment(selectedRiskAssessmentId)
    const riskAssessmentHistories = useRiskAssessmentWithHistory({ riskAssessmentId: selectedRiskAssessmentId, withHistory: true })
    const riskAssessmentHistory = useRiskAssessmentHistory(selectedRiskAssessmentHistoryId)

    function addResponseData() {
        addResponseHazards({ hazards: riskAssessment?.data?.hazards, riskAssessmentHistory: riskAssessment?.data?.riskAssessmentHistory, templateCustomHazards: riskAssessment?.data?.templateCustomHazards }, lastCompletedDate, templateVersionCreatedDate)
        addResponsePeople({ people: riskAssessment?.data?.people, riskAssessmentHistory: riskAssessment?.data?.riskAssessmentHistory })
        addResponseSite(riskAssessment?.data?.siteExternalId)
        addResponseId(riskAssessment?.data?.id)
        addIncompleteActions(riskAssessment?.data?.incompleteActions)
    }

    function addHistoricResponseData() {
        addResponseSite(riskAssessment?.data?.siteExternalId)
        addResponseId(riskAssessment?.data?.id)
        addResponseHazards({ hazards: riskAssessmentHistory?.data?.hazards, riskAssessmentHistory: riskAssessmentHistory?.data }, lastCompletedDate, templateVersionCreatedDate)
    }

    useEffect(() => {
        addResponseData()
        
        if (riskAssessment?.data?.template?.templateVersions?.length > 0) {
            setTemplateVersionCreatedDate(riskAssessment?.data?.template?.templateVersions[0].createdOn)
        }
    }, [riskAssessment?.data])

    useEffect(() => {
        addHistoricResponseData()
    }, [riskAssessmentHistory?.data])

    useEffect(() => {
        let lastCompletedRiskAssessment = null
        if (riskAssessmentHistories?.data?.riskAssessmentHistories?.length > 0) {
            lastCompletedRiskAssessment = [...riskAssessmentHistories?.data?.riskAssessmentHistories]?.pop()
        }
        
        if (lastCompletedRiskAssessment) {
            setLastCompletedDate(lastCompletedRiskAssessment.completedDate)
        }
    }, [riskAssessmentHistories?.data]);
    
    useEffect(() => {
        if (riskAssessment?.data) {
            addResponseData()
        }
    }, [lastCompletedDate])

    function goToPreviousStep() {
        setActiveStep((currentStep) => currentStep - 1)
    }

    function goToNextStep() {
        setActiveStep((currentStep) => currentStep + 1)
    }

    function setStepOne() {
        setActiveStep(0)
    }

    function reset() {
        resetRiskAssessment()
        setActiveStep(0)
        setHazardDialogOpen(false)
        setPeopleDialogOpen(false)
        setSelectedEmployeeId(null)
        setSelectedFrequencyId(null)
        setSelectedRiskAssessmentTemplateId(null)
        setSelectedSiteId(null)
        setStartDate(null)
        setTemplateHazardIds([])
        setLastCompletedDate(null)
    }

    function partialReset() {
        reset()
        addResponseData()
    }

    const value = {
        reset,
        partialReset,
        setStepOne,
        riskAssessmentDetails,
        steps,
        activeStep,
        goToNextStep,
        goToPreviousStep,
        selectedRiskAssessmentTemplateId,
        setSelectedRiskAssessmentTemplateId,
        setSelectedRiskAssessmentId,
        isFirstStep,
        isLastStep,
        selectedSiteId,
        setSelectedEmployeeId,
        selectedEmployeeId,
        setSelectedSiteId,
        selectedFrequencyId,
        setSelectedFrequencyId,
        startDate,
        setStartDate,
        riskAssessment,
        riskAssessmentHistories,
        riskAssessmentTemplate,
        templateHazardIds,
        setTemplateHazardIds,
        hazardDialogOpen,
        setHazardDialogOpen,
        templatePeopleIds,
        setTemplatePeopleIds,
        peopleDialogOpen,
        setPeopleDialogOpen,
        removeHazard,
        removeControl,
        toggleControl,
        removePerson,
        addHazard,
        addPerson,
        addCustomHazard,
        addCustomPerson,
        removeCustomHazard,
        removeCustomPerson,
        addControl,
        addPreControlSeverityScore,
        addPreControlLikelihoodScore,
        addPostControlSeverityScore,
        addPostControlLikelihoodScore,
        addActionDueDate,
        updateActionDueDate,
        addActionAssignedEmployee,
        updateActionAssignedEmployee,
        addCustomAction,
        addActionRating,
        updateActionRating,
        setSelectedRiskAssessmentVersionHistoryId,
        riskAssessmentVersionHistory,
        setIsEditMode,
        isEditMode,
        setActiveStep,
        updateControlText,        
        setSelectedRiskAssessmentHistoryId,
    }

    return (
        <RiskAssessmentContext.Provider value={value}>
            {children}
        </RiskAssessmentContext.Provider>
    )
}

const useRiskAssessmentDetails = () => useContext(RiskAssessmentContext)

export { RiskAssessmentProvider, useRiskAssessmentDetails };
