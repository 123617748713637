import React from "react";
import { makeStyles } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import fileUtils from "../../utils/fileUtils";

const useStyles = makeStyles((theme) => ({
  actionDropBox: (props) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: fileUtils.getDropBoxColor(props),
    borderStyle: "dashed",
    backgroundColor: "#fff",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "100%",
    height: "200px",
    marginRight: "auto",
    marginLeft: "auto",
    cursor: "pointer",
  }),
  icon: {
    color: '#5c45c7',
    backgroundColor: '#e8e4ff',
    borderRadius: '50%',
    padding: theme.spacing(1),
  },
  dropBox: (props) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: fileUtils.getDropBoxColor(props),
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "300px",
    height: "200px",
    marginRight: "auto",
    marginLeft: "auto",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  }),
  dropBoxLabel: {
    cursor: "pointer",
    textAlign: "center",
  },
  bold: {
    color: "#5c45c7",
  },
}));

const DropBoxContainer = ({
  getRootProps,
  getInputProps,
  isDragActive,
  isDragAccept,
  isDragReject,
  forActionAttachments,
}) => {
  const classes = useStyles({ isDragActive, isDragAccept, isDragReject });

  return (
    <div
      {...{
        ...getRootProps({
          isdragactive: isDragActive.toString(),
          isdragaccept: isDragAccept.toString(),
          isdragreject: isDragReject.toString(),
        }),
        className: forActionAttachments ? classes.actionDropBox : classes.dropBox,
      }}
    >
      <input {...getInputProps()} />
      <CloudUpload className={classes.icon} fontSize="large" />
      <p className={classes.dropBoxLabel}>
        Drag and drop your files here
        <br/>
        or <span className={classes.bold}>click</span> to browse
      </p>
    </div>
  );
};

export default DropBoxContainer;
