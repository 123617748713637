import { useMutation } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateFormHistoryService from "../../services/templateFormHistoryService";
import reactQueryUtils from "../../../utils/reactQueryUtils";

const { client } = reactQueryUtils;

const useUpdateTemplateFormHistory = () =>
  useMutation(
      ({ templateFormHistory }) => templateFormHistoryService.updateTemplateFormHistory(templateFormHistory),
      {
      onMutate: async ({ templateFormHistory, pageSize, pageNum, templateFormId, orderBy, sortBy }) => {
        const queryKey = [
          queryKeys.templateFormHistories,
          templateFormId,
          pageSize,
          pageNum,
          orderBy,
          sortBy,
        ];
             
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await client.cancelQueries(queryKey);

        // Snapshot the previous value
        const previousHistories = client.getQueryData(queryKey);

        // Optimistically update to the new value
        client.setQueryData(queryKey, (old) => ({
          ...old,
          templateFormHistories: old.results.map(x => x.templateFormHistoryId === templateFormHistory.templateFormHistoryId ?
            ({ ...x, notes: templateFormHistory.notes }) :
            x)
        }));

        // Return a context object with the snapshotted value
        return { previousHistories, queryKey };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newDocument, context) => {
          client.setQueryData(
          context.queryKey,
          context.previousHistories
        );
      },
      // Always refetch after error or success:
          onSettled: (err, newDocument, context) => {
          client.invalidateQueries(context.queryKey);
      },
    }
  );

export default useUpdateTemplateFormHistory;