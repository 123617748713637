import { useMutation } from "react-query";
import tutorialService from "../../services/tutorialService";
import reactQueryUtils from "../../utils/reactQueryUtils";
import queryKeys from "../../constants/queryKeys";

const useUpdateTutorial = () =>
  useMutation(tutorialService.update, {
    onMutate: async (updatedTutorial) => {
      const { client } = reactQueryUtils;
      const queryKey = [queryKeys.tutorialsList];
      await client.cancelQueries(queryKey);

      const tutorialsBeforeUpdate = client.getQueryData(queryKey);

      client.setQueryData(queryKey, (tutorials) =>
        tutorials.map((t) =>
          t.id === updatedTutorial.id ? { ...t, ...updatedTutorial } : t
        )
      );

      return { tutorialsBeforeUpdate };
    },
    onError: (error, variables, { tutorialsBeforeUpdate }) => {
      const queryKey = [queryKeys.tutorialsList];
      reactQueryUtils.client.setQueryData(queryKey, tutorialsBeforeUpdate);
    },
    onSettled: () => {
      reactQueryUtils.client.invalidateQueries([queryKeys.tutorialsList]);
    },
  });

export default useUpdateTutorial;
