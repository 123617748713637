import { Checkbox, Grid, InputAdornment, List, ListItem, ListItemIcon, ListItemText, makeStyles, TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons';
import React, { useState } from 'react'
import { useRiskAssessmentDetails } from '../../contexts/RiskAssessmentContext';
import usePeople from '../../hooks/queries/usePeople'

const useStyles = makeStyles((theme) => ({
    peopleContainer: {
        marginBottom: theme.spacing(2)
    },
    listWrapper: {
        height: '400px', 
        overflowY: 'scroll', 
        display: 'flex', 
        flexDirection: 'row', 
        flexWrap: 'wrap',
        marginTop: theme.spacing(2)
    },
    halfWidth: {
        width: '50%'
    },
}));

export default function PeopleSelector() {
    const classes = useStyles()
    const people = usePeople()

    const {
        riskAssessmentDetails,
        removePerson,
        addPerson
    } = useRiskAssessmentDetails()

    const [peopleSearch, setPeopleSearch] = useState('')

    function togglePerson(person) {
        riskAssessmentDetails?.peopleIds?.includes(person?.id) ?
            removePerson(person?.id) :
            addPerson(person)
    }

    return (
        <Grid container className={classes.peopleContainer}>
            <Grid item xs={12}>
                <TextField 
                    size="small"
                    label='Search for people'
                    variant="outlined"
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    )
                    }}
                    className={classes.halfWidth}
                    value={peopleSearch}
                    onChange={(e) => setPeopleSearch(e.target.value)}
                />
                <List required className={classes.listWrapper}>
                    <Grid container>
                        {Object.keys(riskAssessmentDetails?.customPeople)?.length > 0 && Object.keys(riskAssessmentDetails?.customPeople)?.map(customPerson => (
                            <Grid item key={customPerson} xs={12} md={4}>
                                <ListItem>
                                    <ListItemIcon>
                                        <Checkbox checked disabled />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {riskAssessmentDetails?.customPeople[customPerson]?.name}
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        ))}
                        {people?.data?.filter(x => x.name.toLowerCase().includes(peopleSearch.toLowerCase()))?.map(person => (
                            <Grid item key={person?.id} xs={12} md={4}>
                                <ListItem>
                                    <ListItemIcon>
                                        <Checkbox 
                                            onChange={() => togglePerson(person)} 
                                            checked={riskAssessmentDetails?.peopleIds?.includes(person?.id)} 
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {person?.name}
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        ))}
                    </Grid>
                </List>
            </Grid>
        </Grid>
    )
}