import { useMemo } from "react";

const usePathWithAccountName = ({ selectedFolder, data }) =>
  useMemo(() => {
    let elements = selectedFolder.split("/");
    const siteExternalId = elements[0];
    let accountName = data.filter(
      (x) => x.accountExternalId === siteExternalId
    )[0]?.accountName;
    elements.shift();
    elements.unshift(accountName);
    return elements.join("/");
  }, [selectedFolder, data]);

export default usePathWithAccountName;
