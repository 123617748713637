import axios from "../../utils/axios";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const addTemplateVersionViews = (addedTemplateVersionViews) =>
  axios.post(`${apiUrl}/templateVersionView`, addedTemplateVersionViews.templateVersionIds);

const templateVersionViewService = {
    addTemplateVersionViews
};

export default templateVersionViewService;
