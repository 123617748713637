import React, {useEffect} from "react";
import AuthenticatedApp from "./components/AuthenticatedApp";
import UnauthenticatedApp from "./components/UnauthenticatedApp";
import { useAuth } from "./contexts/authContext";
import {useLocation} from "react-router-dom";

function App() {
  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = () => {
      window.InlineManual("update");
    };

    handleRouteChange();

  }, [location]);

  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />;
}

export default App;
