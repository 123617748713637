import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import auditService from "../../services/auditService";

const useSiteAuditsLatest = ({ externalIds }) =>
  useQuery(
      [queryKeys.siteAuditsLatest, externalIds?.join(",")],
      () => auditService.fetchLatestAuditTypeForSites(externalIds),
    {
      enabled: !!externalIds?.length,
    }
  );

export default useSiteAuditsLatest;
