export const bandColours = {
  AT_RISK: "#e46164",
  REACTIVE: "#eba240",
  DEVELOPING: "#eac24f",
  PROACTIVE: "#afc951",
  ESTABLISHED: "#44ce82",
};

const bandColoursForEnum = {
  0: bandColours.AT_RISK,
  1: bandColours.REACTIVE,
  2: bandColours.DEVELOPING,
  3: bandColours.PROACTIVE,
  4: bandColours.ESTABLISHED,
};

export const getColourForBand = (band) => bandColoursForEnum[band];

export const scoreTrend = {
  MAINTAINING: 0,
  RISING: 1,
  FALLING: 2,
};
