const getAccountAndRecursiveParentExternalIds = ({ selectedAccountExternalId, sites }) => {
  if (!selectedAccountExternalId) return [];

  let accountExternalIds = [selectedAccountExternalId];
  
  const getParentExternalId = (accountExternalId) => {
    const site = sites[accountExternalId];
    if (sites[site?.parentExternalId]) {
      accountExternalIds.push(site.parentExternalId);
      getParentExternalId(site.parentExternalId);
    }
    return accountExternalIds;
  }
  
  return getParentExternalId(selectedAccountExternalId);
}

const accountUtils = {
  getAccountAndRecursiveParentExternalIds
}

export default accountUtils;