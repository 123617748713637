import { SET_PAGE } from "../types";

const initialState = {
  path: "",
  url: "",
  params: null,
};
function pageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PAGE:
      return {
        ...state,
        path: action.payload.path,
        url: action.payload.url,
        params: action.payload.params,
      };
    default:
      return state;
  }
}

export default pageReducer;
