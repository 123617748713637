import React, { useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import fileUtils from "../../utils/fileUtils";
import CloseIcon from "@material-ui/icons/Close";
import { useTracking } from "react-tracking";
import trackingConstants from "../../constants/trackingConstants";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  buttonsWrapper: {
    display: "flex",
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      flex: 1,
    },
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  dialogTitleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogTitle: {
    flex: 1,
  },
  closeButton: {
    flexShrink: 0,
    marginRight: theme.spacing(1),
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  previewImage: {
    border: 0,
    width: "100%",
  },
}));

const DownloadCard = ({ title, previewImageUrl, downloadUrl }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleDownload = () => {
    trackEvent({
      event: `${trackingConstants.events.downloadFile}: ${title}`,
    });
    fileUtils.downloadFileByUrl(downloadUrl);
  };

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  return (
    <>
      <Paper className={classes.card}>
        <div className={classes.titleWrapper}>
          <GetAppIcon className={classes.icon} />
          <Typography>{title}</Typography>
        </div>
        <div className={classes.buttonsWrapper}>
          <Button
            className={classes.button}
            onClick={handlePreviewOpen}
            color="primary"
            variant="outlined"
          >
            Preview
          </Button>
          <Button
            className={classes.button}
            onClick={handleDownload}
            color="primary"
            variant="outlined"
          >
            Download
          </Button>
        </div>
      </Paper>
      <Dialog
        open={previewOpen}
        onClose={handlePreviewClose}
        fullScreen={fullScreen}
        maxWidth="lg"        
      >
        <div className={classes.dialogTitleWrapper}>
          <DialogTitle className={classes.dialogTitle} disableTypography>
            <Typography variant="h5">{`${title} Preview`}</Typography>
          </DialogTitle>
          <IconButton
            className={classes.closeButton}
            onClick={handlePreviewClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={classes.dialogContent}>
          <img
            className={classes.previewImage}
            src={previewImageUrl}
            alt={`${title} Preview`}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DownloadCard;
