import React from "react";
import { Grid } from "@material-ui/core";
import { useTracking } from "react-tracking";
import useSiteActionsSummary from "../hooks/queries/useSiteActionsSummary";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TemplateFormTableCard from "../components/compliance/templateForms/TemplateFormTableCard";
import moduleAreas, {
  moduleIcons,
  moduleUrls,
} from "../constants/moduleAreaConstants";
import PageBase from "../../components/PageBase";
import PageTitle from "../components/PageTitle";
import SiteSelect from "../../components/SiteSelect";
import ActionsTableCard from "../components/ActionsTableCard";
import { useAuth } from "../../contexts/authContext";

const useStyles = makeStyles((theme) => ({
  myActionsToggle: {
    marginLeft: "auto",
  },
  titleGrid: {
    marginBottom: theme.spacing(2),
  },
}));

function Compliance() {
  const { Track } = useTracking({
    page: `Compliance`,
  });
  const classes = useStyles();
  const { selectedSite, activeAppId, appSiteIds } = useSelector(
    (state) => state.account
  );
  const { complianceActionsTable: compliance } = useSelector(
    (state) => state.tablePage
  );

  const { hasSafetyNestAllActionsRole } = useAuth();

  const { data } = useSiteActionsSummary({
    externalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
    fromDate: compliance.fromDate,
    toDate: compliance.toDate,
    forUser: !hasSafetyNestAllActionsRole ? true : compliance.forUser,
    moduleArea: moduleAreas.COMPLIANCE,
  });

  return (
    <PageBase>
      <Track>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className={classes.titleGrid}
        >
          <Grid item xs={12} md={9}>
            <PageTitle
              icon={moduleIcons.COMPLIANCE}
              title="Compliance"
              description="Store and monitor key certifications in an online filing cabinet that you can access instantly. By providing the date the item was last renewed/completed and selecting how frequently that item needs to be renewed, the system can calculate the expiration date and notify you via email when the item is due."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SiteSelect size="small" />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionsTableCard
              moduleArea={moduleAreas.COMPLIANCE}
              summaryData={data}
            />
          </Grid>
          <Grid item xs={12}>
            <TemplateFormTableCard
              moduleArea={moduleUrls[moduleAreas.COMPLIANCE]}
            />
          </Grid>
        </Grid>
      </Track>
    </PageBase>
  );
}

export default Compliance;
