import axios from "../../utils/axios";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const fetchAllSubjects = () => axios.get(`${apiUrl}/Subject/GetAll`);

const subjectService = {
  fetchAllSubjects,
};

export default subjectService;
