import axios from "../../utils/axios";
import { isAzureFile } from "../contexts/HistoricAuditContext";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const getByHistoricAuditId = (historicAuditId) =>
    axios.get(`${apiUrl}/historicaction/historicaudit/${historicAuditId}`)

const update = (historicActionId, historicActionDTO) => {
    const formData = new FormData();

    if (historicActionDTO.azureFile !== null && !isAzureFile(historicActionDTO.azureFile)) {
        formData.append("azureFile", historicActionDTO.azureFile);
    }
    formData.append("historicActionDTO", JSON.stringify(historicActionDTO));

    return axios.patch(`${apiUrl}/historicaction/${historicActionId}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

const create = (historicActionDTO, historicAuditId) => {
    const formData = new FormData();

    if (historicActionDTO.azureFile !== null && !isAzureFile(historicActionDTO.azureFile)) {
        formData.append("azureFile", historicActionDTO.azureFile);
    }
    formData.append("historicActionDTO", JSON.stringify(historicActionDTO));
    formData.append("historicAuditId", historicAuditId.toString());

    return axios.post(`${apiUrl}/historicaction/create`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

const archive = (historicActionId) =>
    axios.delete(`${apiUrl}/historicaction/${historicActionId}`);

export const historicActionService = {
    getByHistoricAuditId,
    update,
    create,
    archive
}