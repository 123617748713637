import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import fileNameUtils from "../../utils/fileNameUtils";
import { folderNameIsValid } from "../../utils/validators";

const useStyles = makeStyles(() => ({
  textField: {
    minWidth: "300px",
  },
}));

const RenameDocumentDialog = ({
  open,
  handeClose,
  oldDocumentName,
  handleSave,
  documentNames,
}) => {
  const classes = useStyles();
  const [documentName, setDocumentName] = useState("");

  const handleCancel = () => {
    handeClose();
    setDocumentName("");
  };

  const handleRename = () => {
    handleSave(documentName);
    handleCancel();
  };

  const errorMessage = useMemo(() => {
    if (documentName.length && !folderNameIsValid(documentName))
      return "File name contains an invalid character";

    if (!documentNames?.length) return "";

    const filteredDocumentNames = documentNames.filter(
      (f) => f !== oldDocumentName
    );

    for (let i = 0; i < filteredDocumentNames.length; i++) {
      if (
        documentName + fileNameUtils.getFileExtension(oldDocumentName) ===
        filteredDocumentNames[i]
      )
        return "File name already exists";
    }
  }, [documentName, documentNames, oldDocumentName]);

  useEffect(() => {
    if (open && oldDocumentName)
      setDocumentName(fileNameUtils.removeFileExtension(oldDocumentName));
  }, [open, oldDocumentName]);

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Rename</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          className={classes.textField}
          value={documentName}
          onChange={(e) => setDocumentName(e.target.value)}
          label="File Name"
          error={!!errorMessage?.length}
          helperText={errorMessage}
          inputProps={{
            "aria-label": "document-name-input",
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleRename}
          color="secondary"
          disabled={!!errorMessage?.length}
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameDocumentDialog;
