import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import accountService from "../../services/accountService";

const useAccountAppSites = ({
  childExternalIds,
  activeAppId,
}) =>
  useQuery(
    [
      queryKeys.accountSites,
      childExternalIds?.join(","),
      activeAppId,
    ],
    () =>
      accountService.fetchAccountsForApp({
        activeAppId,
        accountExternalIds: childExternalIds,
      }),
    {
      enabled:
        !!childExternalIds &&
        !!activeAppId,
      staleTime: Infinity,
    }
  );

export default useAccountAppSites;
