import { Box, makeStyles, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import clsx from "clsx";
import React from 'react'

const useStyles = makeStyles(() => ({
    text: {
        fontSize: '12px',
        color: '#888'
    },
    score: {
        width: '45px', 
        height: '30px', 
        display: 'flex', 
        justifyContent: 'center', 
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        alignItems: 'center', 
        borderRadius: '4px',
        background: '#aaa',
        cursor: 'pointer',
        '&.red': {
            background: '#f37142',
        },
        '&.amber': {
            background: '#ffb943', 
        },
        '&.yellow': {
            background: '#ffea7b', 
        },
        '&.lime': {
            background: '#9af288', 
        },
        '&.green': {
            background: '#76dd78', 
        },
        '&:hover': {
            opacity: '0.5'
        },
        '&>svg': {
            height: '10px'
        }
    },
}))

export default function ScoreSelector({ textValue, numericValue, id, color, onSelection, score, isCustomHazard, allHazards }) {
    const classes = useStyles()

    return (
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <Typography className={classes.text} variant='subtitle2'>{textValue}</Typography>
            <div 
                onClick={() => onSelection({...(!isCustomHazard ? { hazardId: id } : { customHazardId: id }), value: numericValue})} 
                className={clsx(classes.score, color)}
            >
                {
                    allHazards[id][score] === numericValue &&
                    <Check />
                }
            </div>
        </Box>
    )
}