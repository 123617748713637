import React from "react";
import { useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";
import * as muiIcons from "@material-ui/icons";
import DocumentTile from "../components/tiles/DocumentTile";
import layoutConstants from "../constants/layoutConstants";
import worknestLogo from "../images/worknest-small.svg";
import { useHistory } from "react-router";
import { documentTypes } from "../constants/documentConstants";
import CardBase from "../components/cards/CardBase";
import PageBase from "../components/PageBase";

const useStyles = makeStyles(() => ({
  tilesGrid: {
    height: "100%",
  },
}));

const DocumentSelect = () => {
  const accountName = useSelector(
    (state) => state.account.selectedAccount.account.name
  );

  const classes = useStyles();
  const history = useHistory();

  return (
    <PageBase>
      <CardBase title="Document Manager">
        <Grid
          className={classes.tilesGrid}
          container
          spacing={layoutConstants.documentTilesGridSpacingMultiplier}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10} sm={3} xl={1}>
            <DocumentTile
              title={`${accountName} - Documents`}
              icon={muiIcons["Description"]}
              handleClick={() =>
                history.push(`/documents/${documentTypes.ACCOUNT.value}`)
              }
            />
          </Grid>
          <Grid item xs={10} sm={3} xl={1}>
            <DocumentTile
              title="Documents prepared by us"
              handleClick={() =>
                history.push(`/documents/${documentTypes.WORKNEST.value}`)
              }
              image={worknestLogo}
            />
          </Grid>
        </Grid>
      </CardBase>
    </PageBase>
  );
};

export default DocumentSelect;
