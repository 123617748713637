import { useMutation } from "react-query";
import reactQueryUtils from "../../utils/reactQueryUtils";
import queryKeys from "../../constants/queryKeys";
import userAccessPermissionService from "../../services/userAccessPermissionService";

const useUpdateUserAccessPermission = () =>
  useMutation(userAccessPermissionService.update, {
    onMutate: async (userAccessPermissions) => {
      const { client } = reactQueryUtils;
      const queryKey = [
        queryKeys.userAccessPermissions,
        userAccessPermissions.userExternalId,
      ];
      await client.cancelQueries(queryKey);

      const userAccessPermissionsBeforeChange = client.getQueryData(queryKey);

      client.setQueryData(queryKey, userAccessPermissions);

      return { userAccessPermissionsBeforeChange };
    },
    onError: (
      error,
      userAccessPermissions,
      { userAccessPermissionsBeforeChange }
    ) => {
      const queryKey = [
        queryKeys.userAccessPermissions,
        userAccessPermissions.userExternalId,
      ];
      reactQueryUtils.client.setQueryData(
        queryKey,
        userAccessPermissionsBeforeChange
      );
    },
    onSettled: (_data, _error, userAccessPermissions) => {
      if (userAccessPermissions)
        reactQueryUtils.client.invalidateQueries([
          queryKeys.userAccessPermissions,
          userAccessPermissions.userExternalId,
        ]);
    },
  });

export default useUpdateUserAccessPermission;
