import TrustCentreArticle from "../TrustCentreArticle";

function CookiePolicy() {
    return (
        <TrustCentreArticle title="Cookie Policy" lastUpdatedDate="2023-08-01">
            <section>
                <h3>Information about our use of cookies</h3>
                <p>
                    A cookie is a small file which asks permission to be placed on your computer’s hard drive. The file is added and the cookie helps analyse
                    web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web
                    application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
                </p>
                <p>
                    Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A
                    cookie cannot identity you personally and does not give us access to your computer or any information about you, other than the data you
                    choose to share with us.
                </p>
                <p>
                    Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may
                    prevent you from taking full advantage of the website.
                </p>
                <p>We use the following cookies:</p>
                <ul>
                    <li>
                        <strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of our website. They include, for
                        example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.
                    </li>
                    <li>
                        <strong>Analytical or performance cookies.</strong> These allow us to recognise and count the number of visitors and to see how visitors
                        move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users
                        are finding what they are looking for easily.
                    </li>
                    <li>
                        <strong>Functionality cookies.</strong> These are used to recognise you when you return to our website. This enables us to personalise
                        our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
                    </li>
                    <li>
                        <strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you have visited and the links you have
                        followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests.
                    </li>
                </ul>
                <p>You can find more information about the individual cookies we use and the purposes for which we use them in the table below:</p>
                <table>
                    <thead>
                        <tr>
                            <th>Cookie Name</th>
                            <th>Purpose</th>
                            <th>More information</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>visitor_id</td>
                            <td>
                                Pardot tracks visitor and prospect activities on our website and landing pages by setting cookies on their browsers. Cookies are
                                set to remember preferences (like form field values) when a visitor returns to our site. Pardot cookies don’t store personally
                                identifying information, only a unique identifier.
                            </td>
                            <td>
                                <a target="_blank" href="https://help.salesforce.com/articleView?id=pardot_basics_cookies.htm&type=5">
                                    Pardot cookies
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>worknest_token</td>
                            <td>
                                Stores authentication information for the current session. This enables other MyWorkNest applications on the same domain to
                                authenticate the user using the same session instead of asking the user to login again.
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>ai_user</td>
                            <td>
                                Used by Microsoft Application Insights software to collect statistical usage and telemetry information. The cookie stores a
                                unique identifier to recognize users on returning visits over time.
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>myworknest-pardot</td>
                            <td>Used to determine whether you have opted in or out of the use of pardot cookies.</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    <strong>We do not share any information collected by our cookies with any third parties.</strong>
                </p>
                <p>
                    You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if
                    you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our website.
                </p>
            </section>
        </TrustCentreArticle>
    );
}

export default CookiePolicy;
