import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import TemplateFormTableCard from "../components/compliance/templateForms/TemplateFormTableCard";
import moduleAreas, {
  moduleIcons,
  moduleUrls,
} from "../constants/moduleAreaConstants";
import useSiteActionsSummary from "../hooks/queries/useSiteActionsSummary";
import PageBase from "../../components/PageBase";
import PageTitle from "../components/PageTitle";
import SiteSelect from "../../components/SiteSelect";
import ActionsTableCard from "../components/ActionsTableCard";
import { useAuth } from "../../contexts/authContext";

const useStyles = makeStyles((theme) => ({
  titleGrid: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Monitoring() {
  const { Track } = useTracking({
    page: `Monitoring`,
  });

  const classes = useStyles();

  const { selectedSite, activeAppId, appSiteIds } =
    useSelector((state) => state.account);

  const { monitoringActionsTable: monitoring } = useSelector(
    (state) => state.tablePage
  );

  const { hasSafetyNestAllActionsRole } = useAuth();

  const { data } = useSiteActionsSummary({
    externalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
    fromDate: monitoring.fromDate,
    toDate: monitoring.toDate,
    forUser: !hasSafetyNestAllActionsRole ? true : monitoring.forUser,
    moduleArea: moduleAreas.MONITORING,
  });

  return (
    <PageBase>
      <Track>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className={classes.titleGrid}
        >
          <Grid item xs={12} md={9}>
            <PageTitle
              icon={moduleIcons.MONITORING}
              title="Monitoring"
              description="Checklists that enable you to log and view your periodic checks for health and safety and even operational checks all in one easily accessible place."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SiteSelect size="small" />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionsTableCard
              moduleArea={moduleAreas.MONITORING}
              summaryData={data}
            />
          </Grid>
          <Grid item xs={12}>
            <TemplateFormTableCard
              moduleArea={moduleUrls[moduleAreas.MONITORING]}
            />
          </Grid>
        </Grid>
      </Track>
    </PageBase>
  );
}
