import { addDays, addYears } from "date-fns";
import moduleAreas from "../../safetynest/constants/moduleAreaConstants";
import {
    SET_RISK_ASSESSMENTS_TABLE_PAGE,
    SET_CASES_TABLE_PAGE,
    SET_CONTACT_CASES_TABLE_PAGE,
    SET_TABLE_PAGE,
    SET_ACTIONS_TABLE_PAGE,
    SET_COMPLIANCE_MONITORING_ACTIONS_TABLE_PAGE,
    SET_FORM_INSIGHTS_TABLE_PAGE,
    SET_FORMS_TABLE_PAGE,
    SET_INCIDENTS_TABLE_PAGE
} from "../types";

const today = new Date();
const initialState = {
    casesTable: {
        page: 0,
        rowsPerPage: 15,
        displayAll: false,
        displayClosed: false,
        caseNumber: "",
        adviser: "",
        employee: "",
        description: "",
        primaryContact: 0,
        caseType: 0,
    },
    contactCasesTable: {
        page: 0,
        rowsPerPage: 5,
        displayAll: false,
    },
    actionsTable: {
        page: 0,
        rowsPerPage: 10,
        module: null,
        forUser: false,
        showCompleted: false,
        fromDate: null,
        toDate: null,
        rating: null,
        assignees: null,
        filteredText: null
    },
    complianceActionsTable: {
        page: 0,
        rowsPerPage: 10,
        module: moduleAreas.COMPLIANCE,
        forUser: false,
        showCompleted: false,
        fromDate: null,
        toDate: null,
        rating: null,
        assignees: null,
        filteredText: null
    },
    monitoringActionsTable: {
        page: 0,
        rowsPerPage: 10,
        module: moduleAreas.MONITORING,
        forUser: false,
        showCompleted: false,
        fromDate: null,
        toDate: null,
        rating: null,
        assignees: null,
        filteredText: null
    },
    incidentsActionsTable: {
        page: 0,
        rowsPerPage: 10,
        module: moduleAreas.INCIDENTS,
        forUser: false,
        showCompleted: false,
        fromDate: null,
        toDate: null,
        rating: null,
    },
    riskAssessmentsActionsTable: {
        page: 0,
        rowsPerPage: 10,
        module: moduleAreas.RISKASSESSMENTS,
        forUser: false,
        showCompleted: false,
        fromDate: null,
        toDate: null,
        rating: null,
        assignees: null,
        filteredText: null
    },
    auditActionsTable: {
        page: 0,
        rowsPerPage: 10,
        module: moduleAreas.AUDIT,
        forUser: false,
        showCompleted: false,
        fromDate: null,
        toDate: null,
        rating: null,
    },
    complianceFormsTable: { page: 0, rowsPerPage: 10, assignees: null, filteredText: null, fromDate: null, toDate: null, showHistoric:false },
    monitoringFormsTable: { page: 0, rowsPerPage: 10, assignees: null, filteredText: null, fromDate: null, toDate: null, showHistoric:false },
    incidentsTable: { page: 0, rowsPerPage: 10, assignees: null, filteredText: null, fromDate: null, toDate: null, filteredPersonText: null },
    riskAssessmentsTable: { page: 0, rowsPerPage: 10, riskLevel: null, assignees: null, filteredText: null, fromDate: null, toDate: null},
    complianceAdminTemplatesTable: { page: 0, rowsPerPage: 10 },
    monitoringAdminTemplatesTable: { page: 0, rowsPerPage: 10 },
    incidentsAdminTemplatesTable: { page: 0, rowsPerPage: 10 },
    riskAssessmentsAdminTemplatesTable: { page: 0, rowsPerPage: 10 },
    auditInsightsTable: { page: 0, rowsPerPage: 10 },
    complianceInsightsTable: {
        page: 0,
        rowsPerPage: 10,
        fromDate: addYears(today, -1).toISOString(),
        toDate: today.toISOString(),
        forms: null,
        formIds: null,
    },
    monitoringInsightsTable: {
        page: 0,
        rowsPerPage: 10,
        fromDate: addYears(today, -1).toISOString(),
        toDate: today.toISOString(),
        forms: null,
        formIds: null,
    },
    formsTable: {
        page: 0,
        rowsPerPage: 10,
        assignees: null,
        filteredText: null,
        module: null,
        fromDate: null,
        toDate: null,
    },
};
function tablePageReducer(state = initialState, action) {
    switch (action.type) {
        case SET_TABLE_PAGE:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    page: action.payload.page,
                    rowsPerPage: action.payload.rowsPerPage,
                    sortBy: action.payload.sortBy,
                    orderBy: action.payload.orderBy,
                    fromDate: action.payload.fromDate,
                    toDate: action.payload.toDate,
                    filteredText: action.payload.filteredText,
                    assignees: action.payload.assignees,
                    forms: action.payload.forms,
                    formIds: action.payload.formIds,
                    showHistoric: action.payload.showHistoric,
                },
            };  
        case SET_CASES_TABLE_PAGE:
            return {
                ...state,
                [action.payload.key]: {
                    page: action.payload.page,
                    rowsPerPage: action.payload.rowsPerPage,
                    displayAll: action.payload.displayAll,
                    displayClosed: action.payload.displayClosed,
                    caseNumber: action.payload.caseNumber,
                    adviser: action.payload.adviser,
                    employee: action.payload.employee,
                    description: action.payload.description,
                    primaryContact: action.payload.primaryContact,
                    caseType: action.payload.caseType,
                },
            };
        case SET_CONTACT_CASES_TABLE_PAGE:
            return {
                ...state,
                [action.payload.key]: {
                    page: action.payload.page,
                    rowsPerPage: action.payload.rowsPerPage,
                    displayAll: action.payload.displayAll,
                },
            };
        case SET_ACTIONS_TABLE_PAGE:
            return {
                ...state,
                [action.payload.key]: {
                    page: action.payload.page,
                    rowsPerPage: action.payload.rowsPerPage,
                    module: action.payload.module,
                    forUser: action.payload.forUser,
                    showCompleted: action.payload.showCompleted,
                    fromDate: action.payload.fromDate,
                    toDate: action.payload.toDate,
                    rating: action.payload.rating,
                    assignees: action.payload.assignees,
                    filteredText: action.payload.filteredText
                },
            };

        case SET_COMPLIANCE_MONITORING_ACTIONS_TABLE_PAGE:
            return {
                ...state,
                [action.payload.key]: {
                    page: action.payload.page,
                    rowsPerPage: action.payload.rowsPerPage,
                    forUser: action.payload.forUser,
                    showCompleted: action.payload.showCompleted,
                    fromDate: action.payload.fromDate,
                    toDate: action.payload.toDate,
                },
            };

        case SET_FORMS_TABLE_PAGE:
            return {
                ...state,
                [action.payload.key]: {
                    page: action.payload.page,
                    rowsPerPage: action.payload.rowsPerPage,
                    module: action.payload.module,
                    sortBy: action.payload.sortBy,
                    orderBy: action.payload.orderBy,
                    assignees: action.payload.assignees,
                    filteredText: action.payload.filteredText,
                    fromDate: action.payload.fromDate,
                    toDate: action.payload.toDate,
                },
            };

        case SET_RISK_ASSESSMENTS_TABLE_PAGE:
            return {
                ...state,
                [action.payload.key]: {
                    page: action.payload.page,
                    rowsPerPage: action.payload.rowsPerPage,
                    riskLevel: action.payload.riskLevel,
                    sortBy: action.payload.sortBy,
                    orderBy: action.payload.orderBy,
                    assignees: action.payload.assignees,
                    filteredText: action.payload.filteredText,
                    fromDate: action.payload.fromDate,
                    toDate: action.payload.toDate,
                },
            };

        case SET_INCIDENTS_TABLE_PAGE:
            return {
                ...state,
                [action.payload.key]: {
                    page: action.payload.page,
                    rowsPerPage: action.payload.rowsPerPage,
                    module: action.payload.module,
                    forUser: action.payload.forUser,
                    showCompleted: action.payload.showCompleted,
                    filteredText: action.payload.filteredText,
                    fromDate: action.payload.fromDate,
                    toDate: action.payload.toDate,
                    rating: action.payload.rating,
                    assignees: action.payload.assignees,
                    filteredPersonText: action.payload.filteredPersonText,
                    sortBy: action.payload.sortBy,
                    orderBy: action.payload.orderBy,
                    riddorReportable: action.payload.riddorReportable,
                    incidentCategoryId: action.payload.incidentCategoryId,
                },
            };

        case SET_FORM_INSIGHTS_TABLE_PAGE:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    page: 0,
                    fromDate: action.payload.fromDate,
                    toDate: action.payload.toDate,
                    forms: action.payload.forms,
                    formIds: action.payload.formIds,
                },
            };
        default:
            return state;
    }
}

export default tablePageReducer;
