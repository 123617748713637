export const trimTextToLength = (text, length = 200) => {
  if (!text) return;
  const excerpt = text.substring(0, length);
  return excerpt.length === length ? excerpt + "..." : excerpt;
};

export const buildQueryStringList = (name, arr) => {
  if (!arr) return "";
  return arr.length > 0 ? `?${name}=${arr.join(`&${name}=`)}` : "";
};

export const addQueryStringList = (name, arr) => {
    if (!arr) return "";
    return arr.length > 0 ? `&${name}=${arr.join(`&${name}=`)}` : "";
};

export const capitaliseFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

export const isEmpty = (string) =>
    string.trim().length === 0;

const stringUtils = {
  trimTextToLength,
  buildQueryStringList,
  addQueryStringList,
  isEmpty,
};

export default stringUtils;
