import { CircularProgress, IconButton, makeStyles } from "@material-ui/core"
import { CloudDownload, Delete } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import DropBoxContainer from "../../../components/documents/DropBoxContainer"
import { downloadFileFromBlob } from "../../../utils/fileUtils"
import { useActionDetails } from "../../contexts/ActionContext"
import useDownloadActionAttachment from "../../hooks/queries/useDownloadActionAttachment"

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    width: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: theme.spacing(2),
        width: '33vw',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    attachmentList: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(0.5),
    },
    attachment: {
        display: 'flex',
        alignItems: 'center',
    },
    attachmentIcons: {
        display: 'flex',
        columnGap: theme.spacing(1),
        marginLeft: 'auto',
    },
    attachmentTitle: {
        fontSize: '1.1rem',
        borderBottom: '1px solid #c4c1d3',
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    attachmentsContainer: {
        width: '100%',
    },
}))

export default function ActionAttachments() {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar();
    const { 
        actionAttachmentDetails,
        uploadAttachments,
        filesUploading,
        uploadProgress,
        handleDeleteFile,
        isEditAllowed,
        isDri
    } = useActionDetails()

    const [fileToDownload, setFileToDownload] = useState(null);
    
    const downloadFile = useDownloadActionAttachment(
        { reference: fileToDownload?.reference, isDri },
        { onSuccess: onDownloadFileSuccess, onError: onDownloadFileError }
    );

    function onDownloadFileSuccess(data) {
        if (!fileToDownload) {
          console.error("Could not download file. File in state is missing!");
          return;
        }

        downloadFileFromBlob(data, fileToDownload.name)
        setFileToDownload(null);
    }
    
    function onDownloadFileError(e) {
        console.error(e);
        enqueueSnackbar("Could not download file.", { variant: "error" });
    }

    const uploadAction = (upload) => {
        if (upload.isUploading) {
          return uploadProgress < 100 ? (
            <CircularProgress
              size={20}
              variant="determinate"
              value={uploadProgress}
            />
          ) : (
            <CircularProgress size={20} />
          );
        }
    
        if (
          fileToDownload?.reference === upload.reference &&
          downloadFile.isLoading
        )
          return <CircularProgress size={20} />;
    
        return (
          <>
            <IconButton
              edge="end"
              aria-label="download"
              onClick={() => setFileToDownload(upload)}
            >
              <CloudDownload />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleDeleteFile(upload.azureFileId)}
            >
              <Delete />
            </IconButton>
          </>
        );
    };

    const content = () => {
        if (actionAttachmentDetails.length === 0) {
            return (
                <div>No attachments have been uploaded.</div>
            )
        }

        return (
            <div className={classes.attachmentsContainer}>
                <div className={classes.attachmentTitle}>
                    Uploaded Attachments
                </div>
                <div className={classes.attachmentList}>
                    {actionAttachmentDetails
                        .filter((x) => !x?.toBeDeleted)
                        .map((upload, index) => (
                            <div className={classes.attachment} key={`${upload.name}-${index}`}>
                                <div>{upload.name}</div>
                                <div className={classes.attachmentIcons}>
                                    {uploadAction(upload)}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }

    const onDrop = useCallback(
        (acceptedFiles) => {
          acceptedFiles = acceptedFiles.filter(
            (file) =>
              !actionAttachmentDetails
                .filter(x => !x.azureFileId)
                .map((f) => f.lastModified + f.name)
                .includes(file.lastModified + file.name)
          );
          uploadAttachments(acceptedFiles);
        },
        [actionAttachmentDetails]
      );

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        disabled: filesUploading,
    });

    return (
        <div className={classes.container}>
            <div className={classes.width}>
                {isEditAllowed &&
                    <DropBoxContainer
                        getRootProps={getRootProps}
                        getInputProps={getInputProps}
                        isDragAccept={isDragAccept}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                        forActionAttachments
                    />
                }
                {content()}
            </div>
        </div>
    )
}