import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import productService from "../../services/productService";

const useProductRecommendation = (accountId) =>
    useQuery([queryKeys.productRecommendation, accountId],
        () => productService.getProductRecommendation(accountId),
        {
            enabled: !!accountId,
            staleTime: 600000,
            cacheTime: 900000
        }
    );

export default useProductRecommendation;
