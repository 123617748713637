import React, { useState } from "react";
import CardBase from "../../components/cards/CardBase";
import { useSelector } from "react-redux";
import { sub } from "date-fns";
import { ResponsiveBar } from "@nivo/bar";
import {
    ToggleButton,
    ToggleButtonGroup
} from "@material-ui/lab"


import {
    makeStyles,
    Typography
} from "@material-ui/core";
import useIncidentSummaryStats from "../hooks/queries/useIncidentSummaryStats";
import useIsMobile from "../../hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
    pieSettings: {
        height: 680
    },
    selected: {
        "&&": {
            backgroundColor: theme.palette.primary.main,
            color: "white"
        }
    },
    rangePicker: {
        marginBottom: theme.spacing(2),
    },

}));



function IncidentsChartCard() {
    const classes = useStyles();
    const isMobile = useIsMobile();
    const { selectedSite, activeAppId, appSiteIds } = useSelector(
        (state) => state.account
    );
    const [weekCount, setWeekCount] = useState(1);

    const [fromDate, setFromDate] = useState((sub(new Date(), { weeks: weekCount })))
    const [toDate, setToDate] = useState(new Date())

    const { data, isLoading, error, isFetching } = useIncidentSummaryStats({
        fromDate,
        toDate,
        weekCount,
        externalIds: selectedSite
            ? [selectedSite.externalId]
            : appSiteIds[activeAppId],
    });



    //get a list of the incidents returned
    const validIncidentTypeList = data && [...new Set(data.map(({ incidents }) => incidents.map(({ incidentType }) => incidentType)).flat())];

    const chartData = data && data.map(p => {
        const periodData = p.incidents.reduce((prev, curr) => ({ ...prev, [curr.incidentType]: curr.incidentCount }), {})
        periodData.period = p.period
        return periodData;
    });


    const handleTimeframeChange = (event, newWeekCount) => {
        if (newWeekCount) {
            setWeekCount(newWeekCount);
            let newDate = new Date()
            setToDate(newDate)
            setFromDate(sub(newDate, { weeks: newWeekCount }))
        }
    };

    const keys = validIncidentTypeList;
    const commonProps = {
        margin: { top: 0, right: 0, bottom: isMobile ? 250 : 180 , left: 80 },
        data: chartData,
        indexBy: "period",
        keys,
        padding: 0.5,
        enableLabel: false,
        borderWidth: "1px",
        innerPadding: 3,
        labelTextColor: "inherit:darker(1.4)",
        labelSkipWidth: 16,
        labelSkipHeight: 16,
        axisBottom: {
            tickRotation: -45,
        },
        axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Incident  Count",
            legendPosition: "middle",
            legendOffset: -40,
            format: e => Math.floor(e) === e && e
        }

    };

    const cardContent = () => {
        if (chartData)
            return (
                <div className={classes.pieSettings}>
                    <ToggleButtonGroup
                        className={classes.rangePicker}
                        color="primary"
                        value={weekCount.toString()}
                        exclusive
                        onChange={handleTimeframeChange}
                    >
                        <ToggleButton value="1" classes={{ selected: classes.selected }}>7 Days</ToggleButton>
                        <ToggleButton value="4" classes={{ selected: classes.selected }}>4 Weeks</ToggleButton>
                        <ToggleButton value="26" classes={{ selected: classes.selected }}>6 Months</ToggleButton>
                        <ToggleButton value="52" classes={{ selected: classes.selected }}>12 Months</ToggleButton>
                    </ToggleButtonGroup>
                    
                        <ResponsiveBar {...commonProps}
                            legends={[
                                {
                                    anchor: isMobile ? 'bottom-left': 'bottom',
                                    dataFrom: 'keys',
                                    direction: isMobile?'column': 'row' ,
                                    itemHeight: 20,
                                    itemWidth: 150,
                                    toggleSerie: true,
                                    translateX: 0,
                                    translateY: isMobile ? 180 : 100,
                                    itemDirection: isMobile ? ('left-to-right') : ('top-to-bottom'),
                                },
                            ]}
                    />
                    
                </div>
            );
        return <Typography>No Incident Data</Typography>;
    };

    return (
        <CardBase
            title={"Incidents Breakdown"}
              isLoading={isLoading}
              isFetching={isFetching}
              error={error}
            fullHeight
        >
            {cardContent()}
        </CardBase>
    );
}

export default IncidentsChartCard;
