import {
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
  Link
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 0,
  },
  content: {
    padding: theme.spacing(2),
  },
  image: {
    height: "120px",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: "20px",
    cursor: "pointer"
  },
  excerpt: {
    marginBottom: theme.spacing(2),
    fontSize: "11.2px",
  },
}));

const BlogPostCard = ({ blogPost }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} elevation={0}>
      <CardMedia
        className={classes.image}
        image={blogPost.image}
        title={blogPost.title}
      />
      <CardContent className={classes.content}>
        <Link href={blogPost.link} className={classes.title} variant="h5" target="_blank" underline="hover">
          {blogPost.title}
        </Link>
        <Typography className={classes.excerpt} variant="body2" component="p">
          {blogPost.excerpt}
        </Typography>
        <Typography color="textSecondary">{blogPost.createdOn}</Typography>
      </CardContent>
    </Card>
  );
};

export default BlogPostCard;
