import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateService from "../../services/templateService";
const useTemplateVersion = (templateVersionId, options = {}) =>
  useQuery(
    [queryKeys.templateVersion, templateVersionId],
    () => templateService.fetchTemplateVersion(templateVersionId),
    {
      enabled: !!templateVersionId,
      ...options,
    }
  );
export default useTemplateVersion;
