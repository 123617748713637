import axios from "../../utils/axios";
import { buildQueryStringList } from "../../utils/stringUtils";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const getBySiteExternalId = ({ siteExternalId, pageNum, pageSize }) =>
    axios.get(`${apiUrl}/audit/forsite/${siteExternalId}?pageSize=${pageSize}&pageNum=${pageNum}`);

const fetchAuditsForSite = (externalIds, auditType) =>
    axios.get(
        `${apiUrl}/audit/site/${auditType}${buildQueryStringList(
            "siteExternalIds",
            externalIds
        )}`
    );

const downloadPdfByAuditId = (auditId, isDriAudit) =>
    axios.get(`${apiUrl}/audit/download/${auditId}?isDriAudit=${isDriAudit}`, {
        responseType: "arraybuffer",
    });

const fetchLatestAuditTypeForSites = (externalIds) =>
    axios.get(
        `${apiUrl}/audit/sitelatesttype/${buildQueryStringList(
            "siteExternalIds",
            externalIds
        )}`
    );

const replacePdfForAudit = ({ auditId, file }) => {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(
        `${apiUrl}/audit/replacepdf/${auditId}`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
};

const uploadAuditActionFile = async (
    formData,
    siteExternalId,
    auditId
) => {
    try {
        const response = await axios.post(
            `${apiUrl}/audit/uploadAuditActionFile/${siteExternalId}/${auditId}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const fetchAuditInsights = (externalIds, auditType) => axios.get(`${apiUrl}/audit/insights/${auditType}${buildQueryStringList("siteExternalIds",externalIds)}`);

const auditService = {
    fetchAuditsForSite,
    downloadPdfByAuditId,
    fetchLatestAuditTypeForSites,
    getBySiteExternalId,
    replacePdfForAudit,
    uploadAuditActionFile,
    fetchAuditInsights
};

export default auditService;
