import { useMutation } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import actionService  from "../../services/actionService";

const { client } = reactQueryUtils

const useCreateAction = () => 
    useMutation(
        (action) => actionService.create(action),
        {
            onMutate: async ({ action }) => {
                const queryKey = [queryKeys.currentActions];

                await client.cancelQueries(queryKey);

                const previousAction = client.getQueryData(queryKey);

                client.setQueryData(queryKey, (old) => 
                    old ? [...old, action] : [action]
                )

                return { previousAction, queryKey };
            },
            onError: (_err, _newDocument, context) => {
                if (!context) return;
                client.setQueryData(context.queryKey, context.previousAction);
            },
            onSettled: () => {
                client.invalidateQueries(queryKeys.currentActions)
            }
        }
    )

export default useCreateAction