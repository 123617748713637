import axios from "../utils/axios";

const contactEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/contact`;

const fetchContactsForApp = ({ activeAppId, rootExternalId }) => {
  return axios.get(
    `${contactEndpointUrl}/App/${rootExternalId}/${activeAppId}`
  );
};

const contactService = {
  fetchContactsForApp,
};

export default contactService;
