import {
  SELECT_ACCOUNT,
  SELECT_SITE,
  SET_ACTIVE_APPID,
  SET_SITES,
  SET_ROOT_ACCOUNTS,
  HIDE_CASENEST_TERMS_BANNER,

} from "../types";

export function selectSite(site) {
  return {
    type: SELECT_SITE,
    payload: site,
  };
}

export function selectAccount(account) {
  return {
    type: SELECT_ACCOUNT,
    payload: account,
  };
}

export function setRootAccounts(rootAccounts) {
  return {
    type: SET_ROOT_ACCOUNTS,
    payload: rootAccounts,
  };
}

export function setActiveAppId(activeAppId) {
  return {
    type: SET_ACTIVE_APPID,
    payload: activeAppId,
  };
}

export function setSites({ sites, appId }) {
  return {
    type: SET_SITES,
    payload: { sites, appId },
  };
}

export function hideCaseNestTermsBanner() {
    return {
        type: HIDE_CASENEST_TERMS_BANNER,
    };
}

