import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CardBase from "../../../../components/cards/CardBase";
import ErrorBox from "../../../../components/ErrorBox";
import ResponsiveSelect from "../../../../components/ui/ResponsiveSelect";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import {
    Search as SearchIcon,
    Clear as ClearIcon,
} from "@material-ui/icons";
import { sortOrderName } from "../../../constants/sortOrder";

const useStyles = makeStyles((theme) => ({
  paddingAndMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  bottomGap: {
    marginBottom: theme.spacing(2),
  },
}));

const headCells = [
    {
        id: "templateName",
        label: "Template Name",
        isSortable: true,
    },
    {
        id: "sector",
        label: "Sector",
        isSortable: true,
    },
    {
        id: "subject",
        label: "Subject",
        isSortable: true,
    },
    {
        id: "frequency",
        label: "Frequency",
        isSortable: true,
    },
    {
        id: "admin",
        label: "",
        isSortable: false,
    },
]

export default function FilteredTemplatesTable({
  data,
  addedTemplates,
  addTemplate,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  sectorOptions,
  subjectOptions,
  frequencyOptions,
  selectedSector,
  selectedSubject,
  selectedFrequency,
  handleSectorChange,
  handleSubjectChange,
  handleFrequencyChange,
  isLoading,
  error,
  sectors,
  subjects,
  frequencies,
  onFilteredTextChange,
  filteredText,
  onFilteredTextSearch,
  clearFilteredText,
  sortBy,
  orderBy,
  onOrderByChange,
  onSortByChange,
}) {
 const classes = useStyles();
 const [searchRequested, setSeachRequested] = React.useState(false);

  const tableContent = () => {
    if (data.results?.length > 0) {
      return data.results?.map((template) => (
        <TableRow key={template.id}>
          <TableCell padding="none">{template.name}</TableCell>
          <TableCell padding="none">{template.sector}</TableCell>
          <TableCell padding="none">{template.subject}</TableCell>
          <TableCell padding="none">{template.frequencyDescription}</TableCell>
          <TableCell padding="none" align="right">
            <Button
              className={classes.button}
              variant="text"
              color="secondary"
              size="small"
              disabled={addedTemplates.some(
                (x) => x.template.id === template.id
              )}
              onClick={() => addTemplate(template)}
            >
              Add
            </Button>
          </TableCell>
        </TableRow>
      ));
    }

    return (
      <TableRow>
        <TableCell colSpan={5}>No templates found.</TableCell>
      </TableRow>
    );
    };

    function handleSearchChange(e) {
        setSeachRequested(false);
        onFilteredTextChange(e.target.value)
    }

    const handleSearchClick = () => {
        setSeachRequested(true);
        onFilteredTextSearch();
    };

    const showFilteredTextError = () => {
        return (filteredText && filteredText.length > 0 && filteredText.length < 4 && searchRequested)
    }

    const onSortClick = (event, sortColumn) => {
        const isAsc = sortBy === sortColumn && orderBy === sortOrderName.ASC;
        onOrderByChange(isAsc ? sortOrderName.DESC : sortOrderName.ASC);
        onSortByChange(sortColumn);
    };

  return (
    <div className={classes.bottomGap}>
      <CardBase title="Templates">
        <Grid
          className={classes.paddingAndMargin}
          container
          spacing={2}
          xs={12}
          md={12}
        >
          <Grid item xs={12} md={3}>
            <ResponsiveSelect
              options={
                sectors?.data && sectorOptions.length === 1
                  ? sectorOptions.concat(sectors?.data)
                  : sectorOptions
              }
              optionLabelFunction={(x) => x.name}
              optionValueKey={"id"}
              margin="dense"
              style={{ margin: 0 }}
              label="Sector"
              placeholder="Sector"
              fullWidth
              onChange={handleSectorChange}
              value={selectedSector}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ResponsiveSelect
              options={
                subjects?.data && subjectOptions.length === 1
                  ? subjectOptions.concat(subjects?.data)
                  : subjectOptions
              }
              optionLabelFunction={(x) => x.name}
              optionValueKey={"id"}
              margin="dense"
              style={{ margin: 0 }}
              label="Subject"
              placeholder="Subject"
              fullWidth
              onChange={handleSubjectChange}
              value={selectedSubject}
            />
                  </Grid>
            <Grid item xs={12} md={3}>
                <ResponsiveSelect
                          options={
                              frequencies && frequencyOptions.length === 1
                                  ? frequencyOptions.concat(frequencies)
                                  : frequencyOptions
                          }
                          optionLabelFunction={(x) => x.label}
                          optionValueKey={"value"}
                          margin="dense"
                          style={{ margin: 0 }}
                          label="Frequency"
                          placeholder="Frequency"
                          fullWidth
                          onChange={handleFrequencyChange}
                          value={selectedFrequency}
                      />
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.searchField}>
                      <TextField
                          className={classes.searchTextField}
                          label="Search Template Name"
                          variant="outlined"
                          size="small"
                          value={filteredText}
                          onChange={handleSearchChange}
                          helperText='At least 4 character to search on'
                          error={showFilteredTextError()}
                          FormHelperTextProps={{ hidden: !showFilteredTextError() }}
                          InputProps={{
                              endAdornment: (
                                  <InputAdornment position="end">
                                      {filteredText && (
                                          <Tooltip title="Clear" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                              <IconButton className={classes.iconBtn}>
                                                  <ClearIcon className={`clearIcon ${classes.clearIcon}`}
                                                      onClick={clearFilteredText}
                                                  />
                                              </IconButton>
                                          </Tooltip>
                                      )}
                                      <Tooltip title="Search" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                          <IconButton className={classes.iconBtn}>
                                              <SearchIcon
                                                  onClick={handleSearchClick}
                                              />
                                          </IconButton>
                                      </Tooltip>
                                  </InputAdornment>
                              ),
                              className: classes.endAdornment
                          }}
                  />
             </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          {isLoading ? (
            <Box display="flex" width="100%" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          ) : error ? (
            <ErrorBox message={error?.message ?? "Could not load templates."} />
          ) : (
            <div className={classes.paddingAndMargin}>
              <TableContainer>
                <Table>
                    <EnhancedTableHead
                        headCells={headCells}
                        sortColumn={sortBy}
                        sortDirection={orderBy}
                        onRequestSort={onSortClick}
                    />
                  <TableBody>{tableContent()}</TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data?.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </Grid>
      </CardBase>
    </div>
  );
}
