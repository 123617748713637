import { Button, CircularProgress, Divider, makeStyles, Typography } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import CardBase from "../../../components/cards/CardBase"
import { isAzureFile, useHistoricAuditContext } from "../../contexts/HistoricAuditContext"
import useUpdateHistoricAudit from "../../hooks/mutations/useUpdateHistoricAudit"
import { useHistory } from "react-router-dom"
import { useSnackbar } from "notistack"
import Details from "../../components/historicaudits/Details"
import AuditReport from "../../components/historicaudits/AuditReport"
import HistoricAuditActionsTable from "../../components/historicaudits/HistoricAuditActionsTable"
import EditHistoricActionDialog from "../../components/historicaudits/dialogs/EditHistoricActionDialog"
import { useHistoricActionContext } from "../../contexts/HistoricActionContext"
import CreateHistoricActionDialog from "../../components/historicaudits/dialogs/CreateHistoricActionDialog"
import DeleteHistoricActionDialog from "../../components/historicaudits/dialogs/DeleteHistoricActionDialog"
import { useSiteSearchContext } from "../../contexts/SiteSearchContext"

const useStyles = makeStyles(theme => ({
    auditId: {
        marginBottom: theme.spacing(2)
    },
    form: {
        marginTop: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr'
        }
    },
    columnSpan: {
        gridColumn: '1 / 3',
        [theme.breakpoints.down('sm')]: {
            gridColumn: '1 / 1'
        }
    },
    rightAlignedButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        gridColumn: '1 / 3',
        [theme.breakpoints.down('sm')]: {
            gridColumn: '1 / 1'
        }
    },
    newAction: {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(1),
    },
}))

export default function EditHistoricAudit() {
    const classes = useStyles()
    const history = useHistory()

    const { 
        historicAuditDetails, 
        selectedAudit,
        reset: resetAudit,
        setSelectedAudit,
        setIsPDFError,
    } = useHistoricAuditContext()

    const { selectedSite } = useSiteSearchContext()

    const {
        editHistoricActionDialogOpen,
        setEditHistoricActionDialogOpen,
        reset: resetAction,
        setIsCreationMode,
        setCreateHistoricActionDialogOpen,
        createHistoricActionDialogOpen,
        deleteHistoricActionDialogOpen,
        setDeleteHistoricActionDialogOpen,
        historicActionIdToDelete,
        handleDeleteHistoricAction,
    } = useHistoricActionContext()

    const updateHistoricAudit = useUpdateHistoricAudit()

    const { enqueueSnackbar } = useSnackbar()

    function renderAuditId() {
        return (
            <Typography className={classes.auditId} variant="h5">
                {selectedAudit?.id}
            </Typography>
        )
    }

    function handleUpdateHistoricAudit(e) {
        e.preventDefault()
        
        const documentToUpload = (!historicAuditDetails.document || isAzureFile(historicAuditDetails.document)) ? null : historicAuditDetails.document
        
        if (!historicAuditDetails.document) {
            setIsPDFError(true)
            return
        } 
        
        historicAuditDetails.document = documentToUpload

        updateHistoricAudit.mutate({
            ...historicAuditDetails,
            historicAuditId: selectedAudit?.id,
            siteExternalId: selectedSite?.externalId
        })
        
        returnToAudits()
        enqueueSnackbar('Historic Audit updated successfully', { variant: 'success' })
    }

    function handleCancel() {
        returnToAudits()
    }

    function handleOnCancel() {
        resetAction()
        setCreateHistoricActionDialogOpen(false)
        setEditHistoricActionDialogOpen(false)
    }

    function returnToAudits() {
        resetAudit()
        setSelectedAudit(undefined)
        history.goBack()
    }

    function divider() {
        return (
            <div className={classes.columnSpan}>
                <Divider />
            </div>
        )
    }

    if (!selectedAudit) {
        return <CircularProgress />
    }

    function handleCreateClick() {
        setIsCreationMode(true)
        setCreateHistoricActionDialogOpen(true)
    }

    function createHistoricAction() {
        return (
            <Button 
                className={classes.newAction}
                variant='text'
                onClick={handleCreateClick}
                color='secondary'
            >
                <Add />
                Create New
            </Button>
        )
    }
    
    return (
        <div>
            <EditHistoricActionDialog 
                open={editHistoricActionDialogOpen}
                onCancel={() => handleOnCancel()}
            />            
            <CreateHistoricActionDialog
                open={createHistoricActionDialogOpen}
                onCancel={() => handleOnCancel()}
            />
            <DeleteHistoricActionDialog 
                open={deleteHistoricActionDialogOpen}
                onCancel={() => setDeleteHistoricActionDialogOpen(false)}
                actionId={historicActionIdToDelete}
                onDelete={() => handleDeleteHistoricAction({historicActionId: historicActionIdToDelete, historicAuditId: selectedAudit.id})}
            />
            {renderAuditId()}
            <CardBase>
                <form 
                    className={classes.form}
                    onSubmit={(e) => handleUpdateHistoricAudit(e)}
                    onReset={handleCancel}
                >
                    <Details />
                    {divider()}
                    <AuditReport />
                    {divider()}
                    <div className={classes.columnSpan}>
                        <CardBase
                            flat      
                            title="Actions" 
                            rightComponent={createHistoricAction()}                   
                        >
                            <HistoricAuditActionsTable />
                        </CardBase>
                    </div>
                    <div className={classes.rightAlignedButtons}>
                        <Button
                            variant="outlined"
                            color="primary"
                            type="reset"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                    </div>
                </form>
            </CardBase>
        </div>
    )
}