import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";

export default function ScoreCalculationDialog({ open, onClose }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                How your SafetyNest Score is calculated
            </DialogTitle>
            <DialogContent>
                <Typography>
                    We calculate your SafetyNest score to give you an understanding of how you are managing Health and Safety, and what areas need your attention.
                    <br />
                    <br />
                    The SafetyNest scoring model looks at each module and considers the history of usage, available forms, incident history, and gives weighting to greater risk items and overdue actions.
                    <br />
                    <br />
                    Our model is unique, it&apos;s not just looking at what you haven&apos;t done, it gives you a linear view as to how you are progressing with safety maturity in your organisation.
                    <br />
                    <br />
                    Your score is updated in real-time, so what&apos;s stopping you?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={onClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}