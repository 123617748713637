import { makeStyles, TextField, Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { auditType, getAuditTypeDescription } from "../../../constants/auditConstants";
import { useHistoricAuditContext } from "../../contexts/HistoricAuditContext";
import StringUtils from "../../utils/StringUtils";
import ResponsiveSelect from "../../../components/ui/ResponsiveSelect";

const useStyles = makeStyles((theme) => ({
    columnSpan: {
        gridColumn: '1 / 3',
        [theme.breakpoints.down('sm')]: {
            gridColumn: '1 / 1'
        }
    }
}))

const formDatePickers = [
    {
        label: 'Created Date',
        value: 'createdDate',
    },
    {
        label: 'Last Modified Date',
        value: 'lastModifiedDate',
    },
    {
        label: 'Start Date',
        value: 'startDate',
    },
    {
        label: 'End Date',
        value: 'endDate',
    }
]

const formDateFormat = 'dd/MM/yyyy'

export default function Details() {
    const classes = useStyles()

    const { 
        historicAuditDetails,
        setCreatedDate,
        setLastModifiedDate,
        setStartDate,
        setEndDate,
        setType,
        setDescription,
        isCreationMode,
        requiresCreateValidation,
        setRequiresCreateValidation,
    } = useHistoricAuditContext()

    function setDate(date, datePicker) {
        switch (datePicker) {
            case 'createdDate':
                setCreatedDate(date)
                break
            case 'lastModifiedDate':
                setLastModifiedDate(date)
                break
            case 'startDate':
                setStartDate(date)
                break
            case 'endDate':
                setEndDate(date)
                break
        }
    }

    function showDatePickerError(datePicker) {
        return (!isCreationMode && !historicAuditDetails?.[datePicker]) || (isCreationMode && requiresCreateValidation && !historicAuditDetails?.[datePicker])
    }

    function showTypeError() {
        return (!isCreationMode && (historicAuditDetails?.type === null || historicAuditDetails?.type === undefined)) || (isCreationMode && requiresCreateValidation && (historicAuditDetails?.type === null || historicAuditDetails?.type === undefined))
    }

    function showDescriptionError() {
        return (!isCreationMode && (!historicAuditDetails?.description || StringUtils.isNullOrWhiteSpace(historicAuditDetails?.description))) || (isCreationMode && requiresCreateValidation && (!historicAuditDetails?.description  || StringUtils.isNullOrWhiteSpace(historicAuditDetails?.description)))
    }

    function renderDatePickers() {
        return formDatePickers.map(datePicker => (
            <KeyboardDatePicker 
                onInvalid={() => setRequiresCreateValidation(true)}
                required
                value={historicAuditDetails?.[datePicker.value]}
                format={formDateFormat}
                label={datePicker.label}
                inputVariant="outlined"
                onChange={(date) => setDate(date, datePicker.value)}
                fullWidth
                error={showDatePickerError(datePicker.value)}
                helperText={`Please enter a valid ${datePicker.label}`}
                FormHelperTextProps={{ hidden: !showDatePickerError(datePicker.value) }}
            />
        ))
    }
    
    return (
        <>
            <Typography className={classes.columnSpan} variant="h6">
                Details
            </Typography>
            {renderDatePickers()}
            <ResponsiveSelect
                required
                fullWidth
                label="Audit Type"
                options={Object.keys(auditType).map(key => ({ value: auditType[key], label: getAuditTypeDescription(auditType[key]) }))}
                optionValueKey="value"
                optionLabelKey="label"
                value={historicAuditDetails?.type}
                onChange={(e) => setType(e.target.value)}
                onInvalid={() => setRequiresCreateValidation(true)}
                error={showTypeError()}
                helperText='Please select an audit type'
                FormHelperTextProps={{ hidden: !showTypeError() }}
            />
            <TextField 
                required
                fullWidth                
                onInvalid={() => setRequiresCreateValidation(true)}
                className={classes.columnSpan}
                label="Description"
                variant="outlined"
                value={historicAuditDetails?.description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                minRows={3}
                helperText='Please enter a description'
                error={showDescriptionError()}
                FormHelperTextProps={{ hidden: !showDescriptionError() }}
            />
        </>
    )
}