import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateFormService from "../../services/templateFormService";


const useTemplateFormInsights = (siteExternalIds, moduleArea, fromDate, toDate, formIds) => useQuery(
    [
        queryKeys.templateFormInsights,
        siteExternalIds?.join(","),
        moduleArea,
        fromDate,
        toDate,
        formIds,
    ],
    () => templateFormService.fetchTemplateFormInsights(siteExternalIds, moduleArea, fromDate, toDate, formIds),
    {
        enabled: !!siteExternalIds?.length,
        staleTime: 60000
    }
);

export default useTemplateFormInsights;