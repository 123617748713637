import { combineReducers } from "redux";
import accountReducer from "./accountReducer";
import templateBuilderReducer from "./templateBuilderReducer";
import formCompleterReducer from "./formCompleterReducer";
import pageReducer from "./pageReducer";
import tutorialReducer from "./tutorialReducer";
import cookieReducer from "./cookieReducer";
import documentsReducer from "./documentsReducer";
import tablePageReducer from "./tablePageReducer";
import adminReducer from "./adminReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  templateBuilder: templateBuilderReducer,
  formCompleter: formCompleterReducer,
  page: pageReducer,
  tutorial: tutorialReducer,
  cookie: cookieReducer,
  documents: documentsReducer,
  tablePage: tablePageReducer,
  admin: adminReducer,
});

export default rootReducer;
