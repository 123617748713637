export const auditTypes = {
  GRA: "GRA",
  FRA: "FRA",
};

export const auditType = {
  GeneralRiskAssessment: 0,
  WorkplaceInspection: 1,
  FireRiskAssessment: 2,
}

const auditTypeDescription = {
  [auditType.GeneralRiskAssessment]: "General Risk Assessment",
  [auditType.WorkplaceInspection]: "Workplace Inspection",
  [auditType.FireRiskAssessment]: "Fire Risk Assessment",
}

export const getAuditTypeDescription = (auditType) => auditTypeDescription[auditType];