import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import prismicService from "../../services/prismicService";

const usePrismicCategoryDownloads = (sectionName, categoryId, pageNumber, searchTerm) =>
    useQuery(
        [queryKeys.prismicCategoryDownloads, categoryId, pageNumber, searchTerm],
        () => prismicService.fetchCategoryDownloads(sectionName, categoryId, pageNumber, searchTerm)
    );

export default usePrismicCategoryDownloads;