import axios from "../utils/axios";
import stringUtils, { buildQueryStringList } from "../utils/stringUtils";

const apiUrl = process.env.REACT_APP_AUTH_API_URL;

const getUserDetails = (email) =>
  axios.get(`${apiUrl}/user/GetSalesForceUserByEmail/${email}`);

const getWelcomePackInfo = (orgId) =>
  axios.get(`${apiUrl}/welcome/GetWelcomePackInfo/${orgId}`);

const getContactsForAccountIds = (externalIds) => {
  const queryString = stringUtils.buildQueryStringList(
    "externalIds",
    externalIds
  );

  return axios.get(`${apiUrl}/Contact/Accounts${queryString}`);
};

const updateUser = (updates) =>
  axios.patch(`${apiUrl}/Contact/Update`, updates);

const addNewUser = (user) => axios.post(`${apiUrl}/Contact`, user);

const getAppsForAccountAdmin = (accountId) =>
  axios.get(`${apiUrl}/App/Account/${accountId}`);

const searchForAccountLinkableUser = ({ accountId, email }) =>
  axios.get(`${apiUrl}/Contact/Search/Account/Link/${accountId}/${email}`);

const deleteAccountContactRelation = ({ relationId, accountSaasAppIds }) => {
  const queryString = buildQueryStringList(
    "deletedAccountAppIds",
    accountSaasAppIds
  );
  return axios.delete(`${apiUrl}/Contact/Account/${relationId}${queryString}`);
};

const addAccountContactRelation = ({ newUser, accountId }) =>
  axios.post(`${apiUrl}/Contact/Account/${accountId}/${newUser.externalId}`);

const salesforceService = {
  getUserDetails,
  getWelcomePackInfo,
  getContactsForAccountIds,
  updateUser,
  addNewUser,
  getAppsForAccountAdmin,
  searchForAccountLinkableUser,
  deleteAccountContactRelation,
  addAccountContactRelation,
};

export default salesforceService;
