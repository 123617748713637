import axios from "../utils/axios";

const apiUrl = process.env.REACT_APP_LEGAL_URL + "/client";

const getByUserExternalId = (userExternalId) =>
  axios.get(`${apiUrl}/UserAlertSetting/${userExternalId}`);

const update = (userAlertSetting) =>
  axios.patch(`${apiUrl}/UserAlertSetting`, userAlertSetting);

const create = (userAlertSetting) =>
  axios.post(`${apiUrl}/UserAlertSetting`, userAlertSetting);

const caseNestUserAlertSettingService = {
  getByUserExternalId,
  update,
  create,
};

export default caseNestUserAlertSettingService;