import axios from "../../utils/axios";
import stringUtils from "../../utils/stringUtils";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const fetchConsultantForAccounts = (accountExternalIds) => {
  const queryString = stringUtils.buildQueryStringList(
    "externalIds",
    accountExternalIds
  );
  return axios.get(`${apiUrl}/consultant/accounts${queryString}`);
};

const consultantService = {
  fetchConsultantForAccounts,
};

export default consultantService;
