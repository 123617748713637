import { format } from "date-fns";

export const formatTime = (s) => {
  let timeString = "";
  let hours = Math.floor(s / 3600);
  let minutes = Math.floor((s - hours * 3600) / 60);
  let seconds = Math.floor(s - hours * 3600 - minutes * 60);

  timeString = hours ? timeString + hours + "h " : timeString;
  timeString = minutes ? timeString + minutes + "m " : timeString;
  timeString = seconds ? timeString + seconds + "s" : timeString;

  return timeString;
};

export const formatDigitalTime = (s) => {
  let hours = Math.floor(s / 3600);
  let minutes = Math.floor((s - hours * 3600) / 60);
  let seconds = Math.floor(s - hours * 3600 - minutes * 60);

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

export const formatShortTime = (s) => {
  let timeString = "";
  let hours = Math.floor(s / 3600);
  let minutes = Math.floor((s - hours * 3600) / 60);

  timeString = hours ? timeString + hours + "h " : timeString;
  timeString = minutes ? timeString + minutes + "m " : timeString;

  return timeString;
};

export const formatShortDate = (dateString, options = {}) =>
  format(new Date(dateString), "dd/MM/yyyy", options);

export const formatISODateTime = (dateString) =>
  format(new Date(dateString), "yyyy-MM-dd'T'HH:mm:ss")

export const formatShortMonthDate = (dateString) =>
  format(new Date(dateString), "dd MMM yyyy");

  export const formatShortMonthDateAndTime = (dateString) =>
    format(new Date(dateString), "dd MMM yyyy HH:mm");