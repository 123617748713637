import { useMutation } from "react-query";
import templateService from "../../services/templateService";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";

const useDisableTemplate = () =>
  useMutation(templateService.disableTemplate, {
    onMutate: async ({
      templateId,
      applicationArea,
      page,
      rows,
      accountId,
      siteExternalIds,
    }) => {
      const { client } = reactQueryUtils;
      const queryKey = [
        queryKeys.templates,
        applicationArea,
        { page, rows },
        accountId,
        siteExternalIds?.join(","),
      ];
      await client.cancelQueries(queryKey);
      const previousTemplates = client.getQueryData(queryKey);
      if (previousTemplates) {
        client.setQueryData(queryKey, (previous) => ({
          totalCount: previous.totalCount - 1,
          results: previous.results.filter((x) => x.id !== templateId),
        }));
      }

      return { previousTemplates };
    },
    onError: (error, newTemplate, context) => {
      reactQueryUtils.client.setQueryData(
        [queryKeys.templates, newTemplate.applicationArea],
        context.previousTemplates
      );
    },
    onSettled: (_data, _error, variables) => {
      if (variables)
        reactQueryUtils.client.invalidateQueries([
          queryKeys.templates,
          variables.applicationArea,
        ]);
    },
  });

export default useDisableTemplate;
