import {
    Button,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Grid,
    Checkbox,
    InputAdornment,
    TextField,
    Typography,
    Tooltip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CardBase from "../../../components/cards/CardBase";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import useRiskAssessments from "../../hooks/queries/useRiskAssessments";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SkeletonTableLoader from "../common/SkeletonTableLoader";
import { Alert } from "@material-ui/lab";
import { parseISO, isValid } from "date-fns";
import { useRiskAssessmentDetails } from "../../contexts/RiskAssessmentContext";
import { formatShortDate } from "../../../utils/dateTimeUtils";
import { setRiskAssessmentsTablePage } from "../../../redux/actions/tablePageActions";
import { tablePage } from "../../constants/tablePageConstants";
import StatusChip from "./StatusChip";
import ResponsiveSelect from "../../../components/ui/ResponsiveSelect";
import { riskLevelDropdownValues } from "../../constants/riskAssessmentRiskLevelConstants";
import {
    MoreVert as MoreVertIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlank,
    CheckBox as CheckBox,
    Search as SearchIcon,
    Clear as ClearIcon,
} from "@material-ui/icons";
import { useAuth } from "../../../contexts/authContext";
import RiskAssessmentDeleteDialog from "./dialogs/RiskAssessmentDeleteDialog";
import RiskAssessmentAmendDialog from "./dialogs/RiskAssessmentAmendDialog";
import useContactsForAppAndSite from "../../../hooks/useContactsForAppAndSite";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import { sortOrderName } from "../../constants/sortOrder";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
    },
    tableRow: {
        cursor: "pointer",
    },
    keyboardBtn: {
        padding: theme.spacing(0),
        marginRight: theme.spacing(0),
    },
    toolbar: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    addBtn: {
        margin: theme.spacing(1, 0),
        [theme.breakpoints.up("md")]: {
            margin: 0,
        },
    },
        searchField: {
            marginLeft: 'auto',
        },
        searchTextField: {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
            width: "100%",
            '& .clearIcon': {
                visibility: 'hidden',
            },
            '&:hover .clearIcon': {
                visibility: 'visible',
            },
        },
        endAdornment: {
            paddingRight: theme.spacing(1),
        },
    assignedString: {
        maxWidth: "86%",
    },
    toolbar: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    searchTextField: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: "100%",
        '& .clearIcon': {
            visibility: 'hidden',
        },
        '&:hover .clearIcon': {
            visibility: 'visible',
        },
    },
    endAdornment: {
        paddingRight: theme.spacing(1),
    },
    tooltip: {
        margin: theme.spacing(1),
        backgroundColor: 'white',
        border: '1px solid #000',
        color: theme.palette.common.black,
        fontSize: '0.9em',
        borderRadius: 0,
        padding: theme.spacing(1),
    },
}));

const tableKey = tablePage.RISK_ASSESSMENTS_TABLE;

const stdColumns = [
    { id: "id", label: "ID", isSortable: true },
    { id: "title", label: "Title", isSortable: true },
    { id: "status", label: "Status", isSortable: false },
    { id: "actionsos", label: "Actions Outstanding / Actions Due", isSortable: false },
    { id: "duedate", label: "Due Date", isSortable: true },
    { id: "lastReviewDate", label: "Last Review Date", isSortable: true },
    { id: "assigned", label: "Assigned", isSortable: true },
];

const adminColumns = [
    { id: "AdminActions", label: "Admin Actions", isSortable: false }
];

const defaultDropdownOption = { value: null, label: "All" };
const notSetDropdownOption = { value: "NotSet", label: "Not Set" };

export default function RiskAssessmentsTableCard() {
    const { isTemplateAdminForSites } = useAuth();
    const classes = useStyles();
    const { path } = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [riskAssessmentToAmend, setRiskAssessmentToAmend] = useState(null);
    const [contextRiskAssessmentId, setContextRiskAssessmentId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [filteredCurrentAssignees, setFilteredCurrentAssignees] = React.useState([]);

    const { selectedSite, activeAppId, appSiteIds, selectedAccount } =
        useSelector((state) => state.account);

    const { riskAssessmentsTable } = useSelector((state) => state.tablePage);

    const { setSelectedRiskAssessmentId } = useRiskAssessmentDetails();

    const isSafetynestAmendTemplateUser = isTemplateAdminForSites(
        selectedAccount?.childExternalIds
    );

    const contacts = useContactsForAppAndSite({ selectedSiteId: null });

    const [filterText, setFilterText] = useState(riskAssessmentsTable?.filteredText);
    const [inputFromDate, setInputFromDate] = useState(riskAssessmentsTable?.fromDate);
    const [inputToDate, setInputToDate] = useState(riskAssessmentsTable?.toDate);
    const [searchRequested, setSeachRequested] = React.useState(false);

    useEffect(() => {
        dispatch(
            setRiskAssessmentsTablePage({
                ...riskAssessmentsTable,
                key: tableKey,
                page: 0,
            })
        );
    }, [selectedSite]);

    const { data, isLoading, error } = useRiskAssessments({
        externalIds: selectedSite
            ? [selectedSite.externalId]
            : appSiteIds[activeAppId],
        pageSize: riskAssessmentsTable.rowsPerPage,
        pageNum: riskAssessmentsTable.page + 1,
        riskLevel: riskAssessmentsTable.riskLevel,
        orderByColumn: riskAssessmentsTable.sortBy || stdColumns[0].id,
        sortOrder: riskAssessmentsTable.orderBy || sortOrderName.DESC,
        assignees: riskAssessmentsTable.assignees?.map(option => option.value),
        filteredText: riskAssessmentsTable.filteredText,
        fromDate: riskAssessmentsTable.fromDate,
        toDate: riskAssessmentsTable.toDate,
    });

    const headCells = isSafetynestAmendTemplateUser
        ? stdColumns.concat(adminColumns)
        : stdColumns;

    const handleChangePage = (event, newPage) => {
        dispatch(
            setRiskAssessmentsTablePage({
                ...riskAssessmentsTable,
                key: tableKey,
                page: newPage,
            })
        );
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(
            setRiskAssessmentsTablePage({
                ...riskAssessmentsTable,
                key: tableKey,
                page: 0,
                rowsPerPage: parseInt(event.target.value, 10),
            })
        );
    };

    const handleRiskLevelChange = (event) => {
        dispatch(
            setRiskAssessmentsTablePage({
                ...riskAssessmentsTable,
                key: tableKey,
                page: 0,
                riskLevel: event.target.value,
            })
        );
    };

    const handleSortClick = (event, sortColumn) => {
        const isAsc = riskAssessmentsTable.sortBy === sortColumn && riskAssessmentsTable.orderBy === sortOrderName.ASC;
        dispatch(
            setRiskAssessmentsTablePage({
                ...riskAssessmentsTable,
                key: tableKey,
                page: 0,
                sortBy: sortColumn,
                orderBy: isAsc ? sortOrderName.DESC : sortOrderName.ASC,
            })
        );
    };

    const handleRowClick = (riskAssessmentId) => {
        setSelectedRiskAssessmentId(riskAssessmentId);
        history.push(`${path}/view/${riskAssessmentId}`);
    };

    const handleClick = (e, id) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setContextRiskAssessmentId(id);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
        setContextRiskAssessmentId(null);
    };

    const openEditRiskAssessmentPopup = (e, riskAssessment) => {
        e.stopPropagation();
        setRiskAssessmentToAmend(riskAssessment);
        setIsEditOpen(true);
        setAnchorEl(null);
        setContextRiskAssessmentId(null);
    };

    const openDeleteRiskAssessmentPopup = (e, riskAssessment) => {
        e.stopPropagation();
        setRiskAssessmentToAmend(riskAssessment);
        setIsDeleteOpen(true);
        setAnchorEl(null);
        setContextRiskAssessmentId(null);
    };

    const filteredAsigneeOptions = contacts?.data && data && data?.assignees
        ? (data.assignees.includes(null) || data.assignees.includes('') ? [notSetDropdownOption] : [])
            .concat(Object.values(contacts.data)
                .filter(user => data.assignees.includes(user.externalId) && user.firstName)
                .sort((a, b) => a.firstName.localeCompare(b.firstName))
                .map(user => ({ value: user.externalId, label: `${user.firstName} ${user.lastName}` }))
        ) : [];

    const handleToDateChange = (date) => {
        setInputToDate(date);
        if (!date || isValid(new Date(date))) {
            dispatch(
                setRiskAssessmentsTablePage({
                    ...riskAssessmentsTable,
                    key: tableKey,
                    page: 0,
                    toDate: date ? date.toISOString() : null,
                })
            );
        }
    };

    const handleFromDateChange = (date) => {
        setInputFromDate(date);
        if (!date || isValid(new Date(date))) {
            dispatch(
                setRiskAssessmentsTablePage({
                    ...riskAssessmentsTable,
                    key: tableKey,
                    page: 0,
                    fromDate: date ? date.toISOString() : null,
                })
            );
        }
    };

    function handleCurrentAssigneesChange(event, inputs) {
        setFilteredCurrentAssignees(inputs);
        if (inputs.length == 0) {
            dispatch(
                setRiskAssessmentsTablePage({
                    ...riskAssessmentsTable,
                    key: tableKey,
                    page: 0,
                    assignees: [],
                }))
        }
    }

    const handleConfirmedAssigneesChange = (event, inputs) => {
        dispatch(
            setRiskAssessmentsTablePage({
                ...riskAssessmentsTable,
                key: tableKey,
                page: 0,
                assignees: filteredCurrentAssignees,
            })
        );
    }

    function handleSearchChange(e) {
        setSeachRequested(false);
        onFilteredTextChange(e.target.value)
    }

    const handleSearchClick = () => {
        setSeachRequested(true);
        onFilteredTextSearch();
    };

    const showFilteredTextError = () => {
        return (filterText && filterText.length > 0 && filterText.length < 4 && searchRequested)
    }

    const onFilteredTextChange = (textString) => {
        setFilterText(textString);
    };

    const onFilteredTextSearch = () => {
        dispatch(
            setRiskAssessmentsTablePage({
                ...riskAssessmentsTable,
                key: tableKey,
                page: 0,
                filteredText: filterText
            })
        );
    };

    const handleFilteredTextClear = () => {
        setFilterText('');
        dispatch(
            setRiskAssessmentsTablePage({
                ...riskAssessmentsTable,
                key: tableKey,
                page: 0,
                filteredText: ''
            })
        );
    };

    function handleKeyDown(event) {

        if (event.key === 'Enter') {
            handleSearchClick();
        }
    }

    function errorBox() {
        return (
            <div className={classes.loadingContainer}>
                <Alert variant="outlined" severity="error">
                    {error.message}
                </Alert>
            </div>
        );
    }

    return (
        <>
            <CardBase
                title="Risk Assessments"
                fullHeight
                errorComponent={errorBox}
                error={error}
                rightComponent={
                    <Button
                        component={Link}
                        to={`${path}/add`}
                        variant="contained"
                        color="primary"
                        className={classes.addBtn}
                    >
                        Add Risk Assessment
                    </Button>
                }
            >
                {isLoading && (
                    <SkeletonTableLoader
                        columns={[
                            "ID",
                            "Title",
                            "Status",
                            "Actions Outstanding / Actions Due",
                            "Due Date",
                            "Last Review Date",
                            "Assigned",
                            "Admin Actions",
                        ]}
                        numberOfRows={4}
                    />
                )}
                {!isLoading && (
                    <>
                       <Grid container spacing={1} >
                            <Grid item xs={12} md={6} xl={3} className={classes.toolbar}>
                                <Autocomplete
                                    multiple
                                    value={filteredCurrentAssignees && filteredCurrentAssignees.length > 0 ? filteredCurrentAssignees :
                                        (riskAssessmentsTable.assignees && riskAssessmentsTable.assignees.length > 0 ? riskAssessmentsTable.assignees : [])}
                                    onChange={handleCurrentAssigneesChange}
                                    onBlur={handleConfirmedAssigneesChange}
                                    options={filteredAsigneeOptions}
                                    disableCloseOnSelect
                                    clearOnBlur
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => {
                                        return option.label === value.label;
                                    }}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                className={classes.checkbox}
                                                icon={uncheckedIcon}
                                                checkedIcon={checkedIcon}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={classes.textField}
                                            variant="outlined"
                                            size="small"
                                            label="Responsible Person(s)"
                                            fullWidth
                                        />
                                    )}
                                    renderTags={selected => {
                                        console.log("selected = ", selected);
                                        let renderTagsValue = selected
                                            .map(function (elem) {
                                                return elem.label;
                                            })
                                            .join(", ");
                                        return (
                                            <Typography className={classes.assignedString}
                                                noWrap={true}
                                                color="textPrimary"
                                            >
                                                {renderTagsValue}
                                            </Typography>
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={1} className={classes.toolbar}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        size="small"
                                        inputVariant="outlined"
                                        label="From date"
                                        format="dd/MM/yyyy"
                                        value={inputFromDate || null}
                                        onChange={(date) => handleFromDateChange(date)}
                                        maxDate={inputToDate}
                                        KeyboardButtonProps={{ className: classes.keyboardBtn }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={6} xl={1} className={classes.toolbar}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        size="small"
                                        inputVariant="outlined"
                                        label="To date"
                                        format="dd/MM/yyyy"
                                        value={inputToDate || null}
                                        onChange={(date) => handleToDateChange(date)}
                                        minDate={inputFromDate}
                                        KeyboardButtonProps={{ className: classes.keyboardBtn }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={6} xl={1}>
                                <ResponsiveSelect
                                    options={[defaultDropdownOption].concat(riskLevelDropdownValues)}
                                    optionLabelFunction={(x) => x.label}
                                    optionValueKey={"value"}
                                    margin="dense"
                                    fullWidth
                                    label="Risk level"
                                    placeholder="Risk level"
                                    onChange={handleRiskLevelChange}
                                    value={riskAssessmentsTable.riskLevel}
                                />
                            </Grid>

                            
                            <Grid item xs={12} md={6} xl={2} className={classes.searchField}>
                                <TextField
                                    className={classes.searchTextField}
                                    label="Search Description"
                                    variant="outlined"
                                    size="small"
                                    value={filterText}
                                    onChange={handleSearchChange}
                                    helperText='Please enter at least 4 character to search on'
                                    error={showFilteredTextError()}
                                    FormHelperTextProps={{ hidden: !showFilteredTextError() }}
                                    onKeyDown={handleKeyDown}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {filterText && (
                                                    <Tooltip title="Clear" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                                        <IconButton className={classes.iconBtn}>
                                                            <ClearIcon className={`clearIcon ${classes.clearIcon}`}
                                                                onClick={handleFilteredTextClear}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <Tooltip title="Search" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                                    <IconButton className={classes.iconBtn}>
                                                        <SearchIcon
                                                            onClick={handleSearchClick}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                        className: classes.endAdornment
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <Table>
                                <EnhancedTableHead
                                    headCells={headCells}
                                    sortColumn={riskAssessmentsTable.sortBy}
                                    sortDirection={riskAssessmentsTable.orderBy || sortOrderName.DESC}
                                    onRequestSort={handleSortClick}
                                />
                                <TableBody>
                                    {data?.results?.length ? (
                                        data?.results?.map((riskAssessment) => (
                                            <TableRow
                                                key={riskAssessment?.id}
                                                hover
                                                className={classes.tableRow}
                                                onClick={() => handleRowClick(riskAssessment?.id)}
                                            >
                                                <TableCell>{riskAssessment?.id}</TableCell>
                                                <TableCell>{riskAssessment?.template?.name}</TableCell>
                                                <TableCell>
                                                    <StatusChip
                                                        status={riskAssessment?.status}
                                                        statusName={riskAssessment?.statusName}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    {`${riskAssessment?.actionsOverdue} /
                          ${riskAssessment?.actionsDue}`}
                                                </TableCell>

                                                <TableCell>
                                                    {formatShortDate(parseISO(riskAssessment?.dueDate))}
                                                </TableCell>
                                                <TableCell>
                                                    {riskAssessment?.lastCompletedDate
                                                        ? formatShortDate(parseISO(riskAssessment.lastCompletedDate))
                                                        : ""}
                                                </TableCell>
                                                <TableCell>
                                                    {contacts?.data[riskAssessment?.assignedEmployeeExternalId] ?
                                                        `${contacts?.data[riskAssessment.assignedEmployeeExternalId]
                                                            ?.firstName
                                                        } ${contacts?.data[riskAssessment.assignedEmployeeExternalId]
                                                            ?.lastName
                                                        }` : ""
                                                    }
                                                </TableCell>
                                                {isSafetynestAmendTemplateUser && (
                                                    <TableCell>
                                                        <IconButton
                                                            onClick={(e) =>
                                                                handleClick(e, riskAssessment?.id)
                                                            }
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            keepMounted
                                                            open={contextRiskAssessmentId === riskAssessment?.id}
                                                            onClose={handleClose}
                                                            anchorEl={anchorEl}
                                                        >
                                                            <MenuItem
                                                                onClick={(e) =>
                                                                    openEditRiskAssessmentPopup(e, riskAssessment)
                                                                }
                                                            >
                                                                Edit
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={(e) =>
                                                                    openDeleteRiskAssessmentPopup(
                                                                        e,
                                                                        riskAssessment
                                                                    )
                                                                }
                                                            >
                                                                Delete
                                                            </MenuItem>
                                                        </Menu>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                No risk assessments have been added for selected site(s)
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={data?.totalCount}
                                rowsPerPage={riskAssessmentsTable.rowsPerPage}
                                page={riskAssessmentsTable.page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </>
                )}
            </CardBase>
            <RiskAssessmentAmendDialog
                isOpen={isEditOpen}
                setIsOpen={setIsEditOpen}
                riskAssessment={riskAssessmentToAmend}
                pageNum={riskAssessmentsTable.page + 1}
                pageSize={riskAssessmentsTable.rowsPerPage}
                riskLevel={riskAssessmentsTable.riskLevel}
            />
            <RiskAssessmentDeleteDialog
                isOpen={isDeleteOpen}
                setIsOpen={setIsDeleteOpen}
                riskAssessment={riskAssessmentToAmend}
                pageNum={riskAssessmentsTable.page + 1}
                pageSize={riskAssessmentsTable.rowsPerPage}
                riskLevel={riskAssessmentsTable.riskLevel}
            />
        </>
    );
}
