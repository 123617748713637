import React, {useEffect, useMemo, useState} from "react";
import {
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import CardBase from "../components/cards/CardBase";
import EnhancedTableHead from "../components/table/EnhancedTableHead";
import { tableSortingOrder } from "../constants/tableConstants";
import useAccountAdminSites from "../hooks/queries/useAccountAdminSites";
import { useDispatch, useSelector } from "react-redux";
import useSiteUsers from "../hooks/queries/useSiteUsers";
import RequiresRoleWrapper from "../components/RequiresRoleWrapper";
import { useAuth } from "../contexts/authContext";
import { getComparator, stableSort } from "../utils/tableUtils";
import AddUserToSiteDialog from "../components/dialogs/AddUserToSiteDialog";
import { useTracking } from "react-tracking";
import useDeleteUserFromSite from "../hooks/mutations/useDeleteUserFromSite";
import CustomButton from "../components/CustomButton";
import { useSnackbar } from "notistack";
import PageBase from "../components/PageBase";
import useIsMobile from "../hooks/useIsMobile";
import { useHistory } from "react-router-dom";
import { setSiteId, setUserToManage } from "../redux/actions/adminActions";
import accountUtils from "../utils/accountUtils";

const headCells = [
  {
    id: "lastName",
    label: "Name",
    isSortable: true,
  },
];

const useStyles = makeStyles((theme) => ({
  siteSelect: {
    width: "100%",
  },
  manageButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  removeButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: theme.spacing(1),
      width: "100%",
    },
  },
  nameCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  nameText: {
    flexGrow: 1,
    flexBasis: "200px",
  },
  youChip: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
      marginRight: 0,
      width: "100%",
    },
  },
  actionsContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      width: "100%",
    },
  },
  headerButtonsContainer: {
    display: "flex",
    gap: theme.spacing(2),
  },
}));

function Administration() {
  useTracking({
    page: "Administration",
  });
  const { user, isAdministratorForSites } = useAuth();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedAccount, sites } = useSelector((state) => state.account);
  const [order, setOrder] = useState(tableSortingOrder.asc);
  const [orderBy, setOrderBy] = useState(headCells[0].id);
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [isLinkUserDialogOpen, setIsLinkUserDialogOpen] = useState(false);
  const adminSites = useAccountAdminSites({
    childExternalIds: [ ...(selectedAccount?.childExternalIds || []), selectedAccount?.account.externalId],
  });

  const accountAndRecursiveParentExternalIds = useMemo(() => {
    return accountUtils.getAccountAndRecursiveParentExternalIds({ selectedAccountExternalId: selectedSiteId, sites });
  }, [selectedSiteId]);
  
  const siteUsers = useSiteUsers({ siteExternalIds: accountAndRecursiveParentExternalIds });
  const deleteUserFromSite = useDeleteUserFromSite();

  const { enqueueSnackbar } = useSnackbar();

  const isAdministrator = isAdministratorForSites(
    selectedAccount?.childExternalIds
  );

  useEffect(() => {
    if (!adminSites.data) return;
    if (adminSites.data.length === 1)
      setSelectedSiteId(adminSites.data[0].externalId);
  }, [adminSites.data]);

  const onSiteSelect = (e) => {
    setSelectedSiteId(e.target.value);
  };

  const handleSortRequest = (_, property) => {
    const isAsc = orderBy === property && order === tableSortingOrder.asc;
    setOrder(isAsc ? tableSortingOrder.desc : tableSortingOrder.asc);
    setOrderBy(property);
  };

  const onDeleteUserDialogClose = () => {
    if (deleteUserFromSite.isLoading) return;
    setUserToDelete(null);
  };

  const onAddUserToSiteDialogClose = () => {
    setIsLinkUserDialogOpen(false);
  };

  const onDeleteUserFromSite = () => {
    const accountSaasAppIds =
      userToDelete.accountApps[selectedSiteId]?.map(
        (x) => x.accountSaasAppId
      ) ?? [];

    deleteUserFromSite.mutate(
      {
        relationId: userToDelete.accountRoles[selectedSiteId].relationshipId,
        userId: userToDelete.externalId,
        selectedSiteId,
        accountSaasAppIds,
      },
      {
        onSuccess: () => setUserToDelete(null),
        onError: (error) => {
          console.error(error);
          enqueueSnackbar(
            error?.message ||
              "There was an error deleting the user from the site",
            {
              variant: "error",
            }
          );
        },
      }
    );
  };

  const goToAddUser = () => {
    dispatch(setSiteId({ siteId: selectedSiteId }));
    history.push("add-user");
  };

  const goToManageUser = (siteUser) => {
    dispatch(setSiteId({ siteId: selectedSiteId }));
    dispatch(setUserToManage({ userToManage: siteUser }));
    history.push("edit-user");
  };

  const onNewUserAdded = (newUser) => {
    onAddUserToSiteDialogClose();
    goToManageUser(newUser);
  };

  const tableContent = () => {
    if (siteUsers.isLoading) return null;

    if (siteUsers.data) {
      return Object.keys(siteUsers.data).length === 0 ? (
        <TableRow>
          <TableCell>
            <Typography>No users to display.</Typography>
          </TableCell>
        </TableRow>
      ) : (
        stableSort(
          Object.keys(siteUsers.data),
          getComparator(order, orderBy)
        ).map((siteUserId) => {
          const siteUser = siteUsers.data[siteUserId];
          return (
            <TableRow key={siteUserId}>
              <TableCell className={classes.nameCell}>
                <>
                  <Typography className={classes.nameText}>
                    {`${siteUser.firstName} ${siteUser.lastName}`}
                  </Typography>
                  <div className={classes.actionsContainer}>
                    {siteUserId === user.details.userId && (
                      <Chip
                        className={classes.youChip}
                        variant="outlined"
                        label="You"
                        size="small"
                        color="secondary"
                      />
                    )}
                    {siteUser.accountRoles[selectedSiteId]?.isDirect && (
                      <Chip
                        label="Direct User"
                        className={classes.youChip}
                        size="small"
                      />
                    )}
                    {siteUserId !== user.details.userId &&
                      !siteUser.accountRoles[selectedSiteId]?.isDirect && (
                        <Button
                          className={classes.removeButton}
                          variant="outlined"
                          size="small"
                          onClick={() => setUserToDelete(siteUser)}
                          disabled={
                            siteUser.accountRoles[selectedSiteId]?.isDirect
                          }
                        >
                          Remove from site
                        </Button>
                      )}
                    <Button
                      className={classes.manageButton}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => goToManageUser(siteUser)}
                    >
                      Manage
                    </Button>
                  </div>
                </>
              </TableCell>
            </TableRow>
          );
        })
      );
    }
  };

  return (
    <PageBase>
      <RequiresRoleWrapper hasRole={isAdministrator}>
        <Container maxWidth="md">
          <CardBase
            title="Administration"
            description="Manage users and application permissions."
            isLoading={adminSites.isLoading}
            isFetching={siteUsers.isFetching}
            rightComponent={
              <div className={classes.headerButtonsContainer}>
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={!selectedSiteId}
                  onClick={() => setIsLinkUserDialogOpen(true)}
                >
                  Link user to site
                </Button>
                <Button
                  onClick={goToAddUser}
                  variant="contained"
                  color="primary"
                  disabled={!selectedSiteId}
                >
                  Add User
                </Button>
              </div>
            }
          >
            {isMobile ? (
              <FormControl variant="outlined" className={classes.siteSelect}>
                <InputLabel htmlFor="site-select">Site</InputLabel>
                <Select
                  native
                  value={selectedSiteId}
                  onChange={onSiteSelect}
                  disabled={adminSites.isLoading || adminSites.isError}
                  inputProps={{
                    name: "site-select",
                    id: "site-select",
                  }}
                  label="Site"
                >
                  {adminSites.data &&
                    adminSites.data.map((site) => (
                      <option key={site.externalId} value={site.externalId}>
                        {site.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
            ) : (
              <FormControl variant="outlined" className={classes.siteSelect}>
                <InputLabel id="site-select-label">Site</InputLabel>
                <Select
                  labelId="site-select-label"
                  id="site-select"
                  value={selectedSiteId}
                  onChange={onSiteSelect}
                  label="Site"
                  disabled={adminSites.isLoading || adminSites.isError}
                >
                  {adminSites.data &&
                    adminSites.data.map((site) => (
                      <MenuItem key={site.externalId} value={site.externalId}>
                        {site.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <TableContainer>
              <Table size="small">
                <EnhancedTableHead
                  headCells={headCells}
                  sortDirection={order}
                  sortColumn={orderBy}
                  onRequestSort={handleSortRequest}
                />
                <TableBody>{tableContent()}</TableBody>
              </Table>
            </TableContainer>
          </CardBase>
        </Container>
        {!!selectedSiteId && isLinkUserDialogOpen && (
          <AddUserToSiteDialog
            isOpen={isLinkUserDialogOpen}
            onClose={onAddUserToSiteDialogClose}
            linkingToSiteId={selectedSiteId}
            onUserAdded={onNewUserAdded}
          />
        )}
        {!!userToDelete && (
          <Dialog open={!!userToDelete} onClose={onDeleteUserDialogClose}>
            <DialogTitle>Remove site access?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove site access for{" "}
                {userToDelete.firstName} {userToDelete.lastName}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onDeleteUserDialogClose}
                disabled={deleteUserFromSite.isLoading}
              >
                Cancel
              </Button>
              <CustomButton
                color="error"
                variant="outlined"
                onClick={onDeleteUserFromSite}
                isLoading={deleteUserFromSite.isLoading}
              >
                Remove
              </CustomButton>
            </DialogActions>
          </Dialog>
        )}
      </RequiresRoleWrapper>
    </PageBase>
  );
}

export default Administration;
