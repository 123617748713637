import React from "react";
import RequiresRoleWrapper from "../components/RequiresRoleWrapper";
import { useAuth } from "../contexts/authContext";
import { useHistory } from "react-router-dom";
import useAddTutorial from "../hooks/mutations/useAddTutorial";
import { useSnackbar } from "notistack";
import TutorialBuilder from "../components/TutorialBuilder";

function AddTutorial() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isTutorialAdmin } = useAuth();
  const addTutorial = useAddTutorial();

  const goBack = () => {
    history.goBack();
  };

  const onSubmit = (tutorial) => {
    addTutorial.mutate(tutorial, {
      onSuccess: () => goBack(),
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("Could not save tutorial.", { variant: "error" });
      },
    });
  };

  return (
    <RequiresRoleWrapper hasRole={isTutorialAdmin}>
      <TutorialBuilder
        onSubmit={onSubmit}
        isSaving={addTutorial.isLoading}
        onCancel={goBack}
      />
    </RequiresRoleWrapper>
  );
}

export default AddTutorial;
