import { useMutation } from "react-query";
import reactQueryUtils from "../../utils/reactQueryUtils";
import queryKeys from "../../constants/queryKeys";
import caseNestUserAlertSettingService from "../../services/caseNestUserAlertSettingService";

const useUpdateCaseNestUserAlertSetting = () =>
  useMutation(caseNestUserAlertSettingService.update, {
    onMutate: async (userAlertSetting) => {
      const { client } = reactQueryUtils;
      const queryKey = [queryKeys.caseNestUserAlertSetting, userAlertSetting.userExternalId];
      await client.cancelQueries(queryKey);

      const userAlertSettingBeforeChange = client.getQueryData(queryKey);

      client.setQueryData(queryKey, userAlertSetting);

      return { userAlertSettingBeforeChange };
    },
    onError: (error, userAlertSetting, { userAlertSettingBeforeChange }) => {
      const queryKey = [queryKeys.caseNestUserAlertSetting, userAlertSetting.userExternalId];
      reactQueryUtils.client.setQueryData(queryKey, userAlertSettingBeforeChange);
    },
    onSettled: (_data, _error, userAlertSetting) => {
      if (userAlertSetting)
        reactQueryUtils.client.invalidateQueries([
          queryKeys.caseNestUserAlertSetting,
          userAlertSetting.userExternalId,
        ]);
    },
  });

export default useUpdateCaseNestUserAlertSetting;