import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateService from "../../services/templateService";

const useTemplates = ({
  pageNum,
  pageSize,
  sectorId,
  subjectId,
  moduleArea,
  accountId,
  siteExternalIds,
  frequency,
  filterText,
  orderByColumn,
  sortOrder,
}) =>
  useQuery(
    [
      queryKeys.templates,
      moduleArea,
      pageSize,
      pageNum,
      sectorId,
      subjectId,
      moduleArea,
      accountId,
      siteExternalIds?.join(","),
      frequency,
      filterText,
      orderByColumn,
      sortOrder,
    ],
    () =>
      templateService.fetchTemplates({
        pageNum,
        pageSize,
        sectorId,
        subjectId,
        moduleArea,
        accountId,
        siteExternalIds,
        frequency,
        filterText,
        orderByColumn,
        sortOrder,
      })
  );

export default useTemplates;
