import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateService from "../../services/templateService";

const useAllModuleTemplates = ({ moduleArea, accountId, siteExternalIds, includeDisabled = false }) =>
    useQuery(
        [queryKeys.templates, moduleArea, accountId, siteExternalIds?.join(","), includeDisabled],
    () =>
      templateService.fetchAllTemplatesForModule({
        moduleArea,
        accountId,
          siteExternalIds,
          includeDisabled
      })
  );

export default useAllModuleTemplates;
