import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import prismicService from "../../services/prismicService";

const usePrismicItem = ({ sectionName, itemId }) =>
  useQuery(
    [queryKeys.prismicContentItem, itemId],
    () => prismicService.fetchContentItemById(sectionName, itemId),
    {
      enabled: !!itemId,
    }
  );

export default usePrismicItem;
