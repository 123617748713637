import React, { useState, useEffect } from "react";
import {
    Box, 
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
	makeStyles,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useSelector } from "react-redux";
import useUpdateTemplateForm from "../../../../safetynest/hooks/mutations/useUpdateTemplateForm";
import {useSnackbar} from "notistack";
import { capitaliseFirstLetter } from "../../../../utils/stringUtils";
import useIsMobile from "../../../../hooks/useIsMobile";
import useContactsForAppAndSite from "../../../../hooks/useContactsForAppAndSite";
import moduleAreas, { moduleUrls } from "../../../constants/moduleAreaConstants";
import { useAuth } from "../../../../contexts/authContext";

const useStyles = makeStyles((theme) => ({
    datePicker: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    activeSwitch: {
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    activeLabel: {
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(2),
    },
    boxLayout: {
        marginLeft: theme.spacing(3), 
        marginRight: theme.spacing(3), 
    },
    actionBtns: {
        margin: theme.spacing(2)
    },
    switchSubText: {
        fontSize: '0.85em',
        marginBottom: theme.spacing(3), 
    }
}));

const TemplateFormAmendDialog = ({ isOpen, setIsOpen, form, page, rows, moduleArea }) => {
    const classes = useStyles();
    const { impersonatingUser } = useAuth();
    const updateTemplateForm = useUpdateTemplateForm();

    const { selectedSite, activeAppId, appSiteIds, sites } = useSelector(
		(state) => state.account
	);

    const [assignedEmployee, setAssignedEmployee] = useState("");
    const [siteExternalId, setSiteExternalId] = useState("");
    const [startDate, setStartDate] = useState("");
    const [dueDate, setDueDate] = useState("");
    const isMobile = useIsMobile();

    const contacts = useContactsForAppAndSite({ selectedSiteId: siteExternalId });

    const { enqueueSnackbar } = useSnackbar();

    const hasNoUsers = !Object.values(contacts.data).length && form && form.siteExternalId

	const handleClose = () => {
		setIsOpen(false);
    };

    const handleSubmit = async e => {
        e.preventDefault();

        if (!impersonatingUser && (!assignedEmployee || hasNoUsers)) return;
        const updateData = {
            assignedEmployeeExternalId: assignedEmployee,
            startDate,
            dueDate,
            siteExternalId,
            templateFormId: form.id,
            externalIds: selectedSite
                ? [selectedSite.externalId]
                : appSiteIds[activeAppId],
            page: page + 1,
            rows: rows,
            moduleArea
        };
        updateTemplateForm.mutate(updateData, {
            onSuccess: () => handleClose(),
            onError: (error) => {
                console.error(error);
                enqueueSnackbar(error?.message || "There was an error updating the template form",
                    {
                        variant: "error"
                    });
            },
        });
    }

    useEffect(() => {
        if (form) {
            setAssignedEmployee(form.assignedEmployeeExternalId);
            setSiteExternalId(form.siteExternalId);
            setStartDate(form.startDate);
            setDueDate(form.dueDate);
        }
        
    }, [form]);

    const getDialogContent = () => {
            return (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {
                                isMobile ?
                                  <FormControl variant='outlined' fullWidth>
                                        <InputLabel htmlFor="employee-select">Employee Responsible</InputLabel>
                                        <Select
                                            inputProps={{
                                                name: 'employee-select',
                                                id: 'employee-select'
                                            }}
                                            native
                                            label='Employee Responsible'
                                            value={assignedEmployee}
                                            onChange={e => setAssignedEmployee(e.target.value)}
                                        >
                                            {hasNoUsers ?
                                                <option>No employees for the selected site</option>
                                                :
                                                Object.values(contacts.data)?.sort((a, b) => (a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0)).map(user => <option key={user.externalId} value={user.externalId}>{`${user.firstName} ${user.lastName}`}</option>)
                                            }
                                        </Select>
                                    </FormControl>
                                    :
                                    <FormControl error={hasNoUsers} variant='outlined' fullWidth>
                                        <InputLabel id='employee-select-label'>Employee Responsible</InputLabel>
                                        <Select
                                            labelId="employee-select-label"
                                            label='Employee Responsible'
                                            value={assignedEmployee}
                                            onChange={e => setAssignedEmployee(e.target.value)}>
                                            {hasNoUsers ?
                                                <MenuItem>No employees for the selected site</MenuItem>
                                                :
                                                Object.values(contacts.data).sort((a, b) => (a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0)).map(user => <MenuItem key={user.externalId} value={user.externalId}>{`${user.firstName} ${user.lastName}`}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                            }
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.datePicker}>
                            <FormControl fullWidth>
                                <KeyboardDatePicker
                                    format="dd/MM/yyyy"
                                    variant='inline'
                                    inputVariant="outlined"
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(date) => setStartDate(new Date(date.setHours(12)))}
                                    minDate={(moduleArea === moduleUrls[moduleAreas.COMPLIANCE] || impersonatingUser) ? undefined : form?.startDate}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                isMobile ?
                                    <FormControl variant='outlined' fullWidth>
                                        <InputLabel htmlFor="site-select">Select Site</InputLabel>
                                        <Select
                                            inputProps={{
                                                name: 'site-select',
                                                id: 'site-select'
                                            }}
                                            native
                                            label='Select Site'
                                            value={siteExternalId}
                                            onChange={e => setSiteExternalId(e.target.value)}
                                        >
                                            {Object.values(sites)?.map(site => (
                                                <option key={site.id} value={site.externalId}>{site.name}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    :
                                    <FormControl variant='outlined' fullWidth>
                                        <InputLabel id='site-select-label'>Select Site</InputLabel>
                                        <Select
                                            labelId="site-select-label"
                                            label='Select Site'
                                            value={siteExternalId}
                                            onChange={e => setSiteExternalId(e.target.value)}
                                        >
                                            {Object.values(sites)?.map(site => <MenuItem key={site.externalId} value={site.externalId}>{site.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                            }
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.datePicker}>
                            <FormControl fullWidth>
                                <KeyboardDatePicker
                                    format="dd/MM/yyyy"
                                    variant='inline'
                                    inputVariant="outlined"
                                    label="Due Date"
                                    value={dueDate}
                                    onChange={(date) => setDueDate(new Date(date.setHours(12)))}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </>
            );
        
    }

    return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			fullScreen={isMobile}
		>
			<DialogTitle>{`Amend ${capitaliseFirstLetter(moduleArea)} Form`}</DialogTitle>
            <form onSubmit={handleSubmit}>
                <Box className={classes.boxLayout}>
					{getDialogContent()}
				</Box>
                <DialogActions className={classes.actionBtns}>
                    {updateTemplateForm.isLoading ?
						<CircularProgress /> :
						<>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
                            <Button variant="contained" type="submit" color="primary">Save</Button>
						</>}
				</DialogActions>
			</form>
        </Dialog>

    );
}

export default TemplateFormAmendDialog;