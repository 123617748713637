import React from "react";
import { makeStyles } from "@material-ui/core";
import { HeadlineTile } from "../HeadlineTile";

const useStyles = makeStyles((theme) => ({
  headlineContainer: {
    display: "grid",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gridTemplateColumns: "repeat(4, 15%)",
    gap: theme.spacing(2),
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
      justifyItems: "center",
    },
  },
}));

export default function ActionsHeadlines({ data }) {
  const classes = useStyles();

  const totalDue = data?.reduce((a, b) => a + b?.stats[0]?.actionsCount, 0);
  const totalOverdue = data?.reduce((a, b) => a + b?.stats[1]?.actionsCount, 0);
  const totalNotDue = data?.reduce((a, b) => a + b?.stats[2]?.actionsCount, 0);
  const totalCompleted = data?.reduce(
    (a, b) => a + b?.stats[3]?.actionsCount,
    0
  );

  return (
    <div className={classes.headlineContainer}>
      <HeadlineTile data={totalOverdue} title="Overdue" />
      <HeadlineTile data={totalDue} title="Due" />
      <HeadlineTile data={totalCompleted} title="Completed" />
      <HeadlineTile data={totalNotDue} title="Not Due" />
    </div>
  );
}
