import axios from "../../utils/axios";
import { buildQueryStringList } from "../../utils/stringUtils";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const addNewTemplate = (newTemplate) =>
  axios.post(`${apiUrl}/template/add`, newTemplate);

const updateTemplate = (template) => {
    return axios.patch(`${apiUrl}/Template`, template);
}

const fetchTemplate = (templateId) =>
  axios.get(`${apiUrl}/Template/${templateId}`);

const fetchTemplateVersion = (templateVersionId) =>
    axios.get(`${apiUrl}/Template/TemplateVersion/${templateVersionId}`);

const fetchTemplateVersionAndInvestigation = (templateVersionId, externalId) => {
    let queryString = "";
    if (externalId) {
        queryString = `?externalId=${externalId}`;
    }
    return axios.get(`${apiUrl}/Template/TemplateVersionAndInvestigation/${templateVersionId}${queryString}`);
}

const fetchTemplates = ({
  pageNum,
  pageSize,
  sectorId,
  subjectId,
  moduleArea,
  accountId,
  siteExternalIds,
  frequency,
  filterText,
  orderByColumn,
  sortOrder,
}) => {
    let queryString = buildQueryStringList("siteExternalIds", siteExternalIds);
    if (frequency !== null) queryString += `&frequency=${frequency}`;
    if (sortOrder !== null) queryString += `&sortOrder=${sortOrder}`;
    if (orderByColumn !== null) queryString += `&orderByColumn=${orderByColumn}`;

    return axios.get(
        `${apiUrl}/Template/ApplicationArea/${moduleArea}/Dropdown${queryString}&accountId=${accountId}&pageNum=${pageNum}&pageSize=${pageSize}&sectorId=${sectorId}&subjectId=${subjectId}&filterText=${filterText}`
  );
};

const fetchTemplatesForModule = ({
  moduleArea,
  pageNum,
  rowsPerPage,
  accountId,
  siteExternalIds,
  sortOrder,
  orderByColumn,
  templateType,
  sectorId,
  subjectId,
  frequency,
}) => {
  let sitesList = buildQueryStringList("siteExternalIds", siteExternalIds);

  if (templateType !== null) {
    if (sitesList === "") sitesList += "?";
    sitesList += `&hasSiteExternalId=${templateType}`;
  } 
  if (sectorId !== null) {
    if (sitesList === "") sitesList += "?";
    sitesList += `&sectorId=${sectorId}`;
  } 
  if (subjectId !== null) {
    if (sitesList === "") sitesList += "?";
    sitesList += `&subjectId=${subjectId}`;
  } 
  if (frequency !== null) {
    if (sitesList === "") sitesList += "?";
    sitesList += `&frequency=${frequency}`;
    } 

    return axios.get(`${apiUrl}/Template/ApplicationArea/${moduleArea}${sitesList ? sitesList : "?"
        }&accountId=${accountId}&page=${pageNum}&rows=${rowsPerPage}&sortOrder=${sortOrder}&orderByColumn=${orderByColumn}`);    
};

const fetchAllTemplatesForModule = ({
  moduleArea,
  accountId,
  siteExternalIds,
  includeDisabled
}) => {

    const sitesList = buildQueryStringList("siteExternalIds", siteExternalIds);
    return axios.get(
        `${apiUrl}/Template/ApplicationArea/All/${moduleArea}${sitesList}&accountId=${accountId}&includeDisabled=${includeDisabled}`
  );
};

const fetchIncidentTemplate = ({
  siteExternalId,
  incidentId = null,
  incidentType,
  accountId,
}) => {
  let queryString = "";

  if (incidentId) queryString = `?incidentId=${incidentId}`;

  return axios.get(
    `${apiUrl}/Template/Incident/${siteExternalId}/${accountId}/${incidentType}${queryString}`
  );
};

const fetchRemainingIncidentTypes = (accountExternalId) =>
  axios.get(`${apiUrl}/Template/Incident/Types/${accountExternalId}`);

const disableTemplate = ({ templateId }) =>
  axios.delete(`${apiUrl}/Template/${templateId}`);

const fetchTemplateVersions = ({ templateId }) =>
  axios.get(`${apiUrl}/Template/TemplateVersions/${templateId}`);

const templateService = {
  addNewTemplate,
  fetchTemplatesForModule,
  fetchTemplates,
  fetchTemplate,
  fetchIncidentTemplate,
  fetchRemainingIncidentTypes,
  fetchAllTemplatesForModule,
  disableTemplate,
  fetchTemplateVersion,
  updateTemplate,
  fetchTemplateVersions,
  fetchTemplateVersionAndInvestigation,
};

export default templateService;
