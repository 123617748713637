import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import useDeleteIncident from "../../hooks/mutations/useDeleteIncident";
import { useSnackbar } from "notistack";

const DeleteIncidentDialog = ({
  isOpen,
  setIsOpen,
  archiveIncidentId,
  isDri,
  page,
  rows,
  siteExternalIds,
}) => {
  const deleteIncident = useDeleteIncident();
  const { enqueueSnackbar } = useSnackbar();

    const handleConfirm = async e => {
        e.preventDefault();
            const updateData = {
                id: archiveIncidentId,
                isDri: isDri,
                externalIds: siteExternalIds,
                page: page, 
                rows: rows

            };
            deleteIncident.mutate(updateData, {
                onSuccess: () => handleClose(),
                onError: (error) => {
                    console.error(error);
                    enqueueSnackbar(error?.message || "There was an error deleting the incident",
                        {
                            variant: "error"
                        });
                },
            });
        }


    const handleClose = () => {
        setIsOpen(false);
    };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Archive Incident</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to archive this incident? The incident will no
          longer be visible within SafetyNest.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteIncidentDialog;
