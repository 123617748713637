import React from "react";
import {
    Avatar as MuiAvatar,
    makeStyles
} from "@material-ui/core";
import { avatarSizes } from "../constants/avatarConstants";

const useStyles = makeStyles(() => ({
    avatar: props => ({
        height: avatarSizes[props.size],
        width: avatarSizes[props.size]
    })
}));

const Avatar = ({ size, ...props }) => {

    const classes = useStyles({ size });

    if (!avatarSizes[size])
        return <MuiAvatar { ...props } />    

    return <MuiAvatar className={classes.avatar} { ...props } />
};

export default Avatar;