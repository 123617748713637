import axios from "../../utils/axios";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const addUserLoginLog = (userLoginLog) => axios.post(`${apiUrl}/UserLoginLog`, userLoginLog);

const userLoginLogService = {
  addUserLoginLog,
}

export default userLoginLogService;