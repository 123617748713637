import React, { useLayoutEffect, useState, useEffect } from "react";
import {
    Button,
    Divider,
    makeStyles,
    Tab,
    Typography,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Prompt, useHistory } from "react-router-dom";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useIsMobile from "../../hooks/useIsMobile";
import UserDetails from "./UserDetails";
import Permissions from "./Permissions";
import Alerts from "./Alerts";
import Access from "./Access";
import { useAuth } from "../../contexts/authContext";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { ErrorOutline as ErrorOutlineIcon } from "@material-ui/icons";
import useUserAlertSetting from "../../hooks/queries/useUserAlertSetting";
import appIdConstants from "../../constants/appIdConstants";
import {
    alertFrequencyConstants,
    manageUserConstants,
    manageUserTabs,
    permissionSalesforceNames,
    tabMenuLabels,
} from "../../constants/manageUserConstants";
import useUserAccessPermission from "../../hooks/queries/useUserAccessPermission";
import { setResetUser } from "../../redux/actions/adminActions";
import CustomButton from "../CustomButton";
import useCaseNestUserAlertSetting from "../../hooks/queries/useCaseNestUserAlertSetting";
import CaseNestAlerts from "./CaseNestAlerts";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        margin: "0 auto",
        minWidth: "100%",
        [theme.breakpoints.up("md")]: {
            minWidth: 0,
            width: "85%",
        },
        [theme.breakpoints.up("lg")]: {
            minWidth: 0,
            width: "75%",
        },
        [theme.breakpoints.up("xl")]: {
            width: "65%",
        },
    },
    form: {
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(2, 2, 2, 1),
        },
    },
    tabsContainer: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            flexDirection: "initial",
            justifyContent: "space-between",
        },
    },
    tabsMobileMenu: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: theme.spacing(2),
    },
    tabsMobileMenuText: {
        fontSize: 17,
        fontWeight: 600,
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row-reverse",
            justifyContent: "end",
            marginTop: theme.spacing(2),
            padding: theme.spacing(0, 4),
        },
    },
    saveButton: {
        background: theme.palette.primary.light,
        color: theme.palette.getContrastText(theme.palette.primary.light),
        marginBottom: theme.spacing(1),
        "&.MuiButtonBase-root:hover": {
            background: theme.palette.primary.main,
        },
        [theme.breakpoints.up("md")]: {
            marginLeft: theme.spacing(1),
            marginBottom: 0,
        },
    },
    title: {
        lineHeight: "48px",
        fontSize: "36px",
        letterSpacing: "-3px",
        fontWeight: "500",
        color: theme.palette.primary.main,
    },
    divider: {
        margin: theme.spacing(1, -3, 2, -3),
    },
    tabs: {
        "& .MuiTab-textColorInherit": {
            opacity: 1,
        },
        "& .MuiTab-textColorInherit.Mui-selected": {
            color: theme.palette.primary.light,
        },
        "& .MuiTab-wrapper": {
            alignItems: "initial",
            flexDirection: "row",
            justifyContent: "initial",
            marginLeft: theme.spacing(1),
            fontSize: 15,
            fontWeight: 600,
        },
        [theme.breakpoints.up("md")]: {
            "& .MuiTab-wrapper": {
                marginLeft: theme.spacing(2),
            },
        },
    },
    tab: {
        width: "100%",
        padding: 0,
        [theme.breakpoints.up("md")]: {
            width: "80%",
            padding: theme.spacing(0, 4),
        },
    },
    iconTabContainer: {
        display: "flex",
        alignItems: "center",
    },
    iconTabLabel: {
        fontSize: 15,
        fontWeight: 600,
        marginRight: theme.spacing(1),
    },
    indicator: {
        left: 0,
    },
    disabled: {
        opacity: "0.4 !important",
    },
}));

const displayTabList = (isMobile, isTabMobileMenuOpen) =>
    isTabMobileMenuOpen || !isMobile;

const convertObjectIntoArray = (obj) =>
    Object.entries(obj).map(([key, value]) => ({ key, value }));

const ManageUser = ({
    onSubmit,
    existingUser,
    accountId,
    isLoading,
    isSaveDisabled,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const { enqueueSnackbar } = useSnackbar();
    const { selectedAccount } = useSelector((state) => state.account);

    const {
        isCaseNestAdministratorForSites,
        isSafetyNestAdministratorForSites,
        isMyWorkNestAdministratorForSites,
        user,
        adminUserSelectedAccountApps,
        adminUserAdviceTypes,
    } = useAuth();

    const adviceTypes = adminUserAdviceTypes();   

    const isCaseNestAdministrator = isCaseNestAdministratorForSites(selectedAccount?.childExternalIds);
    const isSafetyNestAdministrator = isSafetyNestAdministratorForSites(selectedAccount?.childExternalIds);
    const isMyWorkNestAdministrator = isMyWorkNestAdministratorForSites(selectedAccount?.childExternalIds);

    const adminUserApps = adminUserSelectedAccountApps(accountId);

    const [appIds, setAppIds] = useState(existingUser?.accountApps[accountId]?.map((app) => app.appId) ?? []);
    const hasSafetyNestAccess = appIds.includes(appIdConstants.safetynest);
    const hasCaseNestAccess = appIds.includes(appIdConstants.casenest);

    const userAccessPermission = useUserAccessPermission({
        userExternalId: existingUser?.externalId,
        hasSafetyNest: hasSafetyNestAccess,
    });
    const [tabValue, setTabValue] = useState(manageUserTabs.USER_DETAILS.value);
    const [tabLabel, setTabLabel] = useState(manageUserTabs.USER_DETAILS.label);
    const [isTabMobileMenuOpen, setIsTabMobileMenuOpen] = useState(false);

    const [userDetails, setUserDetails] = useState({
        firstName: existingUser?.firstName ?? "",
        lastName: existingUser?.lastName ?? "",
        email: existingUser?.email ?? "",
        mobile: existingUser?.mobile ?? "",
        phone: existingUser?.phone ?? "",
        title: existingUser?.title ?? "",
        employmentStatus: manageUserConstants.ACTIVE,
    });

    const [safetyNestAccessModules, setSafetyNestAccessModules] = useState({
        canAccessAudits: false,
        canAccessCompliance: false,
        canAccessIncidents: false,
        canAccessMonitoring: false,
        canAccessRiskAssessments: false,
    });

    const [permissions, setPermissions] = useState({
        myWorkNestAdministrator: false,
        caseNestAdministrator: false,
        caseNestReceiveAdvice: false,
        caseNestAllCases: false,
        safetyNestAdministrator: false,
        safetyNestAmendTemplates: false,
        safetyNestCompleteIncidentInvestigations: false,
        safetyNestEditCompletedActions: false,
        safetyNestAllActions: false,
    });

    const [alerts, setAlerts] = useState({
        actionOverdue: true,
        auditPublished: true,
        complianceFormOverdue: true,
        monitoringFormOverdue: true,
        incidentLogged: true,
        incidentWithInvestigationLogged: false,
        alertFrequency: alertFrequencyConstants.DAILY,
        adminAlertFrequency: alertFrequencyConstants.NONE,
    });

    const [formErrors, setFormErrors] = useState({
        firstName: false,
        lastName: false,
        email: false,
        incidents: false,
    });

    const [userMadeChanges, setUserMadeChanges] = useState(false);
    const [canAccessChildSites, setCanAccessChildSites] = useState(!existingUser);

    const [caseNestAlerts, setCaseNestAlerts] = useState({
        isDaily: true,
        closedCases: false,
        highRiskCases: false,
        caseTypeIds: [],
    });

    const isUserDetailsError = formErrors.firstName || formErrors.lastName || formErrors.email;

    const userAlertSetting = useUserAlertSetting({
        userExternalId: existingUser?.externalId,
        hasSafetynest: hasSafetyNestAccess,
    });

    const caseNestUserAlertSetting = useCaseNestUserAlertSetting({
        userExternalId: existingUser?.externalId,
        isCaseNestAdmin: isCaseNestAdministrator,
        hasCaseNestAccess,
    });

    useEffect(() => {
        if (userAccessPermission?.data) {
            
            const { userExternalId, ...accessPermission } = userAccessPermission.data;
            setSafetyNestAccessModules({
                ...safetyNestAccessModules,
                ...accessPermission,
            });
        }
    }, [userAccessPermission?.data]);

    useEffect(() => {
        if (userAlertSetting?.data) {
            const userAlerts = userAlertSetting.data;
            setAlerts({ ...alerts, ...userAlerts });
        }
    }, [userAlertSetting.data]);

    useEffect(() => {
        if (caseNestUserAlertSetting?.data) {
            const caseNestUserAlerts = caseNestUserAlertSetting.data;
            setCaseNestAlerts({ ...caseNestAlerts, ...caseNestUserAlerts });
        }
    }, [caseNestUserAlertSetting.data]);

    useEffect(() => {
        if (userDetails.employmentStatus === manageUserConstants.ACTIVE) {
            setAppIds([...appIds, appIdConstants.myWorkNest]);
        } else {
            const removeMyWorkNestAppId = appIds.filter(
                (id) => id !== appIdConstants.myWorkNest
            );

            setAppIds(removeMyWorkNestAppId);
        }
    }, [userDetails.employmentStatus]);

    useEffect(() => {
        if (existingUser) {
            let existingPermissions = {};            

            for (const [key, value] of Object.entries(permissionSalesforceNames)) {
                existingUser.accountRoles[accountId].roles.forEach((role) =>
                    role === value ? (existingPermissions[key] = true) : false
                );               
            }

            for (const adviceType of adviceTypes) {
                existingPermissions[adviceType] = existingUser.accountRoles[accountId].adviceTypes.includes(adviceType);              
            }
            
            setPermissions({ ...permissions, ...existingPermissions });
            setCanAccessChildSites(existingUser.accountRoles[accountId].canAccessChildSites);
        }
    }, [existingUser]);

    useLayoutEffect(() => {
        if (
            hasSafetyNestAccess &&
            userAccessPermission.data &&
            !Object.values(safetyNestAccessModules).includes(true)
        ) {
            const removeSafetyNestAppId = appIds.filter(
                (appId) => appId !== appIdConstants.safetynest
            );

            setAppIds(removeSafetyNestAppId);
        }
    }, [safetyNestAccessModules]);

    const handleAccessChange = (accessAppId) => {
        let newApps;
        if (appIds.includes(accessAppId)) {
            if (accessAppId === appIdConstants.safetynest) {
                setSafetyNestAccessModules({
                    canAccessAudits: false,
                    canAccessCompliance: false,
                    canAccessIncidents: false,
                    canAccessMonitoring: false,
                    canAccessRiskAssessments: false,
                });
            }
            newApps = appIds.filter((appId) => appId !== accessAppId);
        } else {
            if (accessAppId === appIdConstants.safetynest) {
                setSafetyNestAccessModules({
                    canAccessAudits: true,
                    canAccessCompliance: true,
                    canAccessIncidents: true,
                    canAccessMonitoring: true,
                    canAccessRiskAssessments: true,
                });
            }
            newApps = [...appIds, accessAppId];
        }

        setAppIds(newApps);
    };

    const handleFormValidation = () => {
        let formIsValid = true;
        let errors = {};
        if (userDetails.firstName === "") {
            formIsValid = false;
            errors = { ...errors, firstName: true };
        }

        if (userDetails.lastName === "") {
            formIsValid = false;
            errors = { ...errors, lastName: true };
        }

        if (userDetails.email === "") {
            formIsValid = false;
            errors = { ...errors, email: true };
        }

        if (
            safetyNestAccessModules.canAccessIncidents &&
            alerts.incidentLogged &&
            alerts.incidentsLoggedType === ""
        ) {
            formIsValid = false;
            errors = { ...errors, incidents: true };
        }
        if (!formIsValid) {
            setFormErrors({ ...errors });
        }
        return formIsValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!handleFormValidation()) {
            enqueueSnackbar("Please ensure all required form fields have been filled in.", { variant: "error" });
        } else {

            const permissionsArray = convertObjectIntoArray(permissions);

            const userRoles = permissionsArray
                .filter((p) => p.value === true && !adviceTypes.includes(p.key))
                .map((item) => permissionSalesforceNames[item.key]);

            const userAdviceTypes = permissionsArray
                .filter((p) => p.value === true && adviceTypes.includes(p.key))
                .map((item) => item.key);

            const user = {
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                phone: userDetails.phone,
                mobile: userDetails.mobile,
                email: userDetails.email,
                title: userDetails.title,
                employmentStatus: userDetails.employmentStatus,
                roles: userRoles,
                appIds,
                alertSettings: alerts,
                safetyNestAccessModules,
                canAccessChildSites,
                caseNestAlertSettings: caseNestAlerts,
                adviceTypes: userAdviceTypes
            };

            if (!!existingUser) {
                user.hasLeftBusiness = userDetails.employmentStatus === manageUserConstants.FORMER;

                for (let setting in userAlertSetting?.data || {}) {
                    if (userAlertSetting.data[setting] !== alerts[setting]) {
                        user.alertSettings = alerts;
                        break;
                    }
                }
            }
            onSubmit(user);
            setUserMadeChanges(false);
        }
    };

    const handleFormChange = () => {
        setUserMadeChanges(true);
    };

    const handleCancel = () => {
        history.goBack();
        dispatch(setResetUser());
    };

    const handleTabChange = (e, value) => {
        setTabValue(value);
        setTabLabel(tabMenuLabels[value - 1]);
    };

    const handleTabMobileMenuChange = () => {
        setIsTabMobileMenuOpen(!isTabMobileMenuOpen);
    };

    return (
        <>
            <div className={classes.pageContainer}>
                <form
                    onSubmit={handleSubmit}
                    onChange={handleFormChange}
                    className={classes.form}
                    noValidate
                >
                    <Typography align="left" className={classes.title}>Manage User</Typography>
                    <div className={classes.tabsContainer}>
                        {
                            isMobile && 
                            <div
                                className={classes.tabsMobileMenu}
                                onClick={handleTabMobileMenuChange}
                            >
                                <Typography className={classes.tabsMobileMenuText}>
                                    {tabLabel}
                                </Typography>
                                {isTabMobileMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </div>
                        }
                        <TabContext value={tabValue}>
                            {
                                displayTabList(isMobile, isTabMobileMenuOpen) && 
                                <TabList
                                    aria-label="Tab list"
                                    orientation="vertical"
                                    onChange={handleTabChange}
                                    className={classes.tabs}
                                    classes={{indicator: classes.indicator }}
                                >
                                    <Tab
                                        label={
                                            <div className={classes.iconTabContainer}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.iconTabLabel}
                                                >
                                                    {manageUserTabs.USER_DETAILS.label}
                                                </Typography>
                                                {isUserDetailsError && <ErrorOutlineIcon size="small" color="error" />}
                                            </div>
                                        }
                                        value={manageUserTabs.USER_DETAILS.value}
                                    />
                                    <Tab
                                        label={manageUserTabs.ACCESS.label}
                                        value={manageUserTabs.ACCESS.value}
                                    />
                                    <Tab
                                        label={manageUserTabs.PERMISSIONS.label}
                                        value={manageUserTabs.PERMISSIONS.value}
                                    />
                                    <Tab
                                        label={
                                            <div className={classes.iconTabContainer}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.iconTabLabel}
                                                >
                                                    {manageUserTabs.ALERTS.label}
                                                </Typography>
                                                {formErrors.incidents && <ErrorOutlineIcon size="small" color="error" />}
                                            </div>
                                        }
                                        value={manageUserTabs.ALERTS.value}
                                        disabled={
                                            !hasSafetyNestAccess &&
                                            !(isCaseNestAdministrator && hasCaseNestAccess)
                                        }
                                        classes={{ disabled: classes.disabled }}
                                    />
                                </TabList>
                            }
                            {isMobile && <Divider className={classes.divider} />}
                            <TabPanel
                                value={manageUserTabs.USER_DETAILS.value}
                                className={classes.tab}
                            >
                                <UserDetails
                                    data={userDetails}
                                    onChange={setUserDetails}
                                    displayError={isUserDetailsError}
                                    isExistingUser={!!existingUser}
                                />
                            </TabPanel>
                            <TabPanel
                                value={manageUserTabs.ACCESS.value}
                                className={classes.tab}
                            >
                                <Access
                                    appIds={appIds}
                                    onChange={handleAccessChange}
                                    safetyNestModules={safetyNestAccessModules}
                                    onSafetyNestModuleChange={setSafetyNestAccessModules}
                                    isCaseNestAdmin={isCaseNestAdministrator}
                                    isSafetyNestAdmin={isSafetyNestAdministrator}
                                    isMyWorkNestAdmin={isMyWorkNestAdministrator}
                                    hasSafetyNestAccess={hasSafetyNestAccess}
                                    adminUser={user?.details}
                                    accountId={accountId}
                                    adminUserApps={adminUserApps}
                                    canAccessChildSites={canAccessChildSites}
                                    setCanAccessChildSites={setCanAccessChildSites}
                                />
                            </TabPanel>
                            <TabPanel
                                value={manageUserTabs.PERMISSIONS.value}
                                className={classes.tab}
                            >
                                <Permissions
                                    data={permissions}
                                    onChange={setPermissions}
                                    isCaseNestAdmin={isCaseNestAdministrator}
                                    isSafetyNestAdmin={isSafetyNestAdministrator}
                                    isMyWorkNestAdmin={isMyWorkNestAdministrator}
                                    adviceTypes={adviceTypes}
                                    hasIncidentsAccess={safetyNestAccessModules.canAccessIncidents}
                                />
                            </TabPanel>
                            <TabPanel
                                value={manageUserTabs.ALERTS.value}
                                className={classes.tab}
                            >
                                {
                                    hasSafetyNestAccess && 
                                    <Alerts
                                        data={alerts}
                                        onChange={setAlerts}
                                        hasAuditAccess={safetyNestAccessModules.canAccessAudits}
                                        hasComplianceAccess={safetyNestAccessModules.canAccessCompliance}
                                        hasMonitoringAccess={safetyNestAccessModules.canAccessMonitoring}
                                        hasIncidentsAccess={safetyNestAccessModules.canAccessIncidents}
                                        isAlertsError={safetyNestAccessModules.canAccessIncidents && formErrors.incidents}
                                    />
                                }
                                {
                                    isCaseNestAdministrator && hasCaseNestAccess && 
                                    <CaseNestAlerts
                                        data={caseNestAlerts}
                                        onChange={setCaseNestAlerts}
                                    />
                                }
                            </TabPanel>
                        </TabContext>
                    </div>
                    <div className={classes.buttonContainer}>
                        <CustomButton
                            className={classes.saveButton}
                            variant="contained"
                            type="submit"
                            isLoading={isSaveDisabled || isLoading}
                        >
                            Save
                        </CustomButton>
                        <Button onClick={handleCancel} color="primary" variant="outlined">
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>
            <Prompt
                when={userMadeChanges}
                message="Your changes will not be saved."
            />
        </>
    );
};

export default ManageUser;
