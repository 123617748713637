import React, { useMemo } from "react";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
} from "@material-ui/core";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import CardBase from "../../../components/cards/CardBase";
import { useDispatch, useSelector } from "react-redux";
import { setTablePage } from "../../../redux/actions/tablePageActions";
import { tablePage } from "../../constants/tablePageConstants";
import { sortOrderName } from "../../constants/sortOrder";
import { format, differenceInCalendarDays } from "date-fns";
import LinearProgressWithLabel from "./LinearProgressWithLabel";

const headCells = [
    {
        id: "site",
        label: "Site",
        isSortable: true
    },
    {
        id: "lastAudit",
        label: "LastAudit",
        isSortable: true,
    },
    {
        id: "lowCount",
        label: "Low"
    },
    {
        id: "mediumCount",
        label: "Medium"
    },
    {
        id: "highCount",
        label: "High"
    },
    {
        id: "criticalCount",
        label: "Critical"
    },
    {
        id: "overallCount",
        label: "Overall"
    },
    {
        id: "completionPercent",
        label: "Completion",
        isSortable: true
    }
];

const tableKey = tablePage.AUDIT_INSIGHTS_TABLE;

const AuditInsightsTableCard = ({ insights, siteName }) => {
    const dispatch = useDispatch();

    const { auditInsightsTable } = useSelector(state => state.tablePage);

    const sortedInsights = useMemo(() => {
        if (!insights?.data)
            return [];

        let swap = auditInsightsTable.orderBy === sortOrderName.ASC ? 1 : -1;

        let sorted = insights.data
            .map(i => ({ ...i, site: siteName(i.siteExternalId) }))
            .sort((a, b) => (a[auditInsightsTable.sortBy] > b[auditInsightsTable.sortBy] ? 1 : -1) * swap)
            .slice(auditInsightsTable.page * auditInsightsTable.rowsPerPage, auditInsightsTable.page * auditInsightsTable.rowsPerPage + auditInsightsTable.rowsPerPage);

        return sorted;
    }, [auditInsightsTable.orderBy, auditInsightsTable.page, auditInsightsTable.rowsPerPage, auditInsightsTable.sortBy, insights.data, siteName]);

    const today = new Date();  

    const handleRowsPerPageChange = (e) => {
        dispatch(
            setTablePage({
                key: tableKey,
                page: 0,
                rowsPerPage: parseInt(e.target.value),
            })
        );
    };

    const handlePageChange = (e, newPage) => {
        dispatch(
            setTablePage({
                key: tableKey,
                page: newPage,
                rowsPerPage: auditInsightsTable.rowsPerPage,
            })
        );
    };

    const formattedCell = (top, bottom) => <>{top}<br /><span style={{ fontSize: '0.9em', color: 'grey' }}>{bottom}</span></>;

    const countCell = (i, rating) => i.actionCounts[rating] && formattedCell(i.actionCompleteCounts[rating], `of ${i.actionCounts[rating]}`);

    const tableContent = () => {
        if (sortedInsights.length > 0) {
            return sortedInsights
                .map((i) => (
                    <TableRow key={i.siteExternalId}>
                        <TableCell>{i.site}</TableCell>
                        <TableCell>{formattedCell(format(new Date(i.lastAudit), "dd/MM/yyyy"), `${differenceInCalendarDays(today, new Date(i.lastAudit))} days ago`)}</TableCell>
                        <TableCell>{countCell(i, "Low")}</TableCell>
                        <TableCell>{countCell(i, "Medium")}</TableCell>
                        <TableCell>{countCell(i, "High")}</TableCell>
                        <TableCell>{countCell(i, "Critical")}</TableCell>
                        <TableCell>{i.overallCount > 0 && formattedCell(i.completedCount, `of ${i.overallCount}`)}</TableCell>
                        <TableCell>{i.overallCount > 0 && <LinearProgressWithLabel value={i.completionPercent} />}</TableCell>
                    </TableRow>
                ));
        }

        return (
            <TableRow>
                <TableCell colSpan={headCells.length}>
                    <Typography>No insights are available.</Typography>
                </TableCell>
            </TableRow>
        );
    };

    const handleSortClick = (event, sortColumn) => {
        console.log({ sortColumn })

        const isAsc = auditInsightsTable.sortBy === sortColumn && auditInsightsTable.orderBy === sortOrderName.ASC;
        dispatch(
            setTablePage({
                ...auditInsightsTable,
                key: tableKey,
                page: 0,
                sortBy: sortColumn,
                orderBy: isAsc ? sortOrderName.DESC : sortOrderName.ASC,
            })
        );
    };

    return (
        <>
            <CardBase
                title="Site Breakdown"
                description="Offers a detailed breakdown of actions and overall completion rates, allowing for a quick assessment of safety"
                isLoading={insights.isLoading}
                isFetching={insights.isFetching}
                error={insights.error}
            >
                <TableContainer style={{ borderTop: '1px solid #cecece' }}>
                    <Table aria-label="Compliance">
                        <EnhancedTableHead
                            headCells={headCells}
                            sortColumn={auditInsightsTable.sortBy}
                            sortDirection={auditInsightsTable.orderBy || sortOrderName.DESC}
                            onRequestSort={handleSortClick}
                        />
                        <TableBody>{tableContent()}</TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={insights.data ? insights.data.length : 0}
                    rowsPerPage={auditInsightsTable.rowsPerPage}
                    page={auditInsightsTable.page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </CardBase>
        </>
    );
}

export default AuditInsightsTableCard;
