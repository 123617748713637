export const actionsStatus = {
  DUE: 0,
  OVERDUE: 1,
  COMPLETED: 2,
  NOTDUE: 3,
};

export const actionStatusNames = {
  DUE: "Due",
  OVERDUE: "Overdue",
  COMPLETED: "Completed",
  NOTDUE: "Not Due",
};

export const actionStatusDropdownValues = [
  {
    value: actionsStatus.DUE,
    label: "Due"
  },
  {
    value: actionsStatus.OVERDUE,
    label: "Overdue"
  },
  {
    value: actionsStatus.COMPLETED,
    label: "Completed"
  },
  {
    value: actionsStatus.NOTDUE,
    label: "Not Due"
  },
]