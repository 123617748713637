import TrustCentreArticle from "../TrustCentreArticle";

const contentNavItems = [
    {
        id: "prohibited-uses",
        title: "Prohibited Uses",
    },
    {
        id: "content-standards",
        title: "Content Standards",
    },
    {
        id: "suspension-and-termination",
        title: "Suspension and Termination",
    },
    {
        id: "changes-to-acceptable-use-policy",
        title: "Changes to Acceptable Use Policy",
    },
];

function AcceptableUsePolicy() {
    return (
        <TrustCentreArticle title="Acceptable Use Policy" lastUpdatedDate="2023-08-01" contentsNavItems={contentNavItems}>
            <section>
                <p>
                    This acceptable use policy sets out the terms between you and us under which you may access our website. This acceptable use policy applies
                    to all users of, and visitors to, our site. Your use of our site means that you accept, and agree to abide by, all the policies in this
                    acceptable use policy, which supplement our Terms of use policy.
                </p>
            </section>
            <section id="prohibited-uses">
                <h3>Prohibited Uses</h3>
                <p>You may use our site only for lawful purposes. You may not use our site:</p>
                <ul>
                    <li>In any way that breaches any applicable local, national or international law or regulation</li>
                    <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect</li>
                    <li>For the purpose of harming or attempting to harm minors in any way</li>
                    <li>
                        To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards (see below)
                    </li>
                    <li>
                        To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar
                        solicitation (spam)
                    </li>
                    <li>
                        To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers,
                        spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer
                        software or hardware
                    </li>
                </ul>
                <p>You also agree:</p>
                <li>Not to reproduce, duplicate, copy or re-sell any part of our site in contravention of the provisions of our Terms of use policy.</li>
                <li>
                    Not to access without authority, interfere with, damage or disrupt:
                    <ul>
                        <li>any part of our site</li>
                        <li>any equipment or network on which our site is stored</li>
                        <li>any software used in the provision of our site, or</li>
                        <li>any equipment or network or software owned or used by any third party</li>
                    </ul>
                </li>
            </section>
            <section id="content-standards">
                <h3>Content Standards</h3>
                <p>
                    These content standards apply to any and all material which you contribute to our site (contributions), and to any interactive services
                    associated with it.
                </p>
                <p>
                    You must comply with the spirit and the letter of the following standards. The standards apply to each part of any contribution as well as
                    to its whole.
                </p>
                <p>Contributions must:</p>
                <ul>
                    <li>Be accurate (where they state facts)</li>
                    <li>Be genuinely held (where they state opinions)</li>
                    <li>Comply with applicable law in the UK and in any country from which they are posted</li>
                </ul>
                <p>Contributions must not:</p>
                <ul>
                    <li>Contain any material which is defamatory of any person</li>
                    <li>Contain any material which is obscene, offensive, hateful or inflammatory</li>
                    <li>Promote sexually explicit material</li>
                    <li>Promote violence</li>
                    <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age</li>
                    <li>Infringe any copyright, database right or trade mark of any other person</li>
                    <li>Be likely to deceive any person</li>
                    <li>Be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence</li>
                    <li>Promote any illegal activity</li>
                    <li>Be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety</li>
                    <li>Be likely to harass, upset, embarrass, alarm or annoy any other person</li>
                    <li>Be used to impersonate any person, or to misrepresent your identity or affiliation with any person</li>
                    <li>Give the impression that they emanate from us, if this is not the case</li>
                    <li>Advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse</li>
                </ul>
            </section>
            <section id="suspension-and-termination">
                <h3>Suspension and Termination</h3>
                <p>
                    We will determine, in our discretion, whether there has been a breach of this acceptable use policy through your use of our site. When a
                    breach of this policy has occurred, we may take such action as we deem appropriate.
                </p>
                <p>
                    Failure to comply with this acceptable use policy constitutes a material breach of the Terms of use policy upon which you are permitted to
                    use our site, and may result in our taking all or any of the following actions:
                </p>
                <ul>
                    <li>Immediate, temporary or permanent withdrawal of your right to use our site</li>
                    <li>Immediate, temporary or permanent removal of any posting or material uploaded by you to our site</li>
                    <li>Issue of a warning to you</li>
                    <li>
                        Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable
                        administrative and legal costs) resulting from the breach
                    </li>
                    <li>Further legal action against you</li>
                    <li>Disclosure of such information to law enforcement authorities as we reasonably feel is necessary</li>
                </ul>
                <p>
                    We exclude liability for actions taken in response to breaches of this acceptable use policy. The responses described in this policy are not
                    limited, and we may take any other action we reasonably deem appropriate.
                </p>
            </section>
            <section id="changes-to-acceptable-use-policy">
                <h3>Changes to Acceptable Use Policy</h3>
                <p>
                    We may revise this acceptable use policy at any time by amending this page. You are expected to check this page from time to time to take
                    notice of any changes we make, as they are legally binding on you. Some of the provisions contained in this acceptable use policy may also
                    be superseded by provisions or notices published elsewhere on our site.
                </p>
            </section>
        </TrustCentreArticle>
    );
}

export default AcceptableUsePolicy;
