import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    subTitle: {
        padding: theme.spacing(3),
    },
    title: {
        padding: theme.spacing(0),
        margin: theme.spacing(0),
    },
    actions: {
        display: "flex",
        flexDirection: "row-reverse",
        gap: theme.spacing(1),
        margin: theme.spacing(3, 0, 2, 0),
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
}));

export default function AcknowledgementDialog({ open, onConfirm, onCancel }) {
    const classes = useStyles();

    return (
        <Dialog open={open} maxWidth="xs">
            <div className={classes.subTitle}>
                <DialogTitle className={classes.title}>Confirm Review</DialogTitle>
                <DialogContentText className={classes.title}>
                    By confirming this review, you acknowledge that you have read and understand the contents of this document.
                </DialogContentText>
            </div>
            <DialogContent>
                <div className={classes.actions}>
                    <Button onClick={onConfirm} fullWidth color="secondary" variant="contained">
                        Confirm Review
                    </Button>
                    <Button onClick={onCancel} fullWidth variant="outlined" color="secondary">
                        Cancel
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}
