import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useRouteMatch } from "react-router-dom";
import useAddTemplateForm from "../hooks/mutations/useAddTemplateForm";
import useTemplates from "../hooks/queries/useTemplates";
import useSectors from "../hooks/queries/useSectors";
import useSubjects from "../hooks/queries/useSubjects";
import { baseRoute } from "../routes";
import CardBase from "../../components/cards/CardBase";
import PepperStepper from "../components/common/PepperStepper";
import FilteredTemplatesTable from "../components/compliance/templateForms/FilteredTemplatesTable";
import { SelectedTemplatesList } from "../components/compliance/templateForms/SelectedTemplatesList";
import AddTemplateFormAccordion from "../components/compliance/templateForms/AddTemplateFormAccorion";
import { formatShortDate } from "../../utils/dateTimeUtils";
import PageBase from "../../components/PageBase";
import moduleAreas from "../constants/moduleAreaConstants";
import PageTitle from "../components/PageTitle";
import useContactsForAppAndSite from "../../hooks/useContactsForAppAndSite";
import { frequency, frequencyDropdownValues } from "../constants/frequencyConstants";
import sortOrder, { sortOrderName } from "../constants/sortOrder";

const useStyles = makeStyles((theme) => ({
  detail: {
    fontWeight: "600",
  },
  padding: {
    padding: theme.spacing(1),
    display: "flex",
    gap: theme.spacing(1),
    flexWrap: "wrap",
  },
  bottomGap: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    gridColumnGap: theme.spacing(2),
    marginTop: theme.spacing(2),
    justifyContent: "space-between",
  },
  rightButtonContainer: {
    display: "flex",
    justifyContent: "end",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
}));

const steps = [
  { label: "Add Forms" },
  { label: "Configure Forms" },
  { label: "Review" },
];

export default function AddTemplateForm() {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const { params } = match;
  const { moduleArea } = params;

  const addNewTemplateForm = useAddTemplateForm();
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderBy] = useState(sortOrderName.DESC);

  const { sites, selectedSite, activeAppId, appSiteIds, selectedAccount } =
    useSelector((state) => state.account);

  const contacts = useContactsForAppAndSite({ selectedSiteId: null });

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [addedTemplates, setAddedTemplates] = useState([]);
  const [selectedSector, setSelectedSector] = useState(0);
  const [selectedSubject, setSelectedSubject] = useState(0);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [filterText, setFilterText] = useState("");

  const { data, isLoading, error } = useTemplates({
    pageSize: rowsPerPage,
    pageNum: page + 1,
    sectorId: selectedSector,
    subjectId: selectedSubject,
    accountId: selectedAccount.account.externalId,
    siteExternalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
      moduleArea: moduleAreas[moduleArea.toUpperCase()],
      frequency: selectedFrequency,
      filterText: filterText,
      orderByColumn: sortBy || "none",
      sortOrder: orderBy === sortOrderName.DESC ? sortOrder.DESC : sortOrder.ASC,

  });

  const frequencyOptions = [{ value: null, label: "All" }];

  const sectors = useSectors();
  const subjects = useSubjects();

  const sectorOptions = [{ id: 0, name: "All" }];
  const subjectOptions = [{ id: 0, name: "All" }];

  const { enqueueSnackbar } = useSnackbar();

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const allFormsComplete = useMemo(() => {
    return !addedTemplates?.every((form) => form.complete);
  }, [addedTemplates]);

  function addTemplate(template) {
    setAddedTemplates([
      ...addedTemplates,
      {
        template,
        templateId: template.id,
        startDate: new Date(),
        assignedEmployeeExternalId: 0,
        siteExternalId: null,
        complete: false,
        tempFormId: addedTemplates.length + 1,
        templateVersionId: template.templateVersionId,
        applicationArea: template.applicationArea,
      },
    ]);
  }

  function handleEmployeeChange(e, form) {
    setAddedTemplates(
      addedTemplates.map((formDetail) =>
        formDetail.tempFormId === form.tempFormId
          ? {
              ...formDetail,
              assignedEmployeeExternalId: e.target.value,
              complete: true,
            }
          : formDetail
      )
    );
  }

  function handleSiteChange(e, form) {
    setAddedTemplates(
      addedTemplates.map((formDetail) =>
        formDetail.tempFormId === form.tempFormId
          ? {
              ...formDetail,
              siteExternalId: e.target.value,
              complete: false,
            }
          : formDetail
      )
    );
  }

  function handleDateChange(e, form) {
    let tempDate = new Date(e);
    const startDate = new Date(
      Date.UTC(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate())
    );

    setAddedTemplates(
      addedTemplates.map((formDetail) =>
        formDetail.tempFormId === form.tempFormId
          ? {
              ...formDetail,
              startDate,
            }
          : formDetail
      )
    );
  }

  const handleClickSave = () => {
    addNewTemplateForm.mutate(addedTemplates, {
      onSuccess: () => history.push(`${baseRoute}/${moduleArea}`),
      onError: (error) => {
        console.error(error);
        enqueueSnackbar(
          error?.message || "There was an error adding the template form",
          {
            variant: "error",
          }
        );
      },
    });
  };

  function handleDelete(id) {
    setAddedTemplates(addedTemplates.filter((x) => x.template.id !== id));
  }

  function handleDecrement(id) {
    setAddedTemplates(addedTemplates.filter((x) => x.tempFormId !== id));
  }

    const onBack = () => history.goBack();

    const handleFilteredTextClear = () => {
        setPage(0);
        setFilterText('');
    };

    const handleFilteredTextChange = (textString) => {
        setPage(0);
        setFilterText(textString);
    };

  return (
    <PageBase>
      <Container>
        <div className={classes.bottomGap}>
          <PageTitle title="Add Form" />
        </div>
        <div className={classes.bottomGap}>
          <CardBase>
            <PepperStepper activeStep={activeStep} steps={steps} />
          </CardBase>
        </div>
        {activeStep === 0 ? (
          <>
            <FilteredTemplatesTable
              data={data}
              sectors={sectors}
              subjects={subjects}
              frequencies={frequencyDropdownValues}
              addTemplate={addTemplate}
              addedTemplates={addedTemplates}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              sectorOptions={sectorOptions}
              subjectOptions={subjectOptions}
              frequencyOptions={frequencyOptions}
              selectedSector={selectedSector}
              selectedSubject={selectedSubject}
              selectedFrequency={selectedFrequency}
              handleSectorChange={(e) => { setPage(0); setSelectedSector(e.target.value); } }
              handleSubjectChange={(e) => { setPage(0); setSelectedSubject(e.target.value); } }
              handleFrequencyChange={(e) => { setPage(0); setSelectedFrequency(e.target.value); } }
              isLoading={isLoading}
                          error={error}
                          onFilteredTextChange={handleFilteredTextChange}
                          filteredText={filterText}
                          clearFilteredText={handleFilteredTextClear}
                          onOrderByChange={setOrderBy}
                          onSortByChange={setSortBy}
                          sortBy={sortBy}
                          orderBy={orderBy}
            />

            <SelectedTemplatesList
              addedTemplates={addedTemplates}
              handleDelete={handleDelete}
              addTemplate={addTemplate}
              handleDecrement={handleDecrement}
              setActiveStep={setActiveStep}
            />
          </>
        ) : activeStep === 1 ? (
          <CardBase>
            {addedTemplates?.map((form, index) => (
              <Grid
                key={form.template.id}
                spacing={4}
                container
                justifyContent="center"
              >
                <Grid item xs={12} md={12}>
                  <AddTemplateFormAccordion
                    form={form}
                    sites={sites}
                    index={index}
                    handleDateChange={handleDateChange}
                    handleSiteChange={handleSiteChange}
                    handleEmployeeChange={handleEmployeeChange}
                    moduleArea={moduleArea}
                  />
                </Grid>
              </Grid>
            ))}
            <div className={classes.buttonContainer}>
              <Button variant="outlined" color="primary" onClick={onBack}>
                Cancel
              </Button>
              <div className={classes.rightButtonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setActiveStep(0)}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setActiveStep(2)}
                  disabled={allFormsComplete}
                >
                  Next
                </Button>
              </div>
            </div>
          </CardBase>
        ) : (
          <CardBase>
            {addedTemplates?.map((form) => (
              <Grid
                key={form.template.id}
                container
                spacing={4}
                justifyContent="center"
              >
                <Grid item xs={12} md={12}>
                  <Accordion elevation={0} variant="outlined" defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant="h6">{form.template.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={4}>
                          <Typography>Sector</Typography>
                          <Typography className={classes.detail}>
                            {form.template.sector}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography>Frequency</Typography>
                          <Typography className={classes.detail}>
                            {form.template.frequencyDescription}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography>Responsible Employee</Typography>
                          <Typography className={classes.detail}>{`${
                            contacts.data[form.assignedEmployeeExternalId]
                              ?.firstName
                          } ${
                            contacts.data[form.assignedEmployeeExternalId]
                              ?.lastName
                          }`}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography>Subject</Typography>
                          <Typography className={classes.detail}>
                            {form.template.subject}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography>Site</Typography>
                          <Typography className={classes.detail}>
                            {sites[form.siteExternalId]?.name ?? "Unknown"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography>{form.template.frequency === frequency.NONE ? "Date of Check" : "Start Date of Checks"}</Typography>
                          <Typography className={classes.detail}>
                            {formatShortDate(form.startDate)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            ))}
            <div className={classes.buttonContainer}>
              <Button variant="outlined" color="primary" onClick={onBack}>
                Cancel
              </Button>
              <div className={classes.rightButtonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setActiveStep(1)}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </CardBase>
        )}
      </Container>
    </PageBase>
  );
}
