import { useMutation } from "react-query";
import templateService from "../../services/templateService";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";

const useAddTemplate = () =>
  useMutation(templateService.addNewTemplate, {
    onMutate: async (newTemplate) => {
      const { client } = reactQueryUtils;
      const queryKey = [
        queryKeys.templates,
        newTemplate.applicationArea,
      ];
      await client.cancelQueries(queryKey);
      const previousTemplates = client.getQueryData(queryKey);
      client.setQueryData(queryKey, (previous) =>
        previous ? [...previous, newTemplate] : [newTemplate]
      );
      return { previousTemplates };
    },
    onError: (error, newTemplate, context) => {
      reactQueryUtils.client.setQueryData(
        [
          queryKeys.templates,
          newTemplate.applicationArea,
        ],
        context.previousTemplates
      );
    },
    onSettled: (_data, _error, variables) => {
      if (variables)
        reactQueryUtils.client.invalidateQueries([
          queryKeys.templates,
          variables.applicationArea,
        ]);
    },
  });

export default useAddTemplate;
