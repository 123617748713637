import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import TrendIndicator from "../TrendIndicator";

export default function ImproveScoreDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>How can you improve your SafetyNest score?</DialogTitle>
      <DialogContent>
        <Typography>
          There are three main reasons to manage health and safety, legal, moral
          and financial. Your score is no different, SafetyNest is built to
          support each of these reasons.
          <br />
          <br />
          Completing the below it will not only help your score but help you
          manage your health and safety.
        </Typography>
        <Typography>
          <ul>
            <li>
              Use more modules, to get a minimum score you need to be using at
              least three of the modules.
            </li>
            <li>
              Assign forms in Compliance and Monitoring and then completing
              them.
            </li>
            <li>
              Complete actions, to help you do this set up more user in your
              organisation and assign to them.
            </li>
            <li>
              Target overdue or &apos;higher&apos; risk items for completion
              first.
            </li>
            <li>Investigate the incidents you have logged.</li>
          </ul>
        </Typography>

        <Typography variant="h6">Trend Indicator</Typography>
        <Typography>
          Our trend indicator shows how your current live score compares to your
          score <strong>1 month</strong> ago.
          <ul>
            <li>
              Rising (
              <TrendIndicator trend={1} difference={"+4"} size="small" />)
              indicates your score has increased versus last month.
            </li>
            <li>
              Falling (
              <TrendIndicator trend={2} difference={"-6"} size="small" />)
              indicates your score has decreased versus last month.
            </li>
          </ul>
          Your score is being maintained if none of the above indicators are
          displayed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
