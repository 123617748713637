import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  asterisk: {
    color: red[600],
  },
  questionText: {
    marginBottom: theme.spacing(1),
  },
}));

const FormQuestionText = ({ text, required }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.questionText}>
      {text} {required && <span className={classes.asterisk}>*</span>}
    </Typography>
  );
};

export default FormQuestionText;
