import { useMutation } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import auditService from "../../services/auditService";

const useAddAuditActionFile = () =>
    useMutation(
        ({ formData, siteExternalId, auditId }) =>
            auditService.uploadAuditActionFile(formData, siteExternalId, auditId),
        {
            onSuccess: () => {
                reactQueryUtils.client.invalidateQueries([queryKeys.siteMeridianAudits]);
            },
        }
    );

export default useAddAuditActionFile;
