import { SET_RESET_USER, SET_SITE_ID, SET_USER_TO_MANAGE } from "../types";

const initialState = {
  siteId: "",
  userToManage: {},
};
function pageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SITE_ID:
      return {
        ...state,
        siteId: action.payload.siteId,
      };
    case SET_USER_TO_MANAGE:
      return {
        ...state,
        userToManage: action.payload.userToManage,
      };
    case SET_RESET_USER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default pageReducer;
