import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import hazardService from "../../services/hazardService";

const useHazards = () =>
  useQuery([queryKeys.hazards], () => hazardService.fetchAllHazards(), {
    staleTime: Infinity,
  });

export default useHazards;
