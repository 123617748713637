import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import documentService from "../../services/documentService";
import reactQueryUtils from "../../utils/reactQueryUtils";
import { documentTypes } from "../../constants/documentConstants";

const { client } = reactQueryUtils;

const useRenameDocument = () =>
  useMutation(
    ({ oldDocumentPath, newDocumentPath, selectedFolder }) =>
      documentService.renameDocument({
        oldDocumentPath,
        newDocumentPath,
        selectedFolder,
      }),
    {
      onSettled: () => {
        const queryKey = [queryKeys.documents, documentTypes.ACCOUNT.value];
        client.invalidateQueries(queryKey);
      },
    }
  );

export default useRenameDocument;
