// Source for regex: https://stackoverflow.com/questions/6222215/regex-for-validating-folder-name-file-name/21263015
const invalidFolderCharacters = /^[^\\\/?%*:|"<>\.]+$/;

// Source for regex: https://stackoverflow.com/questions/4250364/how-to-trim-a-file-extension-from-a-string-in-javascript
const fileExtension = /\.[^\/.]+$/;

const regex = {
  invalidFolderCharacters,
  fileExtension,
};

export default regex;
