import React from "react";
import { useTracking } from "react-tracking";
import { Link, useRouteMatch } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import TemplatesTableCard from "../../components/IncidentsAdminTemplatesTableCard";
import CardBase from "../../../components/cards/CardBase";
import useRemainingIncidentTypes from "../../hooks/queries/useRemainingIncidentTypes";
import PageBase from "../../../components/PageBase";
import PageTitle from "../../components/PageTitle";
import { Favorite } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { useAuth } from "../../../contexts/authContext";
import RequiresRoleWrapper from "../../../components/RequiresRoleWrapper";

function IncidentsAdmin() {
  const match = useRouteMatch();
  const { selectedAccount } = useSelector((state) => state.account);
  const { isTemplateAdminForSites, isWorknestUser } = useAuth();
  const { path } = match;
  const { Track } = useTracking({
    page: `Admin: Incidents`,
  });
 
  const isTemplateAdmin = isTemplateAdminForSites(selectedAccount?.childExternalIds);

  return (
    <RequiresRoleWrapper hasRole={isTemplateAdmin || isWorknestUser}>
      <PageBase>
        <Track>
          <Grid container spacing={2}>
            <Grid item xs={12}>          
                <CardBase
                  title={
                    <PageTitle icon={<Favorite />} title="Incidents Setup" />
                  }
                  rightComponent={
                    <Button
                      component={Link}
                      to={`${path}/templatebuilder`}
                      variant="contained"
                      color="primary"
                    >
                      Create Template
                    </Button>
                  }
                />
            </Grid>
            <Grid item xs={12}>
              <TemplatesTableCard />
            </Grid>
          </Grid>
        </Track>
      </PageBase>
    </RequiresRoleWrapper>
  );
}

export default IncidentsAdmin;
