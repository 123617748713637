import {
  Accordion,
  FormHelperText,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import useIsMobile from "../../../../hooks/useIsMobile";
import useContactsForAppAndSite from "../../../../hooks/useContactsForAppAndSite";
import moduleAreas, { moduleUrls } from "../../../constants/moduleAreaConstants";
import { frequency } from "../../../constants/frequencyConstants";

const useStyles = makeStyles(() => ({
  form: {
    marginTop: "4px",
  },
  accordion: {
    alignItems: "baseline",
    columnGap: "10px",
  },
  incomplete: {
    fontSize: "11px",
    fontWeight: "600",
    color: "red",
  },
  complete: {
    fontSize: "11px",
    fontWeight: "600",
    color: "green",
  },
  datePicker: {
    display: "flex",
    alignItems: "flex-end",
  },
}));

export default function AddTemplateFormAccordion({
  form,
  sites,
  index,
  handleDateChange,
  handleSiteChange,
  handleEmployeeChange,
  moduleArea,
}) {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const contacts = useContactsForAppAndSite({ selectedSiteId: form.siteExternalId });

    const hasNoUsers = Object.values(contacts.data).length === 0 && form.siteExternalId;

  return (
    <Accordion elevation={0} variant="outlined" defaultExpanded={index === 0}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box display="flex" className={classes.accordion}>
          <Typography variant="h6">{form.template.name}</Typography>
          <Typography
            className={form.complete ? classes.complete : classes.incomplete}
          >
            {form.complete ? "COMPLETE" : "INCOMPLETE"}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {isMobile ? (
              <FormControl
                variant="outlined"
                className={classes.form}
                fullWidth
              >
                <InputLabel htmlFor="site-select">Select Site</InputLabel>
                <Select
                  inputProps={{
                    name: "site-select",
                    id: "site-select",
                  }}
                  native
                  label="Select Site"
                  value={form.siteExternalId}
                  onChange={(e) => handleSiteChange(e, form)}
                >
                  {Object.values(sites)?.map((site) => (
                    <option key={site.id} value={site.externalId}>
                      {site.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.form}
                fullWidth
              >
                <InputLabel id="site-select-label">Select Site</InputLabel>
                <Select
                  labelId="site-select-label"
                  label="Select Site"
                  value={form.siteExternalId}
                  onChange={(e) => handleSiteChange(e, form)}
                >
                  {Object.values(sites)?.map((site) => (
                    <MenuItem key={site.id} value={site.externalId}>
                      {site.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {isMobile ? (
              <FormControl
                variant="outlined"
                className={classes.form}
                fullWidth
              >
                <InputLabel htmlFor="employee-select">
                  Employee Responsible
                </InputLabel>
                <Select
                  inputProps={{
                    name: "employee-select",
                    id: "employee-select",
                  }}
                  native
                  disabled={!form.siteExternalId || hasNoUsers}
                  label="Employee Responsible"
                  value={form.assignedEmployeeExternalId}
                  onChange={(e) => handleEmployeeChange(e, form)}
                >
                  {hasNoUsers ? (
                    <option>No employees for the selected site</option>
                  ) : (
                    Object.values(contacts.data).sort((a, b) => (a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0)).map((user) => (
                      <option
                        key={user.externalId}
                        value={user.externalId}
                      >{`${user.firstName} ${user.lastName}`}</option>
                    ))
                  )}
                </Select>
                {hasNoUsers && (
                  <FormHelperText>
                    No employees for the selected site
                  </FormHelperText>
                )}
              </FormControl>
            ) : (
              <FormControl
                error={hasNoUsers}
                variant="outlined"
                className={classes.form}
                fullWidth
              >
                <InputLabel id="employee-select-label">
                  Employee Responsible 
                </InputLabel>
                <Select
                  labelId="employee-select-label"
                  label="Employee Responsible"
                  disabled={!form.siteExternalId || hasNoUsers}
                  value={form.assignedEmployeeExternalId}
                  onChange={(e) => handleEmployeeChange(e, form)}
                >
                  {hasNoUsers ? (
                    <MenuItem>No employees for the selected site</MenuItem>
                  ) : (
                    Object.values(contacts.data).sort((a, b) => (a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0)).map((user) => (
                      <MenuItem
                        key={user.externalId}
                        value={user.externalId}
                      >{`${user.firstName} ${user.lastName}`}</MenuItem>
                    ))
                  )}
                </Select>
                {hasNoUsers && (
                  <FormHelperText>
                    No employees for the selected site
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} md={6} className={classes.datePicker}>
            <FormControl fullWidth>
              <KeyboardDatePicker
                format="dd/MM/yyyy"
                variant="inline"
                inputVariant="outlined"
                label={form.template.frequency === frequency.NONE ? "Due Date" : "Start Date"}
                value={form.startDate}
                onChange={(e) => handleDateChange(e, form)}
                minDate={moduleArea === moduleUrls[moduleAreas.COMPLIANCE] ? undefined : new Date()}
              />
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
