import { useMutation } from "react-query";
import templateFormService from "../../services/templateFormService";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";

const useUpdateTemplateForm = () =>
    useMutation(templateFormService.updateTemplateForm, {
        onMutate: async (templateUpdate) => {
            const { client } = reactQueryUtils;
            const queryKey = [
                queryKeys.templateForms,
                templateUpdate.externalIds?.join(","),
                templateUpdate.page,
                templateUpdate.rows,
                templateUpdate.moduleArea
            ];
            await client.cancelQueries(queryKey);
            const previousTemplateForms = client.getQueryData(queryKey);

            client.setQueryData(queryKey, templateForms => {

                var validForms = templateForms?.results?.map((templateForm) => {
                    if (templateForm.id !== templateUpdate.templateFormId) return templateForm;

                    return {
                        ...templateForm,
                        siteExternalId: templateUpdate.siteExternalId,
                        startDate: templateUpdate.startDate,
                        dueDate: templateUpdate.dueDate,
                        assignedEmployeeExternalId: templateUpdate.assignedEmployeeExternalId,
                        applicationArea: templateUpdate.moduleArea
                    };
                })

                return {
                    count: validForms?.count,
                    templateFormsForSites: validForms
                };

            }

            );

            return { previousTemplateForms }
        },
        onError: (error, data, context) => {
            reactQueryUtils.client.setQueryData(
                [
                    queryKeys.templateForms,
                    data.externalIds?.join(","),
                    data.page,
                    data.rows,
                    data.moduleArea
                ],
                context.previousTemplateForms
            );
        },
        onSettled: (_data, _error, variables) => {
            if (variables)
                reactQueryUtils.client.invalidateQueries([
                    queryKeys.templateForms,
                    variables.externalIds?.join(","),
                    variables.page,
                    variables.rows,
                    variables.moduleArea
                ]);
        },
    });

export default useUpdateTemplateForm;
