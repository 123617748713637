import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import auditService from "../../services/auditService";


const useAuditInsights = (externalIds, auditType) => useQuery(
    [
        queryKeys.auditInsights,
        externalIds?.join(","),
        auditType
    ],
    () => auditService.fetchAuditInsights(externalIds, auditType),
    {
        enabled: !!externalIds?.length,
        staleTime: 60000
    }
);

export default useAuditInsights;