import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

const TemplateFormHistoryDownloadDialog = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Confirm Form History Download</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to download the history of this form. Each version of
          the form will be downloaded as a separate CSV file, preserving the
          state of the form at the time of each version's completion. Do you
          wish to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TemplateFormHistoryDownloadDialog;
