import { makeStyles, Slider } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
    scoreBandContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(0.5),
        fontSize: '0.75rem',
        '&.band-0': {
            color: theme.palette.getContrastText('#e46164'),
        },
        '&.band-1': {
            color: theme.palette.getContrastText('#eba240'),
        },
        '&.band-2': {
            color: theme.palette.getContrastText('#eac24f'),
        },
        '&.band-3': {
            color: theme.palette.getContrastText('#afc951'),
        },
        '&.band-4': {
            color: theme.palette.getContrastText('#44ce82'),
        },
    },
    scoreBandLimit: {
        placeSelf: 'flex-end',
    },
    scoreBandText: {
        placeSelf: 'center',
    },
    scoreBandPill: {
        width: '100%',
        height: theme.spacing(1),
        padding: 0,
        borderRadius: theme.spacing(0.45),
        '&.band-0': {
            backgroundColor: '#e46164',
            color: '#e46164',
        },
        '&.band-1': {
            backgroundColor: '#eba240',
            color: '#eba240',
        },
        '&.band-2': {
            backgroundColor: '#eac24f',
            color: '#eac24f',
        },
        '&.band-3': {
            backgroundColor: '#afc951',
            color: '#afc951',
        },
        '&.band-4': {
            backgroundColor: '#44ce82',
            color: '#44ce82',
        },
    },
    scoreBandSlider: {
        width: '100%',
        height: theme.spacing(1),
        padding: 0,
        borderRadius: theme.spacing(0.45),
        '& .MuiSlider-track': {
            border: 'none',
        },
        '& .MuiSlider-thumb': {
            height: 17,
            width: 17,
            marginTop: theme.spacing(-0.5),
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:before': {
                display: 'none',
            },
        },
        '&.band-0': {
            backgroundColor: '#e46164',
            color: '#e46164',
        },
        '&.band-1': {
            backgroundColor: '#eba240',
            color: '#eba240',
        },
        '&.band-2': {
            backgroundColor: '#eac24f',
            color: '#eac24f',
        },
        '&.band-3': {
            backgroundColor: '#afc951',
            color: '#afc951',
        },
        '&.band-4': {
            backgroundColor: '#44ce82',
            color: '#44ce82',
        },
    },
}))

function CalculateSliderValue(overallSafetyNestScore) {
    return /^\d{3}$/.test(overallSafetyNestScore) ? 
        Number(overallSafetyNestScore.toString().slice(1)) === 0 ? 99 : Number(overallSafetyNestScore.toString().slice(1))
        : overallSafetyNestScore
}

export default function ScoreSkittle({ bandText, bandLowerLimit, bandUpperLimit, bandRating, overallSafetyNestScore, compact }) {
    const classes = useStyles()
    const sliderValue = CalculateSliderValue(overallSafetyNestScore)

    return (
        <div className={clsx(classes.scoreBandContainer, `band=${bandRating}`)}>
            {!compact && (
                <div className={classes.scoreBandText}>
                    {bandText}
                </div>
            )}  
            {overallSafetyNestScore <= bandUpperLimit && overallSafetyNestScore >= bandLowerLimit ? 
                <Slider disabled value={sliderValue} size='small' className={clsx(classes.scoreBandSlider, `band-${bandRating}`)} />
                :
                <div className={clsx(classes.scoreBandPill, `band-${bandRating}`)} />
            }
            <div className={classes.scoreBandLimit}>
                {bandUpperLimit}
            </div>
        </div>
    )
}