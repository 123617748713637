import axios from "../utils/axios";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const getByUserExternalId = (userExternalId) =>
  axios.get(`${apiUrl}/UserAlertSetting/${userExternalId}`);

const update = (userAlertSetting) =>
  axios.patch(`${apiUrl}/UserAlertSetting/Update`, userAlertSetting);
const create = (userAlertSetting) =>
  axios.post(`${apiUrl}/UserAlertSetting`, userAlertSetting);

const userAlertSettingService = {
  getByUserExternalId,
  update,
  create,
};

export default userAlertSettingService;
