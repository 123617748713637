import appConfigurationClient from "../appConfigurationClient";

const isProductionEnvironment = process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "preprod";

const getFeatureFlag = async (featureName) => {
    if (!isProductionEnvironment) {
        console.log("Feature flags are enabled in non-production environments");
        return true;
    }

    if (!featureName || !featureName.trim().length) {
        throw new Error("Feature flag name is required");
    }

    try {
        const result = await appConfigurationClient.getConfigurationSetting({ key: `.appconfig.featureflag/${featureName}`, label: "mwn:ui" });
        if (result && typeof result === "object") {
            return JSON.parse(result.value).enabled;
        }

        return false;
    } catch (e) {
        console.error(e);
        return false;
    }
};

const featureFlagService = {
    getFeatureFlag,
};

export default featureFlagService;
