import React, { useEffect, useState } from "react";
import {
    Grid,
    Button,
    makeStyles,
    Typography,
    Box,
    CircularProgress,
} from "@material-ui/core";
import { Folder as FolderIcon, GetApp, Delete, Edit } from "@material-ui/icons";
import useAddFolder from "../../hooks/mutations/useAddFolder";
import NewFolderDialog from "./NewFolderDialog";
import DocumentUploader from "./DocumentUploader";
import { documentTypes } from "../../constants/documentConstants";
import documentService from "../../services/documentService";
import useDeleteDocument from "../../hooks/mutations/useDeleteDocument";
import useAddDocuments from "../../hooks/mutations/useAddDocuments";
import DocumentList from "./DocumentList";
import FolderList from "./FolderList";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFolder } from "../../redux/actions/documentsActions";
import RenameDocumentDialog from "./RenameDocumentDialog";
import useRenameDocument from "../../hooks/mutations/useRenameDocument";
import fileNameUtils from "../../utils/fileNameUtils";
import useDocumentNames from "../../hooks/documents/useDocumentNames";
import usePathWithAccountName from "../../hooks/documents/usePathWithAccountName";
import useDocumentsAllSites from "../../hooks/documents/useDocumentsAllSites";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";

const useStyles = makeStyles((theme) => ({
    gridItem: {
        display: "flex",
        flexDirection: "column",
    },
    paneBase: {
        borderRight: "1px solid grey",
        borderBottom: "1px solid grey",
    },
    folderHeader: {
        border: "1px solid grey",
        padding: theme.spacing(1),
        height: "50px",
    },
    fileHeader: {
        [theme.breakpoints.up("md")]: {
            borderTop: "1px solid grey",
        },
        [theme.breakpoints.down("sm")]: {
            borderLeft: "1px solid grey",
        },
        padding: theme.spacing(1),
        height: "50px",
    },
    folderPane: {
        borderLeft: "1px solid grey",
        overflowX: "auto",
    },
    filePane: {
        [theme.breakpoints.down("sm")]: {
            borderLeft: "1px solid grey",
        },
        height: "100%",
    },
    fileTitle: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    documentUploader: {
        flexGrow: 1,
    },
    rightButton: {
        marginLeft: theme.spacing(2),
    },
}));

const DocumentViewer = ({ documentType, data }) => {
    const [newFolderOpen, setNewFolderOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState({ name: "", path: "" });
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [renameDocumentOpen, setRenameDocumentOpen] = useState(false);

    const { selectedFolder } = useSelector((state) => state.documents);
    const { account } = useSelector((state) => state.account.selectedAccount);
    const { externalId } = account;

    const classes = useStyles();

    const dispatch = useDispatch();

    const addFolder = useAddFolder();
    const addDocuments = useAddDocuments();
    const deleteDocument = useDeleteDocument();
    const renameDocument = useRenameDocument();

    const { enqueueSnackbar } = useSnackbar();

    const documentNames = useDocumentNames({ selectedFolder, data });
    const pathWithAccountName = usePathWithAccountName({ selectedFolder, data });
    const sites = useDocumentsAllSites({ data });

    const handleAddFolder = (name) => {
        const fileName = `${selectedFolder}/${name}/$$$.$$$`;

        addFolder.mutate(
            {
                externalId,
                documentType: documentType.value,
                fileName,
            },
            {
                onError: (e) =>
                    enqueueSnackbar(
                        e?.message || "There was an error adding the folder",
                        { variant: "error" }
                    ),
            }
        );
    };

    const handleClickDownload = async () => {
        try {
            await documentService.downloadDocument(
                documentType.value,
                selectedFile.path,
                selectedFile.name
            );
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = () => {
        setConfirmDeleteOpen(false);
        setSelectedFile({ name: "", path: "" });
        deleteDocument.mutate(
            {
                externalId,
                documentType: documentType.value,
                fileName: selectedFile.path,
            },
            {
                onError: (e) =>
                    enqueueSnackbar(
                        e?.message || "There was an error deleting the file",
                        {
                            variant: "error",
                        }
                    ),
                onSuccess: () => enqueueSnackbar("File deleted"),
            }
        );
    };

    const handleRenameDocument = (newDocumentName) => {
        const oldDocumentPath = selectedFile.path;
        const newDocumentPath = `${selectedFolder}/${newDocumentName}${fileNameUtils.getFileExtension(selectedFile.path)}`;

        renameDocument.mutate(
            {
                oldDocumentPath,
                newDocumentPath,
                selectedFolder,
            },
            {
                onSuccess: () =>
                    enqueueSnackbar(
                        `'${selectedFile.name}' renamed to '${newDocumentName}${fileNameUtils.getFileExtension(selectedFile.path)}'`,
                        {
                            variant: "default",
                        }
                    ),
                onError: (e) =>
                    enqueueSnackbar(
                        e?.message || "There was an error renaming the file",
                        {
                            variant: "error",
                        }
                    ),
            }
        );
        setSelectedFile({ name: "", path: "" });
    };

    // Clear selected file when folder changes
    useEffect(() => {
        setSelectedFile({ name: "", path: "" });
    }, [selectedFolder]);

    // Reset selected folder when component unmounts
    useEffect(() => {
        return () => dispatch(setSelectedFolder(""));
    }, [dispatch]);

    // Set default selected folder 
    useEffect(() => {
        if (sites?.length > 0)
            dispatch(setSelectedFolder(sites[0]?.externalId ?? ""))

    }, [dispatch, sites])

    if (!sites?.length)
        return null;

    return (
        <>
            <Box display="flex" justifyContent="flex-end" width="100%" mb={2}>
                {(addFolder.isLoading || deleteDocument.isLoading) && (
                    <CircularProgress color="primary" size={24} />
                )}
            </Box>
            <DndProvider backend={HTML5Backend}>
                <Grid container direction="row">
                    <Grid item xs={12} md={4} className={classes.gridItem}>
                        <Box
                            className={classes.folderHeader}
                            display="flex"
                            alignItems="center"
                        >
                            {documentType.value === documentTypes.ACCOUNT.value && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<FolderIcon />}
                                    onClick={() => setNewFolderOpen(true)}
                                >
                                    New Folder
                                </Button>
                            )}
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            flexGrow={1}
                            className={`${classes.paneBase} ${classes.folderPane}`}
                            style={{ width: "100%" }}
                        >
                            <FolderList sites={sites} documentType={documentType} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.gridItem}>
                        <Box
                            className={`${classes.paneBase} ${classes.fileHeader}`}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Box display="flex" alignSelf="flex-end">
                                <Typography className={classes.fileTitle} variant="subtitle2">
                                    {pathWithAccountName.replaceAll("/", " - ")}
                                </Typography>
                            </Box>
                            <Box display="flex" flexShrink={1} justifyContent="space-between">
                                {documentType.value === documentTypes.ACCOUNT.value && (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            startIcon={<Edit />}
                                            disabled={!selectedFile.name}
                                            onClick={() => setRenameDocumentOpen(true)}
                                        >
                                            Rename
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            startIcon={<Delete />}
                                            disabled={!selectedFile.name}
                                            onClick={() => setConfirmDeleteOpen(true)}
                                            className={classes.rightButton}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<GetApp />}
                                    disabled={!selectedFile.name}
                                    onClick={handleClickDownload}
                                    className={classes.rightButton}
                                >
                                    Download
                                </Button>
                            </Box>
                        </Box>
                        <div className={`${classes.paneBase} ${classes.filePane}`}>
                            <DocumentList
                                documentNames={documentNames}
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                                documentType={documentType}
                            />
                            {documentType.value === documentTypes.ACCOUNT.value && (
                                <DocumentUploader
                                    documentType={documentType}
                                    addDocuments={addDocuments}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </DndProvider>



            {documentType.value === documentTypes.ACCOUNT.value && (
                <NewFolderDialog
                    open={newFolderOpen}
                    handleClose={() => setNewFolderOpen(false)}
                    handleAddFolder={handleAddFolder}
                    pathWithAccountName={pathWithAccountName}
                />
            )}
            <ConfirmDeleteDialog
                open={confirmDeleteOpen}
                handleClose={() => setConfirmDeleteOpen(false)}
                name={selectedFile?.name}
                handleConfirm={handleDelete}
            />
            <RenameDocumentDialog
                open={renameDocumentOpen}
                handeClose={() => setRenameDocumentOpen(false)}
                oldDocumentName={selectedFile?.name}
                handleSave={handleRenameDocument}
                documentNames={documentNames}
            />
        </>
    );
};

export default DocumentViewer;