import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import consultantService from "../../services/consultantService";
import reactQueryUtils from "../../../utils/reactQueryUtils";

const useAccountConsultants = ({ selectedExternalIds, allExternalIds }, options) =>
  useQuery(
    [queryKeys.accountConsultants, selectedExternalIds?.join(",")],
    () => consultantService.fetchConsultantForAccounts(selectedExternalIds),
      {
      ...options,
      enabled: !!selectedExternalIds?.length,
      staleTime: Infinity,
      initialData: () => {
        const allConsultantsKey = [
          queryKeys.accountConsultants,
          allExternalIds?.join(","),
        ];

        return reactQueryUtils.client
          .getQueryData(allConsultantsKey)
          ?.filter((x) => selectedExternalIds.includes(x.siteExternalId));
        },
    }
  );

export default useAccountConsultants;
