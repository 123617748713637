import axios from "../../utils/axios";
import { buildQueryStringList, addQueryStringList } from "../../utils/stringUtils";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const fetchRiskAssessment = (riskAssessmentId) =>
  axios.get(`${apiUrl}/RiskAssessment/${riskAssessmentId}`);

const fetchRiskAssessmentWithHistory = (riskAssessmentId) =>
  axios.get(`${apiUrl}/RiskAssessment/AllHistory/${riskAssessmentId}`);

const addRiskAssessment = (riskAssessment) =>
  axios.post(`${apiUrl}/RiskAssessment`, riskAssessment);

const amendRiskAssessment = (riskAssessment) =>
  axios.patch(`${apiUrl}/RiskAssessment/Amend`, riskAssessment);

const deleteRiskAssessment = ({ siteExternalId, riskAssessmentId }) =>
  axios.delete(`${apiUrl}/RiskAssessment/${siteExternalId}/${riskAssessmentId}`);

const fetchRiskAssessmentsForSites = ({
  siteExternalIds,
  pageSize,
  pageNum,
  riskLevel = null,
  sortOrder,
  orderByColumn,
  assignees,
  filteredText,
  fromDate,
  toDate
}) => {
    let queryString = buildQueryStringList("siteExternalIds", siteExternalIds);
    let queryStringAssignees = addQueryStringList("assignees", assignees);

    if (queryStringAssignees !== null) queryString += queryStringAssignees;
    if (fromDate !== null) queryString += `&fromDate=${fromDate}`;
    if (toDate !== null) queryString += `&toDate=${toDate}`;
    if (riskLevel !== null) queryString += `&riskLevel=${riskLevel}`;
    if (filteredText) queryString += `&filteredText=${filteredText}`;

  return axios.get(
    `${apiUrl}/RiskAssessment/Sites/${queryString}&pageNum=${pageNum}&pageSize=${pageSize}&sortOrder=${sortOrder}&orderByColumn=${orderByColumn}`
  );
};

const riskAssessmentService = {
  fetchRiskAssessment,
  addRiskAssessment,
  fetchRiskAssessmentsForSites,
  amendRiskAssessment,
  deleteRiskAssessment,
  fetchRiskAssessmentWithHistory,
};

export default riskAssessmentService;
