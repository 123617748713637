import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import prismicService from "../../services/prismicService";
import reactQueryUtils from "../../utils/reactQueryUtils";

const usePrismicCategory = (sectionName, categoryId) =>
  useQuery(
    [queryKeys.prismicCategory, categoryId],
    () => prismicService.fetchCategory(sectionName, categoryId),
    {
      enabled: !!categoryId,
      initialData: () => {
        const { client } = reactQueryUtils;
        const existingCategory = client
          .getQueryData([queryKeys.prismicCategories, sectionName])
          ?.find((x) => x.id === categoryId);

        return existingCategory;
      },
    }
  );

export default usePrismicCategory;
