import { makeStyles } from "@material-ui/core";
import CardBase from "../cards/CardBase";

const useStyles = makeStyles(() => ({
    iframe: {
        width: "100%",
        height: "100vh",
    },
}));

export default function DocumentViewer({ fileType, fileContents }) {
    const classes = useStyles();

    return (
        <CardBase>
            <iframe className={classes.iframe} title="Document Viewer" type={fileType} src={"data:" + fileType + ";base64," + fileContents} />
        </CardBase>
    );
}
