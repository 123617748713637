import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import accountService from "../../services/accountService";

const useSitesByNameSearch = (accountName) => 
    useQuery([queryKeys.siteSearch, accountName], () =>
        accountService.searchAccountsByName(accountName),
        {
            enabled: !!accountName,
            retry: false,
        }
    );

export default useSitesByNameSearch;