import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTracking } from "react-tracking";
import useIsMobile from "../../hooks/useIsMobile";
import ActionContainer from "../components/actions/ActionContainer";
import { useActionDetails } from "../contexts/ActionContext";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#fff",
        borderBottom: "1px solid #c4c1d3",
        padding: theme.spacing(1),
        margin: theme.spacing(-3, -3, 3, -3),
    },
    width: {
        display: "flex",
        justifyContent: "flex-end",
        columnGap: theme.spacing(2),
        width: '50vw',
    },
    buttonContainerMobile: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "flex-end",
        columnGap: theme.spacing(2),
        backgroundColor: "#fff",
        borderTop: "1px solid #c4c1d3",
        padding: theme.spacing(1),
    },
}));

function Action() {
    const classes = useStyles();
    const {
        setSelectedActionId,
        setIsDri,
        action,
        isEditAllowed,
        goBack,
        save,
    } = useActionDetails()
    const { actionId, isDri } = useParams();
    const { Track } = useTracking({
        page: `Action:${actionId}`,
    });
    const isMobile = useIsMobile()

    useEffect(() => {
        setIsDri(isDri)
        setSelectedActionId(actionId)
    }, [])

    return (
        <Track>
            {!isMobile &&
                <div className={classes.buttonContainer}>
                    <div className={classes.width}>
                        <Button variant="text" color="secondary" onClick={goBack}>Cancel</Button>
                        <Button disabled={!isEditAllowed} variant="contained" color="secondary" onClick={save}>Save</Button>
                    </div>
                </div>
            }
            <ActionContainer action={action} />
            {isMobile &&
                <div className={classes.buttonContainerMobile}>
                    <Button variant="text" color="secondary" onClick={goBack}>Cancel</Button>
                    <Button disabled={!isEditAllowed} variant="contained" color="secondary" onClick={save}>Save</Button>
                </div>
            }
        </Track>
    );
}

export default Action;