import {
    ADD_QUESTION,
    ADD_QUESTION_OPTION,
    ADD_SECTION,
    ADD_EDITABLE_SECTION,
    DELETE_QUESTION,
    DELETE_QUESTION_OPTION,
    DELETE_SECTION,
    INSERT_QUESTION,
    INSERT_SECTION,
    SET_QUESTION_OPTIONS,
    REORDER_QUESTION,
    REORDER_SECTION,
    SET_QUESTION_ALLOW_PHOTO_UPLOAD,
    SET_QUESTION_ATTACHMENT_MANDATORY,
    SET_QUESTION_ANSWER_TYPE,
    SET_QUESTION_MAX_VALUE,
    SET_QUESTION_MIN_VALUE,
    SET_QUESTION_OPTION_TEXT,
    SET_QUESTION_TEXT,
    SET_SECTION_TEXT,
    CLEAR_TEMPLATE,
    ADD_OUTCOME,
    DELETE_OUTCOME,
    SET_CHOICE_OUTCOME_OPTION_ID,
    SET_OUTCOME_LOGIC_OPERATOR,
    SET_NUMBER_OUTCOME_LOGIC_OPERATOR,
    SET_OUTCOME_ACTION_PRESET_ID,
    SET_NUMBER_OUTCOME_VALUE1,
    SET_NUMBER_OUTCOME_VALUE2,
    CLEAR_TEMPLATE_OUTCOMES,
    SET_TEMPLATE_APPLICATION_AREA,
    SET_QUESTION_MANDATORY,
    SET_QUESTION_HELP_INFO_REQUIRED,
    SET_QUESTION_HELP_INFO_TEXT,
    SET_QUESTION_OPTION_REQUIRES_ADDITIONAL_INFO,
    SET_OUTCOME_RATING,
    SET_IS_GLOBAL_TEMPLATE,
    SET_TEMPLATE_NAME,
    SET_TEMPLATE_FREQUENCY,
    SET_TEMPLATE_SECTOR,
    SET_TEMPLATE_SUBJECT,
    SET_INCIDENT_TYPE,
    SET_TEMPLATE_FREQUENCY_INTERVAL,
    SET_TEMPLATE_FREQUENCY_DAYS,
} from "../types";

export function setSectionText({ sectionId, text }) {
    return {
        type: SET_SECTION_TEXT,
        payload: {
            sectionId,
            text,
        },
    };
}

export function reorderSection({ startIndex, endIndex, isInvestigation }) {
    return {
        type: REORDER_SECTION,
        payload: {
            startIndex,
            endIndex,
            isInvestigation,
        },
    };
}

export function deleteSection({ sectionId, isInvestigation }) {
    return {
        type: DELETE_SECTION,
        payload: {
            sectionId,
            isInvestigation,
        },
    };
}

export function addSection({ isInvestigation }) {
    return {
        type: ADD_SECTION,
        payload: {
            isInvestigation,
        },
    };
}

export function addEditableSection({ section }) {
    return {
        type: ADD_EDITABLE_SECTION,
        payload: {
            section,
        },
    };
}

export function insertSection({ atIndex, isInvestigation }) {
    return {
        type: INSERT_SECTION,
        payload: {
            atIndex,
            isInvestigation,
        },
    };
}

export function addQuestion(sectionId) {
    return {
        type: ADD_QUESTION,
        payload: sectionId,
    };
}

export function insertQuestion({ sectionId, atIndex }) {
    return {
        type: INSERT_QUESTION,
        payload: {
            sectionId,
            atIndex,
        },
    };
}

export function deleteQuestion({ questionId, sectionId }) {
    return {
        type: DELETE_QUESTION,
        payload: {
            questionId,
            sectionId,
        },
    };
}

export function reorderQuestion({ sectionId, startIndex, endIndex }) {
    return {
        type: REORDER_QUESTION,
        payload: { sectionId, startIndex, endIndex },
    };
}

export function setQuestionText({ questionId, text }) {
    return {
        type: SET_QUESTION_TEXT,
        payload: {
            questionId,
            text,
        },
    };
}

export function setQuestionMinValue({ questionId, minValue }) {
    return {
        type: SET_QUESTION_MIN_VALUE,
        payload: {
            questionId,
            minValue,
        },
    };
}

export function setQuestionMaxValue({ questionId, maxValue }) {
    return {
        type: SET_QUESTION_MAX_VALUE,
        payload: {
            questionId,
            maxValue,
        },
    };
}

export function addQuestionOption(questionId) {
    return {
        type: ADD_QUESTION_OPTION,
        payload: questionId,
    };
}

export function deleteQuestionOption({ questionId, optionId }) {
    return {
        type: DELETE_QUESTION_OPTION,
        payload: { questionId, optionId },
    };
}

export function setQuestionOptionText({ optionId, text }) {
    return {
        type: SET_QUESTION_OPTION_TEXT,
        payload: {
            optionId,
            text,
        },
    };
}

export function setQuestionOptionRequiresAdditionalInfo({
    optionId,
    requiresAdditionalInfo,
}) {
    return {
        type: SET_QUESTION_OPTION_REQUIRES_ADDITIONAL_INFO,
        payload: {
            optionId,
            requiresAdditionalInfo,
        },
    };
}

export function setQuestionAnswerType({ questionId, answerType }) {
    return {
        type: SET_QUESTION_ANSWER_TYPE,
        payload: {
            questionId,
            answerType,
        },
    };
}

export function setQuestionOptions({ questionId, options }) {
    return {
        type: SET_QUESTION_OPTIONS,
        payload: { questionId, options },
    };
}

export function setQuestionAllowPhotoUpload({ questionId, allowPhotoUpload }) {
    return {
        type: SET_QUESTION_ALLOW_PHOTO_UPLOAD,
        payload: {
            questionId,
            allowPhotoUpload,
        },
    };
}

export function setQuestionAttachmentMandatory({ questionId, attachmentMandatory }) {
    return {
        type: SET_QUESTION_ATTACHMENT_MANDATORY,
        payload: {
            questionId,
            attachmentMandatory,
        },
    };
}

export function setQuestionIsMandatory({ questionId, isMandatory }) {
    return {
        type: SET_QUESTION_MANDATORY,
        payload: {
            questionId,
            isMandatory,
        },
    };
}

export function setQuestionIsHelpInfoRequired({ questionId, isHelpInfoRequired }) {
    return {
        type: SET_QUESTION_HELP_INFO_REQUIRED,
        payload: {
            questionId,
            isHelpInfoRequired,
        },
    };
}

export function setQuestionHelpInfoText({ questionId, helpInfoText }) {
    return {
        type: SET_QUESTION_HELP_INFO_TEXT,
        payload: {
            questionId,
            helpInfoText,
        },
    };
}

export function clearTemplate() {
    return {
        type: CLEAR_TEMPLATE,
    };
}

export function addOutcome({ questionId, answerType }) {
    return {
        type: ADD_OUTCOME,
        payload: { questionId, answerType },
    };
}

export function deleteOutcome({ outcomeId, questionId }) {
    return {
        type: DELETE_OUTCOME,
        payload: { questionId, outcomeId },
    };
}

export function setChoiceOutcomeOptionId({ outcomeId, optionId }) {
    return {
        type: SET_CHOICE_OUTCOME_OPTION_ID,
        payload: { outcomeId, optionId },
    };
}

export function setOutcomeLogicOperator({ outcomeId, logicOperator }) {
    return {
        type: SET_OUTCOME_LOGIC_OPERATOR,
        payload: { outcomeId, logicOperator },
    };
}

export function setOutcomeRating({ outcomeId, rating }) {
    return {
        type: SET_OUTCOME_RATING,
        payload: { outcomeId, rating },
    };
}

export function setNumberOutcomeLogicOperator({ outcomeId, logicOperator }) {
    return {
        type: SET_NUMBER_OUTCOME_LOGIC_OPERATOR,
        payload: { outcomeId, logicOperator },
    };
}

export function setOutcomeActionPresetId({ outcomeId, actionPresetId }) {
    return {
        type: SET_OUTCOME_ACTION_PRESET_ID,
        payload: { outcomeId, actionPresetId },
    };
}

export function setNumberOutcomeValue1({ outcomeId, value1 }) {
    return {
        type: SET_NUMBER_OUTCOME_VALUE1,
        payload: { outcomeId, value1 },
    };
}

export function setNumberOutcomeValue2({ outcomeId, value2 }) {
    return {
        type: SET_NUMBER_OUTCOME_VALUE2,
        payload: { outcomeId, value2 },
    };
}

export function clearTemplateOutcomes() {
    return {
        type: CLEAR_TEMPLATE_OUTCOMES,
    };
}

export function setTemplateApplicationArea(applicationArea) {
    return {
        type: SET_TEMPLATE_APPLICATION_AREA,
        payload: applicationArea,
    };
}
export function setTemplateName({ text, hasUpdated }) {
    return {
        type: SET_TEMPLATE_NAME,
        payload: {
            text,
            hasUpdated
        }
    };
}
export function setTemplateFrequency({ freq, hasUpdated }) {
    return {
        type: SET_TEMPLATE_FREQUENCY,
        payload: {
            freq,
            hasUpdated
        }
    };
}
export function setTemplateSubjectId({ subjectId, hasUpdated }) {
    return {
        type: SET_TEMPLATE_SUBJECT,
        payload: {
            subjectId,
            hasUpdated
        }
    };
}
export function setTemplateSectorId({ sectorId, hasUpdated }) {
    return {
        type: SET_TEMPLATE_SECTOR,
        payload: {
            sectorId,
            hasUpdated
        }
    };
}
export function setIncidentType({ incidentType, hasUpdated }) {
    return {
        type: SET_INCIDENT_TYPE,
        payload: {
            incidentType,
            hasUpdated
        }
    };
}
export function setIsGlobalTemplate({ isGlobalTemplate }) {
    return {
        type: SET_IS_GLOBAL_TEMPLATE,
        payload: { isGlobalTemplate }
    };
}

export function setTemplateFrequencyInterval({ frequencyInterval, hasUpdated }) {
    return {
        type: SET_TEMPLATE_FREQUENCY_INTERVAL,
        payload: {
            frequencyInterval,
            hasUpdated
        }
    };
}

export function setTemplateFrequencyDays({ frequencyDays, hasUpdated }) {
    return {
        type: SET_TEMPLATE_FREQUENCY_DAYS,
        payload: {
            frequencyDays,
            hasUpdated
        }
    };
}
