export const tablePage = {
    ACTIONS_TABLE: "actionsTable",
    INCIDENTS_TABLE: "incidentsTable",
    COMPLIANCE_ACTIONS_TABLE: "complianceActionsTable",
    MONITORING_ACTIONS_TABLE: "monitoringActionsTable",
    INCIDENTS_ACTIONS_TABLE: "incidentsActionsTable",
    RISK_ASSESSMENTS_ACTIONS_TABLE: "riskAssessmentsActionsTable",
    AUDIT_ACTIONS_TABLE: "auditActionsTable",
    COMPLIANCE_FORMS_TABLE: "complianceFormsTable",
    MONITORING_FORMS_TABLE: "monitoringFormsTable",
    RISK_ASSESSMENTS_TABLE: "riskAssessmentsTable",
    RISK_ASSESSMENTS_GROUPED_BY_RISK_TABLE: "riskAssessmentsGroupedByRiskTable",
    COMPLIANCE_ADMIN_TEMPLATES_TABLE: "complianceAdminTemplatesTable",
    MONITORING_ADMIN_TEMPLATES_TABLE: "monitoringAdminTemplatesTable",
    INCIDENTS_ADMIN_TEMPLATES_TABLE: "incidentsAdminTemplatesTable",
    RISK_ASSESSMENTS_ADMIN_TEMPLATES_TABLE: "riskAssessmentsAdminTemplatesTable",
    CASES_TABLE: "casesTable",
    CONTACT_CASES_TABLE: "contactCasesTable",
    AUDIT_INSIGHTS_TABLE: "auditInsightsTable",
    COMPLIANCE_INSIGHTS_TABLE: "complianceInsightsTable",
    MONITORING_INSIGHTS_TABLE: "monitoringInsightsTable",
    FORMS_TABLE: "formsTable",
    INCIDENTS_FORMS_TABLE: "incidentsFormsTable",
};
