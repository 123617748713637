import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import auditService from "../../services/auditService";

const useSiteAudits = ({ externalIds, auditType }) =>
  useQuery(
    [queryKeys.siteAudits, externalIds?.join(","), auditType],
    () => auditService.fetchAuditsForSite(externalIds, auditType),
    {
      enabled: !!externalIds?.length,
    }
  );

export default useSiteAudits;
