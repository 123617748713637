import axios from "../../utils/axios";
import { buildQueryStringList, addQueryStringList } from "../../utils/stringUtils";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const fetchTemplateFormsForSites = ({
    siteExternalIds,
    pageSize,
    pageNum,
    moduleArea,
    sortOrder,
    orderByColumn,
    fromDate,
    toDate,
    showHistoric,
    assignees,
    filteredText,
}) => {
    let queryString = buildQueryStringList("siteExternalIds", siteExternalIds);
    let queryStringAssignees = addQueryStringList("assignees", assignees);

    if (queryStringAssignees !== null) queryString += queryStringAssignees;
    if (fromDate !== null) queryString += `&fromDate=${fromDate}`;
    if (toDate !== null) queryString += `&toDate=${toDate}`;
    if (sortOrder !== null) queryString += `&sortOrder=${sortOrder}`;
    if (orderByColumn !== null) queryString += `&orderByColumn=${orderByColumn}`;
    if (showHistoric !== null) queryString += `&showHistoric=${showHistoric}`;
    if (filteredText) queryString += `&filteredText=${filteredText}`;

    return axios.get(
        `${apiUrl}/templateform/ApplicationArea/${moduleArea}/sites/${queryString}&pageNum=${pageNum}&pageSize=${pageSize}`
    );
}

const addNewTemplateForm = (addedTemplates) =>
    axios.post(`${apiUrl}/templateform`, addedTemplates);

const getTemplateForm = (templateFormId) =>
    axios.get(`${apiUrl}/templateform/${templateFormId}`);

const updateTemplateForm = (templateUpdate) =>
    axios.post(`${apiUrl}/templateform/update`, templateUpdate);

const deleteTemplateForm = (templateUpdate) =>
    axios.post(`${apiUrl}/templateform/delete/${templateUpdate.templateFormId}/${templateUpdate.removeAssociatedActions}`);

const archiveTemplateForm = (templateUpdate) =>
    axios.post(`${apiUrl}/templateform/archive/${templateUpdate.templateFormId}/${templateUpdate.isActive}`);

const downloadTemplateFormsForSite = async ({
    siteExternalIds,
    moduleArea,
    sortOrder,
    orderByColumn,
    fromDate,
    toDate,
    assignees,
    filteredText,
    showHistoric,
}) => {
    let queryString = buildQueryStringList("siteExternalIds", siteExternalIds);
    let queryStringAssignees = addQueryStringList("assignees", assignees);

    if (queryStringAssignees !== null) queryString += queryStringAssignees;
    if (fromDate !== null) queryString += `&fromDate=${fromDate}`;
    if (toDate !== null) queryString += `&toDate=${toDate}`;
    if (sortOrder !== null) queryString += `&sortOrder=${sortOrder}`;
    if (orderByColumn !== null) queryString += `&orderByColumn=${orderByColumn}`;
    if (showHistoric !== null) queryString += `&showHistoric=${showHistoric}`;
    if (filteredText) queryString += `&filteredText=${filteredText}`;

    return axios.get(
        `${apiUrl}/templateform/ApplicationArea/${moduleArea}/sites/download/${queryString}`
    );
}

const fetchTemplateFormInsights = (siteExternalIds, moduleArea, fromDate, toDate, formIds) => {
    let queryString = buildQueryStringList("siteExternalIds", siteExternalIds);
    let queryStringForms = addQueryStringList("formIds", formIds);

    if (queryStringForms !== null) queryString += queryStringForms;
    if (fromDate !== null) queryString += `&fromDate=${fromDate}`;
    if (toDate !== null) queryString += `&toDate=${toDate}`;

    return axios.get(`${apiUrl}/templateform/ApplicationArea/${moduleArea}/insights/${queryString}`);
}

const addFormComment = (commentToAdd) =>
    axios.post(`${apiUrl}/TemplateForm/Comment/Add`, commentToAdd);

const fetchDashboardTemplateFormsForSites = ({
    siteExternalIds,
    pageSize,
    pageNum,
    moduleArea,
    sortOrder,
    orderByColumn,
    fromDate,
    toDate,
    assignees,
    filteredText,
}) => {
    let queryString = buildQueryStringList("siteExternalIds", siteExternalIds);
    let queryStringAssignees = addQueryStringList("assignees", assignees);

    if (queryStringAssignees !== null) queryString += queryStringAssignees;
    if (moduleArea !== null) queryString += `&applicationArea=${moduleArea}`;
    if (fromDate !== null) queryString += `&fromDate=${fromDate}`;
    if (toDate !== null) queryString += `&toDate=${toDate}`;
    if (sortOrder !== null) queryString += `&sortOrder=${sortOrder}`;
    if (orderByColumn !== null) queryString += `&orderByColumn=${orderByColumn}`;
    if (filteredText) queryString += `&filteredText=${filteredText}`;

    return axios.get(
        `${apiUrl}/templateform/dashboard/sites/${queryString}&pageNum=${pageNum}&pageSize=${pageSize}`
    );
}

const templateFormService = {
    fetchTemplateFormsForSites,
    addNewTemplateForm,
    updateTemplateForm,
    deleteTemplateForm,
    getTemplateForm,
    downloadTemplateFormsForSite,
    fetchTemplateFormInsights,
    addFormComment,
    fetchDashboardTemplateFormsForSites,
    archiveTemplateForm,
};

export default templateFormService;
