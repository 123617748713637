import axios from "../utils/axios";
import stringUtils from "../utils/stringUtils";

const accountEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/Account`;

const fetchAccountsDetailsForUser = () =>
  axios.get(`${accountEndpointUrl}/GetForUser`);

const fetchAccountsForConsultant = () =>
  axios.get(`${accountEndpointUrl}/GetForConsultant`);

const fetchAccountsForApp = ({ activeAppId, accountExternalIds }) => {
  const queryString = stringUtils.buildQueryStringList(
    "externalIds",
    accountExternalIds
  );
  return axios.get(`${accountEndpointUrl}/App/${activeAppId}/${queryString}`);
};

const fetchAccountsForAdmin = ({ accountExternalIds }) => {
  const queryString = stringUtils.buildQueryStringList(
    "externalIds",
    accountExternalIds
  );

  return axios.get(`${accountEndpointUrl}/Admin${queryString}`);
};

const accountService = {
  fetchAccountsDetailsForUser,
  fetchAccountsForConsultant,
  fetchAccountsForApp,
  fetchAccountsForAdmin,
};

export default accountService;
