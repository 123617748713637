export const riskLevel = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
};

export const riskLevelDropdownValues = [
  {
    value: riskLevel.LOW,
    label: "Low",
  },
  {
    value: riskLevel.MEDIUM,
    label: "Medium",
  },
  {
    value: riskLevel.HIGH,
    label: "High",
  },
];
