import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { blueGrey, red } from "@material-ui/core/colors";
import useAddActionPreset from "../../hooks/mutations/useAddActionPreset";
import CustomButton from "../../../components/CustomButton";
import { useSelector } from "react-redux";
import moduleAreaConstants from "../../constants/moduleAreaConstants";
import {useSnackbar} from "notistack";
import {useAuth} from "../../../contexts/authContext";

const useStyles = makeStyles((theme) => ({
  outcomeItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  outcomeOptionsContainer: {
    gap: theme.spacing(2),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: "flex",
    alignItems: "center",
    backgroundColor: blueGrey[50],
    flex: 1,
    borderRadius: "4px",
    flexWrap: "wrap",
  },
  deleteOutcomeButton: {
    color: red[500],
    marginLeft: theme.spacing(1),
  },
}));

function OutcomeItemWrapper({
  subjectId,
  children,
  onDelete,
  onActionPresetAdded,
}) {
  const classes = useStyles();
  const { applicationArea } = useSelector((state) => state.templateBuilder);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [presetText, setPresetText] = useState("");
  const [isPresetTextError, setIsPresetTextError] = useState(false);
  const addActionPreset = useAddActionPreset();

  const { isWorknestUser } = useAuth();
  const { selectedSite, selectedAccount } = useSelector(
      (state) => state.account
  );

  const siteExternalId = isWorknestUser ? null : selectedSite?.externalId ?? selectedAccount.account.externalId

  const { enqueueSnackbar } = useSnackbar();

  const onDialogClose = () => {
    setPresetText("");
    setIsPresetTextError(false);
    setDialogOpen(false);
  };

  const onDialogOpen = () => {
    setDialogOpen(true);
  };

  const onSavePreset = async () => {
    if (!presetText) {
      setIsPresetTextError(true);
      return;
    }

    addActionPreset.mutate(
      {
        subjectId:
          applicationArea === moduleAreaConstants.INCIDENTS
            ? undefined
            : subjectId,
        description: presetText,
        siteExternalId: siteExternalId,
      },
      {
        onSuccess: (data) => {
          onActionPresetAdded(data.id);
          onDialogClose();
        },
        onError: (error) => {
          console.error(error);
          enqueueSnackbar(error?.message || "There was an error adding the action preset",
              {
                variant: "error"
              });
        },
      }
    );
  };

  const onPresetTextChanged = (e) => {
    setPresetText(e.target.value);
  };

  return (
    <>
      <li className={classes.outcomeItem}>
        <div className={classes.outcomeOptionsContainer}>
          <>
            {children}
            <Button onClick={onDialogOpen} color="secondary">
              Add custom action preset
            </Button>
          </>
        </div>
        <IconButton
          aria-label="delete outcome"
          onClick={onDelete}
          className={classes.deleteOutcomeButton}
          size="small"
        >
          <Clear />
        </IconButton>
      </li>
      <Dialog open={dialogOpen} onClose={onDialogClose}>
        <DialogTitle>Add new action preset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add the action text below. This text will be the title of any
            actions created.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="action-text"
            label="Action text"
            variant="outlined"
            value={presetText}
            onChange={onPresetTextChanged}
            fullWidth
            error={isPresetTextError}
            helperText={isPresetTextError ? "Please provide action text" : null}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <CustomButton
            isLoading={addActionPreset.isLoading}
            onClick={onSavePreset}
            color="primary"
            variant="contained"
          >
            Add action preset
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OutcomeItemWrapper;
