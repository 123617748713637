import {useQuery} from "react-query";
import queryKeys from "../../constants/queryKeys";
import contactService from "../../services/contactService";

const useContactsForApp = ({
  activeAppId,
  rootExternalId,
}) =>
  useQuery(
    [
      queryKeys.appContacts,
      activeAppId,
      rootExternalId,
    ],
    () =>
      contactService.fetchContactsForApp({
        activeAppId,
        rootExternalId,
      }),
    {
      enabled:
        !!activeAppId &&
        !!rootExternalId,
      staleTime: Infinity,
    }
  );

export default useContactsForApp;