import {
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    Typography,
    makeStyles,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { validEmailRegex } from "../../utils/RegexUtils";
import { getAcknowledgementStatusDescription } from "../../../constants/acknowledgementStatusConstants";

const useStyles = makeStyles((theme) => ({
    subTitle: {
        padding: theme.spacing(3),
    },
    title: {
        padding: theme.spacing(0),
        margin: theme.spacing(0),
    },
    dialogContent: {
        display: "flex",
        gap: theme.spacing(2),
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    error: {
        "&.MuiChip-outlined": {
            border: "1px solid red",
            color: "red",
        },
    },
    sharedWith: {
        marginTop: theme.spacing(2),
    },
    emails: {
        display: "flex",
        alignItems: "center",
    },
    chip: {
        marginLeft: "auto",
        fontSize: "0.75rem",
        marginRight: theme.spacing(2),
    },
    emailList: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

export default function ShareRiskAssessmentDialog({
    emailList,
    emailInput,
    handleEmailListChange,
    shareDialogOpen,
    closeShareDialog,
    riskAssessmentHistoryAcknowledgementRecipients,
    shareRiskAssessmentHistoryInvite,
    setEmailList,
    dialogMenuOpen,
    dialogAnchorEl,
    handleDialogMenuClick,
    handleDialogMenuClose,
}) {
    const classes = useStyles();

    return (
        <Dialog open={shareDialogOpen} onClose={closeShareDialog} fullWidth maxWidth="sm">
            <div className={classes.subTitle}>
                <DialogTitle className={classes.title}>Share Risk Assessment</DialogTitle>
                <DialogContentText className={classes.title}>Invite colleagues to review this risk assessment.</DialogContentText>
            </div>
            <DialogContent>
                <div className={classes.dialogContent}>
                    <Autocomplete
                        fullWidth
                        multiple
                        id="tags-filled"
                        options={[]}
                        onChange={(e, value) => setEmailList([...value])}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    classes={
                                        (!option.match(validEmailRegex) || riskAssessmentHistoryAcknowledgementRecipients?.some((x) => x.email === option)) && {
                                            outlined: classes.error,
                                        }
                                    }
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} value={emailInput} variant="outlined" label="Email" onChange={(e) => handleEmailListChange(e)} />
                        )}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            emailList.some((x) => !x.match(validEmailRegex)) ||
                            emailList.some((x) => riskAssessmentHistoryAcknowledgementRecipients?.some((y) => y.email === x)) ||
                            emailList.length === 0
                        }
                        onClick={() => shareRiskAssessmentHistoryInvite(emailList, false)}
                    >
                        Invite
                    </Button>
                </div>
                <Divider />
                <Typography className={classes.sharedWith} variant="body2">
                    Shared with:
                </Typography>
                {(!riskAssessmentHistoryAcknowledgementRecipients || riskAssessmentHistoryAcknowledgementRecipients?.length === 0) && (
                    <Typography color="textSecondary" variant="caption">
                        Enter email addresses above to share the risk assessment with others.
                    </Typography>
                )}
                <div className={classes.emailList}>
                    {riskAssessmentHistoryAcknowledgementRecipients?.map((x) => (
                        <div className={classes.emails}>
                            <Typography key={x.id} variant="caption">
                                {x.email}
                            </Typography>
                            <Chip className={classes.chip} label={getAcknowledgementStatusDescription(x.status)} variant="outlined" size="small" />
                            <IconButton onClick={(e) => handleDialogMenuClick(e, x.id)}>
                                <MoreVert />
                            </IconButton>
                            <Menu keepMounted open={dialogMenuOpen === x.id} onClose={handleDialogMenuClose} anchorEl={dialogAnchorEl}>
                                <MenuItem
                                    onClick={() => {
                                        shareRiskAssessmentHistoryInvite([x.email], true);
                                    }}
                                >
                                    Resend Invite
                                </MenuItem>
                            </Menu>
                        </div>
                    ))}
                </div>
            </DialogContent>
        </Dialog>
    );
}
