import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { Speed } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import PageBase from "../../components/PageBase";
import SiteSelect from "../../components/SiteSelect";
import PageTitle from "../components/PageTitle";
import ImproveScoreInfo from "../components/safetynestscore/ImproveScoreInfo";
import ModuleScore from "../components/safetynestscore/ModuleScore";
import OverallScore from "../components/safetynestscore/OverallScore";
import SafetyNestScoreTable from "../components/safetynestscore/SafetyNestScoreTable";
import ScoreCalculationInfo from "../components/safetynestscore/ScoreCalculationInfo";
import useOverallSafetyNestScore from "../hooks/queries/useOverallSafetyNestScore";
import MostImpactfulActions from "../components/safetynestscore/MostImpactfulActions";
import { Alert } from "@material-ui/lab";
import React from "react";
import ScoreApplicability from "../components/safetynestscore/ScoreApplicability";

const useStyles = makeStyles((theme) => ({
  titleGrid: {
    marginBottom: theme.spacing(2),
  },
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  applicabilityContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
}));

function SafetyNestScore() {
  const classes = useStyles();
  const { Track } = useTracking({
    page: "SafetyNest Score",
  });

  const { selectedSite, activeAppId, appSiteIds } = useSelector(
    (state) => state.account
  );

  const overallScore = useOverallSafetyNestScore({
    externalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
  });

  const pageContent = () => {
    if (overallScore.isLoading)
      return (
        <div className={classes.spinnerContainer}>
          <CircularProgress color="primary" />
        </div>
      );
    else if (overallScore.isError)
      return <Alert severity="error">Error loading SafetyNest Score</Alert>;
    else if (!overallScore.data)
      return (
        <div className={classes.applicabilityContainer}>
          <ScoreApplicability />
        </div>
      );
    else
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <OverallScore scoreDetails={overallScore.data} />
            <ScoreCalculationInfo />
            <SafetyNestScoreTable />
            <ImproveScoreInfo />
          </Grid>
          <Grid item xs={12} md={4}>
            {overallScore.data?.moduleScores?.map((moduleScore) => (
              <ModuleScore
                key={moduleScore.applicationArea}
                moduleScore={moduleScore}
              />
            ))}
            <MostImpactfulActions />
          </Grid>
        </Grid>
      );
  };

  return (
    <PageBase>
      <Track>     
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className={classes.titleGrid}
        >
          <Grid item xs={12} md={6}>
            <PageTitle icon={<Speed />} title="SafetyNest Score" />
          </Grid>
          <Grid item xs={12} md={3}>
            <SiteSelect size="small" />
          </Grid>    
        </Grid>
        {pageContent()}
      </Track>
    </PageBase>
  );
}

export default SafetyNestScore;
