import { makeStyles, TextField } from "@material-ui/core";
import UserInitials from "./UserInitials";
import CustomButton from "../../../components/CustomButton";
import { useAuth } from "../../../contexts/authContext";

const useStyles = makeStyles((theme) => ({
    newComment: {
        display: 'flex',
        columnGap: theme.spacing(2),
    },
    textField: {
        backgroundColor: '#fff',
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#5c45c7',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#5c45c7',
        },
        '& .MuiInputBase-root': {
            fontSize: '0.875rem',
        },
        '& .MuiFormLabel-root': {
            fontSize: '0.875rem',
        },
    },
    addComment: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        rowGap: theme.spacing(1),
    },
    button: {
        alignSelf: 'flex-start',
        width: 'max-content',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

const AddComment = ({ newComment, setNewComment, isLoading, saveComment }) => {   

    const classes = useStyles();   

    const { user } = useAuth();    

    return (
        <div className={classes.newComment}>
            <UserInitials firstName={user?.details?.firstName} lastName={user?.details?.lastName} />
            <div className={classes.addComment}>
                <TextField
                    className={classes.textField}
                    multiline
                    onChange={(e) => setNewComment(e.target.value)}
                    value={newComment}
                    minRows={4}
                    variant='outlined'
                    fullWidth
                    label='Add a comment...'
                />
                <CustomButton
                    className={classes.button}
                    variant='outlined'
                    color='secondary'
                    disabled={newComment.trim().length === 0}
                    onClick={saveComment}
                    isLoading={isLoading}
                >
                    Add Comment
                </CustomButton>
            </div>
        </div>
    )
}           

export default AddComment;