import axios from "../../utils/axios";

const converterOptions = {
    margin_right: "15mm",
    margin_left: "15mm",
    margin_top: "15mm",
    margin_bottom: "15mm"
};

export const generatePdfFromHtml = async html => {
    let response = await axios.post(
        "https://ckeditor-pdf.greenwave-257981d3.westeurope.azurecontainerapps.io/v1/convert",
        {
            html: html,
            options: converterOptions,
            css: `
                    table {
                        font-size: 0.8em;
                    }
                    td:not(:first-child) { text-align: center;}
                    `
        },
        { responseType: "blob" }
    );

    let href = window.URL.createObjectURL(response);

    return href;
}
