import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import riskAssessmentHistoryService from "../../services/riskAssessmentHistoryService";

const useRiskAssessmentHistory = (riskAssessmentHistoryId) =>
    useQuery(
        [
            queryKeys.riskAssessmentHistory,
            riskAssessmentHistoryId,
        ],
        () => riskAssessmentHistoryService.fetchRiskAssessmentHistory(riskAssessmentHistoryId),
        {
            enabled: !!riskAssessmentHistoryId,
        }
    );

export default useRiskAssessmentHistory;