const appIds = {
  casenest: process.env.REACT_APP_CASENEST_APP_ID,
  safetynest: process.env.REACT_APP_SAFETYNEST_APP_ID,
  safetyNestDri: process.env.REACT_APP_SAFETYNEST_DRI_APP_ID,
  learningNestSafetyMedia:
    process.env.REACT_APP_LEARNINGNEST_SAFETYMEDIA_APP_ID,
  learningNestDeltaNet: process.env.REACT_APP_LEARNINGNEST_DELTANET_APP_ID,
  storageNest: process.env.REACT_APP_STORAGENEST_APP_ID,
  resourceNestHS: process.env.REACT_APP_RESOURCENEST_HS_APP_ID,
  resourceNestLAW: process.env.REACT_APP_RESOURCENEST_LAW_APP_ID,
  lei: process.env.REACT_APP_LEI_APP_ID,
  insightsNewsAndResources:
    process.env.REACT_APP_INSIGHTS_NEWS_AND_RESOURCES_APP_ID,
  peopleNest: process.env.REACT_APP_PEOPLENEST_APP_ID,
  myWorkNest: process.env.REACT_APP_MYWORKNEST_APP_ID,
};

export default appIds;
