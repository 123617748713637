import React, { useEffect } from "react";
import { Divider, TextField, Typography, makeStyles, Button} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ResponsiveSelect from "../../../components/ui/ResponsiveSelect";
import { useCreateActionContext } from "../../contexts/CreateActionContext"
import { useSelector } from "react-redux";
import stringUtils from "../../../utils/stringUtils";
import { positiveNumberOrNull } from "../../../utils/numberUtils";
import { ratingList } from "../../constants/actionRatingConstants";
import useAccountAppSites from "../../../hooks/queries/useAccountAppSites";
import useContactsForAppAndSite from "../../../hooks/useContactsForAppAndSite";
import { useAuth } from "../../../contexts/authContext";
import { nanoid } from "nanoid"
import { moduleAreaDropdownValues } from "../../constants/moduleAreaConstants";

const useStyles = makeStyles((theme) => ({
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}))

const formDateFormat = 'dd/MM/yyyy';
const MAX_DESCRIPTION = 250;

export default function ActionForm({ isModule, module }) {
    const classes = useStyles();

    const {
        actionDetails,
        setDescription,
        setDueDate,
        setRating,
        setSiteExternalId,
        setModule,
        setAssignedEmployeeId,
        setRequiresCreateValidation,
        requiresCreateValidation,
        addAzureFile,
    } = useCreateActionContext()

    const { user } = useAuth();

    const nonAuditModules = user?.details?.userAccessPermission
        ? moduleAreaDropdownValues.filter((value) => user?.details?.userAccessPermission[value.userAccessPermission] === true && value.value !== 4)
        : [];

    const { selectedAccount, activeAppId } = useSelector(
        (state) => state.account
    );

    const { data: accountSitesData } = useAccountAppSites({
        childExternalIds: [
            ...(selectedAccount?.childExternalIds || []),
            selectedAccount?.account.externalId,
        ],
        activeAppId,
    });

    useEffect(() => {
        if (accountSitesData && accountSitesData.length === 1) {setSiteExternalId(accountSitesData[0].externalId);}
        if (isModule) {setModule(module);}
    }, []);
    
    let contacts = [];
    contacts = useContactsForAppAndSite({ selectedSiteId: actionDetails?.siteExternalId });

    const showDescriptionError = () => {
        return ((!actionDetails?.description || stringUtils.isEmpty(actionDetails?.description))) || (requiresCreateValidation && (!actionDetails?.description || stringUtils.isEmpty(actionDetails?.description)))
    }

    const showDatePickerError = () => {
        return (!actionDetails?.dueDate) || (requiresCreateValidation && !actionDetails?.dueDate)
    }

    const showRatingError = () => {
        return ((actionDetails?.rating === null || actionDetails?.rating === undefined)) || (requiresCreateValidation && (actionDetails?.rating === null || actionDetails?.rating === undefined))
    }

    const showModuleError = () => {
        return ((actionDetails?.applicationArea === null || actionDetails?.applicationArea === undefined)) || (requiresCreateValidation && (actionDetails?.applicationArea === null || actionDetails?.applicationArea === undefined))
    }

    const showSiteError = () => {
        return ((actionDetails?.siteExternalId === null || actionDetails?.siteExternalId === undefined)) || (requiresCreateValidation && (actionDetails?.siteExternalId === null || actionDetails?.siteExternalId === undefined))
    }

    const showAssigneeError = () => {
        return ((actionDetails?.assignedEmployeeId === null || actionDetails?.assignedEmployeeId === undefined)) || (requiresCreateValidation && (actionDetails?.assignedEmployeeId === null || actionDetails?.assignedEmployeeId === undefined))
    }

    const onSiteSelect = (e) => {
        setSiteExternalId(e.target.value);
    };

    const handleChangeFile = async (e) => {
        const files = e.target.files

        if (files && files.length) {
            const newFile = {
                syncId: nanoid(5),
                file: files[0],
            };
            addAzureFile(newFile)
        }
    };

    return (
        <div className={classes.form}>
            <TextField
                required
                fullWidth
                inputProps={{ maxLength: MAX_DESCRIPTION }}
                onInvalid={() => setRequiresCreateValidation(true)}
                helperText='Please enter a description (250 characters max)'
                error={showDescriptionError()}
                FormHelperTextProps={{ hidden: !showDescriptionError() }}
                multiline
                minRows={3}
                value={actionDetails?.description}
                variant="outlined"
                label="Description"
                onChange={(e) => setDescription(e.target.value)}
            />
            {(accountSitesData && accountSitesData.length !== 1) && (
                <ResponsiveSelect
                    required
                    fullWidth
                    onInvalid={() => setRequiresCreateValidation(true)}
                    error={showSiteError()}
                    FormHelperTextProps={{ hidden: !showSiteError() }}
                    value={actionDetails?.siteExternalId}
                    variant="outlined"
                    label="Site"
                    options={accountSitesData && accountSitesData.map((s) => ({
                        value: s.externalId,
                        label: s.name,
                    }))}
                    optionValueKey="value"
                    optionLabelKey="label"
                    onChange={onSiteSelect}
                />
            )}
            <ResponsiveSelect
                required
                fullWidth
                disabled={!actionDetails?.siteExternalId} 
                onInvalid={() => setRequiresCreateValidation(true)}
                error={showAssigneeError()}
                FormHelperTextProps={{ hidden: !showAssigneeError() }}
                value={actionDetails?.assignedEmployeeId}
                variant="outlined"
                label="Responsible"
                options={contacts?.data && Object.values(contacts.data).sort((a, b) => (a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0)).map((user) => ({
                    value: user.externalId,
                    label: `${user.firstName} ${user.lastName}`,
                }))}
                optionValueKey="value"
                optionLabelKey="label"
                onChange={(e) => setAssignedEmployeeId(e.target.value)}
            />
            <div className={classes.flex}>
                <KeyboardDatePicker
                    required
                    fullWidth
                    onInvalid={() => setRequiresCreateValidation(true)}
                    error={showDatePickerError()}
                    FormHelperTextProps={{ hidden: !showDatePickerError() }}
                    value={actionDetails?.dueDate}
                    inputVariant="outlined"
                    format={formDateFormat}
                    label="Due Date"
                    onChange={(date) => setDueDate(date)}
                />
                <ResponsiveSelect
                    required
                    fullWidth
                    onInvalid={() => setRequiresCreateValidation(true)}
                    error={showRatingError()}
                    FormHelperTextProps={{ hidden: !showRatingError() }}
                    value={actionDetails?.rating}
                    variant="outlined"
                    label="Priority"
                    options={ratingList}
                    optionValueKey="value"
                    optionLabelKey="label"
                    onChange={(e) => setRating(e.target.value)}
                />
            </div>
            {!isModule && (
                <ResponsiveSelect
                    required 
                    fullWidth
                    onInvalid={() => setRequiresCreateValidation(true)}
                    error={showModuleError()}
                    FormHelperTextProps={{ hidden: !showModuleError() }}
                    value={actionDetails?.applicationArea}
                    variant="outlined"
                    label="Module Area"
                    options={nonAuditModules}
                    optionValueKey="value"
                    optionLabelKey="label"
                    onChange={(e) => setModule(positiveNumberOrNull(e.target.value))}
                />
            )}
            <Divider />
            <Typography variant="h6">
                Attachment
            </Typography>
            <div>
                {actionDetails.azureFile ? (
                    <div className={classes.flex}>
                        <Button
                            variant="text"
                            color="secondary"
                        >
                            {actionDetails.azureFile.name}
                        </Button>
                    </div>
                ) : (
                    <Button
                        variant="text"
                        color="secondary"
                        component="label"
                    >
                        Select file to upload
                        <input
                            type="file"
                            hidden
                            onChange={handleChangeFile}
                        />
                    </Button>
                )}
            </div>
        </div>
    )
}