import React from "react";
import {
    MenuItem,
    TextField,
    Chip,
    makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    statusWorknest: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    statusCustom: {
        backgroundColor: orange[400],
        color: theme.palette.getContrastText(orange[400]),
    },
    chipWithPadding: {
        marginRight: theme.spacing(1),
    },
}));

function ResponsiveSelect({
    options = [],
    optionValueKey = null,
    optionLabelKey = null,
    optionLabelFunction = null,
    ...rest
}) {
    const classes = useStyles();

    const displayOption = (selectOption, index) => {
        const value = optionValueKey ? selectOption[optionValueKey] : selectOption;
        const label = optionLabelKey
            ? selectOption[optionLabelKey]
            : optionLabelFunction
                ? optionLabelFunction(selectOption)
                : selectOption;

        const chipClass =
            selectOption.chipCaption === "Worknest"
                ? classes.statusWorknest
                : classes.statusCustom;

        return (
            <MenuItem key={index} value={value}>
                {selectOption.chipCaption && (
                    <Chip
                        className={clsx(classes.chipWithPadding, chipClass)}
                        size="small"
                        label={selectOption.chipCaption}
                    />
                )}
                {label}
            </MenuItem>
        );
    };

    return (
        <TextField
            select
            variant="outlined"
            {...rest}
        >
            {options.map((option, index) => displayOption(option, index))}
        </TextField>
    );
}

export default ResponsiveSelect;