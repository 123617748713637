import React from "react";
import {
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  numberOutcomeLogic,
  numberOutcomeLogicOptions,
} from "../../../constants/outcomeLogicConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  setNumberOutcomeValue1,
  setNumberOutcomeValue2,
  setNumberOutcomeLogicOperator,
} from "../../../redux/actions/templateBuilderActions";

const useStyles = makeStyles(() => ({
  outcomeText: {
    fontWeight: "500",
  },
  outcomeDropdown: {
    backgroundColor: "white",
  },
}));

function NumberOutcomeItem({
  outcomeId,
  actionPresets,
  isLoadingActionPresets,
  isActionPresetsError,
  onActionPresetChange,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { choiceOutcomes } = useSelector((state) => state.templateBuilder);

  const outcome = choiceOutcomes[outcomeId];

  const onLogicOperatorChange = (e) => {
    dispatch(
      setNumberOutcomeLogicOperator({ outcomeId, logicOperator: e.target.value })
    );
  };

  const onValue1Change = (e) => {
    dispatch(
      setNumberOutcomeValue1({
        outcomeId,
        value1: parseFloat(e.target.value),
      })
    );
  };

  const onValue2Change = (e) => {
    dispatch(
      setNumberOutcomeValue2({
        outcomeId,
        value2: parseFloat(e.target.value),
      })
    );
  };

  const handleActionPresetChange = (e) => {
    onActionPresetChange(e.target.value);
  };

  return (
    <>
      <Typography className={classes.outcomeText}>If response is</Typography>
      <FormControl
        className={classes.outcomeDropdown}
        variant="outlined"
        size="small"
      >
        <Select
          SelectDisplayProps={{
            "data-testid": `number-logic-operator-${outcomeId}`,
          }}
          value={outcome.numberOutcome?.logicType}
          onChange={onLogicOperatorChange}
        >
          {numberOutcomeLogicOptions.map((choice) => (
            <MenuItem key={choice.value} value={choice.value}>
              {choice.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        className={classes.outcomeDropdown}
        variant="outlined"
        size="small"
        type="number"
        value={outcome.numberOutcome?.value1}
        onChange={onValue1Change}
        inputProps={{ "data-testid": `number-value1-input-${outcomeId}` }}
      />
      {outcome.numberOutcome?.logicType === numberOutcomeLogic.between && (
        <>
          <Typography className={classes.outcomeText}>and</Typography>
          <TextField
            className={classes.outcomeDropdown}
            variant="outlined"
            size="small"
            type="number"
            value={outcome.numberOutcome?.value2}
            onChange={onValue2Change}
            inputProps={{ "data-testid": `number-value2-input-${outcomeId}` }}
          />
        </>
      )}
      <Typography className={classes.outcomeText}>then</Typography>
      <FormControl
        className={classes.outcomeDropdown}
        variant="outlined"
        size="small"
        disabled={isLoadingActionPresets}
        error={isActionPresetsError}
      >
        <Select
          SelectDisplayProps={{
            "data-testid": `number-action-preset-${outcomeId}`,
          }}
          value={outcome.actionPresetId}
          onChange={handleActionPresetChange}
        >
          {actionPresets &&
            actionPresets.map((preset) => (
              <MenuItem key={preset.id} value={preset.id}>
                {preset.description}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
}

export default NumberOutcomeItem;
