import { makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useState } from "react";
import useAddFormComment from "../../../hooks/mutations/useAddFormComment";
import CommentPanel from "../../common/CommentPanel";
import AddComment from "../../common/AddComment";
import { useQueryClient } from 'react-query';
import queryKeys from "../../../../constants/queryKeys";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        rowGap: theme.spacing(4),
        flexDirection: 'column',
    },
}));

const FormComments = ({ templateFormId, comments }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [newComment, setNewComment] = useState('');

    const addComment = useAddFormComment(); 

    const queryClient = useQueryClient();


    const saveComment = () => {
        const commentToAdd = {
            comment: newComment,
            templateFormId
        };

        addComment.mutate(commentToAdd, {
            onSuccess: (data) => {
                setNewComment('')
                queryClient.invalidateQueries([
                    queryKeys.templateForm,
                    templateFormId
                ]) 
            },
            onError: (error) => {
                console.error(error);
                enqueueSnackbar("Could not add comment to form.", { variant: "error" });
            },
        })
    };    

    return (
        <div className={classes.container}>
            <AddComment newComment={newComment} setNewComment={setNewComment} isLoading={addComment.isLoading} saveComment={saveComment} />
            {comments?.map((comment, index) => (
                <div key={index}>
                    <CommentPanel comment={comment} />
                </div>
            ))}
        </div>
    )
}

export default FormComments;