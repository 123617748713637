import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateFormHistoryService from "../../services/templateFormHistoryService";

const useTemplateFormHistory = (templateFormHistoryId) =>
  useQuery(
    [queryKeys.templateFormHistory, templateFormHistoryId],
    () => templateFormHistoryService.getById(templateFormHistoryId),
    { enabled: !!templateFormHistoryId }
  );

export default useTemplateFormHistory;
