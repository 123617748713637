import React, { useState } from "react";
import Avatar from "../Avatar";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";

import {
  Email as EmailIcon,
  PhoneAndroid as MobileIcon,
  Phone as PhoneIcon,
} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  textCenter: {
    textAlign: "center",
  },
}));

const CxCard = ({ cxPeople }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const ViewPerson = (person) => (
    <Box
      key={person.name}
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={5}
      mb={2}
      ml={2}
      mr={2}
      className={classes.textCenter}
    >
      <Box>
        <Avatar alt={person.name} size="lg" src={person.photo} />
      </Box>
      <Box>
        <Typography variant="subtitle2">{person.name}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2">{person.position}</Typography>
      </Box>
    </Box>
  );

  const ViewPersonDetails = (cxPerson) => (
    <Grid key={cxPerson.name} container spacing={12}>
      <Grid item xs={12} md={9}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            As your Client Experience Co-ordinator I will provide you with
            on-going support for the term of your contract with WorkNest and:
          </DialogContentText>
          <ul>
            <li>set up your details on our myWorkNest portal</li>
            <li>
              ensure that relevant employees within your organisation have the
              correct access to our myWorkNest portal
            </li>
            <li>
              provide a schedule of on-boarding sessions to our myWorkNest
              portal to ensure you and your employees can enjoy a seamless
              experience
            </li>
            <li>
              be main point of contact for anything relating to the delivery of
              the WorkNest service you have taken
            </li>
            <li>
              keep in touch on a regular basis to ensure you are getting the
              very best from our service and myWorkNest portal
            </li>
          </ul>
          <DialogContentText id="alert-dialog-footer">
            Please feel free to contact me if you have any questions regarding
            the service. If you are unable to reach me on my direct line please
            call 01244 687 603 and one of my colleagues will be able to assist
            you.
          </DialogContentText>
        </DialogContent>
      </Grid>
      <Grid item xs={12} md={3}>
        <DialogContent>
          <Box
            key={cxPerson.name}
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={5}
            mb={2}
            ml={2}
            mr={2}
            className={classes.textCenter}
          >
            <Box>
              <Avatar alt={cxPerson.name} size="lg" src={cxPerson.photo} />
            </Box>
            <Typography className={classes.textCenter} variant="h5" mb={6}>
              {cxPerson.name}
            </Typography>
            <Link href={"mailto:" + cxPerson.email} underline="hover">
              <EmailIcon fontSize="small" />
              {cxPerson.email}
            </Link>
            {cxPerson.phone && (
              <Link href={"tel:" + cxPerson.phone} underline="hover">
                <PhoneIcon fontSize="small" />
                {cxPerson.phone}
              </Link>
            )}
            {cxPerson.mobile && (
              <Link href={"tel:" + cxPerson.mobile} underline="hover">
                <MobileIcon fontSize="small" />
                {cxPerson.mobile}
              </Link>
            )}
          </Box>
        </DialogContent>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {cxPeople.length > 0 && (
        <CardContent>
          <Box display="flex">
            <Box flexGrow={1} alignItems="center">
              <Typography className={classes.textCenter} variant="h5" mb={6}>
                {cxPeople.length === 1
                  ? "Your " + cxPeople[0].role
                  : "Your " + cxPeople[0].role + " Team"}
              </Typography>

              <Box
                display="flex"
                justifyContent="space-around"
                flexWrap="wrap"
                m={-2}
              >
                {cxPeople.map((a) => ViewPerson(a))}
              </Box>
              <Box
                display="flex"
                justifyContent="space-around"
                flexWrap="wrap"
                m={2}
              >
                <Button
                  variant="outlined"
                  className={classes.textCenter}
                  onClick={() => setOpen(true)}
                  color="primary"
                >
                  More Information
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      )}

      <Grid>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {cxPeople.length === 1
              ? "Your " + cxPeople[0].role
              : "Your " + cxPeople[0].role + " Team"}
          </DialogTitle>
          <DialogActions>
            {cxPeople.map((a) => ViewPersonDetails(a))}
          </DialogActions>
          <Box
            display="flex"
            justifyContent="space-around"
            flexWrap="wrap"
            m={2}
          >
            <Button
              variant="outlined"
              className={classes.textCenter}
              onClick={handleCloseDialog}
              color="primary"
            >
              Close
            </Button>
          </Box>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
};

export default CxCard;
