import React from "react";
import {
  alpha,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  CircularProgress,
  Typography,
  InputAdornment,
  Checkbox,
  TextField,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { differenceInDays, format, isAfter, isEqual, isValid } from "date-fns";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { blue, green, orange, purple } from "@material-ui/core/colors";
import { baseRoute } from "../routes";
import ActionsHeadlines from "./compliance/ActionsHeadlines";
import ActionRatingChip from "./ActionRatingChip";
import {
  moduleAreaDropdownValues,
  moduleAreaNames,
  moduleUrls,
} from "../constants/moduleAreaConstants";
import ResponsiveSelect from "../../components/ui/ResponsiveSelect";
import { actionStatusNames } from "../constants/actionStatusConstants";
import { ratingList } from "../constants/actionRatingConstants";
import {
  Beenhere as BeenhereIcon,
  Create as CreateIcon,
  Favorite as FavoriteIcon,
  People as PeopleIcon,
  Visibility as VisibilityIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  ErrorOutline as ErrorOutlineIcon,
  WifiTethering as OnTheSpotIcon,
  CheckBoxOutlineBlank,
  CheckBox,
  Search as SearchIcon,
  Clear as ClearIcon,
} from "@material-ui/icons";
import actionService from "../services/actionService";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useAuth } from "../../contexts/authContext";
import useContactsForAppAndSite from "../../hooks/useContactsForAppAndSite";
import { sortOrderName } from "../constants/sortOrder";
import EnhancedTableHead from "../../components/table/EnhancedTableHead";
import { Autocomplete } from "@material-ui/lab";

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  moduleChip: {
    color: "white",
    borderRadius: "4px",
    "&.module-0": {
      backgroundColor: green[400],
    },
    "&.module-1": {
      backgroundColor: purple[400],
    },
    "&.module-2": {
      backgroundColor: orange[400],
    },
    "&.module-4": {
      backgroundColor: blue[400],
    },
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    },
  },
  completeTableRow: {
    background: alpha(theme.palette.success.main, 0.08),
  },
  moduleCellContainer: {
    display: "flex",
    alignItems: "center",
  },
  moduleCellText: {
    marginLeft: theme.spacing(1),
  },
  checkboxContainer: {
    display: "flex",
      alignItems: "center",
  },
  overdueCell: {
    color: theme.palette.error.dark,
  },
  overdueCellText: {
    color: theme.palette.error.dark,
    marginLeft: theme.spacing(1),
  },
  checkbox: {
      padding: theme.spacing(1),
      marginRight: theme.spacing(1),
  },
  dueCellContainer: {
    display: "flex",
    alignItems: "start",
  },
  dueCellCompleteIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  toolbar: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  notSetText: {
    color: theme.palette.error.dark,
    },
    iconBtn: {
        padding: theme.spacing(1),
    },
    assignedString: {
        maxWidth: "86%",
        },
    searchTextField: {
        width: "100%",
        '& .clearIcon': {
            visibility: 'hidden',
        },
        '&:hover .clearIcon': {
            visibility: 'visible',
        },
    },
    clearIcon: {
        color: theme.palette.grey[700], 
    },
    endAdornment: {
        paddingRight: theme.spacing(1),
    },
    tooltip: {
        margin: theme.spacing(1),
        backgroundColor: 'white',
        border: '1px solid #000', 
        color: theme.palette.common.black,
        fontSize: '0.9em',
        borderRadius: 0,
        padding: theme.spacing(1),
    },
}));

const formatDueDate = (date, isComplete) => {
  const today = new Date();
  today.setHours(23, 59, 59, 999);
  const actionDueDate = new Date(date);
  actionDueDate.setHours(23, 59, 59, 999);

  if (isComplete) {
    return "Complete";
  } else if (isEqual(today, actionDueDate)) {
    return "Today";
  } else if (isAfter(today, actionDueDate)) {
    return `${differenceInDays(today, actionDueDate)} days ago`;
  } else {
    return format(actionDueDate, "dd/MM/yyyy");
  }
};

const defaultDropdownOption = { value: null, label: "All" };
const notSetDropdownOption = { value: "NotSet", label: "Not Set" };

const allHeadCells = [
    {
        id: "description",
        label: "Description",
        isSortable: true,
    },
    {
        id: "priority",
        label: "Priority",
        isSortable: true,
    },
    {
        id: "site",
        label: "Site",
        isSortable: true,
    },
    {
        id: "module",
        label: "Module",
        isSortable: true,
        active: false,
    },
    {
        id: "responsible",
        label: "Responsible",
        isSortable: true,
    },
    {
        id: "dueDate",
        label: "Due",
        isSortable: true,
    },
];

function ActionsTable({
  isModule = false,
  actions = [],
  count = 0,
  onPageChange,
  onRowsChange,
  onModuleChange,
  onFromDateChange,
  onToDateChange,
  onOrderByChange,
  onSortByChange,
  page,
  rows,
  module = -1,
  fromDate,
  toDate,
  orderBy,
  sortBy,
  summaryData,
  rating,
  onRatingChange,
  assignees = [],
  onAssigneesChange,
  filteredAssignees = [],
  handleCurrentAssigneeChange,
  onFilteredTextChange,
  filteredText,
  onFilteredTextSearch,
  clearFilteredText,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { sites } = useSelector((state) => state.account);
    const { user } = useAuth();
 
  const contacts = useContactsForAppAndSite({ selectedSiteId: null });

  const isFromDateValid = isValid(new Date(fromDate));
  const isToDateValid = isValid(new Date(toDate));

  const [searchRequested, setSeachRequested] = React.useState(false);

  const filteredModuleAreaDropdownValues = user?.details?.userAccessPermission
    ? moduleAreaDropdownValues.filter(
        (value) =>
          user?.details?.userAccessPermission[value.userAccessPermission] ===
          true
      )
        : defaultDropdownOption;

    const filteredAsigneeOptions = contacts?.data && assignees
        ? (assignees.includes(null) || assignees.includes('') ? [notSetDropdownOption] : [])
            .concat(Object.values(contacts.data)
                .filter(user => assignees.includes(user.externalId) && user.firstName)
                .sort((a, b) => a.firstName.localeCompare(b.firstName))
                .map(user => ({ value: user.externalId, label: `${user.firstName} ${user.lastName}` }))
            ) : [];

  const handleRowClick = async (action) => {
    if (!action.viewedByUser) await actionService.setViewedByUser(action.id);

    let actionPath = `${baseRoute}/${moduleUrls[action.moduleType]}/action/${
      action.id
    }`;

    if (action.isDri) actionPath += `/true`;

    history.push(actionPath);
    };
    
 const headCells = isModule
        ? allHeadCells.filter(item => item.id !== 'module')
        : allHeadCells;

    const Module = ({ name, type }) => {
    switch (name) {
      case moduleAreaNames.COMPLIANCE:
        return (
          <div className={classes.moduleCellContainer}>
            <BeenhereIcon />
            <Typography variant="body2" className={classes.moduleCellText}>
               {name} 
            </Typography>
          </div>
        );
      case moduleAreaNames.MONITORING:
        return (
          <div className={classes.moduleCellContainer}>
            <VisibilityIcon />
            <Typography variant="body2" className={classes.moduleCellText}>
                    {name} 
            </Typography>
          </div>
        );
      case moduleAreaNames.INCIDENTS:
        return (
          <div className={classes.moduleCellContainer}>
            <FavoriteIcon />
            <Typography variant="body2" className={classes.moduleCellText}>
              {name}
            </Typography>
          </div>
        );
      case moduleAreaNames.RISKASSESSMENTS:
        return (
          <div className={classes.moduleCellContainer}>
            <CreateIcon />
            <Typography variant="body2" className={classes.moduleCellText}>
              {name}
            </Typography>
          </div>
        );
      case moduleAreaNames.AUDIT:
        return (
          <div className={classes.moduleCellContainer}>
            <PeopleIcon />
            <Typography variant="body2" className={classes.moduleCellText}>
              {name} - {type}
            </Typography>
          </div>
        );
      default:
        return <></>;
    }
  };

  const Due = (status, dueDate) => {
    switch (status) {
      case actionStatusNames.OVERDUE:
        return (
          <div className={classes.dueCellContainer}>
            <ErrorIcon className={classes.overdueCell} fontSize="small" />
            <Typography variant="body2" className={classes.overdueCellText}>
              {formatDueDate(dueDate)}
            </Typography>
          </div>
        );
      case actionStatusNames.DUE:
        return (
          <div className={classes.dueCellContainer}>
            <ErrorOutlineIcon color="primary" fontSize="small" />
            <Typography variant="body2" className={classes.moduleCellText}>
              {formatDueDate(dueDate)}
            </Typography>
          </div>
        );
      case actionStatusNames.COMPLETED:
        return (
          <div className={classes.dueCellContainer}>
            <CheckCircleIcon
              className={classes.dueCellCompleteIcon}
              fontSize="small"
            />
            <Typography variant="body2" color="primary">
              {formatDueDate(dueDate, true)}
            </Typography>
          </div>
        );
      default:
        return (
          <div className={classes.dueCellContainer}>
            <Typography variant="body2">{formatDueDate(dueDate)}</Typography>
          </div>
        );
    }
  };

  const UserName = ({ userId }) => {
    if (contacts.isLoading) return <CircularProgress size={12} />;

    if (!userId)
      return (
        <Typography variant="body2" className={classes.notSetText}>
          Not Set
        </Typography>
      );

    if (contacts.isError || !contacts?.data[userId])
      return <Typography variant="body2">Unknown</Typography>;

    const fullname = `${contacts?.data[userId]?.firstName} ${contacts?.data[userId]?.lastName}`;
    return <Typography variant="body2">{fullname}</Typography>;
    };

    const onSortClick = (event, sortColumn) => {
        const isAsc = sortBy === sortColumn && orderBy === sortOrderName.ASC;
        onOrderByChange(isAsc ? sortOrderName.DESC : sortOrderName.ASC);
        onSortByChange(sortColumn);
    };

    function handleSearchChange(e) {
        setSeachRequested(false);
        onFilteredTextChange(e.target.value)
    }

    const handleSearchClick = () => {
        setSeachRequested(true);
        onFilteredTextSearch();
    };

    const showFilteredTextError = () => {
        return (filteredText && filteredText.length > 0 && filteredText.length < 4 && searchRequested)
    }

  return (
      <>
      <Grid container spacing={1}>
        {!isModule && (
          <Grid item xs={12} md={6} xl={2}>
            <ResponsiveSelect
              options={[defaultDropdownOption].concat(
                filteredModuleAreaDropdownValues
              )}
              optionLabelFunction={(x) => x.label}
              optionValueKey={"value"}
              margin="dense"
              disabled={!isFromDateValid || !isToDateValid}
              fullWidth
              label="Module"
              placeholder="Module"
              onChange={onModuleChange}
              value={module || ""}
            />
          </Grid>
              )}
          <Grid item xs={12} md={6} xl={!isModule ? 4 : 6} className={classes.toolbar}>
                  <Autocomplete
                      multiple
                      value={filteredAssignees}
                      onChange={handleCurrentAssigneeChange}
                      onBlur={onAssigneesChange}
                      options={filteredAsigneeOptions}
                      disableCloseOnSelect
                      clearOnBlur
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) => {
                          return option.label === value.label;
                      }}
                      renderOption={(option, { selected }) => (
                          <React.Fragment>
                              <Checkbox
                                  className={classes.checkbox}
                                  icon={uncheckedIcon}
                                  checkedIcon={checkedIcon}
                                  checked={selected}
                              />
                              {option.label}
                          </React.Fragment>
                      )}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              className={classes.textField}
                              variant="outlined"
                              size="small"
                              label="Responsible Person(s)"
                              fullWidth
                          />
                      )}
                      renderTags={selected => {
                          console.log("selected = ", selected);
                          let renderTagsValue = selected
                              .map(function (elem) {
                                  return elem.label;
                              })
                              .join(", ");
                          return (
                              <Typography className={classes.assignedString }
                                  noWrap={true}
                                  color="textPrimary"
                              >
                                  {renderTagsValue}
                              </Typography>
                          );
                      }}
                  />
        </Grid>
        <Grid item xs={12} md={6} xl={2} className={classes.toolbar}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              size="small"
              inputVariant="outlined"
              label="From date"
              disabled={!isToDateValid}
              format="dd/MM/yyyy"
              value={fromDate}
              onChange={(date) => onFromDateChange(date)}
              maxDate={toDate}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={6} xl={2} className={classes.toolbar}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              size="small"
              inputVariant="outlined"
              label="To date"
              format="dd/MM/yyyy"
              disabled={!isFromDateValid}
              value={toDate}
              onChange={(date) => onToDateChange(date)}
              minDate={fromDate}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12} md={6} xl={2}>
          <ResponsiveSelect
            options={[defaultDropdownOption].concat(ratingList)}
            optionLabelFunction={(x) => x.label}
            optionValueKey={"value"}
            margin="dense"
            fullWidth
            label="Priority"
            placeholder="Priority"
            onChange={onRatingChange}
            value={rating || ""}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          xl={true}
          className={classes.checkboxContainer}
        >
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
                  <TextField
                      className={classes.searchTextField}
                      label="Search Description"
                      variant="outlined"
                      size="small"
                      value={filteredText || ""}
                      onChange={handleSearchChange}
                      helperText='Please enter at least 4 character to search on'
                      error={showFilteredTextError()}
                      FormHelperTextProps={{ hidden: !showFilteredTextError() }}
                      InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                  {filteredText && (
                                      <Tooltip title="Clear" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                        <IconButton className={classes.iconBtn}>
                                          <ClearIcon className={`clearIcon ${classes.clearIcon}`}
                                                   onClick={clearFilteredText}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                  )}
                                      <Tooltip title="Search" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                      <IconButton className={classes.iconBtn} onClick={handleSearchClick}>
                                              <SearchIcon />
                                        </IconButton>
                                      </Tooltip>
                             </InputAdornment>
                          ),
                          className: classes.endAdornment 
                      }}
                  />
      </Grid>
      </Grid>
      {summaryData && <ActionsHeadlines data={summaryData} />}
      <TableContainer>
        <Table size="small">
            <EnhancedTableHead
                headCells={headCells}
                sortColumn={sortBy}
                sortDirection={orderBy}
                onRequestSort={onSortClick}
            />
          <TableBody>
            {actions?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6}>No actions.</TableCell>
              </TableRow>
            ) : (
              actions

                .map((row) => (
                    <TableRow
                    key={`${row.id}-${row.isDri}`}
                    onClick={() => handleRowClick(row)}
                    className={
                      row.statusName === actionStatusNames.COMPLETED
                        ? clsx(classes.tableRow, classes.completeTableRow)
                        : classes.tableRow
                    }
                  >
                    <TableCell component="th" scope="row">
                        {row.isAdHoc ?
                            <div className={classes.moduleCellContainer}>
                                <OnTheSpotIcon />
                                <Typography variant="body2" className={classes.moduleCellText}>
                                    {row.description} 
                                </Typography>
                            </div> : row.description}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <ActionRatingChip rating={row?.rating} />
                    </TableCell>
                    <TableCell>
                      {sites[row.siteExternalId]?.name ?? "Unknown"}
                    </TableCell>

                    {!isModule && (
                      <TableCell>
                                <Module name={row.moduleName} type={row.auditType} />
                      </TableCell>
                    )}
                    <TableCell>
                      <UserName userId={row.assignedEmployeeExternalId} />
                    </TableCell>
                    <TableCell>{Due(row.statusName, row.dueDate)}</TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rows}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsChange}
        />
      </TableContainer>
    </>
  );
}

export default ActionsTable;
