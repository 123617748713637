import { useMutation } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import { historicActionService } from "../../services/historicActionService";

const { client } = reactQueryUtils

const useCreateHistoricAction = () => 
    useMutation(
        (historicAction) => historicActionService.create(historicAction, historicAction.historicAuditId),
        {
            onMutate: async ({ historicAuditId, ...historicAction }) => {
                const queryKey = [queryKeys.historicAuditActions, historicAuditId];

                await client.cancelQueries(queryKey);

                const previousHistoricAction = client.getQueryData(queryKey);

                client.setQueryData(queryKey, (old) => 
                    old ? [...old, historicAction] : [historicAction]
                )

                return { previousHistoricAction, queryKey };
            },
            onError: (_err, _newDocument, context) => {
                if (!context) return;
                client.setQueryData(context.queryKey, context.previousHistoricAction);
            },
            onSettled: () => {
                client.invalidateQueries(queryKeys.historicAuditActions);
            }
        }
    )

export default useCreateHistoricAction