import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateService from "../../services/templateService";

const useModuleTemplates = ({
  moduleArea,
  pageNum,
  rowsPerPage,
  accountId,
  siteExternalIds,
  sortOrder,
  orderByColumn,
  templateType,
  sectorId,
  subjectId,
  frequency,
}) =>
  useQuery(
    [
      queryKeys.templates,
      moduleArea,
      { page: pageNum, rows: rowsPerPage },
      accountId,
      siteExternalIds?.join(","),
      sortOrder,
      orderByColumn, 
      templateType,
      sectorId,
      subjectId,
      frequency,
    ],
    () =>
      templateService.fetchTemplatesForModule({
        moduleArea,
        pageNum,
        rowsPerPage,
        accountId,
        siteExternalIds,
        sortOrder,
        orderByColumn, 
        templateType,
        sectorId,
        subjectId,
        frequency,
      }),
    {
      enabled:
        moduleArea !== null &&
        moduleArea !== undefined &&
        pageNum !== null &&
        pageNum !== undefined,
    }
  );

export default useModuleTemplates;
