import { Button, Container, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CardBase from "../../../components/cards/CardBase";
import PepperStepper from "../../components/common/PepperStepper";
import { useRiskAssessmentDetails } from "../../contexts/RiskAssessmentContext";
import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { HazardSelector } from "../../components/riskassessments/HazardSelector";
import CustomHazardDialog from "../../components/riskassessments/dialogs/CustomHazardDialog";
import PeopleSelector from "../../components/riskassessments/PeopleSelector";
import CustomPeopleDialog from "../../components/riskassessments/dialogs/CustomPeopleDialog";
import HazardEvaluation from "../../components/riskassessments/HazardEvaluation";
import HazardActions from "../../components/riskassessments/HazardActions";
import useSaveRiskAssessmentHistory from "../../hooks/mutations/useSaveRiskAssessmentHistory";
import { riskAssessmentSteps } from "../../constants/riskAssessmentStepConstants";
import { baseRoute } from "../../routes";
import moduleAreas, { moduleUrls } from "../../constants/moduleAreaConstants";
import usePeople from '../../hooks/queries/usePeople'

const useStyles = makeStyles((theme) => ({
    bottomGap: {
        marginBottom: theme.spacing(2),
    },
    buttons: {
        width: "100%",
        display: "flex",
        gap: theme.spacing(2),
        justifyContent: "end",
    },
}));

export default function ReviewRiskAssessment() {
    const classes = useStyles();
    const history = useHistory();
    const allPeople = usePeople()
    const { enqueueSnackbar } = useSnackbar();
    const {
        steps,
        setStepOne,
        riskAssessment,
        activeStep,
        goToNextStep,
        goToPreviousStep,
        isLastStep,
        isFirstStep,
        setTemplateHazardIds,
        setHazardDialogOpen,
        setTemplatePeopleIds,
        setPeopleDialogOpen,
        riskAssessmentDetails,
        partialReset,
        reset,
        riskAssessmentHistories,
        isEditMode,
        setActiveStep,
    } = useRiskAssessmentDetails();

    const saveRiskAssessmentHistory = useSaveRiskAssessmentHistory()

    useEffect(() => {
        setTemplateHazardIds(
            riskAssessment?.data?.hazards?.map((x) => x.hazardId) ?? []
        );
        setTemplatePeopleIds(
            riskAssessment?.data?.people?.map(
                (x) => x.peopleId
            ) ?? []
        );
    }, [riskAssessment?.data]);

    useEffect(() => {
        if (isEditMode) setActiveStep(2)
        else
            setStepOne()
    }, [])

    const allHazards = { ...riskAssessmentDetails?.hazards ?? {}, ...riskAssessmentDetails?.customHazards ?? {} }

    function checkHazardsScores() {
        const hazards = riskAssessmentDetails?.hazards || {};
        const customHazards = riskAssessmentDetails?.customHazards || {};

        const hazardsWithScoreZero = Object.keys(hazards).some(x =>
            hazards[x].severityScorePreControl === 0 ||
            hazards[x].severityScorePostControl === 0 ||
            hazards[x].likelihoodScorePreControl === 0 ||
            hazards[x].likelihoodScorePostControl === 0
        );

        const customHazardsWithScoreZero = Object.keys(customHazards).some(x =>
            customHazards[x].severityScorePreControl === 0 ||
            customHazards[x].severityScorePostControl === 0 ||
            customHazards[x].likelihoodScorePreControl === 0 ||
            customHazards[x].likelihoodScorePostControl === 0
        );

        return hazardsWithScoreZero || customHazardsWithScoreZero;
    }

    function onNext(e) {
        e.preventDefault();
        if (isLastStep || isEditMode) {
            if (isEditMode && checkHazardsScores()) {
                enqueueSnackbar("You must score each hazard.", {
                    variant: "error",
                });
                return;
            }

            const processControls = (hazardId, controlsKey) => {
                const controls = allHazards[hazardId]?.[controlsKey];
                if (!controls) return 0;
            
                return controls.reduce((prev, curr) => {
                    if (!curr?.inPlace) {
                        if (!curr?.control?.hasOwnProperty('controlAction') || !curr?.control?.controlAction[0]?.hasOwnProperty('actionPresetDescription') || curr?.control?.controlAction[0]?.actionPresetDescription.length === 0) {
                            if (!curr?.riskAssessmentControl?.riskAssessmentControlAction.hasOwnProperty('actionPresetDescription') || curr?.riskAssessmentControl?.riskAssessmentControlAction.actionPresetDescription.length === 0) {
                                return prev + 1;
                            }
                        }
                    }
                    return prev;
                }, 0);
            };
            
            const controlsWithoutActions = Object.keys(allHazards).map(hazardId => processControls(hazardId, 'hazardControls'));
            const customControlsWithoutActions = Object.keys(allHazards).map(hazardId => processControls(hazardId, 'customHazardControls'));
            
            const filteredCCResults = controlsWithoutActions.filter(count => count > 0);
            const filteredResults = customControlsWithoutActions.filter(count => count > 0);
            
            if (!isEditMode && (filteredCCResults.length > 0 || filteredResults.length > 0)) {
                enqueueSnackbar("You must save custom actions", {
                    variant: "error",
                });
                return;
            }
            const riskAssessmentToSave = {
                siteExternalId: riskAssessmentDetails.siteExternalId,
                riskAssessmentId: riskAssessmentDetails.id,
                RiskAssessmentHistoryId: isEditMode ? riskAssessment?.data?.riskAssessmentHistory?.id : null,
                peoples: Object.keys(riskAssessmentDetails.people).map(people => ({
                    id: riskAssessmentDetails.people[people].id,
                    name: allPeople?.data?.filter(p => p.id === riskAssessmentDetails.people[people].id)[0]?.name || null
                })),
                riskAssessmentHazards: Object.keys(riskAssessmentDetails.hazards)
                .filter(hazardKey => !isNaN(hazardKey))
                .map(hazardKey => {
                        let hazardToCheck = riskAssessmentDetails.hazards[hazardKey];
                        hazardToCheck.hazardControls = hazardToCheck.hazardControls.map(control => {
                        if (control.riskAssessmentControl) {
                            control.riskAssessmentControl.riskAssessmentControlAction.controlId = control.control?.id ?? undefined;
                        }
                        if (isNaN(control.control?.id)) {
                            delete control.control.id;
                        }
                        if (isNaN(control.id)) {
                            delete control.id;
                        }
                        if (isNaN(control.riskAssessmentControl?.riskAssessmentControlAction?.actionPresetId) && control.riskAssessmentControl?.riskAssessmentControlAction) {
                            delete control.riskAssessmentControl.riskAssessmentControlAction.actionPresetId;
                        }
                        if (isNaN(control.riskAssessmentControl?.riskAssessmentControlAction?.controlId) && control.riskAssessmentControl?.riskAssessmentControlAction) {
                            delete control.riskAssessmentControl.riskAssessmentControlAction.controlId;
                        }                        
                        if (control.inPlace) {
                            delete control.riskAssessmentControl;
                        }
                        return control;
                    });
                    return hazardToCheck;
                }),
                customPeoples: Object.keys(riskAssessmentDetails.customPeople).map(people => {
                    let customPeople = riskAssessmentDetails.customPeople[people]
                    delete customPeople.id
                    return customPeople
                }),
                customRiskAssessmentHazards: Object.keys(riskAssessmentDetails.customHazards).map(customHazard => {
                    let customHazardToCheck = riskAssessmentDetails.customHazards[customHazard]
                    customHazardToCheck.hazardControls?.map(control => {
                        if (isNaN(control.id)) { delete control.id }
                        if (isNaN(control.control.id)) { delete control.control.id }
                    })
                    if (isNaN(customHazardToCheck.id)) { delete customHazardToCheck.id; }
                    return customHazardToCheck
                }),
                updatedActions: riskAssessmentDetails.incompleteActions?.filter(x => x.hasUpdated).map(action => {
                    return {
                        id: action.id,
                        dueDate: action.dueDate,
                        assignedEmployeeExternalId: action.employeeId,
                        rating: action.rating,
                    }
                })
            }
            saveRiskAssessmentHistory.mutate(riskAssessmentToSave, {
                onSuccess: () => {
                    riskAssessmentHistories.refetch()
                    reset();
                    history.push(`${baseRoute}/${moduleUrls[moduleAreas.RISKASSESSMENTS]}`);
                    enqueueSnackbar("Risk assessment updated", { variant: "success" });
                },
                onError: (error) => {
                    console.error(error);
                    enqueueSnackbar("Could not update risk assessment.", { variant: "error" });
                },
            })
        }

        if (
            activeStep === riskAssessmentSteps.HAZARDS &&
            riskAssessmentDetails?.hazardIds?.length === 0
        ) {
            enqueueSnackbar("You must select at least one hazard", {
                variant: "error",
            });
            return;
        }

        if (
            activeStep === riskAssessmentSteps.PEOPLE &&
            riskAssessmentDetails?.peopleIds?.length === 0
        ) {
            enqueueSnackbar("You must select at least one person", {
                variant: "error",
            });
            return;
        }

        if (activeStep === riskAssessmentSteps.EVALUATE && (Object.keys(riskAssessmentDetails?.hazards)?.some(hazard => riskAssessmentDetails?.hazards[hazard]?.hazardControls?.some(control => control?.inPlace === undefined)) ||
            Object.keys(riskAssessmentDetails?.customHazards)?.some(hazard => riskAssessmentDetails?.customHazards[hazard]?.hazardControls?.some(control => control?.inPlace === undefined)))) {
            enqueueSnackbar('You must select whether a control is in place or not.', {
                variant: 'error'
            })
            return
        }

        if (isEditMode && checkHazardsScores()) {
            enqueueSnackbar("You must score each hazard.", {
                variant: "error",
            });
            return;
        }

        if (activeStep === riskAssessmentSteps.EVALUATE && checkHazardsScores()) {
            enqueueSnackbar('You must score each hazard.', {
                variant: 'error'
            })
            return
        }

        if (!isLastStep && !isEditMode) goToNextStep();
    }

    function onBack() {
        if (isFirstStep || isEditMode) {
            partialReset()
            history.goBack();
            return;
        }

        goToPreviousStep();
    }

    const manageCustomHazard = () => {
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={() => setHazardDialogOpen(true)}
            >
                Manage Custom Hazards
            </Button>
        );
    };

    const manageCustomPeople = () => {
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={() => setPeopleDialogOpen(true)}
            >
                Manage Custom People
            </Button>
        );
    };

    return (
        <Container>
            <div className={classes.bottomGap}>
                <CardBase>
                    <PepperStepper activeStep={activeStep} steps={steps} />
                </CardBase>
            </div>
            <CardBase
                title={steps[activeStep]?.title ?? null}
                description={steps[activeStep]?.subtitle ?? null}
                rightComponent={
                    activeStep === riskAssessmentSteps.HAZARDS ?
                        manageCustomHazard() :
                        activeStep === riskAssessmentSteps.PEOPLE ?
                            manageCustomPeople() :
                            null
                }
            >
                <form onSubmit={onNext}>
                    {activeStep === riskAssessmentSteps.HAZARDS && (
                        <>
                            <CustomHazardDialog />
                            <HazardSelector />
                        </>
                    )}
                    {activeStep === riskAssessmentSteps.PEOPLE && (
                        <>
                            <CustomPeopleDialog />
                            <PeopleSelector />
                        </>
                    )}
                    {activeStep === riskAssessmentSteps.EVALUATE && (
                        <HazardEvaluation />
                    )}
                    {activeStep === riskAssessmentSteps.RECORD && (
                        <HazardActions />
                    )}
                    <div className={classes.buttons}>
                        <Button
                            variant="outlined"
                            color="primary"
                            type="button"
                            onClick={onBack}
                        >
                            {(isFirstStep || isEditMode) ? "Cancel" : "Back"}
                        </Button>
                        <Button variant="contained" color="primary" type="submit">
                            {(isLastStep || isEditMode) ? "Save" : "Next"}
                        </Button>
                    </div>
                </form>
            </CardBase>
        </Container>
    );
}
