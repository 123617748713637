import { useMutation } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import { historicActionService } from "../../services/historicActionService";

const { client } = reactQueryUtils

const useDeleteHistoricAction = () => 
    useMutation(
        (historicAction) => historicActionService.archive(historicAction.historicActionId),
        {
            onMutate: async ({ historicActionId, historicAuditId }) => {
                const queryKey = [queryKeys.historicAuditActions, historicAuditId];

                await client.cancelQueries(queryKey);

                const previousHistoricActions = client.getQueryData(queryKey);

                if (previousHistoricActions) {
                    client.setQueryData(queryKey, (old) => old.filter((historicAction) => historicAction.id !== historicActionId));
                }

                return { previousHistoricActions, queryKey };
            },
            onError: (err, variables, context) => {
                if (!context) return;
                client.setQueryData(context.queryKey, context.previousHistoricActions);
            },
            onSettled: (data, error, variables, context) => {
                client.invalidateQueries(queryKeys.historicAuditActions);
            },
        }
    )

export default useDeleteHistoricAction;