import TrustCentreArticle from "../TrustCentreArticle";
import { Link } from "react-router-dom";

const contentsNavItems = [
    {
        id: "introduction",
        title: "Introduction",
    },
    {
        id: "important-information",
        title: "Important Information and Who We Are",
    },
    {
        id: "data-we-collect",
        title: "The Data We Collect About You",
    },
    {
        id: "how-is-your-personal-data-collected",
        title: "How Is Your Personal Data Collected?",
    },
    {
        id: "how-we-use-your-personal-data",
        title: "How We Use Your Personal Data",
    },
    {
        id: "disclosures-of-your-personal-data",
        title: "Disclosures of Your Personal Data",
    },
    {
        id: "international-transfers",
        title: "International Transfers",
    },
    {
        id: "data-security",
        title: "Data Security",
    },
    {
        id: "data-retention",
        title: "Data Retention",
    },
    {
        id: "your-legal-rights",
        title: "Your Legal Rights",
    },
    {
        id: "glossary",
        title: "Glossary",
    },
];

function PrivacyPolicy() {
    return (
        <TrustCentreArticle title="Privacy Policy" lastUpdatedDate="2024-09-01" contentsNavItems={contentsNavItems}>
            <section id="introduction">
                <h3>Introduction</h3>
                <p>WorkNest is committed to ensuring that your privacy is protected.</p>
                <p>
                    Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it
                    will only be used in accordance with this privacy statement.
                </p>
                <p>
                    WorkNest may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are
                    happy with any changes. This policy is effective from <strong>September 2024</strong>.
                </p>
                <p>
                    This privacy policy is provided in a layered format so you can click through to the specific areas set out below. Please also use the
                    Glossary to understand the meaning of some of the terms used in this privacy policy.
                </p>
            </section>
            <section id="important-information">
                <h3>Important Information and Who We Are</h3>
                <h4>Purpose of This Privacy Policy</h4>
                <p>This privacy policy sets out how WorkNest uses and protects any information that you give WorkNest when you use this website.</p>
                <p>
                    It is important that you read this privacy policy together with any other privacy notice we may provide on specific occasions when we are
                    collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy
                    supplements the other notices and is not intended to override them.
                </p>
                <p>This website is not intended for children and we do not knowingly collect data relating to children.</p>
                <h4>Controller</h4>
                <p>
                    WorkNest is the controller and responsible for your personal data (collectively referred to as “WorkNest”, “we”, “us” or “our” in this
                    privacy policy) for this website.
                </p>
                <p>
                    We have appointed a data protection officer (DPO) who is responsible for overseeing questions in relation to this privacy notice. If you
                    have any questions about this privacy notice, including any requests to exercise your legal rights, please contact the DPO using the details
                    set out below.
                </p>
                <h4>Contact Details</h4>
                <p>Our contact details are:</p>
                <p>
                    <strong>Full name of legal entity: </strong>WorkNest Limited
                </p>
                <p>
                    <strong>Contact: </strong>Data Privacy Officer
                </p>
                <p>
                    <strong>Email address: </strong>
                    <a href="mailto:enquiries@worknest.com">enquiries@worknest.com</a>
                </p>
                <p>
                    <strong>Postal address: </strong>WorkNest, Woodhouse, Church Ln, Aldford, Chester CH3 6JD
                </p>
                <p>
                    You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data
                    protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so
                    please contact us in the first instance.
                </p>
                <h4>Your duty to inform us of changes</h4>
                <p>
                    It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes
                    during your relationship with us.
                </p>
                <h4>Third-party links</h4>
                <p>
                    This website may include links to third-party websites. Clicking on those links or enabling those connections may allow third parties to
                    collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you
                    leave our website, we encourage you to read the privacy notice of every website you visit.
                </p>
            </section>
            <section id="data-we-collect">
                <h3>The Data We Collect About You</h3>
                <p>
                    Personal data, or personal information, means any information about an individual from which that person can be identified. It does not
                    include data where the identity has been removed (anonymous data).
                </p>
                <p>We will collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:</p>
                <ul>
                    <li>
                        <strong>Identity Data</strong> includes first name, last name, date of birth and gender.
                    </li>
                    <li>
                        <strong>Contact Data</strong> includes email address and telephone numbers.
                    </li>
                    <li>
                        <strong>Financial Data</strong> includes payment details.
                    </li>
                    <li>
                        <strong>Transaction Data</strong> includes details of services we have provided to you.
                    </li>
                    <li>
                        <strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and version, time zone setting
                        and location, operating system and platform.
                    </li>
                    <li>
                        <strong>Profile Data</strong> includes your site preferences and downloads made from our site.
                    </li>
                    <li>
                        <strong>Usage Data</strong> includes information about how you use our website and services.
                    </li>
                    <li>
                        <strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing from us and your communication
                        preferences.
                    </li>
                </ul>
                <p>
                    We also collect, use and share <strong>Aggregated Data</strong> such as statistical or demographic data for any purpose. Aggregated Data may
                    be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your
                    identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However,
                    if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined
                    data as personal data which will be used in accordance with this privacy notice.
                </p>
                <p>
                    We do not collect any Special Categories of Personal Data about you via the website (this includes details about your race or ethnicity,
                    religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health
                    and genetic and biometric data). Nor do we collect any information about criminal convictions and offences via the website. If the services
                    we provide require us to receive this information, such requirement will be made clear to you and any such data we collect will be processed
                    in accordance with the terms outlined in the contract in place between us.
                </p>
                <h4>If you fail to provide personal data</h4>
                <p>
                    Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when
                    requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods
                    or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the
                    time.
                </p>
            </section>
            <section id="how-is-your-personal-data-collected">
                <h3>How Is Your Personal Data Collected?</h3>
                <p>We use different methods to collect data from and about you including through:</p>
                <ul>
                    <li>
                        <strong>Direct interactions.</strong> You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding
                        with us by post, phone, email or otherwise. This includes personal data you provide when you:
                        <ul>
                            <li>Communicate with us about, or enter into a contract for, our services;</li>
                            <li>Create an account on our website;</li>
                            <li>Subscribe to our service or publications;</li>
                            <li>Download any resource available on our website;</li>
                            <li>Request marketing to be sent to you; or</li>
                            <li>Give us some feedback.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Automated technologies or interactions.</strong> As you interact with our website, we will automatically collect Technical Data
                        about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar
                        technologies. Please see our cookie policy for further details.
                    </li>
                    <li>
                        <strong>Third parties or publicly available sources.</strong> We will receive personal data about you from various third parties and
                        public sources as set out below:
                        <ul>
                            <li>Technical Data from analytics providers, such as Microsoft Azure, based inside the EU;</li>
                            <li>Identity and Contact Data from authentication providers such as Microsoft, Google and Auth0, based inside and outside the EU.</li>
                            <li>
                                Identity and Contact Data from publicly available sources such as Companies House and the Electoral Register based inside the
                                EU.
                            </li>
                        </ul>
                    </li>
                </ul>
            </section>
            <section id="how-we-use-your-personal-data">
                <h3>How We Use Your Personal Data</h3>
                <p>
                    We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
                </p>
                <ul>
                    <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
                    <li>
                        Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override
                        those interests.
                    </li>
                    <li>Where we need to comply with a legal or regulatory obligation.</li>
                </ul>
                <p>
                    Click here{" "}
                    <a
                        target="_blank"
                        href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/"
                    >
                        https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/
                    </a>{" "}
                    to find out more about the types of lawful basis that we will rely on to process your personal data.
                </p>
                <p>
                    Generally, we do not rely on consent as a legal basis for processing your personal data other than in relation to sending third party direct
                    marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.
                </p>
                <h4>Purposes for which we will use your personal data</h4>
                <p>
                    We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we
                    rely on to do so. We have also identified what our legitimate interests are where appropriate.
                </p>
                <p>
                    Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your
                    data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than
                    one ground has been set out in the table below.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Purpose/Activity</th>
                            <th>Type of data</th>
                            <th>Lawful basis for processing including basis of legitimate interest</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>To register you as a new customer</td>
                            <td>
                                <ul>
                                    <li>Identity</li>
                                    <li>Contact</li>
                                </ul>
                            </td>
                            <td>Performance of a contract with you</td>
                        </tr>
                        <tr>
                            <td>
                                To process and deliver your order including:
                                <ul>
                                    <li>Manage payments, fees and charges</li>
                                    <li>Collect and recover money owed to us</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Identity</li>
                                    <li>Contact</li>
                                    <li>Financial</li>
                                    <li>Transaction</li>
                                    <li>Marketing and Communications</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Performance of a contract with you</li>
                                    <li>Necessary for our legitimate interests (to recover debts due to us)</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                To manage our relationship with you which will include:
                                <ul>
                                    <li>Notifying you about changes to our terms or privacy policy</li>
                                    <li>Asking you to leave a review or take a survey</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Identity</li>
                                    <li>Contact</li>
                                    <li>Profile</li>
                                    <li>Marketing and Communications</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Performance of a contract with you</li>
                                    <li>Necessary to comply with a legal obligation</li>
                                    <li>
                                        Necessary for our legitimate interests (to keep our records updated and to study how customers use our
                                        products/services)
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance,
                                support, reporting and hosting of data)
                            </td>
                            <td>
                                <ul>
                                    <li>Identity</li>
                                    <li>Contact</li>
                                    <li>Technical</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>
                                        Necessary for our legitimate interests (for running our business, provision of administration and IT services, network
                                        security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)
                                    </li>
                                    <li>Necessary to comply with a legal obligation</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we
                                serve to you
                            </td>
                            <td>
                                <ul>
                                    <li>Identity</li>
                                    <li>Contact</li>
                                    <li>Profile</li>
                                    <li>Usage</li>
                                    <li>Marketing and Communications</li>
                                    <li>Technical</li>
                                </ul>
                            </td>
                            <td>
                                Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business
                                and to inform our marketing strategy)
                            </td>
                        </tr>
                        <tr>
                            <td>To use data analytics to improve our website, products/services, marketing, customer relationships and experiences</td>
                            <td>
                                <ul>
                                    <li>Technical</li>
                                    <li>Usage</li>
                                </ul>
                            </td>
                            <td>
                                Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated
                                and relevant, to develop our business and to inform our marketing strategy)
                            </td>
                        </tr>
                        <tr>
                            <td>To make suggestions and recommendations to you about goods or services that may be of interest to you</td>
                            <td>
                                <ul>
                                    <li>Identity</li>
                                    <li>Contact</li>
                                    <li>Technical</li>
                                    <li>Usage</li>
                                    <li>Profile</li>
                                </ul>
                            </td>
                            <td>Necessary for our legitimate interests (to develop our products/services and grow our business)</td>
                        </tr>
                    </tbody>
                </table>
                <h4>Marketing</h4>
                <p>
                    We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. If you have any
                    questions about how we are using your personal data to conduct marketing or advertising at you, please contact us and we will be happy to
                    answer any questions you may have.
                </p>
                <h4>Promotional offers from us</h4>
                <p>
                    We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of
                    interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).
                </p>
                <p>
                    You will receive marketing communications from us if you have requested information from us or purchased services from us, or if you
                    provided us with your details and consent when you downloaded resources from our website, and, in each case, you have not opted out of
                    receiving that marketing.
                </p>
                <h4>Third-party marketing</h4>
                <p>
                    We will get your express opt-in consent before we share your personal data with any company outside the Marlowe group of companies for
                    marketing purposes.
                </p>
                <h4>Opting out</h4>
                <p>
                    You can ask us or third parties to stop sending you marketing messages at any time by contacting us. Where you opt out of receiving these
                    marketing messages, this will not apply to personal data provided to us as a result of any use you make of WorkNest services, which we will
                    continue to process in accordance with our Data Protection Policy and pursuant to the contractual obligations between us.
                </p>
                <h4>Cookies</h4>
                <p>
                    You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or
                    refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the
                    cookies we use, please see our <Link to="/trustcentre/cookies">cookie policy</Link>.
                </p>
                <h4>Change of purpose</h4>
                <p>
                    We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for
                    another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the
                    new purpose is compatible with the original purpose, please contact us.
                </p>
                <p>
                    If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do
                    so.
                </p>
                <p>
                    Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is
                    required or permitted by law.
                </p>
            </section>
            <section id="disclosures-of-your-personal-data">
                <h3>Disclosures of your personal data</h3>
                <p>We may have to share your personal data with the parties set out below for the purposes set out in the table above.</p>
                <ul>
                    <li>Internal Third Parties as set out in the Glossary.</li>
                    <li>External Third Parties as set out in the Glossary.</li>
                    <li>
                        Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to
                        acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the
                        same way as set out in this privacy notice.
                    </li>
                </ul>
                <p>
                    We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our
                    third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for
                    specified purposes and in accordance with our instructions.
                </p>
            </section>
            <section id="international-transfers">
                <h3>International transfers</h3>
                <p>We do not transfer your personal data outside the European Economic Area (EEA).</p>
            </section>
            <section id="data-security">
                <h3>Data security</h3>
                <p>
                    We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an
                    unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other
                    third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty
                    of confidentiality.
                </p>
                <p>
                    We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach
                    where we are legally required to do so.
                </p>
            </section>
            <section id="data-retention">
                <h3>Data retention</h3>
                <h4>How long will you use my personal data for?</h4>
                <p>
                    We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of
                    satisfying any legal, accounting, or reporting requirements.
                </p>
                <p>
                    To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the
                    potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and
                    whether we can achieve those purposes through other means, and the applicable legal requirements.
                </p>
                <p>
                    In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical
                    purposes in which case we may use this information indefinitely without further notice to you.
                </p>
            </section>
            <section id="your-legal-rights">
                <h3>Your legal rights</h3>
                <p>
                    Under certain circumstances, you have rights under data protection laws in relation to your personal data. Please click on the links below
                    to find out more about these rights:
                </p>
                <ul>
                    <li>
                        <a
                            target="_blank"
                            href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-of-access/"
                        >
                            Request access to your personal data.
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-rectification/"
                        >
                            Request correction of your personal data.
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-erasure/"
                        >
                            Request erasure of your personal data.
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-object/"
                        >
                            Object to processing of your personal data.
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-restrict-processing/"
                        >
                            Request restriction of processing your personal data.
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-data-portability/"
                        >
                            Request transfer of your personal data.
                        </a>
                    </li>
                </ul>
                <p>
                    In addition to the above, you also have the right to withdraw consent to processing where the processing we are undertaking is being done so
                    with you having given consent to it.
                </p>
                <p>If you wish to exercise any of the rights set out above, please contact us.</p>
                <h4>No fee usually required</h4>
                <p>
                    You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable
                    fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these
                    circumstances.
                </p>
                <h4>What we may need from you</h4>
                <p>
                    We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or
                    to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no
                    right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
                </p>
                <h4>Time limit to respond</h4>
                <p>
                    We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is
                    particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.
                </p>
            </section>
            <section id="your-legal-rights">
                <h3>Your legal rights</h3>
                <p>You have the right to:</p>
                <p>
                    <strong>Request access</strong> to your personal data (commonly known as a “data subject access request”). This enables you to receive a
                    copy of the personal data we hold about you and to check that we are lawfully processing it. Your right to your personal data does not
                    necessarily include a right to access or see content that is commercially sensitive or that may reveal another person’s personal data. Where
                    this is the case, it will be made apparent to you, together with our reasoning for withholding any such data, when we respond to your data
                    subject access request.
                </p>
                <p>
                    <strong>Request correction</strong> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data
                    we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
                </p>
                <p>
                    <strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove personal data where there is no good
                    reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully
                    exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required
                    to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure
                    for specific legal reasons which will be notified to you, if applicable, at the time of your request.
                </p>
                <p>
                    <strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or those of a third party) and
                    there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on
                    your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing
                    purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your
                    rights and freedoms.
                </p>
                <p>
                    <strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to suspend the processing of your
                    personal data in the following scenarios:
                </p>
                <ul>
                    <li>If you want us to establish the data’s accuracy;</li>
                    <li>Where our use of the data is unlawful but you do not want us to erase it;</li>
                    <li>Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or</li>
                    <li>You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
                </ul>
                <p>
                    <strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide to you, or a third party you have
                    chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated
                    information which you initially provided consent for us to use or where we used the information to perform a contract with you.
                </p>
                <p>
                    <strong>Withdraw consent</strong> at any time where we are relying on consent to process your personal data. However, this will not affect
                    the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide
                    certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
                </p>
            </section>
            <section id="glossary">
                <h3>Glossary</h3>
                <h4>LAWFUL BASIS</h4>
                <p>
                    <strong>Legitimate Interest</strong> means the interest of our business in conducting and managing our business to enable us to give you the
                    best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both
                    positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data
                    for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted
                    to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect
                    of specific activities by contacting us.
                </p>
                <p>
                    <strong>Performance of Contract</strong> means processing your data where it is necessary for the performance of a contract to which you are
                    a party or to take steps at your request before entering into such a contract.
                </p>
                <p>
                    <strong>Comply with a legal or regulatory obligation</strong> means processing your personal data where it is necessary for compliance with
                    a legal or regulatory obligation that we are subject to.
                </p>
                <h4>THIRD PARTIES</h4>
                <p>
                    <strong>Internal Third Parties</strong>
                </p>
                <p>
                    Other companies in the WorkNest Group acting as joint controllers or processors and who are all based within the United Kingdom and provide
                    different, and complementary, aspects of the services that WorkNest as a whole provides to its customers.
                </p>
                <p>
                    <strong>External Third Parties</strong>
                </p>
                <ul>
                    <li>Service providers, acting as processors, based within the EEA who provide IT and system administration services.</li>
                    <li>
                        Professional advisers, acting as processors, including lawyers, bankers, auditors and insurers based within the United Kingdom who
                        provide consultancy, banking, legal, insurance and accounting services.
                    </li>
                    <li>
                        HM Revenue & Customs, regulators and other authorities acting as processors or joint controllers based in the United Kingdom who require
                        reporting of processing activities in certain circumstances.
                    </li>
                </ul>
            </section>
        </TrustCentreArticle>
    );
}

export default PrivacyPolicy;
