import React, { useState } from "react";
import { useSnackbar } from "notistack";
import ManageUser from "../components/administration/ManageUser";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import useUpdateUser from "../hooks/mutations/useUpdateUser";
import useUpdateUserAlertSetting from "../hooks/mutations/useUpdateUserAlertSetting";
import appIdConstants from "../constants/appIdConstants";
import useUpdateUserAccessPermission from "../hooks/mutations/useUpdateUserAccessPermission";
import { useAuth } from "../contexts/authContext";
import useUpdateCaseNestUserAlertSetting from "../hooks/mutations/useUpdateCaseNestUserAlertSetting";

const EditUser = () => {
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const history = useHistory();
    const { siteId, userToManage } = useSelector(state => state.admin);
    const { enqueueSnackbar } = useSnackbar();
    const { mutate, isLoading } = useUpdateUser();
    const updateUserAlertSetting = useUpdateUserAlertSetting();
    const updateUserAccessPermission = useUpdateUserAccessPermission();
    const updateCaseNestUserAlertSetting = useUpdateCaseNestUserAlertSetting();
    const { isCaseNestAdministratorForSites } = useAuth();
    const { selectedAccount } = useSelector((state) => state.account);
    const isCaseNestAdministrator = isCaseNestAdministratorForSites(selectedAccount?.childExternalIds);

    const handleUpdateUser = async ({
        firstName,
        lastName,
        title,
        phone,
        mobile,
        roles,
        appIds,
        hasLeftBusiness,
        alertSettings,
        safetyNestAccessModules,
        canAccessChildSites,
        caseNestAlertSettings,
        adviceTypes,
    }) => {
        setIsSaveDisabled(true);
        const removedAccountSaasAppIds = userToManage.accountApps[siteId]
            ?.filter((x) => !appIds.includes(x.appId))
            .map((x) => x.accountSaasAppId);

        const addedSaasAppIds = appIds.filter(x => !userToManage.accountApps[siteId]?.some(y => y.appId === x));
        const hasSafetyNestAccess = appIds.includes(appIdConstants.safetynest);
        const hasCaseNestAccess = appIds.includes(appIdConstants.casenest);

        const updates = {
            userExternalId: userToManage.externalId,
            accountRelationshipId: userToManage.accountRoles[siteId].relationshipId,
            accountId: siteId,
            roles,
            firstName,
            lastName,
            title,
            phone,
            mobile,
            removedAccountSaasAppIds,
            addedSaasAppIds,
            hasLeftBusiness,
            canAccessChildSites,
            adviceTypes,
        };

        if (isCaseNestAdministrator && hasCaseNestAccess) {
            await updateCaseNestUserAlertSetting.mutateAsync(
                { userExternalId: userToManage.externalId, ...caseNestAlertSettings },
                {
                    onError: (error) => {
                        console.error(error);
                        enqueueSnackbar(error?.message || "There was an error updating the CaseNest user alert setting", { variant: "error" });
                    }
                }
            )
        }

        if (hasSafetyNestAccess) {
            await updateUserAlertSetting.mutateAsync(
                { userExternalId: userToManage.externalId, ...alertSettings },
                {
                    onError: (error) => {
                        console.error(error);
                        enqueueSnackbar(error?.message || "There was an error updating the SafetyNest user alert setting", { variant: "error" });
                    },
                }
            );
            await updateUserAccessPermission.mutateAsync(
                { userExternalId: userToManage.externalId, ...safetyNestAccessModules },
                {
                    onError: (error) => {
                        console.error(error);
                        enqueueSnackbar(error?.message || "There was an error updating the user access permission", { variant: "error" });
                    },
                }
            );
        }

        mutate(updates, {
            onSuccess: () => {
                history.goBack();
                setIsSaveDisabled(false);
                enqueueSnackbar("Changes saved successfully!", { variant: "success" });
            },
            onError: (error) => {
                console.error(error);
                enqueueSnackbar(error?.message || "There was an error updating the user", { variant: "error" });
            },
        });
    };
    return siteId.length === 0 ? (
        <Redirect to="/administration" />
    ) : (
        <ManageUser
            onSubmit={handleUpdateUser}
            existingUser={userToManage}
            accountId={siteId}
            isLoading={isLoading}
            isSaveDisabled={isSaveDisabled}
        />
    );
};

export default EditUser;
