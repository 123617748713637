import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    userAvatar: {
        borderRadius: '50%',
        width: '2.5rem',
        height: '2.5rem',
        padding: theme.spacing(1),
        backgroundColor: '#5c45c7',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
    },
}));

const UserInitials = ({ firstName, lastName }) => {
    const classes = useStyles();

    return <div className={classes.userAvatar}>{firstName && lastName ? firstName.charAt(0) + lastName.charAt(0) : 'N/A'}</div>
}

export default UserInitials;