import React from "react";
import {
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  Box,
  MobileStepper,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import useIsMobile from "../../../hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 50,
    height: 50,
    border: "solid 1px #eee",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#a7a6ba",
    fontSize: "1.25rem",
    fontWeight: "600",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    "&.active": {
      color: theme.palette.secondary.main,
    },
    "&.completed": {
      color: "#fff",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  stepper: {
    minWidth: "50%",
    padding: 0,
  },
  mobileStepper: {
    background: "none",
  },
  activeDot: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const LineConnecter = withStyles({
  alternativeLabel: {
    top: 23,
    left: "calc(-50% + 36px)",
    right: "calc(50% + 36px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 3,
  },
})(StepConnector);

function StepperIcon(props) {
  const classes = useStyles();

  const { active, completed, icon } = props;

  return (
    <div
      className={`
            ${classes.icon} 
            ${active ? "active" : ""} 
            ${completed ? "completed" : ""}
        `}
    >
      {icon}
    </div>
  );
}

export default function PepperStepper({ steps, activeStep }) {
  const classes = useStyles();
  const isMobile = useIsMobile();

  if (isMobile)
    return (
      <MobileStepper
        variant="dots"
        backButton={<div></div>}
        nextButton={<div></div>}
        steps={steps.length}
        activeStep={activeStep}
        position="static"
        className={classes.mobileStepper}
        classes={{ dotActive: classes.activeDot }}
      />
    );

  return (
    <Stepper
      className={classes.stepper}
      activeStep={activeStep}
      alternativeLabel
      connector={<LineConnecter />}
    >
      {steps?.map((step) => (
        <Step key={step.label}>
          <StepLabel StepIconComponent={StepperIcon}>
            <Box display="flex" justifyContent="center" flexDirection="column">
              <span>{step.label}</span>
              {step.isOptional && (
                <Typography variant="caption">Optional</Typography>
              )}
            </Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
