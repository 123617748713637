import axios from "../utils/axios";

const apiUrl = process.env.REACT_APP_LEGAL_URL;

const getPrecedents = (licence) => {

    return axios.get(`${apiUrl}/client/getprecedents/${licence}`);
};

const resourceNestService = {
    getPrecedents
};

export default resourceNestService;
