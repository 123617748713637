import React, { useState } from "react";
import Avatar from "../Avatar";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";

import {
  Email as EmailIcon,
  PhoneAndroid as MobileIcon,
  Phone as PhoneIcon,
} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  textCenter: {
    textAlign: "center",
  },
  dialogPopup: {
    maxWidth: "lg",
  },
  htmlText: {
    marginLeft: "6px",
  },
  componentStyle: {
    boxShadow: 5,
    padding: "10px",
    border: "5px solid black",
  },
  MuiEngagementCard01: {
    transition: "0.3s",
    margin: "auto",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
}));

const HealthSafetyCard = ({ accountHsConsultants }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const ViewConsultant = (consultant) => (
    <Box
      key={consultant.email}
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={5}
      mb={2}
      ml={2}
      mr={2}
      className={classes.textCenter}
    >
      <Box>
        <Avatar size="lg" alt={consultant.name} src={consultant.photo} />
      </Box>
      <Box>
        <Typography variant="subtitle2">{consultant.name}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2">{consultant.position}</Typography>
      </Box>
    </Box>
  );

  const ViewConsultantDetails = (adviser) => (
    <Grid key={adviser.name} container spacing={12}>
      <Grid item xs={12} md={3}>
        <DialogContent>
          <Box
            key={adviser.name}
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={5}
            mb={2}
            ml={2}
            mr={2}
            className={classes.textCenter}
          >
            <Box>
              <Avatar alt={adviser.name} size="lg" src={adviser.photo} />
            </Box>
            <Typography className={classes.textCenter} variant="h5" mb={6}>
              {adviser.name}
            </Typography>
            <Link href={"mailto:" + adviser.email} underline="hover">
              <EmailIcon fontSize="small" />
              {adviser.email}
            </Link>
            {adviser.phone && (
              <Link href={"tel:" + adviser.phone} underline="hover">
                <PhoneIcon fontSize="small" />
                {adviser.phone}
              </Link>
            )}
            {adviser.mobile && (
              <Link href={"tel:" + adviser.mobile} underline="hover">
                <MobileIcon fontSize="small" />
                {adviser.mobile}
              </Link>
            )}
          </Box>
        </DialogContent>
      </Grid>
      <Grid item xs={12} md={9}>
        <DialogContent>
          <Typography
            dangerouslySetInnerHTML={{
              __html: `<div>${adviser.aboutMe}</div>`,
            }}
          />
        </DialogContent>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {accountHsConsultants.length > 0 && (
        <CardContent>
          <Box display="flex">
            <Box flexGrow={1} alignItems="center">
              <Typography className={classes.textCenter} variant="h5" mb={6}>
                Your Health & Safety Consultant
              </Typography>
              <Box
                display="flex"
                justifyContent="space-around"
                flexWrap="wrap"
                m={-2}
              >
                {accountHsConsultants.map((a) => ViewConsultant(a))}
              </Box>
              <Box
                display="flex"
                justifyContent="space-around"
                flexWrap="wrap"
                m={2}
              >
                <Button
                  variant="outlined"
                  className={classes.textCenter}
                  onClick={() => setOpen(true)}
                  color="primary"
                >
                  More Information
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      )}

      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <Grid>
          <DialogTitle id="alert-dialog-title">
            {accountHsConsultants.length === 1
              ? "Your " + accountHsConsultants[0].role
              : "Your " + accountHsConsultants[0].role + " Team"}
          </DialogTitle>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={5}
            mb={2}
            ml={5}
            mr={5}
          >
            <Box>
              <Typography gutterBottom>
                {"Your Health & Safety Consultant is " +
                  accountHsConsultants[0].name +
                  " who will visit your premises to perform the appropriate assessments and develop your bespoke Health & Safety Management System."}
              </Typography>

              <Typography gutterBottom>
                {"In the event that " +
                  accountHsConsultants[0].name +
                  " is not available, you can call the WorkNest Head Office Health & Safety Team on 0345 226 8393."}
              </Typography>
            </Box>
          </Box>
          {accountHsConsultants.map((a) => ViewConsultantDetails(a))}
          <Box
            display="flex"
            justifyContent="space-around"
            flexWrap="wrap"
            m={2}
          >
            <Button
              variant="outlined"
              className={classes.textCenter}
              onClick={handleCloseDialog}
              color="primary"
            >
              Close
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};

export default HealthSafetyCard;
