import {useMemo} from "react";
import {useSelector} from "react-redux";
import useContactsForApp from "./queries/useContactsForApp";
import _ from "lodash";

const useContactsForAppAndSite = ({ selectedSiteId }) => {
  const { selectedAccount, activeAppId } = useSelector((state) => state.account);
  const appContacts = useContactsForApp({
    activeAppId,
    rootExternalId: selectedAccount?.account?.externalId,
  });

  return useMemo(() => {
    if (!appContacts?.data?.length)
      return { ...appContacts, data: {} };
    
    if (!selectedSiteId)
      return { ...appContacts, data: _.keyBy(appContacts.data, x => x.externalId) };
    
    return { ...appContacts, data: _.keyBy(appContacts.data.filter(x => x.accountIds.includes(selectedSiteId)), x => x.externalId) };
  }, [selectedSiteId, appContacts]);
}

export default useContactsForAppAndSite;