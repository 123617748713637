import { Button, Toolbar, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        boxShadow: theme.shadows[1],
        height: "80px",
        padding: "0 24px",
    },
    reviewConfirmed: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        gap: theme.spacing(1),
    },
    confirmedDismiss: {
        marginLeft: "auto",
    },
}));

export default function DismissableToolbar({ title, subtitle, onDismiss }) {
    const classes = useStyles();

    return (
        <Toolbar className={classes.toolbar}>
            <div className={classes.reviewConfirmed}>
                <Typography variant="body2">{title}</Typography>
                <Typography variant="caption" color="textSecondary">
                    {subtitle}
                </Typography>
            </div>
            <Button onClick={onDismiss} className={classes.confirmedDismiss} variant="text" size="small" color="secondary">
                Dismiss
            </Button>
        </Toolbar>
    );
}
