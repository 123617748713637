import { SET_SELECTED_FOLDER } from "../types";

const initialState = {
  selectedFolder: "",
};

function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_FOLDER:
      return {
        ...state,
        selectedFolder: action.payload,
      };
    default:
      return state;
  }
}

export default documentsReducer;
