import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import prismicService from "../../services/prismicService";

const usePrismicCategoriesByParentId = (sectionName, parentId) =>
    useQuery(
        [queryKeys.prismicCategoriesByParentId, parentId],
        () => prismicService.fetchCategoriesByParentId(sectionName, parentId),
        {
            enabled: !!parentId
        }
    );

export default usePrismicCategoriesByParentId;