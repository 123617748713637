import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, TextField } from "@material-ui/core";
import { useIncidentDetails } from "../../contexts/IncidentContext";

const useStyles = makeStyles((theme) => ({
    paddingBottom: {
        paddingBottom: theme.spacing(0)
    }
}))

export default function InvestigationNotRequiredDialog({
    open,
    onCancel,
    onSave
}) {
    const classes = useStyles()
    const {
        reasonInvestigationNotRequired, 
        setReasonInvestigationNotRequired,
    } = useIncidentDetails()

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle className={classes.paddingBottom}>Investigation Not Applicable</DialogTitle>
            <form onSubmit={(e) => {
                e.preventDefault()
                onSave()
            }}>
                <DialogContent>
                    <DialogContentText>
                        Please give a reason why an investigation is not applicable for this incident.
                    </DialogContentText>
                        <TextField
                            inputProps={{ maxLength: 256 }}
                            fullWidth
                            label="Reason"
                            multiline
                            rows={4}
                            variant="outlined"
                            required
                            value={reasonInvestigationNotRequired}
                            onChange={(e) => setReasonInvestigationNotRequired(e.target.value)}
                        />
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setReasonInvestigationNotRequired('')
                            onCancel()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}