import {SET_COOKIE_JAR_OPEN, SET_COOKIE_JAR_STEP} from "../types";

export function setCookieJarOpen(payload){
  return {
    type: SET_COOKIE_JAR_OPEN,
    payload,
  }
}

export function setCookieJarStep(payload){
  return {
    type: SET_COOKIE_JAR_STEP,
    payload,
  }
}