import React from "react";
import { Badge, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import NavLinkWrapper from "./NavLinkWrapper";

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(3),
    fontWeight: theme.typography.fontWeightRegular,
    alignItems: "center",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.08);",
    },
    [`&.${props.activeClassName}`]: {
      background: "rgba(0, 0, 0, 0.08);",
    },
  }),
  label: {
    margin: 0,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  labelText: {
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  badge: {
    marginLeft: "auto",
  },
  icon: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    opacity: 0.5,
  },
}));

function SidebarLink({ name, to, badge = null, icon: Icon }) {
  const activeClassName = "active";
  const classes = useStyles({ activeClassName });
  return (
    <ListItem
      button
      dense
      component={NavLinkWrapper}
      exact
      to={to}
      activeClassName={activeClassName}
      className={classes.container}
    >
      {Icon && <Icon className={classes.icon} />}
          <ListItemText className={classes.label} classes={{ primary: classes.labelText }}>{name}</ListItemText>
      {badge && (
        <Badge className={classes.badge} color="error" badgeContent={badge} />
      )}
    </ListItem>
  );
}

export default SidebarLink;
