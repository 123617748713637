import React from "react";
import { Switch } from "react-router-dom";
import RouteMapper from "./RouteMapper";
import { publicRoutes } from "../routes";
import { SnackbarProvider as VendingMachine } from "notistack";

const UnauthenticatedApp = () => {
  return (
    <VendingMachine maxSnack={3}>
      <Switch>
        <RouteMapper routes={publicRoutes} />
      </Switch>
    </VendingMachine>
  );
};

export default UnauthenticatedApp;
