import React from "react";
import { makeStyles } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  plusMinus: {
    display: "flex",
  },
  delete: {
    display: "flex",
    alignItems: "center",
    marginTop: "2px",
    marginBottom: "2px",
    marginLeft: theme.spacing(3),
    "&:hover": {
      cursor: "pointer",
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px transparent",
    backgroundColor: "#ddd",
    borderRadius: "20%",
    minWidth: "28px",
    minHeight: "28px",
    fontSize: "14px",
    fontWeight: "600",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#bbb",
    },
  },
  count: {
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "28px",
    fontWeight: "600",
    fontSize: "14px",
  },
}));

export default function CabbageCounter({
  handleDelete,
  handleDecrement,
  handleIncrement,
  count,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.plusMinus}>
        <button className={classes.button} onClick={handleDecrement}>
          -
        </button>
        <div className={classes.count}>{count}</div>
        <button className={classes.button} onClick={handleIncrement}>
          +
        </button>
      </div>
      <div className={classes.delete}>
        <Delete fontSize="small" onClick={handleDelete} />
      </div>
    </>
  );
}
