import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import prismicService from "../../services/prismicService";

const usePrismicCategoryArticles = (sectionName, categoryId, pageNumber, searchTerm) =>
    useQuery(
        [queryKeys.prismicCategoryArticles, categoryId, pageNumber, searchTerm],
        () => prismicService.fetchCategoryArticles(sectionName, categoryId, pageNumber, searchTerm)
    );

export default usePrismicCategoryArticles;