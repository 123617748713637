import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";
import { folderNameIsValid } from "../../utils/validators";

const useStyles = makeStyles(() => ({
  textField: {
    minWidth: "300px",
  },
}));

const NewFolderDialog = ({
  open,
  handleClose,
  handleAddFolder,
  pathWithAccountName,
}) => {
  const classes = useStyles();
  const [folderName, setFolderName] = useState("");

  const handleCancel = () => {
    handleClose();
    setFolderName("");
  };

  const handleSave = () => {
    handleAddFolder(folderName);
    handleCancel();
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Create new folder</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {pathWithAccountName.replaceAll("/", " / ")}
        </DialogContentText>
        <TextField
          fullWidth
          className={classes.textField}
          variant="outlined"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          label="Name"
          error={Boolean(!folderNameIsValid(folderName) && folderName.length)}
          helperText={
            folderNameIsValid(folderName) || !folderName.length
              ? ""
              : "Folder name contains an invalid character"
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="secondary"
          disabled={!folderNameIsValid(folderName)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewFolderDialog;
