import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import riskAssessmentService from "../../services/riskAssessmentService";

const useRiskAssessments = ({ externalIds, pageSize, pageNum, riskLevel, sortOrder, orderByColumn, assignees, filteredText, fromDate, toDate}) =>
  useQuery(
    [
      queryKeys.riskAssessments,
      externalIds?.join(","),
      pageNum,
      pageSize,
      riskLevel !== null ? String(riskLevel) : null,
      sortOrder,
      orderByColumn,
      fromDate,
      toDate,
      filteredText && filteredText.length > 3 ? filteredText : undefined,
      assignees
      ].filter(Boolean), 
    () =>
      riskAssessmentService.fetchRiskAssessmentsForSites({
        siteExternalIds: externalIds,
        pageSize,
        pageNum,
        riskLevel,
        sortOrder,
        orderByColumn,
        fromDate,
        toDate,
        filteredText: filteredText && filteredText.length > 3 ? filteredText : undefined,
        assignees
       }),
    {
      enabled: !!externalIds?.length,
    }
  );

export default useRiskAssessments;
