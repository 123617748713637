import axios from "../utils/axios";
import fileUtils from "../utils/fileUtils";
import stringUtils from "../utils/stringUtils";

const documentEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/documents`;

const getDocuments = ({ documentType, accountExternalIds }) => {
  let queryString = stringUtils.buildQueryStringList(
    "externalIds",
    accountExternalIds
  );

  return axios.get(
    `${documentEndpointUrl}/GetDocuments/${documentType}${queryString}`
  );
};

const addFolder = (documentType, fileName) =>
  axios.post(
    `${documentEndpointUrl}/addfolder/${documentType}?fileName=${encodeURIComponent(
      fileName
    )}`
  );

const addDocuments = (documentType, files, selectedFolder) => {
  let formData = new FormData();
  files.forEach((file) => formData.append("files", file));
  return axios.post(
    `${documentEndpointUrl}/adddocuments/${documentType}?selectedFolder=${encodeURIComponent(
      selectedFolder
    )}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const deleteFolder = (documentType, folder) =>
  axios.post(
    `${documentEndpointUrl}/deleteFolder/${documentType}?folder=${encodeURIComponent(
      folder
    )}`
  );

const renameFolder = (documentType, folder, newFolder) =>
  axios.post(
    `${documentEndpointUrl}/renameFolder/${documentType}?folder=${encodeURIComponent(
      folder
    )}&newFolder=${encodeURIComponent(newFolder)}`
  );

const downloadDocument = async (documentType, fileName, fileNameNoPath) => {
  try {
    const response = await axios.get(
      `${documentEndpointUrl}/downloaddocument/${documentType}?fileName=${encodeURIComponent(
        fileName
      )}`,
      { responseType: "arraybuffer" }
    );
    fileUtils.downloadFileFromBlob(response, fileNameNoPath);
  } catch (error) {
    throw error;
  }
};

const deleteDocument = async (documentType, fileName) =>
  await axios.post(
    `${documentEndpointUrl}/deletedocument/${documentType}?fileName=${encodeURIComponent(
      fileName
    )}`
  );

const downloadCookiePolicy = async () => {
  try {
    const response = await axios.get(
      `${documentEndpointUrl}/downloadcookiepolicy`,
      { responseType: "arraybuffer" }
    );
    fileUtils.downloadFileFromBlob(response, "WorkNest Cookie Policy.docx");
  } catch (error) {
    throw error;
  }
};

const renameDocument = async ({
  oldDocumentPath,
  newDocumentPath,
  selectedFolder,
}) =>
  await axios.patch(`${documentEndpointUrl}/rename`, {
    oldDocumentPath,
    newDocumentPath,
    selectedFolder,
  });

const documentService = {
  getDocuments,
  addFolder,
  deleteFolder,
  renameFolder,
  addDocuments,
  downloadDocument,
  deleteDocument,
  downloadCookiePolicy,
  renameDocument,
};

export default documentService;
