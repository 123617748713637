import {
    Box, Button, Divider, FormControlLabel, Grid, IconButton, makeStyles, Radio, RadioGroup, TextField, Typography,
    Menu, MenuItem, ListItemIcon,
} from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import {
    Cancel, Delete, ExpandLess, ExpandMore, Save, MoreVert,
    DeleteOutlined as DeleteIcon,
    EditOutlined as EditIcon, } from "@material-ui/icons"
import { nanoid } from "nanoid"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { useRiskAssessmentDetails } from "../../contexts/RiskAssessmentContext"
import QuestionMapper from "./QuestionMapper"
import HazardControlAmendDialog from "./dialogs/HazardControlAmendDialog"
import HazardControlDeleteDialog from "./dialogs/HazardControlDeleteDialog"

const useStyles = makeStyles((theme) => ({
    container: {
        border: `1px solid ${grey[400]}`,
        borderRadius: "4px",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    hazardIcons: {
        height: '40px',
        marginRight: theme.spacing(2)
    },
    text: {
        fontSize: '12px',
        color: '#888'
    },
    text2: {
        fontSize: '12px',
    },
    score: {
        width: '45px', 
        height: '30px', 
        display: 'flex', 
        justifyContent: 'center', 
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        alignItems: 'center', 
        borderRadius: '4px',
        background: '#aaa',
        cursor: 'pointer',
        '&.red': {
            background: '#f37142',
        },
        '&.amber': {
            background: '#ffb943', 
        },
        '&.yellow': {
            background: '#ffea7b', 
        },
        '&.lime': {
            background: '#9af288', 
        },
        '&.green': {
            background: '#76dd78', 
        },
        '&:hover': {
            opacity: '0.5'
        },
        '&>svg': {
            height: '10px'
        }
    },
    riskCircle: {
        background: '#eee',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#888',
        '&.red': {
            background: '#f37142',
            color: '#fff'
        },
        '&.amber': {
            background: '#ffb943',
            color: '#fff'
        },
        '&.green': {
            background: '#76dd78', 
            color: '#fff'
        },
    },
    control: {
        border: '1px solid #eee',
        padding: theme.spacing(1),
        borderRadius: '10px',
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginBottom: theme.spacing(2),
        borderTop: '1px dashed #ccc'
    },
    divider2: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
        borderTop: '1px dashed #ccc'
    },
    expand: {
        cursor: 'pointer'
    },
    riskScore: {
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
    customControl: {
        alignItems: 'center', 
        display: 'flex',
        gridColumnGap: theme.spacing(3),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'start'
        }
    },
    menuDelete: {
        color: "red !important",
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    menuItemIcon: {
        minWidth: theme.spacing(5),
    },
    menuItemText: {
        marginRight: theme.spacing(5),
        fontSize: '12px'
    },
    menuItemIconDel: {
        minWidth: theme.spacing(5),
        color: "red"
    },
}))
    
const hazardImagesUrl = `${process.env.PUBLIC_URL}/images/hazardIcons/`

export default function HazardEvaluation() {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const {
        addPreControlSeverityScore,
        addPreControlLikelihoodScore,
        addPostControlSeverityScore,
        addPostControlLikelihoodScore,
        riskAssessmentDetails,
        removeControl,
        addControl,
        toggleControl,
        updateControlText,
        isEditMode,
    } = useRiskAssessmentDetails()

    const scoreSelectorActions = {
        onPreControlSeverityScoreClick: (score) => addPreControlSeverityScore(score),
        onPreControlLikelihoodScoreClick: (score) => addPreControlLikelihoodScore(score),
        onPostControlSeverityScoreClick: (score) => addPostControlSeverityScore(score),
        onPostControlLikelihoodScoreClick: (score) => addPostControlLikelihoodScore(score),
    }

    const scoreSelectors = [
        {
            color: 'green',
            value: 1,
            text: 'One',
            ...scoreSelectorActions,
        },
        {
            color: 'lime',
            value: 2,
            text: 'Two',
            ...scoreSelectorActions,
        },
        {
            color: 'yellow',
            value: 3,
            text: 'Three',
            ...scoreSelectorActions,
        }
        ,
        {
            color: 'amber',
            value: 4,
            text: 'Four',
            ...scoreSelectorActions,
        } ,
        {
            color: 'red',
            value: 5,
            text: 'Five',
            ...scoreSelectorActions,
        }
    ]

    const [openHazards, setOpenHazards] = useState([])
    const [defaultOpen, setDefaultOpen] = useState(true)
    const [newCustomControl, setNewCustomControl] = useState('')
    const [newControlFieldVisible, setNewControlFieldVisible] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [hazardIdToAmend, setHazardIdToAmend] = useState(null);
    const [isCustomHazard, setIsCustomHazard] = useState(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const allHazards = {...riskAssessmentDetails?.hazards ?? {}, ...riskAssessmentDetails?.customHazards ?? {}}

    function openHazard(id, index) {
        if (index === 0 && defaultOpen) setDefaultOpen(false)
        
        else 
            openHazards?.includes(id) ? 
                setOpenHazards(openHazards.filter(x => x !== id)) : 
                setOpenHazards([...openHazards, id])
    }

    function resetCustomControl() {
        setNewControlFieldVisible(0)
        setNewCustomControl('')
    }

    function updateControl(hazardId, controlId, isCustomHazard) {
        controlId ? 
        removeControl({hazardId, controlId, isCustomHazard}) :
        newCustomControl ? 
        addControl({hazardId, id: nanoid(5), name: newCustomControl, isCustomHazard}) :
        enqueueSnackbar("Control description cannot be blank", {
            variant: "error",
        }) 
        
        newCustomControl && resetCustomControl()
    }

    function toggleHazardControl(value, hazardId, controlId, isCustomHazard, isCustomControl) {
        const inPlace = value === 'true'

        toggleControl({hazardId, controlId, inPlace, isCustomHazard, isCustomControl})
    }

    const handleAdminClick = (e, id, isCustomHazard) => {
        e.stopPropagation();
        setIsCustomHazard(isCustomHazard);
        setAnchorEl(e.currentTarget);
        setIsMenuOpen(id);
    };

    const handleAdminClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
        setIsMenuOpen(false);
    };

    const [isEditOpen, setIsEditOpen] = useState(false);
    const [hazardControlToAmend, setHazardControlToAmend] = useState(null);

    function handleUpdateControl(updatedControl) {
        {
            updatedControl?.controlId ?
                updateControlText({ hazardIdToAmend, controlId: updatedControl.controlId, hazardControlText: updatedControl.hazardControlText, isCustomHazard, isCustomControl: updatedControl.isCustomControl })
                : updatedControl.hazardControlText ?
                    addControl({ hazardId: hazardIdToAmend, id: nanoid(5), name: updatedControl.hazardControlText, isCustomHazard: updatedControl?.isCustomHazard }) :
                    enqueueSnackbar("Control description cannot be blank", {
                        variant: "error",
                    })
        }
    }

    const openEditControlPopup = (e, hazardControl, hazardId) => {
        e.stopPropagation();
        setHazardControlToAmend(hazardControl);
        setHazardIdToAmend(hazardId);
        setIsEditOpen(true);
        setAnchorEl(null);
        setIsMenuOpen(false);
    };

    const openDeleteControlPopup = (e, hazardControl, hazardId) => {
        e.stopPropagation();
        setHazardControlToAmend(hazardControl);
        setHazardIdToAmend(hazardId);
        setIsDeleteOpen(true);
        setAnchorEl(null);
        setIsMenuOpen(false);
    };

    function handleRemoveControl(updatedControl) {
            removeControl({ hazardId: hazardIdToAmend, controlId: updatedControl.controlId, isCustomHazard, isCustomControl: updatedControl.isCustomControl });
    }

    return (<>
        <div>
            {Object.keys(allHazards)?.sort((a, b) => a.id - b.id).map((hazardId, index) => {
                const img = new Image()
                img.src = `${hazardImagesUrl}${allHazards[hazardId]?.name}.svg`
                const isCustomHazard = Object.keys(riskAssessmentDetails?.customHazards)?.includes(hazardId)

                return (
                    <div className={classes.container} key={hazardId}>
                        <Box display='flex' flexDirection='column' alignItems='center'>
                            <Box 
                                onClick={() => openHazard(hazardId, index)} 
                                className={classes.expand} 
                                width='100%' 
                                display='flex' 
                                justifyContent={((index === 0 && defaultOpen) || openHazards?.includes(hazardId)) ? 'center' : "left"}  
                                alignItems='center'
                            >
                                <img className={classes.hazardIcons} alt='warning' src={img.complete ? img.src : `${hazardImagesUrl}General warning.svg`} />
                                <Typography variant="subtitle2">{allHazards[hazardId]?.name}</Typography>
                                {((index === 0 && defaultOpen) || openHazards?.includes(hazardId)) ?
                                    <ExpandLess />
                                    :
                                    <ExpandMore />
                                }
                            </Box>
                            {((index === 0 && defaultOpen) || openHazards?.includes(hazardId)) && <>
                                <Box marginTop={2}>
                                    <Typography variant='body2'>
                                        Before any controls are in place, evaluate the severity of the hazard and likelihood:
                                    </Typography>
                                </Box>
                                <QuestionMapper 
                                    allHazards={allHazards} 
                                    riskAssessmentDetails={riskAssessmentDetails} 
                                    id={hazardId} 
                                    scoreSelectors={scoreSelectors}
                                />
                            </>}
                        </Box>
                        {((index === 0 && defaultOpen) || openHazards?.includes(hazardId)) && <>
                            <Divider className={classes.divider} />
                            <Box marginBottom={2} display='flex' alignItems='center' justifyContent='center'>
                                <Typography variant='body2'>
                                    Review and add controls to mitigate the risk:
                                </Typography>
                            </Box>
                            {allHazards[hazardId]?.hazardControls?.map(hazardControl => (
                                <Box className={classes.control} display='flex' alignItems='center' flexDirection='row' key={hazardControl?.id}> 
                                    <Grid container>
                                        <Grid item xs={12} md={9}>
                                            <Typography variant='subtitle2'>
                                                {hazardControl?.control?.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box display='flex' gridColumnGap={10} alignItems='center' justifyContent='flex-end'>
                                                <div>
                                                    <Typography variant='subtitle2' className={classes.text2}>Control in place?</Typography>
                                                    <RadioGroup onChange={(e) => toggleHazardControl(e.target.value, hazardId, hazardControl?.id, isCustomHazard)}>
                                                        <FormControlLabel labelPlacement="end" checked={hazardControl?.inPlace != undefined ? hazardControl?.inPlace : false} value={true} control={<Radio />} label='Yes' />
                                                        <FormControlLabel labelPlacement="end" checked={hazardControl?.inPlace != undefined ? !hazardControl?.inPlace : false} value={false} control={<Radio />} label='No' />
                                                    </RadioGroup>
                                                </div>
                                                <IconButton
                                                    onClick={(e) => handleAdminClick(e, hazardControl?.id, isCustomHazard)}
                                                >
                                                    <MoreVert />
                                                </IconButton>
                                                <Menu
                                                    keepMounted
                                                    open={isMenuOpen === hazardControl.id}
                                                    onClose={handleAdminClose}
                                                    anchorEl={anchorEl}
                                                >
                                                    <MenuItem
                                                        onClick={(e) => openEditControlPopup(e, hazardControl, hazardId)}
                                                    >
                                                        <ListItemIcon className={classes.menuItemIcon} >
                                                            <EditIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <Typography className={classes.menuItemText}>
                                                            Edit
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem className={classes.menuDelete}
                                                        onClick={(e) => openDeleteControlPopup(e, hazardControl, hazardId)}
                                                    >
                                                        <ListItemIcon className={classes.menuItemIconDel}  >
                                                            <DeleteIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <Typography className={classes.menuItemText}>
                                                            Delete
                                                        </Typography>
                                                    </MenuItem>
                                                </Menu>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}

                            {allHazards[hazardId]?.customHazardControls?.map(hazardControl => {
                                return (
                                    <Box className={classes.control} display='flex' alignItems='center' flexDirection='row' key={hazardControl?.id}>
                                        <Grid container>
                                            <Grid item xs={12} md={9}>
                                                <Typography variant='subtitle2'>
                                                    {hazardControl?.control?.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Box display='flex' gridColumnGap={10} alignItems='center' justifyContent='flex-end'>
                                                    <div>
                                                        <Typography variant='subtitle2' className={classes.text2}>Control in place?</Typography>
                                                        <RadioGroup onChange={(e) => toggleHazardControl(e.target.value, hazardId, hazardControl?.id, isCustomHazard, hazardControl.isCustom)}>
                                                            <FormControlLabel labelPlacement="end" checked={hazardControl?.inPlace != undefined ? hazardControl?.inPlace : false} value={true} control={<Radio />} label='Yes' />
                                                            <FormControlLabel labelPlacement="end" checked={hazardControl?.inPlace != undefined ? !hazardControl?.inPlace : false} value={false} control={<Radio />} label='No' />
                                                        </RadioGroup>
                                                    </div>
                                                    <IconButton
                                                        onClick={(e) => handleAdminClick(e, hazardControl?.id, isCustomHazard)}
                                                    >
                                                        <MoreVert />
                                                    </IconButton>
                                                    <Menu
                                                        keepMounted
                                                        open={isMenuOpen === hazardControl.id}
                                                        onClose={handleAdminClose}
                                                        anchorEl={anchorEl}
                                                    >

                                                        <MenuItem
                                                            onClick={(e) => openEditControlPopup(e, hazardControl, Number(hazardId))}
                                                        >
                                                            <ListItemIcon className={classes.menuItemIcon} >
                                                                <EditIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            <Typography className={classes.menuItemText}>
                                                                Edit
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem className={classes.menuDelete}
                                                            onClick={(e) => openDeleteControlPopup(e, hazardControl, Number(hazardId))}
                                                        >
                                                            <ListItemIcon className={classes.menuItemIconDel}  >
                                                                <DeleteIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            <Typography className={classes.menuItemText}>
                                                                Delete
                                                            </Typography>
                                                        </MenuItem>
                                                    </Menu>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                );
                            })}

                            {newControlFieldVisible === hazardId && <div className={classes.customControl}>
                                <TextField 
                                    variant="outlined"
                                    size='small'
                                    label='Control description'
                                    fullWidth
                                    multiline
                                    value={newCustomControl}
                                    onChange={(e) => setNewCustomControl(e.target.value)}
                                    rows={2}
                                />
                                <Box display='flex'>
                                    <IconButton onClick={() => updateControl(hazardId, null, isCustomHazard)}>
                                        <Save />
                                    </IconButton>
                                    <IconButton onClick={resetCustomControl}>
                                        <Cancel />
                                    </IconButton>
                                </Box>
                            </div>}
                            {!isEditMode &&
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    disabled={newControlFieldVisible}
                                    color="secondary"
                                    onClick={() => setNewControlFieldVisible(hazardId)}
                                >
                                    Add Custom Control
                                </Button>
                            }
                            <Divider className={classes.divider2} />
                            <Box display='flex' flexDirection='column' alignItems='center'>
                                <Typography variant='body2'>
                                    After the controls are in place, evaluate the severity of the hazard and likelihood:
                                </Typography>
                                <QuestionMapper                                     
                                    allHazards={allHazards} 
                                    riskAssessmentDetails={riskAssessmentDetails} 
                                    id={hazardId} 
                                    scoreSelectors={scoreSelectors}
                                    postControl 
                                />
                            </Box>
                        </>}
                    </div>
                )
            })}
        </div>
        <HazardControlAmendDialog
            isOpen={isEditOpen}
            setIsOpen={setIsEditOpen}
            hazardControl={hazardControlToAmend}
            updateControl={handleUpdateControl}
        />
        <HazardControlDeleteDialog
            isOpen={isDeleteOpen}
            setIsOpen={setIsDeleteOpen}
            hazardControl={hazardControlToAmend}
            updateControl={handleRemoveControl}
        />        
        </>
    )
}