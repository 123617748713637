const appName = "myworknest";
const safetynestAppName = "safetynest";
const legalAppName = "legal";

const events = {
    pageView: "pageView",
    featureTileClick: "featureTileClick",
    downloadFile: "downloadFile",
    viewPrecedent: "viewPrecedent",
    downloadPrecedent: "downloadPrecedent"
};

const trackingConstants = {
    appName,
    safetynestAppName,
    legalAppName,
    events,
};

export default trackingConstants;
