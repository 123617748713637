import React, { useState } from "react";
import CardBase from "../../components/cards/CardBase";
import useAccountConsultants from "../hooks/queries/useAccountConsultants";
import Avatar from "../../components/Avatar";
import {
  Button,
  Link,
  makeStyles,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useSelector } from "react-redux";
import { Face } from "@material-ui/icons";
import { AvatarGroup } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(3),
  },
  groupAvatar: {
    flex: "1 1 auto",
  },
  profilePhoto: {
    marginBottom: theme.spacing(1),
    maxWidth: "100px",
    width: "100%",
    height: "auto",
    border: "none",
    outline: `2px solid ${theme.palette.primary.contrastText}`,
    cursor: "pointer",
  },
  selectedConsultant: {
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}, 0 0 0 4px ${theme.palette.primary.contrastText}`,
    outline: "none",
  },
  emptyFace: {
    color: grey[300],
    fontSize: "96px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  name: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
  },
  description: {
    color: grey[500],
    marginBottom: theme.spacing(1),
  },
  contactInfoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  contactInfo: {
    padding: 0,
    color: theme.palette.primary.light,
    "&:hover": {
      background: "none",
      textDecoration: "none",
    },
  },
}));

function ErrorComponent() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Face className={classes.emptyFace} />
      <Typography className={classes.description}>
        No consultant assigned to this site.
      </Typography>
    </div>
  );
}

function ConsultantCard() {
  const classes = useStyles();
  const { selectedSite, activeAppId, appSiteIds } = useSelector(
    (state) => state.account
  );
  const [selectedConsultant, setSelectedConsultant] = useState();
  const { data, isLoading } = useAccountConsultants({
    selectedExternalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
      allExternalIds: appSiteIds[activeAppId],
  }, { onSuccess: onQuerySuccess });

  function onQuerySuccess (data) {
    if (data && data.length > 1) setSelectedConsultant(data[0]);
  };

  const getConsultantUI = () => {
    if (!data) return null;

    if (data.length === 0) return <ErrorComponent />;

    let sortData = !selectedConsultant
      ? data
      : [
          selectedConsultant,
          ...data.filter((item) => item.id !== selectedConsultant.id),
        ];

    return (
      <div className={classes.root}>
        {sortData.length === 1 ? (
          <Avatar
            className={classes.profilePhoto}
            size="md"
            src={sortData[0].photoUrl}
          />
        ) : (
          <AvatarGroup
            max={3}
            total={sortData.length}
            spacing="small"
            classes={{ avatar: classes.groupAvatar }}
          >
            {sortData.map((consultant) => (
              <Avatar
                key={consultant.id}
                className={
                  selectedConsultant?.id === consultant.id
                    ? `${classes.profilePhoto} ${classes.selectedConsultant}`
                    : classes.profilePhoto
                }
                src={consultant.photoUrl}
                onClick={() => setSelectedConsultant(consultant)}
              />
            ))}
          </AvatarGroup>
        )}
        <Typography
          className={classes.name}
        >{`${sortData[0].firstName} ${sortData[0].lastName}`}</Typography>
        <Typography className={classes.description}>
          {sortData[0].consultantDescription}
        </Typography>
        <div className={classes.contactInfoContainer}>
          <Button
            className={classes.contactInfo}
            component={Link}
            href={`mailto:${sortData[0]?.email}`}
          >
            {sortData[0]?.email}
          </Button>
          <Tooltip title="Copy to clipboard" placement="right">
            <Button
              className={classes.contactInfo}
              component={Link}
              href={`tel:${sortData[0]?.phone}`}
              onClick={() => {
                navigator.clipboard.writeText(sortData[0]?.phone);
              }}
            >
              {sortData[0]?.phone}
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };
  return (
    <CardBase title="Consultant Details" isLoading={isLoading}>
      {getConsultantUI()}
    </CardBase>
  );
}

export default ConsultantCard;
