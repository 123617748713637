import { Button, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, makeStyles } from "@material-ui/core"
import { useSiteSearchContext } from "../../contexts/SiteSearchContext"
import { formatShortDate } from '../../../utils/dateTimeUtils'
import { useState } from "react"
import useSiteMeridianAudits from "../../hooks/queries/useSiteMeridianAudits"
import useAddAuditActionFile from "../../hooks/mutations/useAddAuditActionFile";
import { useSnackbar } from "notistack"
import useReplaceAuditPDF from "../../hooks/mutations/useReplaceAuditPDF"

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none'
    },
    Pdfbutton: {
        marginLeft: theme.spacing(2),
    },

}))

const tableColumns = [
    'Audit ID',
    'Created Date',
    'Last Modified Date',
    'Start Date',
    'End Date',
    'Audit Type',
    'Description',
    '',
]

export default function MeridianAuditsTable() {
    const classes = useStyles()
    const addAuditActionFile = useAddAuditActionFile();
    const { enqueueSnackbar } = useSnackbar()

    const replaceAuditPdf = useReplaceAuditPDF()

    const { selectedSite } = useSiteSearchContext()

    const [pageSize, setPageSize] = useState(5);
    const [pageNum, setPageNum] = useState(0);

    function handleChangePage(_e, newPage) {
        setPageNum(newPage)
    }

    function handleChangeRowsPerPage(e) {
        setPageNum(0)
        setPageSize(parseInt(e.target.value, 10));
    }

    const meridianAudits = useSiteMeridianAudits({
        siteExternalId: selectedSite?.externalId ?? '',
        pageNum,
        pageSize,
    })

    const handleChangeFile = async (e, auditId) => {
        const files = e.target.files

        if (files && files[0].type !== 'application/pdf') {
            enqueueSnackbar('Please select a PDF file only', { variant: 'error' })
            return
        }
    
        if (files && files.length) {          
            replaceAuditPdf.mutate(
                { 
                    auditId, file: files[0] 
                },
                {
                    onSuccess: () => 
                        enqueueSnackbar('PDF replaced successfully', { variant: 'success' }),
                    onError: () =>
                        enqueueSnackbar('PDF replacement failed', { variant: 'error' })
                }
            )
        }
    }

    const handleCSVUpload = async (newFile, auditId, thirdPartyIdentifier) => {
        if (newFile.target.files[0]) {
            const formData = new FormData();
            formData.append("file", newFile.target.files[0]);
            formData.append("fileName", newFile.target.files[0].name);
            formData.append("siteExternalId", selectedSite?.externalId);
            if (newFile.target.files[0].type !== 'text/csv' && (thirdPartyIdentifier === 'MFS' || thirdPartyIdentifier === 'ESSAFETY')) 
                enqueueSnackbar('Please select a csv file only', { variant: 'error' })
            else if (newFile.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && thirdPartyIdentifier === 'FRA')
                enqueueSnackbar('Please select a xslx file only', { variant: 'error' })

            else {
                try {
                    addAuditActionFile.mutate(
                        { formData, siteExternalId: selectedSite?.externalId, auditId }, 
                        {
                            onError: (e) =>
                                enqueueSnackbar('Actions not created.  Ensure file content is as expected', { variant: 'error' }),
                            onSuccess: () =>
                                enqueueSnackbar('Actions created successfully', { variant: 'success' }),
                        }
                    );
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };

    function tableContent() {
        if (meridianAudits?.isLoading) {
            return (
                <TableRow>
                    <TableCell colSpan={tableColumns.length}>
                        Loading...
                    </TableCell>
                </TableRow>
            )
        }

        if (meridianAudits?.data?.results?.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={tableColumns.length}>
                        No audits found.
                    </TableCell>
                </TableRow>
            )
        }

        if (meridianAudits?.data?.results?.length > 0) {
            return meridianAudits.data.results.map(audit => (
                <TableRow key={audit.id}>
                    <TableCell>
                        {audit.id}
                    </TableCell>
                    <TableCell>
                        {formatShortDate(audit.createdDate)}
                    </TableCell>
                    <TableCell>
                        {formatShortDate(audit.lastModifiedDate)}
                    </TableCell>
                    <TableCell>
                        {formatShortDate(audit.startDate)}
                    </TableCell>
                    <TableCell>
                        {formatShortDate(audit.endDate)}
                    </TableCell>
                    <TableCell>
                        {audit.typeDescription}
                    </TableCell>
                    <TableCell>
                        {audit.description}
                    </TableCell>
                    <TableCell align="right">
                        <>
                        {audit.completedActionsSummary === '' && !audit.isMeridianAudit ? (
                            <Button
                                variant="outlined"
                                color="primary"
                                component="label"
                            >
                                    <input
                                        type="file"
                                        className={classes.input}
                                        accept={(audit.thirdPartyIdentifier === "MFS" || audit.thirdPartyIdentifier === "ESSAFETY") ? "text/csv" :
                                            audit.thirdPartyIdentifier === "FRA" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : ""}
                                        onChange={(e) => handleCSVUpload(e, audit.id, audit.thirdPartyIdentifier)}
                                />
                                    Upload {audit.thirdPartyIdentifier} Actions
                            </Button>
                        ) : audit.completedActionsSummary}
                        <Button variant="contained" color="primary" component="label" className={classes.Pdfbutton}>
                            <input
                                type="file"
                                className={classes.input}
                                accept="application/pdf"
                                onChange={(e) => handleChangeFile(e, audit.id)}
                            />
                            Replace PDF
                            </Button>
                            </>
                    </TableCell>
                </TableRow>
            ))
        }
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableColumns.map(column => (
                            <TableCell key={column}>
                                {column}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableContent()}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            count={meridianAudits?.data?.totalCount ?? 0}
                            rowsPerPage={pageSize}
                            page={pageNum}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}