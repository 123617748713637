import {
  makeStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import CardBase from "../../../components/cards/CardBase";
import sortOrder, { sortOrderName } from "../../constants/sortOrder";
import useSiteBreakdownSafetyNestScore from "../../hooks/queries/useSiteBreakdownSafetyNestScore";
import moduleAreas, {
  moduleAreaNames,
} from "../../constants/moduleAreaConstants";
import { getComparator, stableSort } from "../../../utils/tableUtils";
import TrendIndicator from "./TrendIndicator";
import { scoreTrend } from "../../constants/safetynestScoreConstants";
import safetyNestScoreService from "../../services/safetyNestScoreService";
import { GetApp } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";
import { format } from "date-fns";

const OVERALL = "OverallScore";

const useStyles = makeStyles((theme) => ({
  scoreCellContent: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    },
    csvLink: {
        visibility: 'hidden'
    },
}));

const ScoreCellContent = ({ score, trend, difference }) => {
  const classes = useStyles();
  return (
    <div className={classes.scoreCellContent}>
      {score}
      {trend !== scoreTrend.MAINTAINING && (
        <TrendIndicator trend={trend} difference={difference} size="small" />
      )}
    </div>
  );
};

const csvHeaders = [
    { label: 'Site', key: 'site' },
    { label: 'Audit', key: 'auditScoreText' },
    { label: 'Compliance', key: 'complianceScoreText' },
    { label: 'Monitoring', key: 'monitoringScoreText' },
    { label: 'Incidents', key: 'incidentsScoreText' },
    { label: 'Risk Assessments', key: 'riskAssessmentScoreText' },
    { label: 'Overall', key: 'overallScoreText' },
]

export default function SafetyNestScoreTable() {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(5);
  const [sortBy, setSortBy] = useState(OVERALL);
  const [orderBy, setOrderBy] = useState(sortOrderName.DESC);
  const [exportData, setExportData] = useState([]);
  const csvExportLinkRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { selectedSite, activeAppId, appSiteIds, sites, selectedAccount } = useSelector(
    (state) => state.account
  );

  const siteBreakdownScore = useSiteBreakdownSafetyNestScore({
    externalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
    page,
    rows,
    sortBy:
      sortBy === OVERALL
        ? null
        : moduleAreas[
            Object.keys(moduleAreaNames).find(
              (key) => moduleAreaNames[key] === sortBy
            )
          ],
    orderBy: orderBy === sortOrderName.DESC ? sortOrder.DESC : sortOrder.ASC,
  });


  const onSortClick = (module) => {
    const isAsc = sortBy === module && orderBy === sortOrderName.ASC;
    setOrderBy(isAsc ? sortOrderName.DESC : sortOrderName.ASC);
    setSortBy(module);
    siteBreakdownScore.refetch();
    };

    const handleClickExport = async () => {
        try {
            let scoreDownload = await safetyNestScoreService.downloadSafetyNestScoreForSite({
                externalIds: selectedSite
                    ? [selectedSite.externalId]
                    : appSiteIds[activeAppId]
            });

            setExportData(scoreDownload.map(form => ({
                ...form,
                site: sites[form.siteExternalId]?.name ?? "Unknown",
            })));

            setTimeout(() => {
                csvExportLinkRef.current.link.click();
            });
        }
        catch (e) {
            console.error(e);
            enqueueSnackbar(
                "Could not download form content",
                {
                    variant: "error",
                }
            );
        }
    }

  return (
      <CardBase
          title="Breakdown by Site"
          rightComponent={
                  <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={handleClickExport}
                  >
                      <GetApp /> Download
                  </Button>
          }
      >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Site</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === moduleAreaNames.AUDIT}
                  direction={orderBy}
                  onClick={() => onSortClick(moduleAreaNames.AUDIT)}
                >
                  Audit
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === moduleAreaNames.COMPLIANCE}
                  direction={orderBy}
                  onClick={() => onSortClick(moduleAreaNames.COMPLIANCE)}
                >
                  Compliance
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === moduleAreaNames.MONITORING}
                  direction={orderBy}
                  onClick={() => onSortClick(moduleAreaNames.MONITORING)}
                >
                  Monitoring
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === moduleAreaNames.INCIDENTS}
                  direction={orderBy}
                  onClick={() => onSortClick(moduleAreaNames.INCIDENTS)}
                >
                  Incidents
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === moduleAreaNames.RISKASSESSMENTS}
                  direction={orderBy}
                  onClick={() => onSortClick(moduleAreaNames.RISKASSESSMENTS)}
                >
                  Risk Assessment
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === OVERALL}
                  direction={orderBy}
                  onClick={() => onSortClick(OVERALL)}
                >
                  Overall
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {siteBreakdownScore?.data?.results?.length === 0 && (
              <TableRow>
                <TableCell colSpan={7}>
                  No SafetyNest Score data found for selected site(s).
                </TableCell>
              </TableRow>
            )}
            {siteBreakdownScore?.data?.results &&
              stableSort(
                siteBreakdownScore.data.results,
                getComparator(orderBy, sortBy)
              ).map((siteScore) => (
                <TableRow key={siteScore.siteExternalId}>
                  <TableCell>
                    {sites[siteScore.siteExternalId]?.name ?? "Unknown"}
                  </TableCell>
                  <TableCell
                    sortDirection={sortBy === moduleAreaNames.AUDIT && orderBy}
                  >
                    <ScoreCellContent
                      score={siteScore.auditScoreText}
                      trend={siteScore.auditTrend}
                      difference={siteScore.auditScoreDifferenceText}
                    />
                  </TableCell>
                  <TableCell
                    sortDirection={
                      sortBy === moduleAreaNames.COMPLIANCE && orderBy
                    }
                  >
                    <ScoreCellContent
                      score={siteScore.complianceScoreText}
                      trend={siteScore.complianceTrend}
                      difference={siteScore.complianceScoreDifferenceText}
                    />
                  </TableCell>
                  <TableCell
                    sortDirection={
                      sortBy === moduleAreaNames.MONITORING && orderBy
                    }
                  >
                    <ScoreCellContent
                      score={siteScore.monitoringScoreText}
                      trend={siteScore.monitoringTrend}
                      difference={siteScore.monitoringScoreDifferenceText}
                    />
                  </TableCell>
                  <TableCell
                    sortDirection={
                      sortBy === moduleAreaNames.INCIDENTS && orderBy
                    }
                  >
                    <ScoreCellContent
                      score={siteScore.incidentsScoreText}
                      trend={siteScore.incidentsTrend}
                      difference={siteScore.incidentsScoreDifferenceText}
                    />
                  </TableCell>
                  <TableCell
                    sortDirection={
                      sortBy === moduleAreaNames.RISKASSESSMENTS && orderBy
                    }
                  >
                    <ScoreCellContent
                      score={siteScore.riskAssessmentScoreText}
                      trend={siteScore.riskAssessmentTrend}
                      difference={siteScore.riskAssessmentScoreDifferenceText}
                    />
                  </TableCell>
                  <TableCell sortDirection={sortBy === OVERALL && orderBy}>
                    <ScoreCellContent
                      score={siteScore.overallScoreText}
                      trend={siteScore.overallTrend}
                      difference={siteScore.overallScoreDifferenceText}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={siteBreakdownScore?.data?.totalCount}
          rowsPerPage={rows}
          page={page}
          onChangePage={(_e, newPage) => setPage(newPage)}
          onChangeRowsPerPage={(e) => setRows(parseInt(e.target.value, 10))}
        />
          </TableContainer>
          <CSVLink
              className={classes.csvLink}
              ref={csvExportLinkRef}
              headers={csvHeaders}
              data={exportData}
              filename={`${selectedSite ? [selectedSite.name] : [selectedAccount.account.name]}${format(new Date(), "dd/MM/yy")}.csv`}
          />
    </CardBase>
  );
}
