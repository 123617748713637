import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Chip,
  Typography,
  makeStyles,
  LinearProgress,
  Button,
} from "@material-ui/core";
import DropBoxContainer from "./DropBoxContainer";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  chip: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  progressBar: {
    width: "300px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const DocumentUploader = ({ documentType, addDocuments }) => {
  const [files, setFiles] = useState([]);
  const { selectedFolder } = useSelector((state) => state.documents);

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles = acceptedFiles.filter(
        (file) =>
          !files
            .map((f) => f.lastModified + f.name)
            .includes(file.lastModified + file.name)
      );
      setFiles((f) => [...f, ...acceptedFiles]);
    },
    [files]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    disabled: addDocuments.isLoading,
  });

  const handleDeleteFile = (file) =>
    setFiles(
      files.filter(
        (f) => f.lastModified + f.name !== file.lastModified + file.name
      )
    );

  const handleSubmit = () => {
    addDocuments.mutate(
      {
        documentType: documentType.value,
        files,
        selectedFolder,
      },
      {
        onError: (e) =>
          enqueueSnackbar(
            e?.message || "There was an error uploading the file",
            {
              variant: "error",
            }
          ),
      }
    );
  };

  useEffect(() => {
    if (!addDocuments.isLoading) setFiles([]);
  }, [addDocuments.isLoading, selectedFolder]);

  return (
    <Box display="flex" alignItems="center" flexDirection="column" m={2}>
      <DropBoxContainer
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        isDragAccept={isDragAccept}
        isDragActive={isDragActive}
        isDragReject={isDragReject}
      />
      <Box display="flex" flexWrap="wrap">
        {files.map((file) => (
          <Box my={1} mr={1} key={file.lastModified + file.name}>
            <Chip
              className={classes.chip}
              label={<Typography variant="body2">{file.name}</Typography>}
              onDelete={() => handleDeleteFile(file)}
              disabled={addDocuments.isLoading}
            />
          </Box>
        ))}
      </Box>
      {addDocuments.isLoading && (
        <LinearProgress className={classes.progressBar} />
      )}
      {files.length > 0 && !addDocuments.isLoading && (
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Upload
        </Button>
      )}
    </Box>
  );
};

export default DocumentUploader;
