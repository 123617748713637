import { useMutation } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import { historicActionService } from "../../services/historicActionService";
import reactQueryUtils from "../../../utils/reactQueryUtils";

const { client } = reactQueryUtils

const useUpdateHistoricAction = () => 
    useMutation(
        (historicAction) => historicActionService.update(historicAction.historicActionId, historicAction),
        {
            onMutate: async ({ historicAuditId, ...historicAction }) => {
                const queryKey = [queryKeys.historicAuditActions, historicAuditId];

                await client.cancelQueries(queryKeys.historicAuditActions);

                const previousHistoricAction = client.getQueryData(queryKeys.historicAuditActions);

                client.setQueryData(queryKeys.historicAuditActions, (old) => ({
                    ...old,
                    ...historicAction,
                }));

                return { previousHistoricAction, queryKey };
            },
            onError: (_err, _newDocument, context) => {
                if (!context) return;
                client.setQueryData(context.queryKey, context.previousHistoricAction);
            },
            onSettled: () => {
                client.invalidateQueries(queryKeys.historicAuditActions);
            },
        }    
    )

export default useUpdateHistoricAction;