import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import incidentService from "../../services/incidentService";

const useIncidentResponses = (incidentId) =>
  useQuery(
    [
      queryKeys.incidents,
      incidentId
    ],
    () => incidentService.fetchIncidentResponsesById(incidentId),
    {
      enabled: !!incidentId,
    }
  );

export default useIncidentResponses;