import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatShortMonthDate } from "../../utils/dateTimeUtils";

const useStyles = makeStyles((theme) => ({
    grid: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
        },
    },
    content: {
        paddingRight: theme.spacing(6),
        "& section": {
            scrollMarginTop: "90px",
            marginBottom: theme.spacing(4),
        },
        "& table, & th, & td": {
            border: `1px solid ${theme.palette.grey[300]}`,
        },
        "& table": {
            borderCollapse: "collapse",
            width: "100%",
        },
        "& th, & td": {
            padding: theme.spacing(1),
        },
        "& th": {
            textAlign: "left",
            backgroundColor: theme.palette.grey[100],
        },
        [theme.breakpoints.down("sm")]: {
            paddingRight: 0,
        },
    },
    title: {
        fontSize: "2.5rem",
        marginBottom: 0,
    },
    lastUpdated: {
        color: theme.palette.grey[600],
    },
    navItems: {
        listStyle: "none",
        margin: 0,
        padding: 0,
    },
    navItem: {
        marginBottom: theme.spacing(1),
    },
    navLink: {
        color: theme.palette.secondary.main,
        fontWeight: 500,
        textDecoration: "none",
    },
}));

function TrustCentreArticle({ title, lastUpdatedDate, contentsNavItems = null, children }) {
    const classes = useStyles();
    const articleWidth = contentsNavItems ? 9 : 12;
    return (
        <Container>
            <Grid container className={classes.grid}>
                <Grid item xs={12} md={articleWidth}>
                    <div className={classes.content}>
                        <article>
                            <h1 className={classes.title}>{title}</h1>
                            <p className={classes.lastUpdated}>Last updated: {formatShortMonthDate(lastUpdatedDate)}</p>

                            {children}
                        </article>
                    </div>
                </Grid>
                {contentsNavItems && (
                    <Grid item xs={12} md={3}>
                        <aside>
                            <h2>Contents</h2>
                            <nav>
                                <ul className={classes.navItems}>
                                    {contentsNavItems.map((item) => (
                                        <li key={item.id} className={classes.navItem}>
                                            <a className={classes.navLink} href={`#${item.id}`}>
                                                {item.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </aside>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}

export default TrustCentreArticle;
