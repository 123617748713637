import { createClient } from '@prismicio/client'

const prismicDetails = {
    employmentlaw: {
        apiUrl: "https://ewzeus.cdn.prismic.io/api/v2",
        accessToken: "MC5ZSEJnOEJBQUFGQzBfQ3pm.77-9EyLvv71G77-9QO-_vUYzWkLvv73vv71HDiTvv73vv73vv73vv73vv71E77-977-9HArvv73vv70o77-9Wg",
    },
    healthandsafety: {
        apiUrl: "https://ewzeus-health.cdn.prismic.io/api/v2",
        accessToken: "MC5ZSFd5a0JFQUFDWUE2RjdM.elPvv71MM--_vVDvv73vv71777-9J--_ve-_vU_vv73vv73vv70_Xe-_ve-_ve-_ve-_ve-_ve-_ve-_vXjvv71z77-9Sw",
    },
    leiTerms: {
        apiUrl: "https://ewpublic.cdn.prismic.io/api",
        accessToken: "MC5YUEVET0JBQUFQRlotODh2.77-9S--_ve-_ve-_vQZceBdc77-977-9Yu-_vQjvv73vv73vv71-77-977-977-9TUXvv71Z77-977-9ZO-_vSF6",
    },
};

const getApiForSection = async (sectionName) => {
    const repoDetails = prismicDetails[sectionName];

    if (!repoDetails)
        throw new Error(`Could not find repo details for: ${sectionName}`);

    const { apiUrl, accessToken } = repoDetails;
    const prismicApi = await createClient(apiUrl, { accessToken });

    return prismicApi;
};

const prismicUtils = {
    getApiForSection,
};

export default prismicUtils;
