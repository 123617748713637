import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import historicAuditService from "../../services/historicAuditService";

const useSiteHistoricAudits = ({ siteExternalId, pageNum, pageSize }) => 
    useQuery(
        [queryKeys.siteHistoricAudits, siteExternalId, pageNum, pageSize],
        () => historicAuditService.getBySiteExternalId({ siteExternalId, pageNum, pageSize }),
        {
            enabled: !!siteExternalId,
        }
    );

export default useSiteHistoricAudits;