import React from "react";
import { RichText, Elements } from "prismic-reactjs";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import usePrismicItem from "../hooks/queries/usePrismicItem";
import { useTracking } from "react-tracking";
import PageBase from "../components/PageBase";
import useIsMobile from "../hooks/useIsMobile";

const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

const htmlSerializer = function (type, element, children, key) {
  var props = {};
  switch (type) {
    case Elements.hyperlink:
      const targetAttr = element.data.target
        ? { target: element.data.target }
        : {};
      const relAttr = element.data.target ? { rel: "noopener" } : {};
      props = Object.assign(
        {
          className: "link-class",
          href: element.data.value.file.url,
        },
        targetAttr,
        relAttr
      );
      return React.createElement("a", propsWithUniqueKey(props, key), children);
    default:
      return null;
  }
};

function LeiTerms() {
  const { Track } = useTracking({
    page: `LEITerms`,
  });
  const { selectedAccount } = useSelector((state) => state.account);
  const isMobile = useIsMobile();

  const { data } = usePrismicItem({
    sectionName: "leiTerms",
    itemId: "XS3bdRAAACQAEocj",
  });

  return (
    <PageBase>
      <Track>
        <React.Fragment>
          <Box>
            <Box flexGrow={1} pl={isMobile ? 0 : 10}>
              <Typography variant="h4" mb={6}>
                Insurance Declaration
              </Typography>
            </Box>
            <Box flexGrow={1} pl={isMobile ? 0 : 10}>
              <Typography variant="h5" mb={6}>
                {selectedAccount && selectedAccount.name}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              mt={5}
              mb={2}
              mx={isMobile ? 0 : 5}
              px={isMobile ? 0 : 15}
            >
              {data && data.title && RichText.render(data.title)}
              <div>
                {data && data.bodyContent && (
                  <RichText
                    render={data.bodyContent}
                    htmlSerializer={htmlSerializer}
                  />
                )}
              </div>
            </Box>
          </Box>
        </React.Fragment>
      </Track>
    </PageBase>
  );
}

export default LeiTerms;
