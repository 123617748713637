import { useQuery } from "react-query";
import prismicContentTypes from "../../constants/prismicContentTypes";
import queryKeys from "../../constants/queryKeys";
import prismicService from "../../services/prismicService";
import reactQueryUtils from "../../utils/reactQueryUtils";

const queryKeysMap = {
  [prismicContentTypes.article]: queryKeys.prismicCategoryArticles,
  [prismicContentTypes.webinar]: queryKeys.prismicCategoryWebinars,
  [prismicContentTypes.precedent]: queryKeys.prismicCategoryPrecedents,
};

const usePrismicContentItem = (
  sectionName,
  itemId,
  itemType,
  categoryId,
  cachePage
) =>
  useQuery(
    [queryKeys.prismicContentItem, itemId],
    () => prismicService.fetchContentItemById(sectionName, itemId),
    {
      enabled: !!itemId,
      initialData: () => {
        const { client } = reactQueryUtils;
        const cacheKey = [
          queryKeysMap[itemType],
          categoryId,
          parseInt(cachePage),
        ];

        const existingItem = client
          .getQueryData(cacheKey)
          ?.results.find((x) => x.id === itemId);

        return existingItem;
      },
    }
  );

export default usePrismicContentItem;
