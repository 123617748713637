import axios from "../utils/axios";
import stringUtils from "../utils/stringUtils";

const tileEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/tile`;

const fetchTilesForAccounts = (accountExternalIds) => {
  const queryString = stringUtils.buildQueryStringList(
    "externalIds",
    accountExternalIds
  );
  return axios.get(`${tileEndpointUrl}/accounts${queryString}`);
};

function fetchQuickNavigation(accountExternalIds) {
  const queryString = stringUtils.buildQueryStringList(
    "externalIds",
    accountExternalIds
  );
  return axios.get(
    `${tileEndpointUrl}/accounts/quicknav/${process.env.REACT_APP_MYWORKNEST_APP_ID}/${queryString}`
  );
}

const tileService = {
  fetchTilesForAccounts,
  fetchQuickNavigation,
};

export default tileService;
