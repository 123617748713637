import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { Box, Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CardBase from "../../../../components/cards/CardBase";
import useSitesTemplateForms from "../../../hooks/queries/useSitesTemplateForms";
import useContactsForAppAndSite from "../../../../hooks/useContactsForAppAndSite";
import templateFormService from "../../../services/templateFormService";
import { TemplateFormTable } from "./TemplateFormTable";
import { useAuth } from "../../../../contexts/authContext";
import { capitaliseFirstLetter } from "../../../../utils/stringUtils";
import moduleAreas, {
    moduleUrls,
} from "../../../constants/moduleAreaConstants";
import { tablePage } from "../../../constants/tablePageConstants";
import { GetApp } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import { format, parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import { sortOrderName } from "../../../constants/sortOrder";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  csvLink: {
    visibility: 'hidden'
    },
  downloadBtn: {
      marginRight: theme.spacing(1),
    },
}));

const csvHeaders = [
    { label: 'Title', key: 'templateName' },
    { label: 'Site', key: 'site' },
    { label: 'Responsible', key: 'responsible' },
    { label: 'Due Date', key: 'due' },
    { label: 'Last Completed Actions', key: 'lastCompletedActionsSummary' },
    { label: 'Last Status', key: 'lastStatusDescription' },
    { label: 'Last Completed Date', key: 'completeDate' },
    { label: 'Last Completed By', key: 'lastEmployeeCompletion' },

]

export default function TemplateFormTableCard({ moduleArea }) {
    const { isTemplateAdminForSites } = useAuth();
    const [exportData, setExportData] = useState([]);
    const csvExportLinkRef = useRef();
    const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const match = useRouteMatch();
    const { path } = match;

  const contacts = useContactsForAppAndSite({ selectedSiteId: null });

  const { selectedSite, activeAppId, appSiteIds, selectedAccount, sites } =
    useSelector((state) => state.account);

  const { complianceFormsTable, monitoringFormsTable } = useSelector(
    (state) => state.tablePage
  );

  const isSafetynestAmendTemplateUser = isTemplateAdminForSites(
    selectedAccount?.childExternalIds
  );

  const getModuleArea = (moduleArea) =>
    moduleArea === moduleUrls[moduleAreas.COMPLIANCE]
      ? complianceFormsTable
      : monitoringFormsTable;

  const module = getModuleArea(moduleArea);

  const tableKey =
    moduleArea === moduleUrls[moduleAreas.COMPLIANCE]
      ? tablePage.COMPLIANCE_FORMS_TABLE
      : tablePage.MONITORING_FORMS_TABLE;

  const { data, isLoading, error } = useSitesTemplateForms({
    externalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
    pageSize: module.rowsPerPage,
    pageNum: module.page + 1,
    moduleArea,
    orderByColumn: module.sortBy || csvHeaders[3].key,
    sortOrder: module.orderBy || sortOrderName.ASC,
    fromDate: module.fromDate || null,
    toDate: module.toDate || null,
    filteredText: module.filteredText,
    assignees: module.assignees?.map(option => option.value),
    showHistoric: module.showHistoric,
  });

  function errorBox() {
    return (
      <Box className={classes.loadingContainer}>
        <Alert variant="outlined" severity="error">
          {error.message}
        </Alert>
      </Box>
    );
    }

    const getNameFromUserExternalId = (userExternalId) => {
        if (!contacts?.data[userExternalId]?.firstName || !contacts?.data[userExternalId]?.lastName)
            return "Unknown";

        return `${contacts?.data[userExternalId]?.firstName} ${contacts?.data[userExternalId]?.lastName}`;
    }

    const handleClickExport = async () => {
        try {
            let templateForms = await templateFormService.downloadTemplateFormsForSite({
                siteExternalIds: selectedSite
                    ? [selectedSite.externalId]
                    : appSiteIds[activeAppId],
                moduleArea: moduleArea,
                orderByColumn: module.sortBy || csvHeaders[3].key,
                sortOrder: module.orderBy || sortOrderName.DESC,
                fromDate: module.fromDate || null,
                toDate: module.toDate || null,
                filteredText: module.filteredText,
                assignees: module.assignees?.map(option => option.value),
                showHistoric: module.showHistoric,
            });

            setExportData(templateForms.map(form => ({
                ...form,
                site: sites[form.siteExternalId]?.name ?? "Unknown",
                responsible: getNameFromUserExternalId(form.assignedEmployeeExternalId),
                lastEmployeeCompletion: getNameFromUserExternalId(form.lastAssignedEmployeeExternalId),
                due: format(parseISO(form.dueDate), "dd/MM/yyyy"),
                completeDate: form.lastCompleteDate ? format(parseISO(form.lastCompleteDate), "dd/MM/yyyy") : '',
            })));

            setTimeout(() => {
                csvExportLinkRef.current.link.click();
            });
        }
        catch (e) {
            console.error(e);
            enqueueSnackbar(
                "Could not download form content",
                {
                    variant: "error",
                }
            );
        }
    }

  return (
    <CardBase
      title={`${capitaliseFirstLetter(moduleArea)} Forms`}
      error={error}
      errorComponent={errorBox}
          rightComponent={
              isSafetynestAmendTemplateUser ? (
                  <>
                      <Button className={classes.downloadBtn} variant="outlined" color="primary" onClick={handleClickExport}>
                          <GetApp /> Download
                      </Button>
                      <Button
                          component={Link}
                          to={`${path}/addtemplateform`}
                          variant="contained"
                          color="primary"
                      >
                          Add Form
                      </Button>
                  </>
              ) :
                  <Button className={classes.downloadBtn} variant="outlined" color="primary" onClick={handleClickExport}>
                      <GetApp /> Download
                  </Button>
          }>
          <TemplateFormTable
              rows={module.rowsPerPage}
              page={module.page}
              data={data?.results}
              count={data?.totalCount}
              isLoading={isLoading}
              moduleArea={moduleArea}
              tableKey={tableKey}
              orderBy={module.orderBy}
              sortBy={module.sortBy}
              fromDate={module.fromDate}
              toDate={module.toDate}
              assignees={data?.assignees}
              filteredText={module.filteredText}
              showHistoric={module.showHistoric}
          />
          <CSVLink
              className={classes.csvLink}
              ref={csvExportLinkRef}
              headers={csvHeaders}
              data={exportData}
              filename={`${moduleArea}${format(new Date(), "dd/MM/yy")}.csv`}
          />
    </CardBase>
  );
}
