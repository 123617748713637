import React, { useState, useRef, useMemo } from "react";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
    Button,
    Grid,
    Menu,
    MenuItem,
    makeStyles,
    Checkbox,
    TextField,
} from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import GetAppIcon from '@material-ui/icons/GetApp';
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import CardBase from "../../../components/cards/CardBase";
import { useDispatch, useSelector } from "react-redux";
import { setTablePage, setFormInsightsTablePage } from "../../../redux/actions/tablePageActions";
import { tablePage } from "../../constants/tablePageConstants";
import { sortOrderName } from "../../constants/sortOrder";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import { CSVLink } from "react-csv";
import moduleAreas from "../../constants/moduleAreaConstants";
import useTemplateFormInsights from "../../hooks/queries/useTemplateFormInsights";
import DateFnsUtils from "@date-io/date-fns";
import { isValid, format } from "date-fns";
import { generatePdfFromHtml } from "../../services/pdfGeneratorService";
import { Autocomplete } from "@material-ui/lab";
import {
    CheckBoxOutlineBlank as CheckBoxOutlineBlank,
    CheckBox as CheckBox,
} from "@material-ui/icons";

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    dateFilter: {
        marginRight: theme.spacing(1),
    },
    toolbar: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(0),
    },
    formsSelectedString: {
        maxWidth: "86%",
    },
}))

const TemplateInsightsTableCard = ({ moduleArea, siteId, setSiteId, siteName, sites, title, description }) => {
    const dispatch = useDispatch();
    const csvExportLinkRef = useRef();
    const classes = useStyles();
    const [filteredCurrentForms, setFilteredCurrentForms] = React.useState([]);

    const headCells = useMemo(() => [
        {
            id: siteId ? "formName": "site",
            label: siteId ? "Form" : "Site",
            isSortable: true
        },
        {
            id: "expectedCompletion",
            label: "Expected Completion"
        },
        {
            id: "actualCompletion",
            label: "Actual Completion"
        },
        {
            id: "completionRate",
            label: "Completion Rate",
            isSortable: true
        }
    ], [siteId]);

    const csvHeaders = useMemo(() => [
        { label: siteId ? 'Form' : 'Site', key: siteId ? 'formName' : 'site' },
        { label: 'Expected Completion', key: 'expectedCompletion' },
        { label: 'Actual Completion', key: 'actualCompletion' },
        { label: 'Completion Rate', key: 'completionRate' }
    ], [siteId]);

    const { complianceInsightsTable, monitoringInsightsTable } = useSelector(state => state.tablePage);
    const [menuAnchor, setMenuAnchor] = useState(null);
        
    const [exporting, setExporting] = useState(false);

    const externalIds = useMemo(() => sites?.map(x => x.externalId), [sites]);       

    const tableKey = useMemo(() => moduleArea === moduleAreas.COMPLIANCE ? tablePage.COMPLIANCE_INSIGHTS_TABLE : tablePage.MONITORING_INSIGHTS_TABLE, [moduleArea]);
    
    const tableState = useMemo(() => moduleArea === moduleAreas.COMPLIANCE ? complianceInsightsTable : monitoringInsightsTable, [complianceInsightsTable, moduleArea, monitoringInsightsTable]);

    const insights = useTemplateFormInsights(externalIds, moduleArea, tableState.fromDate, tableState.toDate, tableState.formIds);

    const isFromDateValid = isValid(new Date(tableState.fromDate));
    const isToDateValid = isValid(new Date(tableState.toDate));

    const tableTitle = useMemo(() => (siteId ? "Form Breakdown " : "Site Breakdown"), [siteId]);
    
    const sortedInsights = useMemo(() => {
        if (!insights?.data)
            return [];

        let swap = tableState.orderBy === sortOrderName.ASC ? 1 : -1;

        let sorted;

        if (siteId) {
            let site = insights.data.find(i => i.siteExternalId === siteId);
            
            sorted = site.forms.sort((a, b) => (a[tableState.sortBy] > b[tableState.sortBy] ? 1 : -1) * swap);              
        }
        else {
            sorted = insights.data
                .map(i => ({ ...i, site: siteName(i.siteExternalId) }))
                .sort((a, b) => (a[tableState.sortBy] > b[tableState.sortBy] ? 1 : -1) * swap)
                .slice(tableState.page * tableState.rowsPerPage, tableState.page * tableState.rowsPerPage + tableState.rowsPerPage);
        }
        return sorted;
    }, [insights.data, tableState.orderBy, tableState.sortBy, tableState.page, tableState.rowsPerPage, siteId, siteName]);

    const exportData = useMemo(() => {

        if (!insights.data)
            return [];

        return siteId
            ? insights.data.find(i => i.siteExternalId === siteId).forms
            : insights.data.map(i => ({ ...i, site: siteName(i.siteExternalId) }))
    }, [insights.data, siteId, siteName]);

    const extractedForms = insights.data?.flatMap(item =>
        item.forms.map(({ formId, formName }) => ({ formId, formName }))
    );

    const allFormNames = [...new Set(extractedForms?.map(form => form.formName))].sort();

    const distinctFormNames = allFormNames?.map((formName, index) => ({
        id: index + 1,
        label: formName
    }));
   
    const exportPDF = async () => {
        setMenuAnchor(null);
        setExporting(true);       

        let html = '';

        html += '<figure style="width:100%;display:table;"><img src="https://ewdatastore.blob.core.windows.net/images/worknesths.png"/></figure>'

        html += `<h2>${title}</h2>`;
        if (description)
            html += `<p>${description}</p>`;
        html += `<h6>${tableTitle}</h6>`;       
        html += `<h6>${tableState?.forms?.length > 0 ? "Form(s) Incuded : " + tableState?.forms?.map(l => l.label).join(", ") : "All Forms Included"}</h6>`; 
        html += '<table style="width:"100%">';
        html += '<thead>';
        html += '<tr>';
        csvHeaders.forEach(h => html += `<td>${h.label}</td>`);
        html += '</tr>';
        html += '</thead>';

        exportData.forEach(r => {   

            html += '<tr>';

            csvHeaders.forEach(h => {
                html += `<td>${r[h.key]}</td>`
            });

            html += '</tr>';
        })

        html += '</table>';        

        let href = await generatePdfFromHtml(html);
        
        var link = window.document.createElement("a");
        link.href = href;
        link.setAttribute("target", "_blank");
        link.download = "FormInsights.pdf";
        link.click();

        setExporting(false);
    }

    const exportCSV = () => {
        setMenuAnchor(null);
        setExporting(true);
        
        setTimeout(() => {
            csvExportLinkRef.current.link.click();
            setExporting(false);
        });
    }


    const onFromDateChange = (date) => {
        if (!date || isValid(new Date(date))) {
            const startDate = new Date(date);
            dispatch(
                setFormInsightsTablePage({
                    ...tableState,
                    key: tableKey,
                    fromDate: startDate ? startDate.toISOString() : null,
                    toDate: tableState.toDate
                })
            );
        }
    };

    const onToDateChange = (date) => {
        if (!date || isValid(new Date(date))) {
            const endDate = new Date(date);
            dispatch(
                setFormInsightsTablePage({
                    ...tableState,
                    key: tableKey,
                    toDate: endDate ? endDate.toISOString() : null,
                    fromDate: tableState.fromDate
                })
            );
        }
    };


    const handleRowsPerPageChange = e => {
        dispatch(
            setTablePage({
                ...tableState,
                key: tableKey,
                page: 0,
                rowsPerPage: parseInt(e.target.value),
            })
        );
    };

    const handlePageChange = (_, newPage) => {
        dispatch(
            setTablePage({
                ...tableState,
                key: tableKey,
                page: newPage,
                rowsPerPage: tableState.rowsPerPage,
            })
        );
    };

    const handleSortClick = (_, sortColumn) => {
        const isAsc = tableState.sortBy === sortColumn && tableState.orderBy === sortOrderName.ASC;
        dispatch(
            setTablePage({
                ...tableState,
                key: tableKey,
                page: 0,
                sortBy: sortColumn,
                orderBy: isAsc ? sortOrderName.DESC : sortOrderName.ASC,
            })
        );
    };

    function handleCurrentFormsChange(event, inputs) {
        setFilteredCurrentForms(inputs);
        if (inputs.length == 0) {
            dispatch(
                setTablePage({
                    ...tableState,
                    key: tableKey,
                    page: 0,
                    forms: [],
                    formIds: [],
                }))
        }
    }

    const handleConfirmedFormsChange = (event, inputs) => {
        const actualFormIds = extractedForms?.filter(form => filteredCurrentForms.some(selectedForm => selectedForm.label === form.formName)).map(matchingForm => matchingForm.formId);
        dispatch(
            setTablePage({
                ...tableState,
                key: tableKey,
                page: 0,
                rowsPerPage: tableState.rowsPerPage,
                forms: filteredCurrentForms,
                formIds: actualFormIds,
            })
        );
    }

    const tableContent = () => {
        if (insights.data?.length > 0) {
            return sortedInsights
                .map((i) => (
                    <TableRow key={i.formId || i.siteExternalId} style={{ cursor: siteId ? "inherit" : "pointer" }} onClick={() => !siteId && setSiteId(i.siteExternalId)}>
                        <TableCell>{i.formName || i.site}</TableCell>
                        <TableCell>{i.expectedCompletion}</TableCell>
                        <TableCell>{i.actualCompletion}</TableCell>
                        <TableCell>{i.expectedCompletion > 0 && <LinearProgressWithLabel variant="determinate" value={i.completionRate} />}</TableCell>
                    </TableRow>
                ));
        }

        return (
            <TableRow>
                <TableCell colSpan={headCells.length}>
                    <Typography>No insights are available.</Typography>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <>
            <CardBase
                title={`${tableTitle} ${tableState?.forms?.length > 0 ? " - " + tableState?.forms?.map(l => l.label).join(", ") : " - All Forms Included"}`}
                rightComponent={
                    <div>
                        <Button
                            disabled={exporting}
                            variant="outlined"
                            color="primary"
                            onClick={e => setMenuAnchor(e.currentTarget)}
                        >
                            <GetAppIcon /> Download
                        </Button>
                        <Menu
                            anchorEl={menuAnchor}
                            open={!!menuAnchor}
                            getContentAnchorEl={null}
                            onClose={() => setMenuAnchor(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem onClick={exportCSV}>CSV</MenuItem>
                            <MenuItem onClick={exportPDF}>PDF</MenuItem>
                        </Menu>
                    </div>
                }
                description={
                    siteId
                        ? "Provides a form breakdown that displays the expected number of forms, those already completed, and the overall completion percentage for quick safety assessment."
                        : "Offers a detailed breakdown of submitted forms and overall form completion rates, allowing for a quick assessment of safety"
                }
                isLoading={insights.isLoading}
                isFetching={insights.isFetching}
                error={insights.error}
            >
                <Grid container>
                    <Grid item xs={12} md={6} xl={2} className={classes.dateFilter}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                fullWidth
                                size="small"
                                inputVariant="outlined"
                                label="From date"
                                disabled={!!siteId || !isToDateValid}
                                format="dd/MM/yyyy"
                                value={tableState.fromDate}                                
                                onChange={date => onFromDateChange(date)}
                                maxDate={tableState.toDate}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={6} xl={2} className={classes.dateFilter}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                fullWidth
                                size="small"
                                inputVariant="outlined"
                                label="To date"
                                format="dd/MM/yyyy"
                                disabled={!!siteId || !isFromDateValid}
                                value={tableState.toDate}
                                onChange={value => onToDateChange(format(new Date(value), "yyyy-MM-dd'T'hh:mm:ss"))}
                                minDate={tableState.fromDate}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={6} xl={3} className={classes.toolbar}>
                        <Autocomplete
                            multiple
                            value={filteredCurrentForms && filteredCurrentForms.length > 0 ? filteredCurrentForms :
                                (tableState.forms && tableState.forms.length > 0 ? tableState.forms : [])}
                            disabled={!!siteId}
                            onChange={handleCurrentFormsChange}
                            onBlur={handleConfirmedFormsChange}
                            options={distinctFormNames ?? []}
                            disableCloseOnSelect
                            clearOnBlur
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) => {
                                return option.label === value.label;
                            }}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        className={classes.checkbox}
                                        icon={uncheckedIcon}
                                        checkedIcon={checkedIcon}
                                        checked={selected}
                                    />
                                    {option.label}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={classes.textField}
                                    variant="outlined"
                                    size="small"
                                    label={tableState?.forms?.length === 0 ? "All Forms Included" : "Form Names"}
                                    fullWidth
                                />
                            )}
                            renderTags={selected => {
                                let renderTagsValue = selected
                                    .map(function (elem) {
                                        return elem.label;
                                    })
                                    .join(", ");
                                return (
                                    <Typography className={classes.formsSelectedString}
                                        noWrap={true}
                                    >
                                        {renderTagsValue}
                                    </Typography>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table aria-label="Compliance">
                        <EnhancedTableHead
                            headCells={headCells}
                            sortColumn={tableState.sortBy}
                            sortDirection={tableState.orderBy || sortOrderName.DESC}
                            onRequestSort={handleSortClick}
                        />
                        <TableBody>{tableContent()}</TableBody>
                    </Table>
                </TableContainer>
                {
                    !siteId &&  
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={insights.data ? insights.data.length : 0}
                        rowsPerPage={tableState.rowsPerPage}
                        page={tableState.page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />    
                }               
                <CSVLink
                    ref={csvExportLinkRef}
                    headers={csvHeaders}
                    data={exportData}
                    filename="FormInsights.csv"
                />
            </CardBase>
        </>
    );
}

export default TemplateInsightsTableCard;
