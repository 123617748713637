import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import SectionOptions from "../safetynest/components/templatebuilder/SectionOptions";
import WysiwygEditor from "./WysiwygEditor";

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${grey[400]}`,
    borderRadius: "4px",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  deleteButton: {
    alignSelf: "flex-start",
  },
}));

function TutorialStep({
  content = "",
  onChange,
  stepId,
  index,
  isFirst,
  isLast,
  isOnly,
  onDelete,
}) {
  const classes = useStyles();

  const onContentChange = (content) => {
    onChange({ stepId, content });
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerWrapper}>
        <Typography variant="subtitle1">Step {index + 1}</Typography>
        <SectionOptions
          isFirst={isFirst}
          isLast={isLast}
          isDeleteDisabled={isOnly}
          onDelete={() => onDelete(stepId)}
        />
      </div>
      <WysiwygEditor content={content} onChange={onContentChange} />
    </div>
  );
}

export default TutorialStep;
