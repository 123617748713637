import React, { Fragment } from "react";
import { Button, Divider, makeStyles, TextField } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import SectionQuestion from "./SectionQuestion";
import { useDispatch, useSelector } from "react-redux";
import {
    addQuestion,
    deleteSection,
    insertQuestion,
    reorderSection,
    setSectionText,
} from "../../../redux/actions/templateBuilderActions";
import SectionOptions from "./SectionOptions";
import { AddCircleOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    container: {
        border: `1px solid ${grey[400]}`,
        borderRadius: "4px",
    },
    headerWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: theme.spacing(2),
        padding: theme.spacing(2),
    },
    divider: {
        backgroundColor: grey[400],
    },
    questionsContainer: {
        backgroundColor: grey[50],
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
    addQuestionButton: {
        color: grey[500],
        border: "1px dashed currentColor",
        width: "100%",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
    },
}));

function TemplateSection({
    index,
    sectionId,
    isFirst,
    isLast,
    isOnly,
    subjectId,
    isInvestigation,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { sections } = useSelector((state) => state.templateBuilder);
    const section = sections[sectionId];

    return (
        <div className={classes.container}>
            <div className={classes.headerWrapper}>
                <TextField
                    required
                    name={`section-name-${sectionId}`}
                    id={`section-name-${sectionId}`}
                    label="Section Name"
                    variant="outlined"
                    size="small"
                    value={section.name}
                    onChange={(e) => dispatch(setSectionText({ sectionId: sectionId, text: e.target.value }))}
                />
                <SectionOptions
                    isFirst={isFirst}
                    isLast={isLast}
                    onMoveUp={() =>
                        dispatch(
                            reorderSection({
                                startIndex: index,
                                endIndex: index - 1,
                                isInvestigation,
                            })
                        )
                    }
                    onMoveDown={() =>
                        dispatch(
                            reorderSection({
                                startIndex: index,
                                endIndex: index + 1,
                                isInvestigation,
                            })
                        )
                    }
                    onDelete={() =>
                        dispatch(deleteSection({ sectionId, isInvestigation }))
                    }
                    isDeleteDisabled={isOnly && !isInvestigation}
                />
            </div>
            <Divider className={classes.divider} />
            <div className={classes.questionsContainer}>
                {section.questionIds.map((questionId, questionIndex) => (
                    <Fragment key={questionId}>
                        <SectionQuestion
                            key={questionId}
                            index={questionIndex}
                            questionId={questionId}
                            sectionId={sectionId}
                            isFirst={questionIndex === 0}
                            isLast={questionIndex + 1 === section.questionIds.length}
                            subjectId={subjectId}
                            isInvestigation={isInvestigation}
                        />
                        {questionIndex + 1 === section.questionIds.length ? (
                            <Button
                                color="secondary"
                                variant="outlined"
                                className={classes.addQuestionButton}
                                onClick={() => dispatch(addQuestion(sectionId))}
                                startIcon={<AddCircleOutline />}
                            >
                                Add Question
                            </Button>
                        ) : (
                            <Button
                                color="secondary"
                                variant="outlined"
                                className={classes.addQuestionButton}
                                onClick={() => dispatch(insertQuestion({ sectionId, atIndex: questionIndex + 1 }))}
                                startIcon={<AddCircleOutline />}
                            >
                                Insert Question
                            </Button>
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    );
}

export default TemplateSection;
