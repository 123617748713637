import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import actionService from "../../services/actionService";

const useSiteActionsSummary = ({ externalIds, fromDate, toDate, forUser, moduleArea }) =>
  useQuery(
    [
      queryKeys.currentActionsSummary,
      externalIds?.join(","),
      fromDate,
      toDate,
      forUser,
      moduleArea
    ],
      
    () =>
      actionService.fetchActionsForSiteSummary({
        externalIds,
        fromDate,
        toDate,
        forUser,
        moduleArea
      }),
    {
      enabled: !!externalIds?.length && !!fromDate && !!toDate,
    }
  );

export default useSiteActionsSummary;
