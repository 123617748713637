import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Button,
    Dialog,
    DialogTitle,
    Link,
    makeStyles,
    MenuList,
    MenuItem,
    Typography,
    Grid,
    Paper,
} from "@material-ui/core";

import { useHistory } from "react-router-dom";
import LawyerCard from "../components/welcome/LawyerCard";
import AccountManagerCard from "../components/welcome/AccountManagerCard";
import CxCard from "../components/welcome/CxCard";
import HealthSafetyCard from "../components/welcome/HealthSafetyCard";
import { grey } from "@material-ui/core/colors";
import useSalesforceWelcomePack from "../hooks/queries/useSalesforceWelcomePack";
import { useTracking } from "react-tracking";
import { Facebook, Twitter, LinkedIn, Send, ImportContacts, ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { useAuth } from "../contexts/authContext";
import { useSelector } from "react-redux";
import PageBase from "../components/PageBase";

const useStyles = makeStyles((theme) => ({
    footerContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: grey[100],
        padding: theme.spacing(2),
        borderTop: `1px solid ${grey[300]}`,
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
    },
    footerCopy: {
        fontWeight: 400,
        fontSize: "1.125rem",
    },
    socialIcon: {
        fontSize: 40,
        transition: "all 0.2s ease-out",
        "&:hover": {
            color: theme.palette.primary.dark,
        },
    },
    cardFooter: {
        padding: ".75rem 1.25rem",
        backgroundColor: "rgba(0, 0, 0, 0.03)",
        borderTop: "1px solid rgba(0, 0, 0, 0.125)",
    },
    textCenter: {
        textAlign: "center",
    },
    continueToDash: {
        justifyContent: "center",
    },
    menuLinks: {
        backgroundColor: "white",
    },
    feedbackContainer: {
        border: "2px solid black",
        padding: theme.spacing(0, 2),
        [theme.breakpoints.up("sm")]: {
            margin: theme.spacing(5, 5, 2, 5),
        },
    },
    feedbackTitle: {
        margin: theme.spacing(2, 0),
    },
    accordionDetails: {
        display: "block",
    },
    emailLink: {
        lineBreak: "anywhere",
    },
    list: {
        fontSize: "1rem",
    },
    sideBarContainer: {
        gap: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
}));

const accordionSections = {
    INTRODUCTION: 0,
    KEEP_IN_TOUCH: 1,
    ELEARNING: 2,
    EMPLOYMENT_LAW: 3,
    HEALTH_AND_SAFETY: 4,
    ABSENCE: 5,
    LEI: 6,
    REFERRALS: 7,
    FEEDBACK: 8,
};

function InformationHub() {
    const { user } = useAuth();
    const classes = useStyles();
    const { selectedAccount } = useSelector((state) => state.account);
    const { Track } = useTracking({
        page: `InformationHub`,
    });
    const [expanded, setExpanded] = useState(accordionSections.INTRODUCTION);
    const history = useHistory();
    const [openClientCareDialog, setOpenClientCareDialog] = useState(false);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleCloseClientCareDialog = () => {
        setOpenClientCareDialog(false);
    };

    const handleButtonClick = (section) => {
        if (section != expanded) setExpanded(section);
        else setExpanded(null);
    };

    const { data } = useSalesforceWelcomePack({
        orgId: selectedAccount?.account.externalId,
    });

    const handleGotoDashboard = () => {
        history.push(`/`);
    };

    const accountHsConsultants = data && data.people && data.people.filter((a) => a.role.toLowerCase() == "health & safety consultant");
    const cxPeople = data && data.people && data.people.filter((a) => a.role.toLowerCase() == "client experience coordinator");
    const accountManagers = data && data.people && data.people.filter((a) => a.role.toLowerCase() === "account manager");
    const accountAdvisers = data && data.people && data.people.filter((a) => a.role.toLowerCase() === "employment law adviser");

    return (
        <PageBase>
            <Track>
                <React.Fragment>
                    <Grid container spacing={9}>
                        <Grid item xs={12} md={9}>
                            <div>
                                <Accordion expanded={expanded === accordionSections.INTRODUCTION} onChange={handleChange(accordionSections.INTRODUCTION)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">Introduction</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box>
                                            <Typography className={classes.textCenter} variant="h2" mb={6}>
                                                Welcome to WorkNest
                                            </Typography>

                                            <Box display="flex" justifyContent="space-around" flexWrap="wrap" m={2}>
                                                <Button
                                                    variant="outlined"
                                                    className={classes.textCenter}
                                                    onClick={() => handleGotoDashboard()}
                                                    color="primary"
                                                    endIcon={<Send />}
                                                >
                                                    Continue to Dashboard
                                                </Button>
                                            </Box>
                                            <div>
                                                <Typography gutterBottom>
                                                    Hi {user.details.firstName}, we&lsquo;re delighted youve chosen us to help your organisation stay on the
                                                    right track when it comes to legal compliance.
                                                </Typography>
                                                <Typography gutterBottom>
                                                    You now have access to straightforward, professional support via telephone, email or online. No matter how
                                                    simple or complex the matter, we&lsquo;re here to provide as much pragmatic advice, guidance and
                                                    clarification as you require to approach situations confidently and overcome issues quickly.
                                                </Typography>
                                                <Typography gutterBottom>
                                                    To get you up to speed, this online Information Hub contains details of all your key contacts, short
                                                    summaries of the services you will receive, and some straightforward guidance on how to access and navigate
                                                    around our range of online resources
                                                </Typography>
                                                <Typography gutterBottom>
                                                    The information in this pack should be read in conjunction with your Service Proposal and Service Agreement
                                                    which you have already received. Together, these provide full details of the services you have subscribed to
                                                    and how to make the most of our support.
                                                </Typography>
                                                <Typography gutterBottom>
                                                    We look forward to providing a source of trusted support to you and the team at {data && data.accountName}.
                                                </Typography>
                                            </div>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === accordionSections.KEEP_IN_TOUCH} onChange={handleChange(accordionSections.KEEP_IN_TOUCH)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">Keeping in touch</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <Typography gutterBottom>
                                                We understand how important it is to keep up to date with what&lsquo;s happening in the world of Employment Law,
                                                HR and Health & Safety. Our monthly Insights newsletter provides a useful update on any legislative changes, new
                                                case law, and other topical talking points to help you stay one step ahead.
                                            </Typography>
                                            <Typography gutterBottom>
                                                We also produce regular webinars offering practical advice on issues that may affect your organisation, and our
                                                range of expert-led training courses are designed to equip you and your team with the knowledge and skills you
                                                need to tackle staffing and safety matters confidently day to day. We&lsquo;ll update you on any new webinars
                                                and active training courses in our monthly newsletter, and more information can be found on the &lsquo;Training,
                                                e-Learning & Events&lsquo; section of our website.
                                            </Typography>
                                            <Typography gutterBottom>
                                                We will automatically send our monthly newsletter to all the email addresses listed on the Service Agreement.
                                                You can opt out of receiving these at any time by clicking the &lsquo;Unsubscribe&lsquo; link at the bottom of
                                                the emails. However, we strongly encourage you to receive these updates to make sure you&lsquo;re notified of
                                                any important developments that may be relevant to you and your employees.
                                            </Typography>
                                            <Typography gutterBottom>
                                                If any of your colleagues wish to receive our bulletins, they can do so by clicking on the &lsquo;Latest
                                                Updates&lsquo; button, which can be found at the bottom of every page of our website, and entering their
                                                details. To stay informed in between newsletters, make sure to periodically check our website and follow us on
                                                our social media platforms for breaking news and announcements.
                                            </Typography>
                                            <div className={classes.footerContainer}>
                                                <Typography className={classes.footerCopy} variant="h6">
                                                    Follow us for our latest updates
                                                </Typography>
                                                <Box display="flex" gridGap={16} flexWrap="wrap">
                                                    <Link href="https://facebook.com/WorknestGroup/">
                                                        <Facebook className={classes.socialIcon} color="primary" />
                                                    </Link>
                                                    <Link href="https://twitter.com/WorknestGroup">
                                                        <Twitter className={classes.socialIcon} color="primary" />
                                                    </Link>
                                                    <Link href="https://www.linkedin.com/WorknestGroup">
                                                        <LinkedIn className={classes.socialIcon} color="primary" />
                                                    </Link>
                                                </Box>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === accordionSections.ELEARNING} onChange={handleChange(accordionSections.ELEARNING)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">e-Learning</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <Typography gutterBottom>
                                                Our extensive catalogue of HR and Health &amp; Safety e-Learning courses are designed to have a direct impact on
                                                your organisation, ensuring staff are fully equipped to undertake their duties confidently and safely.
                                            </Typography>
                                            <Typography gutterBottom>
                                                Delivered via our e-Learning portal, our online courses incorporate videos and interactive testing to offer a
                                                convenient and impactful alternative to classroom-based training. As an entirely online system, some of the
                                                benefits of our e-Learning platform include:
                                            </Typography>
                                            <ul className={classes.list}>
                                                <li>
                                                    <strong>Ease and simplicity.</strong>
                                                    &nbsp;There&rsquo;s no need for any special technology, training facilities or supervision, making training
                                                    more accessible and easier to organise.
                                                </li>
                                                <li>
                                                    <strong>Putting employees in charge of their own development.</strong>
                                                    &nbsp;Employees can complete courses at their own pace, in their own time, even at home. This makes training
                                                    less of an inconvenience, encourages information retention, and promotes active and independent learning for
                                                    increased employee engagement.
                                                </li>
                                                <li>
                                                    <strong>Keeping downtime to a minimum.</strong>
                                                    &nbsp;The flexibility to undertake training anytime, anywhere, means that it&rsquo;s no longer necessary to
                                                    take whole groups of employees out of the workplace and no need for operations to come to a halt &ndash;
                                                    keeping productivity levels high.
                                                </li>
                                                <li>
                                                    <strong>More choice.</strong>&nbsp;Licences allow for unlimited training in all modules for all employees,
                                                    giving you access to a full range of relevant material, including courses you may have never thought about
                                                    before.
                                                </li>
                                                <li>
                                                    <strong>Greater personalisation.</strong>&nbsp;With e-Learning, you can set your own pass score for each
                                                    course to suit your requirements.
                                                </li>
                                                <li>
                                                    <strong>Easy monitoring.</strong>&nbsp;To allow you to monitor training progress, our e-Learning platforms
                                                    allows you to see who has completed the training at a glance, as well as who has failed and needs to re-sit.
                                                </li>
                                                <li>
                                                    <strong>Training certificates for employees.</strong>
                                                    &nbsp;When an employee has passed a module, they will receive a certificate for their files, which can be
                                                    used as a record of their training. This is also an excellent, cost-effective way of demonstrating to your
                                                    employees that you take their development and wellbeing seriously.
                                                </li>
                                            </ul>
                                            <Typography gutterBottom>
                                                To browse our growing library of e-Learning training modules, including topics such as Accident Reporting
                                                (RIDDOR), Display Screen Equipment (DSE), Fire Safety, Equality &amp; Diversity and General Data Protection
                                                Regulations (GDPR), visit the e-Learning section of your myWorkNest portal.
                                            </Typography>
                                            <Typography>
                                                If you require assistance setting up your e-Learning or allocating additional courses to your employee&lsquo;s,
                                                please contact the Client Experience team either by emailing{" "}
                                                <a href="mailto:clientexperience@worknest.com">clientexperience@worknest.com</a> or alternatively calling{" "}
                                                <a href="tel:01244 687 603">01244 687 603</a> to speak to one of the team.
                                            </Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === accordionSections.EMPLOYMENT_LAW} onChange={handleChange(accordionSections.EMPLOYMENT_LAW)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">Employment Law</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <Typography gutterBottom>
                                                Unlimited support, a nominated Employment Law Adviser and comprehensive online resources.
                                            </Typography>
                                            <Typography gutterBottom>
                                                Your lead Employment Law Adviser, assisted by a small team of qualified advisers, is on hand to provide
                                                straightforward, practical support through any employee relations challenge you come up against, no matter how
                                                simple or complex.
                                            </Typography>
                                            <Typography gutterBottom>As part of this service, we will:</Typography>
                                            <ul className={classes.list}>
                                                <li>
                                                    Offer as much advice and guidance as you require with day-to-day employment law and HR-related matters,
                                                    including issues relating to discipline, grievance, attendance, sickness absence, performance and any other
                                                    employment-related matter.
                                                </li>
                                                <li>
                                                    Review and update your Contract(s) of Employment and Employee Handbook to ensure they are commercially
                                                    effective, legally compliant and provide useful direction on how to approach common issues.
                                                </li>
                                                <li>Expertly draft letters and other important employment-related documents on your behalf.</li>
                                            </ul>
                                            <Typography gutterBottom>
                                                We can also provide pragmatic support in the event you receive an Employment Tribunal claim. While this support
                                                is not included within the EL service, our Legal Expenses Insurance (if you hold such a policy) can, subject to
                                                policy conditions being met and policy limits, cover the costs of your legal defence, including representation
                                                at hearings, and awards of compensation that may be made against you. If you do not have any insurance cover, we
                                                can still support you at competitive rates.
                                            </Typography>
                                            <Typography gutterBottom>
                                                Think of us as an extension of your team that you can call upon at any time, as often as you need.
                                            </Typography>
                                            <Box display="flex" justifyContent="space-around" flexWrap="wrap" m={2}>
                                                <Button
                                                    variant="outlined"
                                                    className={classes.textCenter}
                                                    onClick={() => setOpenClientCareDialog(true)}
                                                    color="primary"
                                                    endIcon={<ImportContacts />}
                                                >
                                                    Click Here for your Client Care Information
                                                </Button>
                                            </Box>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === accordionSections.HEALTH_AND_SAFETY}
                                    onChange={handleChange(accordionSections.HEALTH_AND_SAFETY)}
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">Health &amp; Safety</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <Typography gutterBottom>
                                                Helping you to fulfil your legal duties and protecting the wellbeing of everyone affected by your organisation’s
                                                activities.
                                            </Typography>
                                            <Typography gutterBottom>
                                                We act as one of your legally-required competent persons, your dedicated Health & Safety Consultant will help
                                                you to identify sensible, proportionate solutions to all of your health and safety challenges.
                                            </Typography>
                                            <Typography gutterBottom>In addition to 24/7 support via phone or email, this service includes:</Typography>
                                            <ul className={classes.list}>
                                                <li>
                                                    Conducting an on-site General Risk Assessment, then preparing a prioritised Action Plan to remedy any areas
                                                    of non-compliance and advising on appropriate controls.
                                                </li>
                                                <li>
                                                    Thereafter, visiting you on site each year to review progress and help you to implement change (see the
                                                    Health & Safety Visit Schedule in your Service Agreement).
                                                </li>
                                                <li>
                                                    Producing a bespoke Health & Safety Policy outlining your approach and commitment to health and safety
                                                    management, supported by a Health & Safety Handbook containing all the information your employees need to
                                                    carry out their role safely.
                                                </li>
                                                <li>
                                                    Providing emergency assistance in the event of regulatory visits and accident investigations, and providing
                                                    close support in the event of a serious incident.
                                                </li>
                                                <li>
                                                    Legal Expenses Insurance to protect your organisation from the costs involved in defending health and safety
                                                    prosecutions. This also covers the costs of appealing an improvement or prohibition notice and Fee for
                                                    Intervention (FFI) charges (see your Service Proposal for more information).
                                                </li>
                                            </ul>
                                            <Typography>
                                                Our hands-on service aims to take the pressure off health and safety management and give you complete confidence
                                                in your compliance.
                                            </Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === accordionSections.ABSENCE} onChange={handleChange(accordionSections.ABSENCE)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">Absence Management Software</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetails}>
                                        <div>
                                            <Typography gutterBottom>
                                                Our Absence Management Software provides you with an easy way to manage employee information, saving you time
                                                and money whilst improving productivity.
                                            </Typography>
                                            <Typography gutterBottom>Providing you with:</Typography>
                                            <ul className={classes.list}>
                                                <li>Employee data, file storage and document management</li>
                                                <li>Time management &ndash; holidays, absence, timesheets and rostering</li>
                                                <li>The ability to manage employee reviews</li>
                                                <li>An employee directory</li>
                                                <li>A record of training and qualifications</li>
                                            </ul>
                                            <Typography gutterBottom>
                                                For further information as please contact
                                                <span>&nbsp;</span>
                                                <a href="mailto:hrsoftware@worknest.com" className={classes.emailLink}>
                                                    hrsoftware@worknest.com
                                                </a>
                                                <span>&nbsp;</span>or speak with your Client Experience Co-ordinator about adding this service to your WorkNest
                                                suite of services.
                                            </Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === accordionSections.LEI} onChange={handleChange(accordionSections.LEI)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">Legal Expenses Insurance</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <Typography gutterBottom>
                                                Our ultimate aim is to help you prevent difficult staffing and safety situations before they arise. However, for
                                                complete peace of mind, our Legal Expenses Insurance (LEI) offers an added layer of protection for your
                                                organisation in difficult times.
                                            </Typography>
                                            <Typography gutterBottom>Our Health &amp; Safety LEI covers the cost of:</Typography>
                                            <ul className={classes.list}>
                                                <li>
                                                    Legal representation, including in court, in the event of prosecutions brought against you for health and
                                                    safety breaches.
                                                </li>
                                                <li>Appealing against an improvement or prohibition notice served upon you by the regulator.</li>
                                                <li>
                                                    Fee for Intervention (a &pound;166/hour fee charged by the HSE for investigating material breaches of health
                                                    and safety law), plus initial representation.
                                                </li>
                                                <li>The highest-quality representation and strongest possible defence should the case go to court.</li>
                                            </ul>
                                            <Typography gutterBottom>
                                                Our Employment Law LEI covers the following (subject to policy conditions being satisfied and policy limits):
                                            </Typography>
                                            <ul className={classes.list}>
                                                <li>
                                                    Costs of a legal defence, including representation at hearings, in relation to Employment Tribunal claims
                                                    that may be brought against your organisation by a current, former or prospective employee, including unfair
                                                    dismissal and TUPE claims.
                                                </li>
                                                <li>Awards of compensation that may be made against you by an Employment Tribunal.</li>
                                                <li>
                                                    The costs of a legal action against your employees/former employees who are acting in breach of restrictive
                                                    covenants, for example, by contacting/soliciting your clients.
                                                </li>
                                            </ul>
                                            <Typography gutterBottom>Cover is subject to policy cap and policy conditions being met.</Typography>
                                            <Typography gutterBottom>
                                                <small>
                                                    We are registered with and regulated by the Financial Conduct Authority. Insurance is provided subject to
                                                    policy terms. You can download a copy of our Legal Expenses Insurance Policy<span>&nbsp;</span>
                                                    <a
                                                        href="https://elliswhittam.com/wp-content/uploads/terms/Business%20Legal%20Policy%20Wording%20-%20November%202020.pdf"
                                                        rel="noopener"
                                                    >
                                                        here
                                                    </a>
                                                    . You will need a password to open the document, which is available to you when you receive a Service
                                                    Proposal from a WorkNest Regional Business Director.
                                                </small>
                                            </Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === accordionSections.REFERRALS} onChange={handleChange(accordionSections.REFERRALS)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">Referrals</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <Typography gutterBottom>
                                                A considerable amount of new business comes to us by way of referral. Because we know your recommendation holds
                                                a lot of weight, we think it&rsquo;s only right that we should show our appreciation to clients who make those
                                                introductions.
                                            </Typography>
                                            <Typography gutterBottom>
                                                If you&rsquo;re happy with the service you receive from WorkNest, we&rsquo;d love it if you&rsquo;d spread the
                                                word. To take advantage of our generous referral scheme, all you need to do is provide us with the contact
                                                details of a senior decision maker at an organisation employing more than five people anywhere in the UK which
                                                you feel would benefit from our support. You will need to have spoken to that person, explained to them who we
                                                are, and received their permission for WorkNest to contact them.
                                            </Typography>
                                            <Typography gutterBottom>
                                                Then, if they come on board, you will be able to select one of the following rewards:
                                            </Typography>
                                            <ul className={classes.list}>
                                                <li>&pound;250 off your next invoice from WorkNest;</li>
                                                <li>&pound;250 one-off payment to you from WorkNest; or</li>
                                                <li>&pound;250 contribution to your selected charity from WorkNest.</li>
                                            </ul>
                                            <Typography gutterBottom>
                                                Simply send an email to your Account Manager with details of the organisation(s) who are happy to be contacted
                                                by us, the name of your contact there and any other information you feel would be relevant.
                                            </Typography>
                                            <Typography gutterBottom>
                                                If you&rsquo;d like some marketing materials such as the WorkNest brochure to share with your contacts, just let
                                                us know, and of course a wealth of other information can be found on our website.
                                            </Typography>
                                            <Typography gutterBottom>
                                                Please remember, it is entirely at your discretion as to whether you want to take up this offer or not, but your
                                                cooperation is always greatly appreciated.
                                            </Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === accordionSections.FEEDBACK} onChange={handleChange(accordionSections.FEEDBACK)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">Feedback</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetails}>
                                        <Box>
                                            <div>
                                                <Typography gutterBottom>
                                                    The team at WorkNest are committed to providing a high-quality service and take pride in achieving
                                                    exceptional client satisfaction rates. To help us continue to improve, your feedback is always welcome.
                                                </Typography>
                                                <Typography gutterBottom>
                                                    We have developed our feedback procedure to apply to all compliments, complaints and suggestions and to
                                                    enable us to respond swiftly.
                                                </Typography>
                                                <Typography gutterBottom>Client Service Review</Typography>
                                                <Typography gutterBottom>
                                                    At several points throughout the course of your agreement with us, we will send you an email asking you to
                                                    complete a Client Service Review. This is a very important means of letting us know what you think of our
                                                    service, so please do take the time to fill this out when you receive it.
                                                </Typography>
                                                <Typography gutterBottom>How to give positive feedback</Typography>
                                                <Typography gutterBottom>
                                                    Our colleagues are recognised for going above and beyond, so any feedback on a particularly good experience
                                                    you&rsquo;ve had with us is always welcome. We encourage you to share your experience directly with the
                                                    member of staff (but please also let your Account Manager know as we love to hear about your positive
                                                    experiences!)
                                                </Typography>
                                                <Typography gutterBottom>Feedback on LEI and payment facilities</Typography>
                                                <Typography gutterBottom>
                                                    If your feedback is about Legal Expenses Insurance (LEI) or payment facilities offered by WorkNest, and you
                                                    are not satisfied with the way that we have handled your case or the outcome of it, you can contact the
                                                    Financial Ombudsman Service:
                                                    <span>&nbsp;</span>
                                                    <a href="https://www.financial-ombudsman.org.uk/" rel="noopener">
                                                        www.financial-ombudsman.org.uk
                                                    </a>
                                                </Typography>
                                                <Typography gutterBottom>How to make a complaint</Typography>
                                            </div>

                                            <div className={classes.feedbackContainer}>
                                                <div>
                                                    <Typography variant="h4" className={classes.feedbackTitle}>
                                                        Making an Employment Law service complaint
                                                    </Typography>
                                                    <Typography gutterBottom>
                                                        Brilliant client service is at the heart of everything we do, but we accept that there may be some
                                                        occasions when we fail to satisfy the particular requirements of our clients. It is our policy to ensure
                                                        that any complaints we receive are handled and managed appropriately and dealt with in a timely manner,
                                                        consistent with any legal or regulatory obligations. The procedure below summarises how we will deal
                                                        with any complaints that our made.
                                                    </Typography>
                                                    <Typography gutterBottom>What is a complaint?</Typography>
                                                    <Typography gutterBottom>
                                                        A complaint is any expression of dissatisfaction by a client or other third party about the
                                                        Company&rsquo;s conduct, its business operations or the conduct of its employees. A complaint can be
                                                        made in a letter, an e-mail, a telephone call or during a conversation.
                                                    </Typography>
                                                    <Typography gutterBottom>What we will do on receipt of a complaint?</Typography>
                                                    <Typography gutterBottom>
                                                        Once a complaint has been received we will investigate it diligently and impartially. We will assess
                                                        fairly, consistently and promptly the subject matter of the complaint, whether it should be upheld, and
                                                        what remedial action may be appropriate. We will advise you promptly of our decision in a way that is
                                                        clear, fair and not misleading.
                                                    </Typography>
                                                    <Typography gutterBottom>Complaints Process</Typography>
                                                    <ol>
                                                        <li>
                                                            Any complaint or expression of dissatisfaction will be acknowledged by the recipient within one
                                                            working day
                                                        </li>
                                                        <li>
                                                            The complaint will be passed by the recipient to the Legal Operations Director who will be
                                                            responsible for investigating the complaint as appropriate. The Legal Operations Director will
                                                            contact the client to confirm receipt of the complaint and to gather any additional information as
                                                            may be necessary to investigate the complaint.
                                                        </li>
                                                        <li>
                                                            In any complaint about poor service or advice, it will normally be necessary to review the file
                                                            including any correspondence or calls between you and us and to speak to the adviser about the
                                                            complaint.
                                                        </li>
                                                        <li>
                                                            The Legal Operations Director will aim to provide an outcome to any complaint within 5 working days
                                                            of receipt. However, with particularly complex matters, a more detailed investigation may be
                                                            required. In those circumstances, we will tell you how long we expect it to take to provide you with
                                                            an outcome.
                                                        </li>
                                                        <li>
                                                            The Legal Operations Director is responsible for determining the outcome of your complaint. This
                                                            will be done by fairly and objectively assessing the subject matter of the complaint against the
                                                            available evidence including, but not limited to, documents held on file, emails between you and us,
                                                            attendance notes of telephone calls, call recordings and speaking to the relevant parties.
                                                        </li>
                                                        <li>
                                                            You will be provided with a written outcome of any complaint. If the complaint is not upheld, you
                                                            will receive a full explanation as to why that decision has been arrived at.
                                                        </li>
                                                        <li>
                                                            If a complaint is upheld, the Company will offer you appropriate redress, the exact nature of which
                                                            will be assessed on a case by case basis considering any inconvenience and/or financial loss
                                                            incurred by you.
                                                        </li>
                                                    </ol>
                                                    <Typography gutterBottom>Complaints to the Legal Ombudsman</Typography>
                                                    <Typography gutterBottom>
                                                        If we have been unable to settle your complaint using our internal complaints process, and your
                                                        complaint relates to services provided by a legal professional based in England and Wales (either a
                                                        Solicitor or Chartered Legal Executive), you have a right to complain to the Legal Ombudsman, an
                                                        independent complaints body, established under the Legal Services Act 2007, that deals with legal
                                                        services complaints.
                                                    </Typography>
                                                    <Typography gutterBottom>
                                                        You have six months from the date of our final outcome in which to complain to the Legal Ombudsman at:
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Legal Ombudsman
                                                        <br />
                                                        PO Box 6806
                                                        <br />
                                                        Wolverhampton,&nbsp;WV1 9WJ
                                                        <br />
                                                        <br />
                                                        <div>Telephone:&nbsp;0300 555 0333</div>
                                                        <div className={classes.emailLink}>
                                                            Email Address:&nbsp;
                                                            <a href="mailto:enquiries@legalombudsman.org.uk">enquiries@legalombudsman.org.uk</a>
                                                        </div>
                                                        <div className={classes.emailLink}>
                                                            Website:&nbsp;
                                                            <a href="https://www.legalombudsman.org.uk/">www.legalombudsman.org.uk</a>
                                                        </div>
                                                    </Typography>
                                                    <Typography gutterBottom>
                                                        Alternative complaints bodies (such as Ombudsman Services, Pro-Mediate and Small Claims Mediation)
                                                        exist, which are competent to deal with complaints about legal services should both we and you wish to
                                                        use such an alternative scheme.
                                                    </Typography>
                                                    <Typography gutterBottom>WorkNest do not agree to use any of these or similar schemes.</Typography>
                                                </div>
                                            </div>

                                            <div className={classes.feedbackContainer}>
                                                <Typography variant="h4" className={classes.feedbackTitle}>
                                                    Making a Health &amp; Safety service complaint
                                                </Typography>
                                                <Typography gutterBottom>
                                                    If you have a suggestion for improvement or a concern regarding any support or advice you have been given by
                                                    one of our team, we would ask that you try to resolve the issue with that individual in the first instance.
                                                </Typography>
                                                <Typography gutterBottom>
                                                    If this doesn&rsquo;t produce a satisfactory outcome, or you would rather not liaise directly with the
                                                    member of staff in question, please contact your Account Manager either by email or telephone. You will be
                                                    sent an acknowledgement within 24 hours.
                                                </Typography>
                                                <Typography gutterBottom>
                                                    Once your Account Manager has all the necessary information from you, the matter will then be investigated,
                                                    and you will receive a substantive response within 10 days.
                                                </Typography>
                                                <Typography gutterBottom>
                                                    If you are still not happy, let your Account Manager know and they will escalate the matter to our
                                                    Commercial Director, who will liaise with the appropriate service directors and normally respond to you
                                                    within five days. If the investigation is going to take longer, we will let you know.
                                                </Typography>
                                                <Typography gutterBottom>What information do I need to provide?</Typography>
                                                <Typography gutterBottom>To help us deal effectively with any issues raised, please tell us:</Typography>
                                                <ul className={classes.list}>
                                                    <li>
                                                        Precisely what the issue is: what happened (or didn&rsquo;t happen), when it happened, and who you dealt
                                                        with.
                                                    </li>
                                                    <li>What you would consider to be a satisfactory outcome.</li>
                                                </ul>
                                            </div>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionDetails className={classes.continueToDash}>
                                        <Box display="flex" justifyContent="space-around" flexWrap="wrap" m={2}>
                                            <Button
                                                variant="outlined"
                                                className={classes.textCenter}
                                                onClick={() => handleGotoDashboard()}
                                                color="primary"
                                                endIcon={<Send />}
                                            >
                                                Continue to Dashboard
                                            </Button>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <div className={classes.sideBarContainer}>
                                <Paper>
                                    <MenuList className={classes.menuLinks}>
                                        <MenuItem
                                            onClick={() => {
                                                handleButtonClick(accordionSections.INTRODUCTION);
                                            }}
                                        >
                                            <Typography>Introduction</Typography>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleButtonClick(accordionSections.KEEP_IN_TOUCH);
                                            }}
                                        >
                                            <Typography>Keep in touch</Typography>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleButtonClick(accordionSections.ELEARNING);
                                            }}
                                        >
                                            <Typography>e-Learning</Typography>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleButtonClick(accordionSections.EMPLOYMENT_LAW);
                                            }}
                                        >
                                            <Typography>Employment Law</Typography>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleButtonClick(accordionSections.HEALTH_AND_SAFETY);
                                            }}
                                        >
                                            <Typography>Health &amp; Safety</Typography>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleButtonClick(accordionSections.ABSENCE);
                                            }}
                                        >
                                            <Typography>Absence</Typography>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleButtonClick(accordionSections.LEI);
                                            }}
                                        >
                                            <Typography>Legal Expenses Insurance</Typography>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleButtonClick(accordionSections.REFERRALS);
                                            }}
                                        >
                                            <Typography>Referrals</Typography>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleButtonClick(accordionSections.FEEDBACK);
                                            }}
                                        >
                                            <Typography>Feedback</Typography>
                                        </MenuItem>
                                    </MenuList>
                                </Paper>
                                <>
                                    {accountAdvisers && accountAdvisers.length > 0 && (
                                        <Paper>
                                            <LawyerCard accountAdvisers={accountAdvisers} />
                                        </Paper>
                                    )}
                                    {accountManagers && accountManagers.length > 0 && (
                                        <Paper>
                                            <AccountManagerCard accountManagers={accountManagers} />
                                        </Paper>
                                    )}
                                    {cxPeople && cxPeople.length > 0 && (
                                        <Paper>
                                            <CxCard cxPeople={cxPeople} />
                                        </Paper>
                                    )}
                                    {accountHsConsultants && accountHsConsultants.length > 0 && (
                                        <Paper>
                                            <HealthSafetyCard accountHsConsultants={accountHsConsultants} />
                                        </Paper>
                                    )}
                                </>
                            </div>
                        </Grid>
                    </Grid>

                    <Dialog
                        open={openClientCareDialog}
                        onClose={handleCloseClientCareDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="lg"
                        fullWidth
                    >
                        <DialogTitle id="alert-dialog-title">Client Care Information</DialogTitle>
                        <Box display="flex" flexDirection="column" alignItems="center" mt={5} mb={3} ml={5} mr={5}>
                            <Box>
                                <div>
                                    <Typography gutterBottom>
                                        Thank you for instructing WorkNest (&ldquo;EW&rdquo;) to act on behalf in relation to your employment law and HR
                                        requirements. The purpose of this note is to provide information in relation to how we will carry out work on your
                                        behalf, who will carry that work out and how you can complain if the need should arise.
                                    </Typography>
                                    <div>
                                        <ol type="1">
                                            <li>
                                                <strong>
                                                    <u>Regulation</u>
                                                </strong>
                                                <ol type="1">
                                                    <li>WorkNest is not a regulated law firm.</li>
                                                    <li>
                                                        WorkNest employ Solicitors who are personally regulated by the Solicitors Regulation Authority
                                                        (&ldquo;SRA&rdquo;) and must comply with the SRA Code of Conduct for Solicitors.
                                                    </li>
                                                    <li>
                                                        WorkNest also employ Chartered and Graduate Members of the Chartered Institute of Legal Executives
                                                        (&ldquo;CILEX&rdquo;) who are regulated by CILEX and must comply with their Code of Conduct.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <strong>
                                                    <u>The service</u>
                                                </strong>
                                                <ol type="1">
                                                    <li>
                                                        WorkNest will provide a comprehensive 24 x 7 core advice service, available to support authorised users
                                                        on the full range of employee relations issues (including, but not limited to, advice on disciplinaries,
                                                        grievances, redundancies, restructures, discrimination, contracts, TUPE and whistleblowing).
                                                    </li>
                                                    <li>
                                                        We will deliver a high quality, informed, pragmatic and responsive service, including:
                                                        <ol start="1" type="i">
                                                            <li>
                                                                Timely responses to employee relations matters, via telephone or email (dedicated telephone line
                                                                and email address)
                                                            </li>
                                                            <li>All advice backed up by attendance notes, available to authorised users on-line</li>
                                                            <li>
                                                                Full drafting service, preparation of documents as wide ranging as disciplinary meeting invites,
                                                                new template employment contracts and settlement agreements
                                                            </li>
                                                            <li>
                                                                Initial and annual review and re-drafting of &ldquo;essential&rdquo; employee documents (eg,
                                                                contracts of employment for all roles and existing employee handbook and policies) to ensure
                                                                legal compliance, currency and completeness
                                                            </li>
                                                            <li>
                                                                Advice is insured assuming cover is taken and the terms of the policy are complied with. A copy
                                                                of your policy can be found within the Legal Expense Insurance folder in your online portal.
                                                            </li>
                                                            <li>
                                                                The conduct of employment tribunal litigation including preparation of tribunal documentation,
                                                                collation of documents and witness statements, negotiations with ACAS and/or the other side,
                                                                briefing barristers and ultimately Employment Tribunal representation.
                                                            </li>
                                                        </ol>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <strong>
                                                    <u>Your Team</u>
                                                </strong>
                                                <ol>
                                                    <li>
                                                        A small, dedicated team will carry out the service on your behalf. Their names and qualifications can be
                                                        found on the pen profiles in the Information Hub. From time to time, if a member of your team is
                                                        unavailable for any reason, a member of the wider WorkNest Employment Law team may carry out work for
                                                        you.
                                                    </li>
                                                    <li>
                                                        The email signature of each member of the team will set out their formal legal qualification as
                                                        appropriate. Fully qualified lawyers, either a &ldquo;Solicitor&rdquo; or &ldquo;Chartered Legal
                                                        Executive&rdquo;, will be noted as such.
                                                    </li>
                                                    <li>
                                                        Any member of the team with a title including the terms &ldquo;Trainee Solicitor&rdquo; or
                                                        &ldquo;Graduate Member of CILEX&rdquo; is training towards being a fully qualified lawyer. As such,
                                                        their work will be supervised by either a Solicitor or a Chartered Legal Executive.
                                                    </li>
                                                    <li>
                                                        Any member of the team without either of the above designations is not currently a qualified lawyer.
                                                        Instead, they will hold a relevant CIPD qualification, will have studied law at undergraduate level or
                                                        will be an experienced employment law practitioner. As such their work will not be supervised by a
                                                        qualified lawyer as their level of expertise does not require this.
                                                    </li>
                                                    <li>
                                                        Whilst we endeavour to make as few changes as possible to your dedicated advice team, sometimes that
                                                        will be necessary for example in the case of maternity leave or long-term sickness absence. Any change
                                                        in adviser will be notified to you in advance and we will send you a copy of their pen profile which
                                                        will set out their legal qualification or relevant experience.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <strong>
                                                    <u>Costs</u>
                                                </strong>
                                                <ol>
                                                    <li>
                                                        The cost of the service is the fixed fee stated on your service agreement. If you require a copy of that
                                                        document, please request it from your client manager.
                                                    </li>
                                                    <li>
                                                        Should you decide to take up any additional service from WorkNest, the cost of that will be discussed
                                                        and agreed with you prior to its commencement.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <strong>
                                                    <u>Professional Indemnity Insurance</u>
                                                </strong>
                                                <ol>
                                                    <li>
                                                        WorkNest is not regulated by the SRA. As a result, you will not be eligible for a grant from the SRA
                                                        Compensation Fund which is a discretionary fund operated by the SRA to which all solicitors contribute.
                                                        Its purpose is to make grants to people whose money has been stolen, misappropriated, or otherwise not
                                                        properly accounted for; or those who have suffered a loss for which a regulated person should have been
                                                        insured, but was not.
                                                    </li>
                                                    <li>
                                                        Despite not being required to have professional indemnity insurance that meets the SRA&rsquo;s minimum
                                                        terms and conditions, WorkNest does have in place professional indemnity insurance. Our indemnity
                                                        insurance covers any claim made by you as a result of negligent advice or service provided to you by a
                                                        member of the WorkNest Employment Law team. Our insurance provides cover of up to &pound;10m for any
                                                        claim made by you against WorkNest.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <strong>
                                                    <u>Complaints</u>
                                                </strong>
                                                <ol>
                                                    <li>
                                                        Brilliant client service is at the heart of everything we do, but we accept that there may be some
                                                        occasions when we fail to satisfy the particular requirements of our clients. It is our policy to ensure
                                                        that any complaints we receive are handled and managed appropriately and dealt with in a timely manner,
                                                        consistent with any legal or regulatory obligations.
                                                    </li>
                                                    <li>
                                                        Full details of our complaints procedure can be found in WorkNest Documents folder, within the &ldquo;My
                                                        Documents&rdquo; tab of the online portal.
                                                    </li>
                                                    <li>
                                                        You also have the right to complain to the SRA about the conduct of a Solicitor or to CILEX about the
                                                        conduct of a Chartered or Graduate Member of the Institute of Legal Executives.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                        <Typography gutterBottom>
                                            If you have any concerns or questions in relation to the above, please contact James Tamm, Director of Legal
                                            Services, on&nbsp;
                                            <a href="tel:+44345 226 8393">0345 226 8393</a>&nbsp;or by email at&nbsp;
                                            <a href="mailto:jamestamm@worknest.com">jamestamm@worknest.com</a>
                                        </Typography>
                                    </div>
                                </div>
                                <Box display="flex" justifyContent="space-around" flexWrap="wrap" mt={2}>
                                    <Button variant="outlined" className={classes.textCenter} onClick={handleCloseClientCareDialog} color="primary">
                                        Close
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Dialog>
                </React.Fragment>
            </Track>
        </PageBase>
    );
}

export default InformationHub;
