import React from "react";

import {
    Typography,
    LinearProgress,
    Box,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';


const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.secondary.mainOpaque,
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.secondary.main,
    },
}))(LinearProgress);

const LinearProgressWithLabel = props => {
    let newProps = { ...props, value: props.value > 100 ? 100 : props.value };

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <BorderLinearProgress variant="determinate" {...newProps} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

export default LinearProgressWithLabel;