import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import riskAssessmentHistoryService from "../../services/riskAssessmentHistoryService";

const useRiskAssessmentVersionHistory = (riskAssessmentHistoryId) =>
    useQuery(
        [
            queryKeys.riskAssessmentVersionHistory,
            riskAssessmentHistoryId,
        ],
        () => riskAssessmentHistoryService.fetchRiskAssessmentVersionHistory(riskAssessmentHistoryId),
        {
            enabled: !!riskAssessmentHistoryId,
        }
    );

export default useRiskAssessmentVersionHistory;