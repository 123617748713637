import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import salesforceService from "../../services/salesforceService";

const useAccountLinkableUserSearch = ({ accountId, email }) =>
  useQuery(
    [queryKeys.accountLinkableUserSearch, accountId, email],
    () => salesforceService.searchForAccountLinkableUser({ accountId, email }),
    { enabled: !!email, retry: false, cacheTime: 0 }
  );

export default useAccountLinkableUserSearch;
