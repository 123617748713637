import React from "react";
import {
    alpha,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    Typography,
    RadioGroup,
} from "@material-ui/core";
import CherryError from "./CherryError";
import BreadboxCheckbox from "./BreadboxCheckbox";
import { alertOptions, instantIncidentAlertOptions, instantAuditAlertOptions } from "../../constants/manageUserOptions";
import {
    alertConstants,
    alertFrequencyConstants,
} from "../../constants/manageUserConstants";
import AlertFrequency from "./AlertFrequency";

const useStyles = makeStyles((theme) => ({
    divider: {
        width: "100%",
        margin: theme.spacing(2, 0),
    },
    lastDivider: {
        width: "100%",
        margin: theme.spacing(2, 0, 4, 0),
    },
    formControlLabel: {
        alignItems: "start",
        marginBottom: theme.spacing(1),
    },
    componentTitle: {
        fontSize: 26,
        fontWeight: 600,
        marginBottom: theme.spacing(2),
    },
    checkbox: {
        padding: 0,
        marginRight: theme.spacing(2),
    },
    radio: {
        padding: 0,
        marginRight: theme.spacing(2),
    },
    checkboxChecked: {
        color: theme.palette.primary.light,
    },
    labelContainer: {
        display: "flex",
        flexDirection: "column",
    },
    sectionTitle: {
        fontSize: 15,
        fontWeight: 600,
    },
    checkboxContainer: {
        marginLeft: theme.spacing(1.5),
        [theme.breakpoints.up("md")]: {
            marginLeft: 0,
        },
    },
    checkboxLabel: {
        fontSize: 13,
        fontWeight: 600,
        marginBottom: theme.spacing(1),
    },
    checkboxDescription: {
        fontSize: 12,
        color: alpha(theme.palette.primary.main, 0.6),
        marginTop: theme.spacing(1),
    },
    radioChecked: {
        color: theme.palette.primary.light,
    },
    incidentsRadioContainer: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(1, 0, 0, 3),
        cursor: "pointer",
    },
    hide: {
        display: "none",
    },
    disabled: {
        cursor: "initial",
    },
    componentSubtitle: {
        fontSize: 18,
        fontWeight: 600,
    },
    subComponent: {
        marginBottom: theme.spacing(2),
    },
    radioLabel: {
        fontSize: 13,
        fontWeight: 600,
    },
}));

const isIncidentsRadioChecked = (data, key) =>
    (data && key === alertConstants.INCIDENT_WITH_INVESTIGATION_LOGGED) ||
    (!data && key !== alertConstants.INCIDENT_WITH_INVESTIGATION_LOGGED);

const Alerts = ({
    data,
    onChange,
    hasAuditAccess,
    hasComplianceAccess,
    hasMonitoringAccess,
    hasIncidentsAccess,
    isAlertsError,
}) => {
    const classes = useStyles();

    const handleChange = (value, key) => {
        if (key === alertConstants.INCIDENT_WITH_INVESTIGATION_LOGGED) {
            return onChange({
                ...data,
                [key]: value === alertConstants.INCIDENT_WITH_INVESTIGATION_LOGGED,
            });
        }
        return onChange({ ...data, [key]: value });
    };

    const handleInstantChange = (value, key) => {
        return onChange({ ...data, [key]: Number(value) });
    };

    const hasAccess = (label) =>
        label === alertConstants.ACTIONS ||
        (label === alertConstants.AUDITS && hasAuditAccess) ||
        (label === alertConstants.COMPLIANCE_FORMS && hasComplianceAccess) ||
        (label === alertConstants.MONITORING_FORMS && hasMonitoringAccess) ||
        (label === alertConstants.INCIDENTS && hasIncidentsAccess);

    return (
        <Grid spacing={2} container>
            <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.componentTitle}>
                    SafetyNest
                </Typography>
            </Grid>
            <AlertFrequency
                title="Admin Alerts"
                description="Receive an Alert Summary Email containing information on overdue actions, published audits, compliance and monitoring form deadlines, and newly logged incidents within your business area."
                subDescription="Choose how often you would like to receive the Admin Alert Summary email."
                value={data.adminAlertFrequency}
                handleChange={(value) => handleChange(value, "adminAlertFrequency")}
            />
            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>
            <AlertFrequency
                title="Personal Alerts"
                description="Configure your personal Alert Summary Email content based on your individual preferences and needs."
                subDescription="Choose how often you would like to receive the Personal Alert Summary email."
                value={data.alertFrequency}
                handleChange={(value) => handleChange(value, "alertFrequency")}
            />
            {data.alertFrequency !== alertFrequencyConstants.NONE && (
                <>
                    {alertOptions.map(
                        (option) =>
                            hasAccess(option.label) && (
                                <React.Fragment key={option.label}>
                                    <Grid item xs={12}>
                                        <Divider className={classes.divider} />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography
                                            color="primary"
                                            variant="body2"
                                            className={classes.sectionTitle}
                                        >
                                            {option.label}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        className={classes.checkboxContainer}
                                    >
                                        {option.label !== alertConstants.INCIDENTS ? (
                                            option.options.map((checkbox) => (
                                                <BreadboxCheckbox
                                                    title={checkbox.label}
                                                    description={checkbox.description}
                                                    checked={data[checkbox.key] ?? false}
                                                    onChange={(e) => handleChange(e, checkbox.key)}
                                                    hasSmallerLabel={true}
                                                    key={checkbox.key}
                                                />
                                            ))
                                        ) : (
                                            <>
                                                <FormControlLabel
                                                    className={classes.formControlLabel}
                                                    control={
                                                        <Checkbox
                                                            size="small"
                                                            classes={{ checked: classes.checkboxChecked }}
                                                            className={classes.checkbox}
                                                            checked={data[option.key] ?? false}
                                                            onChange={(e) => handleChange(e.target.checked, option.key)}
                                                        />
                                                    }
                                                    key={option.label}
                                                    label={
                                                        <div className={classes.labelContainer}>
                                                            <Typography
                                                                color="primary"
                                                                variant="body2"
                                                                className={classes.checkboxLabel}
                                                            >
                                                                {option.label}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                className={classes.checkboxDescription}
                                                            >
                                                                {option.description}
                                                            </Typography>
                                                        </div>
                                                    }
                                                />
                                                {option.options.map((radio) => (
                                                    <div
                                                        className={`${classes.incidentsRadioContainer} ${!data.incidentLogged && classes.disabled}`}
                                                        key={radio.key}
                                                        onClick={() => handleChange(radio.key, alertConstants.INCIDENT_WITH_INVESTIGATION_LOGGED)}
                                                    >
                                                        <Radio
                                                            checked={ data.incidentLogged && isIncidentsRadioChecked(data.incidentWithInvestigationLogged, radio.key)}
                                                            onChange={(e) => handleChange(e.target.value, alertConstants.INCIDENT_WITH_INVESTIGATION_LOGGED)}
                                                            value={radio.key}
                                                            color="secondary"
                                                            classes={{ root: classes.radio, checked: classes.radioChecked }}
                                                            disabled={!data.incidentLogged}
                                                        />
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.checkboxDescription}
                                                        >
                                                            {radio.label}
                                                        </Typography>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </Grid>
                                    {option.label === alertConstants.INCIDENTS &&
                                        isAlertsError &&
                                        hasIncidentsAccess &&
                                        data.incidentLogged &&
                                        !data.incidentWithInvestigationLogged && (
                                            <Grid item xs={12}>
                                                <CherryError message="Please select an option" />
                                            </Grid>
                                        )}
                                </React.Fragment>
                            )
                    )}
                </>
            )}
            <Divider className={classes.lastDivider} />

            <Grid item xs={12} className={classes.subComponent}>
                <Typography
                    color="primary"
                    variant="subtitle2"
                    className={classes.componentSubtitle}
                >
                    Instant Emails (Admin)
                </Typography>
                <Typography variant="body2" className={classes.checkboxDescription}>
                    {instantIncidentAlertOptions.title}
                </Typography>
            </Grid>

            {hasAccess(instantIncidentAlertOptions.label) &&
                <React.Fragment key={instantIncidentAlertOptions.label}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            color="primary"
                            variant="body2"
                            className={classes.sectionTitle}
                        >
                            {instantIncidentAlertOptions.label}
                        </Typography>
                        <Typography variant="body2" className={classes.checkboxDescription}>
                            {instantIncidentAlertOptions.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.checkboxContainer}>
                        <RadioGroup
                            value={data.instantIncidentSetting || 0}
                            onChange={(event) => handleInstantChange(event.target.value, instantIncidentAlertOptions.key)}
                        >
                            {instantIncidentAlertOptions.options.map((radioOption) => (
                                <FormControlLabel
                                    key={radioOption.value}
                                    control={<Radio />}
                                    label={
                                        <Typography
                                            color="primary"
                                            variant="body2"
                                            className={classes.radioLabel}
                                        >
                                            {radioOption.label}
                                        </Typography>
                                    }
                                    value={radioOption.value}
                                />
                            ))}
                        </RadioGroup>
                    </Grid>
                </React.Fragment>            
            }
            {hasAccess(instantAuditAlertOptions.label) &&
                <React.Fragment key={instantAuditAlertOptions.label}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            color="primary"
                            variant="body2"
                            className={classes.sectionTitle}
                        >
                            {instantAuditAlertOptions.label}
                        </Typography>
                        <Typography variant="body2" className={classes.checkboxDescription}>
                            {instantAuditAlertOptions.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.checkboxContainer}>
                        <RadioGroup
                            value={data.instantAuditSetting || 0}
                            onChange={(event) => handleInstantChange(event.target.value, instantAuditAlertOptions.key)}
                        >
                            {instantAuditAlertOptions.options.map((radioOption) => (
                                <FormControlLabel
                                    key={radioOption.value}
                                    control={<Radio />}
                                    label={
                                        <Typography
                                            color="primary"
                                            variant="body2"
                                            className={classes.radioLabel}
                                        >
                                            {radioOption.label}
                                        </Typography>
                                    }
                                    value={radioOption.value}
                                />
                            ))}
                        </RadioGroup>
                    </Grid>
                </React.Fragment>            
            }
            < Divider className={classes.lastDivider} />
        </Grid>
    );
};

export default Alerts;
