import { useMutation } from "react-query";
import riskAssessmentHistoryService from "../../services/riskAssessmentHistoryService";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";

const useArchiveRiskAssessment = () =>
    useMutation(riskAssessmentHistoryService.archiveRiskAssessmentHistory, {
        onMutate: async (riskAssessmentUpdate) => {
            const { client } = reactQueryUtils;
            const queryKey = [
                queryKeys.riskAssessment,
                riskAssessmentUpdate.id,
                true
             ];
            await client.cancelQueries(queryKey);
            const previousRiskAssessments = client.getQueryData(queryKey);

            client.setQueryData(queryKey, riskassessments => {
                var validRiskAssessmentHistories = riskassessments.riskAssessmentHistories.filter(d => d.id !== riskAssessmentUpdate.archiveRiskAssessmentId);
                return {
                    riskAssessment: riskassessments.riskAssessment,
                    riskAssessmentHistories: validRiskAssessmentHistories
                };
            }
            );

            return { previousRiskAssessments }
        },
        onError: (error, data, context) => {
            reactQueryUtils.client.setQueryData(
                [
                    queryKeys.riskAssessment,
                    data.riskAssessmentId,
                    data.withHistory
                ],
                context.previousRiskAssessments
            );
        },
        onSettled: (_data, _error, variables) => {
            if (variables)
                reactQueryUtils.client.invalidateQueries([
                    queryKeys.riskAssessment,
                    variables.riskAssessmentId,
                    variables.withHistory
                ]);
        },
  });

export default useArchiveRiskAssessment;
