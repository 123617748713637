import React from "react";
import { Button, Icon, makeStyles } from "@material-ui/core";
import { useAuth } from "../contexts/authContext";
import { socialConnectionNames } from "../utils/auth0Client";
const msLogoUrl = `${process.env.PUBLIC_URL}/images/ms-logo.svg`;

const useStyles = makeStyles(() => ({
  microsoftLoginButton: {
    backgroundColor: "#2f2f2f",
    minHeight: "40px",
    borderRadius: 0,
    width: "100%",
    fontWeight: 600,
    fontSize: "15px",
    borderRadius: 4,
    fontFamily: "Segoe UI, Arial, sans-serif",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2f2f2f",
    },
    lineHeight: "normal",
    height: "48px"
  },
  startIcon: {
    marginRight: "12px",
  },
  buttonIcon: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
}));

function MicrosoftLoginButton() {
  const classes = useStyles();
  const { loginViaConnection } = useAuth();

  return (
    <Button
      variant="contained"
      className={classes.microsoftLoginButton}
      classes={{ startIcon: classes.startIcon }}
      onClick={() => loginViaConnection({connection: socialConnectionNames.microsoft})}
      startIcon={
        <Icon classes={{ root: classes.buttonIcon }}>
          <img src={msLogoUrl} />
        </Icon>
      }
    >
      Sign in with Microsoft
    </Button>
  );
}

export default MicrosoftLoginButton;
