import {
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
    Grid,
    Checkbox,
    TextField,
    InputAdornment,
    Tooltip
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CardBase from "../../components/cards/CardBase";
import { useHistory, useRouteMatch } from "react-router-dom";
import useDashboardSitesTemplateForms from "../hooks/queries/useDashboardSitesTemplateForms";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SkeletonTableLoader from "./common/SkeletonTableLoader";
import { Alert } from "@material-ui/lab";
import { parseISO, isValid, format } from "date-fns";
import { useRiskAssessmentDetails } from "../contexts/RiskAssessmentContext";
import { setFormsTablePage } from "../../redux/actions/tablePageActions";
import { tablePage } from "../constants/tablePageConstants";
import { positiveNumberOrNull } from "../../utils/numberUtils";
import {
    formsModuleAreaDropdownValues,
    moduleAreaNames,
    moduleUrls,
} from "../constants/moduleAreaConstants";
import ResponsiveSelect from "../../components/ui/ResponsiveSelect";
import {
    Beenhere as BeenhereIcon,
    Create as CreateIcon,
    Favorite as FavoriteIcon,
    People as PeopleIcon,
    Visibility as VisibilityIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlank,
    CheckBox as CheckBox,
    Search as SearchIcon,
    Clear as ClearIcon,
    Error as ErrorIcon,
    ErrorOutline as ErrorOutlineIcon,
} from "@material-ui/icons";
import { useAuth } from "../../contexts/authContext";
import useContactsForAppAndSite from "../../hooks/useContactsForAppAndSite";
import EnhancedTableHead from "../../components/table/EnhancedTableHead";
import { sortOrderName } from "../constants/sortOrder";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
    },
    tableRow: {
        cursor: "pointer",
    },
    searchField: {
        marginLeft: 'auto',  
    },
    moduleCellContainer: {
        display: "flex",
        alignItems: "center",
    },
    moduleCellText: {
        marginLeft: theme.spacing(1),
    },
    toolbar: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    searchTextField: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: "100%",
        '& .clearIcon': {
            visibility: 'hidden',
        },
        '&:hover .clearIcon': {
            visibility: 'visible',
        },
    },
    endAdornment: {
        paddingRight: theme.spacing(1),
    },
    tooltip: {
        margin: theme.spacing(1),
        backgroundColor: 'white',
        border: '1px solid #000',
        color: theme.palette.common.black,
        fontSize: '0.9em',
        borderRadius: 0,
        padding: theme.spacing(1),
    },
    keyboardBtn: {
        padding: theme.spacing(0),
        marginRight: theme.spacing(0),
    },
    assignedString: {
        maxWidth: "86%",
    },
    iconCellText: {
        marginRight: theme.spacing(1),
    },
    overdueCell: {
        color: theme.palette.error.dark,
        marginRight: theme.spacing(1),
    },
    overdueCellText: {
        color: theme.palette.error.dark,
        marginLeft: theme.spacing(1),
    },
    dueCellContainer: {
        display: "flex",
        alignItems: "start",
    },
}));

const tableKey = tablePage.FORMS_TABLE;

const headCells = [
    { id: "form", label: "Form", isSortable: true },
    { id: "module", label: "Module", isSortable: true },
    { id: "site", label: "Site", isSortable: true},
    { id: "responsible", label: "Responsible", isSortable: true },
    { id: "duedate", label: "Due Date", isSortable: true },
];

const defaultDropdownOption = { value: null, label: "All" };
const notSetDropdownOption = { value: "NotSet", label: "Not Set" };

export default function FormsTableCard() {
    const { user } = useAuth();
    const classes = useStyles();
    const { path } = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const [filteredCurrentAssignees, setFilteredCurrentAssignees] = React.useState([]);

    const { setSelectedRiskAssessmentId } = useRiskAssessmentDetails();
    const { selectedSite, activeAppId, appSiteIds, sites } = useSelector((state) => state.account);
    const { formsTable } = useSelector((state) => state.tablePage);

    const [filterText, setFilterText] = useState(formsTable?.filteredText);
    const [inputFromDate, setInputFromDate] = useState(formsTable?.fromDate);
    const [inputToDate, setInputToDate] = useState(formsTable?.toDate);

    const [searchRequested, setSeachRequested] = React.useState(false);

    const contacts = useContactsForAppAndSite({ selectedSiteId: null });

    const filteredModuleAreaDropdownValues = user?.details?.userAccessPermission
        ? formsModuleAreaDropdownValues.filter(
            (value) =>
                user?.details?.userAccessPermission[value.userAccessPermission] ===
                true
        ) : defaultDropdownOption;

    useEffect(() => {
        dispatch(
            setFormsTablePage({
                ...formsTable,
                key: tableKey,
                page: 0,
            })
        );
    }, [selectedSite]);

    const { data, isLoading, error } = useDashboardSitesTemplateForms({
        externalIds: selectedSite
            ? [selectedSite.externalId]
            : appSiteIds[activeAppId],
        pageSize: formsTable.rowsPerPage,
        pageNum: formsTable.page + 1,
        orderByColumn: formsTable.sortBy || headCells[4].id,
        sortOrder: formsTable.orderBy || sortOrderName.ASC,
        assignees: formsTable.assignees?.map(option => option.value),
        filteredText: formsTable.filteredText,
        moduleArea: formsTable.module,
        fromDate: formsTable.fromDate,
        toDate: formsTable.toDate,
    });

    const filteredAsigneeOptions = contacts?.data && data && data?.assignees
        ? (data.assignees.includes(null) || data.assignees.includes('') ? [notSetDropdownOption] : [])
            .concat(Object.values(contacts.data)
                .filter(user => data.assignees.includes(user.externalId) && user.firstName)
                .sort((a, b) => a.firstName.localeCompare(b.firstName))
                .map(user => ({ value: user.externalId, label: `${user.firstName} ${user.lastName}` }))
            ) : [];

    const handleChangePage = (event, newPage) => {
        dispatch(
            setFormsTablePage({
                ...formsTable,
                key: tableKey,
                page: newPage,
            })
        );
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(
            setFormsTablePage({
                ...formsTable,
                key: tableKey,
                page: 0,
                rowsPerPage: parseInt(event.target.value, 10),
            })
        );
    };

    const handleSortClick = (event, sortColumn) => {
        const isAsc = formsTable.sortBy === sortColumn && formsTable.orderBy === sortOrderName.ASC;
        dispatch(
            setFormsTablePage({
                ...formsTable,
                key: tableKey,
                page: 0,
                sortBy: sortColumn,
                orderBy: isAsc ? sortOrderName.DESC : sortOrderName.ASC,
            })
        );
    };

    const handleToDateChange = (date) => {
        setInputToDate(date);
        if (!date || isValid(new Date(date))) {
            dispatch(
                setFormsTablePage({
                    ...formsTable,
                    key: tableKey,
                    page: 0,
                    toDate: date ? date.toISOString() : null,
                })
            );
        }
    };

    const handleFromDateChange = (date) => {
        setInputFromDate(date);
        if (!date || isValid(new Date(date))) {
            dispatch(
                setFormsTablePage({
                    ...formsTable,
                    key: tableKey,
                    page: 0,
                    fromDate: date ? date.toISOString() : null,
                })
            );
        }
    };

    function errorBox() {
        return (
            <div className={classes.loadingContainer}>
                <Alert variant="outlined" severity="error">
                    {error.message}
                </Alert>
            </div>
        );
    }

    const Module = ({ name, type }) => {
        switch (name) {
            case moduleAreaNames.COMPLIANCE:
                return (
                    <div className={classes.moduleCellContainer}>
                        <BeenhereIcon />
                        <Typography variant="body2" className={classes.moduleCellText}>
                            {name}
                        </Typography>
                    </div>
                );
            case moduleAreaNames.MONITORING:
                return (
                    <div className={classes.moduleCellContainer}>
                        <VisibilityIcon />
                        <Typography variant="body2" className={classes.moduleCellText}>
                            {name}
                        </Typography>
                    </div>
                );
            case moduleAreaNames.RISKASSESSMENTS:
                return (
                    <div className={classes.moduleCellContainer}>
                        <CreateIcon />
                        <Typography variant="body2" className={classes.moduleCellText}>
                            {name}
                        </Typography>
                    </div>
                );
            default:
                return <></>;
        }
    };

    const handleModuleChange = (event) => {
        dispatch(
            setFormsTablePage({
                ...formsTable,
                key: tableKey,
                page: 0,
                module: positiveNumberOrNull(event.target.value),
            })
        );
    };

    function handleCurrentAssigneesChange(event, inputs) {
        setFilteredCurrentAssignees(inputs);
        if (inputs.length == 0) {
            dispatch(
                setFormsTablePage({
                    ...formsTable,
                    key: tableKey,
                    page: 0,
                    assignees: [],
                }))
        }
    }

    const handleConfirmedAssigneesChange = (event, inputs) => {
        dispatch(
            setFormsTablePage({
                ...formsTable,
                key: tableKey,
                page: 0,
                assignees: filteredCurrentAssignees,
            })
        );
    }

    function handleSearchChange(e) {
        setSeachRequested(false);
        onFilteredTextChange(e.target.value)
    }

    const handleSearchClick = () => {
        setSeachRequested(true);
        onFilteredTextSearch();
    };

    const showFilteredTextError = () => {
        return (filterText && filterText.length > 0 && filterText.length < 4 && searchRequested)
    }

    const onFilteredTextChange = (textString) => {
        setFilterText(textString);
    };

    const onFilteredTextSearch = () => {
        dispatch(
            setFormsTablePage({
                ...formsTable,
                key: tableKey,
                page: 0,
                filteredText: filterText
            })
        );
    };

    const handleFilteredTextClear = () => {
        setFilterText('');
        dispatch(
            setFormsTablePage({
                ...formsTable,
                key: tableKey,
                page: 0,
                filteredText: ''
            })
        );
    };

    const handleRowClick = async (formSelected) => {
        if (formSelected.moduleName === moduleAreaNames.RISKASSESSMENTS) {
            setSelectedRiskAssessmentId(formSelected.id);
            history.push(`${path}/${moduleUrls[formSelected.moduleType]}/view/${formSelected.id}/true`);
        }
        else {
            history.push(`${path}/${moduleUrls[formSelected.moduleType]}/form/${formSelected.id}/true`);    
        }
    };

    function handleKeyDown(event) {
       
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    }

    const Due = (dueDate) => {
        const currentDate = new Date();
        const thirtyDaysTime = new Date();
        thirtyDaysTime.setDate(currentDate.getDate() + 30);

        const parsedDueDate = parseISO(dueDate);
        const isOverdue = parsedDueDate < currentDate;
        const isComingUp = parsedDueDate >= currentDate && parsedDueDate <= thirtyDaysTime;

        return (
            <div className={classes.dueCellContainer}>
                {isOverdue ? (
                    <Tooltip title="Overdue Item" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                        <ErrorIcon className={classes.overdueCell} fontSize="small" />
                    </Tooltip>
                ) : isComingUp ? (
                    <Tooltip title="Item is Upcoming" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                        <ErrorOutlineIcon className={classes.iconCellText} color="primary" fontSize="small" />
                    </Tooltip>
                ) : null}
                <Typography variant="body2">
                    {format(parsedDueDate, "dd/MM/yyyy")}
                </Typography>
            </div>
        );
    };
    
    return (
        <>
            <CardBase
                title="Forms"
                fullHeight
                errorComponent={errorBox}
                error={error}
            >
                {isLoading && (
                    <SkeletonTableLoader
                        columns={[
                            "Form",
                            "Module",
                            "Site",
                            "Responsible",
                            "Due Date",
                        ]}
                        numberOfRows={4}
                    />
                )}
                {!isLoading && (
                    <>
                        <Grid container spacing={1} >
                            <Grid item xs={12} md={6} xl={2} >
                                <ResponsiveSelect
                                    options={[defaultDropdownOption].concat(
                                        filteredModuleAreaDropdownValues
                                    )}
                                    optionLabelFunction={(x) => x.label}
                                    optionValueKey={"value"}
                                    margin="dense"
                                    fullWidth
                                    label="Module"
                                    placeholder="Module"
                                    onChange={handleModuleChange}
                                    value={formsTable.module}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={3} className={classes.toolbar}>
                                <Autocomplete
                                    multiple
                                    value={filteredCurrentAssignees && filteredCurrentAssignees.length > 0 ? filteredCurrentAssignees :
                                        (formsTable.assignees && formsTable.assignees.length > 0 ? formsTable.assignees : [])}
                                    onChange={handleCurrentAssigneesChange}
                                    onBlur={handleConfirmedAssigneesChange}
                                    options={filteredAsigneeOptions}
                                    disableCloseOnSelect
                                    clearOnBlur
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => {
                                        return option.label === value.label;
                                    }}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                className={classes.checkbox}
                                                icon={uncheckedIcon}
                                                checkedIcon={checkedIcon}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={classes.textField}
                                            variant="outlined"
                                            size="small"
                                            label="Responsible Person(s)"
                                            fullWidth
                                        />
                                    )}
                                    renderTags={selected => {
                                        console.log("selected = ", selected);
                                        let renderTagsValue = selected
                                            .map(function (elem) {
                                                return elem.label;
                                            })
                                            .join(", ");
                                        return (
                                            <Typography className={classes.assignedString}
                                                noWrap={true}
                                                color="textPrimary"
                                            >
                                                {renderTagsValue}
                                            </Typography>
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={1} className={classes.toolbar}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        size="small"
                                        inputVariant="outlined"
                                        label="From date"
                                        format="dd/MM/yyyy"
                                        value={inputFromDate || null}
                                        onChange={(date) => handleFromDateChange(date)}
                                        maxDate={inputToDate}
                                        KeyboardButtonProps={{className: classes.keyboardBtn}}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={6} xl={1} className={classes.toolbar}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        size="small"
                                        inputVariant="outlined"
                                        label="To date"
                                        format="dd/MM/yyyy"
                                        value={inputToDate || null}
                                        onChange={(date) => handleToDateChange(date)}
                                        minDate={inputFromDate}
                                        KeyboardButtonProps={{className: classes.keyboardBtn}}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={6} xl={2} className={classes.searchField}>
                                <TextField
                                    className={classes.searchTextField}
                                    label="Search Description"
                                    variant="outlined"
                                    size="small"
                                    value={filterText}
                                    onChange={handleSearchChange}
                                    helperText='Please enter at least 4 character to search on'
                                    error={showFilteredTextError()}
                                    FormHelperTextProps={{ hidden: !showFilteredTextError() }}
                                    onKeyDown={handleKeyDown} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {filterText && (
                                                    <Tooltip title="Clear" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                                        <IconButton className={classes.iconBtn}>
                                                            <ClearIcon className={`clearIcon ${classes.clearIcon}`}
                                                                onClick={handleFilteredTextClear}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <Tooltip title="Search" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                                    <IconButton className={classes.iconBtn}>
                                                        <SearchIcon
                                                            onClick={handleSearchClick}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                        className: classes.endAdornment
                                    }}
                                />
                        </Grid>
                        </Grid>
                        <TableContainer>
                            <Table>
                                <EnhancedTableHead
                                    headCells={headCells}
                                    sortColumn={formsTable.sortBy}
                                    sortDirection={formsTable.orderBy || sortOrderName.DESC}
                                    onRequestSort={handleSortClick}
                                />
                                <TableBody>
                                    {data?.results?.length ? (
                                        data?.results?.map((formsData) => (
                                            <TableRow
                                                key={formsData?.id}
                                                hover
                                                className={classes.tableRow}
                                                onClick={() => handleRowClick(formsData)}
                                            >
                                                <TableCell>{formsData?.templateName}</TableCell>
                                                <TableCell>
                                                    <Module name={formsData?.moduleName} type={formsData?.auditType} />
                                                </TableCell>
                                                
                                                <TableCell>{sites[formsData?.siteExternalId]?.name ?? "Unknown"}</TableCell>
                                                <TableCell>
                                                    {contacts?.data[formsData?.assignedEmployeeExternalId] ?
                                                        `${contacts?.data[formsData.assignedEmployeeExternalId]
                                                            ?.firstName
                                                        } ${contacts?.data[formsData.assignedEmployeeExternalId]
                                                            ?.lastName
                                                        }` : ""
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {Due(formsData?.dueDate)}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                No Forms exist for selected site(s)
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={data?.totalCount}
                                rowsPerPage={formsTable.rowsPerPage}
                                page={formsTable.page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </>
                )}
            </CardBase>
        </>
    );
}