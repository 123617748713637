import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import actionPresetService from "../../services/actionPresetService";
import moduleAreaConstants from "../../constants/moduleAreaConstants";

const useActionPresets = ({ applicationArea, subjectId, accountId, siteExternalIds }) =>
  useQuery(
    [
      queryKeys.actionPresets,
          applicationArea === moduleAreaConstants.INCIDENTS ? null : subjectId, accountId, siteExternalIds
    ],
      applicationArea === moduleAreaConstants.INCIDENTS
          ? () => actionPresetService.fetchForIncidents(accountId, siteExternalIds)
        : () => actionPresetService.fetchForSubject(subjectId, accountId, siteExternalIds),
    {
      enabled:
            applicationArea === moduleAreaConstants.INCIDENTS ? true : !!subjectId && !!siteExternalIds?.length,
      staleTime: Infinity,
    }
  );

export default useActionPresets;
