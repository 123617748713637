import React from "react";
import {
  Container,
  Box,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  textWrapper: {
    maxWidth: theme.breakpoints.values.md,
    padding: theme.spacing(3),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[4],
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 0),
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "between",
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
    minWidth: "100%",
  },
  infoLabel: {
    width: "35%",
  },
  infoDetail: {
    width: "55%",
  },
  iconWrapper: {
    minWidth: 0,
    marginRight: theme.spacing(2),
  },
  icon: {
    fontSize: 11,
    fill: theme.palette.primary.main,
  },
}));

export const Processing = () => {
  const classes = useStyles();

  const BulletPointIcon = () => (
    <ListItemIcon className={classes.iconWrapper}>
      <FiberManualRecord className={classes.icon} />
    </ListItemIcon>
  );

  return (
    <Container className={classes.container}>
      <Box className={classes.textWrapper}>
        <Typography variant="h3" component="h1" className={classes.title}>
          Data Processing Policy
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          This data processing policy sets out how WorkNest uses and
          protects any personal information that you give WorkNest when you
          commission our services.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          WorkNest is committed to ensuring the confidentiality, integrity
          and availability of the data that we process.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          WorkNest may change this policy from time to time by updating
          this page.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          You should check this page from time to time to ensure that you are
          happy with any changes. This policy is effective from November 2017.
        </Typography>
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <div className={classes.infoWrapper}>
            <Typography className={classes.infoLabel}>
              Post holder responsible for policy:
            </Typography>
            <Typography className={classes.infoDetail}>
              Chief Technology Officer
            </Typography>
          </div>
          <div className={classes.infoWrapper}>
            <Typography className={classes.infoLabel}>
              ICO Registration Name:
            </Typography>
            <Typography className={classes.infoDetail}>
              WorkNest Ltd
            </Typography>
          </div>
          <div className={classes.infoWrapper}>
            <Typography className={classes.infoLabel}>
              ICO Registration Number:
            </Typography>
            <Typography className={classes.infoDetail}>Z2442783</Typography>
          </div>
          <div className={classes.infoWrapper}>
            <Typography className={classes.infoLabel}>
              Data Protection Officer:
            </Typography>
            <Typography className={classes.infoDetail}>Steve Clark</Typography>
          </div>
        </Box>
        <Typography
          variant="subtitle1"
          className={classes.subtitle}
          paragraph
          gutterBottom
        >
          Introduction:
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          This policy sets out the basis on which any data we collect from or on
          behalf of you, or that you provide to WorkNest, will be processed
          by WorkNest.
        </Typography>

        <Typography
          variant="subtitle1"
          className={classes.subtitle}
          paragraph
          gutterBottom
        >
          Data that we collect
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          WorkNest process personal data on behalf of our Clients, with
          this processing necessary in order to provide our services.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          In order to assess the terms of the insurance contract or administer
          claims that arise, we or the Insurer may need to collect data that the
          Data Protection Act defines as sensitive, such as medical history or
          criminal convictions. By proceeding with the Contract you signify your
          consent to such information being processed by us, the Insurer or our
          agents and subcontractors.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          We may collect the following information:
        </Typography>
        <List>
          <ListItem alignItems="flex-start">
            <BulletPointIcon />
            <Typography variant="body1" paragraph gutterBottom>
              Personal Details relating to our Clients, their employees and
              associated third parties and consultants:
            </Typography>
          </ListItem>
        </List>
        <Typography
          variant="subtitle1"
          className={classes.subtitle}
          paragraph
          gutterBottom
        >
          Why we collect this data
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          We require this data for the provision of our services.
        </Typography>

        <Typography
          variant="subtitle1"
          className={classes.subtitle}
          paragraph
          gutterBottom
        >
          Security
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          Data security is very important to WorkNest: we are committed to
          ensuring that your data is secure.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          We will use various technical and organisational measures to safeguard
          all data that we store and process: In order to prevent unauthorised
          access or disclosure we have put in place suitable physical,
          electronic and managerial procedures to safeguard and secure the data
          that we process.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          Our data centres are based in the U.K. and we do not transfer data
          outside of the EEA without explicit consent. Personal data will be
          systematically redacted within a reasonable time after the termination
          of a contract.
        </Typography>
        <Typography
          variant="subtitle1"
          className={classes.subtitle}
          paragraph
          gutterBottom
        >
          Distribution of your personal data
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          We will not sell, distribute or lease your personal data to third
          parties unless we have your permission or are required by law to do
          so.
        </Typography>

        <Typography
          variant="subtitle1"
          className={classes.subtitle}
          paragraph
          gutterBottom
        >
          Subject Access Requests and Data accuracy
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          You may request details of personal information which we hold about
          you.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          If you would like a copy of the information held on you contact
          enquiries@worknest.com or write to WorkNest, Church Lane,
          Aldford, Cheshire CH3 6JD.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          If you believe that any information we are holding on you is incorrect
          or incomplete, please write to or email us as soon as possible, at the
          above address. We will promptly correct any information found to be
          incorrect.
        </Typography>
      </Box>
    </Container>
  );
};

export default Processing;
