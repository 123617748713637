import { IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@material-ui/core"
import { useHistoricAuditContext } from "../../contexts/HistoricAuditContext"
import { useSiteSearchContext } from "../../contexts/SiteSearchContext"
import useSiteHistoricAudits from "../../hooks/queries/useSiteHistoricAudits"
import { formatShortDate } from '../../../utils/dateTimeUtils'
import { useState } from "react"
import { MoreVert } from "@material-ui/icons"
import { useHistory } from "react-router-dom"

const tableColumns = [
    'Audit ID',
    'Created Date',
    'Last Modified Date',
    'Start Date',
    'End Date',
    'Audit Type',
    'Description',
    ''
]

const editHistoricAuditURL = '/safetynest/historic-audit/'

export default function HistoricAuditsTable() {
    const {
        setSelectedAudit, 
        setDeleteHistoricAuditDialogOpen,
        setHistoricAuditIdToDelete,
    } = useHistoricAuditContext()

    const { selectedSite } = useSiteSearchContext()

    const history = useHistory()  

    const [pageSize, setPageSize] = useState(5);
    const [pageNum, setPageNum] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpenOnId, setMenuOpenOnId] = useState(null);

    function handleChangePage(_e, newPage) {
        setPageNum(newPage)
    }

    function handleChangeRowsPerPage(e) {
        setPageNum(0)
        setPageSize(parseInt(e.target.value, 10));
    }

    const historicAudits = useSiteHistoricAudits({
        siteExternalId: selectedSite?.externalId ?? '',
        pageNum,
        pageSize,
    })

    function handleClick(e, id) {
        setAnchorEl(e.currentTarget)
        setMenuOpenOnId(id)
    }

    function handleClose() {
        setAnchorEl(null)
        setMenuOpenOnId(null)
    }

    function handleEditClick(audit) {
        setSelectedAudit(audit)
        history.push(`${editHistoricAuditURL}${audit.id}`)
    }

    function handleDeleteClick(audit) {
        setHistoricAuditIdToDelete(audit.id)
        setDeleteHistoricAuditDialogOpen(true)
    }

    function tableContent() {
        if (historicAudits?.isLoading) {
            return (
                <TableRow>
                    <TableCell colSpan={tableColumns.length}>
                        Loading...
                    </TableCell>
                </TableRow>
            )
        }

        if (historicAudits?.data?.results?.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={tableColumns.length}>
                        No audits found.
                    </TableCell>
                </TableRow>
            )
        }

        if (historicAudits?.data?.results?.length > 0) {
            return historicAudits.data.results.map(audit => (
                <TableRow key={audit.id}>
                    <TableCell>
                        {audit.id}
                    </TableCell>
                    <TableCell>
                        {formatShortDate(audit.createdDate)}
                    </TableCell>
                    <TableCell>
                        {formatShortDate(audit.lastModifiedDate)}
                    </TableCell>
                    <TableCell>
                        {formatShortDate(audit.startDate)}
                    </TableCell>
                    <TableCell>
                        {formatShortDate(audit.endDate)}
                    </TableCell>
                    <TableCell>
                        {audit.typeDescription}
                    </TableCell>
                    <TableCell>
                        {audit.description}
                    </TableCell>
                    <TableCell align="right">
                        <IconButton
                            onClick={(e) => handleClick(e, audit.id)} 
                        >
                            <MoreVert />                        
                        </IconButton>
                        <Menu
                            keepMounted
                            open={menuOpenOnId === audit.id}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                        >
                            <MenuItem
                                onClick={() => handleEditClick(audit)}
                            >
                                Edit
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleDeleteClick(audit)}
                            >
                                Delete
                            </MenuItem>
                        </Menu>
                    </TableCell>
                </TableRow>
            ))
        }
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableColumns.map(column => (
                            <TableCell key={column}>
                                {column}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableContent()}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            count={historicAudits?.data?.totalCount ?? 0}
                            rowsPerPage={pageSize}
                            page={pageNum}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}