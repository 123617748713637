import { format } from "date-fns";
import { isoWithTimezoneFormat } from "../constants/dateConstants";
import axios from "../utils/axios";

const trackingEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/tracking`;

const generateTimestamp = () => format(new Date(), isoWithTimezoneFormat);

const sendTrackingData = async (data) => {
  try {
    const trackingData = {
      ...data,
      timestamp: generateTimestamp(),
    };

    await axios.post(`${trackingEndpointUrl}/logevent`, trackingData);
  } catch (error) {
    console.error(error);
  }
};

const startImpersonationSession = async (impersonationUserId) => {
  try {
    const impersonationData = {
      impersonationUserId,
      startTime: generateTimestamp(),
    };

    const sessionId = await axios.post(
      `${trackingEndpointUrl}/startimpersonation`,
      impersonationData
    );
    return sessionId;
  } catch (error) {
    return Promise.reject(error);
  }
};

const endImpersonationSession = (impersonationId) =>
  axios.get(`${trackingEndpointUrl}/endimpersonation/${impersonationId}`);

const trackingService = {
  sendTrackingData,
  startImpersonationSession,
  endImpersonationSession,
};

export default trackingService;
