import React, { useState } from "react";
import {
  Button,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import CardBase from "../components/cards/CardBase";
import useConsultantAccounts from "../hooks/queries/useConsultantAccounts";
import { tableSortingOrder } from "../constants/tableConstants";
import EnhancedTableHead from "../components/table/EnhancedTableHead";
import { getComparator, stableSort } from "../utils/tableUtils";
import { selectAccount, selectSite } from "../redux/actions/accountActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { INTERNAL_REDIRECT_URL_KEY } from '../constants/authConfig';

const useStyles = makeStyles((theme) => ({
  currentAccountIndicator: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
  currentAccountCardIcon: {
    color: "inherit",
    marginLeft: theme.spacing(1),
  },
  cancelButton: {
    marginTop: theme.spacing(2),
  },
}));

const headCells = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
  },
  {
    id: "action",
    label: "Action",
    align: "right",
  },
];

function ConsultantAccountSelect() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedAccount } = useSelector((state) => state.account);
  const { data, isLoading, error } = useConsultantAccounts();
  const [order, setOrder] = useState(tableSortingOrder.asc);
  const [orderBy, setOrderBy] = useState(headCells[0].id);
  
  const handleAccountSelect = (account) => {
    dispatch(selectAccount({ account, childExternalIds: [] }));
    dispatch(selectSite(account));
    goToDashboard();
  };

  const goToDashboard = () => {
    const internalRedirectUrl = localStorage.getItem(INTERNAL_REDIRECT_URL_KEY);
    if (internalRedirectUrl) {
      localStorage.removeItem(INTERNAL_REDIRECT_URL_KEY);
      history.push(internalRedirectUrl);
    } else {
      history.push("/");
    }
  };

  const handleSortRequest = (_, property) => {
    const isAsc = orderBy === property && order === tableSortingOrder.asc;
    setOrder(isAsc ? tableSortingOrder.desc : tableSortingOrder.asc);
    setOrderBy(property);
  };

  const tableContent = () => {
    if (data && data.length > 0)
      return stableSort(data, getComparator(order, orderBy)).map((account) => (
        <TableRow key={account.id}>
          <TableCell>{account.name}</TableCell>
          <TableCell align="right">
            {selectedAccount?.id === account.id ? (
              <div className={classes.currentAccountIndicator}>
                <Typography variant="button">Current Account</Typography>
                <CheckCircleIcon className={classes.currentAccountCardIcon} />
              </div>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleAccountSelect(account)}
              >
                Select
              </Button>
            )}
          </TableCell>
        </TableRow>
      ));

    return (
      <TableRow>
        <TableCell colSpan={2}>
          <Typography>No active accounts found.</Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Container maxWidth="md">
      <CardBase
        title="Consultant Account Select"
        description="Select an account to view as a consultant."
        isLoading={isLoading}
        error={error}
      >
        <TableContainer>
          <Table>
            <EnhancedTableHead
              headCells={headCells}
              sortDirection={order}
              sortColumn={orderBy}
              onRequestSort={handleSortRequest}
            />
            <TableBody>{tableContent()}</TableBody>
          </Table>
        </TableContainer>
        {selectedAccount?.id && (
          <Button
            className={classes.cancelButton}
            onClick={goToDashboard}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        )}
      </CardBase>
    </Container>
  );
}

export default ConsultantAccountSelect;
