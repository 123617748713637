import React, { useState } from "react";
import { useSnackbar } from "notistack";
import ManageUser from "../components/administration/ManageUser";
import useAddUser from "../hooks/mutations/useAddUser";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import appIdConstants from "../constants/appIdConstants";

import useAddUserAlertSetting from "../hooks/mutations/useAddUserAlertSetting";

import useAddUserAccessPermission from "../hooks/mutations/useAddUserAccessPermission";
import useAddCaseNestUserAlertSetting from "../hooks/mutations/useAddCaseNestUserAlertSetting";
import { useAuth } from "../contexts/authContext";

const AddUser = () => {
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const history = useHistory();
    const { siteId } = useSelector((state) => state.admin);

    const { enqueueSnackbar } = useSnackbar();
    const { mutate, isLoading } = useAddUser();
    const addUserAlertSetting = useAddUserAlertSetting();
    const addUserAccessPermission = useAddUserAccessPermission();
    const addCaseNestUserAlertSetting = useAddCaseNestUserAlertSetting();

    const { isCaseNestAdministratorForSites } = useAuth();
    const { selectedAccount } = useSelector((state) => state.account);
    const isCaseNestAdministrator = isCaseNestAdministratorForSites(selectedAccount?.childExternalIds);

    const handleAddUser = async ({
        firstName,
        lastName,
        title,
        email,
        phone,
        mobile,
        employmentStatus,
        roles,
        adviceTypes,
        appIds,
        alertSettings,
        safetyNestAccessModules,
        canAccessChildSites,
        caseNestAlertSettings,
    }) => {

        setIsSaveDisabled(true);

        const newUser = {
            accountId: siteId,
            firstName,
            lastName,
            title,
            email,
            phone,
            mobile,
            contactStatus: employmentStatus,
            roles,
            addedSaasAppIds: appIds,
            canAccessChildSites,
            adviceTypes,
        };

        mutate(newUser, {
            onSuccess: (newUserId) => {
                if (isCaseNestAdministrator && appIds.includes(appIdConstants.casenest)) {
                    const newCaseNestUserAlertSettings = {
                        userExternalId: newUserId?.contactExternalId,
                        ...caseNestAlertSettings
                    }
                    addCaseNestUserAlertSetting.mutate(newCaseNestUserAlertSettings, {
                        onError: (error) => {
                            console.error(error);
                            enqueueSnackbar(error?.message || "There was an error creating the CaseNest user alert settings.", { variant: "error" });
                        }
                    })
                }
                if (appIds.includes(appIdConstants.safetynest)) {
                    const newUserAlertSettings = {
                        userExternalId: newUserId?.contactExternalId,
                        ...alertSettings,
                    };
                    const newUserAccessPermission = {
                        userExternalId: newUserId?.contactExternalId,
                        ...safetyNestAccessModules,
                    };
                    addUserAccessPermission.mutate(newUserAccessPermission, {
                        onSuccess: () => {
                            addUserAlertSetting.mutate(newUserAlertSettings, {
                                onSuccess: () => {
                                    enqueueSnackbar("Changes saved successfully!", { variant: "success" });
                                    history.goBack();
                                    setIsSaveDisabled(false);
                                },
                                onError: (error) => {
                                    console.error(error);
                                    enqueueSnackbar(error?.message || "There was an error creating the SafetyNest user alert settings.", { variant: "error" });
                                },
                            });
                        },
                        onError: (error) => {
                            console.error(error);
                            enqueueSnackbar(error?.message || "There was an error creating the user access permission.", { variant: "error" });
                        },
                    });
                } else {
                    enqueueSnackbar("Changes saved successfully!", { variant: "success" });
                    history.goBack();
                    setIsSaveDisabled(false);
                }
            },
            onError: (error) => {
                console.error(error);
                enqueueSnackbar(error?.message || "There was an error creating the user.", { variant: "error" });
            },
        });
    };
    return siteId.length === 0 ? (
        <Redirect to="/administration" />
    ) : (
        <ManageUser
            onSubmit={handleAddUser}
            accountId={siteId}
            isLoading={isLoading}
            isSaveDisabled={isSaveDisabled}
        />
    );
};

export default AddUser;
