import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import salesforceService from "../../services/salesforceService";

const useSalesforceWelcomePack = ({ orgId }) =>
  useQuery(
    [queryKeys.salesforceWelcomePackInfo, orgId],
    () => salesforceService.getWelcomePackInfo(orgId),
    {
      enabled: !!orgId,
    }
  );

export default useSalesforceWelcomePack;
