import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import auditService from "../../services/auditService";

const useSiteMeridianAudits = ({ siteExternalId, pageNum, pageSize }) => 
    useQuery(
        [queryKeys.siteMeridianAudits, siteExternalId, pageNum, pageSize],
        () => auditService.getBySiteExternalId({ siteExternalId, pageNum, pageSize }),
        {
            enabled: !!siteExternalId,
        }
    );

export default useSiteMeridianAudits;