import { useMutation } from "react-query";
import riskAssessmentService from "../../services/riskAssessmentService";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";

const { client } = reactQueryUtils;
const useDeleteRiskAssessment = () =>
  useMutation(riskAssessmentService.deleteRiskAssessment, {
    onMutate: async ({
      riskAssessmentId,
      siteExternalId,
      pageNum,
      pageSize,
      riskLevel,
    }) => {
      const queryKey = [
        queryKeys.riskAssessments,
        siteExternalId,
        pageNum,
        pageSize,
        riskLevel,
      ];
      await client.cancelQueries(queryKey);
      const previousRiskAssessments = client.getQueryData(queryKey);

      if (previousRiskAssessments) {
        client.setQueryData(queryKey, (riskAssessments) => {
          const validRiskAssessments = riskAssessments?.results?.filter(
            (riskAssessment) => riskAssessment.id !== riskAssessmentId
          ) ?? [];
          return {
            totalCount: validRiskAssessments?.length,
            results: validRiskAssessments,
          };
        });
      }
      return { previousRiskAssessments, queryKey };
    },
    onError: (error, riskAssessment, context) => {
      client.setQueryData(context.queryKey, context.previousRiskAssessments);
    },
    onSettled: (_data, _error, context) => {
      client.invalidateQueries(context.queryKey);
    },
  });

export default useDeleteRiskAssessment;
