import { useMutation } from "react-query";
import riskAssessmentService from "../../services/riskAssessmentService";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";
import { formatISODateTime } from "../../../utils/dateTimeUtils";

const { client } = reactQueryUtils;
const useAmendRiskAssessment = () =>
  useMutation(riskAssessmentService.amendRiskAssessment, {
    onMutate: async ({
      riskAssessmentId,
      startDate,
      frequency,
      assignedEmployeeExternalId,
      siteExternalId,
      pageNum,
      pageSize,
      riskLevel,
    }) => {
      const queryKey = [
        queryKeys.riskAssessments,
        siteExternalId,
        pageNum,
        pageSize,
        riskLevel,
      ];
      await client.cancelQueries(queryKey);
      const previousRiskAssessments = client.getQueryData(queryKey);

      client.setQueryData(queryKey, (riskAssessments) => {
        const validRiskAssessments = riskAssessments?.results?.map(
          (riskAssessment) =>
            riskAssessment.id !== riskAssessmentId
              ? riskAssessment
              : {
                  ...riskAssessment,
                  siteExternalId,
                  startDate: formatISODateTime(startDate),
                  frequency,
                  assignedEmployeeExternalId,
                }
        ) ?? [];

        return {
          totalCount: validRiskAssessments?.length,
          results: validRiskAssessments,
        };
      });
      return { previousRiskAssessments, queryKey };
    },
    onError: (error, riskAssessment, context) => {
      client.setQueryData(context.queryKey, context.previousRiskAssessments);
    },
    onSettled: (_data, _error, context) => {
      client.invalidateQueries(context.queryKey);
    },
  });

export default useAmendRiskAssessment;
