import { useQuery } from "react-query";
import userAlertSettingService from "../../services/userAlertSettingService";
import queryKeys from "../../constants/queryKeys";

const useUserAlertSetting = ({ userExternalId, hasSafetynest }) =>
  useQuery(
    [queryKeys.userAlertSetting, userExternalId],
    () => userAlertSettingService.getByUserExternalId(userExternalId),
    {
      enabled: !!userExternalId && hasSafetynest,
    }
  );

export default useUserAlertSetting;
