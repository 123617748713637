export const answerTypes = {
  DROPDOWN: 0,
  TEXT: 1,
  RADIO: 2,
  NUMBER: 3,
  DATE: 4,
  MULTISELECT: 5,
};

export const answerTypeDropdownValues = [
  {
    value: answerTypes.TEXT,
    label: "Text",
  },
  {
    value: answerTypes.DROPDOWN,
    label: "Dropdown",
  },
  {
    value: answerTypes.RADIO,
    label: "Radio Buttons",
  },
  {
    value: answerTypes.NUMBER,
    label: "Number",
  },
  {
    value: answerTypes.DATE,
    label: "Date",
  },
  {
    value: answerTypes.MULTISELECT,
    label: "Multi Select",
  },
];
