import React from "react";
import { NavigateBefore } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

function PreviousButton(props) {
  return (
    <IconButton {...props}>
      <NavigateBefore />
    </IconButton>
  );
}

export default PreviousButton;
