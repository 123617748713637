import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import riskAssessmentService from "../../services/riskAssessmentService";

const useRiskAssessmentWithHistory = ({ riskAssessmentId, withHistory }) =>
  useQuery(
    [
      queryKeys.riskAssessment,
      riskAssessmentId,
      withHistory,
    ],
    () => riskAssessmentService.fetchRiskAssessmentWithHistory(riskAssessmentId), 
    {
      enabled: !!riskAssessmentId && !!withHistory
    }
  );

export default useRiskAssessmentWithHistory;