import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
    INTERNAL_REDIRECT_URL_KEY,
    WORKNEST_TOKEN_KEY,
    EXTERNAL_REDIRECT_URL_KEY,
    IMPERSONATION_ID_KEY,
    IMPERSONATION_TOKEN_KEY,
} from "../constants/authConfig";
import LoginLoader from "../components/LoginLoader";
import WorknestTokenError from "../errors/worknestTokenError";
import tokenService from "../services/tokenService";
import { setAuthHeader } from "../utils/axios";
import jwtDecode from "jwt-decode";
import { roles, salesforceRoles } from "../constants/roleConstants";
import requestStatus from "../constants/requestStatus";
import reactQueryUtils from "../utils/reactQueryUtils";
import trackingService from "../services/trackingService";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAccount, setRootAccounts } from "../redux/actions/accountActions";
import accountService from "../services/accountService";
import IsWorkNestTenant from "../utils/isWorkNestTenant";
import { differenceInHours } from "date-fns";
import userAccessPermissionService from "../services/userAccessPermissionService";
import appIdConstants from "../constants/appIdConstants";
import useAccountAppSites from "../hooks/queries/useAccountAppSites";
import webAuth, { scopes } from '../utils/auth0Client'

export const MsalContext = createContext();

const myWorkNestBase =  process.env.REACT_APP_MYWORKNEST_BASE_URL;
const workNestTenantId = process.env.REACT_APP_WORKNEST_TENANT_ID;
const appEnvironment = process.env.REACT_APP_ENV;
const learningNestId = process.env.REACT_APP_LEARNINGNEST_DELTANET_APP_ID;
const claimPrefix = "https://myworknest.com/";

const tokenIsUsable = (tokenExpiry) => {
    const expirationTime = new Date(tokenExpiry * 1000);
    const hoursLeft = differenceInHours(expirationTime, new Date());
    return hoursLeft > 3;
};

const deleteCookieByName = (name) => {
    let cookie = `${name}-${appEnvironment}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    if (window.location.hostname !== "localhost") cookie += ";domain=myworknest.com";

    document.cookie = cookie;
};

const getCookieByName = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}-${appEnvironment}=`);
    if (parts?.length === 2) return parts.pop()?.split(";").shift();
};

const setWorkNestToken = (workNestToken) => {
    setDomainCookie(WORKNEST_TOKEN_KEY, workNestToken);
};

const setDomainCookie = (key, value) => {
    let cookie = `${key}-${appEnvironment}=${value};path=/;max-age=31536000`;
    if (window.location.hostname !== "localhost") cookie += ";domain=myworknest.com";
    document.cookie = cookie;
};

const appsHelper = (apps) => (!apps ? [] : Array.isArray(apps) ? apps : [apps]);

const AuthProvider = ({ children }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { selectedAccount, activeAppId, selectedSite } = useSelector((state) => state.account);
    const [unauthenticatedUserDetails, setUnauthenticatedUserDetails] = useState("");
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [impersonatingUser, setImpersonatingUser] = useState(null);
    const [impersonationRequestStatus, setImpersonationRequestStatus] = useState(requestStatus.IDLE);
    const [impersonationSessionId, setImpersonationSessionId] = useState(null);
    const [impersonationError, setImpersonationError] = useState(null);

    const { data: accountSitesData } = useAccountAppSites({
        childExternalIds: [...(selectedAccount?.childExternalIds || []), selectedAccount?.account.externalId],
        activeAppId,
    });
    
    useEffect(() => {
        if (selectedAccount) {
            initUserDetails(user.token, selectedAccount.childExternalIds);
        }
    }, [selectedAccount]);

    const handleInternalUserLogin = useCallback((userRoles) => {
        let redirectUrl = "/error?type=roles";

        const isCxUser = (Array.isArray(userRoles) && userRoles.includes(roles.CX_ADMIN)) || userRoles === roles.CX_ADMIN;

        const isConsultantUser = (Array.isArray(userRoles) && userRoles.includes(roles.CONSULTANT)) || userRoles === roles.CONSULTANT;

        if (isCxUser) redirectUrl = "/impersonate";
        else if (isConsultantUser) redirectUrl = "/consultant-account-select";

        history.push(redirectUrl);
    }, []);

    const handleClientLogin = useCallback(async (returnTo) => {
        try {
            const rootAccounts = await accountService.fetchAccountsDetailsForUser();
            dispatch(setRootAccounts(rootAccounts));
            if (rootAccounts.length === 1) {
                dispatch(selectAccount(rootAccounts[0]));
                history.push(returnTo);
            } else {
                history.push(`/account-select?returnTo=${returnTo}`);
            }
        } catch (e) {
            throw e;
        }
    }, []);

    const getUserAccessPermission = async (userId) => {
        const data = await userAccessPermissionService.getByUserExternalId(userId);

        return {
            canAccessAudits: data.canAccessAudits,
            canAccessCompliance: data.canAccessCompliance,
            canAccessIncidents: data.canAccessIncidents,
            canAccessMonitoring: data.canAccessMonitoring,
            canAccessRiskAssessments: data.canAccessRiskAssessments,
        };
    };

    const initUserDetails = useCallback((worknestToken, userAccounts = null) => {
        const decoded = jwtDecode(worknestToken);
        const hasSafetyNestAccess = userAccounts?.some((account) => decoded?.apps.includes(`${appIdConstants.safetynest}:${account}`));

        const details = {
            email: decoded.email,
            firstName: decoded.given_name,
            lastName: decoded.family_name,
            accounts: decoded.accounts,
            apps: appsHelper(decoded.apps),
            authMethod: decoded.authentication_method,
            userId: decoded.sub,
            roles: decoded.roles,
            tid: decoded.tid,
            adviceTypes: appsHelper(decoded.account_advice_types)
        };

        if (hasSafetyNestAccess) {
            getUserAccessPermission(details.userId)
                .then((result) => {
                    setUser({
                        details: { ...details, userAccessPermission: result },
                        token: worknestToken,
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            const userObject = {
                details,
                token: worknestToken,
            };
            setUser(userObject);
        }
        setAuthHeader(worknestToken);

        return details;
    }, []);

    const initialiseWorknestUserSession = useCallback(
        (worknestToken, returnTo, isImpersonateSession = false) => {
            return new Promise(async (resolve, reject) => {
                try {
                    if (!isImpersonateSession) {
                        setWorkNestToken(worknestToken);

                        const appRedirect = localStorage.getItem(EXTERNAL_REDIRECT_URL_KEY);
                        if (appRedirect) {
                            localStorage.removeItem(EXTERNAL_REDIRECT_URL_KEY);
                            window.location.href = appRedirect;
                            return;
                        }
                    }

                    const { roles: userRoles, tid } = initUserDetails(worknestToken);

                    if (IsWorkNestTenant(tid)) {
                        handleInternalUserLogin(userRoles);
                        resolve();
                        return;
                    }

                    await handleClientLogin(returnTo);
                } catch (e) {
                    reject(e);
                } finally {
                    setIsLoading(false);
                }
            });
        },
        [initUserDetails, handleInternalUserLogin, handleClientLogin]
    );

    const handleError = useCallback(
        async (error) => {
            setIsLoading(false);

            if (error instanceof WorknestTokenError) {
                console.error(error);
                history.push("/hello");
                return;
            }
        },
        []
    );

    const onAuth0LoginSuccess = useCallback(async (accessToken, returnTo = '/') => {
        try {
            const decoded = jwtDecode(accessToken);
            setUnauthenticatedUserDetails({
                name: decoded[`${claimPrefix}givenname`],
                email: decoded[`${claimPrefix}email`],
            });
            const workNestToken = await tokenService.auth0WorkNestTokenExchange(accessToken);
            await initialiseWorknestUserSession(workNestToken, returnTo);
        } catch (error) {
            handleError(new WorknestTokenError(error));
        }


    }, []);

    useEffect(() => {
        async function handleSessionInit() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const isLogout = urlParams.get("isLogout");

                if (isLogout?.toLowerCase() === "true") {
                    deleteCookieByName(WORKNEST_TOKEN_KEY);
                    history.push("/");
                    setIsLoading(false);
                    return;
                }

                const appRedirect = urlParams.get("appRedirect");

                if (appRedirect) localStorage.setItem(EXTERNAL_REDIRECT_URL_KEY, appRedirect);

                /* if any url with parameters, set in local storage to persist */
                if (!localStorage.getItem(INTERNAL_REDIRECT_URL_KEY) && (location.pathname !== "/" || window.location.search)) {
                    let pathname = location.pathname + window.location.search;
                    localStorage.setItem(INTERNAL_REDIRECT_URL_KEY, pathname);
                }

                const impersonationTokenCookie = getCookieByName(IMPERSONATION_TOKEN_KEY);
                const impersonationIdCookie = getCookieByName(IMPERSONATION_ID_KEY);

                if (impersonationTokenCookie && impersonationIdCookie) {
                    const { given_name, family_name, email, exp } = jwtDecode(impersonationTokenCookie);
                    if (tokenIsUsable(exp)) {
                        await setImpersonationSessionDetails({
                            sessionId: parseInt(impersonationIdCookie),
                            userDetails: { name: `${given_name} ${family_name}`, email },
                            token: impersonationTokenCookie,
                        });
                        return;
                    }
                }

                if (window.location.hash){
                    webAuth.parseHash((hashError, hashResult) => {
                       window.location.hash = ''
                        if (!hashError && hashResult) {
                            onAuth0LoginSuccess(hashResult.accessToken, hashResult.appState.returnTo)
                        }
                    })
                  } else {
                    webAuth.checkSession({ scope: scopes }, (sessionError, sessionResult) => {
                        if (!sessionError && sessionResult) {
                            let returnTo = sessionResult.appState.returnTo;
                            if (!returnTo && localStorage.getItem(INTERNAL_REDIRECT_URL_KEY)) {
                                returnTo = localStorage.getItem(INTERNAL_REDIRECT_URL_KEY);
                                localStorage.removeItem(INTERNAL_REDIRECT_URL_KEY);
                            }
                            onAuth0LoginSuccess(sessionResult.accessToken, returnTo); 
                        }
                        else{
                            history.push('/')
                            setIsLoading(false)
                        }
                    })
                  }
            } catch (error) {
                handleError(error);
            }
        }

        handleSessionInit();
    }, [handleError, onAuth0LoginSuccess]);

    const resetPassword = ({ email }) => {
        return new Promise((resolve, reject) => {
                webAuth.changePassword({
                    connection: 'Username-Password-Authentication',
                    email,
                }, (err, result) => {
                    if (err) {
                        reject(err); 
                    } else {
                        resolve(result); 
                    }
                });

        });
    };

    const loginViaLocalAccount = async ({ email, password }) => {
        const returnTo = localStorage.getItem(INTERNAL_REDIRECT_URL_KEY) ?? '/'
        localStorage.removeItem(INTERNAL_REDIRECT_URL_KEY);

        return new Promise((resolve, reject) => {
            webAuth.login({
                realm: 'Username-Password-Authentication',
                email,
                password,
                appState: {
                    returnTo
                }
            }, (err, res) => {
                if (err) {
                    reject(err); 
                } else {
                    resolve(res); 
                }
            });
        });
    }    

    const loginViaConnection = ({connection}) => {
        const returnTo = localStorage.getItem(INTERNAL_REDIRECT_URL_KEY) ?? '/'
        localStorage.removeItem(INTERNAL_REDIRECT_URL_KEY);

        webAuth.authorize({
            connection,
            scope: scopes,
            prompt: 'select_account',
            redirectUri: myWorkNestBase,
            appState: {
                returnTo
            }
        })
    }

    const logout = async () => {
        deleteCookieByName(WORKNEST_TOKEN_KEY);
        webAuth.logout({returnTo: myWorkNestBase});
    };

    const setImpersonationSessionDetails = async ({ sessionId, userDetails, token }) => {
        setImpersonationSessionId(sessionId);
        setImpersonatingUser(userDetails);

        await initialiseWorknestUserSession(token, '/', true);
    };

    const impersonateUser = async ({ userId, name, email }) => {
        try {
            setImpersonationRequestStatus(requestStatus.PENDING);
            const { token } = await tokenService.getImpersonationToken(email);
            const sessionId = await trackingService.startImpersonationSession(userId);
            const userDetails = {
                name,
                email,
            };

            setDomainCookie(IMPERSONATION_ID_KEY, sessionId);
            setDomainCookie(IMPERSONATION_TOKEN_KEY, token);

            await setImpersonationSessionDetails({ sessionId, userDetails, token });

            reactQueryUtils.client.clear();
            setImpersonationRequestStatus(requestStatus.RESOLVED);
        } catch (e) {
            setImpersonatingUser(null);
            setImpersonationSessionId(null);
            setImpersonationRequestStatus(requestStatus.REJECTED);
            setImpersonationError(e);
            throw e;
        }
    };

    const endImpersonationSession = async () => {
        try {
            deleteCookieByName(IMPERSONATION_ID_KEY);
            deleteCookieByName(IMPERSONATION_TOKEN_KEY);
            await trackingService.endImpersonationSession(impersonationSessionId);
            window.location.reload();
        } catch (e) {
            console.error(e);
            throw new Error("Could not end impersonation session properly.");
        }
    };

    const clearUnauthenticatedUserDetails = () => {
        setUnauthenticatedUserDetails(null);
        webAuth.logout({ returnTo: myWorkNestBase });
    }

    const hasRole = (role) => {
        if (!user?.details?.roles) return false;

        if (Array.isArray(user.details.roles)) return user.details.roles.includes(role);

        return user.details.roles === role;
    };

    const isWorknestUser = user && user.details.tid === workNestTenantId;

    const isCxAdmin = hasRole(roles.CX_ADMIN);
    const isConsultant = hasRole(roles.CONSULTANT);
    const isTutorialAdmin = hasRole(roles.TUTORIAL_ADMIN);

    const isTemplateAdminForSites = (siteIds = []) => {
        return siteIds.some((siteId) => hasRole(`${salesforceRoles.SAFETYNEST_AMENDTEMPLATES}:${siteId}`));
    };

    const isViewAllCasesAllowedForSites = (siteIds = []) => {
        return siteIds.some((siteId) => hasRole(`${salesforceRoles.CASENEST_ALLCASES}:${siteId}`));
    };

    const isAdministratorForSites = (siteIds = []) => {
        return siteIds.some(
            (siteId) =>
                hasRole(`${salesforceRoles.CASENEST_ADMINISTRATOR}:${siteId}`) ||
                hasRole(`${salesforceRoles.SAFETYNEST_ADMINISTRATOR}:${siteId}`) ||
                hasRole(`${salesforceRoles.MYWORKNEST_ADMINISTRATOR}:${siteId}`)
        );
    };

    const isCaseNestAdministratorForSites = (siteIds = []) => {
        return siteIds.some((siteId) => hasRole(`${salesforceRoles.CASENEST_ADMINISTRATOR}:${siteId}`));
    };

    const isSafetyNestAdministratorForSites = (siteIds = []) => {
        return siteIds.some((siteId) => hasRole(`${salesforceRoles.SAFETYNEST_ADMINISTRATOR}:${siteId}`));
    };

    const isMyWorkNestAdministratorForSites = (siteIds = []) => {
        return siteIds.some((siteId) => hasRole(`${salesforceRoles.MYWORKNEST_ADMINISTRATOR}:${siteId}`));
    };

    const hasAnySitesRoles = (siteIds = [], roles) => siteIds.some((siteId) => roles.map((role) => hasRole(`${role}:${siteId}`)).includes(true));

    const hasSafetyNestAllActionsRole = useMemo(() => {
        const siteIds = selectedSite ? [selectedSite.externalId] : accountSitesData?.map((x) => x.externalId);
        return siteIds?.some((siteId) => {
            const site = accountSitesData.filter((x) => x.externalId === siteId)[0];
            return site?.roles?.includes(salesforceRoles.SAFETYNEST_ALLACTIONS);
        });
    }, [accountSitesData, selectedSite]);

    const adminUserSelectedAccountApps = (accountId) => {
        let appIds = [];

        if (!user?.details?.apps?.length || !selectedAccount?.childExternalIds)
            return [];

        user.details.apps.forEach((app) => {
            const appId = app.split(":")[0];
            const appAccountId = app.split(":")[1];
            if (appAccountId == accountId && appId == learningNestId)
                appIds.push(appId)
            else if (appId != learningNestId && selectedAccount.childExternalIds.includes(appAccountId)) {
                appIds.push(appId);
            }
        });
        return appIds;
    };


    const adminUserAdviceTypes = () => {

        let adviceTypes = [];

        if (!user?.details?.adviceTypes?.length || !selectedAccount?.childExternalIds)
            return [];       

        user.details.adviceTypes.forEach((entry) => {
            const adviceType = entry.split(":")[0];
            const appAccountId = entry.split(":")[1];
            if (selectedAccount.childExternalIds.includes(appAccountId) && !adviceTypes.includes(adviceType)) {
                adviceTypes.push(adviceType);
            }
        });

        return adviceTypes;
    };

    if (isLoading) return <LoginLoader />;

    const contextValue = {
        loginViaLocalAccount,
        loginViaConnection,
        resetPassword,
        isLoading,
        user,
        logout,
        isCxAdmin,
        isConsultant,
        isTutorialAdmin,
        impersonateUser,
        impersonatingUser,
        isImpersonationLoading: impersonationRequestStatus === requestStatus.PENDING,
        isImpersonationError: impersonationRequestStatus === requestStatus.REJECTED,
        impersonationError,
        endImpersonationSession,
        isTemplateAdminForSites,
        isAdministratorForSites,
        isCaseNestAdministratorForSites,
        isSafetyNestAdministratorForSites,
        isMyWorkNestAdministratorForSites,
        isViewAllCasesAllowedForSites,
        hasAnySitesRoles,
        isWorknestUser,
        unauthenticatedUserDetails,
        clearUnauthenticatedUserDetails,
        adminUserSelectedAccountApps,
        hasSafetyNestAllActionsRole,
        adminUserAdviceTypes,
    };

    return <MsalContext.Provider value={contextValue}>{children}</MsalContext.Provider>;
};

const useAuth = () => useContext(MsalContext);
export { AuthProvider, useAuth };
