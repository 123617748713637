import { makeStyles, Typography } from "@material-ui/core"
import { NavigateBefore } from "@material-ui/icons"
import { useHistory } from "react-router"
import CardBase from "../../../components/cards/CardBase"
import { useSiteSearchContext } from "../../contexts/SiteSearchContext"
import MeridianAuditsTable from "../../components/meridianaudits/MeridianAuditsTable"

const useStyles = makeStyles(theme => ({
    navigation: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        maxWidth: 'max-content',
    },
    siteDetails: {
        marginBottom: theme.spacing(2),
    },
}))

export default function MeridianAudits() {
    const history = useHistory()
    const classes = useStyles()

    const { 
        selectedSite,
    } = useSiteSearchContext()

    function handleBackClick() {
        history.goBack()
    }

    function renderNavigation() {
        return (
            <Typography 
                className={classes.navigation} 
                variant="subtitle2" 
                color="secondary"
                onClick={handleBackClick}
            >
                <NavigateBefore />
                Back to search results
            </Typography>
        )
    }

    function renderSiteDetails() {
        return (
            <div className={classes.siteDetails}>
                <Typography variant="h5">
                    {selectedSite?.name}
                </Typography>
                <Typography variant="body2">
                    {selectedSite?.rootParentName ?? selectedSite?.name ?? 'Unknown'}
                </Typography>
            </div>
        )
    }

    return (
        <div>
            {renderNavigation()}
            {renderSiteDetails()}
            <CardBase
                title={'Audits'}
            >
                <MeridianAuditsTable />
            </CardBase>
        </div>
    )
}