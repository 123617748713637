import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { useTracking } from "react-tracking";
import trackingConstants from "../constants/trackingConstants";
import { setActiveAppId } from "../redux/actions/accountActions";
import {useDispatch, useSelector} from "react-redux";
import appIds from "../constants/appIdConstants";
import { IncidentProvider } from "./contexts/IncidentContext";
import RouteMapper from "../components/RouteMapper";
import { routes } from "./routes";
import { RiskAssessmentProvider } from "./contexts/RiskAssessmentContext";
import userLoginLogService from "./services/userLoginLogService";
import {useAuth} from "../contexts/authContext";
import { ActionProvider } from "./contexts/ActionContext";
import { HistoricAuditProvider } from "./contexts/HistoricAuditContext";
import { HistoricActionProvider } from "./contexts/HistoricActionContext";
import { CreateActionProvider } from "./contexts/CreateActionContext";
import { SiteSearchProvider } from "./contexts/SiteSearchContext";

function SafetynestIndex() {
  const dispatch = useDispatch();
  const { Track } = useTracking({
    app: trackingConstants.safetynestAppName,
  });
  const { user, isWorknestUser } = useAuth();
  const { selectedAccount } = useSelector(
    (state) => state.account
  );
  const userExternalId = user?.details?.userId;
  const accountExternalId = selectedAccount?.account?.externalId;

  useEffect(() => {
    dispatch(setActiveAppId(appIds.safetynest));
  }, [dispatch]);
  
  useEffect(() => {
    async function logUserLogin() {
      if (isWorknestUser)
        return;
      
      try {
        await userLoginLogService.addUserLoginLog({ accountExternalId, userExternalId });
      }
      catch(e) {
        console.error(e);
      }
    }
    
    logUserLogin();
  }, []);

  return (
    <Track>
      <IncidentProvider>
        <RiskAssessmentProvider>
          <ActionProvider>
           <SiteSearchProvider>
            <CreateActionProvider>
              <HistoricAuditProvider>
                <HistoricActionProvider>
                  <Switch>
                    <RouteMapper routes={routes} />
                  </Switch>
                </HistoricActionProvider>
              </HistoricAuditProvider>
            </CreateActionProvider>
           </SiteSearchProvider>
          </ActionProvider>
        </RiskAssessmentProvider>
      </IncidentProvider>
    </Track>
  );
}

export default SafetynestIndex;
