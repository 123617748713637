import axios from "../../utils/axios";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const fetchAllPeople = () => axios.get(`${apiUrl}/People/GetAll`);

const peopleService = {
    fetchAllPeople,
};

export default peopleService;
