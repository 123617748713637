import { makeStyles, Typography } from "@material-ui/core";
import CardBase from "../../components/cards/CardBase";
import useSitesByNameSearch from "../hooks/queries/useSitesByNameSearch";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from "react-router";
import { useSiteSearchContext } from "../contexts/SiteSearchContext";

const useStyles = makeStyles(theme => ({
    siteItem: {
        display: 'flex',
        '&:hover': {
            backgroundColor: theme.palette.grey[50],
            cursor: 'pointer',
        },
        alignItems: 'center',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1),
    },
    siteLink: {
        marginLeft: 'auto',
    },
    siteList: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
}))

export default function SearchResults({ isHistoric }) {
    const classes = useStyles()
    const history = useHistory()

    const { siteSearchValue, setSelectedSite } = useSiteSearchContext() 

    const sites = useSitesByNameSearch(siteSearchValue)

    function handleSiteClick(site) {
        setSelectedSite(site)
        history.push(`/safetynest/${isHistoric ? 'historic-' : 'meridian-'}audits/${site.externalId}`)
    }

    function noResults() {
        if (sites?.data?.length === 0) {
            return (
                <Typography variant="body2">
                    No results found.
                </Typography>
            )
        }
    } 

    return (
        <CardBase
            title={'Results'}
            isLoading={sites?.isLoading}
        >
            {noResults()}
            <div className={classes.siteList}>
                {sites?.data?.map(site => (
                    <div className={classes.siteItem} key={site.id} onClick={() => handleSiteClick(site)}>
                        <div>
                            <Typography variant="subtitle2">
                                {site.name}
                            </Typography>
                            <Typography variant="body2" color="secondary">
                                {site.rootParentName ?? site.name ?? 'Unknown'}
                            </Typography>
                        </div>
                        <div className={classes.siteLink}>
                            <NavigateNextIcon />
                        </div>
                    </div>
                ))}
            </div>
        </CardBase>
    )
}