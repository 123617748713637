import React from "react";
import {
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@material-ui/core";

function EnhancedTableHead({ headCells, sortColumn, sortDirection, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell.id}
                            sortDirection={headCell.isSortable && sortColumn === headCell.id ? sortDirection : false}
                            align={headCell?.align || "inherit"}
                        >
                            {headCell.isSortable ? (
                                <TableSortLabel
                                    active={sortColumn === headCell.id}
                                    direction={sortColumn === headCell.id ? sortDirection : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                            ) : (
                                headCell.label
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;
