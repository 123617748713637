import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FormSection from "../formcompleter/FormSection";

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
  errorContainer: {
    marginTop: theme.spacing(2),
  },
  sectionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

function IncidentForm({incidentForm}) {
    const classes = useStyles();

  if (incidentForm.isLoading)
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );

  if (incidentForm.isError)
    return (
      <div className={classes.errorContainer}>
        <Alert severity="error">
          {incidentForm.error?.message ?? "Could not load incident form."}
        </Alert>
      </div>
    );

  return (
    <div className={classes.sectionsContainer}>
      {incidentForm.data?.templateVersion?.sections.map((section) => (
        <FormSection key={section.id} section={section} flat />
      ))}
    </div>
  );
}

export default IncidentForm;
