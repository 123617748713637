export const SET_ROOT_ACCOUNTS = "SET_ROOT_ACCOUNTS";
export const SELECT_SITE = "SELECT_SITE";
export const SELECT_ACCOUNT = "SELECT_ACCOUNT";
export const SET_MENTIONS = "SET_MENTIONS";

export const SET_ACTIVE_APPID = "SET_ACTIVE_APPID";
export const SET_SITES = "SET_SITES";
export const HIDE_CASENEST_TERMS_BANNER = "HIDE_CASENEST_TERMS_BANNER";

// template builder
export const CLEAR_TEMPLATE = "CLEAR_TEMPLATE";
export const SET_SECTION_TEXT = "SET_SECTION_TEXT";
export const REORDER_SECTION = "REORDER_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";
export const ADD_SECTION = "ADD_SECTION";
export const ADD_EDITABLE_SECTION = "ADD_EDITABLE_SECTION";
export const INSERT_SECTION = "INSERT_SECTION";
export const ADD_QUESTION = "ADD_QUESTION";
export const INSERT_QUESTION = "INSERT_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const REORDER_QUESTION = "REORDER_QUESTION";
export const SET_QUESTION_TEXT = "SET_QUESTION_TEXT";
export const SET_QUESTION_MIN_VALUE = "SET_QUESTION_MIN_VALUE";
export const SET_QUESTION_MAX_VALUE = "SET_QUESTION_MAX_VALUE";
export const ADD_QUESTION_OPTION = "ADD_QUESTION_OPTION";
export const DELETE_QUESTION_OPTION = "DELETE_QUESTION_OPTION";
export const SET_QUESTION_OPTION_TEXT = "SET_QUESTION_OPTION_TEXT";
export const SET_QUESTION_OPTION_REQUIRES_ADDITIONAL_INFO = "SET_QUESTION_REQUIRES_ADDITIONAL_INFO";
export const SET_QUESTION_ANSWER_TYPE = "SET_QUESTION_ANSWER_TYPE";
export const SET_QUESTION_OPTIONS = "PRESET_QUESTION_OPTIONS";
export const SET_QUESTION_ALLOW_PHOTO_UPLOAD = "SET_QUESTION_ALLOW_PHOTO_UPLOAD";
export const SET_QUESTION_ATTACHMENT_MANDATORY = "SET_QUESTION_ATTACHMENT_MANDATORY";
export const SET_QUESTION_MANDATORY = "SET_QUESTION_MANDATORY";
export const SET_QUESTION_HELP_INFO_REQUIRED = "SET_QUESTION_HELP_INFO_REQUIRED";
export const SET_QUESTION_HELP_INFO_TEXT = "SET_QUESTION_HELP_INFO_TEXT";
export const ADD_OUTCOME = "ADD_OUTCOME";
export const DELETE_OUTCOME = "DELETE_OUTCOME";
export const SET_CHOICE_OUTCOME_OPTION_ID = "SET_CHOICE_OUTCOME_VALUE";
export const SET_OUTCOME_LOGIC_OPERATOR = "SET_OUTCOME_LOGIC_OPERATOR";
export const SET_NUMBER_OUTCOME_LOGIC_OPERATOR = "SET_NUMBER_OUTCOME_LOGIC_OPERATOR";
export const SET_OUTCOME_ACTION_PRESET_ID = "SET_OUTCOME_ACTION_PRESET_ID";
export const SET_NUMBER_OUTCOME_VALUE1 = "SET_NUMBER_OUTCOME_VALUE1";
export const SET_NUMBER_OUTCOME_VALUE2 = "SET_NUMBER_OUTCOME_VALUE2";
export const CLEAR_TEMPLATE_OUTCOMES = "CLEAR_TEMPLATE_OUTCOMES";
export const SET_TEMPLATE_APPLICATION_AREA = "SET_TEMPLATE_APPLICATION_AREA";
export const SET_OUTCOME_RATING = "SET_OUTCOME_RATING";
export const SET_TEMPLATE_NAME = "SET_TEMPLATE_NAME"
export const SET_TEMPLATE_FREQUENCY = "SET_TEMPLATE_FREQUENCY"
export const SET_TEMPLATE_SECTOR = "SET_TEMPLATE_SECTOR"
export const SET_TEMPLATE_SUBJECT = "SET_TEMPLATE_SUBJECT"
export const SET_INCIDENT_TYPE = "SET_INCIDENT_TYPE"
export const SET_IS_GLOBAL_TEMPLATE = "SET_IS_GLOBAL_TEMPLATE"
export const SET_TEMPLATE_FREQUENCY_INTERVAL = "SET_TEMPLATE_FREQUENCY_INTERVAL"
export const SET_TEMPLATE_FREQUENCY_DAYS = "SET_TEMPLATE_FREQUENCY_DAYS"

// form completer
export const SET_DATE_RESPONSE = "SET_DATE_RESPONSE";
export const SET_NUMBER_RESPONSE = "SET_NUMBER_RESPONSE";
export const SET_TEXT_RESPONSE = "SET_TEXT_RESPONSE";
export const SET_OPTION_RESPONSE = "SET_OPTION_RESPONSE";
export const SET_MULTISELECT_RESPONSE = "SET_MULTISELECT_RESPONSE";
export const CLEAR_MULTISELECT_RESPONSE = "CLEAR_MULTISELECT_RESPONSE";
export const SET_QUESTION_FILES = "SET_QUESTION_FILES";
export const SET_QUESTION_ADDITIONAL_INFO = "SET_QUESTION_ADDITIONAL_INFO";
export const CLEAR_FORM = "CLEAR_FORM";
export const INIT_QUESTION_ACTIONS = "INIT_QUESTION_ACTIONS";
export const SET_ACTION_DUE_DATE = "SET_ACTION_DUE_DATE";
export const SET_ACTION_ASSIGNED_TO_EMPLOYEE = "SET_ACTION_ASSIGNED_TO_EMPLOYEE";
export const SET_EXISTING_RESPONSES = "SET_EXISTING_RESPONSES";
export const SET_ACTION_RATING = "SET_ACTION_RATING";

// page
export const SET_PAGE = "SET_PAGE";

// tutorial
export const SET_IS_TUTORIAL_OPEN = "SET_IS_TUTORIAL_OPEN";
export const SET_TUTORIAL = "SET_TUTORIAL";

// cookie consent dialog
export const SET_COOKIE_JAR_OPEN = "SET_COOKIE_JAR_OPEN";
export const SET_COOKIE_JAR_STEP = "SET_COOKIE_JAR_STEP";

// documents
export const SET_SELECTED_FOLDER = "SET_SELECTED_FOLDER";

// table page
export const SET_TABLE_PAGE = "SET_TABLE_PAGE";
export const SET_CASES_TABLE_PAGE = "SET_CASES_TABLE_PAGE";
export const SET_CONTACT_CASES_TABLE_PAGE = "SET_CONTACT_CASES_TABLE_PAGE";
export const SET_ACTIONS_TABLE_PAGE = "SET_ACTIONS_TABLE_PAGE";
export const SET_COMPLIANCE_MONITORING_ACTIONS_TABLE_PAGE = "SET_COMPLIANCE_MONITORING_ACTIONS_TABLE_PAGE";
export const SET_RISK_ASSESSMENTS_TABLE_PAGE = "SET_RISK_ASSESSMENTS_TABLE_PAGE";
export const SET_FORM_INSIGHTS_TABLE_PAGE = "SET_FORM_INSIGHTS_TABLE_PAGE";
export const SET_FORMS_TABLE_PAGE = "SET_FORMS_TABLE_PAGE";
export const SET_INCIDENTS_TABLE_PAGE = "SET_INCIDENTS_TABLE_PAGE,";

// admin
export const SET_SITE_ID = "SET_SITE_ID";
export const SET_USER_TO_MANAGE = "SET_USER_TO_MANAGE";
export const SET_RESET_USER = "SET_RESET_USER";
