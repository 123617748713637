import axios from "../../utils/axios";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const createQRCode = (url) => {
    return axios.get(`${apiUrl}/action/qrcode`, { params: { url } })
};

const qrCodeService = { 
  createQRCode,
};

export default qrCodeService;
