import React from "react";
import {
    Typography,
    Button,
    makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { sidebarButtons, baseRoute } from "../../safetynest/routes";
import { useAuth } from "../../contexts/authContext";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({

    buttonsHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
        backgroundColor: theme.palette.primary.main,
    },
    sidebarButtonText: {
        color: "white",
    },
    btnApp: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(18),
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
        color: "white",
    },
}));

function SidebarButton() {
    const classes = useStyles();
    const history = useHistory();

    const handleButtonClick = (url) => {
        history.push(url);
    };
    const { user, hasAnySitesRoles } = useAuth();
    const { selectedAccount } = useSelector((state) => state.account);

    const filteredByRouteRoles = (route) => {
        return !route.requiredSiteRoles ||
            hasAnySitesRoles(
                selectedAccount?.childExternalIds,
                route.requiredSiteRoles
            );
    }

    return (history.location.pathname.startsWith(baseRoute) &&
        <div className={classes.buttonsHeader}>
            {!user?.details?.userAccessPermission?.canAccessIncidents && sidebarButtons.length && (
                sidebarButtons.filter(filteredByRouteRoles).map((button) => (
                    <Button
                        key={button.label}
                        className={classes.btnApp}
                        size="large"
                        fullWidth
                        onClick={() => handleButtonClick(button.path)}
                        startIcon={button.icon}
                    >
                        <Typography className={classes.sidebarButtonText}>{button.label}</ Typography>
                    </Button>
                )))}
        </div>
    );
}

export default SidebarButton;
