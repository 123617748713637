import React, { useState, useCallback, useMemo } from "react";
import {  Grid, Typography, makeStyles } from "@material-ui/core";
import { useTracking } from "react-tracking";
import CardBase from "../../../components/cards/CardBase";
import PageBase from "../../../components/PageBase";
import PageTitle from "../../components/PageTitle";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import RequiresRoleWrapper from "../../../components/RequiresRoleWrapper";
import { useAuth } from "../../../contexts/authContext";
import { useSelector } from "react-redux";
import TemplateFormInsightsTableCard from "../../components/insights/TemplateFormInsightsTableCard";
import moduleAreas from "../../constants/moduleAreaConstants";
import useAccountAppSites from "../../../hooks/queries/useAccountAppSites";

const useStyles = makeStyles(theme => ({
    navigation: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        maxWidth: 'max-content',
    }
}))

const ComplianceInsights = () => {    

    const classes = useStyles();

    const { selectedAccount, activeAppId } = useSelector((state) => state.account);
    const { isTemplateAdminForSites, isWorknestUser } = useAuth();
   
    const { Track } = useTracking({ page: `Insights: Compliance`});

    const isTemplateAdmin = isTemplateAdminForSites(selectedAccount?.childExternalIds);

    const [siteId, setSiteId] = useState();  

    const { data: sites } = useAccountAppSites({
        childExternalIds: [
            ...(selectedAccount?.childExternalIds || []),
            selectedAccount?.account.externalId,
        ],
        activeAppId,
    });


    const siteName = useCallback(externalId => sites.find(x => x.externalId === externalId)?.name, [sites]);

    const title = useMemo(() => (siteId ? siteName(siteId) : "Compliance Forms"), [siteId, siteName]);

    const description = useMemo(() => (siteId ? null : "A real-time snapshot of all ongoing and completed safety forms"), [siteId]);

    return (
        <RequiresRoleWrapper hasRole={isTemplateAdmin || isWorknestUser}>
            <PageBase>
                <Track>
                    <Grid container spacing={2}>
                        {
                            siteId && 
                            <Grid item xs={12}>
                                <Typography
                                    className={classes.navigation}
                                    variant="subtitle2"
                                    color="secondary"
                                    onClick={() => setSiteId()}
                                >
                                        <NavigateBeforeIcon />
                                    Back to all sites
                                </Typography>
                            </Grid>
                        }                        
                        <Grid item xs={12}>                            
                            <CardBase
                                title={
                                    <PageTitle
                                        icon={<BeenhereIcon />}
                                        title={title}
                                        description={description} />
                                }                                
                            />
                        </Grid>       
                        <Grid item xs={12}>
                            <TemplateFormInsightsTableCard
                                moduleArea={moduleAreas.COMPLIANCE}
                                siteId={siteId}
                                setSiteId={setSiteId}
                                siteName={siteName}
                                sites={sites}
                                title={title}
                                description={description}
                            />
                        </Grid>                        
                    </Grid>
                </Track>
            </PageBase>
        </RequiresRoleWrapper>
    );
}

export default ComplianceInsights;
