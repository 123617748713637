import axios from "../../utils/axios";
import { isAzureFile } from "../contexts/HistoricAuditContext";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const getBySiteExternalId = ({ siteExternalId, pageNum, pageSize }) =>
    axios.get(`${apiUrl}/historicaudit/site/${siteExternalId}?pageSize=${pageSize}&pageNum=${pageNum}`);

const update = (historicAuditId, historicAuditDTO) => {
    const formData = new FormData();

    if (historicAuditDTO.document !== null && !isAzureFile(historicAuditDTO.document)) {
        formData.append("document", historicAuditDTO.document);
    }

    formData.append("historicAuditDTO", JSON.stringify(historicAuditDTO));

    return axios.patch(`${apiUrl}/historicaudit/${historicAuditId}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

const create = (historicAuditDTO, siteExternalId) => {
    const formData = new FormData();

    if (historicAuditDTO.document !== null && !isAzureFile(historicAuditDTO.document)) {
        formData.append("document", historicAuditDTO.document);
    }

    formData.append("historicAuditDTO", JSON.stringify(historicAuditDTO));
    formData.append("siteExternalId", siteExternalId ?? "");

    return axios.post(`${apiUrl}/historicaudit/create`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

const archive = (historicAuditId) =>
    axios.delete(`${apiUrl}/historicaudit/${historicAuditId}`);

const historicAuditService = {
    getBySiteExternalId,
    update,
    create,
    archive,
};

export default historicAuditService;