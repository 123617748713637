import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import CustomButton from "../../../components/CustomButton";
import { formatShortDate } from "../../../utils/dateTimeUtils";

const useStyles = makeStyles(() => ({
  title: {
    display: "flex",
    justifyContent: "center",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  buttonWidth: {
    width: "150px",
  },
}));

export default function DueDateWarningDialog({
  open,
  dueDate,
  onBackClick,
  onContinueClick,
}) {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.title}>Due Date</DialogTitle>
      <DialogContent>
        This form is not due to be completed until:{" "}
        <strong>{formatShortDate(dueDate)}</strong>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <CustomButton
          className={classes.buttonWidth}
          color="primary"
          variant="contained"
          onClick={onBackClick}
        >
          Back
        </CustomButton>
        <CustomButton
          className={classes.buttonWidth}
          color="primary"
          variant="contained"
          onClick={onContinueClick}
        >
          Continue
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
