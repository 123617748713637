import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateService from "../../services/templateService";

const useTemplateVersions = ({ templateId }) =>
  useQuery(
    [
      queryKeys.templateVersions,
      templateId,
    ],
    () =>
      templateService.fetchTemplateVersions({ templateId })
  );

export default useTemplateVersions;
