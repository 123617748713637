import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import documentService from "../../services/documentService";

const useDocuments = ({ documentType, accountExternalIds }) =>
  useQuery(
    [queryKeys.documents, documentType],
    () =>
        documentService.getDocuments({
          documentType,
          accountExternalIds,
        }),
    { staleTime: Infinity }
  );

export default useDocuments;
