import {SET_COOKIE_JAR_OPEN, SET_COOKIE_JAR_STEP} from "../types";
import {dialogSteps} from "../../constants/cookieConstants";

const initialState = {
  isOpen: false,
  step: dialogSteps.INITIAL,
}

function cookieReducer(state=initialState, action) {
  switch (action.type) {
    case SET_COOKIE_JAR_OPEN:
      return {
        ...state,
        isOpen: action.payload.isOpen
      }
    case SET_COOKIE_JAR_STEP:
      return {
        ...state,
        step: action.payload.step
      }
    default:
      return state;
  }
}

export default cookieReducer;