import { Button, makeStyles, Typography } from "@material-ui/core"
import { Add, NavigateBefore } from "@material-ui/icons"
import { useHistory } from "react-router"
import CardBase from "../../../components/cards/CardBase"
import DeleteHistoricAuditDialog from "../../components/historicaudits/dialogs/DeleteHistoricAuditDialog"
import HistoricAuditsTable from "../../components/historicaudits/HistoricAuditsTable"
import { useHistoricAuditContext } from "../../contexts/HistoricAuditContext"
import { useSiteSearchContext } from "../../contexts/SiteSearchContext"

const useStyles = makeStyles(theme => ({
    navigation: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        maxWidth: 'max-content',
    },
    siteDetails: {
        marginBottom: theme.spacing(2),
    },
    newAudit: {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(1),
    },
}))

const createHistoricAuditURL = '/safetynest/historic-audit'

export default function HistoricAudits() {
    const history = useHistory()
    const classes = useStyles()

    const { 
        deleteHistoricAuditDialogOpen,
        setDeleteHistoricAuditDialogOpen,
        historicAuditIdToDelete,
        handleDeleteHistoricAudit,
        setIsCreationMode,
    } = useHistoricAuditContext()

    const { 
        selectedSite,
    } = useSiteSearchContext()

    function handleBackClick() {
        history.goBack()
    }
    
    function handleCreateClick() {
        setIsCreationMode(true)
        history.push(createHistoricAuditURL)
    }

    function renderNavigation() {
        return (
            <Typography 
                className={classes.navigation} 
                variant="subtitle2" 
                color="secondary"
                onClick={handleBackClick}
            >
                <NavigateBefore />
                Back to search results
            </Typography>
        )
    }

    function renderSiteDetails() {
        return (
            <div className={classes.siteDetails}>
                <Typography variant="h5">
                    {selectedSite?.name}
                </Typography>
                <Typography variant="body2">
                    {selectedSite?.rootParentName ?? selectedSite?.name ?? 'Unknown'}
                </Typography>
            </div>
        )
    }

    function createHistoricAudit() {
        return (
            <Button 
                className={classes.newAudit}
                variant='text'
                onClick={handleCreateClick}
                color='secondary'
            >
                <Add />
                Create New
            </Button>
        )
    }

    return (
        <div>
            <DeleteHistoricAuditDialog 
                open={deleteHistoricAuditDialogOpen}
                auditId={historicAuditIdToDelete}
                onCancel={() => setDeleteHistoricAuditDialogOpen(false)}
                onDelete={() => handleDeleteHistoricAudit(historicAuditIdToDelete)}
            />
            {renderNavigation()}
            {renderSiteDetails()}
            <CardBase
                title={'Historic Audits'}
                rightComponent={createHistoricAudit()}
            >
                <HistoricAuditsTable />
            </CardBase>
        </div>
    )
}