import React from "react";
import { Button, Icon, makeStyles } from "@material-ui/core";
import { useAuth } from "../contexts/authContext";
import { socialConnectionNames } from "../utils/auth0Client";

const googleLogoUrl = `${process.env.PUBLIC_URL}/images/google-logo.svg`;

const useStyles = makeStyles(() => ({
    googleLoginButton: {
        backgroundColor: "white",
        minHeight: "40px",
        borderRadius: 4,
        variant:"outlined",
        width: "100%",
        fontWeight: 600,
        fontSize: "15px",
        fontFamily: "Segoe UI, Arial, sans-serif",
        color: "black",
        "&:hover": {
            backgroundColor: "white",
        },
        
        lineHeight: "normal",
        boxShadow: "none",
        border: "1px solid #ccc", 
        height: "48px"
    },
    startIcon: {
        marginRight: "12px",
    },
    buttonIcon: {
        height: "100%",
        display: "flex",
        alignItems: "center",
    },
}));

function GoogleLoginButton() {
    const classes = useStyles();
    const { loginViaConnection } = useAuth();

    return (
        <Button
            variant="contained"
            className={classes.googleLoginButton}
            classes={{ startIcon: classes.startIcon }}
            onClick={() => loginViaConnection({ connection: socialConnectionNames.google })}
            startIcon={
                <Icon classes={{ root: classes.buttonIcon }}>
                    <img src={googleLogoUrl} />
                </Icon>
            }
        >
            Sign in with Google
        </Button>
    );
}

export default GoogleLoginButton;
