import axios from "../utils/axios";

const apiUrl = process.env.REACT_APP_LEGAL_URL + "/client";

const getCaseTypes = () =>
  axios.get(`${apiUrl}/caseTypes`);

const caseTypeService = {
  getCaseTypes,
}

export default caseTypeService;
  