import {
  Button,
  Container,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CardBase from "../../../components/cards/CardBase";
import ResponsiveSelect from "../../../components/ui/ResponsiveSelect";
import { frequencyDropdownValues, frequency } from "../../constants/frequencyConstants";
import moduleAreas from "../../constants/moduleAreaConstants";
import { useRiskAssessmentDetails } from "../../contexts/RiskAssessmentContext";
import useAddRiskAssessment from "../../hooks/mutations/useAddRiskAssessment";
import useAllModuleTemplates from "../../hooks/queries/useAllModuleTemplates";
import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import useContactsForAppAndSite from "../../../hooks/useContactsForAppAndSite";

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(2),
  },
  table: {
    marginBottom: theme.spacing(3),
  },
  buttons: {
    padding: theme.spacing(1),
    width: "100%",
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "end",
  },
  datePicker: {
    margin: "0",
  },
}));

export default function AddRiskAssessment() {
  const classes = useStyles();
  const history = useHistory();
  const enqueueSnackbar = useSnackbar();
  const { sites, selectedSite, activeAppId, appSiteIds, selectedAccount } =
    useSelector((state) => state.account);

  const {
    setSelectedRiskAssessmentTemplateId,
    selectedRiskAssessmentTemplateId,
    riskAssessmentTemplate,
    selectedSiteId,
    setSelectedSiteId,
    selectedEmployeeId,
    setSelectedEmployeeId,
    selectedFrequencyId,
    setSelectedFrequencyId,
    startDate,
    setStartDate,
    reset,
  } = useRiskAssessmentDetails();

  const addRiskAssessment = useAddRiskAssessment();
  const templates = useAllModuleTemplates({
    moduleArea: moduleAreas.RISKASSESSMENTS,
    accountId: selectedAccount.account.externalId,
    siteExternalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
  });
  
  const contacts = useContactsForAppAndSite({ selectedSiteId });

  function onCancel() {
    reset();
    history.goBack();
  }

  function onSave(e) {
    e.preventDefault();
    addRiskAssessment.mutate(
      {
        siteExternalId: selectedSiteId,
        assignedEmployeeExternalId: selectedEmployeeId,
        templateId: selectedRiskAssessmentTemplateId,
        frequency: selectedFrequencyId,
        startDate: startDate,
      },
      {
        onSuccess: () => {
          reset();
          history.goBack();
        },
        onError: (error) => {
          console.error(error);
          enqueueSnackbar("Could not save risk assessment.", {
            variant: "error",
          });
        },
      }
    );
  }

  return (
    <Container>
      <CardBase title="Create Risk Assessment">
        <form onSubmit={onSave}>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12} md={6}>
              <ResponsiveSelect
                size="small"
                required
                fullWidth
                label="Select Template"
                options={templates?.data}
                optionValueKey="id"
                optionLabelKey="name"
                value={selectedRiskAssessmentTemplateId}
                onChange={(e) =>
                  setSelectedRiskAssessmentTemplateId(e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ResponsiveSelect
                size="small"
                required
                fullWidth
                label="Select Site"
                options={Object.values(sites)}
                optionValueKey="externalId"
                optionLabelKey="name"
                value={selectedSiteId}
                onChange={(e) => setSelectedSiteId(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ResponsiveSelect
                size="small"
                required
                fullWidth
                label="Select Employee"
                options={Object.values(contacts.data).sort((a, b) => a.firstName.localeCompare(b.firstName)) || []}
                optionValueKey="externalId"
                optionLabelFunction={(x) => `${x.firstName} ${x.lastName}`}
                value={selectedEmployeeId}
                onChange={(e) => setSelectedEmployeeId(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <KeyboardDatePicker
                format="dd/MM/yyyy"
                size="small"
                required
                fullWidth
                className={classes.datePicker}
                label="Start Date"
                inputVariant="outlined"
                value={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ResponsiveSelect
                size="small"
                required
                fullWidth
                label="Select Frequency"
                options={frequencyDropdownValues.filter((s) => s.value !== frequency.NONE)}
                optionValueKey="value"
                optionLabelKey="label"
                value={selectedFrequencyId}
                onChange={(e) => setSelectedFrequencyId(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} className={classes.table}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableCell>Template Name</TableCell>
                    <TableCell>Sector</TableCell>
                    <TableCell>Description</TableCell>
                  </TableHead>
                  <TableBody>
                    {!riskAssessmentTemplate?.data && (
                      <TableCell colSpan={3}>No template selected.</TableCell>
                    )}
                    {riskAssessmentTemplate?.data && (
                      <>
                        <TableCell>
                          {riskAssessmentTemplate?.data?.name ?? null}
                        </TableCell>
                        <TableCell>
                          {riskAssessmentTemplate?.data?.sector ?? null}
                        </TableCell>
                        <TableCell>
                          {riskAssessmentTemplate?.data?.description ?? null}
                        </TableCell>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <div className={classes.buttons}>
              <Button
                variant="outlined"
                color="primary"
                type="button"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </div>
          </Grid>
        </form>
      </CardBase>
    </Container>
  );
}
