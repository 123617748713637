import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ActionsTableCard from "../components/ActionsTableCard";
import ConsultantCard from "../components/ConsultantCard";
import { useTracking } from "react-tracking";
import PageBase from "../../components/PageBase";
import { Home } from "@material-ui/icons";
import PageTitle from "../components/PageTitle";
import SiteSelect from "../../components/SiteSelect";
import OverallScoreCompact from "../components/safetynestscore/OverallScoreCompact";
import { useSelector } from "react-redux";
import useOverallSafetyNestScore from "../hooks/queries/useOverallSafetyNestScore";
import { useAuth } from "../../contexts/authContext";
import FormsTableCard from "../components/FormsTableCard";

const useStyles = makeStyles((theme) => ({
    divForms: {
        marginBottom: theme.spacing(2),
    },
    titleGrid: {
    marginBottom: theme.spacing(2),
  },
  headingContainer: {
    textAlign: "center",
    margin: theme.spacing(3, 0),
  },
  heading: {
    lineHeight: "48px",
    fontSize: "32px",
    fontWeight: "500",
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
  },
  subheadings: {
    fontSize: "16px",
    lineHeight: "28px",
    fontWeight: "400",
    color: theme.palette.primary.main,
  },
}));

function Dashboard() {
  const classes = useStyles();
  const { Track } = useTracking({
    page: `Dashboard`,
  });

  const { selectedSite, activeAppId, appSiteIds } = useSelector(
    (state) => state.account
  );

  const { isConsultant } = useAuth();

  const overallScore = useOverallSafetyNestScore({
    externalIds: selectedSite
      ? [selectedSite.externalId]
      : appSiteIds[activeAppId],
  });

  if (isConsultant) return (
    <PageBase>
      <Track>
        <div className={classes.headingContainer}>
            <Typography variant="h2" className={classes.heading}>
              Welcome to the SafetyNest Global User Area!
            </Typography>
            <Typography className={classes.subheadings}>
              Here, you can easily manage and update our WorkNest templates and client audits.
            </Typography>
            <Typography className={classes.subheadings}>
              Please note, that any changes made will be reflected live to all clients.
            </Typography>
        </div>
      </Track>
    </PageBase>
  );

  return (
    <PageBase>
      <Track>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className={classes.titleGrid}
        >
          <Grid item xs={12} md={9}>
            <PageTitle
              icon={<Home />}
              title="Dashboard"
              description="An overview of each module's status, a view of actions across modules making it easy to spot areas that need attention by identifying what is complete and what is outstanding."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SiteSelect size="small" />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
                      <ActionsTableCard />
                      </Grid>
          <Grid item xs={12} md={4}>
            {overallScore?.data && (
              <OverallScoreCompact overallScore={overallScore} />
            )}
            <ConsultantCard />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormsTableCard/>
          </Grid> 
        </Grid>
      </Track>
    </PageBase>
  );
}

export default Dashboard;
