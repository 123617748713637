import axios from "../utils/axios";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const getByUserExternalId = (userExternalId) =>
  axios.get(`${apiUrl}/UserAccessPermission/${userExternalId}`);

const create = (userAccessPermission) =>
  axios.post(`${apiUrl}/UserAccessPermission`, userAccessPermission);

const update = (userAccessPermission) =>
  axios.patch(`${apiUrl}/UserAccessPermission/Update`, userAccessPermission);

const userAccessPermissionService = {
  getByUserExternalId,
  create,
  update,
};

export default userAccessPermissionService;
