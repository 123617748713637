import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    acknowledgementActionButton: {
        position: "fixed",
        bottom: "0",
        right: "0",
        margin: theme.spacing(2),
    },
}));

export default function FixedButton({ onClick, icon, text }) {
    const classes = useStyles();

    return (
        <Button color="secondary" variant="contained" onClick={onClick} className={classes.acknowledgementActionButton}>
            {icon && icon} {text}
        </Button>
    );
}
