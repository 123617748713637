import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Typography,
    MenuItem,
    makeStyles,
    TextField,
    FormControl,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
    setActionAssignedToEmployeeId,
    setActionDueDate,
    setActionRating,
} from "../../../redux/actions/formCompleterActions";
import useIsMobile from "../../../hooks/useIsMobile";
import useContactsForAppAndSite from "../../../hooks/useContactsForAppAndSite";
import { ratingList } from "../../constants/actionRatingConstants";
import moduleAreas, { moduleUrls } from "../../constants/moduleAreaConstants";

const useStyles = makeStyles((theme) => ({
    actionContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    formControl: {
        minWidth: "220px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    optionsContainer: {
        display: "flex",
        gap: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            flexWrap: "wrap",
        },
    },
}));

const minimumDate = new Date().setHours(23, 59, 59);

const CreatedActions = ({ siteExternalId, moduleArea, formStartDate }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();

    const { actions } = useSelector((state) => state.formCompleter);
    const contacts = useContactsForAppAndSite({ selectedSiteId: siteExternalId });

    const handleChangeDueDate = ({ actionId, dueDate }) => {
        dispatch(setActionDueDate({ actionId, dueDate }));
    };

    const handleChangeAssignedTo = ({ actionId, employeeExternalId }) => {
        dispatch(setActionAssignedToEmployeeId({ actionId, employeeExternalId }));
    };

    const handleChangeRating = ({ actionId, rating }) => {
        dispatch(setActionRating({ actionId, rating }));
    };

    return (
        <>
            {Object.keys(actions).map((actionId) => {
                const action = actions[actionId];
                return (
                    <div className={classes.actionContainer} key={action.id}>
                        <Typography>{action.description}</Typography>
                        <div className={classes.optionsContainer}>
                            <FormControl className={classes.formControl}>
                                <KeyboardDatePicker
                                    required
                                    value={action?.dueDate ?? null}
                                    onChange={(date) => handleChangeDueDate({ dueDate: date?.toString(), actionId: action.id })}
                                    minDate={moduleArea === moduleUrls[moduleAreas.COMPLIANCE] ? formStartDate : minimumDate}
                                    format="dd/MM/yyyy"
                                    label="Due date"
                                    inputVariant="outlined"
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    required
                                    value={action?.employeeExternalId ?? ""}
                                    onChange={(e) => handleChangeAssignedTo({ employeeExternalId: e.target.value, actionId: action.id })}
                                    label="Assigned to"
                                    variant="outlined"
                                    select
                                    SelectProps={{ native: isMobile }}
                                >
                                    {contacts?.data &&
                                        Object.values(contacts.data)
                                            .sort((a, b) => (a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0))
                                            .map((user) =>
                                                isMobile ? (
                                                    <option key={user.externalId} value={user.externalId}>
                                                        {user.firstName} {user.lastName}
                                                    </option>
                                                ) : (
                                                    <MenuItem key={user.externalId} value={user.externalId}>
                                                        {user.firstName} {user.lastName}
                                                    </MenuItem>
                                                )
                                            )}
                                </TextField>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    required
                                    value={action?.rating ?? ""}
                                    onChange={(e) => handleChangeRating({ actionId: action.id, rating: e.target.value })}
                                    label="Priority"
                                    variant="outlined"
                                    select
                                    SelectProps={{ native: isMobile }}
                                >
                                    {ratingList &&
                                        Object.values(ratingList).map((item) =>
                                            isMobile ? (
                                                <option key={item.value} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ) : (
                                                <MenuItem key={item.value} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            )
                                        )}
                                </TextField>
                            </FormControl>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default CreatedActions;
