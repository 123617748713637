import React, { useMemo, useCallback, useState } from "react";
import {
    makeStyles,
    Box,
    Grid,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import {
    ArrowBack as ArrowBackIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon
} from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import { ReactComponent as DocImage } from "../images/file_doc.svg";
import { ReactComponent as PdfImage } from "../images/file_pdf.svg";
import { format } from "date-fns";
import FileViewerModal from "../components/FileViewerModal";
import caseNestDocumentService from "../services/caseNestDocumentService";
import { useTracking } from "react-tracking";
import trackingConstants from "../constants/trackingConstants";

const useStyles = makeStyles((theme) => ({
    docTile: {
        border: '1px solid #e5e6ec',
        borderRadius: '3px'
    },
    docImage: {
        padding: '15px',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'center',
        borderBottom: theme.breakpoints.only("xs") ? 'none': '1px solid #e5e6ec',
        '& svg': {
            height: '60px'
        },
        backgroundColor: '#f6f5f8',
        [theme.breakpoints.only('xs')]: {
            backgroundColor: 'inherit',
        }
    },
    docFilename: {
        margin: '8px',
        marginBottom: '0px',        
        overflowWrap: 'break-word',
        '& *': {
            fontSize: '0.9em',
            fontWeight: 'bold'
        },
        height: '40px',
        [theme.breakpoints.only('xs')]: {
            height: 'inherit',
        }
    },
    docSize: {
        margin: '8px',
        '& *': {
            fontSize: '0.7em'
        }
    },
    countSmall: {
        backgroundColor: '#f2ecff',
        padding: '1px',
        borderRadius: '2px',
        minWidth: '18px'
    }
}));

const DocumentGrid = ({ selectedCaseType, setSelectedCaseType, filter, setFilter, precedentCount, filteredPrecedents, filteredFolders }) => {

    const { trackEvent } = useTracking();

    const theme = useTheme();

    const classes = useStyles();

    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

    const [openFolders, setOpenFolders] = useState([]);

    const [viewingFile, setViewingFile] = useState();

    const openFileViewer = async (precedent) => {

        trackEvent({ event: `${trackingConstants.events.viewPrecedent}: ${precedent.precedentId}` });

        let previewPath = await caseNestDocumentService.retrieveDocumentPreviewPath({ reference: precedent.azureFileReference, contentType: precedent.azureFileContentType });
     
        setViewingFile({
            name: precedent.name,
            fileType: precedent.name.split('.').pop(),
            filePath: previewPath,
            azureFileReference: precedent.azureFileReference,
            precedentId: precedent.precedentId
        });
    }

    const toggleFolder = folder => {
        if (openFolders.includes(folder)) {
            setOpenFolders(openFolders.filter(f => f !== folder));
        }
        else {
            setOpenFolders([...openFolders, folder]);
        }
    }

    const caseTypePrecedents = useMemo(() => {
        if (!selectedCaseType)
            return [];

        return filteredPrecedents.filter(p => p.caseTypeId === selectedCaseType.caseTypeId);
    }, [filteredPrecedents, selectedCaseType]);    

    const folderPrecedents = useCallback(precedentFolderId => {
        return caseTypePrecedents
            .filter(p => (p.precedentFolderId || null) === precedentFolderId)
            .sort((s1, s2) => s1.sortIndex - s2.sortIndex);;
    }, [caseTypePrecedents]);

    const PrecedentImage = ({ contentType }) => {
        switch (contentType) {
            case "application/pdf":
                return <PdfImage style={{ color: "#b5271c" }} />;
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            case "application/msword":
                return <DocImage style={{ color: "#185abd" }} />;
            default:
                return <DocImage style={{ color: "#cecece" }} />;
        }
    };

    const back = () => {
        setSelectedCaseType();
        setFilter("");
    }

    return (
        <React.Fragment>
            <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
                <ArrowBackIcon onClick={back} />
                <Box m={2} flexGrow={1}><Typography>{selectedCaseType.name}</Typography></Box>                
            </Box>
            {
                filteredFolders.map(f => (
                    <Box key={f.precedentFolderId}>
                        <Box display="flex" alignItems="center" my={2}>
                            <Typography>{f.name}</Typography>
                            <Box display="flex" className={classes.countSmall} justifyContent="center" ml={1}>
                                <Typography style={{ fontSize: '0.9em' }}>{precedentCount(selectedCaseType.caseTypeId, f.precedentFolderId)}</Typography>
                            </Box>
                            <Box flexGrow={1}></Box>
                            <Box display="flex" style={{ cursor: 'pointer', color: '#5942c6' }} onClick={() => toggleFolder(f.precedentFolderId)} alignItems="center">
                                <Typography style={{ fontSize: '0.8em' }}>{openFolders.includes(f.precedentFolderId) ? "Hide" : "Show"}</Typography>
                                {openFolders.includes(f.precedentFolderId) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </Box>                            
                        </Box>
                        <Box></Box>
                        {
                            openFolders.includes(f.precedentFolderId) &&                            
                            <Grid container spacing={2}>
                                {
                                    folderPrecedents(f.precedentFolderId).map(p => (
                                        <Grid xs={12} sm={6} md={4} lg={3} item key={p.precedentId} >
                                            <Box display="flex" gridGap='5px' flexDirection={isMobile ? "row" : "column"} className={classes.docTile}>
                                                <Box display="flex" className={classes.docImage} onClick={() => openFileViewer(p)}><PrecedentImage contentType={p.azureFileContentType} /></Box>
                                                <Box display="flex" flexDirection="column">
                                                    <Box flexGrow={1} className={classes.docFilename}><Typography>{p.name}</Typography></Box>
                                                    <Box className={classes.docSize}><Typography>{p.fileSize && (p.fileSize / 1024).toFixed(1)} MB - {p.addedOn && format(new Date(p.addedOn), "d MMM, yyyy")}</Typography></Box>
                                                </Box>                                                
                                            </Box>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        }
                    </Box>
                ))
            }
            {
                viewingFile &&
                <FileViewerModal
                    open={!!viewingFile}
                    fileType={viewingFile.fileType}
                    filePath={viewingFile.filePath}
                    fileName={viewingFile.name}
                    onClose={() => setViewingFile()}
                    azureGuid={viewingFile.azureFileReference}
                    precedentId={viewingFile.precedentId}
                />
            }
        </React.Fragment>

    );
}

export default DocumentGrid;
