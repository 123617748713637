import { useMutation } from "react-query";
import salesforceService from "../../services/salesforceService";
import reactQueryUtils from "../../utils/reactQueryUtils";
import queryKeys from "../../constants/queryKeys";

const useAddUser = () =>
  useMutation(salesforceService.addNewUser, {
    onSettled: (_data, _error, newUser) => {
      reactQueryUtils.client.invalidateQueries([
        queryKeys.siteUsers,
        newUser.accountId,
      ]);
    },
  });

export default useAddUser;
