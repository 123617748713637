import React from "react";
import {
  Button, 
  Dialog, 
  DialogContent, 
  DialogContentText,
  DialogTitle,
  makeStyles,
  DialogActions,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    marginLeft: theme.spacing(2)
  }
}))

const CustomDialog = ({ open, onClose, onConfirm, title, content, confirmButtonText }) => {
  const classes = useStyles();
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>Cancel</Button>
        <Button className={classes.confirmButton} color="primary" variant="contained" onClick={onConfirm}>{confirmButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;