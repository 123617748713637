import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import safetyNestScoreService from "../../services/safetyNestScoreService";

const useOverallSafetyNestScore = ({ externalIds }) =>
  useQuery(
    [queryKeys.safetyNestScore, externalIds?.join(",")],
    () => safetyNestScoreService.fetchOverallSafetyNestScore({ externalIds }),
    {
      enabled: !!externalIds?.length,
    }
  )

export default useOverallSafetyNestScore
