import { alpha, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import TrendIndicator from "./TrendIndicator";
import {bandColours, scoreTrend} from "../../constants/safetynestScoreConstants";
import {moduleIconForEnum} from "../../constants/moduleAreaConstants";

const scoreBandBackgroundOpacity = 0.1;

const useStyles = makeStyles((theme) => ({
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    width: "fit-content",
    borderRadius: "100%",
    border: "2px solid #aaa",
    backgroundColor: "#aaaaaa50",
    "&.band-0": {
      border: `2px solid ${bandColours.AT_RISK}`,
      backgroundColor: alpha(bandColours.AT_RISK, scoreBandBackgroundOpacity),
      color: bandColours.AT_RISK,
    },
    "&.band-1": {
      border: `2px solid ${bandColours.REACTIVE}`,
      backgroundColor: alpha(bandColours.REACTIVE, scoreBandBackgroundOpacity),
      color: bandColours.REACTIVE,
    },
    "&.band-2": {
      border: `2px solid ${bandColours.DEVELOPING}`,
      backgroundColor: alpha(
        bandColours.DEVELOPING,
        scoreBandBackgroundOpacity
      ),
      color: bandColours.DEVELOPING,
    },
    "&.band-3": {
      border: `2px solid ${bandColours.PROACTIVE}`,
      backgroundColor: alpha(bandColours.PROACTIVE, scoreBandBackgroundOpacity),
      color: bandColours.PROACTIVE,
    },
    "&.band-4": {
      border: `2px solid ${bandColours.ESTABLISHED}`,
      backgroundColor: alpha(
        bandColours.ESTABLISHED,
        scoreBandBackgroundOpacity
      ),
      color: bandColours.ESTABLISHED,
    },
  },
  moduleScoreContainer: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    backgroundColor: "#fff",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    columnGap: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "5px solid #aaa",
    "&.band-0": {
      borderColor: bandColours.AT_RISK,
    },
    "&.band-1": {
      borderColor: bandColours.REACTIVE,
    },
    "&.band-2": {
      borderColor: bandColours.DEVELOPING,
    },
    "&.band-3": {
      borderColor: bandColours.PROACTIVE,
    },
    "&.band-4": {
      borderColor: bandColours.ESTABLISHED,
    },
  },
  content: {},
  scoreWrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  moduleScore: {
    fontSize: "1.25rem",
    fontWeight: 700,
  },
}));

export default function ModuleScore({ moduleScore }) {
  const classes = useStyles();
  const icon = moduleIconForEnum[moduleScore.applicationArea] ?? "N/A";
  const scoreBand = moduleScore.band;

  return (
    <div className={clsx(classes.moduleScoreContainer, `band-${scoreBand}`)}>
      <div className={classes.content}>
        <div>
          <span>{moduleScore.applicationAreaText}</span>
        </div>
        <div className={classes.scoreWrapper}>
          <span className={classes.moduleScore}>{moduleScore.scoreText}</span>
          {moduleScore.trend !== scoreTrend.MAINTAINING &&
              <TrendIndicator
                trend={moduleScore.trend}
                difference={moduleScore.scoreDifferenceText}
            />
          }
        </div>
      </div>
      <div className={clsx(classes.icon, `band-${scoreBand}`)}>{icon}</div>
    </div>
  );
}
