import React from "react";
import { ReactComponent as WorknestLogo } from "../images/myWorkNest_Purple_Positive_RGB.svg";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useAuth } from "../contexts/authContext";
import { grey } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";

const circlesImgUrl = `${process.env.PUBLIC_URL}/images/circles_bottom_left_purple.svg`;
const consultancySvgUrl = `${process.env.PUBLIC_URL}/images/wn-consultancy.svg`;
const maxImgWidth = "300px";

const useStyles = makeStyles((theme) => ({
  grid: {
    height: "100vh",
    padding: theme.spacing(2),
    position: "relative",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  logoWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
    maxWidth: maxImgWidth,
  },
  logo: {
    width: "100%",
  },
  circles: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "40%",
    height: "auto",
    zIndex: -1,
  },
  splash: {
    maxWidth: maxImgWidth,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(3),
  },
  subGreeting: {
    marginBottom: theme.spacing(3),
  },
  copy: {
    marginBottom: theme.spacing(3),
  },
  productsButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    borderRadius: 0,
    marginBottom: theme.spacing(3),
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
  email: {
    color: grey[500],
    marginTop: theme.spacing(6),
  },
  changeAccountButton: {
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

function Hello() {
  const classes = useStyles();
  const history = useHistory();
  const { unauthenticatedUserDetails, clearUnauthenticatedUserDetails } =
    useAuth();

  const returnToLogin = () => {
    clearUnauthenticatedUserDetails();
    history.push("/");
  };

  return (
    <Grid
      className={classes.grid}
      container
      justifyContent="center"
      alignItems="center"
    >
      <img className={classes.circles} src={circlesImgUrl} />
      <Grid item xs={12} sm={6} xl={4} className={classes.content}>
        <div className={classes.logoWrapper}>
          <WorknestLogo className={classes.logo} />
        </div>
        <img className={classes.splash} src={consultancySvgUrl} />
        <Typography variant="h3">
          {unauthenticatedUserDetails?.name
            ? `Welcome, ${unauthenticatedUserDetails.name}`
            : "Welcome"}
        </Typography>
        <Typography className={classes.subGreeting} variant="h5">
          It looks like you&apos;re new here.
        </Typography>
        <Typography className={classes.copy}>
          See below to learn more about our products.
        </Typography>
        <Button
          variant="contained"
          href="https://www.worknest.com"
          target="_blank"
          className={classes.productsButton}
        >
          Learn more about our products.
        </Button>
        <Typography>
          If your organisation has already signed up, please contact your
          account administrator.
        </Typography>
        {unauthenticatedUserDetails.email && (
          <>
            <Typography variant="subtitle2" className={classes.email}>
              {unauthenticatedUserDetails.email}
            </Typography>
            <Button
              className={classes.changeAccountButton}
              variant="text"
              color="secondary"
              onClick={returnToLogin}
            >
              Use another account
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default Hello;
