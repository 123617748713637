import { useMutation } from "react-query";
import reactQueryUtils from "../../utils/reactQueryUtils";
import queryKeys from "../../constants/queryKeys";
import tutorialService from "../../services/tutorialService";

const useDeleteTutorial = () =>
  useMutation(tutorialService.deleteById, {
    onMutate: async (tutorialId) => {
      const { client } = reactQueryUtils;
      const queryKey = [queryKeys.tutorialsList];
      await client.cancelQueries(queryKey);

      const tutorialsBeforeDelete = client.getQueryData(queryKey);

      client.setQueryData(queryKey, (tutorials) =>
        tutorials.filter((x) => x.id !== tutorialId)
      );

      return { tutorialsBeforeDelete };
    },
    onError: (error, variables, { tutorialsBeforeAdd }) => {
      const queryKey = [queryKeys.tutorialsList];
      reactQueryUtils.client.setQueryData(queryKey, tutorialsBeforeAdd);
    },
    onSettled: () => {
      reactQueryUtils.client.invalidateQueries([queryKeys.tutorialsList]);
    },
  });

export default useDeleteTutorial;
