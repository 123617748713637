import axios from "../../utils/axios";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const saveTemplateForm = (templateFormResponse) =>
  axios.post(`${apiUrl}/TemplateFormHistory/Add`, templateFormResponse);

const updateTemplateFormHistory = (templateFormHistory) => 
    axios.post(`${apiUrl}/TemplateFormHistory/Update`, templateFormHistory);

const getFormHistories = ({ templateFormId, pageSize, pageNum, sortOrder, orderByColumn, }) =>
  axios.get(
      `${apiUrl}/TemplateFormHistory/Form/${templateFormId}?pageNum=${pageNum}&pageSize=${pageSize}&sortOrder=${sortOrder}&orderByColumn=${orderByColumn}`
  );

const getById = (templateFormHistoryId) =>
  axios.get(`${apiUrl}/TemplateFormHistory/${templateFormHistoryId}`);

const uploadAttachments = ({ templateFormId, files, onUploadProgress }) => {
  const formData = new FormData();
  for (const [index, dto] of files.entries()) {
    formData.append(`[${index}].syncId`, dto.syncId);
    formData.append(`[${index}].file`, dto.file);
  }
  return axios.post(
    `${apiUrl}/TemplateFormHistory/Form/${templateFormId}/Attachments`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    }
  );
};

const downloadAttachment = (reference, templateFormHistoryId) =>
  axios.get(
    `${apiUrl}/TemplateFormHistory/Form/${templateFormHistoryId}/Attachment/${reference}`,
    {
      responseType: "arraybuffer",
    }
  );

const downloadHistory = (templateFormId) =>
    axios.get(`${apiUrl}/TemplateFormHistory/Download/${templateFormId}`);

const addHistoricAttachment = (attachmentToAdd) =>
    axios.post(`${apiUrl}/TemplateFormHistory/attachment/Add`, attachmentToAdd);

const deleteHistoricAttachment = (attachmentToDelete) =>
    axios.post(`${apiUrl}/TemplateFormHistory/attachment/Delete`, attachmentToDelete);

const templateFormHistoryService = {
  saveTemplateForm,
  updateTemplateFormHistory,
  getFormHistories,
  getById,
  uploadAttachments,
  downloadAttachment,
  downloadHistory,
  addHistoricAttachment,
  deleteHistoricAttachment,
};

export default templateFormHistoryService;
