import { createContext, useContext, useState } from 'react'

const SiteSearchContext = createContext();

function SiteSearchProvider({ children }) {
    const [siteSearch, setSiteSearch] = useState('')
    const [siteSearchValue, setSiteSearchValue] = useState('')
    const [showSiteResults, setShowSiteResults] = useState(false)
    const [selectedSite, setSelectedSite] = useState(null)

    function handleSiteSearch() {
        setShowSiteResults(true)
        setSiteSearchValue(siteSearch)
    }

    const value = {
        siteSearch,
        setSiteSearch,
        handleSiteSearch,
        showSiteResults,
        siteSearchValue,
        selectedSite,
        setSelectedSite,
    }

    return (
        <SiteSearchContext.Provider value={value}>
            {children}
        </SiteSearchContext.Provider>
    )
}

const useSiteSearchContext = () => useContext(SiteSearchContext)

export { SiteSearchProvider, useSiteSearchContext }