import React from "react";
import { useIncidentDetails } from "../../contexts/IncidentContext";
import { Button, makeStyles } from "@material-ui/core";
import PersonForm from "./PersonForm";

const useStyles = makeStyles((theme) => ({
  peopleContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

function IncidentPeopleForm() {
  const classes = useStyles();
  const { addPerson, people } = useIncidentDetails();

  return (
    <div className={classes.peopleContainer}>
      {people.ids.filter((id) => (!people.details[id].toBeDeleted)).map((id) => (
        <PersonForm key={id} personId={id} isOnly={people.ids.length === 1} />
      ))}
      <Button
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={addPerson}
      >
        Add Person
      </Button>
    </div>
  );
}

export default IncidentPeopleForm;
