import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import actionService from "../../services/actionService";

const useDownloadActionAttachment = ({ reference, isDri }, options = {}) =>
  useQuery(
    [queryKeys.downloadActionAttachment, reference],
    () => actionService.downloadAttachment(reference, isDri),
    { enabled: !!reference, ...options }
  );

export default useDownloadActionAttachment;
