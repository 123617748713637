import { formatDistance } from "date-fns";
import axios from "../utils/axios";

export const fetchLatestWorknestBlogPosts = async () => {
  try {
    const response = await axios.get(
      "https://worknest.com/wp-json/wp/v2/posts?orderby=date&per_page=6&_embed"
    );

    const cleanedData = response.map((item) => ({
      title: item.title.rendered
        .replaceAll("&#8217;", "'"),
      excerpt: item.excerpt.rendered
        .replace(/(<([^>]+)>)/gi, "")
        .replace("[&hellip;]", "...")
        .replaceAll("&#8217;", "'")
        .replaceAll("&nbsp;", " ")
      ,
      createdOn: formatDistance(new Date(item.date), new Date(), {
        addSuffix: true,
      }),
      image: item._embedded["wp:featuredmedia"][0].source_url,
      link: item.link,
    }));

    return cleanedData;
  } catch (error) {
    throw error;
  }
};

const wordpressService = {
  fetchLatestWorknestBlogPosts,
};

export default wordpressService;
