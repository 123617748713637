import React, { useState } from "react";
import RequiresRoleWrapper from "../components/RequiresRoleWrapper";
import { useAuth } from "../contexts/authContext";
import {
  Button,
  Chip,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import CardBase from "../components/cards/CardBase";
import EnhancedTableHead from "../components/table/EnhancedTableHead";
import { tableSortingOrder } from "../constants/tableConstants";
import { Link, useRouteMatch } from "react-router-dom";
import useActiveTutorialsList from "../hooks/queries/useActiveTutorialsList";
import { getComparator, stableSort } from "../utils/tableUtils";
import { green, orange } from "@material-ui/core/colors";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  statusChip: {
    color: "white",
    borderRadius: "4px",
    "&.appearance-mode-1": {
      backgroundColor: green[400],
    },
    "&.appearance-mode-2": {
      backgroundColor: orange[400],
    },
  },
}));

const headCells = [
  {
    id: "title",
    label: "Title",
    isSortable: true,
  },
  {
    id: "route",
    label: "Route",
    isSortable: true,
  },
  {
    id: "appearanceMode",
    label: "Appearance Mode",
    isSortable: true,
  },
  {
    id: "action",
    label: "",
  },
];

function Tutorials() {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { isTutorialAdmin } = useAuth();
  const [order, setOrder] = useState(tableSortingOrder.asc);
  const [orderBy, setOrderBy] = useState(headCells[0].id);
  const tutorialList = useActiveTutorialsList();

  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === tableSortingOrder.asc;
    setOrder(isAsc ? tableSortingOrder.desc : tableSortingOrder.asc);
    setOrderBy(property);
  };

  const tableContent = () => {
    if (tutorialList.data?.length > 0) {
      return stableSort(tutorialList.data, getComparator(order, orderBy)).map(
        (tutorial) => {
          const appearanceModeChipClass = clsx(
            classes.statusChip,
            `appearance-mode-${tutorial.appearanceMode}`
          );

          return (
            <TableRow key={tutorial.id}>
              <TableCell>{tutorial.title}</TableCell>
              <TableCell>{tutorial.route}</TableCell>
              <TableCell>
                <Chip
                  className={appearanceModeChipClass}
                  size="small"
                  label={tutorial.appearanceModeDescription}
                />
              </TableCell>
              <TableCell align="right">
                <Button
                  color="secondary"
                  variant="text"
                  component={Link}
                  to={`${path}/edit/${tutorial.id}`}
                  size="small"
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          );
        }
      );
    }

    return (
      <TableRow>
        <TableCell colSpan={headCells.length}>
          <Typography>No tutorials.</Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <RequiresRoleWrapper hasRole={isTutorialAdmin}>
      <Container>
        <CardBase
          title="Tutorials"
          description="A list of currently active tutorials."
          isLoading={tutorialList.isLoading}
          isFetching={tutorialList.isFetching}
          error={tutorialList.error}
          rightComponent={
            <Button
              component={Link}
              to={`${path}/add`}
              variant="contained"
              color="primary"
            >
              Add Tutorial
            </Button>
          }
        >
          <TableContainer>
            <Table>
              <EnhancedTableHead
                headCells={headCells}
                sortColumn={orderBy}
                sortDirection={order}
                onRequestSort={handleSortRequest}
              />
              <TableBody>{tableContent()}</TableBody>
            </Table>
          </TableContainer>
        </CardBase>
      </Container>
    </RequiresRoleWrapper>
  );
}

export default Tutorials;
