import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateService from "../../services/templateService";

// Fetch a single incident form template by ID
const useIncidentFormVersion = (
    templateVersionId,
    externalId
) =>
  useQuery(
      [queryKeys.template, templateVersionId],
      () =>
          templateService.fetchTemplateVersionAndInvestigation(templateVersionId, externalId),
    {
      enabled: !!templateVersionId     
    }
  );

export default useIncidentFormVersion;
