import { Grid, makeStyles } from "@material-ui/core";
import { useTracking } from "react-tracking";
import RiskAssessmentsTableCard from "../components/riskassessments/RiskAssessmentsTableCard";
import PageBase from "../../components/PageBase";
import React from "react";
import SiteSelect from "../../components/SiteSelect";
import PageTitle from "../components/PageTitle";
import ActionsTableCard from "../components/ActionsTableCard";
import moduleAreas, { moduleIcons } from "../constants/moduleAreaConstants";

const useStyles = makeStyles((theme) => ({
  titleGrid: {
    marginBottom: theme.spacing(2),
  },
}));

export default function RiskAssessments() {
  const classes = useStyles();
  const { Track } = useTracking({
    page: `Risk Assessments`,
  });

  return (
    <PageBase>
      <Track>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className={classes.titleGrid}
        >
          <Grid item xs={12} md={9}>
            <PageTitle
              icon={moduleIcons.RISKASSESSMENTS}
              title="Risk Assessments"
              description="Using the 5 step Risk Assessment method, generate and manage your Risk Assessments. Once in place you can review and take action to improve the any risks that have been identified."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SiteSelect size="small" />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionsTableCard moduleArea={moduleAreas.RISKASSESSMENTS} />
          </Grid>
          <Grid item xs={12}>
            <RiskAssessmentsTableCard />
          </Grid>
        </Grid>
      </Track>
    </PageBase>
  );
}
