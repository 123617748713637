import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import { useRiskAssessmentDetails } from "../../../contexts/RiskAssessmentContext";

const useStyles = makeStyles((theme) => ({
    bottomGap: {
        marginBottom: theme.spacing(2)
    },
    removed: {
        textDecoration: 'line-through'
    },
    dialog: {
        [theme.breakpoints.up("md")]: {
            height: "50vh",
        },
        [theme.breakpoints.down("sm")]: {
            height: "75vh",
        }
    },
}))

export default function CustomHazardDialog() {
    const classes = useStyles()
    const { 
        setHazardDialogOpen,
        hazardDialogOpen,
        addCustomHazard,
        riskAssessmentDetails,
        removeCustomHazard,
    } = useRiskAssessmentDetails()

    const [newCustomHazard, setNewCustomHazard] = useState('')
    const [removeOnSave, setRemoveOnSave] = useState([])
    const [newCustomHazards, setNewCustomHazards] = useState([])

    function onCancel() {
        setRemoveOnSave([])
        setNewCustomHazards([])
        setHazardDialogOpen(false)
    }

    function onSave() {        
        newCustomHazards?.map(hazard => addCustomHazard({
            id: hazard?.id, 
            name: hazard?.name,
        }))
        removeOnSave?.map(id => removeCustomHazard(id))
        setNewCustomHazards([])
        setRemoveOnSave([])
        setHazardDialogOpen(false)
    }

    function addHazard() {
        setNewCustomHazards([...newCustomHazards, {
            id: nanoid(5), 
            name: newCustomHazard,
        }])
        setNewCustomHazard('')
    }

    return (
        <Dialog classes={Object.keys(riskAssessmentDetails?.customHazards)?.length > 0 && { paper: classes.dialog }} maxWidth="sm" fullWidth open={hazardDialogOpen}>
            <DialogTitle>Custom Hazards</DialogTitle>
            <DialogContent>
                <Grid className={classes.bottomGap} spacing={2} alignItems='center' container>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            required
                            size='small'
                            variant="outlined"
                            label='Hazard Name'
                            value={newCustomHazard}
                            inputProps={{ maxLength: 250 }}
                            onChange={(e) => setNewCustomHazard(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant='text'
                            color='secondary'
                            disabled={!newCustomHazard}
                            onClick={addHazard}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                {Object.keys((riskAssessmentDetails?.customHazards)?.length > 0 || newCustomHazards?.length > 0) &&
                    <Table className={classes.bottomGap} size="small">
                        <TableHead>
                            <TableCell colSpan={2}>Hazard Name</TableCell>
                        </TableHead>
                        <TableBody>
                            {Object.keys(riskAssessmentDetails?.customHazards)?.map(hazard => {
                                const toBeRemoved = removeOnSave?.some(x => x === hazard)

                                return (    
                                    <TableRow key={hazard}>
                                        <TableCell className={toBeRemoved && classes.removed}>{riskAssessmentDetails?.customHazards[hazard]?.name}</TableCell>
                                        <TableCell align="right">
                                            <IconButton disabled={toBeRemoved} onClick={() => setRemoveOnSave([...removeOnSave, hazard])} size="small"><Delete /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            {newCustomHazards?.map(hazard => {
                                const toBeRemoved = removeOnSave?.some(x => x === hazard?.id)

                                return (    
                                    <TableRow key={hazard?.id}>
                                        <TableCell className={toBeRemoved && classes.removed}>{hazard?.name}</TableCell>
                                        <TableCell align="right">
                                            <IconButton disabled={toBeRemoved} onClick={() => setRemoveOnSave([...removeOnSave, hazard?.id])} size="small"><Delete /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={newCustomHazards?.length === 0 && removeOnSave?.length === 0}
                    onClick={onSave}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}