import React from "react";
import clsx from "clsx";
import { Chip, makeStyles } from "@material-ui/core";
import { green, grey, orange, red } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  root: {
    color: "white",
    maxWidth: 'max-content',
    borderRadius: "4px",
    "&.status-0": {
      backgroundColor: orange[400],
    },
    "&.status-1": {
      backgroundColor: red[400],
    },
    "&.status-2": {
      backgroundColor: green[400],
    },
    "&.status-3": {
      backgroundColor: grey[400],
      color: "black",
    },
  },
}));

function ActionStatusChip({ status, statusName }) {
  const classes = useStyles();

  if (status === null || statusName === null) return null;

  return (
    <Chip
      size="small"
      className={clsx(classes.root, `status-${status}`)}
      label={statusName}
    />
  );
}

export default ActionStatusChip;
