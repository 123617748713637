import React from "react";
import {
  Container,
  Box,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemIcon, Button,
} from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import {useDispatch} from "react-redux";
import {setCookieJarOpen, setCookieJarStep} from "../redux/actions/cookieActions";
import {dialogSteps} from "../constants/cookieConstants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  textWrapper: {
    maxWidth: theme.breakpoints.values.md,
    padding: theme.spacing(3),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[4],
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 0),
  },
  iconWrapper: {
    minWidth: 0,
    marginRight: theme.spacing(2),
  },
  icon: {
    fontSize: 11,
    fill: theme.palette.primary.main,
  },
}));

export const Privacy = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const BulletPointIcon = () => (
    <ListItemIcon className={classes.iconWrapper}>
      <FiberManualRecord className={classes.icon} />
    </ListItemIcon>
  );
  
  const handleManageCookies = () => {
    dispatch(setCookieJarStep({ step: dialogSteps.MANAGE }));
    dispatch(setCookieJarOpen({ isOpen: true }));
  }

  return (
    <Container className={classes.container}>
      <Box className={classes.textWrapper}>
        <Typography variant="h3" component="h1" className={classes.title}>
          Privacy Policy & Disclaimer
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          This privacy policy sets out how WorkNest uses and protects any
          information that you give WorkNest when you use this website.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          WorkNest is committed to ensuring that your privacy is protected.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          Should we ask you to provide certain information by which you can be
          identified when using this website, then you can be assured that it
          will only be used in accordance with this privacy statement.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          WorkNest may change this policy from time to time by updating
          this page. You should check this page from time to time to ensure that
          you are happy with any changes. This policy is effective from February
          2018.
        </Typography>
        <Typography
          variant="subtitle1"
          className={classes.subtitle}
          paragraph
          gutterBottom
        >
          What we collect
        </Typography>
        <Typography variant="body1">
          We may collect the following information:
        </Typography>
        <List>
          <ListItem alignItems="flex-start">
            <BulletPointIcon />
            <Typography variant="body1">name and job title</Typography>
          </ListItem>
          <ListItem alignItems="flex-start">
            <BulletPointIcon />
            <Typography variant="body1">
              contact information including email address
            </Typography>
          </ListItem>
          <ListItem alignItems="flex-start">
            <BulletPointIcon />
            <Typography variant="body1">
              company information such as postcode, number of employees
            </Typography>
          </ListItem>
          <ListItem alignItems="flex-start">
            <BulletPointIcon />
            <Typography variant="body1">
              other information relevant to customer surveys and/or offers.
            </Typography>
          </ListItem>
        </List>
        <Typography variant="body1" paragraph gutterBottom>
          We collect anonymous non-personal information from visitors to our
          website using analytics tools, cookies and web logs.
        </Typography>
        <Typography
          variant="subtitle1"
          className={classes.subtitle}
          paragraph
          gutterBottom
        >
          What we do with the information we gather
        </Typography>
        <Typography variant="body1">
          We require this information to understand your needs and provide you
          with a better service, and in particular for the following reasons:
        </Typography>
        <List>
          <ListItem alignItems="flex-start">
            <BulletPointIcon />
            <Typography variant="body1">Internal record keeping.</Typography>
          </ListItem>
          <ListItem alignItems="flex-start">
            <BulletPointIcon />
            <Typography variant="body1">
              We may use the information to improve our products and services.
            </Typography>
          </ListItem>
          <ListItem alignItems="flex-start">
            <BulletPointIcon />
            <Typography variant="body1">
              We may periodically send promotional emails to our customers about
              new services or training courses or other information which we
              think you may find interesting using the email address which you
              have provided. We may also occasionally telephone you.
            </Typography>
          </ListItem>
          <ListItem alignItems="flex-start">
            <BulletPointIcon />
            <Typography variant="body1">
              We may use the information to customise the website according to
              your interests.
            </Typography>
          </ListItem>
        </List>
        <Typography
          variant="subtitle1"
          className={classes.subtitle}
          paragraph
          gutterBottom
        >
          Security
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          We are committed to protect the security of your information during
          transmission by using Secure Sockets Layer (SSL) software, which
          encrypts information you input.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          We maintain physical, electronic and procedural safeguards in
          connection with the collection, storage and disclosure of personally
          identifiable information. Our security procedures mean that we may
          occasionally request proof of identity before we disclose personal
          information to you.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          It is important for you to protect against unauthorised access to your
          password and to your computer. Be sure to sign off when you finish
          using a shared computer. For more information on how to sign off.
        </Typography>

        <Typography
          variant="subtitle1"
          className={classes.subtitle}
          paragraph
          gutterBottom
        >
          How we use cookies
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          A cookie is a small file which asks permission to be placed on your
          computer’s hard drive. The file is added and the cookie helps analyse
          web traffic or lets you know when you visit a particular site. Cookies
          allow web applications to respond to you as an individual. The web
          application can tailor its operations to your needs, likes and
          dislikes by gathering and remembering information about your
          preferences.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          Overall, cookies help us provide you with a better website, by
          enabling us to monitor which pages you find useful and which you do
          not. A cookie cannot identity you personally and does not give us
          access to your computer or any information about you, other than the
          data you choose to share with us.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          Most web browsers automatically accept cookies, but you can usually
          modify your browser setting to decline cookies if you prefer. This may
          prevent you from taking full advantage of the website.
        </Typography>
        <Button color="secondary" variant="contained" onClick={handleManageCookies}>Manage Cookies</Button>
        <Typography
          variant="subtitle1"
          className={classes.subtitle}
          paragraph
          gutterBottom
        >
          Controlling your personal information
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          Any personal information provided to WorkNest via this website is
          controlled by WorkNest:
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          We will not sell, distribute or lease your personal information to
          third parties unless we have your permission or are required by law to
          do so.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          You may request details of personal information which we hold about
          you under the Data Protection Act 1998. If you would like a copy of
          the information held on you contact enquiries@worknest.com or
          write to WorkNest, Church Lane, Aldford, Cheshire CH3 6JD.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          If you believe that any information we are holding on you is incorrect
          or incomplete, please write to or email us as soon as possible, at the
          above address. We will promptly correct any information found to be
          incorrect.
        </Typography>
        <Typography
          variant="subtitle1"
          className={classes.subtitle}
          paragraph
          gutterBottom
        >
          Please read the following notice carefully. Your use of our website
          will be regarded as acceptance of these conditions.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          <span className={classes.subtitle}>Disclaimer:</span> WorkNest
          accepts no liability for loss of any kind as a result of any
          inaccuracies or of your reliance on the information contained on the
          site.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          Any legal or health and safety related information is not a substitute
          for specific advice relevant to your particular circumstances.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          We cannot guarantee that our website and any matter downloaded from
          this site will be free from viruses or other harmful programs or
          computer code.
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          We accept no liability for any loss that may be suffered by any person
          arising from any such harmful programs or computer code.
        </Typography>
      </Box>
    </Container>
  );
};

export default Privacy;
