export const roles = {
  CX_ADMIN: "client:cxadmin",
  CONSULTANT: "client:consultant",
  TUTORIAL_ADMIN: "client:tutorialadministrator",
};

export const salesforceRoles = {
  CASENEST_ADMINISTRATOR: "0",
  CASENEST_ADVICE: "1",
  SAFETYNEST_ADMINISTRATOR: "2",
  SAFETYNEST_AMENDTEMPLATES: "3",
  CASENEST_ALLCASES: "4",
  SAFETYNEST_COMPLETE_INCIDENT_INVESTIGATIONS: "5",
  MYWORKNEST_ADMINISTRATOR: "6",
  SAFETYNEST_EDIT_COMPLETED_ACTION: "7",
  SAFETYNEST_ALLACTIONS: "8",
  SAFETYNEST_INCIDENTLOGGING: "12",
};
