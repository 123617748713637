import { makeStyles } from "@material-ui/core";
import { formatShortMonthDateAndTime } from "../../../utils/dateTimeUtils";
import UserInitials from "./UserInitials";
import useContactsForAppAndSite from "../../../hooks/useContactsForAppAndSite";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    comment: {
        display: 'flex',
        columnGap: theme.spacing(2),
    },
    commentName: {
        fontSize: '0.875rem',
    },
    commentDate: {
        color: '#9a94b2',
        fontSize: '0.75rem',
        marginBottom: theme.spacing(1),
    },
   commentText: {
        whiteSpace: 'pre-wrap',
    },
}));


const CommentPanel = ({ comment }) => {

    const classes = useStyles();

    const { activeAppId, appSiteIds, selectedSite } = useSelector(
        (state) => state.account
    );


    const siteUsers = useContactsForAppAndSite({
        siteExternalIds: selectedSite
            ? [selectedSite.externalId]
            : appSiteIds[activeAppId],
    });

    const firstName = siteUsers?.data[comment.userExternalId]?.firstName;
    const lastName = siteUsers?.data[comment.userExternalId]?.lastName;

    return (
        <div className={classes.comment}>
            <UserInitials firstName={firstName} lastName={lastName} />
            <div>
                <div className={classes.commentName}>{firstName} {lastName}</div>
                <div className={classes.commentDate}>{comment.createdDate && formatShortMonthDateAndTime(comment.createdDate)}</div>
                <div className={classes.commentText}>{comment.text}</div>
            </div>
        </div>
    )
}           

export default CommentPanel;