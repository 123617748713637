import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    deleteBtn: {
        background: theme.palette.error.dark,
            color: theme.palette.getContrastText(theme.palette.error.dark),
        },
}));

const HazardControlDeleteDialog = ({
    isOpen,
    setIsOpen,
    hazardControl,
    updateControl,

}) => {
    const classes = useStyles();
    const [hazardControlText, setHazardControlText] = useState("");

    useEffect(() => {
        if (hazardControl) {
            setHazardControlText(hazardControl?.control?.name);
        }
    }, [hazardControl]);

    const handleConfirm = async e => {
        e.preventDefault();
        updateControl({ controlId: hazardControl?.id, hazardControlText, isCustomHazard: hazardControl?.control?.isCustomHazard, isCustomControl: hazardControl?.isCustomControl })
        handleClose();
    }

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Delete Control</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to remove this control from the current Risk Assessment? The control will no
                    longer appear but will be displayed when reviewing previously completed assessments.
                </DialogContentText>
                <DialogContentText>
                    If removed then it will also no longer appear in any subsequent new versions of this Risk Assessment.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button className={classes.deleteBtn} onClick={handleConfirm} variant="contained" color="primary">
                    Confirm Removal
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HazardControlDeleteDialog;
