import React from "react";
import { Box, Container, LinearProgress, makeStyles } from "@material-ui/core";
import { ReactComponent as WorknestLogo } from "../images/myWorkNest_Purple_Positive_RGB.svg";

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(4),
    maxWidth: "300px",
  },
  logo: {
    width: "100%",
  },
  progress: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const LoginLoader = () => {
  const classes = useStyles();
  return (
    <Box height="100vh" display="flex" alignItems="center">
      <Container maxWidth="sm">
        <div className={classes.logoWrapper}>
          <WorknestLogo />
        </div>
        <LinearProgress classes={{ barColorPrimary: classes.progress }} />
      </Container>
    </Box>
  );
};

export default LoginLoader;
