import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateFormService from "../../services/templateFormService";

const useDashboardSitesTemplateForms = ({ externalIds, pageSize, pageNum, moduleArea, sortOrder, orderByColumn, fromDate, toDate, assignees, filteredText }) => {
    return useQuery(
        [queryKeys.templateForms, externalIds?.join(","), pageNum, pageSize, moduleArea !== null ? String(moduleArea) : null, sortOrder, orderByColumn, fromDate, toDate, assignees?.join(","),
        filteredText && filteredText.length > 3 ? filteredText : undefined].filter(Boolean),

        () => templateFormService.fetchDashboardTemplateFormsForSites({ siteExternalIds: externalIds, pageSize, pageNum, moduleArea, sortOrder, orderByColumn, fromDate, toDate, filteredText: filteredText && filteredText.length > 3 ? filteredText : undefined, assignees }),
        {
            enabled: !!externalIds?.length, 
        }
    );
}

export default useDashboardSitesTemplateForms;
