import React, { useState, useEffect } from "react";
import {
    Box, 
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
	makeStyles,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import useUpdateTemplateForm from "../../../../safetynest/hooks/mutations/useUpdateTemplateForm";
import {useSnackbar} from "notistack";
import { capitaliseFirstLetter } from "../../../../utils/stringUtils";
import useIsMobile from "../../../../hooks/useIsMobile";
import useUpdateTemplateFormHistory from "../../../hooks/mutations/useUpdateTemplateFormHistory";

const useStyles = makeStyles(  ({
    datePicker: {
        display: 'flex',
        alignItems: 'flex-end'
    }
}));

const TemplateFormHistoryAmendDialog = ({ isOpen, setIsOpen, form, page, rows, moduleArea, sortBy, orderBy }) => {
    const classes = useStyles();
    const updateTemplateForm = useUpdateTemplateForm();
    const { mutate } = useUpdateTemplateFormHistory();
    
    const [completedDate, setCompletedDate] = useState("");
    const [notes, setNotes] = useState("");
    const isMobile = useIsMobile();
    const { enqueueSnackbar } = useSnackbar();

	const handleClose = () => {
		setIsOpen(false);
    };

    const handleChangeNotes = (newNotes) => {
        setNotes({
            ...notes,
            notes: newNotes,
        });
    };
    
    function handleClickSave(e) {
        e.preventDefault()
        const dataToSave = {
            ...notes,
            completionDate: completedDate
        };

        mutate({
            templateFormHistory: dataToSave,
            pageSize: rows,
            pageNum: page + 1,
            templateFormId: form.templateFormId,
            sortBy,
            orderBy
        },
            {
                onSuccess: () => {
                    setNotes(null);
                    handleClose();
                        }
            },
            {
                onError: (error) => {
                    console.error(error);
                    enqueueSnackbar(
                        error?.message || "There was an error updating the form history",
                        {
                            variant: "error",
                        }
                    );
                },
            }
        )
    };

    useEffect(() => {
        if (form) {
            setNotes(form);
            setCompletedDate(form.completedDate);
        }

    }, [form]);

    const getDialogContent = () => {
            return (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} className={classes.datePicker}>
                            <FormControl fullWidth>
                                <KeyboardDatePicker
                                    format="dd/MM/yyyy"
                                    variant='inline'
                                    inputVariant="outlined"
                                    label="Date Completed"
                                    value={completedDate}
                                    onChange={(date) => setCompletedDate(new Date(date.setHours(12)))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Notes"
                                fullWidth
                                value={notes?.notes || ""}
                                onChange={e => handleChangeNotes(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </>
            );
    }

    return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			fullScreen={isMobile}
		>
			<DialogTitle>{`Amend Completed Historic ${capitaliseFirstLetter(moduleArea)} Form`}</DialogTitle>
            <form>
				<Box m={3}>
					{getDialogContent()}
				</Box>
                <DialogActions>
                    {updateTemplateForm.isLoading ?
						<CircularProgress /> :
						<>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" onClick={(e) => handleClickSave(e)} color="primary">Save</Button>
						</>}
				</DialogActions>
			</form>
        </Dialog>
    );
}

export default TemplateFormHistoryAmendDialog;