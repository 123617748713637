import { useQuery } from "react-query";
import userAccessPermissionService from "../../services/userAccessPermissionService";
import queryKeys from "../../constants/queryKeys";

const useUserAccessPermission = ({ userExternalId, hasSafetyNest }) =>
  useQuery(
    [queryKeys.userAccessPermission, userExternalId],
    () => userAccessPermissionService.getByUserExternalId(userExternalId),
    {
      enabled: !!userExternalId && hasSafetyNest,
    }
  );

export default useUserAccessPermission;
