import { alpha, Grid, Link, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import {
  generatePath,
  Link as RouterLink,
  useRouteMatch,
} from "react-router-dom";
import layoutConstants from "../../constants/layoutConstants";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#fff",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  card: {
    padding: theme.spacing(2),
    boxShadow: "none",
    border: "1px solid #00000012",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    },
  },
  error: {
    backgroundColor: "#fff",
  },
}));

const CategoriesGrid = ({ categories, isNested = false }) => {
  const classes = useStyles();
  const { url, path, params } = useRouteMatch();

  return (
    <Grid container spacing={layoutConstants.homePageSectionsPaddingMultiplier}>
      {categories.map((item) => {
        const linkPath = isNested
          ? generatePath(path, {
              sectionName: params.sectionName,
              categoryId: item.id,
            })
          : `${url}/${item.id}`;

        return (
          <Grid item xs={12} md={6} key={item.id}>
            <Link className={classes.link} component={RouterLink} to={linkPath}>
              <Paper className={classes.card}>
                <Typography>{item.title.trim()}</Typography>
              </Paper>
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CategoriesGrid;
