import React, { useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  ArrowDownward,
  ArrowUpward,
  Delete,
  MoreVert,
} from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import useIsMobile from "../../../hooks/useIsMobile";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  deleteButton: {
    color: red[500],
    borderColor: red[500],
  },
  menuIcon: {
    minWidth: "32px",
  },
}));

function SectionOptions({
  onMoveUp,
  onMoveDown,
  onDelete,
  isFirst,
  isLast,
  isDeleteDisabled,
}) {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState();
  const isMobile = useIsMobile();

  return isMobile ? (
    <>
      <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)}>
        <MoreVert />
      </IconButton>
      <Menu
        keepMounted
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorEl={menuAnchor}
      >
        {onMoveUp && (
          <MenuItem disabled={isFirst} onClick={onMoveUp}>
            <ListItemIcon className={classes.menuIcon}>
              <ArrowUpward fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Move up" />
          </MenuItem>
        )}
        {onMoveDown && (
          <MenuItem disabled={isLast} onClick={onMoveDown}>
            <ListItemIcon className={classes.menuIcon}>
              <ArrowDownward fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Move down" />
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          disabled={isDeleteDisabled}
          onClick={onDelete}
          className={classes.deleteButton}
        >
          <ListItemIcon className={classes.menuIcon}>
            <Delete className={classes.deleteButton} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
    </>
  ) : (
    <div className={classes.container}>
      {onMoveUp && (
        <Button
          disabled={isFirst}
          onClick={onMoveUp}
          size="small"
          startIcon={<ArrowUpward />}
        >
          Move up
        </Button>
      )}
      {onMoveDown && (
        <Button
          disabled={isLast}
          onClick={onMoveDown}
          size="small"
          startIcon={<ArrowDownward />}
        >
          Move down
        </Button>
      )}
      <Button
        disabled={isDeleteDisabled}
        className={classes.deleteButton}
        variant="outlined"
        size="small"
        startIcon={<Delete />}
        onClick={onDelete}
      >
        Delete
      </Button>
    </div>
  );
}

export default SectionOptions;
