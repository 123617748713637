import React from "react";
import { ListItemText, makeStyles, MenuItem } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { ReactComponent as MyWorkNestLogo } from "../../images/myWorkNest_Purple_Positive_RGB.svg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
    },
    imageContainer: {
        width: 80,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    descriptionContainer: {
        whiteSpace: "normal",
        flexGrow: 1,
    },
    appLogo: {
        width: "100%",
    },
}));

function QuickNavigationMenuItem({ app }) {
    const classes = useStyles();

    return (
        <MenuItem
            className={classes.menuItem}
            component={app.clickUrl ? "a" : app.internalRoute ? Link : null}
            href={app.clickUrl ? app.clickUrl : undefined}
            to={app.internalRoute ? app.internalRoute : undefined}
        >
            <div className={classes.imageContainer}>
                {app.imageUrl ? (
                    <img
                        alt={app.displayName}
                        src={app.imageUrl}
                        className={classes.appLogo}
                    />
                ) : (
                    <MyWorkNestLogo />
                )}
            </div>
            <div className={classes.descriptionContainer}>
                <ListItemText primary={app.displayName} secondary={app.information} />
            </div>
            <ChevronRight />
        </MenuItem>
    );
}

export default QuickNavigationMenuItem;
