import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import ScoreCalculationDialog from "./dialogs/ScoreCalculationDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.spacing(1),
    backgroundColor: "#fff",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  title: {
    fontWeight: 700,
    paddingBottom: theme.spacing(1),
  },
  text: {
    fontSize: "0.75rem",
  },
  button: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(1),
    backgroundColor: "transparent",
    border: "none",
    fontWeight: 700,
    fontSize: "0.75rem",
    color: theme.palette.secondary.main,
    "&:hover": {
      cursor: "pointer",
    },
    width: "fit-content",
  },
}));

export default function ScoreCalculationInfo() {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <ScoreCalculationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <div className={classes.container}>
        <div className={classes.title}>
          How your SafetyNest Score is calculated
        </div>
        <div className={classes.text}>
          We calculate your SafetyNest score to give you an understanding of how
          you are managing health and safety, and what areas need your
          attention.
        </div>
        <div className={classes.button} onClick={() => setDialogOpen(true)}>
          Learn more about your SafetyNest Score
        </div>
      </div>
    </>
  );
}
