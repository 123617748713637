import React from "react";
import {
  Box,
  CircularProgress,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { grey } from "@material-ui/core/colors";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
  },
  fullHeight: {
    height: "100%",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  description: {
    color: grey[500],
    marginBottom: theme.spacing(2),
  },
  loadingContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    marginBottom: theme.spacing(2),
  },
  fetchingSpinner: {
    marginRight: theme.spacing(1),
  },
  rightWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

function CardBase({
  title = null,
  description = null,
  children,
  isLoading = false,
  isFetching = false,
  error = null,
  fullHeight,
  errorComponent: ErrorComponent = null,
  rightComponent = null,
  flat = false,
}) {
  const classes = useStyles();

  const cardContent = () => {
    if (isLoading)
      return (
        <Box className={classes.loadingContainer}>
          <CircularProgress data-testid="loading-spinner" color="primary" />
        </Box>
      );

    if (error) {
      if (ErrorComponent) return <ErrorComponent />;
      return (
        <Box className={classes.loadingContainer}>
          <Alert variant="outlined" severity="error">
            {error.message}
          </Alert>
        </Box>
      );
    }

    return children;
  };

  return (
    <Paper
      className={clsx({
        [classes.card]: true,
        [classes.fullHeight]: !!fullHeight,
      })}
      variant={flat ? "outlined" : "elevation"}
      elevation={flat ? 0 : 1}
    >
      <div className={classes.titleWrapper}>
        {title && <Typography variant="h6">{title}</Typography>}
        <div className={classes.rightWrapper}>
          {!isLoading && isFetching && (
            <CircularProgress
              className={classes.fetchingSpinner}
              data-testid="fetching-spinner"
              size={24}
              color="primary"
            />
          )}
          {rightComponent}
        </div>
      </div>
      {description && (
        <Typography className={classes.description} variant="subtitle2">
          {description}
        </Typography>
      )}
      {cardContent()}
    </Paper>
  );
}

export default CardBase;
