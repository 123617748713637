import React, { Fragment } from "react";
import TemplateSection from "./TemplateSection";
import { Button, makeStyles } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { addSection, insertSection } from "../../../redux/actions/templateBuilderActions";
import { grey } from "@material-ui/core/colors";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  sectionsContainer: {
    marginTop: theme.spacing(2),
  },
  connectionWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  insertSectionButton: {
    backgroundColor: "white",
    border: `1px solid ${grey[300]}`,
    borderRadius: "4px",
    fontSize: "12px",
    padding: "4px 8px",
    color: theme.palette.secondary.main,
    fontWeight: "normal",
    zIndex: 1,
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
  endConnection: {
    position: "absolute",
    top: 0,
    left: "50%",
    backgroundColor: grey[300],
    width: "1px",
    height: "50%",
  },
  connection: {
    position: "absolute",
    top: 0,
    left: "50%",
    backgroundColor: grey[300],
    width: "1px",
    height: "100%",
  },
}));

const Template = ({ templateSubjectId, isInvestigation, setTemplateHasBeenUpdated }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { sectionIds, investigationSectionIds, sections } = useSelector(
    (state) => state.templateBuilder
  );
  
  const sectionIdsToMap = isInvestigation ? investigationSectionIds : sectionIds;
  
  return (
    <div className={classes.sectionsContainer}>
      {sectionIdsToMap.map((sectionId, index) => (
        <Fragment key={sectionId}>
          <TemplateSection
                  index={index}
                  sectionId={sectionId}
                  section={sections[sectionId]}
                  isFirst={index === 0}
                  isLast={index + 1 === sectionIdsToMap.length}
                  isOnly={sectionIdsToMap.length === 1}
                  subjectId={templateSubjectId}
                  isInvestigation={isInvestigation}
                  setTemplateHasBeenUpdated={setTemplateHasBeenUpdated}
          />
          {index + 1 === sectionIdsToMap.length ? (
            <div className={classes.connectionWrapper}>
              <Button
                className={classes.insertSectionButton}
                size="small"
                startIcon={<AddCircleOutline/>}
                onClick={() => dispatch(addSection({ isInvestigation: !!isInvestigation }))}
              >
                Add Section
              </Button>
              <div className={classes.endConnection}/>
            </div>
          ) : (
            <div className={classes.connectionWrapper}>
              <Button
                className={classes.insertSectionButton}
                size="small"
                startIcon={<AddCircleOutline/>}
                onClick={() => dispatch(insertSection({atIndex: index + 1, isInvestigation: !!isInvestigation}))}
              >
                Insert Section
              </Button>
              <div className={classes.connection}/>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
}

export default Template;