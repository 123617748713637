import { useMutation } from "react-query";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";
import actionPresetService from "../../services/actionPresetService";

const useAddActionPreset = () =>
  useMutation(actionPresetService.addNew, {
    // eslint-disable-next-line no-unused-vars
    onSettled: (_data, _error, variables) => {
      if (variables)
        reactQueryUtils.client.invalidateQueries([
          queryKeys.actionPresets,
          !!variables.subjectId ? variables.subjectId : null,
        ]);
    },
  });

export default useAddActionPreset;
