import React from "react";
import RequiresRoleWrapper from "../components/RequiresRoleWrapper";
import { useAuth } from "../contexts/authContext";
import TutorialBuilder from "../components/TutorialBuilder";
import { useHistory, useParams } from "react-router-dom";
import useTutorial from "../hooks/queries/useTutorial";
import useDeleteTutorial from "../hooks/mutations/useDeleteTutorial";
import { useSnackbar } from "notistack";
import useUpdateTutorial from "../hooks/mutations/useUpdateTutorial";

function EditTutorial() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isTutorialAdmin } = useAuth();
  const { tutorialId } = useParams();
  const tutorial = useTutorial(tutorialId);
  const deleteTutorial = useDeleteTutorial();
  const updateTutorial = useUpdateTutorial();

  const onSubmit = (tutorial) => {
    const updatedTutorial = {
      id: tutorialId,
      ...tutorial,
    };

    updateTutorial.mutate(updatedTutorial, {
      onSuccess: () => goBack(),
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("Could not update tutorial.", { variant: "error" });
      },
    });
  };

  const goBack = () => {
    history.goBack();
  };

  const deleteTutorialCallback = () => {
    deleteTutorial.mutate(tutorialId, {
      onSuccess: () => goBack(),
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("Could not delete tutorial.", { variant: "error" });
      },
    });
  };

  return (
    <RequiresRoleWrapper hasRole={isTutorialAdmin}>
      <TutorialBuilder
        existingTutorial={tutorial?.data}
        isSaving={false}
        isLoading={tutorial.isLoading}
        onSubmit={onSubmit}
        onCancel={goBack}
        onDelete={deleteTutorialCallback}
        isDeleting={deleteTutorial.isLoading}
      />
    </RequiresRoleWrapper>
  );
}

export default EditTutorial;
