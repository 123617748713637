import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import sectorService from "../../services/sectorService";

const useSectors = () =>
  useQuery([queryKeys.sectors], () => sectorService.fetchAllSectors(), {
    staleTime: Infinity,
  });

export default useSectors;
