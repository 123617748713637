import React from "react";
import {
  alpha,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    alignItems: "start",
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    padding: 0,
    marginRight: theme.spacing(2),
  },
  checkboxChecked: {
    color: theme.palette.secondary.main,
  },
  labelContainer: {
    display: "flex",
    flexDirection: "column",
  },
  labelWrapper: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  disabled: {
    opacity: 0.6,
  },
  label: {
    fontSize: 15,
    fontWeight: 600,
  },
  smallerLabel: {
    fontSize: 13,
    fontWeight: 600,
  },
  labelOldName: {
    fontSize: 15,
    fontWeight: 500,
    marginLeft: 2,
  },
  description: {
    color: alpha(theme.palette.primary.main, 0.6),
  },
  smallerDescription: {
    fontSize: 12,
    color: alpha(theme.palette.primary.main, 0.6),
  },
}));

export const BreadboxCheckbox = ({
  title = "",
  description = "",
  checked = false,
  onChange,
  oldName,
  hasSmallerLabel,
  disabled,
  indeterminate,
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.formControlLabel}
      control={
        <Checkbox
          size="small"
          classes={{ checked: classes.checkboxChecked }}
          className={classes.checkbox}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
          indeterminate={indeterminate}
        />
      }
      label={
        <div
          className={`${classes.labelContainer} ${
            disabled ? classes.disabled : ""
          }`}
        >
          <div className={classes.labelWrapper}>
            <Typography
              color="primary"
              variant="body2"
              className={hasSmallerLabel ? classes.smallerLabel : classes.label}
            >
              {title}
            </Typography>
            {oldName && (
              <Typography variant="body2" className={classes.labelOldName}>
                ({oldName})
              </Typography>
            )}
          </div>
          <Typography
            variant="body2"
            className={
              hasSmallerLabel ? classes.smallerDescription : classes.description
            }
          >
            {description}
          </Typography>
        </div>
      }
    />
  );
};

export default BreadboxCheckbox;
