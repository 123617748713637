import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import auditService from "../../services/auditService";

const useDownloadAuditPdf = ({ auditId, isDriAudit }, options = {}) =>
  useQuery(
    [queryKeys.auditPdf, auditId, isDriAudit],
    () => auditService.downloadPdfByAuditId(auditId, isDriAudit),
    {
      enabled: !!auditId,
      ...options,
    }
  );

export default useDownloadAuditPdf;
