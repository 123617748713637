export const frequency = {
  DAILY: 0,
  WEEKLY: 1,
  MONTHLY: 2,
  THREEMONTHLY: 3,
  SIXMONTHLY: 4,
  YEARLY: 5,
  FOURTEENMONTHLY: 6,
  TWOWEEKLY: 7,
  TWOMONTHLY: 8,
  TWOYEARLY: 9,
  THREEYEARLY: 10,
  FIVEYEARLY: 11,
  NONE: 12,
};

export const frequencyDropdownValues = [
  {
    value: frequency.DAILY,
    label: "Daily",
  },
  {
    value: frequency.WEEKLY,
    label: "Weekly",
  },
  {
    value: frequency.TWOWEEKLY,
    label: "Every 2 weeks",
  },
  {
    value: frequency.MONTHLY,
    label: "Monthly",
  },
  {
    value: frequency.TWOMONTHLY,
    label: "Every 2 months",
  },
  {
    value: frequency.THREEMONTHLY,
    label: "Every 3 months",
  },
  {
    value: frequency.SIXMONTHLY,
    label: "Every 6 months",
  },
  {
    value: frequency.YEARLY,
    label: "Yearly",
  },
  {
    value: frequency.FOURTEENMONTHLY,
    label: "Every 14 months",
  },
  {
    value: frequency.TWOYEARLY,
    label: "Every 2 years",
  },
  {
    value: frequency.THREEYEARLY,
    label: "Every 3 years",
  },
  {
    value: frequency.FIVEYEARLY,
    label: "Every 5 years",
  },
  {
     value: frequency.NONE,
     label: "None",
  },
];

export const frequencyInterval = {
    LASTCOMPLETEDDATE: 0,
    ORIGINALDUEDATE: 1,
};

export const frequencyOptionValues = [
    {
        value: frequencyInterval.LASTCOMPLETEDDATE,
        label: "Last completed date",
        description: "Due date frequency will repeat at regular intervals, based on when the form was last completed.",
    },
    {
        value: frequencyInterval.ORIGINALDUEDATE,
        label: "Original due date",
        description: "Due date frequency will repeat at regular intervals, based on the original due date.",
    },
];

export const dayBit = {
    Mon: 1,
    Tue: 2,
    Wed: 4,
    Thu: 8,
    Fri: 16,
    Sat: 32,
    Sun: 64,
};

export const frequencyDays = [
    {
        key: "Mon",
        label: "Mon",
    },
    {
        key: "Tue",
        label: "Tue",
    },
    {
        key: "Wed",
        label: "Wed",
    },
    {
        key: "Thu",
        label: "Thu",
    },
    {
        key: "Fri",
        label: "Fri",
    },
    {
        key: "Sat",
        label: "Sat",
    },
    {
        key: "Sun",
        label: "Sun",
    },

];