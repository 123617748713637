import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import actionService from "../../services/actionService";

const useMostImpactfulActionsForSites = ({ siteExternalIds }) =>
    useQuery(
        [
            queryKeys.mostImpactfulActions,
            siteExternalIds?.join(","),
        ],

        () => actionService.fetchMostImpactfulActionsForSite({ siteExternalIds }),
        {
            enabled: !!siteExternalIds?.length,
        }
    );

export default useMostImpactfulActionsForSites;
