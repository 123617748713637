import React from "react";
import { TreeItem } from "@material-ui/lab";
import { IconButton, makeStyles } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { useDrop } from 'react-dnd';
import useRenameDocument from "../../hooks/mutations/useRenameDocument";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
    label: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

const Label = ({
    onClickMenu,
    selected,
    hovered,
    nodeId,
    label,
    isRootNode,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.label}>
            {label}
            <IconButton
                onClick={onClickMenu}
                style={{
                    visibility:
                        (selected === nodeId || hovered === nodeId) && !isRootNode && onClickMenu
                            ? "visible"
                            : "hidden",
                }}
            >
                <MoreVert />
            </IconButton>
        </div>
    );
};

const StyledTreeItem = ({
    children,
    label,
    nodeId,
    selected,
    hovered,
    setMenuAnchor,
    setHovered,
    handleClickMenu,
    ...props
}) => {
    const onClickMenu = (e) => {
        e.stopPropagation();
        handleClickMenu(e.currentTarget, nodeId);
    };

    const renameDocument = useRenameDocument();
    const { enqueueSnackbar } = useSnackbar();

    const moveDocument = (oldFolder, newFolder, documentName) => {
        const oldDocumentPath = `${oldFolder}/${documentName}`;
        const newDocumentPath = `${newFolder}/${documentName}`;

        renameDocument.mutate(
            {
                oldDocumentPath,
                newDocumentPath,
                selectedFolder: newFolder,
            },
            {
                onSuccess: () => enqueueSnackbar(
                    `'${documentName}' moved to '${newFolder.split("/").pop()}'`,
                    { variant: "default", }
                ),
                onError: e => enqueueSnackbar(
                    e?.message || "There was an error moving the file",
                    { variant: "error" }
                ),
            }
        );        
    };


    const [, drop] = useDrop(
        () => ({
            accept: "file",
            drop: (item, monitor) => {
                console.log({ ...item, nodeId });
                moveDocument(item.from, nodeId, item.documentName);
                //if (monitor.isOver({ shallow: true }))
                //    console.log({ ...item, nodeId });
            },
            canDrop: (item, monitor) => item.selectedFolder !== nodeId && monitor.isOver({ shallow: true }),
            collect: monitor => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }), [nodeId]
    );

    const customProps = {
        nodeId,
        label: (
            <Label
                selected={selected}
                hovered={hovered}
                nodeId={nodeId}
                onClickMenu={handleClickMenu && onClickMenu}
                label={label}
                isRootNode={!nodeId.includes("/")}
            />
        ),
        onMouseEnter: () => setHovered(nodeId),
    };

    return (
        <>
            {children ? (
                <TreeItem ref={drop} {...customProps} {...props}>
                    {children}
                </TreeItem>
            ) : (
                <TreeItem ref={drop} {...customProps} {...props} />
            )}
        </>
    );
};

export default StyledTreeItem;
