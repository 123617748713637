import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  title: {
    lineHeight: "48px",
    fontSize: "36px",
    letterSpacing: "-3px",
    fontWeight: "500",
    color: theme.palette.primary.main,
  },
  description: {
    fontSize: "16px",
    lineHeight: "28px",
    letterSpacing: "0px",
    fontWeight: "400",
    color: theme.palette.primary.mainOpaque,
  },
  icon: {
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.mainOpaque,
    height: "36px",
    width: "36px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: "18px",
    },
  },
}));

export default function PageTitle({ icon, title, description }) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.titleContainer}>
        {icon && <div className={classes.icon}>{icon}</div>}
        <Typography className={classes.title}>{title}</Typography>
      </div>
      <Typography className={classes.description}>{description}</Typography>
    </div>
  );
}
