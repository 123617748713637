import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AppProviders from "./components/AppProviders";
import CssBaseline from "@material-ui/core/CssBaseline";
import "@brainhubeu/react-carousel/lib/style.css";
import "react-perfect-scrollbar/dist/css/styles.css";

if (process.env.NODE_ENV !== "development")
    console.log(`Build ${process.env.REACT_APP_BUILD_NUMBER} (${process.env.REACT_APP_COMMIT_HASH}) (${process.env.REACT_APP_ENV})`);

ReactDOM.render(
    <React.StrictMode>
        <AppProviders>
            <CssBaseline />
            <App />
        </AppProviders>
    </React.StrictMode>,
    document.getElementById("root")
);
