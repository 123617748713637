import React, { useState } from "react";
import Avatar from "../Avatar";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";

import {
  Email as EmailIcon,
  PhoneAndroid as MobileIcon,
  Phone as PhoneIcon,
} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  textCenter: {
    textAlign: "center",
  },
}));

const AccountManagerCard = ({ accountManagers }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const ViewPerson = (person) => (
    <Box
      key={person.email}
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={5}
      mb={2}
      ml={2}
      mr={2}
      className={classes.textCenter}
    >
      <Box>
        <Avatar size="lg" alt={person.name} src={person.photo} />
      </Box>
      <Box>
        <Typography variant="subtitle2">{person.name}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2">{person.position}</Typography>
      </Box>
    </Box>
  );

  const ViewPersonDetails = (accMan) => (
    <Grid key={accMan.name} container spacing={12}>
      <Grid item xs={12} md={9}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            As your Account Manager I am here to support you in the following
            areas throughout your agreement with WorkNest:
          </DialogContentText>
          <ul>
            <li>
              discuss any new requirements or additional services that you may
              require
            </li>
            <li>
              take overall responsibility for your relationship with WorkNest
            </li>
            <li>
              discuss any questions you may have over your contract agreement
              with WorkNest, such as payment terms, legal expenses insurance
              questions, invoicing queries etc.
            </li>
            <li>
              when appropriate, I will be responsible for discussing with you
              and proposing terms for the renewal of your agreement with
              WorkNest
            </li>
          </ul>
          <DialogContentText id="alert-dialog-footer">
            Although you will mostly be dealing with WorkNest colleagues who
            directly provide day to day support, please feel free to contact me
            if you have any questions about your service from WorkNest or any
            additional services you require.
          </DialogContentText>
        </DialogContent>
      </Grid>
      <Grid item xs={12} md={3}>
        <DialogContent>
          <Box
            key={accMan.name}
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={5}
            mb={2}
            ml={2}
            mr={2}
            className={classes.textCenter}
          >
            <Box>
              <Avatar alt={accMan.name} src={accMan.photo} size="lg" />
            </Box>
            <Typography className={classes.textCenter} variant="h5" mb={6}>
              {accMan.name}
            </Typography>
            <Link href={"mailto:" + accMan.email} underline="hover">
              <EmailIcon fontSize="small" />
              {accMan.email}
            </Link>
            {accMan.phone && (
              <Link href={"tel:" + accMan.phone} underline="hover">
                <PhoneIcon fontSize="small" />
                {accMan.phone}
              </Link>
            )}
            {accMan.mobile && (
              <Link href={"tel:" + accMan.mobile} underline="hover">
                <MobileIcon fontSize="small" />
                {accMan.mobile}
              </Link>
            )}
          </Box>
        </DialogContent>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {accountManagers.length > 0 && (
        <CardContent>
          <Box display="flex">
            <Box flexGrow={1} alignItems="center">
              <Typography className={classes.textCenter} variant="h5" mb={6}>
                {accountManagers.length === 1
                  ? "Your " + accountManagers[0].role
                  : "Your " + accountManagers[0].role + " Team"}
              </Typography>
              <Box
                display="flex"
                justifyContent="space-around"
                flexWrap="wrap"
                m={-2}
              >
                {accountManagers.map((a) => ViewPerson(a))}
              </Box>
              <Box
                display="flex"
                justifyContent="space-around"
                flexWrap="wrap"
                m={2}
              >
                <Button
                  variant="outlined"
                  className={classes.textCenter}
                  onClick={() => setOpen(true)}
                  color="primary"
                >
                  More Information
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      )}
      <Grid>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {accountManagers.length === 1
              ? "Your " + accountManagers[0].role
              : "Your " + accountManagers[0].role + " Team"}
          </DialogTitle>
          <DialogActions>
            {accountManagers.map((a) => ViewPersonDetails(a))}
          </DialogActions>
          <Box
            display="flex"
            justifyContent="space-around"
            flexWrap="wrap"
            m={2}
          >
            <Button
              variant="outlined"
              className={classes.textCenter}
              onClick={handleCloseDialog}
              color="primary"
            >
              Close
            </Button>
          </Box>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
};

export default AccountManagerCard;
