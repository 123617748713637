import { useMutation } from "react-query";
import salesforceService from "../../services/salesforceService";
import reactQueryUtils from "../../utils/reactQueryUtils";
import queryKeys from "../../constants/queryKeys";

const useDeleteUserFromSite = () =>
  useMutation(salesforceService.deleteAccountContactRelation, {
    onMutate: async ({ selectedSiteId, userId }) => {
      const { client } = reactQueryUtils;
      const queryKey = [queryKeys.siteUsers, selectedSiteId];
      await client.cancelQueries(queryKey);

      const usersBeforeChange = client.getQueryData(queryKey);

      client.setQueryData(queryKey, (siteUsers) =>
        Object.keys(siteUsers)
          .filter((key) => siteUsers[key].externalId !== userId)
          .reduce((curr, key) => ({ ...curr, [key]: siteUsers[key] }), {})
      );

      return { usersBeforeChange };
    },
    onError: (error, { selectedSiteId }, { usersBeforeChange }) => {
      const queryKey = [queryKeys.siteUsers, selectedSiteId];
      reactQueryUtils.client.setQueryData(queryKey, usersBeforeChange);
    },
    onSettled: (_data, _error, { selectedSiteId }) => {
      if (selectedSiteId)
        reactQueryUtils.client.invalidateQueries([
          queryKeys.siteUsers,
          selectedSiteId,
        ]);
    },
  });

export default useDeleteUserFromSite;
