import { makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useActionDetails } from "../../contexts/ActionContext";
import useAddActionComment from "../../hooks/mutations/useAddActionComment";
import CommentPanel from "../common/CommentPanel";
import AddComment from "../common/AddComment";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        rowGap: theme.spacing(4),
        flexDirection: 'column',
    },
}));

export default function ActionComments() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [newComment, setNewComment] = useState('');    
    
    const addComment = useAddActionComment();   
    
    const { actionCommentDetails, selectedActionId, addComments, isDri } = useActionDetails()

    function saveComment() {
        const commentToAdd = {
            comment: newComment,
            actionId: selectedActionId,
            isDri,
        }

        addComment.mutate(commentToAdd, {
            onSuccess: (data) => {
                setNewComment('')
                addComments(data)
            },
            onError: (error) => {
                console.error(error);
                enqueueSnackbar("Could not add comment to action.", { variant: "error" });
            },
        })
    }     

    const comments = () => {
        return actionCommentDetails?.map((comment, index) => (
            <div key={index}>
                <CommentPanel comment={comment} />
            </div>
        ))
    }    

    return (
        <div className={classes.container}>
            <AddComment newComment={newComment} setNewComment={setNewComment} isLoading={addComment.isLoading} saveComment={saveComment} />
            {comments()}
        </div>
    )
}