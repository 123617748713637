import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import prismicService from "../../services/prismicService";

const usePrismicCategories = (sectionName) =>
  useQuery([queryKeys.prismicCategories, sectionName], () =>
    prismicService.fetchCategories(sectionName)
  );

export default usePrismicCategories;
