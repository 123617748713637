import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import { editorConfig } from "../utils/ckEditorConfig";
import editorTypes from "../constants/editorTypes";

function WysiwygEditor({ content = "", onChange, type }) {
  const handleChange = (_e, editor) => {
    onChange(editor.getData());
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      config={editorConfig}
      data={content}
	  onChange={handleChange}
		  onReady={editor => {
			  if (type === editorTypes.READONLY) {
			  editor.isReadOnly = 'true';
			  const toolbarElement = editor.ui.view.toolbar.element;
			  toolbarElement.style.display = 'none';
		  }
	  }}
    />
  );
}

export default WysiwygEditor;
