import { alpha, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import useIsMobile from "../../../hooks/useIsMobile";
import ReactCountUp from "react-countup";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ScoreSkittle from "./ScoreSkittle";
import TrendIndicator from "./TrendIndicator";
import {
  bandColours,
  getColourForBand, scoreTrend,
} from "../../constants/safetynestScoreConstants";

const scoreBandBackgroundOpacity = 0.1;

const useStyles = makeStyles((theme) => ({
  scoreDisplay: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    boxShadow: ({ compact }) =>
      compact
        ? "none"
        : "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    "&.band-0": {
      backgroundColor: alpha(bandColours.AT_RISK, scoreBandBackgroundOpacity),
    },
    "&.band-1": {
      backgroundColor: alpha(bandColours.REACTIVE, scoreBandBackgroundOpacity),
    },
    "&.band-2": {
      backgroundColor: alpha(
        bandColours.DEVELOPING,
        scoreBandBackgroundOpacity
      ),
    },
    "&.band-3": {
      backgroundColor: alpha(bandColours.PROACTIVE, scoreBandBackgroundOpacity),
    },
    "&.band-4": {
      backgroundColor: alpha(
        bandColours.ESTABLISHED,
        scoreBandBackgroundOpacity
      ),
    },
  },
  scoreContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  score: {
    fontSize: "4rem",
    color: theme.palette.primary.main,
    fontWeight: 700,
    "&.withMarginTop": {
      marginTop: theme.spacing(2.5),
    },
  },
  scoreBand: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  scoreBandEmphasis: {
    fontWeight: 500,
    backgroundColor: "#d9e4adcc",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    "&.band-0": {
      backgroundColor: bandColours.AT_RISK,
      color: theme.palette.getContrastText(bandColours.AT_RISK),
    },
    "&.band-1": {
      backgroundColor: bandColours.REACTIVE,
      color: theme.palette.getContrastText(bandColours.REACTIVE),
    },
    "&.band-2": {
      backgroundColor: bandColours.DEVELOPING,
      color: theme.palette.getContrastText(bandColours.DEVELOPING),
    },
    "&.band-3": {
      backgroundColor: bandColours.PROACTIVE,
      color: theme.palette.getContrastText(bandColours.PROACTIVE),
    },
    "&.band-4": {
      backgroundColor: bandColours.ESTABLISHED,
      color: theme.palette.getContrastText(bandColours.ESTABLISHED),
    },
  },
  scoreBands: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(0, 2),
    display: "grid",
    width: "100%",
    gridTemplateColumns: "repeat(5, 1fr)",
    columnGap: theme.spacing(0.25),
  },
  circularProgress: {
    padding: theme.spacing(4),
  },
  scoreWrapper: {
    position: "relative",
  },
  trendIndicator: {
    position: "absolute",
    left: "100%",
    top: "10px",
  },
}));

function OverallScore({ scoreDetails, compact = false }) {
  const classes = useStyles({ compact });
  const isMobile = useIsMobile();
  const { band, bandDescription, trend, scoreDifferenceText, overallScore } =
    scoreDetails;

  const SafetyNestScore = () => {
    return (
      <div className={classes.scoreContainer}>
        {isMobile ? (
          <div className={classes.circularProgress}>
            <CircularProgressbarWithChildren
              value={overallScore}
              minValue={0}
              maxValue={500}
              strokeWidth={3}
              styles={buildStyles({
                pathColor: getColourForBand(band),
              })}
            >
              <ReactCountUp
                end={overallScore}
                className={classes.score}
                duration={0.5}
                start={0}
              />
            </CircularProgressbarWithChildren>
          </div>
        ) : (
          <div className={classes.scoreWrapper}>
            {trend !== scoreTrend.MAINTAINING && (
              <div className={classes.trendIndicator}>
                <TrendIndicator
                  trend={trend}
                  difference={scoreDifferenceText}
                />
              </div>
            )}
            <ReactCountUp
              end={overallScore}
              className={clsx(classes.score, `withMarginTop`)}
              duration={0.5}
              start={0}
            />
          </div>
        )}
        <div className={classes.scoreBand}>
          {!compact && "Your SafetyNest Score is"}
          <div className={clsx(classes.scoreBandEmphasis, `band-${band}`)}>
            {bandDescription}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={clsx(classes.scoreDisplay, `band-${band}`)}>
      <SafetyNestScore />
      {!isMobile && (
        <div className={classes.scoreBands}>
          <ScoreSkittle
            compact={compact}
            bandRating={0}
            bandText="At Risk"
            bandLowerLimit={1}
            bandUpperLimit={100}
            overallSafetyNestScore={overallScore}
          />
          <ScoreSkittle
            compact={compact}
            bandRating={1}
            bandText="Reactive"
            bandLowerLimit={101}
            bandUpperLimit={200}
            overallSafetyNestScore={overallScore}
          />
          <ScoreSkittle
            compact={compact}
            bandRating={2}
            bandText="Developing"
            bandLowerLimit={201}
            bandUpperLimit={300}
            overallSafetyNestScore={overallScore}
          />
          <ScoreSkittle
            compact={compact}
            bandRating={3}
            bandText="Proactive"
            bandLowerLimit={301}
            bandUpperLimit={400}
            overallSafetyNestScore={overallScore}
          />
          <ScoreSkittle
            compact={compact}
            bandRating={4}
            bandText="Established"
            bandLowerLimit={401}
            bandUpperLimit={500}
            overallSafetyNestScore={overallScore}
          />
        </div>
      )}
    </div>
  );
}

export default OverallScore;
