import React from "react";
import {
  Box,
  CircularProgress,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useRouteMatch } from "react-router-dom";
import ContentCard from "./ContentCard";
import DownloadCard from "./DownloadCard";
import prismicContentTypes from "../../constants/prismicContentTypes";
import ErrorBox from "../ErrorBox";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  paginationWrapper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pageCounterWrapper: {
    color: "#fff",
    display: "flex",
    justifyContent: "end",
    marginBottom: theme.spacing(1),
  },
}));

const CategorySection = ({ query, page, onPageChange }) => {
  const classes = useStyles();
  const { data, isLoading, isError, error } = query;
  const { url } = useRouteMatch();

  const handlePageChange = (_e, newPage) => {
    onPageChange(newPage);
  };

  if (isLoading)
    return (
      <Box className={classes.wrapper}>
        <CircularProgress color="primary" />
      </Box>
    );

  if (isError)
    return (
      <ErrorBox message={error?.message || "Could not load articles."} />
    );

  return (
    <>
      {query.data.totalPages > 1 && (
        <Box className={classes.pageCounterWrapper}>
          <Typography>{`Page ${page} of ${query.data.totalPages}`}</Typography>
        </Box>
      )}
      {data.results.map((item) =>
        item.type === prismicContentTypes.download ? (
          <DownloadCard
            key={item.id}
            title={item.title}
            previewImageUrl={item.previewImageUrl}
            downloadUrl={item.downloadUrl}
          />
        ) : (
          <ContentCard
            key={item.id}
            title={item.title}
            content={item.excerpt}
            route={`${url}/${item.type}/${item.id}/${page}`}
            type={item.type}
          />
        )
      )}
      {query.data.totalPages > 1 && (
        <Paper className={classes.paginationWrapper}>
          <Pagination
            shape="rounded"
            page={page}
            count={query.data.totalPages}
            onChange={handlePageChange}
          />
        </Paper>
      )}
    </>
  );
};

export default CategorySection;
