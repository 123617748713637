import React from "react";
import { NavigateNext } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

function NextButton(props) {
 return  <IconButton {...props}>
   <NavigateNext />
 </IconButton>
}

export default NextButton;