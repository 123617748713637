import { useMutation } from "react-query";
import reactQueryUtils from "../../../utils/reactQueryUtils";
import queryKeys from "../../../constants/queryKeys";
import templateFormHistoryService from "../../services/templateFormHistoryService";

const useSaveTemplateForm = () =>
  useMutation(
    templateFormHistoryService.saveTemplateForm,
    {
      onSuccess: () => {
        reactQueryUtils.client.invalidateQueries([queryKeys.templateForms]);
      }
    }
  );

export default useSaveTemplateForm;