import { useMemo } from "react";

const useSiblingFolderNames = ({ folder, sites }) =>
  useMemo(() => {
    if (!folder) return [];

    const parentElements = folder.split("/");
    parentElements.pop();
    const siteExternalId = parentElements[0];
    let branch = sites.filter((x) => x.externalId === siteExternalId)[0]?.tree;
    if (!branch) return [];

    for (let i = 1; i < parentElements.length; i++) {
      branch = branch[parentElements[i]];
    }

    return Object.keys(branch).filter((x) => x !== "$$$.$$$");
  }, [sites, folder]);

export default useSiblingFolderNames;
