import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import useDisableTemplate from "../../hooks/mutations/useDisableTemplate";
import { useSnackbar } from "notistack";

const ArchiveTemplateDialog = ({
  open,
  onCancel,
  archiveTemplateId,
  applicationArea,
  page,
  rows,
  accountId,
  siteExternalIds,
}) => {
  const disableTemplate = useDisableTemplate();
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirm = () => {
    disableTemplate.mutate(
      {
        templateId: archiveTemplateId,
        applicationArea,
        page,
        rows,
        accountId,
        siteExternalIds,
      },
      {
        onError: (e) =>
          enqueueSnackbar(e?.message || "Unable to archive Template", {
            variant: "error",
          }),
      }
    );
    onCancel();
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Archive Template?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to archive this template? The template will no
          longer be available for users to add to their sites.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveTemplateDialog;
