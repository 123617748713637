import { Checkbox, Grid, InputAdornment, List, ListItem, ListItemIcon, ListItemText, makeStyles, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useRiskAssessmentDetails } from "../../contexts/RiskAssessmentContext";
import useHazards from "../../hooks/queries/useHazards";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
    hazardIconText: {
        '&> span': {
          display: 'flex',
          alignItems: 'center'
        }
    },
    hazardIcons: {
        height: '40px',
        marginRight: theme.spacing(2)
    },
    hazardContainer: {
        marginBottom: theme.spacing(2)
    },
    listWrapper: {
        height: '400px', 
        overflowY: 'scroll', 
        display: 'flex', 
        flexDirection: 'row', 
        flexWrap: 'wrap',
        marginTop: theme.spacing(2)
    },
    halfWidth: {
        width: '50%'
    },
}));

export function HazardSelector() {
    const classes = useStyles()
    const hazards = useHazards()
    const {
        riskAssessmentDetails,
        removeHazard,
        addHazard,
    } = useRiskAssessmentDetails()

    const [hazardSearch, setHazardSearch] = useState('')

    const hazardImagesUrl = `${process.env.PUBLIC_URL}/images/hazardIcons/`

    function toggleHazard(hazard) {
        riskAssessmentDetails?.hazardIds?.includes(hazard?.id) ?
            removeHazard(hazard?.id) :
            addHazard(hazard)
    }

    return (
        <Grid container className={classes.hazardContainer}>
            <Grid item xs={12}>
                <TextField 
                    size="small"
                    label='Search for hazards'
                    variant="outlined"
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    )
                    }}
                    className={classes.halfWidth}
                    value={hazardSearch}
                    onChange={(e) => setHazardSearch(e.target.value)}
                />
                <List required className={classes.listWrapper}>
                    <Grid container>
                        {Object.keys(riskAssessmentDetails?.customHazards)?.length > 0 && Object.keys(riskAssessmentDetails?.customHazards)?.map(customHazard => (
                            <Grid item key={customHazard} xs={12} md={4}>
                                <ListItem>
                                    <ListItemIcon>
                                        <Checkbox checked disabled />
                                    </ListItemIcon>
                                    <ListItemText className={classes.hazardIconText}>
                                        <img className={classes.hazardIcons} alt='warning' src={`${hazardImagesUrl}General warning.svg`} />
                                        {riskAssessmentDetails?.customHazards[customHazard]?.name}
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        ))}
                        {hazards?.data?.filter(x => x.name.toLowerCase().includes(hazardSearch.toLowerCase()))?.map(hazard => {
                            const img = new Image()
                            img.src = `${hazardImagesUrl}${hazard?.name}.svg`

                            return (
                                <Grid item key={hazard?.id} xs={12} md={4}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Checkbox 
                                                onChange={() => toggleHazard(hazard)} 
                                                checked={riskAssessmentDetails?.hazardIds?.includes(hazard?.id)} 
                                            />
                                        </ListItemIcon>
                                        <ListItemText className={classes.hazardIconText}>
                                            <img className={classes.hazardIcons} alt='warning' src={img.complete ? img.src : `${hazardImagesUrl}General warning.svg`} />
                                            {hazard?.name}
                                        </ListItemText>
                                    </ListItem>
                                </Grid>
                            )
                        })}
                    </Grid>
                </List>
            </Grid>
        </Grid>
    )
}