import { Block } from '@material-ui/icons';
import { makeStyles, Typography } from '@material-ui/core';
import FixedButton from '../FixedButton';

const useStyles = makeStyles(() => ({
    unauthorized: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "calc(100vh - 96px)",
        gap: "24px",
    },
    icon: {
        height: "120px",
        width: "120px",
        background: "#f2edff",
        borderRadius: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    iconFont: {
        fontSize: "64px",
        color: "#5c45c7",
    },
    text: {
        maxWidth: "640px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    textBody: {
        display: "flex",
        flexDirection: "column",
        gap: "24px",
    },
    titleText: {
        fontWeight: "600",
        fontSize: "24px",
        lineHeight: "36px",
    },
    subTitleText: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
    },
    supportingText: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#52487c",
    },
}));

export default function AccessDenied({ verify }) {
    const classes = useStyles();

    return (
        <div className={classes.unauthorized}>
            <div className={classes.icon}>
                <Block className={classes.iconFont} />
            </div>
            <div className={classes.text}>
                <Typography className={classes.titleText} variant="body1">
                    Access Denied
                </Typography>
                <div className={classes.textBody}>
                    <Typography className={classes.subTitleText} variant="body1">
                        We're sorry, but you do not have access to this document.
                    </Typography>
                    <Typography className={classes.supportingText} variant="subtitle2">
                        If you believe this is an error or if you have any questions, please contact the document owner or the person who shared the document
                        with you for further assistance.
                    </Typography>
                </div>
            </div>
            <FixedButton text='Verify Email' onClick={verify} />
        </div>
    );
}
