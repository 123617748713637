import axios, { setAuthHeader } from "../utils/axios";

const apiUrl = process.env.REACT_APP_AUTH_API_URL;

const auth0WorkNestTokenExchange = async (auth0Token) => {
    setAuthHeader(auth0Token);

    try {
        const worknestTokenResponse = await axios.get(`${apiUrl}/token/auth0`);

        return handleWorkNestTokenResponse(worknestTokenResponse);
    } catch (error) {
        console.error("Error when fetching worknest token:", error);
        return Promise.reject();
    }
};

const handleWorkNestTokenResponse = (worknestTokenResponse) =>
  worknestTokenResponse.token;

const getImpersonationToken = (email) =>
  axios.get(`${apiUrl}/token/impersonate/${email}`);

const tokenService = {
    getImpersonationToken,
    auth0WorkNestTokenExchange,
};

export default tokenService;
