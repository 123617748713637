import React, { useState } from "react";
import useModuleTemplates from "../hooks/queries/useModuleTemplates";
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Tooltip,
    TablePagination,
    TableRow,
    Typography,
    Menu,
    MenuItem,
    makeStyles,
    Grid,
} from "@material-ui/core";
import CardBase from "../../components/cards/CardBase";
import EnhancedTableHead from "../../components/table/EnhancedTableHead";
import moduleAreas from "../constants/moduleAreaConstants";
import ArchiveTemplateDialog from "./dialogs/ArchiveTemplateDialog";
import { Archive, Edit, History, MoreVert, ControlPointDuplicate } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import TemplateNameCell from "./TemplateNameCell";
import { Link, useRouteMatch } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import TemplateVersionHistoryDialog from "./riskassessments/dialogs/TemplateVersionHistoryDialog";
import { setTablePage } from "../../redux/actions/tablePageActions";
import { tablePage } from "../constants/tablePageConstants";
import { sortOrderName } from "../constants/sortOrder";
import useSectors from "../hooks/queries/useSectors";
import useSubjects from "../hooks/queries/useSubjects";
import ResponsiveSelect from "../../components/ui/ResponsiveSelect";
import { frequencyDropdownValues } from "../constants/frequencyConstants";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: "flex",
        gap: theme.spacing(1),
        fontSize: "0.875rem",
    },
}));

const defaultDropdownOption = { value: -1, label: "All" };

const headCells = [
    {
        id: "name",
        label: "Name",
        isSortable: true,
  },
  {
    id: "sector",
      label: "Sector",
      isSortable: true,
  },
  {
    id: "subject",
      label: "Subject",
      isSortable: true,
  },
  {
    id: "frequency",
      label: "Frequency",
      isSortable: true,
    },
    {
        id: "site",
        label: "Site",
        isSortable: false,
    },
  {
    id: "actions",
    label: "Actions",
  },
];

const tableKey = tablePage.MONITORING_ADMIN_TEMPLATES_TABLE;

const hideOptionForClientUserIfTemplateIsGlobal = (
    siteExternalId,
    isWorkNestUser
) => (!siteExternalId && isWorkNestUser) || siteExternalId;

function MonitoringAdminTemplatesTableCard() {
    const classes = useStyles();
    const dispatch = useDispatch();

  const { selectedSite, activeAppId, appSiteIds, selectedAccount, sites } =
    useSelector((state) => state.account);

    const { monitoringAdminTemplatesTable } = useSelector(
        (state) => state.tablePage
    );

    const currentAccountId = selectedAccount?.account?.externalId;

    const siteExternalIds = selectedSite
        ? [selectedSite.externalId]
        : appSiteIds[activeAppId];

    const sectors = useSectors();
    const subjects = useSubjects();

    const [templateType, setTemplateType] = useState(-1);
    const [sector, setSector] = useState(-1);
    const [subject, setSubject] = useState(-1);
    const [frequency, setFrequency] = useState(-1);

    const [archiveTemplateId, setArchiveTemplateId] = useState(null);

    const match = useRouteMatch();
    const { path } = match;
    const { isWorknestUser } = useAuth();

    const templates = useModuleTemplates({
        moduleArea: moduleAreas.MONITORING,
        pageNum: monitoringAdminTemplatesTable.page,
        rowsPerPage: monitoringAdminTemplatesTable.rowsPerPage,
        accountId: currentAccountId,
        siteExternalIds,
        orderByColumn: monitoringAdminTemplatesTable.sortBy || headCells[0].id,
        sortOrder: monitoringAdminTemplatesTable.orderBy || sortOrderName.ASC,
        frequency: frequency === -1 ? null : frequency,
        sectorId: sector === -1 ? null : sector,
        subjectId: subject === -1 ? null : subject,
        templateType: templateType === 2 ? true : templateType === 1 ? false : null,
    });

    const handleTemplateTypeChange = (e) => {
        setTemplateType(e.target.value);
    };

    const handleSectorChange = (e) => {
        setSector(e.target.value);
    };

    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
    };

    const handleFrequencyChange = (e) => {
        setFrequency(e.target.value);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState();
    const [versionDialogOpen, setVersionDialogOpen] = useState(false);

    function handleCloseDialog() {
        setVersionDialogOpen(false);
    }

    function handleOpenVersionDialog(id) {
        setSelectedTemplateId(id);
        setVersionDialogOpen(true);
        setOpen(false);
    }

    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setOpen(id);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleRowsPerPageChange = (e) => {
        dispatch(
            setTablePage({
                key: tableKey,
                page: 0,
                rowsPerPage: parseInt(e.target.value),
            })
        );
    };

    const handlePageChange = (e, newPage) => {
        dispatch(
            setTablePage({
                key: tableKey,
                page: newPage,
                rowsPerPage: monitoringAdminTemplatesTable.rowsPerPage,
            })
        );
    };

    const handleSortClick = (event, sortColumn) => {
        const isAsc = monitoringAdminTemplatesTable.sortBy === sortColumn && monitoringAdminTemplatesTable.orderBy === sortOrderName.ASC;
        dispatch(
            setTablePage({
                ...monitoringAdminTemplatesTable,
                key: tableKey,
                page: 0,
                sortBy: sortColumn,
                orderBy: isAsc ? sortOrderName.DESC : sortOrderName.ASC,
            })
        );
    };

  const tableContent = () => {
    if (templates.data?.results?.length > 0) {
      return templates.data.results.map((t) => (
        <TableRow key={t.id}>
          <TableCell>
            <TemplateNameCell name={t.name} isWorkNest={!t.siteExternalId} />
          </TableCell>
          <TableCell>{t.sector}</TableCell>
          <TableCell>{t.subject}</TableCell>
          <TableCell>{t.frequencyDescription}</TableCell>
          <TableCell>{sites[t?.siteExternalId]?.name ?? "All"}</TableCell>
          <TableCell>
            <Tooltip title="More">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => handleClick(e, t.id)}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open === t.id}
              onClose={handleClose}
            >
              <MenuItem
                className={classes.menuItem}
                component={Link}
                to={`${path}/templatebuilder/edit/${t.templateVersionId}`}
              >
                <Edit />
                {isWorknestUser || t.siteExternalId ? "Edit Template" : "Duplicate and Edit Template"}
                      </MenuItem>
                      {t.siteExternalId && (
                          <MenuItem
                              className={classes.menuItem}
                              component={Link}
                              to={`${path}/templatebuilder/edit/${t.templateVersionId}/true`}
                          >
                              <ControlPointDuplicate />
                              Duplicate and Edit Custom Template
                          </MenuItem>
                      )}
              {hideOptionForClientUserIfTemplateIsGlobal(
                t.siteExternalId,
                isWorknestUser
              ) && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => setArchiveTemplateId(t.id)}
                >
                  <Archive />
                  Archive Template
                </MenuItem>
              )}
              <MenuItem
                className={classes.menuItem}
                onClick={() => handleOpenVersionDialog(t.id)}
              >
                <History />
                Version History
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
      ));
    }

        return (
            <TableRow>
                <TableCell colSpan={headCells.length}>
                    <Typography>No templates have been added.</Typography>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <>
            <TemplateVersionHistoryDialog
                onClose={handleCloseDialog}
                open={versionDialogOpen}
                templateId={selectedTemplateId}
            />
            <CardBase
                title="Monitoring Templates"
                description="A list of all available monitoring templates."
                isLoading={templates.isLoading}
                isFetching={templates.isFetching}
                error={templates.error}
            >
                <Grid container spacing={2}>
                    {!isWorknestUser &&
                        <Grid item xs={12} md={6} xl={3}>
                            <ResponsiveSelect
                                options={[
                                    { value: -1, label: "All" },
                                    { value: 1, label: "WorkNest" },
                                    { value: 2, label: "Client" },
                                ]}
                                optionLabelFunction={(option) => option.label}
                                optionValueKey={'value'}
                                margin='dense'
                                fullWidth
                                label="Template Type"
                                placeholder="Template Type"
                                value={templateType}
                                onChange={handleTemplateTypeChange}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} md={6} xl={3}>
                        <ResponsiveSelect
                            options={[defaultDropdownOption].concat(sectors.data?.map((s) => ({
                                value: s.id,
                                label: s.name,
                            })))}
                            optionLabelFunction={(option) => option.label}
                            optionValueKey={'value'}
                            margin='dense'
                            fullWidth
                            label="Sector"
                            placeholder="Sector"
                            value={sector}
                            onChange={handleSectorChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                        <ResponsiveSelect
                            options={[defaultDropdownOption].concat(subjects.data?.map((s) => ({
                                value: s.id,
                                label: s.name,
                            })))}
                            optionLabelFunction={(option) => option.label}
                            optionValueKey={'value'}
                            margin='dense'
                            fullWidth
                            label="Subject"
                            placeholder="Subject"
                            value={subject}
                            onChange={handleSubjectChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                        <ResponsiveSelect
                            options={[defaultDropdownOption].concat(frequencyDropdownValues)}
                            optionLabelFunction={(option) => option.label}
                            optionValueKey={'value'}
                            margin='dense'
                            fullWidth
                            label="Frequency"
                            placeholder="Frequency"
                            value={frequency}
                            onChange={handleFrequencyChange}
                        />
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table aria-label="Monitoring">
                        <EnhancedTableHead
                            headCells={headCells}
                            sortColumn={monitoringAdminTemplatesTable.sortBy}
                            sortDirection={monitoringAdminTemplatesTable.orderBy || sortOrderName.DESC}
                            onRequestSort={handleSortClick}
                        />
                        <TableBody>{tableContent()}</TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={templates.data ? templates.data.totalCount : 0}
                    rowsPerPage={monitoringAdminTemplatesTable.rowsPerPage}
                    page={monitoringAdminTemplatesTable.page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
                <ArchiveTemplateDialog
                    open={!!archiveTemplateId}
                    onCancel={() => setArchiveTemplateId(null)}
                    archiveTemplateId={archiveTemplateId}
                    applicationArea={moduleAreas.MONITORING}
                    page={monitoringAdminTemplatesTable.page}
                    rows={monitoringAdminTemplatesTable.rowsPerPage}
                    accountId={currentAccountId}
                    siteExternalIds={siteExternalIds}
                />
            </CardBase>
        </>
    );
}

export default MonitoringAdminTemplatesTableCard;
