import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import documentService from "../../services/documentService";
import reactQueryUtils from "../../utils/reactQueryUtils";

const { client } = reactQueryUtils;

const useDeleteFolder = () =>
  useMutation(
    ({ documentType, folder }) =>
      documentService.deleteFolder(documentType, folder),
    {
      onMutate: async ({ documentType, folder }) => {
        const queryKey = [queryKeys.documents, documentType];
        const externalId = folder.blob;

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await client.cancelQueries(queryKey);

        // Snapshot the previous value
        const previousDocuments = client.getQueryData(queryKey);
        
        let prefix = `${folder.blob}/${folder.name}`;

        // Optimistically update to the new value
        // delete doc
        client.setQueryData(queryKey, (old) =>
          old.map((site) =>
            site.accountExternalId == externalId
              ? {
                  ...site,
                  documents: site.documents.filter(
                    (document) => !document.name.startsWith(prefix)
                  ),
                }
              : site
          )
        );

        // Return a context object with the snapshotted value
        return { previousDocuments, queryKey };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, newDocument, context) => {
        client.setQueryData(context.queryKey, context.previousDocuments);
      },
      // Always refetch after error or success:
      onSettled: (_data, _err, _variables, context) => {
        client.invalidateQueries(context.queryKey);
      },
    }
  );

export default useDeleteFolder;
