import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
    TextField,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    outlinedInput: {
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)', 
    },
}));

const HazardControlAmendDialog = ({
  isOpen,
    setIsOpen,
    hazardControl,
    updateControl,

}) => {
    const classes = useStyles();

    const [hazardControlText, setHazardControlText] = useState("");

    useEffect(() => {
        if (hazardControl) {
            setHazardControlText(hazardControl?.control?.name);
        }

    }, [hazardControl]);

    const handleUpdateHazardControlText = (value) => {
        setHazardControlText(value);
    };

    const handleConfirm = async e => {
        e.preventDefault();
        updateControl({ controlId: hazardControl?.control?.id, hazardControlText, isCustomHazard: hazardControl?.control?.isCustomHazard, isCustomControl: hazardControl?.isCustomControl })
        handleClose();
        }

    const handleClose = () => {
        setIsOpen(false);
    };

  return (
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle>{hazardControl?.id ? "Edit " : "Add "} Hazard Control Text</DialogTitle>
          <DialogContent>

              <TextField
                  InputProps={{ classes: {outlined: classes.outlinedInput,}}}
                  fullWidth
                  id="outlined-multiline-static"
                  label="Hazard Control Details"
                  multiline
                  variant="outlined"
                  value={hazardControlText}
                  onChange={(e) => handleUpdateHazardControlText(e.target.value)}
              />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HazardControlAmendDialog;
