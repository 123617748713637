import axios from "../../utils/axios";

const apiUrl = process.env.REACT_APP_HS_API_URL;

const fetchAllSectors = () => axios.get(`${apiUrl}/Sector/GetAll`);

const sectorService = {
  fetchAllSectors,
};

export default sectorService;
