import { useMutation } from "react-query";
import tutorialService from "../../services/tutorialService";
import reactQueryUtils from "../../utils/reactQueryUtils";
import queryKeys from "../../constants/queryKeys";

const useAddTutorial = () =>
  useMutation(tutorialService.add, {
    onMutate: async (tutorial) => {
      const { client } = reactQueryUtils;
      const queryKey = [queryKeys.tutorialsList];
      await client.cancelQueries(queryKey);

      const tutorialsBeforeAdd = client.getQueryData(queryKey);

      client.setQueryData(queryKey, (tutorials) => ({
        ...tutorials,
        tutorial,
      }));

      return { tutorialsBeforeAdd };
    },
    onError: (error, variables, { tutorialsBeforeAdd }) => {
      const queryKey = [queryKeys.tutorialsList];
      reactQueryUtils.client.setQueryData(queryKey, tutorialsBeforeAdd);
    },
    onSettled: () => {
      reactQueryUtils.client.invalidateQueries([queryKeys.tutorialsList]);
    },
  });

export default useAddTutorial;
