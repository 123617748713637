import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import peopleService from "../../services/peopleService";

const usePeople = () =>
  useQuery([queryKeys.people], () => peopleService.fetchAllPeople(), {
    staleTime: Infinity,
  });

export default usePeople;
