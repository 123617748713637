import { makeStyles } from "@material-ui/core"
import { useLocation } from "react-router-dom"
import SearchBar from "./SearchBar";
import SearchResults from "./SearchResults";
import { useSiteSearchContext } from "../contexts/SiteSearchContext";

const useStyles = makeStyles(theme => ({
    searchContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}))

const IS_HISTORIC = 'isHistoric'

export default function SearchContainer() {
    const classes = useStyles()    
    const { search } = useLocation()
    const parameters = new URLSearchParams(search)

    const isHistoric = parameters.get(IS_HISTORIC) === 'true'

    const { showSiteResults } = useSiteSearchContext()

    return (
        <div className={classes.searchContainer}>
            <SearchBar isHistoric={isHistoric} />
            {showSiteResults && <SearchResults isHistoric={isHistoric} />}
        </div>
    )
}