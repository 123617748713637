import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import actionService from "../../services/actionService";
import reactQueryUtils from "../../../utils/reactQueryUtils";

const useAction = ({ actionId, isDri }, options) =>
  useQuery(
    [queryKeys.action, actionId, isDri],
    () => actionService.getById({ actionId, isDri }),
    {
      ...options,
      enabled: !!actionId,
      initialData: () => {
        const { client } = reactQueryUtils;
        const parsedActionId = parseInt(actionId);
        const cachedActions = client.getQueryData([queryKeys.currentActions], {
          exact: false,
        });

        return cachedActions?.results.find(
          (x) => x.id === parsedActionId && x.isDri === isDri
        );
      },
    }
  );

export default useAction;
