import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import safetyNestScoreService from "../../services/safetyNestScoreService";

const useSiteBreakdownSafetyNestScore = ({ externalIds, page, rows, sortBy, orderBy }) =>
  useQuery(
    [queryKeys.safetyNestScore, externalIds?.join(","), page, rows],
    () => safetyNestScoreService.fetchSiteBreakdownSafetyNestScore({ externalIds, page, rows, sortBy, orderBy }),
    {
      enabled: !!externalIds?.length,
    }
  )

export default useSiteBreakdownSafetyNestScore
