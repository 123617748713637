import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateService from "../../services/templateService";

const useTemplate = (templateId) =>
  useQuery(
    [
      queryKeys.template,
      templateId
    ],
    () => templateService.fetchTemplate(templateId),
    {
      enabled: !!templateId,
    }
  );

export default useTemplate;