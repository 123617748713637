import React from "react";
import clsx from "clsx";
import { ratingList } from "../constants/actionRatingConstants";
import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    color: "white",
    maxWidth: 'max-content',
    borderRadius: "4px",
        "&.status-0": {
        backgroundColor: ratingList[0].chipColour,
    },
    "&.status-1": {
        backgroundColor: ratingList[1].chipColour,
    },
    "&.status-2": {
        backgroundColor: ratingList[2].chipColour,
    },
    "&.status-3": {
        backgroundColor: ratingList[3].chipColour,
    },
  },
}));

function ActionRatingChip({ rating }) {
  const classes = useStyles();

  if (rating === null) return null;

  return (
      <Chip
          size="small"
          className={clsx(classes.root, `status-${rating}`)}
          label={ratingList[rating].label}
      />
  );
}

export default ActionRatingChip;
