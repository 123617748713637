import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import documentService from "../../services/documentService";
import reactQueryUtils from "../../utils/reactQueryUtils";

const useAddDocuments = () =>
  useMutation(
    ({ documentType, files, selectedFolder }) =>
      documentService.addDocuments(documentType, files, selectedFolder),
    {
      onSuccess: (data, { documentType }) => {
        const { client } = reactQueryUtils;
        const queryKey = [queryKeys.documents, documentType];
        client.invalidateQueries(queryKey);
      },
    }
  );

export default useAddDocuments;
